/** @module */

import { ALERT_SET, ALERT_CLEAR, ALERT_LOADING } from '../reducers/alert';

/**
 * Shows an alert confirmation dialog via <AlertDialog />.
 * @param {Object} data - a data object that represents the details and actions of the alert.
 */
export const showAlert = data => {
  return dispatch => {
    dispatch({
      type: ALERT_SET,
      data
    });
  };
};

/**
 * Closes the alert <AlertDialog />
 */
export const setAlertLoading = () => {
  return dispatch => {
    dispatch({
      type: ALERT_LOADING
    });
  };
};

/**
 * Closes the alert <AlertDialog />
 */
export const clearAlert = () => {
  return dispatch => {
    dispatch({
      type: ALERT_CLEAR
    });
  };
};
