import React, { Fragment, useState } from 'react';
import { bool, func, object, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import classnames from 'classnames';

import { FormTextInput, Icon, NoResultsItem, Tag, TagGroup } from '..';
import { getGeoPropertyId, getGroupNameFromInput, getLabel } from '../../utils';

import styles from './Lookup.css';
import dropdownStyles from '../Dropdown/Dropdown.css';
import formTextInputStyles from '../FormTextInput/FormTextInput.css';
import searchStyles from '../Search/Search.css';
import { getMlsAddressSuggest } from '../../actions/cirrusProperties';
import { getCirrusPropertyId } from '../../utils/cirrusProperties';

const SUGGEST_SEARCH_LIMIT = 40;

const LookupProperty = ({ mlsBoard, fieldIsValid, inputThreshold = 1, onChange, onRemove, onSelect }) => {
  const [inputValue, setInputValue] = useState('');
  const groupName = getGroupNameFromInput(inputValue);

  const [entry, setEntry] = useState(null);

  const dispatch = useDispatch();

  // check if the group has already done loading

  // select the group based on the input value
  const cirrusGroup = useSelector(store => store.cirrusProperties.groups[groupName]) || [];
  const cirrusEntities = useSelector(store => store.cirrusProperties.entities) || {};
  // check if the group has already done loading
  const cirrusDoneLoading = useSelector(store => store.cirrusProperties.doneLoading[groupName]) || false;

  // map the entities based on the list of ids indexed by the input value
  const list = cirrusGroup.map(id => cirrusEntities[id]);

  // if we are done loading but the list is empty, then no results were found
  const noResultsFound = cirrusDoneLoading && list.length === 0;

  const noCleanResultsFound = cirrusDoneLoading && list.length === 0 && list.length < SUGGEST_SEARCH_LIMIT;

  const suggestGeoProperty = debounce(input => {
    if (!input || input.length < inputThreshold) {
      // We don't want to make a request when the input is less than a specific threshold (default is 1) - this is to work around the geo API not being able to filter by state.
      return;
    }

    dispatch(getMlsAddressSuggest({ input, source: mlsBoard, limit: SUGGEST_SEARCH_LIMIT }));
  }, 250);

  const handleChange = e => {
    const { value: searchTerm } = e.target;

    if (onChange) {
      onChange();
    }

    // change the input value
    setInputValue(searchTerm);

    // fetch the suggestions
    suggestGeoProperty(searchTerm);
  };

  const handleRemove = () => {
    // remove the entry from the state
    setEntry(null);

    onRemove();
  };

  const handleSelect = item => {
    // clear the input
    setInputValue('');

    // store the entry on the state
    setEntry(item);

    // call onSelect with the id, meaning that the entry was selected
    onSelect(item);
  };

  const menuClasses = classnames({
    [dropdownStyles.menu]: true,
    [dropdownStyles.menuNoResults]: noResultsFound
  });

  const classes = classnames({
    [styles.searchInput]: true,
    [formTextInputStyles.invalid]: !fieldIsValid
  });

  return (
    <Fragment>
      {entry ? (
        <TagGroup className={styles.tagGroup}>
          <Tag
            key={getGeoPropertyId(entry)}
            entityId={getGeoPropertyId(entry)}
            label={entry.line}
            removable
            handleRemove={handleRemove}
          />
        </TagGroup>
      ) : (
        <Downshift inputValue={inputValue} onSelect={handleSelect} itemToString={getLabel}>
          {({ getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex }) => (
            <div className={searchStyles.field}>
              <FormTextInput
                data-testid="LookupProperty.searchInput"
                id="propertySearchInput"
                type="search"
                placeholder="Search by address..."
                size="l"
                className={classes}
                {...getInputProps({ onChange: handleChange })}
              />
              <Icon name="search" size="s" className={searchStyles.icon} />
              {isOpen && cirrusDoneLoading ? (
                <ul {...getMenuProps()} className={menuClasses}>
                  {noCleanResultsFound ? (
                    <NoResultsItem />
                  ) : (
                    list.map((item, index) => {
                      const classes = classnames({
                        [dropdownStyles.item]: true,
                        [dropdownStyles.hovered]: highlightedIndex === index
                      });

                      return (
                        <li
                          className={classes}
                          {...getItemProps({
                            key: getCirrusPropertyId(item),
                            index,
                            item
                          })}
                        >
                          {item.full_address}
                        </li>
                      );
                    })
                  )}
                </ul>
              ) : null}
            </div>
          )}
        </Downshift>
      )}
    </Fragment>
  );
};

export default LookupProperty;

LookupProperty.propTypes = {
  fieldIsValid: bool,
  filterBy: object,
  inputThreshold: number,
  onChange: func,
  onRemove: func,
  onSelect: func.isRequired
};
