import React, { Fragment } from 'react';
import { bool, oneOf, func } from 'prop-types';

import { FormRadio, FormRadioGroup } from '../';

export const TaskType = React.forwardRef((props, ref) => {
  const {
    children,
    disabled,
    handleChange,
    selectedTaskType,
    source,
    showImportantDates,
    showEmail = false,
    showText = false
  } = props;

  return (
    <FormRadioGroup name="taskType">
      {children || (
        <Fragment>
          <FormRadio
            id={`taskTypeCall${source.toUpperCase()}`}
            label="Call"
            value="call"
            icon="call"
            onChange={handleChange}
            checked={selectedTaskType === 'call'}
            disabled={disabled}
            ref={selectedTaskType !== 'appointment' ? ref : null}
          />
          <FormRadio
            id={`taskTypeAppointment${source.toUpperCase()}`}
            label="Appt."
            value="appointment"
            icon="appointment"
            onChange={handleChange}
            checked={selectedTaskType === 'appointment'}
            disabled={disabled}
            ref={selectedTaskType === 'appointment' ? ref : null}
          />
          <FormRadio
            id={`taskTypeTodo${source.toUpperCase()}`}
            label="To Do"
            value="todo"
            icon="todo"
            onChange={handleChange}
            checked={selectedTaskType === 'todo'}
            disabled={disabled}
          />
          {showEmail && (
            <FormRadio
              id={`taskTypeTpxEmail${source.toUpperCase()}`}
              label="Email"
              value="tpxEmail"
              icon="email"
              onChange={handleChange}
              checked={selectedTaskType === 'tpxEmail'}
              disabled={disabled}
            />
          )}
          {showText && (
            <FormRadio
              id={`taskTypeTpxText${source.toUpperCase()}`}
              label="Text"
              value="texting"
              icon="texting"
              onChange={handleChange}
              checked={selectedTaskType === 'texting'}
              disabled={disabled}
            />
          )}
          {showImportantDates ? (
            <FormRadio
              id={`taskTypeImportantDates${source.toUpperCase()}`}
              label="Date"
              value="importantDates"
              icon="importantdates"
              onChange={handleChange}
              checked={selectedTaskType === 'importantDates'}
              disabled={disabled}
            />
          ) : (
            <Fragment />
          )}
        </Fragment>
      )}
    </FormRadioGroup>
  );
});

TaskType.propTypes = {
  showImportantDates: bool,
  selectedTaskType: oneOf(['appointment', 'call', 'todo', 'importantDates', 'tpxEmail', 'texting']),
  disabled: bool,
  handleChange: func,
  showEmail: bool
};
