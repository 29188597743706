export const SSO_AUTH_REQUEST = 'SSO_AUTH_REQUEST';
export const SSO_AUTH_SUCCESS = 'SSO_AUTH_SUCCESS';
export const SSO_AUTH_FAIL = 'SSO_AUTH_FAIL';
export const SSO_FETCH_TP8I_TOKEN_REQUEST = 'SSO_FETCH_TP8I_TOKEN_REQUEST';
export const SSO_FETCH_TP8I_TOKEN_SUCCESS = 'SSO_FETCH_TP8I_TOKEN_SUCCESS';
export const SSO_FETCH_TP8I_TOKEN_FAIL = 'SSO_FETCH_TP8I_TOKEN_FAIL';
export const SSO_SALESFORCE_AUTH_REQUEST = 'SSO_SALESFORCE_AUTH_REQUEST';
export const SSO_SALESFORCE_AUTH_SUCCESS = 'SSO_SALESFORCE_AUTH_SUCCESS';
export const SSO_SALESFORCE_AUTH_FAIL = 'SSO_SALESFORCE_AUTH_FAIL';

export const initialState = {
  isLoading: true,
  isSuccessful: false
};

/**
 * The alert redux reducer.
 * @param {Object} state - the current state of the alert store.
 * @param {Object} action - the action to take on the alert store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - A data object that represents the alert's details.
 */
export const ssoReducer = (state = initialState, action = {}) => {
  const { type = 'default' } = action;

  switch (type) {
    case SSO_SALESFORCE_AUTH_REQUEST:
    case SSO_AUTH_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case SSO_SALESFORCE_AUTH_SUCCESS:
    case SSO_AUTH_SUCCESS:
      return {
        ...state,
        isSuccessful: true,
        isLoading: false
      };
    case SSO_SALESFORCE_AUTH_FAIL:
    case SSO_AUTH_FAIL:
      return {
        ...state,
        isSuccessful: false,
        isLoading: false
      };
    default:
      return state;
  }
};
