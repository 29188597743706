/** @module */

export const UNSUBSCRIBE_RESET = 'UNSUBSCRIBE_RESET';
export const UNSUBSCRIBE_LOADING = 'UNSUBSCRIBE_LOADING';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_ERROR = 'UNSUBSCRIBE_ERROR';
export const FETCH_SENDER_NAME_LOADING = 'FETCH_SENDER_NAME_LOADING';
export const FETCH_SENDER_NAME_SUCCESS = 'FETCH_SENDER_NAME_SUCCESS';
export const FETCH_SENDER_NAME_ERROR = 'FETCH_SENDER_NAME_ERROR';
export const FETCH_ISUSCONTACT_LOADING = 'FETCH_ISUSCONTACT_LOADING';
export const FETCH_ISUSCONTACT_SUCCESS = 'FETCH_ISUSCONTACT_SUCCESS';
export const FETCH_ISUSCONTACT_ERROR = 'FETCH_ISUSCONTACT_ERROR';
export const SET_ISUSCONTACT = 'SET_ISUSCONTACT';

export const initialState = {
  isLoading: false,
  isUnsubscribed: false,
  senderName: '',
  isUSContact: false
};

/**
 * The alert redux reducer.
 * @param {Object} state - the current state of the alert store.
 * @param {Object} action - the action to take on the alert store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - A data object that represents the alert's details.
 */
export const subscriptionsReducer = (state = initialState, action = {}) => {
  const { type = 'default', errorData, senderName, isUSContact } = action;

  switch (type) {
    case UNSUBSCRIBE_RESET:
      return {
        ...state,
        isLoading: false,
        isUnsubscribed: false
      };
    case FETCH_SENDER_NAME_LOADING:
    case UNSUBSCRIBE_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUnsubscribed: true
      };
    case FETCH_ISUSCONTACT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUSContact
      };
    case SET_ISUSCONTACT:
      return {
        ...state,
        isUSContact
      };
    case UNSUBSCRIBE_ERROR:
      return {
        ...state,
        errorData,
        isUnsubscribed: false,
        isLoading: false
      };
    case FETCH_SENDER_NAME_SUCCESS:
      return {
        ...state,
        senderName
      };
    default:
      return state;
  }
};
