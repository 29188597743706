import { deleteFromCollection, getMergedEntities, getMergedSetOfIds } from '../utils';
import { deleteFromAllGroups } from '../utils/collections';

/** @module */
export const TEMPLATES_LOADING = 'TEMPLATES_LOADING';
export const TEMPLATES_SUCCESS = 'TEMPLATES_SUCCESS';
export const TEMPLATES_FAIL = 'TEMPLATES_FAIL';
export const TEMPLATES_DELETE = 'TEMPLATES_DELETE';

export const initialState = {
  isLoading: false,
  entities: {},
  groups: {},
  currentGroup: ''
};

/**
 * The templates redux reducer.
 * @param {Object} state - the current state of the settings store.
 * @param {Object} action - the action to take on the settings store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const templatesReducer = (state = initialState, action = {}) => {
  const { type, data, group, setCurrentGroup, id } = action;

  switch (type) {
    case TEMPLATES_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case TEMPLATES_SUCCESS:
      const entities = getMergedEntities(state.entities, data, { idKey: 'fileId' });
      return {
        ...state,
        isLoading: false,
        entities,
        groups: {
          ...state.groups,
          [group]: getMergedSetOfIds(state.groups[group], data, { idKey: 'fileId', entities })
        },
        currentGroup: setCurrentGroup ? group : state.currentGroup
      };
    case TEMPLATES_FAIL:
      return { ...state, isLoading: false };
    case TEMPLATES_DELETE:
      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, id)
        },
        groups: deleteFromAllGroups(state.groups, id)
      };
    default:
      return state;
  }
};
