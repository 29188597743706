import { REGEX } from '../constants';
import { getISODateFromNow } from './dates';
import { ENTITY_TYPES, getEntityTypeFromId } from './notes';
import { cleanStrOfSymbols, parseParamsStr, serializeToParamsStr } from './strings';

/**
 * A Map of contact statuses.
 */
// ToDo: make uppercase const name
export const contactStatuses = new Map([
  [10, 'New'],
  [20, 'Engage'],
  [40, 'Future'],
  [50, 'Non Client'],
  [60, 'Active'],
  [70, 'Closed'],
  [80, 'Inactive']
]);

/**
 * A Map of zillow contact statuses.
 */
export const zillowContactStatuses = new Map([
  [500, 'New'],
  [505, 'Attempted contact'],
  [510, 'Spoke with customer'],
  [515, 'Appointment set'],
  [520, 'Met with customer'],
  [530, 'Submitting offers'], // Buyer Only
  [535, 'Listing agreement'], // Seller Only
  [540, 'Active listing'], // Seller Only
  [545, 'Under contract'],
  [550, 'Sale closed'],
  [555, 'Nurture'],
  [560, 'Rejected'],
  [565, 'Showing homes'] // Buyer Only
]);

export const LAST_TOUCH_TYPES_MAP = new Map([
  [1, 'Task'],
  [3, 'Snapshot'],
  [4, 'Email'],
  [7, 'Note'],
  [9, ''], // Status changed was the default in TPM, but it is empty here, because it often overlaps the description of a last touch
  [12, 'Text']
]);

export const DEFAULT_CONTACT_LIST_COLUMNS = {
  contactType: 'touch',
  leads: 'touch',
  fav: 'touch',
  default: 'name' // all, search
};

export const DEFAULT_CONTACT_SORT_DIRECTIONS = {
  name: 0, // asc
  source: 0, // asc
  touch: 1, // desc
  consumer_touch: 0 //asc
};

// Indicates priority, from hgih to low
export const ALLOWED_SOCIAL_PROFILES = [
  'facebook',
  'instagram',
  'linkedin',
  'twitter',
  'pinterest',
  'youtube',
  'medium'
];

export const SOCIAL_LINKS_MAP = [
  { value: 'facebook', title: 'Facebook' },
  { value: 'instagram', title: 'Instagram' },
  { value: 'linkedin', title: 'LinkedIn' },
  { value: 'medium', title: 'Medium' },
  { value: 'pinterest', title: 'Pinterest' },
  { value: 'twitter', title: 'Twitter' },
  { value: 'youtube', title: 'Youtube' }
];

export const DEFAULT_CONTACTS_PARAMS = { status: 10 }; // new contacts

export const DEFAULT_QUERIES = ['all', 'fav', 'followup', 'recent', 'selected'];

export const PAGING_SIZE_OPTIONS = [
  { id: '25', value: '25' },
  { id: '50', value: '50' },
  { id: '100', value: '100' },
  { id: '250', value: '250' },
  { id: '500', value: '500' },
  { id: '1000', value: '1000' }
];

export const getContactListType = (q, status) => {
  if (status || (!q && !status)) {
    return 'leads';
  }

  if (q && q.startsWith('is:')) {
    return 'contactType';
  }

  if (q && q === 'fav') {
    return 'fav';
  }

  return 'default';
};

export const getContactListSortDirection = (dir, column) => {
  return dir !== null && dir !== undefined ? dir : DEFAULT_CONTACT_SORT_DIRECTIONS[column];
};

export const getContactGroupFromParams = requestOptions => {
  const options = requestOptions.params || requestOptions.payload;
  const { assignedTo, assignedToId, contactTypes, q, sortBy, dir, direction, queryType, searchType } = options || {};

  const status = queryType && queryType.status[0];
  const listType = getContactListType(q, status);

  const groupArray = [
    (Array.isArray(contactTypes) && contactTypes.join('-')) ||
      (q && q.toLowerCase().replace(/\s/g, '')) ||
      searchType ||
      (status && contactStatuses.get(parseInt(status)).toLowerCase()) ||
      'New' // ToDo:  We may need to treat status different if we want to support multiple statuses
  ];

  const sortByStr = sortBy ? sortBy : DEFAULT_CONTACT_LIST_COLUMNS[listType];
  groupArray.push(sortByStr);

  const dirStr = dir || direction || DEFAULT_CONTACT_SORT_DIRECTIONS[sortByStr];
  groupArray.push(dirStr);

  if (assignedTo) {
    let assignedToStr;

    if (assignedTo.assignedToUserId) {
      assignedToStr = assignedTo.assignedToUserId?.id || assignedTo.assignedToUserId;
    } else {
      if (assignedTo.filterType === 0) {
        assignedToStr = 'all';
      }
      if (assignedTo.filterType === 0) {
        assignedToStr = 'unassigned';
      }
    }

    groupArray.push(assignedToStr);
  } else if (assignedToId) {
    // For social connect assignedToId, different param name from the regular `assignedTo`
    groupArray.push(assignedToId);
  }

  return groupArray.join('::');
};

/**
 * Returns a human friendly contact status.
 * @param {number} statusId - the contact status ID.
 */
export const getContactStatus = statusId => {
  const parsedStatuisId = parseInt(statusId);
  return parsedStatuisId >= 500 ? zillowContactStatuses.get(parsedStatuisId) : contactStatuses.get(parsedStatuisId);
};

/**
 * Returns a human friendly contact status.
 * @param {number} statusName - the contact status ID.
 */
export const getContactStatusId = statusName => {
  let value;
  for (const [key, val] of contactStatuses.entries()) {
    if (val.toLowerCase() === statusName.toLowerCase()) {
      value = key;
      break;
    }
  }

  return value;
};

/**
 * Takes in an alphabetic name string (comma delimited), and makes it human friendly.
 * @param {String} commaDelimitedName - a comma delimited name string in reverse order.
 */
export const getContactDisplayName = commaDelimitedName => {
  if (!commaDelimitedName) {
    return null;
  }

  const ampersandConjunctionStr = ' & ';
  const nameCommaStr = ', ';
  const nameArray = commaDelimitedName.split(ampersandConjunctionStr);

  const formattedNamesArray = nameArray.map(name => {
    return name.split(nameCommaStr).reverse().join(' ').trim();
  });

  return formattedNamesArray.join(ampersandConjunctionStr);
};

/**
 * Method that allows us to show the best data in the card based on what is available.
 * Some APIs do not have the fullName key.
 * @param {String} fullName - lastName, firstName
 * @param {String} firstName
 * @param {String} lastName
 * @param {'firstNameFirst'|'lastNameFirst'} format
 */
export const getDisplayName = (fullName, firstName, lastName, format = 'lastNameFirst') => {
  if (fullName) {
    return fullName;
  }

  if (firstName && lastName) {
    if (format === 'lastNameFirst') {
      return `${lastName}, ${firstName}`;
    }

    return `${firstName} ${lastName}`;
  }

  return lastName || firstName;
};

/**
 * Gets the contact's initials from first and last name, but only for Latin based names
 * @param {Object} contact - a primary contact object
 */
export const getContactInitials = contact => {
  if (!contact) {
    return null;
  }

  const { firstName, lastName } = contact;
  const letters = [];

  if (firstName) {
    letters.push(firstName.charAt(0));
  }

  if (lastName) {
    letters.push(lastName.charAt(0));
  }

  if (letters.length === 0) {
    return null;
  }

  const initials = letters.join('');

  // only return initials if from a basic latin set of characters
  return /^[A-zÀ-ÖØ-öø-ÿ]+$/.test(initials) ? initials : null;
};

/**
 * Cleans a phone number to be in the format NNN-NNN-NNNN
 * @param {string} number - a string representing a phone number
 */
export const cleanPhoneNumber = number => {
  if (!number) {
    return null;
  }

  const cleanNumber = number.replace(/[^a-zA-Z0-9]/g, '');

  if (cleanNumber.length < 10) {
    return cleanNumber;
  }

  const reversedNumber = [...cleanNumber].reverse().join('');

  const reversedFragmentsInReverseOrder = [
    reversedNumber.slice(0, 4),
    reversedNumber.slice(4, 7),
    reversedNumber.slice(7, 10),
    reversedNumber.slice(10)
  ];

  const fragments = reversedFragmentsInReverseOrder
    .reduce((acc, fragment) => {
      if (!fragment) {
        return acc;
      }

      acc.push([...fragment].reverse().join(''));

      return acc;
    }, [])
    .reverse();

  const finalNumber = fragments.length > 1 ? fragments.join('-') : fragments[0];

  return finalNumber && finalNumber.length > 6 ? finalNumber.toUpperCase() : null;
};

/**
 * A Map of contact method types.
 */
export const contactMethodTypes = new Map([
  [0, { id: 0, method: 'phone', label: 'Phone' }],
  [1, { id: 1, method: 'email', label: 'Email' }]
]);

/**
 * Gets the primary contact method for a contact
 * @param {object} contact - a standard contact object
 */
export const getPrimaryMethod = contact => {
  // Note: This method is a little complicated due to the fact that the list/search,
  // the contact details, and contact update APIs return different ways for dealing with the primary contact method.
  // The source of truth is the defaultCommId returned from the contact details/update API, and we default to relying on it when possible.
  const { defaultCommId, emails, phones, primaryMethod } = contact;
  let primaryCommMethod;
  let primaryMethodType = contactMethodTypes.get(primaryMethod);
  let methodLabel;

  if (!defaultCommId && (primaryMethodType === null || primaryMethodType === undefined)) {
    return null;
  }

  if (defaultCommId) {
    // Only the contact details API returns defaultCommId - it is the best source of truth.
    const isEmail = emails.find(method => {
      return method.id === defaultCommId;
    });
    const isPhone = phones.find(method => {
      return method.id === defaultCommId;
    });
    primaryCommMethod = isEmail || isPhone;
    primaryMethodType = isEmail ? contactMethodTypes.get(1) : contactMethodTypes.get(0);
    methodLabel = isEmail ? 'email' : 'phone';
  } else {
    // When we don't have defaultCommId, as with the list/search APIs, we rely on the primaryMethod value.
    methodLabel = primaryMethodType.method;
    const contactItem = contact[`${methodLabel}s`];
    primaryCommMethod = contactItem && contactItem.length > 0 ? contactItem[0] : null;
  }

  const primaryMethodValue = primaryCommMethod ? primaryCommMethod.value : null;
  const cleanValue = methodLabel === 'phone' ? cleanPhoneNumber(primaryMethodValue) : primaryMethodValue;

  // there is a lot of dirty data, so we want to do a basic check to see if it is healthy and closely represents a phone or email
  const isValid = methodLabel === 'phone' ? cleanValue && cleanValue.length >= 8 : REGEX.EMAIL.test(cleanValue);

  return isValid ? { ...primaryMethodType, ...{ value: cleanValue } } : null;
};

/**
 * Updates key with the value for a contact
 * @param {Object} originalEntities - the current entities from the store
 * @param {string} contactId - a string representing contactId
 * @param {Object} updatedKeys - keys whose values have to be updated
 */
export const updateContactUtility = (originalEntities, contactId, updatedKeys) => {
  return {
    ...originalEntities,
    [contactId]: {
      ...originalEntities[contactId],
      ...updatedKeys
    }
  };
};

/**
 * Calculates followUp Count - counts records with is_valid set to true
 * @param {Object} contactRecords - the current entities from the store
 */
export const calculateFollowUpCount = contactRecords => {
  const followUpCount = Object.keys(contactRecords).reduce((count, contact) => {
    return (count += contactRecords[contact].is_valid ? 1 : 0);
  }, 0);

  return followUpCount;
};

export const isValidPhone = number => {
  if (!number) {
    return false;
  }

  let cleanNumber = number.replace(/[a-zA-Z\s()-]/g, '');

  if (cleanNumber.length < 10 && cleanNumber.length > 12) {
    return false;
  }

  return REGEX.PHONE.test(number);
};

export const formatPhoneNumber = number => {
  if (!number) {
    return null;
  }

  let cleanNumber = number.replace(/[a-zA-Z\s()-]/g, '');

  if (cleanNumber.length < 10 && cleanNumber.length > 12) {
    return null;
  }

  if (cleanNumber.length === 11 && cleanNumber.startsWith('1')) {
    cleanNumber = `+${cleanNumber}`;
  } else if (cleanNumber.length === 10) {
    cleanNumber = `+1${cleanNumber}`;
  }

  return isValidPhone(cleanNumber) ? cleanNumber : null;
};

/**
 * Get's a contact's email name - referring primaryPerson over fullNameFirstNameFirst.
 * Checks if email matches to the Secondary Contact, if yes then supply their name.
 * @param {Object} contact
 */
export const getContactEmailName = (contact, email) => {
  const { fullNameFirstNameFirst, primaryPerson, emails, secondaryPerson, secondaryEmails } = contact;

  if (
    secondaryEmails != null &&
    secondaryEmails.length > 0 &&
    secondaryEmails[0].value === email &&
    !emails.some(item => email === item.value)
  ) {
    return `${secondaryPerson.fullName}`;
  } else {
    return primaryPerson ? `${primaryPerson.firstName} ${primaryPerson.lastName}` : fullNameFirstNameFirst;
  }
};

/**
 * Returns entity tag URL given id and type.
 * @param {String} id - the id.
 * @param {String} type - the type.
 */
export const createEntityTagUrl = (id, type) => {
  const entityType = getEntityTypeFromId(type);

  if (entityType == null) {
    return;
  }
  const entityPaths = {
    contact: 'contacts',
    task: 'tasks',
    transaction: 'transactions'
  };

  const entityPathFragment = entityPaths[entityType];

  return `/${entityPathFragment}/${id}`;
};

/**
 * Returns contact type tag URL based on tag
 * @param {String} tag - the tag.
 */
export const createContactTypeTagUrl = tag => {
  const escapedTag = encodeURIComponent(tag);
  return `/contacts?q=is:${escapedTag}`;
};

export const getContactListIdentifier = search => {
  const paramsObj = parseParamsStr(search) || {};
  const { assignedTo, dir, sortBy, pageSize, ...listParams } = paramsObj;

  if (Object.keys(listParams).length === 0) {
    listParams.status = 10;
  }

  return cleanStrOfSymbols(serializeToParamsStr(listParams));
};

/**
 * When performing touches locally in TPX, we sometimes need the last touch date to be updated right away.  We use this
 * method to produce a pseudo lastTouchPoint to show in the UI.  Otherwise, the only way to fetch the last touch is to fetch the contact details.
 * @param {String} type - The type of last touch performed.
 * @param {String} desc - The description of the last touch.
 */
export const getLastTouchFromAction = (type, desc) => {
  if (!type) {
    return {};
  }

  const lastTouchDescriptions = {
    email: 'Email sent',
    note: 'Note added',
    status: 'Contact status updated',
    task: 'Task completed',
    text: 'Text message sent'
  };

  return {
    dateUtc: getISODateFromNow(),
    description: desc || lastTouchDescriptions[type]
  };
};

export const getFollowupWindow = (reminder = 0) => {
  // Return plus or minus days from reminder.
  if (reminder <= 2) {
    return 0;
  }

  if (reminder <= 7) {
    return 1;
  }

  if (reminder <= 30) {
    return 3;
  }

  return 6;
};

export const isFollowUpUpcoming = (reminderInDays, numberOfDaysFromToday, followupWindow) => {
  return (
    numberOfDaysFromToday >= reminderInDays - followupWindow && numberOfDaysFromToday <= reminderInDays + followupWindow
  );
};

export const isFollowUpOverdue = (reminderInDays, numberOfDaysFromToday, followupWindow) => {
  return numberOfDaysFromToday > reminderInDays + followupWindow;
};

export const getContactTags = contactId => {
  if (!contactId) {
    return [];
  }

  return [
    {
      id: contactId,
      type: ENTITY_TYPES.contact
    }
  ];
};

export const getContact = (contactId, entities) => {
  if (!contactId || !entities) {
    return {};
  }

  return entities[contactId];
};

/**
 * Used in Snapshot agent view when contact for the report doesn't exist in tpx
 * Use this to pass name into Lookup
 * @param {String} contactId
 * @param {String} name
 */
export const getContactTagsWithName = (contactId, name) => {
  if (!contactId) {
    return [];
  }

  return [
    {
      id: contactId,
      type: ENTITY_TYPES.contact,
      name: name
    }
  ];
};

export const getSelectedValue = (currentItem, PICKLIST, { idKey = 'id' } = {}) => {
  if (!currentItem) {
    return null;
  }

  const selectedItem = Object.keys(PICKLIST).find(item => {
    return PICKLIST[item][idKey] === currentItem.value;
  });

  return selectedItem ? PICKLIST[selectedItem] : null;
};

export const getEmailsFromContacts = (contactIds, contacts) => {
  const result =
    contactIds?.reduce(
      (acc, key) => {
        const { emails, is_unsubscribed } = contacts?.[key] || {};
        if (emails?.length > 0 && !Boolean(is_unsubscribed)) {
          return emails?.reduce((acc, i) => {
            acc.validList = [...acc.validList, i.value];
            return acc;
          }, acc);
        } else {
          acc.noEmailList = [...acc.noEmailList, key];
        }
        return acc;
      },
      { validList: [], noEmailList: [] }
    ) || [];
  return result;
};

export const getDefaultAssignedToParam = (user, userInfo) => {
  return { assignedTo: userInfo?.isResponsibleAgent || !user?.isRestrictedAgent ? 'all' : user.userId };
};

/**
 * Converts the array of recentContacts from store into an object in entities form.
 * @param {Array} recentContacts the array of recentContacts
 * @returns {Object} the object in entities form.
 */
export const convertRecentContactsToEntities = recentContacts => {
  return recentContacts.reduce((acc, item) => ({ ...acc, [item.contact.id]: item.contact }), {});
};

/**
 * Checks if contact has spouse
 */
export const contactHasSpouse = contact => {
  const { secondaryPerson } = contact || {};
  return secondaryPerson !== null && (secondaryPerson?.firstName !== '' || secondaryPerson?.lastName !== '');
};
