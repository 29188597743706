import { bool, string } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContactList, ListPlaceholder } from '../../components';
import styles from './Dashboard.css';
import { CONTACTS_CURRENT_GROUP } from '../../reducers/contacts';

export const NewContacts = ({ isDetailsOpen, userId }) => {
  const dispatch = useDispatch();
  const { entities, isLoading, groups, donePaging, statusCount } = useSelector(store => store.contacts);
  const { userInfo } = useSelector(store => store.userProfile);
  const group = `new::touch::1::${userId}`;
  const TAB_DATA = {
    new: {
      url: '',
      icon: 'new',
      label: 'New',
      count: statusCount?.new,
      matchParams: { q: undefined, status: undefined },
      isLoading
    }
  };
  const shouldShowLoading = userInfo == null || !groups[group] || (!isDetailsOpen && isLoading);
  const list = groups?.[group]?.slice(0, 5) || [];

  const handleCardClick = () => {
    dispatch({
      type: CONTACTS_CURRENT_GROUP,
      group
    });
  };

  return !shouldShowLoading ? (
    <div>
      <ContactList
        contacts={entities}
        isLoading={isLoading}
        list={list}
        location={location}
        donePaging={donePaging[group]}
        tabData={TAB_DATA}
        isSearchTab={false}
        variant="dashboard"
        handleCardClick={handleCardClick}
        listClassName={styles.taskList}
      />
      <div className={styles.footerText}>
        <a href={`/contacts?assignedTo=${userId}`}>See All New Contacts</a>
      </div>
    </div>
  ) : (
    <ListPlaceholder rows={3} />
  );
};

NewContacts.propTypes = {
  className: string,
  isDetailsOpen: bool,
  userId: string
};
