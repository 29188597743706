/** @module */

import {
  CAMPAIGN_CHECK_SET,
  CAMPAIGN_CHECK_SET_INIT,
  CAMPAIGN_LAST_UPDATED,
  CAMPAIGN_LOADING,
  CAMPAIGN_SET,
  CAMPAIGN_UPDATE_CITIES,
  CAMPAIGN_SET_SKIP_MLS
} from '../reducers/socialConnect';
import { request, requestError } from '../utils/request';
import { getCitiesFromCampaign } from '../utils/socialConnect';
import { parseStringToBoolean } from '../utils/strings';
import { showMessage } from './message';

const SOCIAL_CONNECT_URI = 'facebookAd';
/**
 * Get city data suggest
 */
export const getCitySuggestions = ({ input }) => {
  return async dispatch => {
    const addressSuggestPath = `${SOCIAL_CONNECT_URI}/getCitySuggestions?cityName=${input}`;

    const options = {
      baseUrlKey: 'api',
      path: addressSuggestPath,
      shouldBeCached: false
    };

    if (!input) {
      return;
    }

    try {
      const response = await request(options);
      const { data } = response;

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const setCities = cities => {
  return dispatch => {
    dispatch({
      type: CAMPAIGN_UPDATE_CITIES,
      cities
    });
  };
};

export const setCampaignIsLoading = isLoading => {
  return dispatch => {
    dispatch({
      type: CAMPAIGN_LOADING,
      isLoading
    });
  };
};

/**
 * Get campaign status and campaign insights for agent
 */
export const getCampaignStatusAndInsights = ({ agentId, forceRefresh = false }) => {
  return async dispatch => {
    dispatch(setCampaignIsLoading(true));
    const options = {
      baseUrlKey: 'api',
      path: `${SOCIAL_CONNECT_URI}/getCampaignStatusAndInsights?agentId=${agentId}`,
      shouldBeCached: true,
      forceRefresh
    };

    try {
      const response = await request(options);
      const { data, status } = response;

      if (status === 200) {
        dispatch({
          type: CAMPAIGN_SET,
          payload: data,
          lastUpdated: new Date()
        });
        dispatch(setCampaignIsLoading(false));

        dispatch(setCities(getCitiesFromCampaign(data)));
      }
      return data;
    } catch (error) {
      // We want to handle errors silently because all users without purchsae will get 500
    }
  };
};

export const setUpSocialConnect = ({
  data,
  isInitialSetup = true,
  message = 'Targeted cities saved successfully.'
}) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      path: `${SOCIAL_CONNECT_URI}/Setup`,
      shouldBeCached: false,
      method: 'POST',
      payload: {
        cities: data,
        isInitialSetup
      }
    };

    try {
      const response = await request(options);
      const { data, status } = response;

      if (status === 200) {
        dispatch({
          type: CAMPAIGN_LAST_UPDATED,
          lastAction: new Date()
        });

        dispatch(showMessage({ message, type: 'success' }, true)); // useTimer set to true
      }

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Get campaign status and campaign insights for agent
 */
export const getCampaignCheck = ({ agentId, forceRefresh = false }) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      path: `${SOCIAL_CONNECT_URI}/getCampaignCheck?agentId=${agentId}`,
      shouldBeCached: false,
      forceRefresh
    };

    try {
      const response = await request(options);
      const { data, status } = response;

      if (status === 200) {
        dispatch({
          type: CAMPAIGN_CHECK_SET,
          isActive: parseStringToBoolean(data?.isActive)
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        dispatch({
          type: CAMPAIGN_CHECK_SET_INIT
        });
        // We don't want to keep calling getCampaignStatusAndInsights for users without license
        return Promise.reject();
      }
    }
  };
};

/**
 * Get city data suggest
 */
export const getAdPreview = ({ userId, type }) => {
  return async dispatch => {
    const adPreviewPath = `${SOCIAL_CONNECT_URI}/getCampaignAdPreviews?agentId=${userId}&type=${type}`;

    const options = {
      baseUrlKey: 'api',
      path: adPreviewPath,
      shouldBeCached: false
    };

    if (!userId || !type) {
      return;
    }

    try {
      const response = await request(options);
      const { data } = response;

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Set skip mls.
 * @param {Boolean} skipMls - value to assign to skipMls
 */
export const setSkipMls = skipMls => {
  return dispatch => {
    dispatch({
      type: CAMPAIGN_SET_SKIP_MLS,
      skipMls
    });
  };
};
