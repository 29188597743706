/** @module */

import {
  DEFAULT_CONTACT_SETTINGS,
  DEFAULT_DATE_RANGES,
  DEFAULT_TAG_COLORS,
  DEFAULT_TASK_SETTINGS,
  DEFAULT_TRANSACTION_SETTINGS
} from '../constants/preferences';

export const PREFS_SAVE = 'PREFS_SAVE';

export const initialState = {
  calendarDefaultView: null,
  calendarDefaultStartDay: '0',
  contactDetailsTabs: [
    'dashboard',
    'emails',
    'homeIntel',
    'importantDates',
    'notes',
    'properties',
    'insights',
    'relationships',
    'snapshot',
    'tasks',
    'texts',
    'transactions'
  ],
  contactsTabs: [
    'favorites',
    'all',
    'new',
    'engage',
    'future',
    'active',
    'closed',
    'followup',
    'search',
    'recent',
    'selected',
    'facebook'
  ],
  customTabs: {},
  defaultContactsPath: '/contacts',
  defaultContactDetailsPath: '/dashboard',
  defaultEmailAddressId: null,
  defaultTransactionDetailsPath: '/notes',
  emailSignatures: {},
  followUpReminders: {},
  recentContacts: [],
  recentSearches: [],
  tagColors: { contact: DEFAULT_TAG_COLORS },
  dateRanges: DEFAULT_DATE_RANGES,
  transactionDetailsTabs: ['documents', 'notes', 'listingParties', 'tasks'],
  transactions: DEFAULT_TRANSACTION_SETTINGS,
  contacts: DEFAULT_CONTACT_SETTINGS,
  tasks: DEFAULT_TASK_SETTINGS
};

/**
 * The preferences reducer redux reducer.
 * @param {Object} state - the current state of the user store.
 * @param {Object} action - the action to take on the user store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const prefsReducer = (state = initialState, action = {}) => {
  const { data } = action;
  switch (action.type) {
    case PREFS_SAVE:
      const contactsTabs = [...data.contactsTabs];
      // Add the new search tab to accounts with existing contactTabs data
      if (!data?.contactsTabs?.includes('search')) {
        contactsTabs.splice(contactsTabs.indexOf('recent'), 0, 'search');
      }

      const contactDetailsTabs = [...data.contactDetailsTabs];
      // Add the new relationships tab to accounts with existing contactDetailsTabs data
      if (!data?.contactDetailsTabs?.includes('relationships')) {
        contactDetailsTabs.splice(contactDetailsTabs.indexOf('snapshot'), 0, 'relationships');
      }

      return {
        ...state,
        ...data,
        tagColors: { contact: { ...DEFAULT_TAG_COLORS, ...data?.tagColors?.contact } },
        dateRanges: { ...DEFAULT_DATE_RANGES, ...data?.dateRanges },
        transactions: { ...DEFAULT_TRANSACTION_SETTINGS, ...data?.transactions },
        contacts: { ...DEFAULT_CONTACT_SETTINGS, ...data?.contacts },
        tasks: { ...DEFAULT_TASK_SETTINGS, ...data?.tasks },
        contactsTabs,
        contactDetailsTabs
      };
    default:
      return state;
  }
};
