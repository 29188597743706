import React, { Fragment, useMemo } from 'react';
import { bool, func, string, instanceOf, arrayOf, shape, number, oneOfType } from 'prop-types';
import classnames from 'classnames';

import { WARNING_MESSAGE } from '../../constants/communication';
import { EMAIL_INTEGRATION_URI, TEXTING_SETUP_URI } from '../../constants/uris';

import { TASK_TYPES } from '../../utils/tasks';
import { buildTestId } from '../../utils/tests';
import { IndexedTree } from '../../utils/IndexedTree';
import { TASK_PLAN_CATEGORIES } from '../../utils/taskPlans';
import { ListGroupers } from '../../utils/taskPlans/ListGroupers';
import { checkEmailIntegrationSetup } from '../../utils/communication';
import { checkIsUSAgent, checkTextingRegistered, checkRegistrationInProgress } from '../../utils/texting';
import { FormRow } from '../FormRow';
import { FormLabel } from '../FormLabel';
import { TaskPlanItemsList } from '../List/TaskPlanItemsList';
import { LookupPlan } from '../Lookup/LookupPlan';
import { Button } from '../Button';
import { MessageBanner } from '../MessageBanner';

import styles from './PlanFormField.css';
import formFieldStyles from '../FormField/FormField.css';

export const PlanFormField = props => {
  const {
    categoryId,
    currentList,
    labelForLookupField,
    idForLookupField = 'lookupTextField',
    initialInputValue,
    isLoading = false,
    isValid,
    onSelect,
    onRemove,
    plans,
    required = false,
    testId = 'PlanFormFieldset',
    userProfile,
    emailSettings,
    textingSettings
  } = props;

  const groupedList = useMemo(() => {
    const groupList =
      categoryId === TASK_PLAN_CATEGORIES.transactions
        ? ListGroupers.groupByDateType
        : ListGroupers.groupByDaysFromStart;
    return groupList(currentList);
  }, [categoryId, currentList]);

  const planPreviewClasses = classnames({
    [formFieldStyles.field]: true,
    [formFieldStyles.fullWidth]: true
  });

  const doesGroupListHaveTaskType = (groupedList, taskTypeValue) =>
    groupedList?.some(x => x.items.some(y => y.activityTypeId === taskTypeValue)) || false;

  const renderServiceWarningMessagesIfNeeded = () => {
    const textingRegistrationInProgress = checkRegistrationInProgress(textingSettings);
    const planHasEmailTask = doesGroupListHaveTaskType(groupedList, TASK_TYPES.tpxEmail.value);
    const planHasTextingTask = doesGroupListHaveTaskType(groupedList, TASK_TYPES.texting.value);

    return (
      <>
        {checkIsUSAgent(userProfile) && planHasTextingTask && !checkTextingRegistered(textingSettings) && (
          <MessageBanner
            icon="info"
            isVisible={true}
            message={
              textingRegistrationInProgress
                ? WARNING_MESSAGE.TEXTING_REG_IN_PROGRESS
                : WARNING_MESSAGE.TEXTING_NOT_SETUP
            }
          >
            {!textingRegistrationInProgress && (
              <Button
                icon="texting"
                styleType="transparent"
                label="Setup Texting"
                ariaLabel="Setup Texting"
                title="Setup Texting"
                url={TEXTING_SETUP_URI}
              />
            )}
          </MessageBanner>
        )}
        {planHasEmailTask && !checkEmailIntegrationSetup(emailSettings) && (
          <MessageBanner icon="info" isVisible={true} message={WARNING_MESSAGE.EMAIL_NOT_SETUP}>
            <Button
              icon="emailsetup"
              styleType="transparent"
              label="Setup Email Integration"
              ariaLabel="Setup Email Integration"
              title="Setup Email Integration"
              url={EMAIL_INTEGRATION_URI}
            />
          </MessageBanner>
        )}
      </>
    );
  };

  return (
    <Fragment>
      <FormRow>
        <div className={formFieldStyles.field}>
          <div className={formFieldStyles.labelWrap}>
            <FormLabel htmlFor={idForLookupField} required={required}>
              {labelForLookupField}
            </FormLabel>
          </div>
          <LookupPlan
            id={idForLookupField}
            initialInputValue={initialInputValue}
            list={plans}
            onSelect={onSelect}
            onRemove={onRemove}
            required={required}
            fieldIsValid={isValid}
            testId={buildTestId(testId, 'LookupPlan')}
          />
        </div>
      </FormRow>
      {currentList ? (
        <>
          {renderServiceWarningMessagesIfNeeded()}
          <FormRow>
            <div className={planPreviewClasses}>
              <FormLabel htmlFor="planItemContainer">Plan preview</FormLabel>
              <div id="planItemContainer" className={styles.itemsContainer}>
                <TaskPlanItemsList
                  readOnly
                  isNested
                  isLoading={isLoading}
                  groupedList={groupedList}
                  isVirtuoso={false}
                />
              </div>
            </div>
          </FormRow>
        </>
      ) : null}
    </Fragment>
  );
};

PlanFormField.propTypes = {
  categoryId: oneOfType([number, string]),
  currentList: oneOfType([instanceOf(Map), instanceOf(IndexedTree)]),
  labelForLookupField: string.isRequired,
  isLoading: bool,
  onSelect: func.isRequired,
  onRemove: func,
  plans: arrayOf(shape({ name: string.isRequired, categoryId: number.isRequired })).isRequired,
  required: bool,
  testId: string,
  visible: bool,
  userProfile: Object.isRequired,
  emailSettings: Object.isRequired,
  textingSettings: Object.isRequired
};
