import React, { Fragment } from 'react';
import { bool, func, object } from 'prop-types';
import { FormField, FormLabel, FormRadio, FormRadioGroup, FormRow } from '../';

import formFieldStyles from '../FormField/FormField.css';

export const ContactDetailsForm = React.forwardRef((props, ref) => {
  const { disableContactType, disabled, email, firstName, formIsValid, id = '', isSeller, lastName, onChange } = props;

  return (
    <Fragment>
      <FormRow>
        <div className={formFieldStyles.field}>
          <FormLabel htmlFor="contactType">Contact type</FormLabel>
          <FormRadioGroup name="contactType">
            <FormRadio
              id={`${id}roleBuyer`}
              label="Buyer"
              value="buyer"
              onChange={onChange}
              checked={!isSeller}
              disabled={disableContactType != null ? disableContactType : disabled}
              ref={isSeller ? null : ref}
            />
            <FormRadio
              id={`${id}roleSeller`}
              label="Seller"
              value="seller"
              onChange={onChange}
              checked={isSeller}
              disabled={disableContactType != null ? disableContactType : disabled}
              ref={isSeller ? ref : null}
            />
          </FormRadioGroup>
        </div>
      </FormRow>
      <FormRow>
        <FormField
          type="input"
          inputType="text"
          id="firstName"
          label="First name"
          onChange={onChange}
          placeholder="First Name"
          maxLength="256"
          value={firstName.value}
          disabled={disabled}
          fieldIsValid={firstName.isValid}
          formIsValid={formIsValid}
          required
        />
        <FormField
          type="input"
          fieldIsValid={lastName.isValid}
          formIsValid={formIsValid}
          inputType="text"
          id="lastName"
          label="Last name"
          onChange={onChange}
          placeholder="Last Name"
          maxLength="256"
          value={lastName.value}
          disabled={disabled}
        />
      </FormRow>
      <FormRow>
        <FormField
          label="Email"
          type="input"
          inputType="email"
          id="email"
          onChange={onChange}
          placeholder="Email address"
          maxLength="256"
          value={email.value || ''}
          fieldIsValid={email.isValid}
          formIsValid={formIsValid}
          validationMessage="Invalid email"
          disabled={disabled}
          required
        />
      </FormRow>
    </Fragment>
  );
});

ContactDetailsForm.propTypes = {
  email: object.isRequired,
  firstName: object.isRequired,
  isSeller: bool.isRequired,
  onChange: func,
  lastName: object.isRequired,
  disableContactType: bool,
  disable: bool
};
