import React from 'react';
import camelCase from 'camelcase';

import { useMediaQueryContext } from '../MediaQueryProvider/MediaQueryProvider';
import { ALL_NAV_LINKS, MOBILE_NAV_LINKS } from '../../data/nav-links';
import { buildTestId } from '../../utils';
import { LinkList } from '../';

import styles from './NavBar.css';
import { useDispatch } from 'react-redux';
import { openAIDialog } from '../../actions/openai';

export const getNavLinksData = (LINKS, defaultContactsPath, dispatch) => {
  return LINKS.map(link => {
    const id = camelCase(link.label);
    const newLink = {
      ...link,
      matchKey: 'startsWithUrl'
    };

    if (defaultContactsPath && id === 'contacts') {
      newLink.url = defaultContactsPath;
    }

    if (id === 'calendar') {
      newLink.iconLabel = new Date().getDate().toString();
    }

    if (id === 'aiAuthor' && dispatch) {
      return {
        label: link.label,
        icon: link.icon,
        openAs: 'aiAuthor',
        onClick: () => dispatch(openAIDialog())
      };
    }

    return newLink;
  });
};

export const NavBar = props => {
  const { isTabletLandscapeAndUp } = useMediaQueryContext();
  const dispatch = useDispatch();

  const { defaultContactsPath, testId } = props;

  const LINKS = isTabletLandscapeAndUp ? ALL_NAV_LINKS : MOBILE_NAV_LINKS;

  const LINKS_WITH_AI = [
    ...LINKS,
    {
      label: 'AI Author',
      icon: 'aiAuthor'
    }
  ];

  const NAV_DATA = getNavLinksData(LINKS_WITH_AI, defaultContactsPath, dispatch);
  const builtTestId = buildTestId(testId, 'LinkList');

  return (
    <nav data-testid={testId} className={styles.navbar}>
      <LinkList data={NAV_DATA} orientation="vertical" type="nav" testId={builtTestId} />
    </nav>
  );
};
