import { object } from 'prop-types';
import React from 'react';
import { COLOR_BLUE_LIGHT, COLOR_DARK_ORANGE, COLOR_ORANGE } from '../../constants/preferences';
import { getContactDisplayName } from '../../utils/contacts';
import { Avatar } from '../Avatar';
import { Icon } from '../Icon';
import { Tag } from '../Tag';
import { Card } from './Card';
import { ContactLastTouch } from './ContactLastTouch';
import styles from './RecentActivityCard.css';

export const RecentActivityCard = props => {
  const { data } = props;
  const {
    emails,
    fullName,
    consumerLastTouchPoint = {},
    pictureURL,
    primaryPerson,
    phones,
    whenCreated,
    id
  } = data || {};

  if (!id) {
    return null;
  }
  const { objectTypeId } = consumerLastTouchPoint || {};

  const tagConfig = (() => {
    if ([4, 18, 19].includes(objectTypeId)) {
      return { label: 'Review Activity', color: COLOR_ORANGE };
    }
    if ([2, 12, 14, 17].includes(objectTypeId)) {
      return { label: 'Reply Needed', color: COLOR_DARK_ORANGE, icon: 'fire' };
    }
    if ([16].includes(objectTypeId)) {
      return { label: 'Review Changes', color: COLOR_BLUE_LIGHT };
    }
  })();
  return (
    <Card url={`/dashboard/view/contacts/${data?.id}`}>
      <div className={styles.recentActivityCard}>
        <div className={styles.tags}>
          <Tag key={`${id}tag`} label={tagConfig?.label} color={tagConfig?.color} icon={tagConfig?.icon} />
        </div>

        <div className={styles.header}>
          <div className={styles.avatar}>
            <Avatar
              emails={emails}
              name={fullName}
              pictureURL={pictureURL}
              primaryPerson={primaryPerson}
              size="m"
              hideImage={true}
            />
          </div>

          <h4 className={styles.fullName}>{getContactDisplayName(fullName)}</h4>
        </div>

        <div className={styles.contactInfo}>
          {phones.length > 0 && (
            <span className={styles.phone}>
              <Icon name="call" size="s" />
              {phones[0]?.value}
            </span>
          )}
          {emails.length > 0 && (
            <span className={styles.email}>
              <Icon name="email" size="s" />
              {emails[0]?.value}
            </span>
          )}
        </div>

        <div>
          <div className={`${styles.gridData} ${styles.gridLastResponse}`}>
            <ContactLastTouch
              consumerLastTouchPoint={consumerLastTouchPoint}
              showLastTouchLabel={false}
              touchType="response"
              whenCreated={whenCreated}
              showHomeIntel={true}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

RecentActivityCard.propTypes = {
  data: object
};
