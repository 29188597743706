import classnames from 'classnames';
import { bool, object, oneOf } from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '.';
import { Tag, TagGroup } from '..';
import { clearAlert, deleteNote, getNoteDetail, setAlertLoading, showAlert } from '../../actions';
import { clearDrawer, showDrawer } from '../../actions/drawer';
import { getPropertyInsightsByInsightId } from '../../actions/propertyInsights';
import { DATE_FORMATS } from '../../constants';
import { LazyPage } from '../../pages';
import { formatLongDate } from '../../utils';
import { getGroupByEntity } from '../../utils/collections';
import { createEntityTagUrl } from '../../utils/contacts';
import { parseISODate } from '../../utils/dates';
import { ENTITY_TYPES } from '../../utils/notes';
import { createInsightNoteTagUrl } from '../../utils/tags';
import { Button, ButtonGroup } from '../Button';

import cardStyles from './Card.css';
import styles from './NoteCard.css';

const CONFIRM_DELETE_MSG = 'Are you sure you want to delete this note?';

export const NoteCard = props => {
  const { className, currentNote, data, group, isNested = false, onClick, variant = 'default' } = props;

  const { groups: propertyInsightsGroups } = useSelector(state => state.propertyInsights);
  const { id, noteText, date, tags, createdBy } = data || {};
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(setAlertLoading());
    dispatch(deleteNote(id, group)).then(() => {
      dispatch(clearAlert());
    });
  };

  const handleConfirmDelete = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      showAlert({
        message: CONFIRM_DELETE_MSG,
        primaryButtonHandler: handleDelete
      })
    );
  };

  const handleEdit = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      showDrawer({
        drawerType: 'updateNotesForm',
        noteId: id,
        group,
        closeCallback: () => dispatch(getNoteDetail({ id, forceRefresh: true })).then(() => dispatch(clearDrawer()))
      })
    );
  };

  // Workaround for property insight tag URL.
  const [isInsightNote] = data?.tags.filter(e => e.type === 5) || [];

  useEffect(() => {
    if (isInsightNote && !getGroupByEntity(isInsightNote.id, propertyInsightsGroups)) {
      dispatch(getPropertyInsightsByInsightId(isInsightNote.id));
    }
  }, [dispatch, isInsightNote, propertyInsightsGroups]);

  if (!data || !data.noteText) {
    return null;
  }

  const parsedDate = parseISODate(date);
  const dateLong = formatLongDate(parsedDate);
  const time = formatLongDate(parsedDate, DATE_FORMATS.SHORT_TIME);
  const dateFriendly = formatLongDate(parsedDate, DATE_FORMATS.SHORT_DATE_WITH_YEAR);
  const dateTooltip = `${dateLong} at ${time}`;
  const createdByTooltip = `Created by ${createdBy}`;

  const classes = classnames({
    [styles.noteCard]: !className,
    [styles[`${variant}Card`]]: true,
    [styles.isNested]: isNested,
    [className]: !!className
  });

  const url = `/notes/${id}`;
  const isSelected = id === currentNote;

  return (
    <Fragment>
      <Card id={id} url={url} className={classes} isNested={isNested} isSelected={isSelected} onClick={onClick}>
        <div className={styles.grid}>
          <div className={styles.gridAreaMain} title={'noteBody'}>
            {noteText}
          </div>
          <div className={styles.gridAreaTags}>
            <TagGroup className={cardStyles.tagGroup} limit={5}>
              {tags.map((tag, i) => {
                const { name, id, type } = tag || {};
                const isInsightNote = type === ENTITY_TYPES.insight;

                // Don't show tag if 8i listing (3) or 8i closing (2).
                if (type === 2 || type === 3) {
                  return null;
                }

                return (
                  <Tag
                    key={`${name}-${i}`}
                    entityType={type}
                    label={name}
                    url={
                      isInsightNote ? createInsightNoteTagUrl(id, propertyInsightsGroups) : createEntityTagUrl(id, type)
                    }
                  />
                );
              })}
            </TagGroup>
          </div>
          <div className={styles.gridAreaCreatedBy} title={createdByTooltip}>
            {createdBy}
          </div>
          <div className={styles.gridAreaDate} title={dateTooltip}>
            {dateFriendly}
          </div>
          <div className={styles.gridAreaActions}>
            <ButtonGroup variant="icon">
              <Button ariaLabel="Edit" icon="edit" size="s" onClick={handleEdit} tooltipPos="left" />
              <Button ariaLabel="Delete" icon="delete" size="s" onClick={handleConfirmDelete} tooltipPos="left" />
            </ButtonGroup>
          </div>
        </div>
      </Card>
      {isSelected && isNested && <LazyPage page="noteDetail" noteId={id} group={group} isNested={isNested} />}
    </Fragment>
  );
};

NoteCard.propTypes = {
  data: object.isRequired,
  isNested: bool,
  variant: oneOf(['default', 'compact'])
};
