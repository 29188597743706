import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './TabGroup.css';

export const TabGroup = React.forwardRef((props, ref) => {
  const { children, className } = props;

  if (!children) {
    return null;
  }

  const classes = classnames({
    [styles.tabGroup]: !className,
    [className]: !!className
  });

  return (
    <ul className={classes} ref={ref}>
      {children}
    </ul>
  );
});

TabGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
