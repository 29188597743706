export const getInitialMessages = () => {
  return [
    {
      role: 'system',
      content:
        'Act as a specialized writing assistant for Top Producer TPX, exclusively supporting realtors. Focus solely on crafting and enhancing real estate-related email or text content. Exclude generic sections such as subject, greeting, and closing. Limit responses to under 200 words. This role is dedicated to improving real estate communications only, do not answer unrelated topics. Do not provide real listing data. You will get a template string with html tags and merge code in {{}} format, keep both and paraphrase the template'
    }
  ];
};

/**
 * Removes assistant messages from the end of an array until the first user message is encountered.
 * Used when regenerating last message
 * @param {Array<{ role: string, content: string }>} messages - The array of messages to process.
 * @returns {Array<{ role: string, content: string }>} The array with assistant messages removed from the end.
 */
export const removeAssistantMessages = messages => {
  let lastIndex = messages.length - 1;

  while (lastIndex >= 0 && messages[lastIndex].role === 'assistant') {
    lastIndex--;
  }

  return messages.slice(0, lastIndex + 1);
};
