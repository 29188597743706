import { TIMEZONE_LIST } from '../data/timezones';

export const NOT_MAPPED_FIELD = 'Not Mapped';

/**
 * A array of contact fields for multi select.
 */
export const multiSelectFields = [
  { value: NOT_MAPPED_FIELD },
  { value: 'Other Email' },
  { value: 'Other Web Address' },
  { value: 'Other Phone Numbers' },
  { value: 'Add to Contact Notes' },
  { value: 'Contact Type' }
];

/**
 * Returns a status count of the progress.
 * @param {Object} event - the event of the upload progress.
 */
export const getProgressCount = event => {
  if (!event) {
    return 0;
  }

  const totalLength = event.lengthComputable ? event.total : 0;
  return totalLength ? Math.round((event.loaded * 100) / totalLength) : 0;
};

/**
 * A Map of internal fields for dropdown.
 */
export const getMergedInternalFields = fields => {
  if (!fields) {
    return null;
  }

  const mergedFields = fields.map(field => {
    return { value: field.InternalFieldDisplayName, name: field.InternalFieldName };
  });

  mergedFields.unshift({ value: multiSelectFields[1].value, name: multiSelectFields[1].value });
  mergedFields.unshift({ value: multiSelectFields[0].value, name: multiSelectFields[0].value });

  return mergedFields;
};

/**
 * A default timezone from browser.
 */
export const getBrowsersTimeZone = () => {
  const offset = new Date().getTimezoneOffset() / 60;
  const zone = TIMEZONE_LIST.find(item => {
    return item.offset === offset || (item.offset + 30 > offset && item.offset - 30 < offset);
  });

  return zone || TIMEZONE_LIST[5];
};

/**
 * Get the path from location in props.
 */
export const getLocationFromProp = ({ location }) => {
  if (!location || !location.pathname) {
    return null;
  }
  const locationFragments = location.pathname.split('/');
  const hasSource = locationFragments.length === 4;
  return hasSource ? locationFragments.pop() : null;
};
