import React from 'react';
import PropTypes from 'prop-types';

import { ContactLastTouch, ContactStatus } from './';
import { Button, ButtonGroup, Icon } from '../';

import styles from './ContactCardFollowUp.css';
import { checkIsFromDashboard } from '../../utils/urls';

export const ContactCardFollowUp = props => {
  const { data, handleFollowup, handleSkipFollowup, location } = props;

  if (!data) {
    return null;
  }

  const {
    contactStatus,
    followUpCoachingTexts,
    fullName,
    id,
    is_valid, // whether the follow-up still needs to be completed
    lastTouchPoint = {}, // lastTouchPoint can empty for brand new automated leads
    whenCreated
  } = data;

  if (!followUpCoachingTexts) {
    return null;
  }

  const isCompleted = !is_valid;
  const { text: followupText, ruleId } = followUpCoachingTexts[0].Value[0];
  const urlPathFragment = ruleId === 1016 ? '/snapshots' : '';

  const isFromDashboard = checkIsFromDashboard(location.pathname);

  const url = !isFromDashboard
    ? `/contacts/${id}${urlPathFragment}${location.search}`
    : `/dashboard/view/contacts/${id}${urlPathFragment}${location.search}`;
  return (
    <div className={styles.cardContainer}>
      <div className={styles.grid}>
        <div className={styles.columnMain}>
          {!isFromDashboard && <ContactStatus contactStatus={contactStatus} className={styles.contactStatus} />}
          <div className={styles.cardTitle} title={fullName}>
            {fullName}
          </div>
          <ContactLastTouch lastTouchPoint={lastTouchPoint} whenCreated={whenCreated} type="followup" />
        </div>
        {!isCompleted && (
          <div className={isFromDashboard ? styles.coachTextAbbr : styles.coachText}>{followupText}</div>
        )}
      </div>
      {!isCompleted ? (
        <ButtonGroup className={styles.buttonGroup}>
          <Button
            data-contactid={id}
            label="Skip"
            ariaLabel="Skip the follow-up suggestion for this contact."
            onClick={handleSkipFollowup}
            data-cy="skipFollowUpButton"
          />
          <object className="nestedLinkWrapper">
            <Button
              label="Follow up"
              ariaLabel="Follow-up with this contact."
              icon="followup"
              size="s"
              url={url}
              onClick={handleFollowup}
              className={styles.followupButton}
              data-cy="followUpButton"
            />
          </object>
        </ButtonGroup>
      ) : (
        <div className={styles.completed}>
          <Icon name="check" isColored={true} className={styles.completedIcon} /> Completed!
        </div>
      )}
    </div>
  );
};

ContactCardFollowUp.propTypes = {
  data: PropTypes.object.isRequired
};
