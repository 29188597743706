import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './FormTextArea.css';
import { FormLabel } from '../FormLabel';

export const FormTextArea = React.forwardRef((props, ref) => {
  const { size = 'm', label, required, className, disabled, id, showInvalid, value = '', ...attrs } = props;

  const classes = classnames({
    [styles.textarea]: !className,
    [styles[`${size}Size`]]: true,
    [styles.invalid]: !!showInvalid,
    [className]: !!className
  });

  return (
    <div>
      {label && (
        <div className={styles.labelWrap}>
          <FormLabel htmlFor={id} size={size} required={required}>
            {label}
          </FormLabel>
        </div>
      )}
      <textarea ref={ref} id={id} className={classes} disabled={disabled} value={value} {...attrs} />
    </div>
  );
});

FormTextArea.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  rows: PropTypes.number,
  showInvalid: PropTypes.bool,
  value: PropTypes.string,
  size: PropTypes.oneOf(['s', 'm', 'l'])
};
