/** @module */

import { getMergedEntities, getMergedSetOfIds } from '../utils';

export const GEO_PROPERTIES_GET_START = 'GEO_PROPERTIES_GET_START';
export const GEO_PROPERTIES_GET_SUCCESS = 'GEO_PROPERTIES_GET_SUCCESS';
export const GEO_PROPERTIES_GET_ERROR = 'GEO_PROPERTIES_GET_ERROR';

export const initialState = {
  currentGroup: '',
  entities: {},
  groups: {},
  doneLoading: {},
  isLoading: false
};

export const geoPropertiesReducer = (state = initialState, action) => {
  const { type = 'default', payload = {} } = action || {};
  switch (type) {
    case GEO_PROPERTIES_GET_START: {
      const { groupName } = payload;
      return {
        ...state,
        currentGroup: groupName,
        doneLoading: {
          ...state.doneLoading,
          [groupName]: false
        },
        isLoading: true
      };
    }
    case GEO_PROPERTIES_GET_SUCCESS: {
      const { data, groupName } = payload;
      const { autocomplete } = data;

      return {
        ...state,
        entities: getMergedEntities(state.entities, autocomplete, { ignorePreexisting: true, idKey: '_id' }),
        groups: {
          ...state.groups,
          [groupName]: getMergedSetOfIds(state.groups[groupName], autocomplete, { idKey: '_id' })
        },
        doneLoading: {
          ...state.doneLoading,
          [groupName]: true
        },
        isLoading: false
      };
    }
    case GEO_PROPERTIES_GET_ERROR: {
      return { ...state, isLoading: false };
    }
    default:
      return initialState;
  }
};
