import React from 'react';
import { bool } from 'prop-types';

import { Loading } from '../Loading';

import styles from './ImageUploading.css';

export const ImageUploading = props => {
  const { isImageUploading = false } = props;

  if (!isImageUploading) {
    return null;
  }

  return (
    <div className={styles.imageUpload}>
      Image uploading...
      <div>
        <Loading loading={isImageUploading} size="m" className={styles.imageLoading} />
      </div>
    </div>
  );
};

ImageUploading.propTypes = {
  isImageUploading: bool
};
