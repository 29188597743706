import { navigate } from '@reach/router';
import { format } from 'date-fns';
import { object } from 'prop-types';
import React, { Fragment, lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert, showAlert } from '../../actions';
import { getContactLeadSourcePicklist } from '../../actions/contacts';
import { getTransactions, import8iClosings, setCurrentTransactionStatus } from '../../actions/transactions';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogHeader,
  Dropdown,
  FormLabel,
  FormToggle,
  Loading,
  Tab,
  TabContainer,
  TabGroup
} from '../../components';
import { Filters } from '../../components/Filters';
import TransactionsList from '../../components/List/TransactionsList';
const TransactionFormDialog = lazy(() => import('../../components/TransactionForm/TransactionFormDialog'));
import { toggleTransactionFormDialog } from '../../components/TransactionForm/TransactionFormDialog';
import TransactionPartyForm from '../../components/TransactionForm/TransactionPartyForm';
import { useMediaQueryContext } from '../../components/MediaQueryProvider/MediaQueryProvider';
import { View } from '../../components/View';
import { ViewHeader } from '../../components/ViewHeader';
import { DATE_FORMATS } from '../../constants';
import { TRANSACTIONS_ADD_PARTY_FORM_TOGGLE } from '../../reducers/transactions';
import { getDateFromToday } from '../../utils/tasks';
import { parseParamsStr, serializeToParamsStr } from '../../utils/strings';
import tabContainerStyles from '../../components/Tab/TabContainer.css';
import {
  ALL_ACTIVE,
  buildTransactionsGroupName,
  getTotalCommission,
  NON_ACTIVE_FLAG,
  TRANSACTION_ALL_STATUSES,
  TRANSACTION_LISTINGS_STATUSES,
  validateDateRange
} from '../../utils/transactions';
import { Details } from '../Details';
import { CustomDateRangeForm } from '../Transactions/CustomDateRangeForm';
import { TransactionStats } from './TransactionStats';

import styles from './Transactions.css';
import listStyles from '../../components/List/List.css';

const TAB = {
  LISTINGS: { matchParams: { status: undefined }, url: '' },
  CLOSINGS: {
    matchParams: { status: NON_ACTIVE_FLAG },
    url: `?status=${NON_ACTIVE_FLAG}`
  }
};

export const TRANSACTIONS_URL = 'transactions';
const noResultsMessage = 'No transactions found.';

const Transactions = props => {
  const { location, children } = props;
  const { pathname, search } = location;

  const startDateInputRef = useRef(null);
  const { isTabletPortraitAndUp } = useMediaQueryContext();
  const mountedParams = parseParamsStr(search) || {};

  const {
    status: mountedStatus,
    startDate: mountedStartDate,
    endDate: mountedEndDate,
    buyer: mountedBuyer,
    seller: mountedSeller,
    source: mountedSource
  } = mountedParams || {};

  const isClosings = mountedStatus === NON_ACTIVE_FLAG.toString();
  const dateRangeLabel = `${isClosings ? 'Closing' : 'Listing'} date range`;

  const mountedStatusId = parseInt(mountedStatus);
  const status = isNaN(mountedStatusId) ? ALL_ACTIVE : mountedStatusId;

  const displayStatus = TRANSACTION_ALL_STATUSES.find(item => item.id === status)?.value;

  const dispatch = useDispatch();
  const transactions = useSelector(state => state.transactions);
  const leadSourcePicklist = useSelector(state => state.contacts.leadSourcePicklist);

  const preferences = useSelector(state => state.preferences);
  const { transactions: transactionsPref } = preferences;
  const dateTo = isClosings
    ? transactionsPref?.transactionClosingRange?.dateTo
    : transactionsPref?.transactionRange?.dateTo;
  const dateFrom = isClosings
    ? transactionsPref?.transactionClosingRange?.dateFrom
    : transactionsPref?.transactionRange?.dateFrom;

  const userInfo = useSelector(state => state.userProfile.userInfo);
  const displayLeadSourcePicklist = [{ id: '', value: 'All' }, ...leadSourcePicklist];

  const {
    currentGroup,
    entities,
    groups,
    has8iImportBeenTriggered,
    isLoading,
    message,
    toggleAddPartyForm,
    toggleForm,
    updateTransactionId,
    lastSearchedDates
  } = transactions;
  const { isResponsibleAgent, isTeamOrPartnershipAccount, isAssistant } = userInfo;

  const lastSearchDates = isClosings ? lastSearchedDates?.closings : lastSearchedDates?.listings;

  // Default range date is from 364 to today
  const [startDate, setStartDate] = useState(
    mountedStartDate || format(getDateFromToday(parseInt(dateFrom)), DATE_FORMATS.ISO_DATE)
  );

  const [endDate, setEndDate] = useState(
    mountedEndDate || format(getDateFromToday(parseInt(dateTo)), DATE_FORMATS.ISO_DATE)
  );

  // By default, set buyer and seller to true, meaning no filtering
  const [buyerRepType, setBuyerRepType] = useState(mountedBuyer === 'true' || (mountedBuyer == null && true));
  const [sellerRepType, setSellerRepType] = useState(mountedSeller === 'true' || (mountedSeller == null && true));
  const [source, setSource] = useState(mountedSource || '');

  useEffect(() => {
    const options = { status, buyer: buyerRepType, seller: sellerRepType, startDate, endDate, source };
    dispatch(getTransactions(options, true));
  }, [dispatch, status, startDate, endDate, buyerRepType, sellerRepType, source]);

  useEffect(() => {
    // preload all the closed transaction without resetting the current group, so we can calculate stats.
    const options = { status: NON_ACTIVE_FLAG, buyer: buyerRepType, seller: sellerRepType, startDate, endDate, source };
    dispatch(getTransactions(options, false));
  }, [dispatch, startDate, endDate, buyerRepType, sellerRepType, source]);

  useEffect(() => {
    setStartDate(
      mountedStartDate ||
        lastSearchDates?.dateFrom ||
        format(getDateFromToday(parseInt(dateFrom)), DATE_FORMATS.ISO_DATE)
    );

    setEndDate(
      mountedEndDate || lastSearchDates?.dateTo || format(getDateFromToday(parseInt(dateTo)), DATE_FORMATS.ISO_DATE)
    );
  }, [mountedStartDate, mountedEndDate, dateFrom, dateTo, lastSearchDates]);

  // For keeping local buyerRepType in sync with mountedBuyer
  useEffect(() => {
    setBuyerRepType(mountedBuyer === 'true' || (mountedBuyer == null && true));
    setSellerRepType(mountedSeller === 'true' || (mountedSeller == null && true));
    setSource(mountedSource || '');
  }, [mountedBuyer, mountedSeller, mountedSource]);

  // Update currentGroup for display based on filter
  useEffect(() => {
    const newGroup = buildTransactionsGroupName({
      status,
      startDate,
      endDate,
      seller: sellerRepType,
      buyer: buyerRepType,
      source
    });

    // group name format: ${status}::${repType}::${startDate}::${endDate}
    const newGroupArray = newGroup.split('::');
    const newStatus = newGroupArray[0];
    const newRepType = newGroupArray[1];
    const newStartDate = newGroupArray[2];
    const newEndDate = newGroupArray[3];
    const newSource = newGroupArray[4];
    const current = currentGroup.split('::');
    const currentStatus = current[0];
    const currentRepType = current[1];
    const currentStartDate = current[2];
    const currentEndDate = current[3];
    const currentSource = current[4];

    // validate dates from path
    const isDateValid = validateDateRange(startDateInputRef, newStartDate, newEndDate, dispatch);
    if (!isDateValid) {
      return;
    }

    // Check if any of the filter has changed
    if (
      (newStatus !== currentStatus && TRANSACTION_ALL_STATUSES.some(i => i?.id.toString() === newStatus)) ||
      newRepType !== currentRepType ||
      newStartDate !== currentStartDate ||
      newEndDate !== currentEndDate ||
      newSource !== currentSource
    ) {
      dispatch(setCurrentTransactionStatus(newGroup));
    }
  }, [currentGroup, dispatch, status, startDate, endDate, sellerRepType, buyerRepType, source]);

  useEffect(() => {
    dispatch(getContactLeadSourcePicklist());
  }, [dispatch]);

  const commissionGroupName = { startDate, endDate, source, buyer: buyerRepType, seller: sellerRepType };
  // ALL_ACTIVE_STATUS includes `active`, `active under contact` and `pending`.
  // There is no unrealized commission when the status is closed.
  const unrealizedCommission =
    status === NON_ACTIVE_FLAG
      ? 0
      : getTotalCommission(
          groups[
            buildTransactionsGroupName({
              status,
              ...commissionGroupName
            })
          ],
          entities
        );
  const realizedCommission = getTotalCommission(
    groups[
      buildTransactionsGroupName({
        status: NON_ACTIVE_FLAG,
        ...commissionGroupName
      })
    ],
    entities
  );
  // Projected commission should include both realized and unrealized commission
  const projectedCommission = realizedCommission + unrealizedCommission;

  const handleChange = (e, type) => {
    // type: `status`
    const currentParams = parseParamsStr(location.search) || {};
    let statusItem;
    if (type === 'status') {
      statusItem = TRANSACTION_ALL_STATUSES.find(item => item.value === e.target.value);
    } else {
      statusItem = displayLeadSourcePicklist.find(item => item.value === e.target.value);
    }
    const { id: newStatus } = statusItem;
    const newParams = { ...currentParams, [type]: newStatus };

    // Hide default dropdown value from path
    if (newStatus === 0 || newStatus === '') {
      delete newParams[type];
    }
    const newParamsStr = `?${serializeToParamsStr(newParams)}`;
    const url = Object.keys(newParams).length > 0 ? `${TRANSACTIONS_URL}${newParamsStr}` : TRANSACTIONS_URL;
    navigate(url);
  };

  const getOppositeLabel = label => {
    const newLabel = label === 'buyer' ? 'seller' : 'buyer';

    return newLabel;
  };

  const handleRepTypeChange = (value, label) => {
    // label: `buyer` or `seller`
    const currentParams = parseParamsStr(location.search) || {};
    const { [label]: oldParams, ...updatedParams } = currentParams;

    updatedParams[label] = value.toString();

    const { buyer: updatedBuyer = 'false', seller: updatedSeller = 'true', ...newParams } = updatedParams;

    // If both the updated values of buyer and seller are unchecked, we check the previously unchecked,
    // leaving the newly unchecked as unchecked. Doing so keeps at least one toggle checked.
    if (updatedBuyer === 'false' && updatedSeller === 'false') {
      const oppositeLabel = getOppositeLabel(label);
      newParams[oppositeLabel] = 'true';

      const swappedLabel = getOppositeLabel(oppositeLabel);
      newParams[swappedLabel] = 'false';
    } else {
      newParams.buyer = updatedBuyer;
      newParams.seller = updatedSeller;
    }

    const hasParams = Object.keys(newParams).length > 0;
    const newParamsStr = hasParams ? `?${serializeToParamsStr(newParams)}` : '';

    navigate(`${TRANSACTIONS_URL}${newParamsStr}`);
  };

  const toggleFormDialog = e => {
    toggleTransactionFormDialog(e, dispatch, location);
  };

  const toggleAddForm = e => {
    e.preventDefault();

    dispatch({
      type: TRANSACTIONS_ADD_PARTY_FORM_TOGGLE
    });
  };

  const handleImport8iClosings = () => {
    dispatch(import8iClosings()).then(() => {
      dispatch(clearAlert());
    });
  };

  const confirmImport8iClosings = () => {
    dispatch(
      showAlert({
        message: 'Are you sure you want to import your 8i closings?',
        hint: 'Only closed/paid closings and their notes will be imported. Associated tasks will be excluded.',
        icon: 'housedownarrow',
        iconSize: 'm',
        primaryButtonLabel: 'Import 8i Closings',
        primaryButtonHandler: handleImport8iClosings
      })
    );
  };

  const showImportButton =
    isTabletPortraitAndUp &&
    ((isTeamOrPartnershipAccount && isResponsibleAgent) || (!isTeamOrPartnershipAccount && !isAssistant));

  const isUpdateMode = !!updateTransactionId;
  const dialogTitle = isUpdateMode ? 'Update transaction' : 'Add transaction';

  const detailsOpen = pathname.startsWith('/transactions/'); // isDetailsPage

  return (
    <View isDetailsOpen={detailsOpen}>
      <ViewHeader title="Transactions" titleIcon="transaction" titleAs="h1">
        <ButtonGroup>
          {showImportButton && (
            <Button
              disabled={has8iImportBeenTriggered}
              icon="housedownarrow"
              label="Import 8i Closings"
              ariaLabel="Import 8i Closings"
              onClick={confirmImport8iClosings}
              title={has8iImportBeenTriggered ? 'Import already started' : ''}
            />
          )}
          <Button
            label="Add Transaction"
            ariaLabel="Add transaction"
            data-cy="addTransactionButton"
            onClick={toggleFormDialog}
            styleType="primary"
          />
        </ButtonGroup>
      </ViewHeader>
      <TabContainer>
        <div className={tabContainerStyles.content}>
          <TabGroup>
            <Tab
              icon="transaction"
              id="listings"
              label="Listings"
              url={TAB.LISTINGS.url}
              matchParams={TAB.LISTINGS.matchParams}
            />
            <Tab
              icon="housecheck"
              id="closings"
              label="Closings"
              url={TAB.CLOSINGS.url}
              matchParams={TAB.CLOSINGS.matchParams}
            />
          </TabGroup>
        </div>
      </TabContainer>

      <Details className={styles.detailsContainer}>{children}</Details>

      <div className={listStyles.listActionsHeader}>
        <Filters isLoading={isLoading} isVisible={true} title="Filter transactions">
          <div className={tabContainerStyles.filterContainer}>
            {!isClosings && (
              <section className={styles.filterSection}>
                <Fragment>
                  <FormLabel htmlFor="transactionStatus" className={styles.filterLabel}>
                    Transaction status
                  </FormLabel>

                  <Dropdown
                    items={TRANSACTION_LISTINGS_STATUSES}
                    value={displayStatus}
                    id="transactionStatus"
                    onChange={e => handleChange(e, 'status')}
                    size="auto"
                  />
                </Fragment>
              </section>
            )}
            <section className={styles.filterSection}>
              <FormLabel htmlFor="transactionRepType" className={styles.filterLabel}>
                Representation type
              </FormLabel>
              <div className={styles.toggleGroup}>
                <FormToggle
                  id="repTypeBuyer"
                  ariaLabel="Buyer"
                  label="Buyer"
                  isChecked={buyerRepType}
                  changeHandler={value => handleRepTypeChange(value, 'buyer')}
                  className={styles.formToggle}
                />
                <FormToggle
                  id="repTypeSeller"
                  ariaLabel="Seller"
                  label="Seller"
                  isChecked={sellerRepType}
                  changeHandler={value => handleRepTypeChange(value, 'seller')}
                  className={styles.formToggle}
                />
              </div>
            </section>
            <section className={styles.filterSection}>
              <FormLabel htmlFor="transactionDateRange" className={styles.filterLabel}>
                {dateRangeLabel}
              </FormLabel>
              <CustomDateRangeForm
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                location={location}
                startDateInputRef={startDateInputRef}
                isClosings={isClosings}
              />
            </section>
            <section className={styles.filterSection}>
              <FormLabel htmlFor="transactionSource" className={styles.filterLabel}>
                Source
              </FormLabel>
              <Dropdown
                items={displayLeadSourcePicklist}
                value={source}
                id="transactionSource"
                onChange={e => handleChange(e, 'source')}
                size="auto"
                data-cy="transactionStatus"
              />
            </section>
          </div>
        </Filters>
      </div>
      <div className={styles.body}>
        <TransactionsList
          isLoading={isLoading}
          entities={entities}
          currentList={groups[currentGroup]}
          noResultsMessage={noResultsMessage}
          location={location}
        />
        <TransactionStats projected={projectedCommission} realized={realizedCommission} status={status} />
      </div>
      {/* Dialog shared by add and update */}
      <Suspense fallback={<Loading />}>
        <TransactionFormDialog
          clearHandler={toggleFormDialog}
          location={location}
          isOpen={toggleForm}
          title={dialogTitle}
        />
      </Suspense>

      <Dialog isOpen={toggleAddPartyForm}>
        <DialogHeader title="Add transaction party" icon="transactionparty" clearHandler={toggleAddForm} />
        <TransactionPartyForm bannerMessage={message} />
      </Dialog>
    </View>
  );
};

export default Transactions;

Transactions.propTypes = {
  location: object.isRequired
};
