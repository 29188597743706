/**
 * Checks whether a new user with no data should be prompted to setup their account more.
 * @param {Boolean} hasContacts - does the user have any contacts.
 * @param {String} pathname - is the current path the default contacts path.
 * @param {Boolean} isAssistant - is the user an assistant.
 * @param {Object} statusCounts - the statusCounts object to check all is loaded.
 */
export const shouldNewUserBePrompted = (hasContacts, pathname, isAssistant, statusCounts) => {
  return isAssistant
    ? false
    : statusCounts.all !== null && hasContacts === 0 && (pathname === '/contacts' || pathname === '/dashboard');
};
