import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Header.css';

export const Header = props => {
  const { children, className } = props;

  if (!children) {
    return null;
  }

  const classes = classnames({
    [styles.header]: !className,
    [className]: !!className
  });

  return <header className={classes}>{children}</header>;
};

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
