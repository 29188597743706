/** @module */

import {
  deleteFromAllGroups,
  deleteFromCollection,
  deleteFromGroup,
  getMergedEntities,
  getMergedSetOfIds,
  moveGroups,
  sortCollection
} from '../utils/collections';
import { updateContactUtility } from '../utils/contacts';

export const CONTACTS_ASSIGN_TYPES = 'CONTACTS_ASSIGN_TYPES';
export const CONTACTS_CURRENT_GROUP = 'CONTACTS_CURRENT_GROUP';
export const CONTACTS_DELETE = 'CONTACTS_DELETE';
export const CONTACTS_DELETE_FROM_ALL_GROUPS = 'CONTACTS_DELETE_FROM_ALL_GROUPS';
export const CONTACTS_IGNORE_TEMPORARILY = 'CONTACTS_IGNORE_TEMPORARILY';
export const CONTACTS_DETAILS = 'CONTACTS_DETAILS';
export const CONTACTS_DONE_PAGING = 'CONTACTS_DONE_PAGING';
export const CONTACTS_SET = 'CONTACTS_SET';
export const CONTACTS_LOADING = 'CONTACTS_LOADING';
export const CONTACTS_MOVE = 'CONTACTS_MOVE';
export const CONTACTS_STATUS_COUNTS = 'CONTACTS_STATUS_COUNTS';
export const CONTACTS_TOGGLE_DISPLAY_MASS_ASSIGN_TYPES_MODAL = 'CONTACTS_TOGGLE_DISPLAY_MASS_ASSIGN_TYPES_MODAL';
export const CONTACTS_TOGGLE_FAVORITE = 'CONTACTS_TOGGLE_FAVORITE';
export const CONTACTS_TYPE = 'CONTACTS_TYPE';
export const CONTACTS_UPDATE_CONTACT = 'CONTACTS_UPDATE_CONTACT';
export const CONTACTS_SEND_MARKET_SNAPSHOT = 'CONTACTS_SEND_MARKET_SNAPSHOT';
export const CONTACTS_LEAD_SOURCE_PICKLIST = 'CONTACTS_LEAD_SOURCE_PICKLIST';
export const CONTACTS_UPDATE_SELECTED = 'CONTACTS_UPDATE_SELECTED';
export const CONTACTS_DELETE_SELECTED = 'CONTACTS_DELETE_SELECTED';
export const CONTACTS_SHOW_EMAIL_MODAL = 'CONTACTS_SHOW_EMAIL_MODAL';
export const CONTACTS_SET_SELECT_ALL = 'CONTACTS_SET_SELECT_ALL';
export const CONTACTS_DELETE_FROM_GROUP = 'CONTACTS_DELETE_FROM_GROUP';
export const CONTACTS_SET_LAST_SEARCHED_DATE = 'CONTACTS_SET_LAST_SEARCHED_DATE';
export const CONTACTS_RESET_PAGING = 'CONTACTS_RESET_PAGING';
export const CONTACTS_ADD_LEAD_SOURCE_PICKLIST = 'CONTACTS_ADD_LEAD_SOURCE_PICKLIST';
export const CONTACTS_SET_PAGING_SIZE = 'CONTACTS_SET_PAGING_SIZE';

export const initialState = {
  entities: {},
  groups: {},
  currentGroup: null,
  statusCounts: {
    all: null,
    new: null,
    engage: null,
    active: null,
    closed: null,
    followup: '&nbsp;'
  },
  hasContacts: null,
  donePaging: {},
  cursors: {},
  ignoreData: {
    ignoredContactsArray: [],
    deleteTimestamp: null
  },
  isLoading: false,
  types: {
    entities: {},
    groups: {},
    currentGroup: null
  },
  leadSourcePicklist: [],
  currentlySelected: [],
  displayMassEmailModal: false,
  selectAll: false,
  lastSearchedDates: null,
  pageSize: 25
};

/**
 * The contacts redux reducer.
 * @param {Object} state - the current state of the contacts store.
 * @param {Object} action - the action to take on the contacts store
 * @param {String} [action.type=default] - the action to take.
 * @param {Boolean} [action.isPaging] - Denotes whether the request data is due to paging.
 * @param {String} [action.group] - Acts as a key when storing groups of contacts.
 * @param {Boolean} [action.isLoading] - Denotes whether the data request is in progress.
 */
export const contactReducer = (state = initialState, action = {}) => {
  const {
    type = 'default',
    contactRecords,
    contactDetails,
    cursor,
    displayContactTypeModal,
    group = state.currentGroup,
    isLoading,
    isPaging,
    contactTypes,
    contactId,
    ignoreData,
    statusCounts,
    updatedKeys,
    setCurrentGroup,
    leadSourcePicklist,
    ignorePreexisting,
    replaceGroup = false,
    updatedEntities,
    date,
    leadSource,
    pageSize
  } = action;

  switch (type) {
    case CONTACTS_TOGGLE_DISPLAY_MASS_ASSIGN_TYPES_MODAL:
      return {
        ...state,
        displayContactTypeModal
      };
    case CONTACTS_ASSIGN_TYPES:
      return {
        ...state,
        entities: updatedEntities
      };
    case CONTACTS_SET:
      return {
        ...state,
        entities: getMergedEntities(state.entities, contactRecords, {
          ignorePreexisting
        }),
        groups: {
          ...state.groups,
          [group]: replaceGroup
            ? getMergedSetOfIds([], contactRecords, { isPaging })
            : getMergedSetOfIds(state.groups[group], contactRecords, {
                isPaging
              })
        },
        currentGroup: setCurrentGroup ? group : state.currentGroup,
        cursors: {
          ...state.cursors,
          [group]: cursor || state.cursors?.[group]
        },
        isLoading: isLoading
      };
    case CONTACTS_LEAD_SOURCE_PICKLIST:
      return {
        ...state,
        leadSourcePicklist
      };
    case CONTACTS_ADD_LEAD_SOURCE_PICKLIST:
      return {
        ...state,
        leadSourcePicklist: state?.leadSourcePicklist?.some(item => item.id === leadSource.id)
          ? state?.leadSourcePicklist
          : [...state.leadSourcePicklist, leadSource]
      };
    case CONTACTS_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: group,
        isLoading: isLoading
      };
    case CONTACTS_DONE_PAGING:
      return {
        ...state,
        donePaging: {
          ...state.donePaging,
          [group]: true
        }
      };
    case CONTACTS_RESET_PAGING:
      return {
        ...state,
        donePaging: {}
      };
    case CONTACTS_SET_PAGING_SIZE:
      return {
        ...state,
        pageSize: pageSize
      };
    case CONTACTS_DETAILS:
      if (contactDetails === null) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [contactId]: {
            ...state.entities[contactId],
            ...contactDetails
          }
        }
      };
    case CONTACTS_LOADING:
      return {
        ...state,
        isLoading: isLoading
      };
    case CONTACTS_STATUS_COUNTS:
      const newStatusCounts = { ...state.statusCounts, ...statusCounts };
      const total = Object.values(newStatusCounts).reduce((acc, b) => {
        const numB = typeof b === 'string' ? 0 : b;

        acc = acc + numB;

        return acc;
      }, 0);

      return {
        ...state,
        statusCounts: {
          ...newStatusCounts
        },
        hasContacts: total
      };
    case CONTACTS_TYPE:
      const mergedEntities = getMergedEntities(state.types.entities, contactTypes, {
        idKey: 'contactType'
      });

      return {
        ...state,
        types: {
          entities: mergedEntities,
          groups: {
            ...state.types.groups,
            [group]: getMergedSetOfIds(state.types.groups[group], contactTypes, {
              idKey: 'contactType',
              entities: mergedEntities,
              sortFunction: sortCollection,
              sortKey: 'contactType',
              isPaging
            })
          },
          currentGroup: group
        }
      };
    case CONTACTS_DELETE:
      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, contactId)
        },
        groups: {
          ...state.groups,
          [group]: deleteFromGroup(state.groups[group], contactId)
        }
      };
    case CONTACTS_DELETE_FROM_ALL_GROUPS:
      return {
        ...state,
        groups: deleteFromAllGroups(state.groups, contactId)
      };
    case CONTACTS_IGNORE_TEMPORARILY:
      return {
        ...state,
        ignoreData: {
          ignoredContactsArray: state.ignoreData.ignoredContactsArray.concat(ignoreData.ignoredContactsArray),
          deleteTimestamp: ignoreData.deleteTimestamp
        }
      };
    case CONTACTS_MOVE:
      return {
        ...state,
        groups: {
          ...state.groups,
          ...moveGroups(contactId, action.newGroup, state.groups)
        }
      };
    case CONTACTS_UPDATE_CONTACT:
      return {
        ...state,
        entities: updateContactUtility(state.entities, contactId, updatedKeys)
      };
    case CONTACTS_UPDATE_SELECTED: {
      return {
        ...state,
        currentlySelected: !action.shouldReplace
          ? action.currentlySelected.length > 0
            ? [...new Set([...state.currentlySelected, ...action.currentlySelected])]
            : action.currentlySelected
          : action.currentlySelected
      };
    }
    case CONTACTS_SHOW_EMAIL_MODAL: {
      return {
        ...state,
        displayMassEmailModal: action.displayEmailModal
      };
    }
    case CONTACTS_SET_SELECT_ALL: {
      return {
        ...state,
        selectAll: action.selectAll
      };
    }
    case CONTACTS_DELETE_SELECTED: {
      return {
        ...state,
        currentlySelected: state.currentlySelected.filter(i => !action.currentlySelected.includes(i))
      };
    }
    case CONTACTS_DELETE_FROM_GROUP: {
      return {
        ...state,
        groups: {
          ...state.groups,
          [group]: deleteFromGroup(state.groups[group], contactId)
        }
      };
    }
    case CONTACTS_SET_LAST_SEARCHED_DATE: {
      return {
        ...state,
        lastSearchedDates: date
      };
    }
    case CONTACTS_SEND_MARKET_SNAPSHOT:
    default:
      return state;
  }
};
