import { Link } from '@reach/router';
import classnames from 'classnames';
import { bool, func, number, oneOf, oneOfType, string } from 'prop-types';
import { useSelector } from 'react-redux';
import React, { Fragment } from 'react';
import { Button, Icon } from '../';
import { sanitizer } from '../../utils/dom';
import { ENTITY_TYPES } from '../../utils/notes';
import { formatCount, parseTag } from '../../utils/strings';
import styles from './Tag.css';
import { TRANSACTION_STATUSES } from '../../utils/transactions';

const TAG_LABEL_LENGTH = 25;

export const Tag = props => {
  const {
    className,
    dataCy,
    entityId,
    entityType,
    label,
    handleClick,
    handleRemove,
    isVisible = true,
    removable = false,
    removeTooltip = 'Remove',
    shouldTruncateLabel = true,
    size = 's',
    styleType = 'default',
    title,
    url,
    color,
    removeTooltipPos = 'right',
    icon
  } = props;

  const tagColors = useSelector(store => store.preferences.tagColors);
  if (label === null || label === undefined || !isVisible) {
    return null;
  }

  const handleLinkClick = e => {
    e.stopPropagation();
  };

  const onHandleRemove = e => {
    if (handleRemove) {
      e.stopPropagation();

      handleRemove(e);
    }
  };

  const typedLabel = label.toString();
  // We need to check if the typedLabel is empty due to the insight being created historically without and MLS number or addressLine.
  const labelToParse = typedLabel === '::' && entityType === ENTITY_TYPES.insight ? `Property Insight` : typedLabel;

  const typesToBeParsed = [ENTITY_TYPES.insight, ENTITY_TYPES.transaction];
  const parsedLabel = parseTag(labelToParse, entityType, typesToBeParsed);

  const lowerCasedLabel = parsedLabel
    .toLowerCase()
    .trim()
    .replace(/\s/g, '')
    .replace(/,/g, '')
    .replace(/'/g, '')
    .replace('&', '');

  const lowerCasedMLSLabel = lowerCasedLabel.replace('mls', '');

  const MLS_RAW_STATUSES = TRANSACTION_STATUSES.reduce((arr, i) => {
    return [...arr, i.value.toLowerCase().replace(/\s/g, '')];
  }, []);

  // For tags in TransactionCard and PropertyInsightCard, when tpxTransactionStatus mismatches with MLS status, we display both statuses and distinguish them by adding `MLS ` to the MLS status
  // If this label is one of those, like "MLS Closed", we treat it as a MLS status tag by stripping `mls`
  // Otherwise we treat it as a regualr tag, like "MLS New York"
  const tagId = MLS_RAW_STATUSES.includes(lowerCasedMLSLabel) ? lowerCasedMLSLabel : lowerCasedLabel;

  const displayLabel =
    shouldTruncateLabel && parsedLabel.length > TAG_LABEL_LENGTH
      ? `${parsedLabel.slice(0, TAG_LABEL_LENGTH).trim()}...`
      : parsedLabel;

  const displayTitle = title || parsedLabel;

  const isCount = styleType === 'count'; // Check if "All" contacts tag and not null.

  const classes = classnames({
    [styles.tag]: !className,
    [styles[`${size}Size`]]: true,
    [styles[`${styleType}TagStyle`]]: !!styleType,
    [styles.clickable]: !!handleClick,
    [styles.removable]: !!handleRemove && removable,
    [styles.ellipsis]: shouldTruncateLabel,
    [className]: !!className
  });

  const objectClasses = classnames({
    nestedLinkWrapper: true,
    [styles.object]: true
  });

  const TagDiv = props => {
    const { children } = props;
    return (
      <div
        data-id={entityId}
        data-tag={label}
        data-type={entityType}
        title={displayTitle}
        onClick={handleClick}
        style={{ background: color || tagColors.contact?.[tagId]?.value }}
        className={classes}
        data-cy={dataCy}
      >
        {children}
      </div>
    );
  };

  const TagChildren = () => (
    <>
      {icon && <Icon name={icon} size={'xs'} />}
      <span dangerouslySetInnerHTML={{ __html: sanitizer(isCount ? formatCount(label) : displayLabel) }} />
      {removable && handleRemove && entityId && (
        <Button
          icon="close"
          size="xxxs"
          className={styles.button}
          ariaLabel={removeTooltip}
          title={removeTooltip}
          onClick={onHandleRemove}
          data-id={entityId}
          data-testid="Tag.remove"
          tooltipPos={removeTooltipPos}
        />
      )}
    </>
  );

  if (url) {
    return (
      <object className={objectClasses}>
        <TagDiv>
          <Link to={url} className={styles.tagLink} onClick={handleLinkClick}>
            <TagChildren />
          </Link>
        </TagDiv>
      </object>
    );
  }

  return (
    <TagDiv>
      <TagChildren />
    </TagDiv>
  );
};

Tag.propTypes = {
  className: string,
  entityId: string,
  entityType: number,
  handleClick: func,
  handleRemove: func,
  isVisible: bool,
  label: oneOfType([string, number]),
  removable: bool,
  removeTooltip: string,
  shouldTruncateLabel: bool,
  size: oneOf(['s', 'l']),
  styleType: oneOf(['default', 'count']),
  color: string
};
