/** @module */

import { showMessage } from '../../src/actions/message';
import { USER_PROFILE_SET_INFO } from '../reducers/userProfile';
import { SETTINGS_EULA_UPDATE } from '../reducers/settings';
import { request, requestError } from '../utils';

/**
 * Updates user profile information
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to send mapping data.
 * @param {String} [options.userInfo] - Updating user info data.
 */
export const updateUserInfo = options => {
  const { userInfo } = options;

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'userProfile',
      method: 'POST',
      path: 'users/current',
      payload: userInfo,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (data) {
        await dispatch(getUserInfo({ forceRefresh: true }));

        dispatch(showMessage({ message: 'Your info saved successfully.', type: 'success' }, true)); // useTimer set to true

        return true;
      }

      return false;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Gets user profile information
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to send mapping data
 * @param {String} [options.userId] - the id of the user.
 * @param {String} [options.tokenId] - the token id of the user.
 */
export function getUserInfo(options) {
  const { forceRefresh = false, shouldBeCached = true, headers } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'userProfile',
      forceRefresh,
      path: 'users/current',
      shouldBeCached,
      headers
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: USER_PROFILE_SET_INFO,
        userInfo: data
      });
      return Promise.resolve(data);
    } catch (error) {
      requestError(error, dispatch);
    }
  };
}

/**
 * Get Eula Document
 *
 * If fails, shows error message via <Toast />.
 * @param {String} userid - userId of current user.
 * @param {String} feature - feature for which eula to be fetched.
 */
export const getEula = ({ userid, feature = 'SMS' }) => {
  return async dispatch => {
    const options = {
      apiServiceType: 'userProfile',
      method: 'GET',
      params: { userid, feature },
      path: 'UserEula'
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      //Dispatch action to save eula in store
      dispatch({
        type: SETTINGS_EULA_UPDATE,
        eula: { [feature]: data[0] }
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Update Eula Document
 *
 * If fails, shows error message via <Toast />.
 * @param {Boolean} accepted - true
 * @param {String} feature - feature for which eula to be fetched.
 */
export const updateEula = ({ accepted, feature = 'SMS' }) => {
  return async dispatch => {
    const options = {
      apiServiceType: 'userProfile',
      method: 'POST',
      path: 'UserEula',
      payload: { accepted, feature },
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      //Dispatch action to save eula in store
      dispatch({
        type: SETTINGS_EULA_UPDATE,
        eula: { [feature]: data }
      });

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};
