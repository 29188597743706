import { Transforms } from 'slate';

import { EditorCommands } from './editor-commands';
import { cleanAndDeserializeForSlate } from './editor-utils';
import { REGEX } from '../../constants';
import { isImageUrl } from '../../utils/image';
import { validateUrl } from '../../utils/strings';

export const withImages = editor => {
  const { insertData, isInline, isVoid } = editor;

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.isInline = element => {
    return element.type === 'image' ? true : isInline(element);
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split('/');

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result;
            EditorCommands.insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      EditorCommands.insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const withLinks = editor => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element);
  };

  editor.insertText = text => {
    if (text && validateUrl(text, REGEX.URL_SIMPLE_CASE_INSENSITIVE)) {
      EditorCommands.wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && validateUrl(text)) {
      EditorCommands.wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const withHtml = editor => {
  const { insertData } = editor;

  editor.insertData = data => {
    const html = data.getData ? data.getData('text/html') : data;

    if (html) {
      const slateFragment = cleanAndDeserializeForSlate(html);
      Transforms.insertFragment(editor, slateFragment);
      // After inserting the fragment pasted, we make sure to move the cursor forward, so that the last element pasted isn't selected by default.
      Transforms.move(editor, { distance: 1 });
      return;
    }

    insertData(data);
  };

  return editor;
};

export const withTemplates = editor => {
  const { isInline } = editor;

  editor.isInline = element => {
    return element.type === 'template' ? true : isInline(element);
  };

  return editor;
};
