export const getDefaultLayout = hasSnapshot => {
  const defaultLgLayout = [
    { i: 'followupCoach', x: 0, y: 0, w: 3, h: 2 },
    { i: 'salesStats', x: 3, y: 0, w: 1, h: 2 },
    { i: 'tasks', x: 0, y: 2, w: 4, h: 3 },
    { i: 'newContacts', x: 0, y: 5, w: hasSnapshot ? 3 : 4, h: 3 },
    ...(hasSnapshot ? [{ i: 'msStats', x: 4, y: 5, w: 1, h: 3 }] : []),
    {
      i: 'recentActivity',
      x: 0,
      y: 7,
      w: 4,
      h: 2
    },
    { i: 'listings', x: 0, y: 9, w: 3, h: 3 },
    { i: 'commissions', x: 3, y: 9, w: 1, h: 3 }
  ];
  const defaultSmLayout = [
    { i: 'followupCoach', x: 0, y: 0, w: 2, h: 5 },
    { i: 'salesStats', x: 0, y: 2, w: 2, h: 2 },
    { i: 'tasks', x: 0, y: 4, w: 2, h: 3 },
    { i: 'newContacts', x: 0, y: 7, w: 2, h: 3 },
    ...(hasSnapshot ? [{ i: 'msStats', x: 0, y: 10, w: 2, h: 2 }] : []),
    {
      i: 'recentActivity',
      x: 0,
      y: hasSnapshot ? 12 : 10,
      w: 2,
      h: 2
    },
    { i: 'listings', x: 0, y: hasSnapshot ? 14 : 12, w: 2, h: 3 },
    { i: 'commissions', x: 0, y: hasSnapshot ? 16 : 14, w: 2, h: 3 }
  ];
  return {
    lg: defaultLgLayout,
    md: defaultLgLayout,
    sm: defaultSmLayout,
    xs: defaultSmLayout
  };
};

export const getMinMaxForLayout = layouts => {
  const standardMinMax = { minW: 2, maxW: 4, minH: 2, maxH: 4 };
  const narrowMinMax = { ...standardMinMax, minW: 1 };
  const tallMinMax = { ...standardMinMax, minH: 3, minW: 1 };
  const shortMinMax = { ...standardMinMax, maxH: 3 };

  const smallScreenMinMax = { minW: 2, maxW: 2, minH: 2, maxH: 5 };

  // Common function to apply min/max for lg and md layouts
  const applyMinMax = items =>
    items.map(item => {
      const { i: id } = item;
      if (['salesStats', 'commissions'].includes(id)) {
        return { ...item, ...narrowMinMax };
      }
      if (['msStats'].includes(id)) {
        return { ...item, ...tallMinMax };
      }
      if (['recentActivity'].includes(id)) {
        return { ...item, ...shortMinMax };
      }
      if (['followupCoach'].includes(id)) {
        return { ...item, ...standardMinMax, minW: 3, maxH: 3 };
      }
      if (['tasks'].includes(id)) {
        return { ...item, ...standardMinMax, minH: 3 };
      }

      return { ...item, ...standardMinMax };
    });

  const applyMinMaxMobile = items =>
    items.map(item => {
      const { i: id } = item;
      if (['tasks'].includes(id)) {
        return { ...item, ...smallScreenMinMax, minH: 3 };
      }
      return { ...item, ...smallScreenMinMax };
    });
  return {
    lg: applyMinMax(layouts.lg),
    md: applyMinMax(layouts.md),
    sm: applyMinMaxMobile(layouts.sm),
    xs: applyMinMaxMobile(layouts.xs)
  };
};
