import React, { Fragment, useEffect, useState, useRef } from 'react';
import { number, bool, string, func, array } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';

import { DATE_FORMATS } from '../../constants';
import { buildTestId } from '../../utils/tests';
import { applyPlan, getPlans, getPlanItemsChronologically, massApplyPlan } from '../../actions/taskPlans';
import { Button } from '../Button';
import { PlansCallToAction } from '../CallToAction/PlansCallToAction';
import { Form } from '../Form';
import { FormDateInput } from '../FormDateInput';
import { FormFooter } from '../FormFooter';
import { FormFieldset } from '../FormFieldset';
import { FormLabel } from '../FormLabel';
import { FormRow } from '../FormRow';
import { Loading } from '../Loading';
import { Panel } from '../Panel';
import { PlanFormField } from '../PlanFormField/PlanFormField';

import styles from './ApplyPlanForm.css';
import formFieldStyles from '../FormField/FormField.css';
import { Tag } from '../Tag';
import { sortCollection } from '../../utils';
import { convertRecentContactsToEntities } from '../../utils/contacts';

export const ApplyPlanForm = props => {
  const {
    categoryId,
    disabled = false,
    formIsSubmitting = false,
    isLoading = false,
    ownerId,
    submitCallback,
    selectedContacts,
    handleRemoveContact,
    testId = 'ApplyPlanForm'
  } = props;

  const formRef = useRef(null);

  const [formIsValid, setFormIsValid] = useState(true);

  // Start date:
  const [startDate, setStartDate] = useState(format(Date.now(), DATE_FORMATS.ISO_DATETIME_CALENDAR));

  // Plans
  const plansAreDoneLoading = useSelector(state => state.taskPlans.doneLoading[categoryId]);
  const entities = useSelector(state => state.taskPlans.entities) || {};
  const list = useSelector(state => state.taskPlans.groups[categoryId]) || [];

  // Settings
  const userProfile = useSelector(store => store.userProfile);
  const emailSettings = useSelector(store => store.settings.email);
  const textingSettings = useSelector(store => store.settings.texting);

  // Filter out the lead response plans
  const plans = list.map(id => entities[id]).filter(item => item.autoPlanType == null);
  const hasPlans = list.length > 0;

  const [planId, setPlanId] = useState(null);

  // Plan Items
  const items = useSelector(store => store.taskPlans.entities?.[planId]?.items);
  const itemsAreDoneLoading = useSelector(store => store.taskPlans.itemsAreDoneLoading?.[planId]) || false;
  const currentList = items;

  // Contacts
  const contactEntities = useSelector(store => store.contacts.entities);
  const recentContactEntities = convertRecentContactsToEntities(useSelector(store => store.preferences.recentContacts));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlans(categoryId));
  }, [dispatch, categoryId]);

  useEffect(() => {
    dispatch(getPlanItemsChronologically(planId));
  }, [dispatch, planId]);

  const handlePlanSelect = plan => {
    setPlanId(plan?.id);
    setFormIsValid(true);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (formRef.current.checkValidity()) {
      if (ownerId) {
        await dispatch(applyPlan(planId, startDate, categoryId, ownerId));
        submitCallback(planId);
      }

      if (selectedContacts?.length) {
        await dispatch(massApplyPlan(planId, selectedContacts, startDate));
        submitCallback();
      }
    } else {
      setFormIsValid(false);
    }
  };

  const deleteContactHandler = contactId => {
    handleRemoveContact(contactId);
  };

  const displaySelectedContactList = () => {
    const combinedEntities = { ...recentContactEntities, ...contactEntities };

    if (selectedContacts) {
      const sortedContacts = sortCollection(selectedContacts, combinedEntities, {
        sortKey: 'fullName',
        direction: 'asc'
      });

      return (
        <FormFieldset label="Selected contacts">
          <FormRow>
            <div className={styles.contactRow}>
              <div className={formFieldStyles.field}>
                <div className={formFieldStyles.labelWrap}>
                  <FormLabel>
                    <Tag label={`${selectedContacts.length} selected`} />
                  </FormLabel>
                </div>
              </div>
              <div className={styles.selectedContacts}>
                {sortedContacts.map(contactId => (
                  <div className={styles.contactItem}>
                    <span className={styles.name}>{combinedEntities[contactId].fullName}</span>

                    {selectedContacts.length > 1 && (
                      <Button
                        ariaLabel="Remove contact"
                        icon="delete"
                        onClick={() => deleteContactHandler(contactId)}
                        tooltipPos="left"
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </FormRow>
        </FormFieldset>
      );
    }

    return null;
  };

  return (
    <Fragment>
      {hasPlans ? (
        <Form data-testid={`${testId}-Form`} id="applyPlanForm" ref={formRef}>
          {displaySelectedContactList()}

          <FormFieldset label="Plan details">
            <PlanFormField
              categoryId={categoryId}
              currentList={currentList}
              labelForLookupField="Choose a plan"
              isLoading={!itemsAreDoneLoading}
              onSelect={handlePlanSelect}
              onRemove={handlePlanSelect}
              plans={plans}
              isValid={formIsValid}
              required
              testId={buildTestId(testId, 'PlanFormFieldset')}
              userProfile={userProfile}
              emailSettings={emailSettings}
              textingSettings={textingSettings}
            />
            <FormRow>
              <div className={formFieldStyles.field}>
                <FormLabel htmlFor="date" required>
                  Start Date
                </FormLabel>
                <FormDateInput
                  id="date"
                  placeholder={DATE_FORMATS.INPUT_PLACEHOLDER}
                  timestamp={startDate}
                  onDateChange={setStartDate}
                  disabled={formIsSubmitting}
                  required
                />
              </div>
            </FormRow>
          </FormFieldset>
        </Form>
      ) : plansAreDoneLoading ? (
        <PlansCallToAction />
      ) : (
        <Panel className={styles.loadingPanel}>
          <Loading />
        </Panel>
      )}
      {hasPlans && (
        <FormFooter loading={isLoading}>
          <Button
            type="submit"
            form="applyPlanForm"
            label="Apply Plan"
            ariaLabel="Apply plan."
            styleType="primary"
            onClick={handleSubmit}
            disabled={formIsSubmitting || disabled}
            data-cy="applyPlanFromForm"
          />
        </FormFooter>
      )}
    </Fragment>
  );
};

ApplyPlanForm.propTypes = {
  categoryId: number.isRequired,
  disabled: bool,
  formIsSubmitting: bool,
  isLoading: bool,
  ownerId: string,
  removeContactHandler: func,
  selectedContacts: array,
  submitCallback: func.isRequired,
  testId: string
};
