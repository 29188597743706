import React from 'react';
import { string, oneOf, oneOfType, number, bool } from 'prop-types';
import classnames from 'classnames';

import { Icon } from '../';

import styles from './FormRadio.css';
import buttonStyles from '../Button/Button.css';

export const FormRadio = React.forwardRef((props, ref) => {
  const {
    className,
    icon,
    id,
    label,
    labelClassName,
    name,
    size = 'm',
    title,
    tooltipPos = 'below',
    value,
    checked,
    ...attrs
  } = props;

  const labelSize = icon ? 's' : size;

  const showTooltip = icon && !label;

  const classes = classnames({
    [styles.radio]: true,
    [styles.withIcon]: !!icon && !!label,
    [styles.iconOnly]: !!icon && label == null,
    [buttonStyles.showTooltip]: showTooltip,
    [styles[`${tooltipPos}TooltipPos`]]: showTooltip && !!tooltipPos,
    [className]: !!className
  });

  const labelClasses = classnames({
    [styles.label]: true,
    [styles[`${labelSize}Size`]]: true,
    [labelClassName]: !!labelClassName
  });

  return (
    <div className={classes} {...(showTooltip ? { 'aria-label': title } : { title })}>
      <input
        ref={ref}
        type="radio"
        id={id}
        name={name}
        value={value}
        className={styles.input}
        {...attrs}
        checked={checked}
      />
      <label htmlFor={id} className={labelClasses}>
        <Icon name={icon} size="s" className={styles.radioIcon} />
        {label}
      </label>
    </div>
  );
});

FormRadio.propTypes = {
  className: string,
  icon: string,
  id: string.isRequired,
  label: string,
  name: string,
  size: oneOf(['xs', 's', 'm']),
  value: oneOfType([string, number, bool]).isRequired
};
