import { navigate } from '@reach/router';
import { array, bool, object, string } from 'prop-types';
import React, { Fragment, Suspense, useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dropdown,
  Form,
  FormDateInput,
  FormField,
  FormFieldGroup,
  FormFieldReadOnly,
  FormFieldset,
  FormFooter,
  FormHint,
  FormLabel,
  FormRadio,
  FormRadioGroup,
  FormRow
} from '../';
import { addContact, getContactLeadSourcePicklist, saveContactLeadSource } from '../../actions/contacts';

import { toggleTransactionForm } from '../../actions/properties';
import { addTransactionParty, saveTransaction } from '../../actions/transactions';
import { Loading } from '../../components';
import { UNICODE } from '../../constants';
import { PROPERTY_INSIGHTS_TRANSACTION_FORM_TOGGLE } from '../../reducers/propertyInsights';
import { TRANSACTIONS_ADD_PARTY_FORM_TOGGLE } from '../../reducers/transactions';
import { formatLongDate, parseParamsStr } from '../../utils';
import { focusFirstInvalidField } from '../../utils/dom';
import { getSelectedValue } from '../../utils/contacts';
import { REFERRAL_FEE_TYPE } from '../../utils/transactions';
import { isNonNegativeFloat, isNonNegativePercentage, serializeToParamsStr } from '../../utils/strings';
import { delay } from '../../utils/timer';
import {
  BUYER_PRIMARY_PROPERTY_OPTION_TYPE,
  COMMISSION_RATE_TYPE,
  didDatesChange,
  didStopReminderChange,
  getTransactionCardData,
  getTransactionPartyRoleOptions,
  getTransactionPrice,
  REPRESENTATION_TYPES,
  TRANSACTION_STATUSES,
  TRANSACTION_PARTY_ROLES
} from '../../utils/transactions';
import { isFieldFilled } from '../../utils/validation';
import PropertyInsightCard from '../Card/PropertyInsightCard';
import ContactForm from '../ContactForm/ContactForm';
import { Dialog, DialogHeader } from '../Dialog';
import formFieldStyles from '../FormField/FormField.css';
import { FormToggle } from '../FormToggle';
import { Lookup } from '../Lookup';
import { Tag } from '../Tag';
import styles from './AddTransactionForm.css';
import { ManualAddTransactionForm } from './ManualAddTransactionForm';
import { getInitialState } from './manualAddTransactionFormInitialState';
import {
  IS_FORM_SUBMITTING,
  IS_FORM_VALID,
  reducer,
  SAVE_TEMP_CONTACT,
  SET_ADDRESS_FIELDS,
  SET_FIELDS,
  TOGGLE_DIALOG,
  UPDATE_LISTING
} from './manualAddTransactionFormReducer';

const TextInputField = props => {
  const { data, fieldIsValid, formIsSubmitting, formIsValid, ...otherProps } = props;
  const { label, key, input, size = 'm', type = 'input', ref } = data;
  const { disabled, onChange, value } = input;

  return (
    <FormField
      fieldIsValid={fieldIsValid}
      formIsValid={formIsValid}
      id={key}
      label={label}
      onChange={onChange}
      value={value}
      disabled={disabled || formIsSubmitting}
      ref={ref}
      size={size}
      type={type}
      {...otherProps}
    />
  );
};

const DateInputField = props => {
  const { data, formIsSubmitting, formIsValid, handleChange } = props;
  const { label, key, date } = data;
  return (
    <div className={formFieldStyles.field}>
      <FormLabel htmlFor={key} required={date.isRequired}>
        {label}
      </FormLabel>
      <FormDateInput
        id={key}
        timestamp={date.value}
        onDateChange={e => handleChange(e, key)}
        disabled={formIsSubmitting}
        formIsValid={formIsValid}
        showInvalid={!date.isValid}
      />
    </div>
  );
};

const AddTransactionForm = props => {
  const dispatch = useDispatch();
  const transactions = useSelector(state => state.transactions);
  const userInfo = useSelector(state => state.userProfile.userInfo);
  const { currentGroup, updateTransactionId, entities } = transactions;
  const { insightId, transactionPartyContactId } = useSelector(state => state.propertyInsights);
  const { propertyId, contactId: propertyContactId } = useSelector(state => state.properties);
  const { entities: contactEntities, currentGroup: currentContactGroup } = useSelector(state => state.contacts);
  const leadSourcePicklist = useSelector(state => state.contacts.leadSourcePicklist);

  const commissionRateTypeRef = useRef(null);
  const fileNumberRef = useRef(null);
  const lockboxRef = useRef(null);
  const showingInstructionsRef = useRef(null);
  const termsRef = useRef(null);
  const contactLookupRef = useRef();
  const contactRoleRef = useRef();
  const sourceRef = useRef();
  const { data, contactId: propContactId, focusId, formRef, location } = props;

  const hasContactEntity = transactionPartyContactId || propContactId || propertyContactId;

  const isUpdateMode = !!updateTransactionId;
  const isAddFromInsightsMode = !!insightId;
  const isAddFromPropertyMode = !!propertyId;
  const buttonText = isUpdateMode ? 'Update transaction' : 'Add transaction';

  const entity = !isAddFromPropertyMode ? entities[updateTransactionId] : data;
  const {
    listing,
    listPrice: entityListPrice,
    salePrice: entitySalePrice,
    statusId,
    transactionParties
  } = entity || {};

  const [formState, dispatchFormState] = useReducer(reducer, {}, () =>
    getInitialState(entity, userInfo?.agentAddress, {
      contactId: hasContactEntity,
      contactRole: (() => {
        if (hasContactEntity != null) {
          if (isAddFromPropertyMode) {
            return TRANSACTION_PARTY_ROLES.Primary_seller.label;
          }
          // From insight or linked contact
          return TRANSACTION_PARTY_ROLES.Primary_buyer.label;
        }
        return '';
      })(),
      contactSource: hasContactEntity ? contactEntities[hasContactEntity].source : null
    })
  );

  const { fields, isFormValid, isFormSubmitting, showAddContactDialog, tempContact } = formState || {};

  const {
    address: manualAddress,
    mlsNumber: manualMlsNumber,
    listingDate: manualListingDate,
    listPrice: manualListingPrice,
    beds: manualBed,
    baths: manualBath,
    sqft: manualSqft,
    yearBuilt: manualYearBuilt,
    representationTypeId,
    commissionRateType,
    stopReminder,
    signPlaced,
    expirationDate,
    reminderDate,
    inspectionDate,
    offerDate,
    offerExpirationDate,
    acceptanceDate,
    contractAgreementDate,
    financialCommitmentDate,
    closingDate,
    possessionDate,
    salePrice,
    lockboxNumber,
    howToShow,
    terms,
    fileNumber,
    commissionFirstAmount,
    commissionFirstRate,
    commissionFlatAmount,
    commissionRemainderRate,
    taxRate,
    brokerSplitRate,
    buyerPrimaryPropertyOption,
    propertyType: manualPropertyType,
    rdcListingId,
    rdcPropertyId,
    contactId,
    contactRole,
    source,
    referralAmount,
    referralAmountType,
    mlsSourceId
  } = fields;

  const {
    unit: manualUnit,
    streetNumber: manualStreetNumber,
    streetName: manualStreetName,
    streetSuffix: manualStreetSuffix,
    country: manualCountry,
    state: manualStateCode,
    city: manualCity,
    postalCode: manualPostalCode,
    county: manualCounty
  } = manualAddress;

  const insightData = isUpdateMode ? listing : data[0];
  const isLookUpDataLoaded = data?.[0]?.source?.listing_id != null;

  const showManualAddForm = isUpdateMode ? listing == null : insightData == null;

  const showPropertyCard = insightData != null;

  const displayListingDate = formatLongDate(manualListingDate?.value) || UNICODE.NBSP;

  const [selectedStatus, setSelectedStatus] = useState('1');
  const transactionPartyValues = getTransactionPartyRoleOptions();
  const displayPartyValues = transactionPartyValues.filter(item => item.value.toLowerCase().includes('primary'));

  const focusAfterContactLookup = () => {
    const refFocusField = contactRoleRef.current;

    if (refFocusField) {
      refFocusField.focus();
    }
  };

  const handleRadioChange = (e, id) => {
    const { target } = e;
    if (target) {
      dispatchFormState({
        type: SET_FIELDS,
        id,
        value: {
          [id]: {
            value: target.value,
            isValid: id === 'referralAmountType' ? true : target.value !== ''
          }
        }
      });
      return;
    }
  };

  const handleToggleDialog = () => {
    dispatchFormState({
      type: TOGGLE_DIALOG
    });
  };

  const handleSaveTempContact = value => {
    dispatchFormState({
      type: SAVE_TEMP_CONTACT,
      value
    });
    if (value.source) {
      dispatchFormState({
        type: SET_FIELDS,
        id: 'source',
        value: {
          source: {
            value: value.source,
            isValid: value.source !== ''
          }
        }
      });
    }
  };

  const handleContactTypeChange = async (value = 'temp') => {
    if (value === 'import') {
      // Clear temp contact
      await handleSaveTempContact();

      const focusRef = contactLookupRef.current;

      if (focusRef) {
        // Focus on the lookup field when the temp contact is removed.
        focusRef.focus();
      }

      return;
    }

    handleToggleDialog();

    // Reset contactId in case one was imported already
    dispatchFormState({
      type: SET_FIELDS,
      id: 'contactId',
      value: {
        contactId: {
          ...contactId,
          value: null
        }
      }
    });

    dispatchFormState({
      type: SET_FIELDS,
      id: 'contactRole',
      value: {
        contactRole: {
          ...contactRole,
          isRequired: true
        }
      }
    });
  };

  const handleChange = (value, id) => {
    dispatchFormState({
      type: SET_FIELDS,
      id,
      value: {
        [id]: {
          value,
          isValid: value !== ''
        }
      }
    });
  };

  const handleContactLookupChange = selectedEntity => {
    const tags = Object.values(selectedEntity);
    const contactEntity = tags[0];
    const { id } = contactEntity || {};

    dispatchFormState({
      type: SET_FIELDS,
      id: 'contactId',
      value: {
        contactId: {
          value: id,
          isValid: true
        }
      }
    });

    dispatchFormState({
      type: SET_FIELDS,
      id: 'contactRole',
      value: {
        contactRole: {
          ...contactRole,
          isRequired: !!id
        }
      }
    });

    if (contactEntities[contactEntity?.id]?.source) {
      dispatchFormState({
        type: SET_FIELDS,
        id: 'source',
        value: {
          source: {
            value: contactEntities[contactEntity.id].source,
            isValid: contactEntities[contactEntity.id].source !== ''
          }
        }
      });
    }

    // On change of the contact, we choose which field to focus.
    focusAfterContactLookup();
  };

  const handleRoleChange = e => {
    const { target } = e;
    const { selectedIndex } = target;
    const { value } = target[selectedIndex];

    handleChange(value, 'contactRole');

    // If the representationTypeId isn't set to Buyer and Seller
    if (representationTypeId.value !== '3') {
      const repTypeId = value.toLowerCase().includes('seller') ? 2 : 1; // Only Primary buyer and Primary seller are options at this point.
      handleChange(repTypeId.toString(), 'representationTypeId');
    }
  };

  const handleFormFieldChange = e => {
    const { target } = e;
    const { id, value } = target;

    const stateKey = id;

    dispatchFormState({
      type: SET_FIELDS,
      id: stateKey,
      value: {
        [stateKey]: {
          value,
          isValid: !fields[stateKey].isRequired || value !== ''
        }
      }
    });
  };

  useEffect(() => {
    if (isLookUpDataLoaded) {
      dispatchFormState({
        type: UPDATE_LISTING,
        value: insightData
      });
    }

    const isDisabled = !showManualAddForm && !isLookUpDataLoaded && !isUpdateMode;
    dispatchFormState({ type: IS_FORM_SUBMITTING, value: isDisabled });
  }, [isLookUpDataLoaded, insightData, isUpdateMode, showManualAddForm]);

  useEffect(() => {
    const focusRefsById = {
      commissionRateTypeRadioFlat: commissionRateTypeRef.current,
      fileNumber: fileNumberRef.current,
      lockboxNumber: lockboxRef.current,
      showingInstructions: showingInstructionsRef.current,
      terms: termsRef.current,
      source: sourceRef.current
    };

    const focusRef = focusRefsById[focusId] || contactLookupRef.current || contactRoleRef.current;

    if (focusRef) {
      setTimeout(() => {
        /*
          Note: we could get around this setTimeout hack if we did focus at the FormTextInput level,
          but that might get overly verbose. Without it, the focussed element may not be on screen.
        */
        focusRef.focus();
      }, 300);
    }

    dispatch(getContactLeadSourcePicklist()); // Get lead sources.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update field values accordingly if in update mode
  useEffect(() => {
    if (isUpdateMode) {
      // ToDo: This setup causes a React warning.  It should probably be moved to useReducer.
      setSelectedStatus(statusId);
    }
  }, [isUpdateMode, statusId]);

  const isClosedSelected = selectedStatus.toString() === '4';

  useEffect(() => {
    if (isClosedSelected && buyerPrimaryPropertyOption.value === '') {
      handleChange('0', 'buyerPrimaryPropertyOption');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosedSelected, buyerPrimaryPropertyOption]);

  // Grouping date fields together helps us to find out wether
  // we need to refresh transaction tasks after an update or not.
  const dateFields = {
    listingDate: {
      label: 'Listing Date',
      key: 'listingDate',
      date: manualListingDate,
      disabled: false
    },
    contractAgreementDate: {
      label: 'Contract Agreement Date',
      key: 'contractAgreementDate',
      date: contractAgreementDate,
      disabled: false
    },
    expirationDate: { label: 'Expiration Date', key: 'expirationDate', date: expirationDate, disabled: false },
    financialCommitmentDate: {
      label: 'Financial Commitment Date',
      key: 'financialCommitmentDate',
      date: financialCommitmentDate,
      disabled: false
    },
    reminderDate: { label: 'Expiration Reminder Date', key: 'reminderDate', date: reminderDate, disabled: false },
    inspectionDate: { label: 'Inspection Date', key: 'inspectionDate', date: inspectionDate, disabled: false },
    offerDate: { label: 'Offer Date', key: 'offerDate', date: offerDate, disabled: false },
    offerExpirationDate: {
      label: 'Offer Expiration Date',
      key: 'offerExpirationDate',
      date: offerExpirationDate,
      disabled: false
    },
    acceptanceDate: { label: 'Acceptance Date', key: 'acceptanceDate', date: acceptanceDate, disabled: false },
    closingDate: { label: 'Closing Date', key: 'closingDate', date: closingDate, disabled: false },
    possessionDate: { label: 'Possession Date', key: 'possessionDate', date: possessionDate, disabled: false }
  };

  const textInputFields = {
    commissionFirstAmount: {
      key: 'commissionFirstAmount',
      label: 'First Amount',
      input: commissionFirstAmount,
      size: 's'
    },
    commissionFirstRate: {
      key: 'commissionFirstRate',
      label: 'First %',
      input: commissionFirstRate,
      size: 's'
    },
    commissionRemainderRate: {
      key: 'commissionRemainderRate',
      label: 'Remainder %',
      input: commissionRemainderRate,
      size: 's'
    },
    commissionFirstRemainderFlat: {
      label: 'Flat %'
    },
    commissionFlatAmount: {
      key: 'commissionFlatAmount',
      label: 'Dollar amount',
      input: commissionFlatAmount,
      size: 's'
    },
    brokerSplitRate: { key: 'brokerSplitRate', label: 'Broker Split %', input: brokerSplitRate, size: 's' },
    taxRate: { key: 'taxRate', label: 'Tax %', input: taxRate, size: 's' },
    salePrice: { key: 'salePrice', label: 'Sale Price', input: salePrice },
    fileNumber: { key: 'fileNumber', label: 'File Number', input: fileNumber, ref: fileNumberRef },
    lockboxNumber: { key: 'lockboxNumber', label: 'Lockbox Number', input: lockboxNumber, ref: lockboxRef },
    showingInstructions: {
      key: 'howToShow',
      label: 'Showing Instructions',
      input: howToShow,
      type: 'textarea',
      ref: showingInstructionsRef
    },
    terms: { key: 'terms', label: 'Terms', input: terms, type: 'textarea', ref: termsRef },
    referralAmount: {
      key: 'referralAmount',
      label: Object.values(REFERRAL_FEE_TYPE).find(i => i.id.toString() === referralAmountType.value)?.value || '',
      input: referralAmount,
      size: 's'
    }
  };

  /**
   * Check if the transaction has required parties when updating to closed.
   */
  const validateSubmission = () => {
    // When closing a transaction, ask the user to add transaction parties if needed
    if (isUpdateMode && isClosedSelected) {
      const hasPrimaryParties = (() => {
        const hasPrimarySeller = !!transactionParties?.find(
          party => party?.roleId === TRANSACTION_PARTY_ROLES.Primary_seller.value
        );
        const hasPrimaryBuyer = !!transactionParties?.find(
          party => party?.roleId === TRANSACTION_PARTY_ROLES.Primary_buyer.value
        );

        const repType = representationTypeId.value;
        if (repType === REPRESENTATION_TYPES.Buyer.value.toString()) {
          return hasPrimaryBuyer;
        }
        if (repType === REPRESENTATION_TYPES.Seller.value.toString()) {
          return hasPrimarySeller;
        }
        return hasPrimaryBuyer && hasPrimarySeller;
      })();

      if (!hasPrimaryParties) {
        dispatch({
          type: TRANSACTIONS_ADD_PARTY_FORM_TOGGLE,
          transactionId: updateTransactionId,
          message: `This transaction doesn't have the required primary transaction party for changing it to Closed status.`
        });
        return false;
      }
    }
    return true;
  };

  const checkCommissionFieldsAreValid = fields => {
    const {
      brokerSplitRate,
      commissionFirstAmount,
      commissionFirstRate,
      commissionRemainderRate,
      commissionFlatAmount,
      price,
      taxRate
    } = fields;

    let areCommissionFieldsValid = true;

    const setFieldInvalid = id => {
      dispatchFormState({
        type: SET_FIELDS,
        id: id,
        value: {
          [id]: {
            ...formState.fields[id],
            isValid: false
          }
        }
      });

      // Return helps us set areCommissionFieldsValid boolean;
      return false;
    };

    if (isFieldFilled(commissionFirstAmount) && commissionFirstAmount >= price) {
      areCommissionFieldsValid = setFieldInvalid('commissionFirstAmount');
    }

    if (isFieldFilled(commissionFirstRate) && !isNonNegativePercentage(commissionFirstRate)) {
      areCommissionFieldsValid = setFieldInvalid('commissionFirstRate');
    }

    if (isFieldFilled(commissionRemainderRate) && !isNonNegativePercentage(commissionRemainderRate)) {
      areCommissionFieldsValid = setFieldInvalid('commissionRemainderRate');
    }

    if (isFieldFilled(brokerSplitRate) && !isNonNegativePercentage(brokerSplitRate)) {
      areCommissionFieldsValid = setFieldInvalid('brokerSplitRate');
    }

    if (isFieldFilled(taxRate) && !isNonNegativePercentage(taxRate)) {
      areCommissionFieldsValid = setFieldInvalid('taxRate');
    }

    if (isFieldFilled(commissionFlatAmount) && !isNonNegativeFloat(commissionFlatAmount)) {
      areCommissionFieldsValid = setFieldInvalid('commissionFlatAmount');
    }

    return areCommissionFieldsValid;
  };

  const checkReferralFieldsAreValid = fields => {
    const { referralAmountType, referralAmount } = fields;

    // - default
    if (!isFieldFilled(referralAmount)) {
      return true;
    }

    const isValid =
      referralAmountType === '1' ? isNonNegativeFloat(referralAmount) : isNonNegativePercentage(referralAmount);

    dispatchFormState({
      type: SET_FIELDS,
      id: 'referralAmount',
      value: {
        referralAmount: {
          ...formState.fields.referralAmount,
          isValid
        }
      }
    });

    return isValid;
  };

  const checkFieldsAreValid = fields => {
    const { address, commission, referral, textInputFields, restOfFields } = fields;
    let areAllFieldsValid = true;

    // Check and set all address fields for validity.
    Object.keys(address).map(item => {
      const isValid = address[item].isRequired
        ? !!address[item].value && address[item].value !== '' && address[item].isValid
        : true;

      if (!isValid) {
        // We need to make sure that all fields are valid, so we need to use a local var to keep track.
        areAllFieldsValid = false;
      }

      dispatchFormState({
        type: SET_ADDRESS_FIELDS,
        id: item,
        value: {
          [item]: {
            value: address[item].value,
            isValid
          }
        }
      });
    });

    const commissionsAreValid = checkCommissionFieldsAreValid({
      ...commission,
      price: getTransactionPrice(getTransactionCardData(insightData, entity), entitySalePrice, entityListPrice)
    });

    const referralsAreValid = checkReferralFieldsAreValid(referral);

    const textInputFieldsAreValid = Object.keys(textInputFields).every(key => textInputFields[key].isValid);

    areAllFieldsValid = areAllFieldsValid && commissionsAreValid && referralsAreValid && textInputFieldsAreValid;

    // Check and set all other fields for validity.
    Object.keys(restOfFields).map(item => {
      const isValid = restOfFields[item].isRequired
        ? restOfFields[item].value !== '' && restOfFields[item].isValid
        : restOfFields[item].isValid;

      if (!isValid) {
        // We need to make sure that all fields are valid, so we need to use a local var to keep track.
        areAllFieldsValid = false;
      }

      dispatchFormState({
        type: SET_FIELDS,
        id: item,
        value: {
          [item]: {
            value: restOfFields[item].value,
            isValid
          }
        }
      });
    });

    return areAllFieldsValid;
  };

  const setFormInvalid = async value => {
    dispatchFormState({ type: IS_FORM_VALID, value });
    // A 0 ms delay to ensure we can focus the field.
    await delay();
    // Give focus to the first invalid field in the form.
    focusFirstInvalidField('addTransactionForm');
  };

  const checkIsImportContact = async () => {
    if (tempContact == null) {
      return Promise.resolve();
    }
    return dispatch(addContact(tempContact, { contactCurrentGroup: currentContactGroup }))
      .then(data => {
        const { id } = data || {};

        return Promise.resolve(id);
      })
      .catch(() => {
        return Promise.reject();
      });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const {
      address,
      brokerSplitRate,
      commissionFirstAmount,
      commissionFirstRate,
      commissionRemainderRate,
      commissionFlatAmount,
      taxRate,
      commissionRateType,
      referralAmount,
      referralAmountType,
      howToShow,
      terms,
      ...restOfFields
    } = fields;

    // We need to clean-up commission types incase entered values need to be removed based on rate type changes.
    const cleanCommissionRateType = parseInt(commissionRateType.value);
    const cleanCommissionFirstAmount = cleanCommissionRateType === 2 ? commissionFirstAmount.value : null;
    const cleanCommissionFirstRate = cleanCommissionRateType === 2 ? commissionFirstRate.value : null;
    const cleanCommissionRemainderRate = cleanCommissionRateType < 3 ? commissionRemainderRate.value : null;
    const cleanCommissionFlatAmount = cleanCommissionRateType === 3 ? commissionFlatAmount.value : null;

    const fieldsAreValid = checkFieldsAreValid({
      address,
      commission: {
        brokerSplitRate: brokerSplitRate.value,
        commissionFirstAmount: cleanCommissionFirstAmount,
        commissionFirstRate: cleanCommissionFirstRate,
        commissionRemainderRate: cleanCommissionRemainderRate,
        commissionFlatAmount: cleanCommissionFlatAmount,
        taxRate: taxRate.value
      },
      referral: {
        referralAmountType: referralAmountType.value,
        referralAmount: referralAmount.value
      },
      textInputFields: {
        howToShow,
        terms
      },
      restOfFields
    });
    const isValid = fieldsAreValid && validateSubmission() && formRef.current.checkValidity();

    setFormInvalid(isValid);
    dispatchFormState({ type: IS_FORM_SUBMITTING, value: true });

    if (source.hasCustomLeadSource && source.custom.trim() !== '') {
      dispatch(saveContactLeadSource({ itemValue: source.custom }));
    }

    if (!isValid) {
      dispatchFormState({ type: IS_FORM_SUBMITTING, value: false });

      return;
    }

    const payload = {
      address: {
        unit: manualUnit.value,
        houseNumber: manualStreetNumber.value,
        streetName: manualStreetName.value,
        streetType: manualStreetSuffix.value,
        city: manualCity.value,
        state: manualStateCode.value,
        country: manualCountry.value,
        zip: manualPostalCode.value,
        county: manualCounty.value
      },
      listingDate: manualListingDate.value,
      listPrice: manualListingPrice.value,
      mlsNumber: manualMlsNumber.value,
      mlsSourceId: mlsSourceId.value,
      propertyType: manualPropertyType.value,
      rdcListingId: rdcListingId.value,
      rdcPropertyId: rdcPropertyId.value,
      statusId: selectedStatus,
      salePrice: salePrice.value,
      contractAgreementDate: contractAgreementDate.value,
      closingDate: closingDate.value,
      financialCommitmentDate: financialCommitmentDate.value,
      possessionDate: possessionDate.value,
      representationTypeId: representationTypeId.value,
      expirationDate: expirationDate.value,
      reminderDate: reminderDate.value,
      inspectionDate: inspectionDate.value,
      offerDate: offerDate.value,
      offerExpirationDate: offerExpirationDate.value,
      acceptanceDate: acceptanceDate.value,
      stopReminder: stopReminder.value,
      signPlaced: signPlaced.value,
      lockboxNumber: lockboxNumber.value,
      terms: terms.value,
      howToShow: howToShow.value,
      fileNumber: fileNumber.value,
      commissionRateType: cleanCommissionRateType,
      commissionFirstAmount: cleanCommissionFirstAmount,
      commissionFirstRate: cleanCommissionFirstRate,
      commissionFlatAmount: cleanCommissionFlatAmount,
      commissionRemainderRate: cleanCommissionRemainderRate,
      taxRate: taxRate.value,
      brokerSplitRate: brokerSplitRate.value,
      buyerPrimaryPropertyOption: buyerPrimaryPropertyOption.value,
      baths: manualBath.value,
      beds: manualBed.value,
      source: source.value !== 'Other' ? source.value : fields.source.custom,
      sqft: manualSqft.value,
      year_built: manualYearBuilt.value,
      referralAmountType: referralAmountType.value,
      referralAmount: referralAmount.value
    };

    // referralAmount is optional but type is preselected, delete them from payload if amount is empty
    if (referralAmount.value == null || referralAmount.value === '') {
      delete payload.referralAmountType;
      delete payload.referralAmount;
    }

    if (isUpdateMode) {
      const options = {
        currentGroup,
        listing: insightData,
        mode: 'update',
        shouldRefreshTaskDates: didDatesChange(entity, dateFields),
        shouldRefreshTasks: didStopReminderChange(entity, dateFields)
      };

      dispatch(saveTransaction({ ...payload, transactionId: updateTransactionId }, options)).catch(() => {
        dispatchFormState({ type: IS_FORM_SUBMITTING, value: false });
      });
      return;
    }

    const options = {
      currentGroup,
      listing: insightData,
      mode: isAddFromInsightsMode ? 'insight' : isAddFromPropertyMode ? 'property' : 'add'
    };

    const hasTransactionParty =
      isAddFromInsightsMode ||
      (contactId.value && contactId.value !== '' && contactRole.value && contactRole.value !== '') ||
      tempContact != null;

    checkIsImportContact().then(newContactId => {
      dispatch(saveTransaction(payload, options))
        .then(transactionId => {
          // A new transaction can have a party of the transaction party fields are filled by hand or it is prepopulated from an Insight.
          if (hasTransactionParty) {
            // contactId
            const cId = transactionPartyContactId || contactId?.value || newContactId;
            // roleId
            const cRoleId = contactRole?.value
              ? transactionPartyValues.find(i => i.value === contactRole?.value).id
              : TRANSACTION_PARTY_ROLES.Primary_buyer.value;
            // Add contact and transaction as transaction party; Role falls back to primary buyer role (4);
            dispatch(addTransactionParty(transactionId, cId, cRoleId)).then(() => {
              if (isAddFromInsightsMode) {
                // The toggle action below clears transactionPartyContactId
                dispatch({
                  type: PROPERTY_INSIGHTS_TRANSACTION_FORM_TOGGLE
                });
                return;
              }
              if (isAddFromPropertyMode) {
                dispatch(toggleTransactionForm());
                return;
              }
            });
          }

          const basePath = `/transactions/${transactionId}`;
          const currentParams = parseParamsStr(location.search) || {};

          delete currentParams['mode'];

          const newParamsStr = serializeToParamsStr(currentParams);
          const newPath = location.pathname === '/transactions' ? `${basePath}?${newParamsStr}` : basePath;

          navigate(newPath);
        })
        .catch(() => {
          dispatchFormState({ type: IS_FORM_SUBMITTING, value: false });
        });
    });
  };

  const handleStatusUpdate = e => {
    e.preventDefault();
    const selected = TRANSACTION_STATUSES.find(i => i.value === e.target.value);

    const { id } = selected;

    setSelectedStatus(id.toString());
  };

  const handleLeadSourceChange = e => {
    const { target } = e;
    const { id, value } = target;

    const stateKey = id;

    dispatchFormState({
      type: SET_FIELDS,
      id: stateKey,
      value: {
        [stateKey]: {
          value,
          hasCustomLeadSource: value === 'Other'
        }
      }
    });
  };

  const handleCustomLeadSourceChange = e => {
    const { target } = e;
    const { value } = target;

    dispatchFormState({
      type: SET_FIELDS,
      id: 'source',
      value: {
        source: {
          custom: value,
          customIsValid: value.trim() !== ''
        }
      }
    });
  };

  const handleNoteValidate = (id, isValid) => {
    dispatchFormState({
      type: SET_FIELDS,
      id,
      value: {
        [id]: {
          isValid
        }
      }
    });
  };
  const displaySelected = TRANSACTION_STATUSES.find(item => item.id === parseInt(selectedStatus));
  // Show signPlaced toggle only if representationTypeId is not Buyer
  const showSignPlaced = representationTypeId.value !== REPRESENTATION_TYPES.Buyer.value.toString();

  const isFirstAndRemainder = commissionRateType.value === '2';
  const isDollarAmount = commissionRateType.value === '3';

  const firstAndRemainderField = isFirstAndRemainder
    ? textInputFields.commissionRemainderRate
    : { ...textInputFields.commissionRemainderRate, ...textInputFields.commissionFirstRemainderFlat };

  const tpxStatusObj = TRANSACTION_STATUSES.find(item => item.id === statusId);
  const tpxStatus = statusId ? tpxStatusObj.value : null;

  const cardData = getTransactionCardData(insightData, entity);
  const contactLookupEntity = contactId?.value
    ? [{ id: contactId.value, type: TRANSACTION_PARTY_ROLES.Primary_buyer.value }]
    : null;

  const renderLeadSources = () => {
    let selectedLeadSource;
    let displayList;

    // EDGE CASE: When lead is imported from external systems, the Source value is only associated to the contact record.
    // We're having to manually add the source to the list here. Yucky edge case hack. Ideally should be handled by BE.
    if (source.value !== '' && typeof leadSourcePicklist.find(item => item.value === source.value) === 'undefined') {
      const clonedLeadSourcePickList = [...leadSourcePicklist];
      clonedLeadSourcePickList.push({ id: source.value, value: source.value });
      displayList = clonedLeadSourcePickList;
      selectedLeadSource = getSelectedValue(source, clonedLeadSourcePickList);
    } else {
      displayList = leadSourcePicklist;
      selectedLeadSource = getSelectedValue(source, leadSourcePicklist);
    }

    if (leadSourcePicklist.length) {
      return (
        <Fragment>
          <FormRow>
            <div className={formFieldStyles.field}>
              <Dropdown
                id="source"
                items={displayList}
                value={selectedLeadSource ? selectedLeadSource.value : ''}
                defaultValue="Select a source..."
                ref={sourceRef}
                onChange={handleLeadSourceChange}
                disabled={isFormSubmitting}
              />
            </div>
            {source.value === 'Other' ? (
              <FormField
                type="input"
                inputType="text"
                id="otherSource"
                placeholder="Source"
                onChange={handleCustomLeadSourceChange}
                disabled={isFormSubmitting}
                fieldIsValid={source.customIsValid}
                formIsValid={isFormValid}
                required
              />
            ) : null}
          </FormRow>
          <FormHint>
            <strong>Note:</strong> Select "Other" to add a new source.
          </FormHint>
        </Fragment>
      );
    }

    return <Loading />;
  };

  return (
    <Fragment>
      <Form id="addTransactionForm" ref={formRef} autoComplete="off">
        <input hidden readOnly /> {/* This hidden input is to allow autocomplete off to work */}
        {showPropertyCard && (
          <div className={styles.propertyCardWrap}>
            <PropertyInsightCard
              address={entity?.address}
              data={cardData}
              tpxTransactionListPrice={entityListPrice}
              tpxTransactionSalePrice={entitySalePrice}
              tpxTransactionStatus={tpxStatus}
            />
          </div>
        )}
        {!isUpdateMode && (
          <FormFieldset label="Transaction party">
            <FormRow>
              <div className={formFieldStyles.field}>
                <FormLabel htmlFor="contactLookup">Contact</FormLabel>
                <FormFieldGroup className={styles.transactionPartyGroup}>
                  {tempContact ? (
                    <Tag
                      key="tempContactTag"
                      entityId="tempContactTag"
                      handleClick={handleToggleDialog}
                      handleRemove={() => handleContactTypeChange('import')}
                      label={`${tempContact?.primaryPerson.firstName} ${tempContact?.primaryPerson.lastName}`}
                      removable={true}
                      size="l"
                    />
                  ) : (
                    <Lookup
                      contacts={contactEntities}
                      changeHandler={handleContactLookupChange}
                      entities={contactLookupEntity}
                      fieldIsValid={contactId.isValid}
                      formIsValid={isFormValid}
                      formIsSubmitting={isFormSubmitting}
                      id="contactLookup"
                      isMultiSelect={false}
                      externalRef={contactLookupRef}
                    />
                  )}

                  {!contactLookupEntity && (
                    <Fragment>
                      {!tempContact && <span className={styles.textJoin}>OR</span>}
                      <Button
                        ariaLabel={tempContact == null ? 'Add New Contact' : 'Edit Contact'}
                        label={tempContact == null ? 'Add New Contact' : 'Edit Contact'}
                        labelShort={tempContact == null ? 'Add New' : 'Edit'}
                        disabled={isFormSubmitting}
                        icon="contact"
                        onClick={tempContact == null ? handleContactTypeChange : handleToggleDialog}
                        styleType="secondary"
                      />
                    </Fragment>
                  )}
                </FormFieldGroup>
              </div>
            </FormRow>
            <FormRow>
              <div className={formFieldStyles.field}>
                <FormLabel htmlFor="transactionPartyRole" required={contactRole.isRequired}>
                  Transaction Role
                </FormLabel>
                <Dropdown
                  defaultValue=" "
                  items={displayPartyValues}
                  value={contactRole.value}
                  id="transactionPartyRole"
                  onChange={handleRoleChange}
                  // formIsSubmitting={isFormSubmitting}
                  fieldIsValid={contactRole.isValid}
                  formIsValid={isFormValid}
                  ref={contactRoleRef}
                />
              </div>
            </FormRow>
          </FormFieldset>
        )}
        <ManualAddTransactionForm
          data={formState}
          dispatchFormState={dispatchFormState}
          isFormSubmitting={isFormSubmitting}
        />
        <FormFieldset label="Transaction details">
          <FormRow>
            <div className={formFieldStyles.field}>
              <FormLabel htmlFor="representationTypeRadio" required={representationTypeId.isRequired}>
                Representation Type
              </FormLabel>

              <FormRadioGroup
                name="representationTypeRadio"
                fieldIsValid={representationTypeId.isValid}
                formIsValid={isFormValid}
              >
                {Object.values(REPRESENTATION_TYPES).map(item => {
                  const { value, label } = item;
                  const idKey = `representationTypeRadio${label}`;
                  return (
                    <FormRadio
                      disabled={isFormSubmitting}
                      key={idKey}
                      id={idKey}
                      label={label}
                      value={value}
                      onChange={e => handleRadioChange(e, 'representationTypeId')}
                      checked={representationTypeId.value === value.toString()}
                      size="xs"
                    />
                  );
                })}
              </FormRadioGroup>
            </div>
          </FormRow>
          <FormRow>
            <div className={formFieldStyles.field}>
              <FormLabel htmlFor="transactionStatusId" required>
                Transaction Status
              </FormLabel>
              <Dropdown
                disabled={isFormSubmitting}
                items={TRANSACTION_STATUSES}
                value={displaySelected ? displaySelected.value : null}
                id="transactionStatusId"
                onChange={handleStatusUpdate}
                formIsValid={isFormValid}
                data-cy="transactionStatusId"
              />
            </div>
          </FormRow>
          {isClosedSelected && (
            <FormRow>
              <div className={formFieldStyles.field}>
                <FormLabel htmlFor="buyerPropOption" required={buyerPrimaryPropertyOption.isRequired}>
                  For the Buyer, add this property as type
                </FormLabel>

                <FormRadioGroup name="buyerPropOption">
                  {Object.keys(BUYER_PRIMARY_PROPERTY_OPTION_TYPE).map(item => {
                    const { id, value } = BUYER_PRIMARY_PROPERTY_OPTION_TYPE[item];
                    const idKey = `buyerPropOption${value}`;
                    return (
                      <FormRadio
                        disabled={isFormSubmitting}
                        key={idKey}
                        id={idKey}
                        label={value}
                        value={id}
                        onChange={e => handleRadioChange(e, 'buyerPrimaryPropertyOption')}
                        checked={buyerPrimaryPropertyOption.value === id.toString()}
                        size="xs"
                      />
                    );
                  })}
                </FormRadioGroup>
              </div>
            </FormRow>
          )}
          <FormRow>
            <div className={formFieldStyles.field}>
              <TextInputField
                data={textInputFields.salePrice}
                formIsSubmitting={isFormSubmitting}
                formIsValid={isFormValid}
                onChange={handleFormFieldChange}
              />
              <FormHint>
                <strong>Note:</strong> Use Sale Price to capture an agreed upon price for the listing prior to close -
                your projected commissions will be adjusted accordingly.
              </FormHint>
            </div>
          </FormRow>
          <FormRow>
            <TextInputField
              data={textInputFields.fileNumber}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              onChange={handleFormFieldChange}
            />
          </FormRow>
          <FormRow>
            <TextInputField
              data={textInputFields.lockboxNumber}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              onChange={handleFormFieldChange}
            />
          </FormRow>
          {showSignPlaced && (
            <FormRow>
              <div className={formFieldStyles.field}>
                <FormLabel htmlFor="signPlaced">Sign placed?</FormLabel>
                <FormToggle
                  id="signPlaced"
                  ariaLabel="signPlaced"
                  label="Yes"
                  isChecked={signPlaced.value}
                  changeHandler={e => handleChange(e, `signPlaced`)}
                  formIsValid={isFormValid}
                />
              </div>
            </FormRow>
          )}
        </FormFieldset>
        <FormFieldset label="Key dates">
          <FormRow>
            {!showManualAddForm && insightData?.list_date ? (
              <FormFieldReadOnly id="listingDateReadOnly" label="Listing Date" value={displayListingDate} />
            ) : (
              <DateInputField
                data={dateFields.listingDate}
                formIsSubmitting={isFormSubmitting}
                formIsValid={isFormValid}
                handleChange={handleChange}
              />
            )}
          </FormRow>
          <FormRow>
            <DateInputField
              data={dateFields.contractAgreementDate}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              handleChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <DateInputField
              data={dateFields.expirationDate}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              handleChange={handleChange}
            />
            <DateInputField
              data={dateFields.reminderDate}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              handleChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <DateInputField
              data={dateFields.offerDate}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              handleChange={handleChange}
            />
            <DateInputField
              data={dateFields.offerExpirationDate}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              handleChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <DateInputField
              data={dateFields.inspectionDate}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              handleChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <DateInputField
              data={dateFields.acceptanceDate}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              handleChange={handleChange}
            />
            <DateInputField
              data={dateFields.financialCommitmentDate}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              handleChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <DateInputField
              data={dateFields.closingDate}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              handleChange={handleChange}
            />
            <DateInputField
              data={dateFields.possessionDate}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              handleChange={handleChange}
            />
          </FormRow>
        </FormFieldset>
        <FormFieldset label="Commission structure">
          <FormRow>
            <div className={formFieldStyles.field}>
              <FormLabel htmlFor="commissionRateTypeRadio" required>
                Rate Type
              </FormLabel>
              <FormRadioGroup name="commissionRateTypeRadio">
                {Object.keys(COMMISSION_RATE_TYPE).map(item => {
                  const { id, value } = COMMISSION_RATE_TYPE[item];
                  const idKey = `commissionRateTypeRadio${value}`;
                  const ref = id === 1 ? commissionRateTypeRef : null;
                  return (
                    <FormRadio
                      disabled={isFormSubmitting}
                      key={idKey}
                      id={idKey}
                      label={value}
                      value={id}
                      onChange={e => handleRadioChange(e, 'commissionRateType')}
                      checked={commissionRateType.value === id.toString()}
                      ref={ref}
                      size="xs"
                    />
                  );
                })}
              </FormRadioGroup>
            </div>
          </FormRow>
          <FormRow>
            {isFirstAndRemainder && (
              <Fragment>
                <TextInputField
                  data={textInputFields.commissionFirstAmount}
                  fieldIsValid={commissionFirstAmount.isValid}
                  formIsSubmitting={isFormSubmitting}
                  formIsValid={isFormValid}
                  onChange={handleFormFieldChange}
                  validationMessage="Invalid"
                />
                <TextInputField
                  data={textInputFields.commissionFirstRate}
                  fieldIsValid={commissionFirstRate.isValid}
                  formIsSubmitting={isFormSubmitting}
                  formIsValid={isFormValid}
                  onChange={handleFormFieldChange}
                  validationMessage="Invalid"
                />
              </Fragment>
            )}
            {isDollarAmount ? (
              <TextInputField
                data={textInputFields.commissionFlatAmount}
                fieldIsValid={commissionFlatAmount.isValid}
                formIsSubmitting={isFormSubmitting}
                formIsValid={isFormValid}
                onChange={handleFormFieldChange}
                validationMessage="Invalid"
              />
            ) : (
              <TextInputField
                data={firstAndRemainderField}
                fieldIsValid={commissionRemainderRate.isValid}
                formIsSubmitting={isFormSubmitting}
                formIsValid={isFormValid}
                onChange={handleFormFieldChange}
                validationMessage="Invalid"
              />
            )}
          </FormRow>
          <FormRow>
            <TextInputField
              data={textInputFields.brokerSplitRate}
              fieldIsValid={brokerSplitRate.isValid}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              onChange={handleFormFieldChange}
              validationMessage="Invalid"
            />
            <TextInputField
              data={textInputFields.taxRate}
              fieldIsValid={taxRate.isValid}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              onChange={handleFormFieldChange}
              validationMessage="Invalid"
            />
          </FormRow>
        </FormFieldset>
        <FormFieldset label="Referral fee">
          <FormRow>
            <div className={formFieldStyles.field}>
              <FormLabel htmlFor="referralAmountType">Referral Type</FormLabel>
              <FormRadioGroup name="referralAmountType">
                {Object.keys(REFERRAL_FEE_TYPE).map(item => {
                  const { id, value } = REFERRAL_FEE_TYPE[item];
                  const idKey = `referralAmountType${value}`;

                  return (
                    <FormRadio
                      disabled={isFormSubmitting}
                      key={idKey}
                      id={idKey}
                      label={value}
                      value={id}
                      onChange={e => handleRadioChange(e, 'referralAmountType')}
                      checked={referralAmountType.value === id.toString()}
                      size="xs"
                    />
                  );
                })}
              </FormRadioGroup>
            </div>
          </FormRow>

          <FormRow>
            <TextInputField
              data={textInputFields.referralAmount}
              fieldIsValid={referralAmount.isValid}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              onChange={handleFormFieldChange}
              validationMessage="Invalid"
            />
          </FormRow>
        </FormFieldset>
        <FormFieldset label="Source">
          <div>{renderLeadSources()}</div>
        </FormFieldset>
        <FormFieldset label="Tasks">
          <FormRow>
            <FormToggle
              id={`stopReminder`}
              ariaLabel="stopReminder"
              label="Hide transaction tasks from Calendar and Tasks"
              isChecked={stopReminder.value}
              changeHandler={e => handleChange(e, 'stopReminder')}
              formIsValid={isFormValid}
            />
          </FormRow>
        </FormFieldset>
        <FormFieldset label="Terms and instructions">
          <FormRow>
            <TextInputField
              data={textInputFields.showingInstructions}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              maxLength="500"
              onChange={handleFormFieldChange}
              onValidate={isValid => handleNoteValidate('howToShow', isValid)}
              fieldIsValid={fields.howToShow.isValid}
            />
          </FormRow>
          <FormRow>
            <TextInputField
              data={textInputFields.terms}
              formIsSubmitting={isFormSubmitting}
              formIsValid={isFormValid}
              maxLength="500"
              onChange={handleFormFieldChange}
              onValidate={isValid => handleNoteValidate('terms', isValid)}
              fieldIsValid={fields.terms.isValid}
            />
          </FormRow>
        </FormFieldset>
      </Form>
      <FormFooter loading={false}>
        <Button
          ariaLabel={buttonText}
          type="button"
          label={buttonText}
          disabled={isFormSubmitting}
          onClick={handleSubmit}
          styleType="primary"
        />
      </FormFooter>
      <Dialog isOpen={showAddContactDialog}>
        <DialogHeader title="Add contact" icon="addcontact" clearHandler={handleToggleDialog} />
        <Suspense>
          <ContactForm
            handleSaveTempContact={handleSaveTempContact}
            saveCallback={handleToggleDialog}
            contact={tempContact}
            isQuickAdd={true}
          />
        </Suspense>
      </Dialog>
    </Fragment>
  );
};

export default AddTransactionForm;

AddTransactionForm.propTypes = {
  contactId: string,
  data: array.isRequired,
  formRef: object.isRequired,
  formIsSubmitting: bool,
  location: object.isRequired
};
