/** @module */

import {
  deleteFromCollection,
  deleteFromGroup,
  getMergedEntities,
  getMergedInternalFields,
  getMergedSetOfIds
} from '../utils';

export const SETTINGS_UPLOAD_CONTACTS_FILE = 'SETTINGS_UPLOAD_CONTACTS_FILE';
export const SETTINGS_UPLOAD_CONTACTS_PROGRESS = 'SETTINGS_UPLOAD_CONTACTS_PROGRESS';
export const SETTINGS_CONTACTS_GET_INTERNAL_FIELDS = 'SETTINGS_CONTACTS_GET_INTERNAL_FIELDS';
export const SETTINGS_IMPORT_HISTORY = 'SETTINGS_IMPORT_HISTORY';
export const SETTINGS_IMPORT_MAPPING_CONTACTS = 'SETTINGS_IMPORT_MAPPING_CONTACTS';
export const SETTINGS_GOOGLE_CALENDAR_LIST = 'SETTINGS_GOOGLE_CALENDAR_LIST';
export const SETTINGS_TEXTING_DATA = 'SETTINGS_TEXTING_DATA';
export const SETTINGS_RA_TEXTING_DATA = 'SETTINGS_RA_TEXTING_DATA';
export const SETTINGS_EMAIL_ACCOUNTS = 'SETTINGS_EMAIL_ACCOUNTS';
export const SETTINGS_EMAIL_DETAILS = 'SETTINGS_EMAIL_DETAILS';
export const SETTINGS_EMAIL_ACCOUNT_DELETE = 'SETTINGS_EMAIL_ACCOUNT_DELETE';
export const SETTINGS_EMAIL_SET_UP = 'SETTINGS_EMAIL_SET_UP';
export const SETTINGS_EULA_UPDATE = 'SETTINGS_EULA_UPDATE';
export const SETTINGS_EMAIL_SIGNATURE_SAVE = 'SETTINGS_EMAIL_SIGNATURE_SAVE';

export const initialState = {
  calendarList: null,
  email: {
    entities: {},
    group: [],
    // Retrieved for nylas v3 integration
    setupEmail: null,
    v2Signature: null
  },
  eula: null,
  importHistory: null,
  importStatus: null,
  internalFields: null,
  mappingData: null,
  progressStatus: null,
  texting: null,
  responsibleAgentTexting: null
};

/**
 * The settings redux reducer.
 * @param {Object} state - the current state of the settings store.
 * @param {Object} action - the action to take on the settings store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.mappingData] - Request mapping data to save to the store.
 * @param {Boolean} [action.importStatus] - Status of the import action.
 * @param {String} [action.internalFields] - Internal contacts field details.
 * @param {Boolean} [action.progressStatus] - Progress status of the uploading file.
 * @param {Array} [action.email] - Progress status of the uploading file.
 */
export const settingsReducer = (state = initialState, action = {}) => {
  const {
    type = 'default',
    progressStatus,
    mappingData,
    internalFields,
    importHistory,
    importStatus,
    calendarList,
    texting,
    email,
    emailDetails,
    emailAccountId,
    eula,
    data
  } = action || {};

  switch (type) {
    case SETTINGS_UPLOAD_CONTACTS_FILE:
      return {
        ...state,
        mappingData: mappingData,
        importStatus: null
      };
    case SETTINGS_UPLOAD_CONTACTS_PROGRESS:
      return {
        ...state,
        progressStatus: progressStatus
      };
    case SETTINGS_CONTACTS_GET_INTERNAL_FIELDS:
      return {
        ...state,
        internalFields: getMergedInternalFields(internalFields)
      };
    case SETTINGS_IMPORT_MAPPING_CONTACTS:
      return {
        ...state,
        importStatus
      };
    case SETTINGS_IMPORT_HISTORY:
      return {
        ...state,
        importHistory
      };
    case SETTINGS_GOOGLE_CALENDAR_LIST:
      return {
        ...state,
        calendarList
      };
    case SETTINGS_TEXTING_DATA:
      return {
        ...state,
        texting
      };
    case SETTINGS_RA_TEXTING_DATA:
      return {
        ...state,
        responsibleAgentTexting: texting
      };
    case SETTINGS_EMAIL_ACCOUNTS:
      return {
        ...state,
        email: {
          ...state.email,
          entities: getMergedEntities(state.email.entities, email, { idKey: 'NylasAccountId' }),
          group: getMergedSetOfIds(state.email.group, email, { idKey: 'NylasAccountId' })
        }
      };
    case SETTINGS_EMAIL_DETAILS:
      return {
        ...state,
        email: {
          ...state.email,
          entities: {
            ...state.email.entities,
            [emailAccountId]: {
              ...state.email.entities[emailAccountId],
              ...emailDetails
            }
          }
        }
      };
    case SETTINGS_EMAIL_ACCOUNT_DELETE:
      return {
        ...state,
        email: {
          ...state.email,
          entities: {
            ...deleteFromCollection(state.email.entities, emailAccountId)
          },
          group: deleteFromGroup(state.email.group, emailAccountId)
        }
      };
    case SETTINGS_EULA_UPDATE:
      if (!eula) {
        return state;
      }
      return {
        ...state,
        eula: {
          ...state.eula,
          ...eula
        }
      };
    case SETTINGS_EMAIL_SET_UP:
      return {
        ...state,
        email: {
          ...state.email,
          setupEmail: email
        }
      };
    case SETTINGS_EMAIL_SIGNATURE_SAVE:
      return {
        ...state,
        email: {
          ...state.email,
          v2Signature: data
        }
      };
    default:
      return state;
  }
};
