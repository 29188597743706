export const TIMEZONE_LIST = [
  { id: 17, value: '(GMT -12:00) Eniwetok', offset: 720 },
  { id: 16, value: '(GMT -11:00) Samoa', offset: 660 },
  { id: 7, value: '(GMT -10:00) Hawaii time', offset: 600 },
  { id: 2, value: '(GMT -09:00) Alaska time', offset: 540 },
  { id: 11, value: '(GMT -08:00) Pacific time (US & Canada); Tijuana', offset: 480 },
  { id: 9, value: '(GMT -07:00) Mountain time (US & Canada)', offset: 420 },
  { id: 3, value: '(GMT -07:00) Arizona time', offset: 420 },
  { id: 5, value: '(GMT -06:00) Central time (US & Canada)', offset: 360 },
  { id: 8, value: '(GMT -05:00) Indiana time (East)', offset: 300 },
  { id: 6, value: '(GMT -05:00) Eastern time (US & Canada)', offset: 300 },
  { id: 4, value: '(GMT -04:00) Atlantic time (Canada)', offset: 240 },
  { id: 10, value: '(GMT -03:30) Newfoundland time (Canada)', offset: 210 },
  { id: 15, value: '(GMT -03:00) Brazilia', offset: 180 },
  { id: 14, value: '(GMT -02:00) Mid-Atlantic Time', offset: 120 },
  { id: 13, value: '(GMT -01:00) Azores', offset: 60 },
  { id: 1, value: '(GMT) Universal time', offset: 0 },
  { id: 18, value: '(GMT +01:00) Rome; Central European Time (CET)', offset: -60 },
  { id: 19, value: '(GMT +02:00) Israel; Eastern European Time (EET)', offset: -120 },
  { id: 20, value: '(GMT +03:00) Moscow', offset: -180 },
  { id: 21, value: '(GMT +03:30) Tehran', offset: -210 },
  { id: 22, value: '(GMT +04:00) Baku', offset: -240 },
  { id: 23, value: '(GMT +04:30) Kabul', offset: -270 },
  { id: 24, value: '(GMT +05:00) Karachi', offset: -300 },
  { id: 25, value: '(GMT +05:30) New Delhi', offset: -330 },
  { id: 26, value: '(GMT +06:00) Dhakar', offset: -360 },
  { id: 27, value: '(GMT +06:30) Cocos Islands', offset: -390 },
  { id: 28, value: '(GMT +07:00) Bangkok', offset: -420 },
  { id: 29, value: '(GMT +08:00) Hong Kong; Western Standard Time (Australia)', offset: -480 },
  { id: 30, value: '(GMT +09:00) Tokyo', offset: -540 },
  { id: 31, value: '(GMT +09:30) Central Standard Time (Australia)', offset: -570 },
  { id: 32, value: '(GMT +09:30) Adelaide', offset: -570 },
  { id: 12, value: '(GMT +10:00) Guam, Port Moresby', offset: -600 },
  { id: 33, value: '(GMT +11:00) Magadan', offset: -660 },
  { id: 34, value: '(GMT +11:30) Norfolk Island', offset: -690 },
  { id: 35, value: '(GMT +12:00) Wellington', offset: -720 },
  { id: 36, value: '(GMT +13:00) Tonga', offset: -780 },
  { id: 37, value: '(GMT +14:00) Kiribati', offset: -840 }
];
