export const checkIsUSAgent = userProfile => {
  return userProfile?.userInfo?.isUSAgent || false;
};

/**
 * Returns whether or not agent is allowed to use texting functionality
 * @param {Object} userProfile
 * @param {Object} userProfile.userInfo
 * @param {boolean} userProfile.userInfo.isTextingAllowed
 * @returns whether or not texting is allowed for user
 */
export const checkIsTextingAllowed = userProfile => {
  return Boolean(userProfile?.userInfo?.isTextingAllowed);
};

export const setupMessages = {
  registrationDescriptionUs: `The FCC recently introduced new regulations in an effort to reduce text messaging spam and fraud. All businesses that offer texting via a software application, like Top Producer, need to register the phone numbers that are used for texting.`,
  registrationDescriptionCanada: `All businesses that offer texting via a software application, like Top Producer, need to register the phone numbers that are used for texting.`,
  registrationInstructions: `To ensure you're in compliance with all relevant laws and regulations, you need to register your phone
  number. Click Register to begin this simple process.`,
  pendingPrimary: `Thank you. We're registering your account with our texting provider to ensure you are in compliance with all relevant laws and regulations. Registration times vary based on carrier response. We will notify you once your account is active.`,
  pendingTeam: `The primary agent on your account has started the process to register. As soon as this is complete, a new texting phone number will be issued for you and shown here. Registration times vary based on carrier response. We will notify you once your account is active.`
};

export const checkIfEulaIsValid = eula => {
  const { SMS } = eula || {};

  //Check if Eula is accepted
  return SMS?.accepted === true;
};

/**
 * Check if texting is registered
 * @param {Object} texting
 * @returns
 */
export const checkTextingRegistered = texting => {
  const { registered, insider, brand } = texting || {};
  const { relayPhone, confirmStatus } = insider || {};

  return registered && relayPhone !== null && confirmStatus === 'confirmed' && brand?.campaignStatus === 'ACTIVE';
};

/**
 * Check if texting registration is in still progress
 * @param {Object} textingSetting texting settings from state
 * @returns {boolean}
 */
export const checkRegistrationInProgress = textingSetting => {
  const { registered: requestSuccess, insider, brand } = textingSetting || {};
  const { confirmStatus, relayPhone } = insider || {};
  const { campaignStatus } = brand || {};

  return (
    requestSuccess && brand && insider && (!relayPhone || confirmStatus !== 'confirmed' || campaignStatus !== 'ACTIVE')
  );
};

/**
 * Check if texting should be enabled by current user
 * @param {Object} settings
 * @param {Object} userProfile
 * @returns
 */
export const checkTextingEnabled = (settings, userProfile) => {
  const { eula, texting } = settings || {};
  const isRegistered = checkTextingRegistered(texting);
  const isTextingAllowed = checkIsTextingAllowed(userProfile);
  const isEulaValid = checkIfEulaIsValid(eula);

  return isRegistered && isTextingAllowed && isEulaValid;
};
