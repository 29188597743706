import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';
import { Icon } from '../';
import { API_BASE_URL, SVC_HOST } from '../../constants';
import { getContactInitials } from '../../utils';
import styles from './Avatar.css';

export const Avatar = props => {
  const { className, emails, name, pictureURL, hideImage = false, primaryPerson, size = 'm', ...attrs } = props;
  const [fullContactAvatarIsValid, setFullContactAvatarIsValid] = useState(true);

  useEffect(() => {
    setFullContactAvatarIsValid(true);
  }, [name]);

  const classes = classnames({
    [styles.avatar]: !className,
    [styles[`${size}Size`]]: !!size,
    [className]: !!className
  });

  const getProperUrl = url => {
    const PATH_FRAGMENT = '/Gate/Photo.aspx';

    if (url.startsWith(PATH_FRAGMENT)) {
      return `${SVC_HOST}${url}`;
    }

    if (url.startsWith(`../dataservice${PATH_FRAGMENT}`)) {
      return `${SVC_HOST}${url.split('dataservice')[1]}`;
    }
  };

  const hideAvatar = () => {
    setFullContactAvatarIsValid(false);
  };

  const renderSimple = () => {
    if (primaryPerson && /^[A-zÀ-ÖØ-öø-ÿ]+$/.test(primaryPerson.firstName.charAt(0))) {
      const { firstName, lastName } = primaryPerson;
      const firstInitial = firstName && firstName.charAt(0).toUpperCase();
      const lastInitial = lastName && lastName.charAt(0).toUpperCase();

      return (
        <Fragment>
          {firstInitial}
          {lastInitial}
        </Fragment>
      );
    }

    return <Icon name="contact" size={size} />;
  };

  const renderAvatar = () => {
    if (pictureURL) {
      const url = getProperUrl(pictureURL) || pictureURL;
      return <img src={url} alt={name} className={styles.thumbnail} />;
    }

    if (hideImage) {
      return renderSimple();
    }

    if (emails && emails.length) {
      const contactInitials = getContactInitials(primaryPerson);

      return (
        <Fragment>
          {fullContactAvatarIsValid ? (
            <img
              src={`${API_BASE_URL.edge}/image/${emails[0].value}`}
              onError={hideAvatar}
              className={styles.thumbnail}
            />
          ) : (
            [
              pictureURL ? (
                <img src={getProperUrl(pictureURL) || pictureURL} alt={name} className={styles.thumbnail} key={name} />
              ) : (
                [
                  contactInitials === undefined || contactInitials === null ? (
                    <Icon key={name} name="contact" size={size} />
                  ) : (
                    <Fragment key={name}>{contactInitials}</Fragment>
                  )
                ]
              )
            ]
          )}
        </Fragment>
      );
    }

    return renderSimple();
  };

  return (
    <div className={classes} {...attrs}>
      {renderAvatar()}
    </div>
  );
};

Avatar.propTypes = {
  emails: PropTypes.array,
  className: PropTypes.string,
  name: PropTypes.string,
  hideImage: PropTypes.bool,
  pictureURL: PropTypes.string,
  primaryPerson: PropTypes.object,
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl'])
};
