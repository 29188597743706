import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import getDate from 'date-fns/getDate';
import getDay from 'date-fns/getDay';
import getISODay from 'date-fns/getISODay';
import getMonth from 'date-fns/getMonth';
import isFuture from 'date-fns/isFuture';
import isPast from 'date-fns/isPast';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import sub from 'date-fns/sub';
import { DATE_FORMATS, UNICODE } from '../constants';
import { DATE_RANGES_FROM_NOW } from '../constants/preferences';
import { CUSTOM_REPEAT_OPTIONS, END_AFTER_OPTIONS } from '../data/activities';
import { getDayName, getSpecifiedTimeLabel, parseISODate } from './dates';
import { ENTITY_TYPES } from './notes';
import { ordinalize, parseParamsStr, pluralize } from './strings';
import { request } from '../utils';

const { EN_DASH } = UNICODE;

export const DELETE_RECURRING_MESSAGE = `You are deleting a recurring task. What tasks would you like to delete?`;
export const CONFIRM_DELETE_MSG = 'Are you sure you want to delete this task?';
export const EDIT_RECURRING_SYNC_MESSAGE =
  'You are updating a single instance of a recurring appointment. It is advised that you make this same update in your calendar sync provider to ensure the information is fully captured.';
export const TASK_TYPES_MAP = new Map([
  [0, 'all'],
  [1, 'appointment'],
  [2, 'call'],
  [3, 'todo'],
  [4, 'letter'],
  [5, 'email'],
  [6, 'postcard'],
  [7, 'envelope'],
  [8, 'label'],
  [9, 'listingTodo'],
  [10, 'closingTodo'],
  [12, 'sentEmail'],
  [13, 'receivedEmail'],
  [14, 'texting'],
  [15, 'tpxEmail'],
  [24, 'serviceReport'],
  [99, 'completed']
]);

export const TASK_TYPES = {
  all: { value: 0, label: 'All' },
  appointment: { value: 1, label: 'Appointment' },
  call: { value: 2, label: 'Call' },
  todo: { value: 3, label: 'To Do' },
  letter: { value: 4, label: 'Letter' },
  email: { value: 5, label: 'Email' },
  postcard: { value: 6, label: 'Postcard' },
  envelope: { value: 7, label: 'Envelope' },
  label: { value: 8, label: 'Label' },
  listingTodo: { value: 9, label: 'Listing To Do' }, // Used as transaction tasks
  closingTodo: { value: 10, label: 'Closing To Do' },
  sentEmail: { value: 12, label: 'Sent Email' },
  receivedEmail: { value: 13, label: 'Received Email' },
  texting: { value: 14, label: 'Text' },
  tpxEmail: { value: 15, label: 'Email' },
  serviceReport: { value: 24, label: 'Service Report' },
  completed: { value: 99, label: 'Completed' }
};

export const TASK_CATEGORIES = {
  all: 0,
  contact: 1,
  listing: 2,
  closing: 3,
  transaction: 9
};

export const WRAPUP_TYPES = {
  text: { value: 0, label: 'Text sent' },
  wrapup: { value: 1, label: 'Wrapup (first contact made)' },
  snapshot: { value: 1, label: 'Market Snapshot sent' },
  call: { value: 2, label: 'Call made' },
  email: { value: 5, label: 'Email sent' }
};

export const PRIORITY_LEVELS_MAP = new Map([
  [0, 'urgent'],
  [1, 'high'],
  [2, 'medium'],
  [3, 'low']
]);

export const PRIORITY_LEVELS = {
  urgent: { value: 0, label: 'Urgent' },
  high: { value: 1, label: 'High' },
  medium: { value: 2, label: 'Medium' },
  low: { value: 3, label: 'Low' }
};

// Field adjustEvent in a plan object
// Contacts only support: Plan_Start_Date, By_Previous_Item, and Not_Adjust
// Transactions support all.
export const TASK_PLAN_ADJUSTMENT_MAP = new Map([
  [0, 'Not_Adjust'],
  [1, 'By_Previous_Item'],
  [2, 'Plan_Start_Date'],
  [3, 'Agreement_Date'],
  [4, 'Closing_Date'],
  [5, 'Inspection_Date'],
  [6, 'Offer_Date'],
  [7, 'Offer_Expiration_Date'],
  [8, 'Listing_Date'],
  [9, 'Expiration_Date'],
  [10, 'Acceptance_Date'],
  [11, 'Possession_Date'],
  [12, 'Financial_Commitment_Date']
]);

export const TASK_PLAN_ADJUSTMENTS = {
  Not_Adjust: { value: 0, label: 'Not Adjust' },
  By_Previous_Item: { value: 1, label: 'Previous Task' },
  Plan_Start_Date: { value: 2, label: 'Plan Start Date' },
  Agreement_Date: { value: 3, label: 'Agreement Date' },
  Closing_Date: { value: 4, label: 'Closing Date' },
  Inspection_Date: { value: 5, label: 'Inspection Date' },
  Offer_Date: { value: 6, label: 'Offer Date' },
  Offer_Expiration_Date: { value: 7, label: 'Offer Expiration Date' },
  Listing_Date: { value: 8, label: 'Listing Date' },
  Expiration_Date: { value: 9, label: 'Expiration Date' },
  Acceptance_Date: { value: 10, label: 'Acceptance Date' },
  Possession_Date: { value: 11, label: 'Possession Date' },
  Financial_Commitment_Date: { value: 12, label: 'Financial Commitment Date' }
};

export const TASK_PLAN_ADJUSTMENTS_TRANSACTION_DATES = {
  Agreement_Date: 'contractAgreementDate',
  Closing_Date: 'closingDate',
  Inspection_Date: 'inspectionDate',
  Offer_Date: 'offerDate',
  Offer_Expiration_Date: 'offerExpirationDate',
  Listing_Date: 'listingDate',
  Expiration_Date: 'expirationDate',
  Acceptance_Date: 'acceptanceDate',
  Possession_Date: 'possessionDate',
  Financial_Commitment_Date: 'financialCommitmentDate'
};

// Field adjustDirection in a plan object
export const TASK_PLAN_ADJUSTMENT_DIRECTION_MAP = new Map([
  [1, 'before'],
  [2, 'on'],
  [3, 'after']
]);

export const TASK_PLAN_ADJUSTMENT_DIRECTIONS = {
  before: { value: 1, label: 'before' },
  on: { value: 2, label: 'on' },
  after: { value: 3, label: 'after' }
};

/**
 * TPX only use 1 or 3
 * Appointment task is always 1
 */
export const TIME_RANGES = {
  allDay: 0, // This value is no longer used in TPX
  timed: 1,
  restOfDay: 2,
  notTimed: 3
};

export const DEFAULT_DUE_PERIOD = {
  contact: 'currentYear',
  importantDates: 'currentYear',
  transaction: 'currentYear'
};

export const TASK_TABS_WITH_DATE_RANGE = ['custom', 'overdue'];

export const TRANSACTION_TASK_PARTY_ROLE = [
  { value: 1, title: 'Seller' },
  { value: 2, title: 'Buyer' },
  { value: 3, title: 'Buyer and seller' },
  { value: 4, title: 'Primary seller' },
  { value: 5, title: 'All sellers' },
  { value: 8, title: 'Primary buyer' },
  { value: 10, title: 'All buyers' },
  { value: 12, title: 'Primary buyer and primary seller' },
  { value: 15, title: 'All' }
];

export const TASK_PLAN_AUTO_TYPE = {
  manual: '0',
  auto: '1',
  default: '2'
};

export const TASK_PLAN_STOP_OPTIONS = {
  nonstop: '0',
  // "1" is reserved for future use
  auto: '2',
  manual: '3',
  each: '4'
};

export const DATETIME_ADJUST_OPTION = {
  default: 0,
  specifiedTime: 1
};

export const isOverdue = timestamp => {
  const parsedTimestamp = parseISODate(timestamp);
  if (isPast(parsedTimestamp) && !isToday(parsedTimestamp)) {
    return true;
  }

  return false;
};

export const getDateFromToday = (daysAgo = 0) => {
  let today = new Date();
  return today.setDate(today.getDate() + daysAgo);
};

export const getTimeRange = (start, end) => {
  if (!start && !end) {
    return null;
  }

  const startTime = start && format(parseISODate(start), DATE_FORMATS.SHORT_TIME);
  const endTime = end && format(parseISODate(end), DATE_FORMATS.SHORT_TIME);
  if ((startTime && !endTime) || startTime === endTime) {
    return startTime;
  }

  if (endTime && !startTime) {
    return endTime;
  }

  if (startTime.toLowerCase() === '12:00am' && endTime.toLowerCase() === '11:59pm') {
    return 'All day';
  }

  return `${startTime} ${EN_DASH} ${endTime}`;
};

export const getTaskTagLabels = data => {
  const { isDone, endOfEvent, planName } = data;
  const tagLabels = [];

  if (isDone) {
    tagLabels.push('Completed');
  }

  if (!isDone && isOverdue(endOfEvent)) {
    tagLabels.push('Overdue');
  }

  if (planName) {
    tagLabels.push(planName);
  }

  return tagLabels;
};

/**
 * Get the task title/description, and if empty, return placeholder title.
 * @param {string} description - the task title/description.
 */
export const getTaskDescription = description => {
  return description && description.length > 0 ? description : '<No description>';
};

/**
 * Get the task descriptionNote and account for it being the same as the description.
 * @param {string} description - the task title/description.
 * @param {string} descriptionNote - the task descriptionNote.
 */
export const getTaskDescriptionNote = (description, descriptionNote) => {
  return descriptionNote?.length > 0 && descriptionNote !== description ? descriptionNote : null;
};

export const getTaskPriorityLabel = (priority = 2) => {
  const taskPriorityLevel = PRIORITY_LEVELS[PRIORITY_LEVELS_MAP.get(parseInt(priority))];

  return taskPriorityLevel ? taskPriorityLevel.label : PRIORITY_LEVELS.medium.label;
};

export const getTaskAdjustmentLabel = (adjustEvent = 0) => {
  const taskAdjustment = TASK_PLAN_ADJUSTMENTS[TASK_PLAN_ADJUSTMENT_MAP.get(parseInt(adjustEvent))];

  return taskAdjustment ? taskAdjustment.label : TASK_PLAN_ADJUSTMENTS.Not_Adjust.label;
};

export const getTaskAdjustmentDirectionLabel = (adjustDirection = 3) => {
  const taskAdjustmentDirection =
    TASK_PLAN_ADJUSTMENT_DIRECTIONS[TASK_PLAN_ADJUSTMENT_DIRECTION_MAP.get(parseInt(adjustDirection))];

  return taskAdjustmentDirection ? taskAdjustmentDirection.label : TASK_PLAN_ADJUSTMENT_DIRECTIONS.after.label;
};

export const getTaskAdjustTimeLabel = (adjustDays, adjustHours, adjustMinutes) => {
  if (adjustMinutes != null && adjustMinutes !== 0) {
    return `${adjustMinutes} ${pluralize(adjustMinutes, 'minute')}`;
  }
  if (adjustHours != null && adjustHours !== 0) {
    return `${adjustHours} ${pluralize(adjustHours, 'hour')}`;
  }
  return `${adjustDays} ${pluralize(adjustDays, 'day')}`;
};
export const getTaskDueLabel = data => {
  const {
    adjustDirection,
    adjustEvent,
    adjustDays,
    datetimeAdjustOption,
    specifiedHour,
    specifiedMinute,
    adjustHours,
    adjustMinutes,
    dateType
  } = data || {};

  if (adjustEvent === TASK_PLAN_ADJUSTMENTS.Not_Adjust.value) {
    return 'not adjusted';
  }

  const isTransactionType = dateType != null;

  const taskAdjustment = getTaskAdjustmentLabel(adjustEvent);
  const timeLabel =
    datetimeAdjustOption === DATETIME_ADJUST_OPTION.specifiedTime
      ? getSpecifiedTimeLabel(specifiedHour, specifiedMinute)
      : getTaskAdjustTimeLabel(adjustDays, adjustHours, adjustMinutes);
  const taskAdjustmentDirection = getTaskAdjustmentDirectionLabel(adjustDirection);
  if (adjustDays === 0) {
    if (adjustHours === 0 && adjustMinutes === 0) {
      return `Immediately`;
    }

    return `${isTransactionType ? '' : `${timeLabel} `}${taskAdjustmentDirection} ${taskAdjustment}`;
  }

  const adjustment = `${taskAdjustmentDirection} ${taskAdjustment}`;

  if (adjustDirection === TASK_PLAN_ADJUSTMENT_DIRECTIONS.on.value) {
    // something like: "On Plan Start Date"
    return adjustment;
  }

  // something like: "5 days after Plan Start Date"

  const adjustmentStr = `${adjustDays} ${pluralize(adjustDays, 'day')} ${adjustment}`;

  if (isTransactionType) {
    return adjustmentStr;
  }

  if (parseInt(adjustEvent) === TASK_PLAN_ADJUSTMENTS.Plan_Start_Date.value) {
    return timeLabel;
  }

  return `${timeLabel} ${adjustmentStr}`;
};

/**
 * Returns task group based on timestamp.
 * @param {string} timestamp - timestamp returned from the API
 */
export const getTaskGroup = timestamp => {
  if (!timestamp) {
    return null;
  }

  if (isToday(timestamp)) {
    return 'today';
  }

  if (isTomorrow(timestamp)) {
    return 'tomorrow';
  }

  if (isFuture(timestamp)) {
    return 'future';
  }

  if (isPast(timestamp)) {
    return 'overdue';
  }
};

/**
 * Returns filtered set of tasks based on a duePeriod and each task's startOfEvent date.
 * @param {array} group - the current group of tasks.
 * @param {object} entities - all available task entities.
 * @param {string} duePeriod - the current duePeriod for the task list - today, tomorrow, overdue, etc.
 */
export const getTasksMatchingDuePeriod = (group, entities, duePeriod) => {
  if (!group) {
    return;
  }

  const matchingTasks = group.reduce((acc, id) => {
    const task = entities[id];

    if (!task) {
      return acc;
    }

    const taskGroup = getTaskGroup(parseISODate(task.startOfEvent));

    // The task should be shown if it has the duePeriod of current - usually only when in the task list for a given contact.
    // If duePeriod is current, it trumps all taskGroup related checks.
    // Or if the duePeriod is custom
    // Or if the duePeriod matches it's taskGroup
    // Or if it has a duePeriod of future and is not overdue
    if (
      duePeriod === 'current' ||
      duePeriod === 'currentYear' || // contact tasks
      duePeriod === 'custom' || // no defined time period
      duePeriod === taskGroup || // otherwise, we need to check it fits the timeframe
      (duePeriod === 'future' && taskGroup !== 'overdue') // Future tasks will always match tomorrow, today, tomorrow, or future
    ) {
      acc.push(id);
    }

    return acc;
  }, []);

  return matchingTasks;
};

export const getTasksByPlanIdGroupName = options => {
  const { isDone, ownerId, ownerType, planId } = options || {};
  return `task::plan::${planId}::${ownerType}::${ownerId}::${isDone}`;
};

/**
 * Get isDone value by filter status
 * @param {string} complete - 'true' or 'false'
 * @param {string} incomplete - 'true' or 'false'
 * @returns {number} - 0 for incomplete, 1 for complete, 2 for both
 */
export const getTaskFilterStatus = (complete, incomplete) => {
  if (complete === 'true' && incomplete === 'true') {
    return 2;
  }
  if (complete === 'true' && incomplete !== 'true') {
    return 1;
  }

  // default has incomplete selected
  return 0;
};

/**
 * Structure of currentTaskOptions example:
 * 0: "task"
 * 1: "9441ab00-ae1e-4a40-8728-56152133485f"
 * 2: "0"
 * 3: "0"
 * 4: "2020-11-10to2021-03-10"
 * 5: "af25c16b-cf6a-4603-af6c-b2721af5f578"
 */
const GroupOptions = {
  TYPE: 0, // 'task'
  LIST_ID: 1, // objectId or due
  ACTIVITY_TYPE: 2, // the activity type is used as filter when fetching tasks. 0 is for today business, 1 is contact, 2 is listing, 3 is closing
  IS_DONE: 3, // 0 is for incomplete tasks; 1 is for complete tasks (default=0)
  DATE_RANGE: 4, // `${dateFrom}to${dateTo}`
  ASSIGNED_TO_ID: 5 // user id to which the task is assigned
};

/**
 * Get the group name for the task list, based on the the action options and assignedToId (getTasks action parameters)
 *
 * @param {Object} actionOptions getTasks options to fetch a list of tasks
 * @param {String} [actionOptions.objectId] Id of the object that is linked to the task (Contact or Transaction)
 * @param {Number} [actionOptions.activityType=0] the activity type is used as filter when fetching tasks. 0 is for today business, 1 is contact, 2 is listing, 3 is closing
 * @param {'current'|'overdue'|'today'|'tomorrow'|'future'|'currentYear'|'custom'|'pastMonth'} [actionOptions.due='today'] due period for which we are fetching the tasks (default='today')
 * @param {0|1} [actionOptions.isDone=0] - 0 is for incomplete tasks; 1 is for complete tasks (default=0)
 * @param {String} assignedToId user id to which the task is assigned
 *
 * @returns {String} The name of the group based on the options passed
 *   (format: task::${listId}::${activityType}::${isDone}::${timeframeStr}::${assignedToId})
 *   @see GroupOptions
 */
export const buildTasksGroupName = (actionOptions, assignedToId) => {
  const { activityType = 0, due = 'today', isDone = 0, objectId } = actionOptions || {};

  // When not set directly in options of getTasks (or is from Dashboard), set default values based on due period
  const dateFrom =
    actionOptions.dateFrom && !actionOptions.setDashboardGroup
      ? actionOptions.dateFrom
      : format(parseISODate(getDateFromToday(DATE_RANGES_FROM_NOW[due].dateFrom)), DATE_FORMATS.ISO_DATE);
  const dateTo =
    actionOptions.dateTo && !actionOptions.setDashboardGroup
      ? actionOptions.dateTo
      : format(parseISODate(getDateFromToday(DATE_RANGES_FROM_NOW[due].dateTo)), DATE_FORMATS.ISO_DATE);

  const timeframeStr = `${format(parseISODate(dateFrom), DATE_FORMATS.ISO_DATE)}to${format(
    parseISODate(dateTo),
    DATE_FORMATS.ISO_DATE
  )}`;

  const listId = objectId ? objectId : due;
  const queryKey = `task::${listId}::${activityType}::${isDone}::${timeframeStr}::${assignedToId}`; // tasks can be filtered in many ways, so this is complex.

  return queryKey;
};

/**
 * Return i-th particular day of the week of the month
 * @param {Date} date
 * @return {1|2|3|4|5}
 */
export const getOrdinalWeekOfMonth = (date, isOrdinalized = true) => {
  const tempDate = new Date(date);
  // We start from the beginning of the month
  tempDate.setDate(1);
  const dayOfTheWeek = getDay(date);
  const month = getMonth(date);
  const allDays = [];

  // Find the first day of the week in the month
  while (tempDate.getDay() !== dayOfTheWeek) {
    tempDate.setDate(tempDate.getDate() + 1);
  }

  while (tempDate.getMonth() === month) {
    allDays.push(new Date(tempDate));
    tempDate.setDate(tempDate.getDate() + 7);
  }
  const index = allDays.findIndex(day => isSameDay(day, date));
  return index !== -1 ? (isOrdinalized ? ordinalize(index + 1) : (index + 1).toString()) : null;
};

/**
 * Generate the string for repeating pattern based on options
 * @param {Object} data subset of form field data
 */
export const buildRepeatingPattern = data => {
  //'1:1|2:1|3:2|9:2020-12-03|10:2020-12-07'
  // only date is used in startTime and endTime, the actual timestamp in in the payload
  const {
    date, // The task date, the date of the first repeating occurrence, use to extract day of week/month ..etc.
    repeatingType,
    intervals,
    repeatStartTime: startTime, // The start date of the repeating
    repeatEndTime: endTime,
    repeatDayOfTheWeek,
    customRepeatingType,
    customIntervals,
    customMonthlyOption,
    customEndOption,
    occurrence,
    dropdownOptions
  } = data;

  const formattedStartTime = startTime ? format(parseISODate(startTime), DATE_FORMATS.ISO_DATE) : null;
  const formattedEndTime = endTime ? format(parseISODate(endTime), DATE_FORMATS.ISO_DATE) : null;
  const parsedDate = parseISODate(date);
  const dayOfWeek = getISODay(parsedDate);
  const dayOfMonth = getDate(parsedDate);

  const ordinalWeekOfMonth = getOrdinalWeekOfMonth(parsedDate, false);

  // Month starts from 0
  const monthOfYear = getMonth(parsedDate) + 1;

  const foundRepeatType = dropdownOptions && dropdownOptions.find(item => item?.value === repeatingType);

  // repeatingType is derived FE values for different types
  // Use parsedRepeatType for raw BE value
  const parsedRepeatType = foundRepeatType?.repeatType;

  if (parsedRepeatType == null) {
    return null;
  }

  // Reused pattern
  // 1:{repeatingType}
  const base = '1:';
  const repeatingTypeStr = base + parsedRepeatType;

  const baseHasInterval = '|2:';
  const defaultHasIntervalStr = `${baseHasInterval}1`;
  const customHasIntervalStr = `${baseHasInterval}2`;

  const monthIntervalStr = `|5:${intervals}`;

  const weekOfMonthStr = `|6:${ordinalWeekOfMonth}`;
  const dayOfWeekStr = `|7:${dayOfWeek}`;
  // TODO: Make sure to change this back
  const maxValue = END_AFTER_OPTIONS[parsedRepeatType]?.max;
  const defaultOccurrenceStr = maxValue ? `|8:${maxValue}` : '';

  const occurrenceStr = repeatingType === 'custom' ? `|8:${occurrence}` : defaultOccurrenceStr;
  const startTimeStr = `|9:${formattedStartTime}`;
  const endTimeStr = `|10:${formattedEndTime}`;

  // Daily and weekly share the same pattern
  if (repeatingType === '1' || repeatingType === '2') {
    // 2:{1|2} has interval or not

    // Weekly repeatType = 2 is a special case, it uses flag `7` for interval/repeat pattern instead of `2`
    const hasIntervalStr =
      parsedRepeatType !== '2' ? (intervals !== 'weekly' ? defaultHasIntervalStr : customHasIntervalStr) : '';

    const intervalStr = hasIntervalStr === defaultHasIntervalStr || parsedRepeatType === '2' ? `|3:${intervals}` : '';

    const daysOfTheWeek = parsedRepeatType === '2' ? `|7:${dayOfWeek}` : '';

    return `${repeatingTypeStr}${hasIntervalStr}${intervalStr}${daysOfTheWeek}${occurrenceStr}${startTimeStr}`;
  }
  // Monthly on the i-th day
  if (repeatingType === '4') {
    return (
      repeatingTypeStr +
      customHasIntervalStr +
      monthIntervalStr +
      weekOfMonthStr +
      dayOfWeekStr +
      defaultOccurrenceStr +
      startTimeStr
    );
  }
  // Monthly on the last day
  if (repeatingType === '5') {
    const weekOfMonthStr = `|6:5`;
    return (
      repeatingTypeStr +
      customHasIntervalStr +
      monthIntervalStr +
      weekOfMonthStr +
      dayOfWeekStr +
      defaultOccurrenceStr +
      startTimeStr
    );
  }
  // Custom. When editing an existing repeating tasks, all patterns get parsed here as "savedCustom"
  if (repeatingType === 'custom' || repeatingType === 'savedCustom') {
    const timeStr =
      customEndOption === 'on'
        ? startTimeStr + endTimeStr
        : customRepeatingType === '2'
        ? `|8:${repeatDayOfTheWeek.length * occurrence}${startTimeStr}` // We need to manually calculate occurrence for custom weekly
        : `|8:${occurrence}${startTimeStr}`;
    const repeatingTypeStr = base + customRepeatingType;
    const intervalStr = `|3:${customIntervals}`;

    // Daily
    if (customRepeatingType === '1') {
      return repeatingTypeStr + defaultHasIntervalStr + intervalStr + timeStr;
    }
    // weekly
    if (customRepeatingType === '2') {
      const repeatDayOfTheWeekStr = `|7:${repeatDayOfTheWeek}`;

      return repeatingTypeStr + repeatDayOfTheWeekStr + intervalStr + timeStr;
    }
    // monthly
    if (customRepeatingType === '3') {
      const monthIntervalStr = `|5:${customIntervals}`;

      // Monthly on specific date
      if (customMonthlyOption === '1') {
        const dayOfMonthStr = `|4:${dayOfMonth}`;
        return repeatingTypeStr + defaultHasIntervalStr + monthIntervalStr + dayOfMonthStr + timeStr;
      }
      // Monthly on i-th day
      if (customMonthlyOption === '2') {
        return repeatingTypeStr + customHasIntervalStr + monthIntervalStr + weekOfMonthStr + dayOfWeekStr + timeStr;
      }
      // Monthly on last day
      if (customMonthlyOption === '3') {
        const weekOfMonthStr = `|6:5`;

        return repeatingTypeStr + customHasIntervalStr + monthIntervalStr + weekOfMonthStr + dayOfWeekStr + timeStr;
      }
    }
    // Yearly
    if (customRepeatingType === '4') {
      const monthOfYearStr = `|5:${monthOfYear}`;
      return `${repeatingTypeStr}|2:1${monthOfYearStr}` + `|4:${dayOfMonth}${timeStr}`;
    }
  }

  // Monthly, weekly share the same pattern
  // Monthly on the date (repeatingTypeStr = 3)
  // 1:3|2:1|4:1|5:2|9:2020-08-06|8:20|
  const hasIntervalStr = intervals !== 'weekly' ? defaultHasIntervalStr : customHasIntervalStr;
  const dayofTheMonthStr = `|4:${dayOfMonth}`;

  return repeatingTypeStr + hasIntervalStr + dayofTheMonthStr + monthIntervalStr + occurrenceStr + startTimeStr;
};

export const parseRepeatingPattern = pattern => {
  if (pattern == null) {
    return null;
  }
  const patternFragments = pattern.split('|');
  return patternFragments.reduce((acc, item) => {
    if (item.startsWith('1:')) {
      acc.repeatingType = item.replace('1:', '');
    } else if (item.startsWith('2:')) {
      acc.repeatingOption = item.replace('2:', ''); // For monthly, it's either repeating on specific n-th day of the week for the month or n-th day of the month
    } else if (item.startsWith('3:')) {
      acc.intervals = item.replace('3:', '');
    } else if (item.startsWith('4:')) {
      acc.repeatDay = item.replace('4:', '');
    } else if (item.startsWith('5:')) {
      acc.repeatMonth = item.replace('5:', '');
    } else if (item.startsWith('6:')) {
      acc.repeatGeneralDay = item.replace('6:', '');
    } else if (item.startsWith('7:')) {
      acc.repeatDayOfTheWeek = item.replace('7:', '');
    } else if (item.startsWith('8:')) {
      acc.occurrence = item.replace('8:', '');
    } else if (item.startsWith('9:')) {
      acc.startTime = item.replace('9:', '');
    } else if (item.startsWith('10:')) {
      acc.endTime = item.replace('10:', '');
    }
    return acc;
  }, {});
};
/**
 * Get the last name day of the month
 * @param {Date} date
 * @param {1|2|3|4|5|6|7} dayOfWeek
 */
export const getLastDayOfMonthByName = (date, lastDayOfMonth) => {
  const dayOfWeek = getISODay(date);

  const dayofWeekLastDay = getISODay(lastDayOfMonth);

  const diff = dayOfWeek <= dayofWeekLastDay ? dayofWeekLastDay - dayOfWeek : 7 - (dayOfWeek - dayofWeekLastDay);
  return sub(lastDayOfMonth, { days: diff });
};

/**
 * Construct the data for repeating dropdown dynamically based on date
 * @param {Object} options REPEATING_PATTERNS
 * @param {Date} date date supplied
 */
export const getRepeatingPatternOptions = (options, inputDate) => {
  const date = parseISODate(inputDate);
  const nameofDay = getDayName(date);
  const dayofMonth = ordinalize(getDate(date));

  const ordinalWeekOfMonth = getOrdinalWeekOfMonth(date);
  const endOfMonthStr = endOfMonth(date);
  const lastDayOfMonth = getLastDayOfMonthByName(date, endOfMonthStr);
  // is the last Mon/Tue/.. of the month
  const isLastDayOfMonth = isSameDay(lastDayOfMonth, date);

  return options.reduce((acc, curr) => {
    const { id, title } = curr;

    if (id === 'weekly') {
      acc.push({
        ...curr,
        title: title + nameofDay
      });
    } else if (id === 'monthlyOnDay') {
      // The value `5` is reserved on BE for the last day of the week from the month
      // Skipping the option of Monthly on the 5th ...
      if (ordinalWeekOfMonth == null || ordinalWeekOfMonth === '5th') {
        return acc;
      }
      acc.push({
        ...curr,
        title: `${title + ordinalWeekOfMonth} ${nameofDay}`
      });
    } else if (id === 'monthlyOnDate') {
      acc.push({
        ...curr,
        title: `${title}${dayofMonth}`
      });
    } else if (id === 'monthly') {
      if (isLastDayOfMonth) {
        acc.push({
          ...curr,
          title: `${`${title} last`} ${nameofDay}`
        });
      }
    } else if (id === 'yearly') {
      acc.push({
        ...curr,
        title: `${title} ${format(date, DATE_FORMATS.SHORT_DATE)}`
      });
    } else {
      acc.push(curr);
    }

    return acc;
  }, []);
};

export const getCustomRepeatingPatternOptions = date => {
  const dayofMonth = ordinalize(getDate(date));
  const weekOfMonth = getOrdinalWeekOfMonth(date);
  const nameofDay = getDayName(date);

  const endOfMonthStr = endOfMonth(date);
  const lastDayOfMonth = getLastDayOfMonthByName(date, endOfMonthStr);
  // is the last Mon/Tue/.. of the month
  const isLastDayOfMonth = isSameDay(lastDayOfMonth, date);

  const options = [
    { title: `Monthly on the ${dayofMonth}`, value: '1' },
    { title: `Monthly on the ${weekOfMonth} ${nameofDay}`, value: '2' }
  ];

  return !isLastDayOfMonth ? options : [...options, { title: `Monthly on the last ${nameofDay}`, value: '3' }];
};

/**
 * Get the date range based on the tasks group name.
 * @param {String} tasksGroupName format: task::${listId}::${activityType}::${isDone}::${timeframeStr}::${assignedToId}
 * @returns {{ dateFrom: String, dateTo: String }} the date range based on the tasks group name. Default is today.
 */
export const getDateRangeFromGroupName = tasksGroupName => {
  const currentTaskOptions = tasksGroupName.split('::'); // the current task group name is made up of the due key and activityType id
  const timeframeStr = currentTaskOptions[GroupOptions.DATE_RANGE];
  const today = format(Date.now(), DATE_FORMATS.ISO_DATE);
  const [dateFrom, dateTo] = timeframeStr?.split('to') || [today, today]; // We fallback to today, since our default task group in the reducer is 'today'.

  return dateFrom && dateTo ? { dateFrom, dateTo } : {};
};

export const getRepeatingLabel = options => {
  const {
    customIntervals,
    customRepeatingType,
    weekIntervals,
    customMonthlyDropdownOptions,
    customEndOption,
    customMonthlyOption,
    endTime,
    occurrence
  } = options;

  const isWeekly = customRepeatingType === '2';

  const isMonthly = customRepeatingType === '3';
  const base = 'Every ';

  // Display `Every week` instead of `every 1 week` to avoid confusion
  const intervalStr = customIntervals === '1' ? '' : `${customIntervals} `;

  const foundType = CUSTOM_REPEAT_OPTIONS.find(item => item.value === customRepeatingType);
  const type = foundType?.title && !isWeekly ? `${pluralize(parseInt(customIntervals), foundType?.title)}` : '';

  // Iterate and find selected days from the week
  const selectedDaysOfWeek =
    weekIntervals &&
    Object.keys(weekIntervals).reduce((acc, key) => {
      if (weekIntervals[key].value) {
        acc.push(weekIntervals[key].id);
      }
      return acc;
    }, []);

  const weeklyStr = isWeekly ? selectedDaysOfWeek.join(', ') : '';

  const endOn = customEndOption === 'on';

  const endStr = endOn
    ? `, until ${format(endTime, DATE_FORMATS.LONG_DATE)}`
    : `, after ${occurrence}${pluralize(occurrence, ' time')}`;
  const foundMonthly =
    customMonthlyDropdownOptions && customMonthlyDropdownOptions.find(item => item.value === customMonthlyOption);

  const monthlyStr = foundMonthly ? foundMonthly?.title : '';
  const baseStr = !isMonthly ? `${base}${intervalStr}${weeklyStr}${type}` : monthlyStr;
  return `${baseStr}${endStr}`;
};

export const getWeekIntervals = repeatDayOfTheWeek => {
  return {
    Sun: { id: 'Sun', label: '7', value: repeatDayOfTheWeek?.includes('7') },
    Mon: { id: 'Mon', label: '1', value: repeatDayOfTheWeek?.includes('1') },
    Tue: { id: 'Tue', label: '2', value: repeatDayOfTheWeek?.includes('2') },
    Wed: { id: 'Wed', label: '3', value: repeatDayOfTheWeek?.includes('3') },
    Thu: { id: 'Thu', label: '4', value: repeatDayOfTheWeek?.includes('4') },
    Fri: { id: 'Fri', label: '5', value: repeatDayOfTheWeek?.includes('5') },
    Sat: { id: 'Sat', label: '6', value: repeatDayOfTheWeek?.includes('6') }
  };
};

/**
 * Get a list of linked contacts from a task
 * @param {Object} task - existing task entity
 * @param {String} contactId
 * @param {String[]} contactList - existing contact list
 * @returns A list of linked contacts with id and type
 */
export const getContactsFromTask = (task, contactId, contactList) => {
  const { contacts } = task || {};
  const list = [...(contactList || [])];

  if (contacts != null) {
    return [...list, ...contacts];
  } else if (contactId != null && contactId !== '') {
    return [...list, { id: contactId, type: ENTITY_TYPES.contact }];
  }
  return list;
};

/**
 * Get the display value for task type, replacing tpxEmail to email
 * @param {Number} type activity/task type (@see TASK_TYPES)
 */
export const getDisplayTaskType = type => {
  return type === TASK_TYPES.tpxEmail.value ? 'Email' : TASK_TYPES[TASK_TYPES_MAP.get(type)].label;
};

/**
 * Get the display icon for task type, replacing tpxEmail to email
 * @param {Number} type activity/task type (@see TASK_TYPES)
 */
export const getDisplayTaskIcon = type => {
  return type === TASK_TYPES.tpxEmail.value ? 'email' : TASK_TYPES_MAP.get(type);
};

/**
 * Get the display icon for task previews - only for email and texting.
 * @param {Number} type activity/task type (@see TASK_TYPES)
 */
export const getDisplayTaskPreviewIcon = type => {
  return type === 14 ? 'textpreview' : 'emailpreview';
};

/**
 * This function is responsible for creating the parameters that need to be passed to fetchTasks method.
 * Since task group names are built based on these options, this funciton is also used to determine
 * "currentGroup" and "duePeriod" when instantiating a TaskList in the Contact tab nested into Task Details
 * @param {Object} props TaskList component props
 * @param {0|1|9} [props.category] Task Category to fetch (0=all;1=contact;9=transaction)
 * @param {Object} [props.entity] Contact or Transaction Entity linked to the task
 * @param {String} [props.entity.id] Entity id
 * @param {Object} [props.location] Search string inside Location Object
 * @param {String} [props.location.search] Search string inside Location Object
 * @param {String} [props.taskId] Task id: if falsey, setCurrentGroup will be sent as true
 */
export const getFetchOptions = props => {
  const { category, entity, location, taskId, fetchMoreTasks, isOnDashboard } = props || {};

  const { search } = location;
  const params = parseParamsStr(search) || {};
  const { activityType, complete, incomplete, due = 'today', end, start } = params;

  // When viewing a task list inside of task details (nested inside the contact), we don't want to set the
  // current group to be the newly fetched contact detail's tasks.
  const isNested = !!entity;
  const setCurrentGroup = taskId ? false : true;

  let options = {
    activityType,
    dateFrom: start
      ? start
      : format(parseISODate(getDateFromToday(DATE_RANGES_FROM_NOW[due].dateFrom)), DATE_FORMATS.ISO_DATE),
    dateTo: end ? end : format(parseISODate(getDateFromToday(DATE_RANGES_FROM_NOW[due].dateTo)), DATE_FORMATS.ISO_DATE),
    due,
    isDone: getTaskFilterStatus(complete, incomplete),
    setCurrentGroup: setCurrentGroup && !isOnDashboard,
    setDashboardGroup: isOnDashboard
  };

  if (fetchMoreTasks) {
    options = {
      ...options,
      category
    };
  } else if (isNested) {
    options = {
      ...options,
      category,
      objectId: entity && entity.id
    };
  }

  return options;
};

/**
 * This function returns an object with the options that are required for paging the list of tasks.
 * Use this function in conjunction with getFetchOptions to get the complete set of options for a paginated fetch.
 * @param {Object} props TaskList component props
 * @param {String} [props.currentGroup] Name of the current group
 * @param {Object} [props.groups] Hash Map with arrays of tasks (groups) hashed by group name
 * @param {Object} [props.tasks] Hash Map with the tasks hashed by task id
 */
export const getPagingOptions = props => {
  const { currentGroup, groups, tasks, category } = props;
  const options = {};

  const taskList = groups[currentGroup];

  if (taskList == null || taskList.length === 0) {
    options.forceRefresh = true;
  } else {
    const lastTaskId = taskList.slice(-1)[0];
    const last = tasks[lastTaskId];
    try {
      if (last) {
        options.id = lastTaskId;

        // when category=0, all items in the current list will have keyRecId computed
        // when category!=0 (category in [1, 9]), startOfEvent should be used as keyRecId
        options.keyRecId = category !== 0 ? last.startOfEvent : last.keyRecId || last.startOfEvent;

        options.activityId = last.activityId;
      } else {
        throw 'undefined';
      }
    } catch (err) {
      options.forceRefresh = true;
    }
  }
  return options;
};

/**
 * This function does an async fetch for up to 1000 tasks and returns them as an entities object,
 * for the purpose of allowing the print tasks button to print all tasks in a range. Most logic
 * is copied over directly from the existing getTasks action.
 * @param {Object} options The fetch options.
 * @param {String} assignedToId The ID for the assigned to agent.
 * @returns {Object} The fetched tasks in entities format.
 **/
export const getPrintTasks = async (options, assignedToId) => {
  const {
    due = 'today',
    activityId,
    keyRecId,
    pageSize = 1000,
    category = TASK_CATEGORIES.all,
    isDone = 0,
    assignedTo = assignedToId === 'all' ? { all: 1, assigned: [] } : { all: 0, assigned: [assignedToId] },
    allCounts = 1,
    activityType = 0,
    objectId,
    forceRefresh = false,
    shouldBeCached = false
  } = options || {};

  // When not set directly in options, dateFrom and dateTo need to come from the due period.
  const dateFrom =
    options.dateFrom ||
    format(parseISODate(getDateFromToday(DATE_RANGES_FROM_NOW[due].dateFrom)), DATE_FORMATS.ISO_DATE);
  const dateTo =
    options.dateTo || format(parseISODate(getDateFromToday(DATE_RANGES_FROM_NOW[due].dateTo)), DATE_FORMATS.ISO_DATE);

  let direction;

  if (options.direction === 0) {
    direction = options.direction;
  } else {
    direction = options.due === 'overdue' ? 1 : 2;
  }

  const requestOptions = {
    apiServiceType: 'task',
    forceRefresh,
    method: 'POST',
    path: 'list',
    payload: {
      direction: parseInt(direction),
      activityId,
      keyRecId,
      pageSize: parseInt(pageSize),
      assignedTo,
      type: activityType,
      category: parseInt(category),
      isDone: parseInt(isDone),
      allCounts: parseInt(allCounts),
      dateFrom: format(parseISODate(dateFrom), DATE_FORMATS.ISO_DATETIME_START),
      dateTo: format(parseISODate(dateTo), DATE_FORMATS.ISO_DATETIME_END),
      objectId
    },
    shouldBeCached
  };
  const taskRequest = await request(requestOptions);

  if (!taskRequest) {
    return;
  }

  const { data } = taskRequest;
  const entities = data?.records.reduce((acc, record) => ({ ...acc, [record.activityId]: record }), {});

  return entities;
};

/**
 * This function filters the tasks entities object to just the tasks assigned to
 * the assignedToId, or returns all if no assignedToId given or is "all".
 * @param {Object} tasks The tasks entities.
 * @param {String} assignedToId The ID for the assigned to agent.
 * @returns {Object} The fetched tasks in entities format.
 **/
export const getTasksByAssignedToId = (tasks, assignedToId) => {
  if (!assignedToId || assignedToId === 'all') {
    return tasks;
  }

  const filteredTasks = Object.keys(tasks).reduce(
    (acc, task) => (tasks[task].assignedToId === assignedToId ? { ...acc, [task]: tasks[task] } : { ...acc }),
    {}
  );
  return filteredTasks;
};
