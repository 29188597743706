import { getObjValueFromStr } from './data';

/**
 * Get the "autocomplete" Entry ID
 *
 * @param {Object} item An "autocomplete" Entry from "/suggest" response payload
 * @returns {String|null} id the "_id" attribute of the entry or null if no id
 */
export const getCirrusPropertyId = item => getObjValueFromStr(item, 'mls_number') || null;

/**
 * Get the "autocomplete" Entry Label
 * which is the value of the field pointed by `area_type` attribute
 *
 * E.g.: getLabel({ area_type: "university", university: "University of Seattle" })
 *       will return "University of Seattle"
 *
 * @param {Object} item An "autocomplete" Entry from "/suggest" response payload
 * @param {String} [line] - the line of the address when [area_type] === 'address'
 * @param {String} [area_type] - the area type, e.g.: 'address'
 * @returns {String} One of the following strings:
 *                   1) item.line;
 *                   2) item[area_type] if no `item.line`;
 *                   3) ''; if nothing else.
 */
export const getLabel = item => {
  if (!item) {
    return '';
  }

  if (item.line) {
    return item.line;
  }

  return item && item.area_type && item[item.area_type] ? item[item.area_type] : '';
};
