/** @module */

export const SALESFORCE_REQUEST = 'SALESFORCE_REQUEST';
export const SALESFORCE_REQUEST_SUCCESS = 'SALESFORCE_REQUEST_SUCCESS';
export const SALESFORCE_REQUEST_FAIL = 'SALESFORCE_REQUEST_FAIL';
export const SALESFORCE_REQUEST_RESET = 'SALESFORCE_REQUEST_RESET';

export const initialState = {
  isLoading: false,
  salesforceStatus: null
};

export const salesforceReducer = (state = initialState, action = {}) => {
  const { salesforceStatus } = action;
  switch (action.type) {
    case SALESFORCE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case SALESFORCE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        salesforceStatus
      };
    case SALESFORCE_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case SALESFORCE_REQUEST_RESET:
      return initialState;
    default:
      return state;
  }
};
