import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Container } from '../../components';

import styles from './Details.css';

export const DetailsView = props => {
  const { children, className } = props;

  const classes = classnames({
    [styles.detailsView]: !className,
    [className]: !!className
  });

  return <Container className={classes}>{children}</Container>;
};

DetailsView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
};
