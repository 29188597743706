/** @module */
import {
  deleteFromCollection,
  deleteFromGroup,
  getMergedEntities,
  getMergedSetOfIds,
  moveGroups,
  deleteAllFromCollection,
  deleteAllFromAllGroups
} from '../utils/collections';

export const TASKS_CURRENT_GROUP = 'TASKS_CURRENT_GROUP';
export const TASKS_DASHBOARD_GROUP = 'TASKS_DASHBOARD_GROUP';
export const TASKS_DELETE = 'TASKS_DELETE';
export const TASKS_IGNORE_TEMPORARILY = 'TASKS_IGNORE_TEMPORARILY';
export const TASKS_UNIGNORE = 'TASKS_UNIGNORE';
export const TASK_RESET_IGNORE_DATA = 'TASK_RESET_IGNORE_DATA';
export const TASKS_DETAILS = 'TASKS_DETAILS';
export const TASKS_DONE_PAGING = 'TASKS_DONE_PAGING';
export const TASKS_GET_REPEATING_PATTERN = 'TASKS_GET_REPEATING_PATTERN';
export const TASKS_LOADING = 'TASKS_LOADING';
export const TASKS_MARK_COMPLETE = 'TASKS_MARK_COMPLETE';
export const TASKS_MARK_INCOMPLETE = 'TASKS_MARK_INCOMPLETE';
export const TASKS_MOVE = 'TASKS_MOVE';
export const TASKS_REMOVE_BY_GROUP = 'TASKS_REMOVE_BY_GROUP';
export const TASKS_REMOVE_BY_PLAN_ID = 'TASKS_REMOVE_BY_PLAN_ID';
export const TASKS_RESET = 'TASKS_RESET';
export const TASKS_RESET_PAGING = 'TASKS_RESET_PAGING';
export const TASKS_SET = 'TASKS_SET';
export const TASKS_DELETE_ALL_OCCURRENCES = 'TASKS_DELETE_ALL_OCCURRENCES';
export const TASKS_EMAIL_STATUS = 'TASKS_EMAIL_STATUS';
export const TASKS_SET_DETAIL_LOCATION = 'TASKS_SET_DETAIL_LOCATION';
export const TASKS_CURRENT_ENTITY = 'TASKS_CURRENT_ENTITY';
export const TASKS_SET_LAST_SEARCHED_DATE = 'TASKS_SET_LAST_SEARCHED_DATE';

export const initialState = {
  entities: {},
  groups: {},
  currentGroup: 'today',
  due: 'today',
  donePaging: {},
  isLoading: false,
  ignoreData: {
    massDelete: {
      timestamp: null,
      list: []
    },
    massMarkdone: {
      timestamp: null,
      list: []
    }
  },
  counts: {},
  currentEntity: null,
  lastSearchedDate: null
};

/**
 * The tasks redux reducer.
 * @param {Object} state - the current state of the tasks store.
 * @param {Object} action - the action to take on the tasks store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 * @param {String} [action.due] - The due keyword for the fetch (it should be in sync with the currentGroup).
 * @param {Boolean} [action.isPaging] - Denotes whether the request data is due to paging.
 * @param {String} [action.query] - The request query acts as a key when storing groups of tasks.
 * @param {Boolean} [action.isLoading] - Denotes whether the data request is in progress.
 * @param {Boolean} [action.setCurrentGroup] - Denotes whether the fetch should set the query as the currentGroup.
 */
export const taskReducer = (state = initialState, action = {}) => {
  const { type = 'default', data = {}, due, isPaging, query, isLoading, setCurrentGroup, taskId, key } = action || {}; // What all values supported for activity type not known
  const { counts, id, group } = data || {};

  const taskGroups = ['overdue', 'today', 'tomorrow', 'future', 'custom'];
  const countGroups = new Map([
    [1, 'appointment'],
    [2, 'call'],
    [3, 'todo'],
    [9, 'listingTodo'],
    [15, 'tpxEmail']
  ]);

  const taskCount = counts
    ? Object.keys(counts).reduce((count, type) => {
        const types = ['all', 'appointments', 'calls', 'todos', 'listingTodos', 'tpxEmails'];
        if (!types.includes(type)) {
          return count;
        }
        return count + counts[type];
      }, 0)
    : 0;

  switch (type) {
    case TASKS_RESET:
      return initialState;
    case TASKS_SET:
      const saveToGroup = query || !state.currentGroup.includes('task::plan::');

      return {
        ...state,
        currentGroup: setCurrentGroup ? query : state.currentGroup,
        due: setCurrentGroup ? due : state.due,
        isLoading: isLoading,
        entities: getMergedEntities(state.entities, data.records),
        groups: {
          ...state.groups,
          // Save to the currentGroup if group name/query is not provided, and currentGroup not a task plan group.
          ...(saveToGroup && {
            [query || state.currentGroup]: getMergedSetOfIds(state.groups[query] || [], data.records, { isPaging }),
            ...(state.dashboardGroup &&
              action.setDashboardGroup && {
                [state.dashboardGroup]: getMergedSetOfIds(state.groups[state.dashboardGroup] || [], data.records, {
                  isPaging
                })
              })
          })
        },
        counts: taskGroups.includes(query?.split('::')[1])
          ? { ...state.counts, [query.split('::')[1] || 'today']: { ...counts, all: taskCount } }
          : state.counts
      };
    case TASKS_DETAILS:
      if (data === null) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            ...state.entities[id],
            ...data
          }
        }
      };
    case TASKS_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: query,
        due,
        isLoading: isLoading
      };
    case TASKS_DASHBOARD_GROUP:
      return {
        ...state,
        dashboardGroup: query,
        dashboardDue: due ?? state.dashboardDue
      };
    case TASKS_MOVE:
      return {
        ...state,
        groups: {
          ...state.groups,
          ...moveGroups(id, group, state.groups)
        }
      };
    case TASKS_MARK_COMPLETE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            ...state.entities[id],
            isDone: true,
            completed: true
          }
        }
      };
    case TASKS_MARK_INCOMPLETE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            ...state.entities[id],
            isDone: false,
            completed: false
          }
        }
      };
    case TASKS_IGNORE_TEMPORARILY:
      return {
        ...state,
        ignoreData: {
          ...state.ignoreData,
          [key]: {
            list: state.ignoreData[key].list.concat(data.list),
            timestamp: state.ignoreData[key].timestamp || data.timestamp
          }
        }
      };
    case TASKS_UNIGNORE:
      return {
        ...state,
        ignoreData: {
          ...state.ignoreData,
          massMarkdone: {
            list: state.ignoreData.massMarkdone.list.filter(id => id !== data.idToUnignore),
            timestamp: state.ignoreData.massMarkdone.timestamp || data.timestamp
          }
        }
      };
    case TASKS_DELETE:
      // New task counts to reflect deletion.
      const countGroup = state.currentGroup.split('::')[1];
      const deletedType = countGroups.get(state.entities?.[id]?.type);
      const newCounts =
        taskGroups.includes(countGroup) && deletedType
          ? {
              ...state.counts,
              [countGroup]: {
                ...state.counts[countGroup],
                all: state.counts[countGroup].all - 1,
                [`${deletedType}s`]: state.counts[countGroup][`${deletedType}s`] - 1
              }
            }
          : state.counts;

      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, id)
        },
        groups: {
          ...state.groups,
          [group]: deleteFromGroup(state.groups[group], id)
        },
        counts: newCounts
      };
    case TASKS_DONE_PAGING:
      return {
        ...state,
        donePaging: {
          ...state.donePaging,
          [group]: true
        }
      };
    case TASKS_RESET_PAGING:
      return {
        ...state,
        donePaging: {}
      };
    case TASKS_LOADING:
      return {
        ...state,
        isLoading
      };
    case TASKS_REMOVE_BY_GROUP: {
      const { groupName } = action.payload;
      const currentGroups = state.groups?.[groupName];
      if (currentGroups == null) {
        return state;
      }
      const entities = { ...deleteAllFromCollection(state.entities, currentGroups) };
      const groups = {
        ...deleteFromCollection(state.groups, groupName)
      };

      const nextState = {
        ...state,
        entities,
        groups
      };

      return nextState;
    }
    case TASKS_REMOVE_BY_PLAN_ID: {
      const { planId } = action.payload;
      // first we identify which activities belongs to the plan.
      // we put it in a hash, to simplify the future searches.
      const planActivityIds = Object.keys(state.entities).reduce((ids, key) => {
        const entity = state.entities[key];
        if (entity.planId === planId) {
          ids[entity.id] = true;
        }
        return ids;
      }, {});

      // now we delete all tasks found from the entities collection
      const entities = { ...deleteAllFromCollection(state.entities, Object.keys(planActivityIds)) };

      // finally, we filter off the plan's tasks from each group in the store
      const groups = Object.keys(state.groups).reduce((partialGroups, key) => {
        const group = state.groups[key];
        partialGroups[key] = group.filter(id => !planActivityIds[id]);
        return partialGroups;
      }, {});

      const nextState = {
        ...state,
        entities,
        groups
      };

      return nextState;
    }
    case TASKS_GET_REPEATING_PATTERN:
      if (data === null) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [taskId]: {
            ...state.entities[taskId],
            repeating: data
          }
        }
      };
    case TASKS_DELETE_ALL_OCCURRENCES:
      const entity = state.entities[taskId];

      const { repeating } = entity || {};
      const { recurringPattern } = repeating || {};
      const { instances } = recurringPattern || {};
      // If we have id references to other occurrences, update the entities and groups by deleting those
      if (instances) {
        const entities = { ...deleteAllFromCollection(state.entities, instances) };
        const groups = {
          ...deleteAllFromAllGroups(state.groups, instances)
        };
        return {
          ...state,
          entities,
          groups
        };
      }
    case TASKS_EMAIL_STATUS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [taskId]: {
            ...state.entities[taskId],
            emailStatus: data
          }
        }
      };
    case TASKS_SET_DETAIL_LOCATION:
      return {
        ...state,
        location: action.location
      };
    case TASKS_CURRENT_ENTITY:
      return {
        ...state,
        currentEntity: id
      };
    case TASKS_SET_LAST_SEARCHED_DATE:
      return {
        ...state,
        lastSearchedDate: data
      };
    case TASK_RESET_IGNORE_DATA:
      if (key) {
        return {
          ...state,
          ignoreData: {
            ...state.ignoreData,
            [key]: initialState.ignoreData[key]
          }
        };
      }
      return {
        ...state,
        ignoreData: { ...initialState.ignoreData }
      };
    default:
      return state;
  }
};
