import React from 'react';
import { arrayOf, node, object, oneOfType } from 'prop-types';

import { Image } from './Image';
import { ImageBasic } from './ImageBasic';
import { Template } from './Template';

/**
 * Returns desired styles for certain Element types
 * @param {String} type - The Slate Element type.
 * @param {String} value - An optional style value.
 */
const getBlockElementStyles = (type, value) => {
  if (type === 'block-quote') {
    return {
      margin: '0 0 0 .8ex',
      paddingLeft: '1ex',
      borderLeft: '1px solid #ccc'
    };
  }

  if (type === 'alignment') {
    return { textAlign: value?.toLowerCase() };
  }

  return {};
};

/**
 * React component for rendering Slate Element nodes.
 * @param {Object} props Element component props.
 * @param {Object} [props.attributes] The node's Slate attributes.
 * @param {Array} [props.children] The child nodes of a node.
 * @param {Object} [props.element] HTML element related attribute and style values.
 */
export const Element = props => {
  const { attributes, children, element } = props;
  const { alignment, id, styles = {}, type, url } = element;

  const tpxSupportedId = id && id === 'tpxEmailSignature' ? id : null;

  switch (type) {
    case 'alignment':
      return (
        <div style={{ ...styles, ...getBlockElementStyles(type, alignment) }} {...attributes}>
          {children}
        </div>
      );
    case 'block-quote':
      return (
        <blockquote style={{ ...styles, ...getBlockElementStyles(type) }} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={{ ...styles }} {...attributes}>
          {children}
        </ul>
      );
    case 'list-item':
      return (
        <li style={{ ...styles }} {...attributes}>
          {children}
        </li>
      );
    case 'ordered-list':
      return (
        <ol style={{ ...styles }} {...attributes}>
          {children}
        </ol>
      );
    case 'link':
      return (
        <a href={url} rel="noopener" style={{ ...styles }} target="_blank" {...attributes}>
          {children}
        </a>
      );
    case 'image':
      return <Image {...props} />;
    case 'image-basic': // Used to render images during serialization. Needs isSerializing = true.
      return <ImageBasic {...props} isSerializing />;
    case 'template':
      return <Template {...props} />;
    default:
      const isEmptyDiv = Array.isArray(children) && children.length === 0;
      return (
        <div id={tpxSupportedId} style={{ ...styles }} {...attributes}>
          {!isEmptyDiv ? children : <br />}
        </div>
      );
  }
};

Element.propTypes = {
  attributes: object,
  children: oneOfType([arrayOf(node), node]),
  element: object.isRequired
};
