/**
 * A delay method that returns a promise so we can await a timer. A cleaner alternative to setTimeout wrapping.
 * @param {Number} time - the number of milliseconds to wait.
 */
export const delay = (time = 0) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};
