import { string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { FollowUpList } from '../../components';

export const FollowUp = () => {
  const { entities, groups, donePaging, isLoading } = useSelector(store => store.contacts);

  const followupGroup = 'New::touch::1';

  if (!groups?.[followupGroup]) {
    return <></>;
  }
  return (
    <FollowUpList
      contacts={entities}
      isLoading={isLoading}
      list={groups[followupGroup].slice(0, 3)}
      location={location}
      donePaging={donePaging[followupGroup]}
      variant="compact"
    />
  );
};

FollowUp.propTypes = {
  className: string
};
