import React from 'react';
import { bool, string } from 'prop-types';
import { useSlate } from 'slate-react';

import { EditorCommands } from '../editor-commands';
import { MenuButton } from '../../MenuButton';

/**
 * React component for rendering the ButtonAlignment component.
 * @param {Object} props ButtonAlignment component props.
 * @param {String} [props.currentAlignment] The current alignment direction.
 * @param {Boolean} [props.isDisabled] The button's disabled state.
 */
export const ButtonAlignment = props => {
  const editor = useSlate();
  const { currentAlignment = 'left', isDisabled } = props;

  const alignmentLabels = ['Left', 'Center', 'Right'];

  const menuItems = alignmentLabels.map(label => {
    const id = label.toLowerCase();
    const icon = `align${id}`;
    const isSelected = currentAlignment.toLowerCase() === id;

    return {
      icon,
      id,
      isSelected,
      label,
      onSelect: () => EditorCommands.toggleBlock(editor, 'alignment', label)
    };
  });

  return (
    <MenuButton
      disabled={isDisabled}
      icon="alignleft"
      menuItems={menuItems}
      position="above"
      showArrow={true}
      toolTip="Align"
      tooltipPos="below"
    />
  );
};

ButtonAlignment.propTypes = {
  currentAlignment: string,
  isDisabled: bool
};
