import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dropdown, FormFieldset, FormRow } from '../../components';
import { INSIGHT_STATUS_LABELS } from '../../utils/insights';
import { handleTempInsightRemove } from '../../utils/mlsBoards';
import PropertyInsightCard from '../Card/PropertyInsightCard';
import formFieldStyles from '../FormField/FormField.css';
import styles from '../PropertyInsightsForm/PropertyInsightsForm.css';

const InsightsPreviewList = props => {
  const { displayListings, insightStatus, contactId, setLookupIsValid, setInsightStatus, setMlsSearchValue } = props;
  const dispatch = useDispatch();
  const handleChangeInsightStatus = (e, setInsightStatus) => {
    setInsightStatus(e.target.value);
  };

  return (
    <Fragment>
      <ul className={styles.propertyList}>
        {displayListings.map(insight => (
          <li key={`${insight.property_id}-${insight.listing_id}`} className={styles.property}>
            <PropertyInsightCard data={insight} contactId={contactId} />
            <Button
              className={styles.removeButton}
              icon="delete"
              label="Remove"
              ariaLabel="Remove property and search again."
              onClick={e => handleTempInsightRemove(e, dispatch, setLookupIsValid, setMlsSearchValue)}
            />
          </li>
        ))}
      </ul>
      <FormFieldset label="Interest">
        <FormRow>
          <div className={formFieldStyles.field}>
            <Dropdown
              id="insightStatus"
              items={INSIGHT_STATUS_LABELS}
              onChange={e => handleChangeInsightStatus(e, setInsightStatus)}
              value={insightStatus}
            />
          </div>
        </FormRow>
      </FormFieldset>
    </Fragment>
  );
};

export default InsightsPreviewList;
