import { validateUrl } from './strings';

export const IMAGE_EXTENSIONS = ['apng', 'avif', 'gif', 'jpg', 'jpeg', 'png', 'svg', 'webp'];

export const isImageUrl = url => {
  if (!url || !validateUrl(url)) {
    return false;
  }

  const ext = new URL(url).pathname.split('.').pop();

  return IMAGE_EXTENSIONS.includes(ext);
};

/**
 * Make sure urls are HTTPS and have a better image size than the default small size
 * @param {String} href
 */
export const getDisplayImage = (href, width = 500) => {
  return href ? href.replace('http:', 'https:').replace('.jpg', `-w${width}.jpg`) : null;
};
