export const EXTERNAL_LINK_ATTRS = { target: '_blank', rel: 'noopener' };

/**
 * Decides whether a url should match the current location and set the url as selected.
 * @param {String} url - The url of a given link.
 * @param {Object} location - The current location from the router, will fall back to window.location.
 * @param {String} matchKey - What to match against.
 */

export const fuzzyMatchUrl = (url, location, matchKey = 'url') => {
  const { pathname, search } = location;

  if (!url) {
    return false;
  }

  if (matchKey === 'url') {
    // The default. Most matching should use this matchKey.
    return url === `${pathname}${search}`;
  }

  if (matchKey === 'startsWithUrl') {
    // Convenient for matching a higher level link to a current child location/
    // e.g. The main nav contacts item will use this to show selected when on a child tab of a given contact's details.
    return pathname.startsWith(url.replace(search, ''));
  }

  // As the ultimate fallback, we might need to simply match against a given key of the current location.
  return url === location[matchKey];
};

/**
 * Decides whether a url is an external link.
 * @param {String} url - The url of a given link.
 */

export const isExternalLink = url => Boolean(url?.startsWith?.('http'));

export const checkIsFromDashboard = pathname => {
  const pathsArray = pathname?.split('/') || [];
  return pathsArray?.[1] === 'dashboard';
};
