import React from 'react';
import { CallToAction } from './CallToAction';
import { PLANS_URI } from '../../constants';

export const PlansCallToAction = props => {
  const { clickHandler } = props;
  const message = 'Start managing your task plans to create multiple tasks at once.';
  return (
    <CallToAction
      ariaLabel={message}
      buttonLabel="Manage task plans"
      clickHandler={clickHandler}
      icon="tasks"
      iconSize="l"
      isNested={true}
      message={message}
      url={`${PLANS_URI}/new`}
    />
  );
};
