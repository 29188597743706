/** @module */

import {
  PHOTOVIEWER_OPEN,
  PHOTOVIEWER_CLEAR,
  PHOTOVIEWER_SET_INDEX,
  PHOTOVIEWER_SET_URLS,
  PHOTOVIEWER_SET_URL,
  PHOTOVIEWER_CLOSE,
  PHOTOVIEWER_SET_NEXT_INDEX,
  PHOTOVIEWER_SET_PREV_INDEX
} from '../reducers/photoViewer';

/**
 * Sets a the photo viewer as open.
 */
export const photoViewerOpen = () => {
  return dispatch => {
    dispatch({
      type: PHOTOVIEWER_OPEN
    });
  };
};

/**
 * Sets a the photo viewer as closed.
 */
export const photoViewerClose = () => {
  return dispatch => {
    dispatch({
      type: PHOTOVIEWER_CLOSE
    });
  };
};

/**
 * Sets current index for displaying to next.
 */
export const photoViewerNext = () => {
  return dispatch => {
    dispatch({
      type: PHOTOVIEWER_SET_NEXT_INDEX
    });
  };
};

/**
 * Sets current index for displaying to previous.
 */
export const photoViewerPrevious = () => {
  return dispatch => {
    dispatch({
      type: PHOTOVIEWER_SET_PREV_INDEX
    });
  };
};

/**
 * Resets the photo viewer state, including current index and urls
 */
export const photoViewerClear = () => {
  return dispatch => {
    dispatch({
      type: PHOTOVIEWER_CLEAR
    });
  };
};

/**
 * Sets the current index of display image of the photo viewer
 * @param {Number} index
 */
export const photoViewerSetIndex = index => {
  return dispatch => {
    dispatch({
      type: PHOTOVIEWER_SET_INDEX,
      index
    });
  };
};

/**
 * Sets the urls data  in the photo viewer
 * @param {String[]} urls
 */
export const photoViewerSetUrls = urls => {
  return dispatch => {
    dispatch({
      type: PHOTOVIEWER_SET_URLS,
      urls
    });
  };
};

/**
 * Sets the current url for display in the photo viewer
 * @param {String[]} urls
 */
export const photoViewerSetUrl = url => {
  return dispatch => {
    dispatch({
      type: PHOTOVIEWER_SET_URL,
      url
    });
  };
};
