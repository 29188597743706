import classnames from 'classnames';
import format from 'date-fns/format';
import { bool, object, string } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { photoViewerOpen, photoViewerSetUrl } from '../../actions/photoViewer';

import { DATE_FORMATS } from '../../constants';
import { linkifyText } from '../../utils';
import { parseISODate } from '../../utils/dates';
import { sanitizer } from '../../utils/dom';
import { Card } from './';
import styles from './TextCard.css';
import TypingEffect from '../TypingEffect/TypingEffect';
import { Loading } from '../Loading';
import { Avatar } from '../Avatar';

const AI_DATA = { firstName: 'A', lastName: 'I' };

export const TextCard = props => {
  const {
    className,
    data,
    shouldTypeEffect = false,
    isLoading = false,
    messagesEndRef,
    isAIMessage = false
  } = props || {};

  const dispatch = useDispatch();
  if (!data) {
    return null;
  }

  const { actor, message, media, timeStamp } = data || {};

  const time = !isAIMessage ? format(parseISODate(timeStamp), DATE_FORMATS.SHORT_TIME) : '';

  const displayMessage = linkifyText(message);

  const classes = classnames({
    [styles.textCard]: !className,
    [styles[`${actor}TextCard`]]: true,
    [className]: !!className
  });

  const messageClasses = classnames({
    [styles.message]: true,
    [styles[`${actor}Message`]]: true
  });

  const handleClick = (e, url) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(photoViewerSetUrl(url));
    dispatch(photoViewerOpen());
  };

  const getImageUrlsFromMedia = data => {
    return data && data.filter(i => i.ContentType.startsWith('image'));
  };

  const imageData = getImageUrlsFromMedia(media);

  return (
    <Card className={classes}>
      {isAIMessage && actor === 'assistant' && <Avatar name={'AI'} primaryPerson={AI_DATA} size="s" />}
      <div className={messageClasses}>
        {imageData && (
          <div className={styles.imageWrapper}>
            {imageData.map(data => {
              const { Url } = data;

              return (
                <img
                  key={`img${Url}key`}
                  src={Url}
                  className={styles.image}
                  alt="Image from text"
                  onClick={e => handleClick(e, Url)}
                />
              );
            })}
          </div>
        )}
        {isLoading && shouldTypeEffect && (
          <div className={styles.messageText}>
            <Loading />
          </div>
        )}
        {displayMessage &&
          (!shouldTypeEffect ? (
            <div
              className={styles.messageText}
              dangerouslySetInnerHTML={{
                __html: sanitizer(displayMessage, { ADD_ATTR: ['target', 'contenteditable'] }) // we need to support linkify attrs that aren't allowed normally in Dompurify
              }}
            />
          ) : (
            <TypingEffect className={styles.messageText} text={displayMessage} messagesEndRef={messagesEndRef} />
          ))}
      </div>
      {!isAIMessage && <div className={styles.time}>{time}</div>}
    </Card>
  );
};

TextCard.propTypes = {
  className: string,
  data: object.isRequired,
  isAIMessage: bool,
  shouldTypeEffect: bool
};
