import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from './';

import { toggleContactFavorite } from '../../actions';
import { clearMessage } from '../../actions/message';
import { trackEvent } from '../../utils/analytics';

import { bool, oneOf, string } from 'prop-types';
export class FavoriteContactButton extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const { contactId, isFavorite = false, clearMessage, toggleContactFavorite } = this.props;

    trackEvent('contactDetails', 'toggleFavorite');

    // clear any existing app errors
    clearMessage();

    const toggleFavorite = !isFavorite;
    const method = toggleFavorite ? 'add' : 'delete';
    toggleContactFavorite(contactId, method);
  };

  render() {
    const { className, isFavorite = false, size, contactId } = this.props;

    if (!contactId) {
      return null;
    }

    const ariaLabel = isFavorite ? 'Marked as a favorite.' : 'Not a favorite.';

    return (
      <Button
        icon="favorite"
        ariaLabel={ariaLabel}
        title={ariaLabel}
        isFavorite={isFavorite}
        className={className}
        size={size}
        onClick={this.handleSubmit}
      />
    );
  }
}

FavoriteContactButton.propTypes = {
  className: string,
  contactId: string,
  isFavorite: bool,
  size: oneOf(['s', 'm', 'l', 'xl'])
};

const mapDispatchToProps = {
  clearMessage,
  toggleContactFavorite: (contactId, method) => toggleContactFavorite(contactId, method)
};

export default connect(null, mapDispatchToProps)(FavoriteContactButton);
