import React from 'react';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import { ButtonGroup, Loading } from '../';

import styles from './FormFooter.css';

export const FormFooter = props => {
  const { advancedSearch = false, children, className, isNested = false, loading = false } = props;

  if (!children) {
    return null;
  }

  const classes = classnames({
    [styles.footer]: !className,
    [styles.isNested]: !!isNested,
    [className]: !!className
  });

  const buttonGroupClasses = classnames({
    [styles.buttonGroup]: true,
    [styles.advancedSearch]: advancedSearch
  });

  return (
    <footer className={classes}>
      <ButtonGroup className={buttonGroupClasses}>
        <Loading loading={loading} className={styles.loading} />
        {children}
      </ButtonGroup>
    </footer>
  );
};

FormFooter.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
  isNested: bool,
  loading: bool
};
