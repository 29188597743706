import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { object } from 'prop-types';
import { navigate } from '@reach/router';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';

import { showMessage } from '../../actions/message';
import { FormDateInput } from '../../components';
import formFieldStyles from '../../components/FormField/FormField.css';
import { DATE_FORMATS } from '../../constants';
import { parseParamsStr, serializeToParamsStr, TASK_TABS_WITH_DATE_RANGE } from '../../utils';

export const CustomDateRangeForm = props => {
  const startDateInputRef = useRef(null);
  const [isDateRangeValid, setIsDateRangeValid] = useState(true);
  const dispatch = useDispatch();

  const { location, setLastSearchedDate } = props;
  const { pathname, search } = location;

  const defaultDate = format(new Date(), DATE_FORMATS.ISO_DATE);
  const params = parseParamsStr(search) || {};
  const { due, end = defaultDate, start = defaultDate } = params;

  const shouldHaveDateRange = TASK_TABS_WITH_DATE_RANGE.includes(due);

  if (!shouldHaveDateRange) {
    return null;
  }

  const handleDateChange = ({ selectedDate, type }) => {
    const currentStartValue = document.getElementById('startDate')?.value;
    const currentEndValue = document.getElementById('endDate')?.value;

    const newParams = {
      ...params,
      start: currentStartValue || params.start,
      end: currentEndValue || params.end,
      [type]: selectedDate
    };

    const isValid = isBefore(new Date(newParams.start), new Date(newParams.end));
    setIsDateRangeValid(isValid);

    if (!isValid) {
      // We need to dispatch a click to close the endDate calendar Popover.
      document.dispatchEvent(new Event('click'));
      // Focus on the start date input.
      startDateInputRef.current.focus();
      // Show a message.
      dispatch(
        showMessage({ message: 'Invalid date range. Start date must come before end date.', type: 'error' }, true)
      );
      return;
    }
    setLastSearchedDate(newParams.start, newParams.end);

    const newParamsStr = serializeToParamsStr(newParams);
    const newUrl = `${pathname}?${newParamsStr}`;

    navigate(newUrl);
  };
  return (
    <div className={formFieldStyles.dateRange}>
      <div className={formFieldStyles.field}>
        <FormDateInput
          id="startDate"
          timestamp={format(parseISO(start), DATE_FORMATS.ISO_DATETIME_CALENDAR)}
          onDateChange={date => handleDateChange({ selectedDate: date, type: 'start' })}
          placeholder=""
          inputRef={startDateInputRef}
          required
          showInvalid={!isDateRangeValid}
        />
      </div>
      <span>to</span>

      {/* @NOTE:
          As we use CustomDateRangePicker in multiple pages, we do the
          pathname.split check in order to determine where we are in the app.
          "Yesterday" should only show up in the TasksPage header.
          We use pathname to make sure it is only there that we display this
      */}
      {due === 'overdue' && !pathname.split('/')[2] ? (
        <div className={formFieldStyles.field}>
          <span> Yesterday</span>
        </div>
      ) : (
        <div className={formFieldStyles.field}>
          <FormDateInput
            id="endDate"
            timestamp={format(parseISO(end), DATE_FORMATS.ISO_DATETIME_CALENDAR)}
            onDateChange={date => handleDateChange({ selectedDate: date, type: 'end' })}
            placeholder=""
            required
          />
        </div>
      )}
    </div>
  );
};

CustomDateRangeForm.propTypes = {
  location: object.isRequired
};
