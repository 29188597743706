import React, { Component, Fragment, lazy, Suspense } from 'react';
import { connect, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import arrayMove from 'array-move';

import { clearAlert, showAlert } from '../../actions';
import { getContacts, getCounts, handlePaging } from '../../actions/contacts';
import { savePreferences } from '../../actions/preferences';
import { Button, ButtonGroup, ContactList, FollowUpList, Loading, Tab, TabContainer, TabGroup } from '../../components';
const ManageTabsButton = lazy(() => import('../../components/Button/ManageTabsButton'));
import { View } from '../../components/View';
import { ViewBody } from '../../components/ViewBody';
import { ViewHeader } from '../../components/ViewHeader';
import { Details } from '../Details';
import { useMediaQueryContext } from '../../components/MediaQueryProvider/MediaQueryProvider';
import { buildTestId, cleanStrOfSymbols, getParamFromSearch, parseParamsStr } from '../../utils';
import { shouldNewUserBePrompted } from '../../utils/authentication';
import { decodeURIComponentSafe } from '../../utils/strings';

import tabContainerStyles from '../../components/Tab/TabContainer.css';
import {
  convertRecentContactsToEntities,
  DEFAULT_CONTACTS_PARAMS,
  getDefaultAssignedToParam
} from '../../utils/contacts';
import debounce from 'lodash/debounce';

export const UNDELETABLE_TABS = ['search', 'all', 'favorites', 'followup', 'recent', 'selected', 'facebook'];
// Default tabs using search (?q=)
const DEFAULT_TABS_BY_SEARCH = ['all', 'fav', 'followup', 'recent', 'facebook'];

const ContactTabs = React.memo(props => {
  const { contactsTabs, tabData, testId } = props;
  const { userInfo } = useSelector(store => store.userProfile);
  const { isRestrictedAgent } = useSelector(store => store.user);
  const { currentlySelected } = useSelector(store => store.contacts);
  const { isTabletLandscapeAndUp } = useMediaQueryContext();

  return contactsTabs.map((key, i) => {
    if (key === 'selected' && currentlySelected.length < 1) {
      return;
    }

    const data = tabData[key];

    if (!data) {
      return null;
    }

    const { count, icon, isLoading, label, matchKey, matchParams, title, url } = data;
    const idBase = cleanStrOfSymbols(url) || (label && label.toLowerCase().replace(/\s/g, ''));
    const id = `${idBase}-${i}`;

    const getDropStyle = (style, snapshot) => {
      if (!UNDELETABLE_TABS.includes(key) && snapshot.isDragging && snapshot.isDropAnimating) {
        return {
          ...style,
          transitionDuration: '0.001s'
        };
      }

      return style;
    };

    const { assignedTo } = parseParamsStr(location.search) || {};

    let definedUrl;
    if (userInfo?.isResponsibleAgent || !isRestrictedAgent) {
      if (url !== '') {
        definedUrl = `${url}&assignedTo=${assignedTo || 'all'}`;
      } else {
        definedUrl = `${url}?assignedTo=${assignedTo || 'all'}`;
      }
    } else {
      definedUrl = url;
    }

    const tabProps = {
      count,
      icon,
      id: key,
      index: i,
      isLoading,
      label,
      matchKey,
      matchParams,
      testId: buildTestId(testId, `Tab-${id}`),
      title,
      url: definedUrl
    };

    if (isTabletLandscapeAndUp) {
      return (
        <Draggable key={id} draggableId={id} index={i}>
          {(provided, snapshot) => (
            <Tab
              {...tabProps}
              isDragging={snapshot.isDragging}
              draggingOver={snapshot.draggingOver}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getDropStyle(provided.draggableProps.style, snapshot)}
            />
          )}
        </Draggable>
      );
    }

    return <Tab key={id} {...tabProps} />;
  });
});

export class Contacts extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.userInfo && location.pathname.endsWith('/contacts')) {
      this.fetchContacts();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      clearAlert,
      currentlySelected,
      currentGroup,
      hasContacts,
      isAssistant,
      location,
      preferences,
      showAlert,
      statusCounts,
      userInfo,
      advancedSearch
    } = this.props;

    const promptNewUser = shouldNewUserBePrompted(hasContacts, location.pathname, isAssistant, statusCounts);

    if (prevProps.userInfo?.isResponsibleAgent !== userInfo?.isResponsibleAgent) {
      this.fetchContacts();
    }

    if (
      location.pathname.endsWith('/contacts') &&
      (location.search !== prevProps.location.search || advancedSearch?.time !== prevProps.advancedSearch?.time)
    ) {
      this.fetchContacts();
      return;
    }

    if (promptNewUser) {
      // There are exactly zero contacts in the status counts and the all count has loaded.
      function alertAction(url) {
        clearAlert();
        navigate(url);
      }

      showAlert({
        isOpen: !hasContacts,
        icon: 'addcontact',
        iconSize: 'm',
        loading: false,
        hint: 'Start by adding or importing to your database.',
        message: 'Welcome to Top Producer X! A great CRM starts with your contacts.',
        primaryButtonLabel: 'Add Contact',
        primaryButtonHandler: () => alertAction('/contacts/add'),
        secondaryButtonLabel: 'Import Contacts',
        secondaryButtonHandler: () => alertAction('/settings/import')
      });
    }

    // Redirect from selected tab if no selections and on selected tab.
    const searchParam = getParamFromSearch(location.search, 'q');

    if (currentlySelected.length < 1 && searchParam === 'selected') {
      navigate(preferences.defaultContactsPath);
    }

    // Redirect from search tab if searchType is advanced but not active
    const searchType = getParamFromSearch(location.search, 'searchType');

    if (searchType && !advancedSearch.isActive && currentGroup !== prevProps.currentGroup) {
      navigate(preferences.defaultContactsPath);
    }
  }

  fetchContacts = debounce(() => {
    const { advancedSearch, getContacts, getCounts, location, user, userInfo, pagingSize } = this.props;
    const params = parseParamsStr(location.search) || DEFAULT_CONTACTS_PARAMS;

    const { assignedTo } = params || {};
    const inSocialConnect = params?.q === 'social connect';

    if (location.pathname.split('/').includes('tasks')) {
      delete params.assignedTo;
    }

    const fetchOptions = { ...getDefaultAssignedToParam(user, userInfo), ...params };

    // Disable caching when on new tab so the lead will come in on refresh
    // Always refresh social connect incoming lead

    // Not supported by BE
    if (inSocialConnect && (assignedTo === 'unassigned' || !userInfo.hasFacebookAdLicense)) {
      return;
    }

    getContacts({
      ...fetchOptions,
      pageSize: parseInt(pagingSize),
      forceRefresh: true,
      replaceGroup: true,
      advancedSearch
    });
    getCounts(assignedTo);
  }, 250);

  confirmDeleteTab = sourceIndex => {
    const { showAlert, preferences } = this.props;
    const tabToRemove = preferences.contactsTabs[sourceIndex];

    if (UNDELETABLE_TABS.includes(tabToRemove)) {
      return;
    }

    showAlert({
      message: 'Are you sure you want to delete this tab?',
      hint: "(Don't worry, you can always re-create it.)",
      icon: 'delete',
      primaryButtonLabel: 'Delete tab',
      primaryButtonHandler: () => this.handleDeleteTab(sourceIndex)
    });
  };

  handleDeleteTab = sourceIndex => {
    const { clearAlert, location, preferences, savePreferences } = this.props;
    const { contactsTabs, customTabs, defaultContactsPath } = preferences;
    const { pathname, search } = location;

    const tabToRemove = contactsTabs[sourceIndex];
    const newTabOrder = [...contactsTabs.slice(0, sourceIndex), ...contactsTabs.slice(sourceIndex + 1)];

    const { [tabToRemove]: value, ...newCustomTabs } = customTabs;

    // Determine if we're removing the defaultContactsPath.
    const url = this.TAB_DATA[tabToRemove]?.url;
    const newDefaultContactsPath =
      contactsTabs.includes('new') && tabToRemove !== 'new' ? pathname : `${pathname}?q=all`;
    const status = getParamFromSearch(defaultContactsPath.split('?')[1], 'status');
    const query = getParamFromSearch(defaultContactsPath.split('?')[1], 'q');

    const cleanDefaultPath = status ? `${pathname}?status=${status}` : query ? `${pathname}?q=${query}` : pathname;
    const isDefaultPath = `${pathname}${url}` === cleanDefaultPath;

    savePreferences(
      {
        ...preferences,
        contactsTabs: newTabOrder,
        customTabs: { ...newCustomTabs },
        defaultContactsPath: isDefaultPath ? newDefaultContactsPath : defaultContactsPath
      },
      preferences
    ).then(() => {
      clearAlert();
    });

    // Determine if active tab for redirect, same logic as Tab.
    const locationParamsObject = parseParamsStr(search) || {};

    let paramsMatch = false;
    const matchParams = this.TAB_DATA[tabToRemove]?.matchParams;
    for (const param in matchParams) {
      paramsMatch = matchParams[param] === locationParamsObject[param];
      if (paramsMatch === false) {
        break;
      }
    }

    if (matchParams ? paramsMatch : search.startsWith(url)) {
      navigate(isDefaultPath ? newDefaultContactsPath : defaultContactsPath);
    }
  };

  handleDragEnd = result => {
    const { preferences, savePreferences } = this.props;
    const { destination, source } = result;

    const { index: sourceIndex } = source;

    if (!destination) {
      this.confirmDeleteTab(sourceIndex);
      return;
    }

    const { index: destinationIndex } = destination;

    if (sourceIndex === destinationIndex) {
      // Do nothing if the the new location of the draggable matches its original location.
      return;
    }

    const newTabOrder = arrayMove(preferences.contactsTabs, sourceIndex, destinationIndex);

    savePreferences({ ...preferences, contactsTabs: newTabOrder }, preferences);
  };

  handleLoadMore = debounce(() => {
    const {
      advancedSearch,
      currentCursor,
      currentGroup,
      groups,
      user,
      userInfo,
      handlePaging,
      statusCounts,
      pagingSize
    } = this.props;

    handlePaging({ currentCursor, currentGroup, groups, user, userInfo, statusCounts, advancedSearch, pagingSize });
  }, 250);

  render() {
    const {
      advancedSearch,
      children,
      contacts,
      currentGroup,
      currentlySelected,
      donePaging,
      groups,
      location,
      isLoading,
      preferences,
      statusCounts,
      testId
    } = this.props;
    const {
      all: countAll,
      new: countNew,
      engage: countEngage,
      future: countFuture,
      active: countActive,
      followup: countFollowup,
      closed: countClosed
    } = statusCounts;
    const { pathname } = location;
    const list = groups[currentGroup];

    if (!list) {
      return <Loading loading={true} />;
    }

    // Recent contacts
    const { recentContacts, recentSearches, customTabs } = preferences || {};
    const countRecent = recentContacts.length;
    const recentList = recentContacts.map(recent => recent.contact.id);
    const recentData = convertRecentContactsToEntities(recentContacts);

    // Selected data
    const selectedData = currentlySelected.reduce(
      (acc, selected) => ({ ...acc, [selected]: contacts[selected] || recentData[selected] }),
      {}
    );

    /* ToDo: This is slightly incorrect, given it fires with the add flow */
    const detailsOpen = pathname.startsWith('/contacts/');

    const searchParam = getParamFromSearch(location.search, 'q');
    const searchType = getParamFromSearch(location.search, 'searchType');

    const CUSTOM_TABS = Object.keys(customTabs).reduce((acc, key) => {
      const tab = customTabs[key];
      const [, tabParam] = tab.url.split('=');
      const count = tab.icon
        ? statusCounts[tab.icon]
        : statusCounts[decodeURIComponentSafe(tabParam).toLowerCase()] || null;

      acc[key] = { ...tab, count, isLoading };

      return acc;
    }, {});

    const searchTabUrl = advancedSearch.isActive ? 'advanced' : recentSearches?.[0]?.searchTerm || '';

    const parsedSearchTabUrl = decodeURIComponentSafe(searchTabUrl)
      ?.toLowerCase()
      ?.replace(/is:(\s+)/, 'is:');
    // The search tab is shown when
    // 1. there's recent search available and
    // 2. current search term doesn't match with any of the custom tabs and
    // 3. current search doesn't match to default tabs enabled by search
    const shouldShowSearchTab =
      recentSearches?.length > 0 &&
      !Object.keys(customTabs).some(
        tab =>
          customTabs[tab].matchKey === 'search' &&
          decodeURIComponentSafe(customTabs[tab].url)
            .replace('?q=', '')
            .toLowerCase()
            .replace(/is:(\s+)/, 'is:') === parsedSearchTabUrl
      ) &&
      !DEFAULT_TABS_BY_SEARCH.includes(parsedSearchTabUrl);

    const isSearchTab =
      searchParam?.toLowerCase()?.replace(/is:(\s+)/, 'is:') === parsedSearchTabUrl ||
      searchType === parsedSearchTabUrl;

    const searchTab = shouldShowSearchTab
      ? {
          search: {
            url: `?${advancedSearch.isActive ? 'searchType' : 'q'}=${parsedSearchTabUrl}`,
            icon: 'search',
            title: 'search',
            label: 'Search',
            matchKey: 'search',
            count: statusCounts?.[advancedSearch.isActive ? 'advanced' : parsedSearchTabUrl] || null,
            isLoading
          }
        }
      : null;

    const TAB_DATA = {
      ...searchTab,
      favorites: {
        url: '?q=fav',
        icon: 'favorite',
        title: 'Favorites',
        matchParams: { q: 'fav' },
        isLoading
      },
      all: {
        url: '?q=all',
        icon: 'contact',
        label: 'All',
        count: countAll,
        matchParams: { q: 'all' },
        isLoading
      },
      new: {
        url: '',
        icon: 'new',
        label: 'New',
        count: countNew,
        matchParams: { q: undefined, status: undefined },
        isLoading
      },
      engage: {
        url: '?status=20',
        icon: 'engage',
        label: 'Engage',
        count: countEngage,
        matchParams: { status: '20' },
        isLoading
      },
      future: {
        url: '?status=40',
        icon: 'future',
        label: 'Future',
        count: countFuture,
        matchParams: { status: '40' },
        isLoading
      },
      active: {
        url: '?status=60',
        icon: 'active',
        label: 'Active',
        count: countActive,
        matchParams: { status: '60' },
        isLoading
      },
      closed: {
        url: '?status=70',
        icon: 'closed',
        label: 'Closed',
        count: countClosed,
        matchParams: { status: '70' },
        isLoading
      },
      followup: {
        url: '?q=followup',
        icon: 'followup',
        label: 'Follow-up Coach',
        count: countFollowup,
        matchParams: { q: 'followup' },
        isLoading
      },
      recent: {
        url: '?q=recent',
        icon: 'recent',
        label: 'Recent',
        count: countRecent,
        matchParams: { q: 'recent' },
        isLoading
      },
      selected: {
        url: '?q=selected',
        icon: 'check',
        label: 'Selected',
        count: currentlySelected.length,
        matchParams: { q: 'selected' },
        isLoading
      },
      facebook: {
        url: '?q=social%20connect&sortBy=touch',
        icon: 'facebook',
        label: 'Social Connect',
        count: statusCounts?.['social connect'] || statusCounts?.['social connect 2'] || null,
        matchParams: { q: 'social%20connect' },
        isLoading
      },
      ...CUSTOM_TABS
    };

    this.TAB_DATA = TAB_DATA;

    return (
      <View isDetailsOpen={detailsOpen}>
        <ViewHeader title="Contacts" titleAs="h1" titleIcon="addressbook" helpKey="contactOrganization">
          <ButtonGroup>
            <Suspense fallback={<Loading loading={true} />}>
              <ManageTabsButton location={location} />
            </Suspense>
            <Button
              url={`${location.pathname}/add${location.search}`}
              icon="addcontact"
              label="Add Contact"
              ariaLabel="Add Contact"
              data-cy="addContactButton"
              styleType="primary"
              className={tabContainerStyles.addButton}
            />
          </ButtonGroup>
        </ViewHeader>
        <TabContainer>
          <div className={tabContainerStyles.content}>
            <DragDropContext onDragEnd={this.handleDragEnd}>
              <Droppable droppableId="tabDroppable" direction="horizontal">
                {provided => (
                  <TabGroup key={testId} ref={provided.innerRef} {...provided.droppableProps}>
                    <ContactTabs
                      testId={buildTestId(testId, 'ContactTabs')}
                      tabData={TAB_DATA}
                      contactsTabs={preferences.contactsTabs}
                    />
                    {provided.placeholder}
                  </TabGroup>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </TabContainer>
        {['/contacts/add', '/contacts/create-tab', '/contacts/quick-add'].includes(pathname) ? (
          <Fragment>{children}</Fragment>
        ) : (
          <Details>{children}</Details>
        )}
        <ViewBody>
          {searchParam === 'followup' ? (
            <FollowUpList
              contacts={contacts}
              isLoading={isLoading}
              list={list}
              location={location}
              donePaging={donePaging[currentGroup]}
              pageHandler={this.handleLoadMore}
            />
          ) : searchParam === 'recent' ? (
            <ContactList
              contacts={recentData}
              followUpReminders={preferences.followUpReminders}
              isLoading={isLoading}
              list={recentList}
              location={location}
              tabData={TAB_DATA}
              donePaging={donePaging[currentGroup]}
              pageHandler={this.handleLoadMore}
            />
          ) : searchParam === 'selected' ? (
            <ContactList
              contacts={selectedData}
              followUpReminders={preferences.followUpReminders}
              isLoading={isLoading}
              list={currentlySelected}
              location={location}
              tabData={TAB_DATA}
              donePaging={donePaging[currentGroup]}
              pageHandler={this.handleLoadMore}
            />
          ) : (
            <ContactList
              contacts={contacts}
              followUpReminders={preferences.followUpReminders}
              isLoading={isLoading}
              list={list}
              location={location}
              donePaging={donePaging[currentGroup]}
              pageHandler={this.handleLoadMore}
              tabData={TAB_DATA}
              isSearchTab={isSearchTab}
            />
          )}
        </ViewBody>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  advancedSearch: state.advancedSearch,
  contacts: state.contacts.entities,
  hasContacts: state.contacts.hasContacts,
  groups: state.contacts.groups,
  currentGroup: state.contacts.currentGroup,
  currentCursor: state.contacts.cursors[state.contacts.currentGroup],
  currentlySelected: state.contacts.currentlySelected,
  statusCounts: state.contacts.statusCounts,
  donePaging: state.contacts.donePaging,
  preferences: state.preferences,
  isLoading: state.contacts.isLoading,
  user: state.user,
  userInfo: state.userProfile.userInfo,
  isAssistant: state.user.isAssistant,
  pagingSize: state.contacts.pageSize
});

const mapDispatchToProps = {
  clearAlert,
  getContacts,
  getCounts,
  savePreferences,
  showAlert,
  handlePaging
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
