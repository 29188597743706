import React from 'react';
import { func, number, string } from 'prop-types';
import classnames from 'classnames';
import styles from './FormRangeInput.css';

export const FormRangeInput = props => {
  const { className, id, label, max, min, onChange, step = 1, value, ...attrs } = props;

  if (!onChange) {
    return null;
  }

  const classes = classnames({
    [styles.rangeInput]: true,
    [className]: !!className
  });

  return (
    <label htmlFor={id} className={classes}>
      <input type="range" id={id} min={min} max={max} step={step} value={value} onChange={onChange} {...attrs} />
      {label}
    </label>
  );
};

FormRangeInput.propTypes = {
  className: string,
  id: string.isRequired,
  label: string,
  max: number.isRequired,
  min: number.isRequired,
  onChange: func.isRequired,
  onMouseUp: func.isRequired,
  step: number,
  value: number
};
