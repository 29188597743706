/** @module */

import { deleteFromCollection, getMergedEntities, getMergedSetOfIds, IndexedTree } from '../utils';

export const TASK_PLANS_SET = 'TASK_PLANS_SET';
export const TASK_PLANS_SET_IN_PROGRESS = 'TASK_PLANS_SET_IN_PROGRESS';
export const TASK_PLANS_SET_CURRENT_GROUP = 'TASK_PLANS_SET_CURRENT_GROUP';

export const TASK_PLANS_GET_START = 'TASK_PLANS_GET_START';
export const TASK_PLANS_GET_SUCCESS = 'TASK_PLANS_GET_SUCCESS';
export const TASK_PLANS_GET_ERROR = 'TASK_PLANS_GET_ERROR';

export const TASK_PLANS_GET_BY_ID_START = 'TASK_PLANS_GET_BY_ID_START';
export const TASK_PLANS_GET_BY_ID_SUCCESS = 'TASK_PLANS_GET_BY_ID_SUCCESS';
export const TASK_PLANS_GET_BY_ID_ERROR = 'TASK_PLANS_GET_BY_ID_ERROR';

export const TASK_PLANS_GET_ITEMS_START = 'TASK_PLANS_GET_ITEMS_START';
export const TASK_PLANS_GET_ITEMS_SUCCESS = 'TASK_PLANS_GET_ITEMS_SUCCESS';
export const TASK_PLANS_GET_ITEMS_ERROR = 'TASK_PLANS_GET_ITEMS_ERROR';

export const TASK_PLANS_ADD_START = 'TASK_PLANS_ADD_START';
export const TASK_PLANS_ADD_SUCCESS = 'TASK_PLANS_ADD_SUCCESS';
export const TASK_PLANS_ADD_ERROR = 'TASK_PLANS_ADD_ERROR';

export const TASK_PLANS_ADD_ITEM_START = 'TASK_PLANS_ADD_ITEM_START';
export const TASK_PLANS_ADD_ITEM_SUCCESS = 'TASK_PLANS_ADD_ITEM_SUCCESS';
export const TASK_PLANS_ADD_ITEM_ERROR = 'TASK_PLANS_ADD_ITEM_ERROR';

export const TASK_PLANS_UPDATE_START = 'TASK_PLANS_UPDATE_START';
export const TASK_PLANS_UPDATE_SUCCESS = 'TASK_PLANS_UPDATE_SUCCESS';
export const TASK_PLANS_UPDATE_ERROR = 'TASK_PLANS_UPDATE_ERROR';

export const TASK_PLANS_UPDATE_ITEM_START = 'TASK_PLANS_UPDATE_ITEM_START';
export const TASK_PLANS_UPDATE_ITEM_SUCCESS = 'TASK_PLANS_UPDATE_ITEM_SUCCESS';
export const TASK_PLANS_UPDATE_ITEM_ERROR = 'TASK_PLANS_UPDATE_ITEM_ERROR';

export const TASK_PLANS_DELETE_START = 'TASK_PLANS_DELETE_START';
export const TASK_PLANS_DELETE_SUCCESS = 'TASK_PLANS_DELETE_SUCCESS';
export const TASK_PLANS_DELETE_ERROR = 'TASK_PLANS_DELETE_ERROR';

export const TASK_PLANS_DELETE_ITEM_START = 'TASK_PLANS_DELETE_ITEM_START';
export const TASK_PLANS_DELETE_ITEM_SUCCESS = 'TASK_PLANS_DELETE_ITEM_SUCCESS';
export const TASK_PLANS_DELETE_ITEM_ERROR = 'TASK_PLANS_DELETE_ITEM_ERROR';

export const TASK_PLANS_APPLY_START = 'TASK_PLANS_APPLY_START';
export const TASK_PLANS_APPLY_SUCCESS = 'TASK_PLANS_APPLY_SUCCESS';
export const TASK_PLANS_APPLY_ERROR = 'TASK_PLANS_APPLY_ERROR';

export const TASK_PLANS_MASS_APPLY_START = 'TASK_PLANS_MASS_APPLY_START';
export const TASK_PLANS_MASS_APPLY_SUCCESS = 'TASK_PLANS_MASS_APPLY_SUCCESS';
export const TASK_PLANS_MASS_APPLY_ERROR = 'TASK_PLANS_MASS_APPLY_ERROR';

export const TASK_PLANS_UNAPPLY_START = 'TASK_PLANS_UNAPPLY_START';
export const TASK_PLANS_UNAPPLY_SUCCESS = 'TASK_PLANS_UNAPPLY_SUCCESS';
export const TASK_PLANS_UNAPPLY_ERROR = 'TASK_PLANS_UNAPPLY_ERROR';

export const TASK_PLANS_REFRESH_PLANS_START = 'TASK_PLANS_REFRESH_PLANS_START';
export const TASK_PLANS_REFRESH_PLANS_SUCCESS = 'TASK_PLANS_REFRESH_PLANS_SUCCESS';
export const TASK_PLANS_REFRESH_PLANS_ERROR = 'TASK_PLANS_REFRESH_PLANS_ERROR';

export const TASK_PLANS_GET_ALL_RULES = 'TASK_PLANS_GET_ALL_RULES';
export const TASK_PLANS_RESET_RULES = 'TASK_PLANS_RESET_RULES';
export const TASK_PLANS_DELETE_RULE = 'TASK_PLANS_DELETE_RULE';

export const initialState = {
  currentGroup: '',

  // entities (plans):
  // by entityId (planId)
  // shape: {
  //  ...planInfo,
  //  items: IndexedTree,
  //  options
  // }
  //
  isTaskPlansFormModalOpen: false,
  entities: {},
  groups: {}, // grouped by categories
  doneLoading: {}, // list: groups that are done loading
  detailsAreDoneLoading: {}, // by entityId
  itemsAreDoneLoading: {}, // by entityId
  isLoading: false, // ADD/UPDATE/DELETE
  leadAutoResponseRules: []
};

export const taskPlansReducer = (state = initialState, action) => {
  const { type = 'default', payload = {} } = action || {};

  switch (type) {
    case TASK_PLANS_GET_START: {
      const { groupName } = payload;
      return {
        ...state,
        currentGroup: groupName,
        doneLoading: {
          ...state.doneLoading,
          [groupName]: false
        },
        isLoading: true
      };
    }
    case TASK_PLANS_ADD_START:
    case TASK_PLANS_ADD_ITEM_START:
    case TASK_PLANS_UPDATE_START:
    case TASK_PLANS_UPDATE_ITEM_START:
    case TASK_PLANS_APPLY_START:
    case TASK_PLANS_UNAPPLY_START:
    case TASK_PLANS_REFRESH_PLANS_START:
    case TASK_PLANS_DELETE_START: {
      return { ...state, isLoading: true };
    }
    case TASK_PLANS_GET_SUCCESS: {
      const { data, groupName } = payload;
      const newData = Array.isArray(data) ? data : [];
      return {
        ...state,
        entities: getMergedEntities(state.entities, newData, { ignorePreexisting: true }),
        groups: {
          ...state.groups,
          [groupName]: getMergedSetOfIds(state.groups[groupName], newData)
        },
        doneLoading: {
          ...state.doneLoading,
          [groupName]: true
        },
        isLoading: false
      };
    }
    case TASK_PLANS_ADD_SUCCESS: {
      // when the add is successful, we want to insert
      // the new entity in the entities object, and
      // push its id to the corresponding group.
      const { entity, groupName } = payload;

      // let's make sure the entity has an id
      if (!entity.id) {
        return state;
      }

      // let's make sure to update All

      const groupForCategoryAll = Object.values(state.groups)[0];
      groupForCategoryAll.push(entity.id);

      const newGroups = {
        0: groupForCategoryAll
      };

      // now let's check if there is a group
      // for the entity's category, and update it
      const group = state.groups[groupName];
      if (group) {
        group.push(entity.id);
        newGroups[groupName] = group;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [entity.id]: entity
        },
        groups: {
          ...state.groups,
          ...newGroups
        },
        isLoading: false
      };
    }
    case TASK_PLANS_MASS_APPLY_SUCCESS: {
      return {
        ...state,
        displayTaskPlansForm: false
      };
    }
    case TASK_PLANS_UPDATE_SUCCESS: {
      // when update is successful, we just need
      // to update the entity in the entities object
      const { entity } = payload;

      // let's make sure the entity has an id
      if (!entity.id) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [entity.id]: {
            items: state.entities[entity.id].items,
            ...entity
          }
        },
        isLoading: false
      };
    }
    case TASK_PLANS_DELETE_SUCCESS:
    case TASK_PLANS_UNAPPLY_SUCCESS: {
      const { entityId } = payload;

      if (!entityId) {
        return state;
      }

      // delete entity from entities object
      const entities = { ...state.entities };
      delete entities[entityId];

      // delete entity id from groups
      const groups = {};
      for (const groupName of Object.keys(state.groups)) {
        groups[groupName] = state.groups[groupName].filter(id => id !== entityId);
      }

      return {
        ...state,
        entities,
        groups,
        isLoading: false
      };
    }

    case TASK_PLANS_ADD_ITEM_SUCCESS:
    case TASK_PLANS_UPDATE_ITEM_SUCCESS:
    case TASK_PLANS_DELETE_ITEM_SUCCESS:
    case TASK_PLANS_APPLY_SUCCESS:
    case TASK_PLANS_MASS_APPLY_SUCCESS:
    case TASK_PLANS_REFRESH_PLANS_SUCCESS:
    case TASK_PLANS_GET_ERROR:
    case TASK_PLANS_ADD_ERROR:
    case TASK_PLANS_UPDATE_ERROR:
    case TASK_PLANS_ADD_ITEM_ERROR:
    case TASK_PLANS_UPDATE_ITEM_ERROR:
    case TASK_PLANS_APPLY_ERROR:
    case TASK_PLANS_MASS_APPLY_ERROR:
    case TASK_PLANS_UNAPPLY_ERROR:
    case TASK_PLANS_REFRESH_PLANS_ERROR:
    case TASK_PLANS_DELETE_ERROR: {
      return { ...state, isLoading: false };
    }

    case TASK_PLANS_GET_BY_ID_START: {
      const { id } = payload;
      return {
        ...state,
        detailsAreDoneLoading: {
          ...state.detailsAreDoneLoading,
          [id]: false // start loading...
        }
      };
    }
    case TASK_PLANS_GET_BY_ID_SUCCESS: {
      const { data, id } = payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            items: state.entities[id].items,
            ...data
          }
        },
        detailsAreDoneLoading: {
          ...state.detailsAreDoneLoading,
          [id]: true
        }
      };
    }
    case TASK_PLANS_GET_BY_ID_ERROR: {
      const { id } = payload;
      return {
        ...state,
        detailsAreDoneLoading: {
          ...state.detailsAreDoneLoading,
          [id]: true
        }
      };
    }

    case TASK_PLANS_GET_ITEMS_START: {
      const { id } = payload;
      return {
        ...state,
        itemsAreDoneLoading: {
          ...state.itemsAreDoneLoading,
          [id]: false // start loading...
        }
      };
    }
    case TASK_PLANS_GET_ITEMS_SUCCESS: {
      const { data, id } = payload;

      const items = new IndexedTree(data);

      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            ...state.entities[id],
            items
          }
        },
        itemsAreDoneLoading: {
          ...state.itemsAreDoneLoading,
          [id]: true
        }
      };
    }
    case TASK_PLANS_GET_ITEMS_ERROR: {
      const { id } = payload;
      return {
        ...state,
        itemsAreDoneLoading: {
          ...state.itemsAreDoneLoading,
          [id]: true
        }
      };
    }

    case TASK_PLANS_DELETE_ITEM_START: {
      const { planId, id: itemId } = payload;

      // optimistically remove the item.
      const items = state.entities[planId].items;
      items.delete(itemId);

      return {
        ...state,
        entities: {
          ...state.entities,
          [planId]: {
            ...state.entities[planId],
            items
          }
        },
        isLoading: true
      };
    }
    case TASK_PLANS_DELETE_ITEM_ERROR: {
      const { planId, item } = payload;

      // put item back
      const items = state.entities[planId].items;
      items.add(item);

      return {
        ...state,
        entities: {
          ...state.entities,
          [planId]: {
            ...state.entities[planId],
            items
          }
        },
        isLoading: false
      };
    }
    case TASK_PLANS_GET_ALL_RULES: {
      const { data } = payload;
      return {
        ...state,
        leadAutoResponseRules: getMergedEntities(state.leadAutoResponseRules, data, { ignorePreexisting: false })
      };
    }
    case TASK_PLANS_DELETE_RULE: {
      const { id } = payload;

      return {
        ...state,
        leadAutoResponseRules: {
          ...deleteFromCollection(state.leadAutoResponseRules, id)
        }
      };
    }
    case TASK_PLANS_RESET_RULES: {
      return {
        ...state,
        leadAutoResponseRules: []
      };
    }

    default:
      return state;
  }
};
