import { format } from 'date-fns';
import { string } from 'prop-types';
import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactions } from '../../../actions';
import { Dialog, DialogHeader, Loading } from '../../../components';
import TransactionsList from '../../../components/List/TransactionsList';
import TransactionFormDialog, {
  toggleTransactionFormDialog
} from '../../../components/TransactionForm/TransactionFormDialog';
import { DATE_FORMATS } from '../../../constants';
import { getDateFromToday } from '../../../utils';
import { ALL_ACTIVE, NON_ACTIVE_FLAG } from '../../../utils/transactions';
import styles from './Listings.css';
import TransactionPartyForm from '../../../components/TransactionForm/TransactionPartyForm';
import { TRANSACTIONS_ADD_PARTY_FORM_TOGGLE } from '../../../reducers/transactions';

export const Listings = () => {
  const dispatch = useDispatch();
  const transactions = useSelector(state => state.transactions);
  const preferences = useSelector(state => state.preferences);

  const { transactions: transactionsPref } = preferences;

  const dateTo = transactionsPref?.transactionRange?.dateTo;
  const dateFrom = transactionsPref?.transactionRange?.dateFrom;
  const noResultsMessage = 'No transactions found.';

  // Default range date is from 364 to today
  const startDate = format(getDateFromToday(parseInt(dateFrom)), DATE_FORMATS.ISO_DATE);
  const endDate = format(getDateFromToday(parseInt(dateTo)), DATE_FORMATS.ISO_DATE);

  const { currentGroup, entities, groups, isLoading, toggleForm, updateTransactionId, toggleAddPartyForm, message } =
    transactions;

  const group = `0${currentGroup?.substring(1)}`;
  useEffect(() => {
    const options = {
      status: ALL_ACTIVE,
      buyer: true,
      seller: true,
      startDate,
      endDate,
      source: ''
    };
    dispatch(getTransactions(options, true));

    dispatch(getTransactions({ ...options, status: NON_ACTIVE_FLAG }, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isUpdateMode = !!updateTransactionId;
  const dialogTitle = isUpdateMode ? 'Update transaction' : 'Add transaction';

  const clearHandler = e => {
    toggleTransactionFormDialog(e, dispatch, location);
  };

  const toggleAddForm = e => {
    e.preventDefault();

    dispatch({
      type: TRANSACTIONS_ADD_PARTY_FORM_TOGGLE
    });
  };

  const list = groups?.[group]?.slice(0, 3) || [];
  return (
    <div className={styles.wrapper}>
      <TransactionsList
        isLoading={isLoading}
        entities={entities}
        currentList={list}
        noResultsMessage={noResultsMessage}
        location={location}
      />
      <Suspense fallback={<Loading />}>
        <TransactionFormDialog
          clearHandler={clearHandler}
          location={location}
          isOpen={toggleForm}
          title={dialogTitle}
        />

        <Dialog isOpen={toggleAddPartyForm}>
          <DialogHeader title="Add transaction party" icon="transactionparty" clearHandler={toggleAddForm} />
          <TransactionPartyForm bannerMessage={message} />
        </Dialog>
      </Suspense>
    </div>
  );
};

Listings.propTypes = {
  className: string
};
