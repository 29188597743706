import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { LinkList } from '../LinkList';
import { BUILD_VERSION } from '../../constants';
import { PRIVACY_LINKS } from '../../data/privacy-links';

import styles from './Footer.css';
import documentStyles from '../Document/Document.css';

export const Footer = props => {
  const { className, ...attrs } = props;

  const classes = classnames({
    [styles.footer]: !className,
    [className]: !!className
  });

  const currentYear = new Date().getFullYear();
  const copyright = `© ${currentYear} Top Producer Systems Company`;

  return (
    <footer className={classes} {...attrs}>
      <span className={documentStyles.middotSeparated}>{copyright}</span>
      <span className={documentStyles.middotSeparated}>v.{BUILD_VERSION}</span>
      <LinkList data={PRIVACY_LINKS} orientation="horizontal" type="copy" />
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};
