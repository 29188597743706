import { navigate } from '@reach/router';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMlsCredentials, getMlsSources } from '../../actions/mls';
import { clearTempListings, getPropertyById } from '../../actions/propertyInsights';
import { getTransaction, getTransactionParty } from '../../actions/transactions';
import tabContainerStyles from '../../components/Tab/TabContainer.css';
import { COUNTRIES_VALUE_TO_CODE_MAP } from '../../data/countries';
import ErrorMLSCredentials from '../../pages/Settings/ErrorMlsCredentials';
import { generateCleanParamsStr, getParamFromSearch, parseParamsStr } from '../../utils';
import {
  checkCanAccessMLSData,
  checkMlsValidity,
  hasMlsCredentials,
  hasOneMlsCredential,
  reduceMlsCredentials
} from '../../utils/mlsBoards';
import { Form } from '../Form';
import { Loading } from '../Loading';
import { MlsErrorMessageBanner } from '../MessageBanner/MlsErrorMessageBanner';
import { Tab, TabContainer, TabGroup } from '../Tab';
import AddTransactionForm from './AddTransactionForm';
import { ImportForm } from './ImportForm';
import styles from './TransactionForm.css';

const TransactionForm = props => {
  const dispatch = useDispatch();
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [mlsBoard, setMlsBoard] = useState(undefined); // Undefined prevents React warning on null value for select.
  const [mlsCredentials, setMlsCredentials] = useState(undefined);
  const [isMlsValid, setIsMlsValid] = useState(true);
  const [isMlsDataAccessApproved, setIsMlsDataAccessApproved] = useState(true);
  const [listingPreview, setListingPreview] = useState(null);
  const mls = useSelector(state => state.mls);
  const { credentials: reduxMlsCredentials, sources, credentialsLoading, sourcesLoading } = mls || {};
  const mlsCredsError = mlsCredentials == null;
  const { tempListings, showNoTempListingsError, insightId, entities } = useSelector(state => state.propertyInsights);
  const { propertyId, entities: propertiesEntities } = useSelector(state => state.properties);

  const property = propertiesEntities?.[propertyId];

  const parsePropertyData = property => {
    if (property == null) {
      return null;
    }
    const { houseNumber, dirPrefix, streetName, streetType, unit, city, state, county, country, zip, line } =
      property?.address || {};
    return {
      listing: {
        description: {
          baths: property.baths,
          beds: property.beds,
          sqft: property.sqft,
          year_built: property.year_built,
          type: property.propertyType
        },
        location: {
          address: {
            city,
            country: COUNTRIES_VALUE_TO_CODE_MAP.get(country) || country,
            county,
            line,
            postal_code: zip,
            state_code: state,
            street_direction: dirPrefix,
            street_name: streetName,
            street_number: houseNumber,
            street_suffix: streetType,
            unit
          },
          country: { name: country }
        }
      }
    };
  };
  const propertyData = parsePropertyData(property);

  const { focusId, updateTransactionId } = useSelector(state => state.transactions);
  const [lookupIsValid, setLookupIsValid] = useState(!showNoTempListingsError);
  const [isManualAdd, setIsManualAdd] = useState(false);
  const [mlsSearchValue, setMlsSearchValue] = useState('');

  const { contactId, location } = props;
  const { pathname, search } = location;

  const formRef = useRef();

  const currentParams = parseParamsStr(search) || {};

  const { mode, ...newParams } = currentParams;

  const TAB = {
    IMPORT: { matchParams: { mode: undefined }, url: `${pathname}${generateCleanParamsStr(newParams)}` },
    MANUAL: {
      matchParams: { mode: 'manual' },
      url: `${pathname}${generateCleanParamsStr({ mode: 'manual', ...newParams })}`
    }
  };

  useEffect(() => {
    dispatch(clearTempListings());
    dispatch(getMlsCredentials());
    dispatch(getMlsSources());
  }, [dispatch]);

  // Get the latest data for this transaction before updating.
  useEffect(() => {
    if (updateTransactionId) {
      dispatch(getTransaction({ transactionId: updateTransactionId, forceRefresh: true }));
      dispatch(getTransactionParty({ transactionId: updateTransactionId, forceRefresh: true }));
    }
  }, [dispatch, updateTransactionId]);

  useEffect(() => {
    if (reduxMlsCredentials) {
      const reducedMlsCredentials = reduceMlsCredentials(reduxMlsCredentials, sources);
      setMlsCredentials(reducedMlsCredentials);
      setMlsBoard(Object.keys(reducedMlsCredentials)[0]);
    }
  }, [reduxMlsCredentials, sources]);

  useEffect(() => {
    if (mlsBoard) {
      setIsMlsValid(checkMlsValidity(mlsCredentials[mlsBoard]));
      setIsMlsDataAccessApproved(checkCanAccessMLSData(mlsCredentials[mlsBoard]));
    }
  }, [mlsCredentials, mlsBoard]);

  useEffect(() => {
    const { search } = location;
    const mode = getParamFromSearch(search, 'mode');

    if (mode === 'manual') {
      setIsManualAdd(true);
    } else {
      setIsManualAdd(false);
    }
  }, [location, setIsManualAdd]);

  useEffect(() => {
    // When mls creds fails to load, enforce manual mode
    if (mlsCredsError) {
      navigate(TAB.MANUAL.url);
    }
  }, [mlsCredsError, TAB.MANUAL.url]);

  if (mlsCredentials?.length === 0 || sources?.length === 0) {
    const hasCredentials = hasMlsCredentials(mlsCredentials);
    const hasOneCredential = hasOneMlsCredential(mlsCredentials);
    return (
      <Form id="transactionForm">
        {credentialsLoading || sourcesLoading ? (
          <Loading />
        ) : (
          <ErrorMLSCredentials hasCredentials={hasCredentials} hasOneCredential={hasOneCredential} />
        )}
      </Form>
    );
  }

  const handleChangeMlsBoard = e => {
    setMlsBoard(e.target.value);
  };

  const handleLookupSelect = selection => {
    const { mpr_id, mls_number, line, city, state_code, postal_code } = selection;

    setFormIsSubmitting(true);

    setListingPreview({ location: { address: { line, city, state_code, postal_code } } });
    dispatch(
      getPropertyById(
        mpr_id
          ? {
              source: mlsBoard,
              propertyId: mpr_id
            }
          : { source: mlsBoard, mlsNumber: mls_number }
      )
    ).then(success => {
      setListingPreview(null);

      if (success) {
        setLookupIsValid(true);
      } else {
        setLookupIsValid(false);
      }
    });
  };

  const isUpdateMode = !!updateTransactionId;

  const isAddFromPropertyInsights = !!insightId;
  const isAddFromPropertyMode = !!propertyId;

  const mlsStates = mlsBoard && sources ? sources[mlsBoard]?.states : null;

  const hasTempListings = tempListings && tempListings.length > 0;
  const hasListings =
    hasTempListings || listingPreview || isUpdateMode || isAddFromPropertyInsights || isAddFromPropertyMode;

  const formData = (() => {
    // From contact property insights
    if (isAddFromPropertyInsights) {
      return [entities[insightId]];
    }
    // From contact properties
    if (isAddFromPropertyMode) {
      return propertyData;
    }
    // from look up
    return hasTempListings ? tempListings : [listingPreview];
  })();

  const showAddTransactionForm = hasListings || isManualAdd;

  // Only show the tabs when creating a new transaction
  const showTab = !isUpdateMode && !isAddFromPropertyMode && !isAddFromPropertyInsights && !mlsCredsError;

  return (
    <Fragment>
      {mlsCredsError && (
        <MlsErrorMessageBanner message="Failed to load MLS credentials. You can only manually add a transaction." />
      )}
      {showTab && (
        <div className={styles.container}>
          <TabContainer>
            <div className={tabContainerStyles.content}>
              <TabGroup>
                <Tab id="import" url={TAB.IMPORT.url} label="Import from MLS" matchParams={TAB.IMPORT.matchParams} />
                <Tab id="manual" url={TAB.MANUAL.url} label="Add manually" matchParams={TAB.MANUAL.matchParams} />
              </TabGroup>
            </div>
          </TabContainer>
        </div>
      )}
      {showAddTransactionForm ? (
        <AddTransactionForm
          contactId={contactId}
          data={formData}
          focusId={focusId}
          formRef={formRef}
          formIsSubmitting={formIsSubmitting}
          location={location}
        />
      ) : (
        <ImportForm
          formIsSubmitting={formIsSubmitting}
          formRef={formRef}
          handleChangeMlsBoard={handleChangeMlsBoard}
          handleLookupSelect={handleLookupSelect}
          hasListings={hasListings}
          isMlsDataAccessApproved={isMlsDataAccessApproved}
          isMlsValid={isMlsValid}
          lookupIsValid={lookupIsValid}
          setLookupIsValid={setLookupIsValid}
          mlsBoard={mlsBoard}
          mlsCredentials={mlsCredentials}
          mlsSearchValue={mlsSearchValue}
          setMlsSearchValue={setMlsSearchValue}
          mlsStates={mlsStates}
          setListingPreview={setListingPreview}
          showNoTempListingsError={showNoTempListingsError}
        />
      )}
    </Fragment>
  );
};

export default TransactionForm;
