import { getArrayOfEntities } from '../../utils/collections';

export const EDITOR_TEMPLATE_CATEGORIES = [
  { id: 'currentBuyer', value: 'Current buyer' },
  { id: 'currentSeller', value: 'Current seller' },
  { id: 'events', value: 'Events' },
  { id: 'leadConversion', value: 'Lead Conversion' },
  { id: 'pastClient', value: 'Past client' },
  { id: 'prospecting', value: 'Prospecting' },
  { id: 'request', value: 'Request' },
  { id: 'other', value: 'Other' }
];

export const EDITOR_TEMPLATES = {
  // PLEASE LEAVE SYSTEM TEMPLATES COMMENTED OUT FOR NOW.  CAN BE REMOVED AFTER A FUTURE RELEASE.
  // templatePastClientFollowUp: {
  //   awsFileId: 'templatePastClientFollowUp',
  //   body: {
  //     area: ['contact', 'agent'],
  //     subject: "How's the new place?",
  //     body: "<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>Just wanted to check in on you and the family and see how you're doing. Hope you're settling into your new place and neighborhood!</div><div><br></div><div>As always, feel free to reach out with any questions. From neighborhood info to home improvement recommendations, I'm here for you!</div><div><br></div><div>Talk soon,</div><div>{{agent.firstName}}</div>"
  //   },
  //   category: 'pastClient',
  //   description: 'Check in 2 weeks after move',
  //   objectType: 99,
  //   subType: 1
  // },
  // templateBuyerProspectQuickQuestions: {
  //   awsFileId: 'templateBuyerProspectQuickQuestions',
  //   body: {
  //     area: ['contact', 'agent'],
  //     subject: 'A couple of quick questions',
  //     body: "<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>It was great meeting you recently. You must be so excited at the prospect of getting into a new home!</div><div><br></div><div>Would you like to set up a time to talk about your current situation and what you're looking for? If so, just let me know a time that works best for you.</div><div><br></div><div>Also, if you let me know which area you're interested in, I can send you a market report that'll give you key insights about the area, including the average asking and sold prices.</div><div><br></div><div>I look forward to hearing from you!</div><div><br></div><div>{{agent.firstName}}</div>"
  //   },
  //   category: 'currentBuyer',
  //   description: 'Initial touch after meeting',
  //   objectType: 99,
  //   subType: 1
  // },
  // templateSellerProspectQuickQuestions: {
  //   awsFileId: 'templateSellerProspectQuickQuestions',
  //   body: {
  //     area: ['contact', 'agent'],
  //     subject: 'Quick question',
  //     body: "<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>It was great meeting you recently!</div><div><br></div><div>You mentioned you may be looking to sell your home. Are there any initial questions I can answer for you? If you'd like to set up a time to talk about your current situation, just let me know a time that works best for you.</div><div><br></div><div>I can also send you a market report for your area that'll give you key insights, including the average asking price and days on market.</div><div><br></div><div>Hope this note finds you well. Talk soon,</div><div><br></div><div>{{agent.firstName}}</div>"
  //   },
  //   category: 'currentSeller',
  //   description: 'Initial touch after meeting',
  //   objectType: 99,
  //   subType: 1
  // },
  // templateNonResponsiveCheckIn: {
  //   awsFileId: 'templateNonResponsiveCheckIn',
  //   body: {
  //     area: ['contact', 'agent'],
  //     subject: 'Checking in',
  //     body: "<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>Just wanted to take a second to check in and see if you'd have a chance to look through my previous e-mail.</div><div><br></div><div>If you are interested in having me set you up to receive regular market updates as they happen, don't hesitate to let me know.</div><div><br></div><div>Best regards,</div><div>{{agent.firstName}}</div>"
  //   },
  //   category: 'leadConversion',
  //   description: 'Non-responsive lead - check in 1',
  //   objectType: 99,
  //   subType: 1
  // },
  // templateNonResponsiveStillLooking: {
  //   awsFileId: 'templateNonResponsiveStillLooking',
  //   body: {
  //     area: ['contact', 'agent'],
  //     subject: 'Are you still looking?',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>I know it can be scary and sometimes frustrating finding the home of your dreams, so I am always straightforward with clients like yourself who have inquired about buying a home but have not moved forward.</div><div><br></div><div>Have you given up on buying a home?</div><div><br></div><div>You can simply reply with Y for “Yes” and N for “No” to make it simple, I know you are probably very busy, and I do not want to continue to email you if these emails no longer pertain to you.</div><div><br></div><div>Thank you in advance for your response.</div><div><br></div><div>{{agent.firstName}}</div>'
  //   },
  //   category: 'leadConversion',
  //   description: 'Non-responsive lead - check in 2',
  //   objectType: 99,
  //   subType: 1
  // },
  // templateNonResponsiveNotThisTime: {
  //   awsFileId: 'templateNonResponsiveNotThisTime',
  //   body: {
  //     area: ['contact', 'agent'],
  //     subject: "Sorry that it didn't work out this time around",
  //     body: "<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>It's been a while since I've heard from you regarding your recent real estate inquiry. I'm assuming you've gone in a different direction, or your priorities have changed. If I can be of any assistance in the future don't hesitate to get in touch.</div><div><br></div><div>Best regards,</div><div>{{agent.firstName}}</div>"
  //   },
  //   category: 'leadConversion',
  //   description: 'Non-responsive lead - giving up',
  //   objectType: 99,
  //   subType: 1
  // },
  //
  // templateAskTestimonial: {
  //   description: 'Testimonial',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'request',
  //     subject: 'Quick question',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>Would you be open-minded to [leaving an online review / shooting a quick video] to share your experience working with me?</div><div><br></div><div>I know it would help a lot of people just like you.</div><div><br></div><div>Thanks,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateAskTestimonial',
  //   objectType: 99
  // },
  // templateBuyersDecide: {
  //   description: 'Buyer taking a long time to decide',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'currentBuyer',
  //     subject: 'Quick question',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>Would you be open-minded to me making some small changes to your home search criteria if it meant that you could find the perfect home faster?</div><div><br></div><div>Thanks,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateBuyersDecide',
  //   objectType: 99
  // },
  // templateReferral: {
  //   description: 'Referral',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'request',
  //     subject: 'Just checking in',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>I hope you and [spouse, children, family, pets] have been doing well! It was so wonderful to work with you. I'm happy to say it's been such a busy year in [neighborhood name].</div><div><br></div><div>One of the ways I've been able to work with so many fantastic people is through referrals from former clients. It's such an honor to be able to find the perfect home for many of them and help others move on to new locations.</div><div><br></div><div>If you have any friends, family, or co-workers who are thinking about buying or selling in our area, I'd be honored if you'd consider referring them to me.</div><div><br></div><div>You can reach me via call or text at {{agent.mobilePhone}}. I'd be happy to follow up with them to offer the same level of service I offered to you.</div><div><br></div><div>Best regards,</div><div>{{agent.firstName}} {{agent.lastName}}</div>'
  //   },
  //   fileId: 'templateReferral',
  //   objectType: 99
  // },
  // templateExpiredListing: {
  //   description: 'Expired listing',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'prospecting',
  //     subject: 'Selling your property',
  //     body: "<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>I noticed that your listing recently expired. I know what a frustrating time this can be, and I want to let you know I can help. I've helped many others in the same situation. They were rightfully skeptical in the beginning, but we worked together to achieve the desired outcome—selling at market value in a reasonable timeframe.</div><div><br></div><div>I believe with smart marketing, my knowledge of the neighborhood, and the latest technology, we can get this back on track. If you'd like to hear my thoughts on what we should do differently and how to best position your property, let me know a time that works for you. Alternatively, if you'd like to take a breather and stay in the loop on what's going on in the local market, I can send a report that can help with that too. I hope to hear from you soon.</div><div><br></div><div>Thanks,</div><div>{{agent.firstName}} {{agent.lastName}}</div>"
  //   },
  //   fileId: 'templateExpiredListing',
  //   objectType: 99
  // },
  // templateFSBO: {
  //   description: 'FSBO',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'prospecting',
  //     subject: 'Selling your home',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>I noticed that you put your home up for sale yourself. I'm wondering if I can help in any way. I recently sold homes in the neighborhood and I have active buyers looking in the area.</div><div><br></div><div>I understand that saving on the commission is probably very important to you. But let me assure you, homes that sell with a real estate agent get a higher overall price than those that sell without one, which often makes up for the commission you pay.</div><div><br></div><div>I would be happy to provide a free estimate of your home's value. Would it be possible to schedule a time to meet and discuss how I can help? You can reach me via call or text at {{agent.mobilePhone}}. I'll share a marketing strategy with you that I'm sure will get your home sold quickly. I can also send you a report to help you stay in the loop on what's going on in the local market. Just let me know what you need.</div><div><br></div><div>Best regards,</div><div>{{agent.firstName}} {{agent.lastName}}</div>'
  //   },
  //   fileId: 'templateFSBO',
  //   objectType: 99
  // },
  // templateHomeAnniversary: {
  //   description: 'Home anniversary',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'pastClient',
  //     subject: 'Can you believe it's been [a year / x years]?',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>Just wanted to send you a note to wish you a happy anniversary on the purchase of your home. It's hard to believe it's been [a year / x years] already! Let me know if there's ever anything I can help with, and thanks for allowing me to be part of the process.</div><div><br></div><div>Talk soon,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateHomeAnniversary',
  //   objectType: 99
  // },
  // templateHomeHolidays: {
  //   description: 'Happy holidays',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'events',
  //     subject: "There's no place like home for the holidays",
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>With the holiday season fast approaching, our thoughts shift to those near and dear. Just wanted to write you a note to wish you all the best for the holidays and the new year ahead.</div><div><br></div><div>If there's ever anything I can help with, let me know.</div><div><br></div><div>All the best to you and yours,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateHomeHolidays',
  //   objectType: 99
  // },
  // templateLocalMarketDropping: {
  //   description: 'Local market update - dropping',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'currentBuyer',
  //     subject: 'Quick update on our housing market',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>Just wanted to let you know what's happening locally in our market. It seems like prices are dropping, and while you're well positioned to take your time and not rush into a decision, you can move aggressively if you're wanting to make a move and find a property you like.</div><div><br></div><div>I'll keep you posted if I see prices start to stabilize or increase, and will send any new listings your way so you can see them. In the meantime, if you want to get together to review any of this, let me know and we'll plan a strategy that works for you.</div><div><br></div><div>Thanks,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateLocalMarketDropping',
  //   objectType: 99
  // },
  // templateLocalMarketHolding: {
  //   description: 'Local market update - holding steady',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'currentBuyer',
  //     subject: 'Quick update on our housing market',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>Just wanted to let you know what's happening locally in our market. It seems like prices are holding steady and there seems to be some stability in the market. If you're looking to make a move, you can take your time and do your due diligence.</div><div><br></div><div>I'll keep you posted if anything changes, and will send any new listings your way so you can see them. In the meantime, if you want to get together to review any of this, let me know and we'll plan a strategy that works for you.</div><div><br></div><div>Thanks,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateLocalMarketHolding',
  //   objectType: 99
  // },
  // templateLocalMarketRise: {
  //   description: 'Local market update - on the rise',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'currentBuyer',
  //     subject: "What's happening in our housing market",
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>Just wanted to let you know what's happening locally in our market. It seems like prices are on the rise, and while I can't say for certainty if this is temporary or a trend, I'm keeping a close watch and will let you know if a pattern emerges.</div><div><br></div><div>If you're looking to make a move soon, we should get together to plan a strategy so we don't get caught off-guard should this turn into a pricing trend.</div><div><br></div><div>Talk soon,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateLocalMarketRise',
  //   objectType: 99
  // },
  // templateLowerAsking: {
  //   description: 'Ask for a lower price',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'currentSeller',
  //     subject: 'Quick question',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>Would you be open-minded to lowering your asking price if it would create some immediate interest?</div><div><br></div><div>Thanks,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateLowerAsking',
  //   objectType: 99
  // },
  // templateMarketChanging: {
  //   description: 'The market is changing - picking up',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'prospecting',
  //     subject: 'Things are looking up!',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>What a difference a few years makes! If you haven't heard about recent trends in our real estate market, things are really picking up and have made a dramatic change for the better. If you'd like to know what this means for you or what opportunities this opens up, just let me know. I hope this note finds you well!</div><div><br></div><div>Talk soon,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateMarketChanging',
  //   objectType: 99
  // },
  // templateNewLeadLight: {
  //   description: 'New lead - light survey',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'leadConversion',
  //     subject: 'Thanks for visiting – what category fits you best?',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>I noticed you visited my real estate website and I wanted to check in to see if I can help. Which category would you say you fit into?</div><div><br></div><div>1) You were just looking and don't have any questions.\n2) You may want to ask a few questions about the market.\n3) Would love to talk—you're very interested in the local market and have lots of questions.</div><div><br></div><div>If it's either 2 or 3, I can help! Let me know.</div><div><br></div><div>Thanks,</div><div>{{agent.firstName}} {{agent.lastName}}</div>'
  //   },
  //   fileId: 'templateNewLeadLight',
  //   objectType: 99
  // },
  // templateNewLeadNoPressure: {
  //   description: 'New lead - no pressure',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'leadConversion',
  //     subject: 'Thanks for visiting – let me know how I can help',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>I noticed you visited my real estate website and I wanted to check in to see if I can help. If you were just looking and don't have any questions, you can disregard this email. But if you're interested in the local real estate market and have a few questions, whether you're looking to buy in two months or two years, I'd be happy to answer them! Let me know.</div><div><br></div><div>Thanks,</div><div>{{agent.firstName}} {{agent.lastName}}</div>'
  //   },
  //   fileId: 'templateNewLeadNoPressure',
  //   objectType: 99
  // },
  // templatePastClient: {
  //   description: 'Neighborhood update',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'pastClient',
  //     subject: "Your home's value may surprise you",
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>I hope you've been enjoying your home! It's been a busy year in [neighborhood name]. Since you purchased your home in [year], the market has exploded. The average [# bedroom] home is selling for about [price]. Amazing!</div><div><br></div><div>I just wanted to check in because there are more buyers than homes available right now — and sellers are surprised by how much they can get for their homes. If you've been thinking about selling your home, I would love to work with you again.</div><div><br></div><div>Can I drop by and give you a five-minute price consultation so that you can see how much your home is worth? It might be enough for you to purchase your dream home!</div><div><br></div><div>You can reach me via call or text at {{agent.mobilePhone}}. I look forward to speaking with you!</div><div><br></div><div>Talk soon,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templatePastClient',
  //   objectType: 99
  // },
  // templateReconnect: {
  //   description: 'General reconnect',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'pastClient',
  //     subject: "It's been a while",
  //     body: "<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>I just wanted to check in and see how things are going. It's been a while since we last touched base, so if you have any questions on the real estate market, don't hesitate to give me a call or send me an email. I hope this note finds you well!</div><div><br></div><div>Talk soon,</div><div>{{agent.firstName}}</div>"
  //   },
  //   fileId: 'templateReconnect',
  //   objectType: 99
  // },
  // templateSnapshotFollowupBuyer: {
  //   description: 'Market Snapshot warm-up email',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'currentBuyer',
  //     subject: 'Sent you a report on the neighborhood you like',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>I sent you a report that contains details on the neighborhood you mentioned you liked.</div><div><br></div><div>You'll see key insights about the area, including the average asking price, sold price, days on market and percentage of homes for sale in the area. You can also see whether it's a buyer or seller's market and can click any of these stats for more info.</div><div><br></div><div>You'll also see a list of properties in the area, which is based on your report criteria (price, number of beds/baths, etc). By default, you'll see all current listings, new listings and recently-sold properties. Just click the button to omit any of these. For example, if you want to only display new listings in the area, just click For Sale and Sold to exclude these.</div><div><br></div><div>Lastly, click a property to view additional info, including property features and neighborhood info, such as nearby amenities, schools and the property's walkscore.</div><div><br></div><div>I hope you enjoy this insider's look at the local market. Feel free to give me a call or text me at {{agent.mobilePhone}}​​ if you have any questions!</div><div><br></div><div>Thanks,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateSnapshotFollowupBuyer',
  //   objectType: 99
  // },
  // templateSnapshotFollowupSeller: {
  //   description: 'Market Snapshot warm-up email',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'currentSeller',
  //     subject: 'Sent you a report on your neighborhood',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>I sent you a report that contains details on your neighborhood so you can see how your biggest investment is performing.</div><div><br></div><div>You'll see key insights about your area, including the average asking price, sold price, days on market and percentage of homes for sale. You can also see whether it's a buyer or seller's market and can click any of these stats for more info.</div><div><br></div><div>You'll also see a list of comparable properties in your neighborhood. By default, you'll see all current listings, new listings and recently-sold properties. Just click the button to omit any of these. For example, if you want to only display new listings in the area, just click For Sale and Sold to exclude these.</div><div><br></div><div>Lastly, click a property to view additional info, including photos and the property's description and features.</div><div><br></div><div>I hope you enjoy this insider's look at the local market. Feel free to give me a call or text me at {{agent.mobilePhone}}​​ if you have any questions!</div><div><br></div><div>Thanks,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateSnapshotFollowupSeller',
  //   objectType: 99
  // },
  // templateSnapshotReceive: {
  //   description: 'Did you receive the Market Snapshot?',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'prospecting',
  //     subject: 'Did you receive your neighborhood report?',
  //     body: '<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>Just checking in to make sure you received the neighborhood report I sent? If not, you may want to check your spam folder.</div><div><br></div><div>If you need me to resend or have any questions, just let me know!</div><div><br></div><div>Thanks,</div><div>{{agent.firstName}}</div>'
  //   },
  //   fileId: 'templateSnapshotReceive',
  //   objectType: 99
  // },
  // templateSneakPeek: {
  //   description: 'Sneak peak at new properties',
  //   file: {
  //     area: ['contact', 'agent'],
  //     category: 'currentBuyer',
  //     subject: 'A sneak peek at some new properties',
  //     body: "<div>Hi {{contact.primaryPerson.firstName}},</div><div><br></div><div>Just wanted to let you know that later this week I'll be out touring properties that are fresh on the market to get a head start for my clients. If you'd like me to preview some homes for you, let me know specifics and I'll keep my eyes open and follow up with the details.</div><div><br></div><div>Feel free to call me at {{agent.mobilePhone}} if you'd like to talk further.</div><div><br></div><div>Talk soon,</div><div>{{agent.firstName}}</div>"
  //   },
  //   fileId: 'templateSneakPeek',
  //   objectType: 99
  // },
  // textWithClient: {
  //   description: 'Currently with a client',
  //   body: {
  //     area: [],
  //     body: "Thanks for contacting me! I'm currently with a client and will return your message ASAP. Is this a good number to reach you?"
  //   },
  //   category: 'leadConversion',
  //   formatVersion: '0',
  //   fileId: 'textWithClient',
  //   objectType: 99,
  //   subType: 2
  // },
  // textNeedToReschedule: {
  //   description: 'Need to reschedule',
  //   body: {
  //     area: ['contact'],
  //     body: 'Hi, {{contact.primaryPerson.firstName}}. Apologies: I need to reschedule. Are you free tomorrow?'
  //   },
  //   category: 'currentSeller',
  //   formatVersion: '0',
  //   fileId: 'textNeedToReschedule',
  //   objectType: 99,
  //   subType: 2
  // },
  // textSendingListings: {
  //   description: 'Sending you listing',
  //   body: {
  //     area: ['contact'],
  //     body: 'Hi {{contact.primaryPerson.firstName}}, sending some handpicked listings your way. Good, bad or ugly: What stands out most about these homes? Happy to keep ‘em coming — let me know what you think!'
  //   },
  //   category: 'currentBuyer',
  //   formatVersion: '0',
  //   fileId: 'textSendingListings',
  //   objectType: 99,
  //   subType: 2
  // },
  // textFollowUpForeclosure: {
  //   description: 'Follow-up with foreclosures',
  //   body: {
  //     area: ['contact'],
  //     body: "Hope you're doing well {{contact.primaryPerson.firstName}}! You contacted me recently about a home. There are a few available foreclosures in your market available if you're looking for a great deal — can send them to you, just let me know!"
  //   },
  //   category: 'leadConversion',
  //   formatVersion: '0',
  //   fileId: 'textFollowUpForeclosure',
  //   objectType: 99,
  //   subType: 2
  // },
  // textSendOpenHouses: {
  //   description: 'Send open houses',
  //   body: {
  //     area: ['contact'],
  //     body: 'Hi {{contact.primaryPerson.firstName}}, I have a list of open houses I can send your way. Would you like me to text that to you?'
  //   },
  //   category: 'leadConversion',
  //   formatVersion: '0',
  //   fileId: 'textSendOpenHouses',
  //   objectType: 99,
  //   subType: 2
  // },
  // textSendSnapshot: {
  //   description: 'Demo Market Snapshot report',
  //   body: {
  //     area: [],
  //     body: "Morning! Sorry we haven't connected. I have put together a market report with helpful intel that my clients LOVE — check it out: [Market Snapshot Demo Link]"
  //   },
  //   category: 'leadConversion',
  //   formatVersion: '0',
  //   fileId: 'textSendSnapshot',
  //   objectType: 99,
  //   subType: 2
  // },
  // textBuyerStillInterested: {
  //   description: 'Still searching for a new home?',
  //   body: {
  //     area: [],
  //     body: 'Morning! Are you still interested in searching for a home? I wanted to make sure you are being taken care of, so let me know if I can help.'
  //   },
  //   category: 'prospecting',
  //   formatVersion: '0',
  //   fileId: 'textBuyerStillInterested',
  //   objectType: 99,
  //   subType: 2
  // },
  // textBuyerTouchTargetArea: {
  //   description: "Haven't spoken recently",
  //   body: {
  //     area: ['contact'],
  //     body: "Happy Friday, {{contact.primaryPerson.firstName}}! We haven't spoken recently, but wanted to let you know there are a few houses in your target area that caught my eye. If you'd like some info, just let me know!"
  //   },
  //   category: 'leadConversion',
  //   formatVersion: '0',
  //   fileId: 'textBuyerTouchTargetArea',
  //   objectType: 99,
  //   subType: 2
  // },
  // textGreatSpeakingWithYou: {
  //   description: 'Great speaking with you',
  //   body: {
  //     area: ['contact'],
  //     body: "Hi {{contact.primaryPerson.firstName}}, It was a pleasure speaking with you; thanks for your time! I look forward to meeting you on [DAY/TIME] at [LOCATION]. Between now and then, if you find other homes you'd like to see, you don't need to fill out the online form. Just send them my way and I can make an appointment."
  //   },
  //   category: 'currentBuyer',
  //   formatVersion: '0',
  //   fileId: 'textGreatSpeakingWithYou',
  //   objectType: 99,
  //   subType: 2
  // },
  // textSnapshotSoon: {
  //   description: 'Receiving Market Snapshot soon',
  //   body: {
  //     area: ['contact'],
  //     body: "Hi {{contact.primaryPerson.firstName}}, Just a quick note to let you know you'll be receiving a Market Snapshot Report via email soon. If you have any questions, don't hesitate to call!"
  //   },
  //   category: 'prospecting',
  //   formatVersion: '0',
  //   fileId: 'textSnapshotSoon',
  //   objectType: 99,
  //   subType: 2
  // },
  // textColdSellerLead: {
  //   description: 'Cold seller lead follow-up',
  //   body: {
  //     area: ['contact'],
  //     body: "Hi {{contact.primaryPerson.firstName}}, more homes just sold in your area. Want me to send a report that tells you what's going on in your neighborhood?"
  //   },
  //   category: 'leadConversion',
  //   formatVersion: '0',
  //   fileId: 'textColdSellerLead',
  //   objectType: 99,
  //   subType: 2
  // },
  // textBuyerNeedInfo: {
  //   description: 'Buyer - need more info',
  //   body: {
  //     area: ['contact'],
  //     body: "Hi {{contact.primaryPerson.firstName}}, let me know a couple of the neighborhoods you're interested in and I'll set some showings up for you!"
  //   },
  //   category: 'leadConversion',
  //   formatVersion: '0',
  //   fileId: 'textBuyerNeedInfo',
  //   objectType: 99,
  //   subType: 2
  // },
  // textColdBuyerLead: {
  //   description: 'Cold buyer lead follow-up',
  //   body: {
  //     area: ['contact'],
  //     body: "Hi {{contact.primaryPerson.firstName}}! New homes just hit the market I think you'll love. Want me to set up a time for you to see them?"
  //   },
  //   category: 'leadConversion',
  //   formatVersion: '0',
  //   fileId: 'textColdBuyerLead',
  //   objectType: 99,
  //   subType: 2
  // },
  // textOpenHouseLead: {
  //   description: 'Open house follow-up',
  //   body: {
  //     area: ['contact'],
  //     body: 'Thanks for joining us at the open house at [Address]. Please reach out if you have any questions about the property.'
  //   },
  //   category: 'leadConversion',
  //   formatVersion: '0',
  //   fileId: 'textOpenHouseLead',
  //   objectType: 99,
  //   subType: 2
  // },
  // textStillInterested: {
  //   description: 'Are you still interested?',
  //   body: {
  //     area: ['contact', 'agent'],
  //     body: "Hi {{contact.primaryPerson.firstName}}, it's {{agent.firstName}} {{agent.lastName}} here! Are you still interested in searching for a home in the [buyer area] area?"
  //   },
  //   category: 'leadConversion',
  //   formatVersion: '0',
  //   fileId: 'textStillInterested',
  //   objectType: 99,
  //   subType: 2
  // },
  // textOfferAccepted: {
  //   description: 'Offer accepted',
  //   body: {
  //     area: ['contact'],
  //     body: "Hi {{contact.primaryPerson.firstName}} - great news! I'll follow up with a call soon, but I wanted to let you know right away that the seller accepted your offer. Congratulations!"
  //   },
  //   category: 'currentBuyer',
  //   formatVersion: '0',
  //   fileId: 'textOfferAccepted',
  //   objectType: 99,
  //   subType: 2
  // },
  // textPriceReduction: {
  //   description: 'Price reduction',
  //   body: {
  //     area: ['contact'],
  //     body: "Hi {{contact.primaryPerson.firstName}}, as you know we've been on the market for [x] days. Would you be open-minded to lowering your asking price to create some immediate interest?"
  //   },
  //   category: 'currentSeller',
  //   formatVersion: '0',
  //   fileId: 'textPriceReduction',
  //   objectType: 99,
  //   subType: 2
  // },
  // textNewListing: {
  //   description: 'New listing',
  //   body: {
  //     area: ['contact'],
  //     body: 'Hi {{contact.primaryPerson.firstName}}, I just listed this property and immediately thought of you! Take a look and let me know what you think.'
  //   },
  //   category: 'prospecting',
  //   formatVersion: '0',
  //   fileId: 'textNewListing',
  //   objectType: 99,
  //   subType: 2
  // },
  // textSnapshotFollowUp: {
  //   description: 'Market Snapshot follow-up',
  //   body: {
  //     area: ['contact'],
  //     body: 'Hi {{contact.primaryPerson.firstName}}, just checking in to make sure you received the neighborhood report I sent? If you need me to resend or have any questions, just let me know!'
  //   },
  //   category: 'prospecting',
  //   formatVersion: '0',
  //   fileId: 'textSnapshotFollowUp',
  //   objectType: 99,
  //   subType: 2
  // },
  // textHoldingOff: {
  //   description: 'Still holding off?',
  //   body: {
  //     area: ['contact'],
  //     body: "Hi {{contact.primaryPerson.firstName}}, we spoke a while ago about selling your home and I just wanted to check in and see how things are. If you'd like a report that tells you what's going on in your neighborhood, just let me know!"
  //   },
  //   category: 'prospecting',
  //   formatVersion: '0',
  //   fileId: 'textHoldingOff',
  //   objectType: 99,
  //   subType: 2
  // },
  // textHotMarket: {
  //   description: 'Hot market',
  //   body: {
  //     area: ['contact', 'agent'],
  //     body: "Hi {{contact.primaryPerson.firstName}}, this is {{agent.firstName}} {{agent.lastName}} from {{agent.companyName}}. The market is really hot in your area. Is there a price in mind you'd consider selling your house for?"
  //   },
  //   category: 'prospecting',
  //   formatVersion: '0',
  //   fileId: 'textHotMarket',
  //   objectType: 99,
  //   subType: 2
  // },
  // textExpiredListing: {
  //   description: 'Older expired listing',
  //   body: {
  //     area: ['contact'],
  //     body: 'Hi {{contact.primaryPerson.firstName}}, I noticed that your listing expired in [year]. Any thoughts on selling now that the market is better?'
  //   },
  //   category: 'prospecting',
  //   formatVersion: '0',
  //   fileId: 'textExpiredListing',
  //   objectType: 99,
  //   subType: 2
  // },
  // textHowIsHouse: {
  //   description: "How's the new house?",
  //   body: {
  //     area: ['contact'],
  //     body: "Hi {{contact.primaryPerson.firstName}}, just wanted to check in on you and the family and see how you're doing. Hope you're settling into your new place and neighborhood! Feel free to reach out with any questions."
  //   },
  //   category: 'pastClient',
  //   formatVersion: '0',
  //   fileId: 'textHowIsHouse',
  //   objectType: 99,
  //   subType: 2
  // },
  // textCheckingIn: {
  //   description: 'Just checking in',
  //   body: {
  //     area: ['contact'],
  //     body: "Hi {{contact.primaryPerson.firstName}}, just wanted to check in and see how things are going. It's been a while since we last talked, so if you ever have any questions on the market, just let me know!"
  //   },
  //   category: 'pastClient',
  //   formatVersion: '0',
  //   fileId: 'textCheckingIn',
  //   objectType: 99,
  //   subType: 2
  // },
  firstName: {
    objectType: 'contact',
    description: 'First name',
    key: 'primaryPerson.firstName'
  },
  lastName: {
    objectType: 'contact',
    description: 'Last name',
    key: 'primaryPerson.lastName'
  },
  fullName: {
    objectType: 'contact',
    description: 'Full name',
    key: 'fullNameFirstNameFirst'
  },
  address: {
    objectType: 'contact',
    description: 'Address',
    key: 'address'
  },
  addressLine: {
    objectType: 'contact',
    description: 'Street address',
    key: 'address.addressLine'
  },
  agentName: {
    objectType: 'agent',
    description: 'Agent name',
    key: 'agentName'
  },
  agentFirstName: {
    objectType: 'agent',
    description: 'Agent first name',
    key: 'firstName'
  },
  agentLastName: {
    objectType: 'agent',
    description: 'Agent last name',
    key: 'lastName'
  },
  agentEmail: {
    objectType: 'agent',
    description: 'Agent email',
    key: 'agentEmail'
  },
  agentBusinessPhone: {
    objectType: 'agent',
    description: 'Agent business phone',
    key: 'businessPhone'
  },
  agentMobilePhone: {
    objectType: 'agent',
    description: 'Agent mobile phone',
    key: 'mobilePhone'
  },
  agentWebsite: {
    objectType: 'agent',
    description: 'Agent website',
    key: 'agentWebSite'
  },
  agentAddress: {
    objectType: 'agent',
    description: 'Office address',
    key: 'agentAddress'
  },
  companyName: {
    objectType: 'agent',
    description: 'Company name',
    key: 'companyName'
  },
  companyWebsite: {
    objectType: 'agent',
    description: 'Company website',
    key: 'companyWebsite'
  },
  companyMapLink: {
    objectType: 'agent',
    description: 'Office map link',
    key: 'googleMapsLink'
  },
  spouseFirstName: {
    objectType: 'relationship',
    description: 'Spouse first name',
    key: 'spouse.relatedContactFirstName'
  },
  spouseLastName: {
    objectType: 'relationship',
    description: 'Spouse last name',
    key: 'spouse.relatedContactLastName'
  },
  spouseFullName: {
    objectType: 'relationship',
    description: 'Spouse full name',
    key: 'spouse.relatedContactFullName'
  },
  secondaryFirstName: {
    objectType: 'relationship',
    description: 'Spouse first name',
    key: 'secondaryPerson.firstName',
    hidden: true
  },
  secondaryLastName: {
    objectType: 'relationship',
    description: 'Spouse last name',
    key: 'secondaryPerson.lastName',
    hidden: true
  },
  secondaryFullName: {
    objectType: 'relationship',
    description: 'Spouse full name',
    key: 'secondaryPerson.fullName',
    hidden: true
  }
};

export const filterAndSortTemplates = TEMPLATES => {
  // This order is used along with the template description for sorting by object type.
  // 1 and 99 are user and system types uploaded to aws respectively.
  const templateObjectTypeOrder = [1, 99, 'template', 'contact', 'relationship', 'agent'];

  return getArrayOfEntities(TEMPLATES)
    .filter(template => !template.hidden)
    .sort((a, b) => {
      const getSortDesc = template => {
        const templateObjectType = template?.file?.category || '';
        return `${templateObjectTypeOrder.indexOf(
          template.objectType
        )}-${templateObjectType}-${template.description.toLowerCase()}`;
      };
      const sortDescA = getSortDesc(a);
      const sortDescB = getSortDesc(b);

      return sortDescA.localeCompare(sortDescB);
    });
};
