import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './ProgressBar.css';

const Filler = props => {
  const { percentage } = props;
  return <div className={styles.filler} style={{ width: `${percentage}%` }} />;
};

export const ProgressBar = props => {
  const { percentage = 0, isInProgress, className } = props;

  if (!isInProgress) {
    return null;
  }

  const classes = classnames({
    [styles.progressBar]: !className,
    [className]: !!className
  });

  return (
    <div className={classes}>
      <Filler percentage={percentage} />
    </div>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  className: PropTypes.string
};
