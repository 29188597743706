import React from 'react';
import { func, oneOf, string } from 'prop-types';
import classnames from 'classnames';

import { Button, ButtonGroup, Icon, Panel } from '../';

import styles from './CallToAction.css';
import { isExternalLink } from '../../utils/urls';

export const CallToAction = props => {
  const {
    buttonAriaLabel,
    buttonIcon,
    buttonLabel,
    clickHandler,
    className,
    icon,
    iconSize = 'xl',
    isNested = false,
    message,
    title,
    url,
    buttonId,
    variant = 'default',
    secondaryButtonHandler,
    secondaryButtonAriaLabel,
    secondaryButtonLabel,
    ...attrs
  } = props || {};

  if (!message && !buttonLabel && !(url || clickHandler)) {
    return null;
  }

  const classes = classnames({
    [styles.panel]: true,
    [styles[`${variant}Variant`]]: true,
    [styles.isNested]: isNested
  });

  const showButton = url || clickHandler;

  const shouldSecondaryButton = secondaryButtonHandler && secondaryButtonLabel;

  return (
    <Panel className={classes}>
      {icon && (
        <div className={styles.iconWrap}>
          <Icon name={icon} size={iconSize} />
        </div>
      )}
      {title && <h2 className={styles.title}>{title}</h2>}
      {message && <span className={styles.message}>{message}</span>}
      <ButtonGroup>
        {showButton && (
          <Button
            url={url}
            label={buttonLabel}
            ariaLabel={buttonAriaLabel || buttonLabel}
            icon={buttonIcon}
            onClick={clickHandler}
            styleType="white"
            externalLink={isExternalLink(url)}
            data-cy={buttonId}
            id={buttonId}
            {...attrs}
          />
        )}
        {shouldSecondaryButton && (
          <Button
            label={secondaryButtonLabel}
            ariaLabel={secondaryButtonAriaLabel || secondaryButtonLabel}
            onClick={secondaryButtonHandler}
            styleType="white"
            data-cy={`${buttonId}secondary`}
            id={`${buttonId}secondary`}
          />
        )}
      </ButtonGroup>
    </Panel>
  );
};

CallToAction.propTypes = {
  buttonAriaLabel: string,
  buttonIcon: string,
  buttonLabel: string,
  className: string,
  clickHandler: func,
  icon: string,
  iconSize: oneOf(['xxxs', 'xxs', 'xs', 's', 'm', 'l', 'xl']),
  message: string,
  url: string,
  variant: oneOf(['default', 'white'])
};
