import React from 'react';

import { CallToAction } from '../';
import { getHelpLink } from '../../utils/help';

const NotesCallToAction = () => {
  const message = 'Add a note to get started.';

  return (
    <CallToAction
      icon="notes"
      isNested={true}
      buttonLabel="Learn more"
      ariaLabel={message}
      message={message}
      url={getHelpLink('notes')}
    />
  );
};

export default NotesCallToAction;
