import { Link } from '@reach/router';
import classnames from 'classnames';
import { bool, oneOf, string } from 'prop-types';
import React from 'react';

import { Icon } from '../';
import { Loading } from '../Loading';
import { useMediaQueryContext } from '../MediaQueryProvider/MediaQueryProvider';
import styles from './Button.css';

const ButtonChildren = ({
  icon,
  iconLabel,
  label,
  labelShort,
  size = 's',
  isColored = false,
  isIconOnly = false,
  isLoading = false
}) => {
  const { isTabletPortraitAndUp } = useMediaQueryContext() || {};
  const classes = classnames({
    [styles.iconWithLabel]: !!label && !!icon,
    [styles[`${icon}IconButton`]]: !!label && !!icon
  });

  const buttonLabel = !isTabletPortraitAndUp && labelShort ? labelShort : label;

  return (
    <>
      <Icon name={icon} size={size} className={classes} isColored={isColored} label={iconLabel} />
      {!isIconOnly && <span className={styles.labelWrap}>{buttonLabel}</span>}
      <Loading loading={isLoading} />
    </>
  );
};

export const Button = React.forwardRef((props, ref) => {
  const {
    disabled,
    ariaLabel,
    className,
    externalLink = false,
    icon,
    iconLabel,
    label,
    labelShort,
    size,
    styleType,
    tooltipPos = 'below',
    type = 'button',
    url,
    isColored = false,
    isFavorite = false,
    isLoading = false,
    isSelected = false,
    ...attrs
  } = props;

  if (!label && !icon) {
    return null;
  }

  const isIconOnly = !label && !!icon; // has icon prop but no label
  const showTooltip = isIconOnly;

  if (showTooltip) {
    // If we are going to show the tooltip, we want to get rid of the title attribute to ensure it doesn't conflict;
    delete attrs.title;
  }

  const classes = classnames({
    [styles.button]: !styleType,
    [styles[`${styleType}Button`]]: !!styleType,
    [styles.linkButton]: label && !!url, // has url prop
    [styles.withIcon]: !!label && !!icon, // has icon prop AND label
    [styles.iconOnly]: isIconOnly,
    [styles.showTooltip]: showTooltip,
    [styles[`${tooltipPos}TooltipPos`]]: !!tooltipPos,
    [styles.isSelected]: isSelected,
    [className]: !!className
  });

  if (url) {
    if (externalLink || url.startsWith('http')) {
      const safeAttrs = // we only need safe attributes for actual page links
        url.startsWith('tel:') || url.startsWith('sms:') || url.startsWith('mailto:') || !externalLink
          ? null
          : {
              target: '_blank',
              rel: 'noopener'
            };

      return (
        <a ref={ref} href={url} aria-label={ariaLabel} className={classes} {...safeAttrs} {...attrs}>
          <ButtonChildren
            label={label}
            labelShort={labelShort}
            icon={icon}
            iconLabel={iconLabel}
            isColored={isFavorite || isColored}
            isIconOnly={isIconOnly}
            isLoading={isLoading}
            size={size}
          />
        </a>
      );
    }

    return (
      <Link ref={ref} to={url} aria-label={ariaLabel} className={classes} {...attrs}>
        <ButtonChildren
          label={label}
          labelShort={labelShort}
          icon={icon}
          iconLabel={iconLabel}
          isColored={isFavorite || isColored}
          isIconOnly={isIconOnly}
          isLoading={isLoading}
          size={size}
        />
      </Link>
    );
  }

  return (
    <button
      disabled={disabled}
      role="button"
      aria-label={ariaLabel}
      ref={ref}
      type={type}
      className={classes}
      {...attrs}
    >
      <ButtonChildren
        label={label}
        labelShort={labelShort}
        icon={icon}
        iconLabel={iconLabel}
        isColored={isFavorite || isColored}
        isIconOnly={isIconOnly}
        isLoading={isLoading}
        size={size}
      />
    </button>
  );
});

Button.propTypes = {
  ariaLabel: string.isRequired,
  className: string,
  externalLink: bool,
  icon: string,
  isColored: bool,
  isFavorite: bool, // ToDo: Can we drop this and move to isFavorite?
  label: string,
  labelShort: string,
  size: oneOf(['xxxs', 'xxs', 'xs', 's', 'm', 'l', 'xl']),
  styleType: oneOf(['primary', 'secondary', 'tertiary', 'transparent', 'white', 'inline', 'bar']),
  tooltipPos: oneOf(['below', 'above', 'left', 'right']),
  type: oneOf(['button', 'submit']),
  url: string
};
