/** @module */

/**
 * Tracks a user interaction with Google Analytics.
 * @param {String} eventCategory - the event category.
 * @param {String} eventAction - the event action.
 * @param {Object} dimensions - an object containing custom dimensions.
 */
export const trackEvent = (eventCategory = null, eventAction, dimensions = {}) => {
  if (!window.ga || !eventAction) {
    return;
  }

  window.ga('send', 'event', eventCategory, eventAction, dimensions);
};

/**
 * Tracks a client side error in Google Analytics.
 * @param {String} errorMessage - an error message to track.
 * @param {Boolean} isFatal - whether the error replaces the UI (true means React ErrorBoundary caught it).
 */
export const trackError = (errorMessage, isFatal = false) => {
  if (!window.ga || !errorMessage) {
    return;
  }

  window.ga('send', 'exception', {
    exDescription: errorMessage,
    exFatal: isFatal
  });
};
