import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './FormHint.css';

export const FormHint = props => {
  const { children, className, styleType = 'default' } = props;

  if (!children) {
    return null;
  }

  const classes = classnames({
    [styles.hint]: !className,
    [styles[`${styleType}Hint`]]: !!styleType,
    [className]: !!className
  });

  return <div className={classes}>{children}</div>;
};

FormHint.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  styleType: PropTypes.oneOf(['default', 'legal'])
};
