import React from 'react';
import { bool, string } from 'prop-types';
import { useSlate } from 'slate-react';

import { fontFamilies, getFontFamilyStr } from '../Nodes/Leaf';
import { EditorCommands } from '../editor-commands';
import { MenuButton } from '../../MenuButton';

/**
 * React component for rendering the ButtonFontFamily component.
 * @param {Object} props ButtonFontFamily component props.
 * @param {String} [props.currentFontFamily=sansserif] The current font family.
 * @param {Boolean} [props.isDisabled] The button's disabled state.
 */
export const ButtonFontFamily = props => {
  const editor = useSlate();
  const { currentFontFamily = 'sansserif', isDisabled } = props;

  const currentInFontFamilies = !!fontFamilies[currentFontFamily];

  const menuItems = Object.keys(fontFamilies).map(id => {
    return {
      id,
      isSelected: currentInFontFamilies
        ? currentFontFamily === id
        : currentFontFamily && currentFontFamily.toLowerCase().includes(id),
      label: fontFamilies[id],
      onSelect: () => {
        EditorCommands.handleMarkStyleChange(editor, 'fontFamily', id);
      },
      style: { fontFamily: getFontFamilyStr(id) }
    };
  });

  return (
    <MenuButton
      disabled={isDisabled}
      icon="fontfamily"
      menuItems={menuItems}
      position="above"
      showArrow={true}
      toolTip="Font"
      tooltipPos="below"
    />
  );
};

ButtonFontFamily.propTypes = {
  currentFontFamily: string,
  isDisabled: bool
};
