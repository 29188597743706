/** @module */

export const GET_FULL_CONTACT = 'GET_FULL_CONTACT';
export const GET_FULL_CONTACT_PROFILE_SUCCESS = 'GET_FULL_CONTACT_PROFILE_SUCCESS';
export const GET_FULL_CONTACT_PROFILE_ERROR = 'GET_FULL_CONTACT_PROFILE_ERROR';

export const initialState = {
  isLoading: false,
  imageURL: '',
  profile: {}
};

/**
 * The alert redux reducer.
 * @param {Object} state - the current state of the alert store.
 * @param {Object} action - the action to take on the alert store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - A data object that represents the alert's details.
 */
export const fullContactReducer = (state = initialState, action = {}) => {
  const { type = 'default', data } = action;

  switch (type) {
    case GET_FULL_CONTACT:
      return {
        ...state,
        isLoading: true
      };
    case GET_FULL_CONTACT_PROFILE_SUCCESS:
      return {
        ...state,
        profile: data,
        isLoading: false
      };
    case GET_FULL_CONTACT_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};
