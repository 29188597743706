import React, { useContext, useEffect, useRef, useState } from 'react';
import { arrayOf, node, object, oneOfType } from 'prop-types';
import { Transforms } from 'slate';
import { useSelected, useSlate } from 'slate-react';

import { ImageBasic } from './ImageBasic';
import { TemplateMenuContext } from '../Editor';
import { EditorCommands } from '../editor-commands';
import { Button, FormRangeInput } from '../../';
import { getStyleObjectFromStr } from '../../../utils/dom';
import { leaveOnlyDigits } from '../../../utils/strings';
import { hasDigit } from '../../../utils/validation';

import styles from '../Editor.css';

/**
 * React component for rendering Slate Image nodes.
 * @param {Object} props Image component props.
 * @param {Object} props.attributes The node's Slate attributes.
 * @param {Array} props.children The child nodes of an image - usually empty.
 * @param {Object} props.element HTML image related attribute and style values.
 */
export const Image = props => {
  const ref = useRef();
  const editor = useSlate();
  const { toggleLinkDialog } = useContext(TemplateMenuContext);
  const { attributes, children, element } = props;
  const { width: elWidth, height: elHeight, style } = element;
  const elStyles = typeof style === 'string' ? getStyleObjectFromStr(style) : style;
  const width = elWidth || elStyles?.width;
  const height = elHeight || elStyles?.height;

  const cleanWidth = width === 'auto' || hasDigit(width) ? leaveOnlyDigits(width) : 'auto';
  const cleanHeight = height === 'auto' || hasDigit(height) ? leaveOnlyDigits(height) : 'auto';

  const isSelected = useSelected();

  const { naturalWidth, naturalHeight } = ref?.current || {};
  const alignment = ref && ref.current ? getComputedStyle(ref.current).textAlign : null;

  const [imageWidth, setImageWidth] = useState(cleanWidth); // this is a percentage.
  const [imageHeight, setImageHeight] = useState(cleanHeight); // this is a percentage.
  const [parentLink, setParentLink] = useState(null);

  useEffect(() => {
    if (!isSelected) {
      if (parentLink) {
        setParentLink(null);
      }
      return;
    }

    const [node] = EditorCommands.getMatchingNode(editor, 'link');

    if (node?.url) {
      setParentLink(node.url);
    }
  }, [editor, isSelected, parentLink]);

  const getNewDimension = (size, percentage) => {
    return Math.round((size * percentage) / 100);
  };

  const handleImageSliderChange = e => {
    const { value } = e.target;
    const newWidth = getNewDimension(naturalWidth, value);
    const newHeight = getNewDimension(naturalHeight, value);
    setImageWidth(newWidth);
    setImageHeight(newHeight);
  };

  const handleImageSliderMouseUp = () => {
    const [node, path] = EditorCommands.getMatchingNode(editor, 'image');
    const imageData = {
      ...node,
      style: {
        width: imageWidth && hasDigit(imageWidth.toString()) ? `${imageWidth}px` : 'auto',
        height: imageHeight && hasDigit(imageHeight.toString()) ? `${imageHeight}px` : 'auto'
      }
    };
    Transforms.setNodes(editor, imageData, { at: path });
    EditorCommands.giveFocusAndMoveCursorForward(editor);
  };

  const handleDeleteLink = e => {
    e.preventDefault();
    e.stopPropagation();

    EditorCommands.unwrapNode(editor, 'link');
    EditorCommands.giveFocusAndMoveCursorForward(editor);
  };

  const handleEditLink = e => {
    toggleLinkDialog(e, parentLink);
  };

  const dimensions = {
    width: imageWidth,
    height: imageHeight
  };

  const menuStyle = {
    right: alignment === 'right' ? 0 : 'auto'
  };

  const percentOfOriginal =
    naturalWidth && imageWidth && imageWidth !== 'auto' ? Math.round((imageWidth / naturalWidth) * 100) : 100;

  return (
    <div contentEditable={false} className={styles.imageWrap} {...attributes}>
      <ImageBasic ref={ref} isSelected={isSelected} dimensions={dimensions} {...props} />
      {isSelected && (
        <div className={styles.imageMenu} style={menuStyle}>
          <label className={styles.imageMenuLabel}>Image width</label>
          <div className={styles.imageMenuSection}>
            <FormRangeInput
              id="imageWidth"
              label={`${percentOfOriginal}%`}
              min={10}
              max={100}
              value={imageWidth ? percentOfOriginal : 100}
              onChange={handleImageSliderChange}
              onMouseUp={handleImageSliderMouseUp}
            />
          </div>
          {parentLink && (
            <div className={styles.imageMenuSection}>
              <label className={styles.imageMenuLabel}>Link image to</label>
              <div className={styles.linkActions}>
                <object className="nestedLinkWrapper">
                  <a href={parentLink} target="_blank" rel="noopener" className={styles.imageLink} title={parentLink}>
                    {parentLink}
                  </a>
                </object>
                <Button
                  icon="edit"
                  ariaLabel="Change image link."
                  title="Edit"
                  tooltipPos="above"
                  onClick={handleEditLink}
                />
                <Button
                  icon="delete"
                  ariaLabel="Remove image link."
                  title="Delete"
                  tooltipPos="above"
                  onClick={handleDeleteLink}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  );
};

Image.propTypes = {
  attributes: object.isRequired,
  children: oneOfType([arrayOf(node), node]),
  element: object.isRequired
};
