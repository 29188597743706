/** @module */

export const RECAPTCHA_RESPONSE = 'RECAPTCHA_RESPONSE';

export const initialState = {
  isHuman: true,
  failCount: 0
};

/**
 * The preferences reducer redux reducer.
 * @param {Object} state - the current state of the user store.
 * @param {Object} action - the action to take on the user store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const reCaptchaReducer = (state = initialState, action = {}) => {
  const { reCaptchaVerified } = action;
  switch (action.type) {
    //allow a maximum of 5 failed recaptcha attempts
    case RECAPTCHA_RESPONSE: {
      const failCount = reCaptchaVerified ? 0 : state.failCount + 1;
      return {
        ...state,
        failCount: failCount,
        isHuman: failCount < 5
      };
    }
    default:
      return state;
  }
};
