import React, { Fragment } from 'react';
import { bool, func, oneOf } from 'prop-types';
import { ReactEditor, useSlate } from 'slate-react';

import { EditorCommands } from '../editor-commands';
import { Button, FormFileInput } from '../../';
import { ButtonAlignment } from './ButtonAlignment';
import { ButtonFontFamily } from './ButtonFontFamily';
import { ButtonFontSize } from './ButtonFontSize';
import { ButtonFontColor } from './ButtonFontColor';

import styles from './Toolbar.css';
import { useState } from 'react';
import { Editor, Transforms } from 'slate';
import AIDialog, { AI_TEST_ID } from './AIDialog';
import { useDispatch, useSelector } from 'react-redux';
import { closeAIDialog, getResponse, openAIDialog } from '../../../actions/openai';
import { emailSanitizer } from '../../../utils/email';
import { serialize } from '../editor-utils';
import { getInitialMessages } from '../../../utils/aiAuthor';
import { STYLE_DESCRIPTIONS } from '../../../data/openai';
import { buildTestId } from '../../../utils';

/**
 * React component for rendering the BlockButtons - for adding an Element in Slate.
 * @param {Object} props BlockButton component props.
 * @param {String} props.command The command label for adding an Element to Slate.
 * @param {Object} props.editor The editor state.
 * @param {String} props.icon The icon that represents the command.
 * @param {Boolean} [props.isDisabled] The editor's, and subsequently the toolbar's, disabled state.
 */
const AIButton = ({ command, editor, disabled, setIsOpen }) => {
  const isActive = EditorCommands.isBlockActive(editor, command);
  const text = EditorCommands.getSelectionText(editor);

  const handleonPointerDown = async e => {
    e.preventDefault();
    setIsOpen(text);
  };

  return (
    <Button
      icon={'aiAuthor'}
      ariaLabel="AI Author"
      onPointerDown={handleonPointerDown}
      className={styles.toolbarButton}
      isColored={false}
      isSelected={isActive}
      disabled={disabled}
    />
  );
};

/**
 * React component for rendering the BlockButtons - for adding an Element in Slate.
 * @param {Object} props BlockButton component props.
 * @param {String} props.command The command label for adding an Element to Slate.
 * @param {Object} props.editor The editor state.
 * @param {String} props.icon The icon that represents the command.
 * @param {Boolean} [props.isDisabled] The editor's, and subsequently the toolbar's, disabled state.
 */
const BlockButton = ({ command, editor, icon, isDisabled }) => {
  const isActive = EditorCommands.isBlockActive(editor, command);

  return (
    <Button
      icon={icon}
      ariaLabel={icon}
      onPointerDown={e => {
        e.preventDefault();
        EditorCommands.toggleBlock(editor, command);
      }}
      data-type={icon}
      className={styles.toolbarButton}
      isColored={isActive}
      isSelected={isActive}
      disabled={isDisabled}
    />
  );
};

/**
 * React component for rendering the MarkButtons - for adding a Leaf in Slate.
 * @param {Object} props MarkButton component props.
 * @param {String} props.command The command label for adding a Leaf to Slate.
 * @param {Object} props.editor The editor state.
 * @param {String} props.icon The icon that represents the command.
 * @param {Boolean} [props.isDisabled] The editor's, and subsequently the toolbar's, disabled state.
 */
const MarkButton = ({ command, editor, icon, isDisabled }) => {
  const isActive = EditorCommands.isMarkActive(editor, command);

  return (
    <Button
      icon={icon}
      ariaLabel={icon}
      onPointerDown={e => {
        e.preventDefault();
        EditorCommands.toggleMark(editor, command);
      }}
      data-type={icon}
      className={styles.toolbarButton}
      isColored={isActive}
      isSelected={isActive}
      disabled={isDisabled}
    />
  );
};

/**
 * React component for rendering the HistoryButtons - undo and redo.
 * @param {Object} props HistoryButton component props.
 * @param {undo|redo} props.command The command undo or redo to perform.
 * @param {Object} props.editor The editor state.
 * @param {undo|redo} props.icon The undo or redo icon.
 * @param {Boolean} [props.isDisabled] The editor's, and subsequently the toolbar's, disabled state.
 */
const HistoryButton = ({ command, editor, icon, isDisabled }) => {
  return (
    <Button
      icon={icon}
      ariaLabel={icon}
      onPointerDown={e => {
        e.preventDefault();
        EditorCommands.history(editor, command);
      }}
      className={styles.toolbarButton}
      disabled={isDisabled}
    />
  );
};

/**
 * React component for rendering the LinkButton in the Toolbar component.
 * @param {Object} props LinkButton component props.
 * @param {Object} props.editor The editor state.
 * @param {Boolean} [props.isDisabled] The editor's, and subsequently the toolbar's, disabled state.
 * @param {Function} props.toggleLinkDialog A method for use when clicking the LinkButton.
 */
const LinkButton = ({ editor, isDisabled, toggleLinkDialog }) => {
  const isActive = EditorCommands.isBlockActive(editor, 'link');

  return (
    <Button
      icon="link"
      ariaLabel="Insert link"
      onPointerDown={e => {
        e.preventDefault();
        toggleLinkDialog();
      }}
      className={styles.toolbarButton}
      disabled={isDisabled}
      isColored={isActive}
      isSelected={isActive}
    />
  );
};

/**
 * React component that renders a button within the toolbar to enhance content using AI.
 *
 * @param {Object} props - The properties passed to the AIEnhanceButton component.
 * @param {string} props.command - The command associated with the button.
 * @param {Object} props.editor - The editor state that affects button behavior.
 * @param {boolean} [props.disabled=false] - Indicates if the button should be disabled.
 * @param {Function} props.onClick - Callback function to execute on button click.
 * @param {string} props.label - Accessible label for the button.
 * @param {string} [props.icon='aiAuthor'] - Icon to display on the button, defaults to 'aiAuthor'.
 *
 * @returns {JSX.Element} The rendered button component.
 */
const AIEnhanceButton = ({ command, editor, disabled, onClick, label, icon = 'aiAuthor' }) => {
  const isActive = EditorCommands.isBlockActive(editor, command);

  const handleOnClick = e => {
    e.preventDefault();
    onClick();
  };
  return (
    <Button
      icon={icon}
      ariaLabel={label}
      onPointerDown={handleOnClick}
      className={styles.toolbarButton}
      isColored={true}
      isSelected={isActive}
      disabled={disabled}
      data-cy={buildTestId(AI_TEST_ID, `enhance${label}Button`)}
    />
  );
};

/**
 * React component that renders a button specifically for AI-related enhancements within a menu.
 *
 * @param {Object} props - The properties passed to the AIMenuButton component.
 * @param {string} props.command - The command associated with the button.
 * @param {string} props.icon - Icon to display on the button.
 * @param {Object} props.editor - The editor state that affects button behavior.
 * @param {boolean} [props.disabled=false] - Indicates if the button should be disabled.
 * @param {Function} props.onClick - Callback function to execute on button click.
 *
 * @returns {JSX.Element} The rendered button component.
 */
const AIMenuButton = ({ command, icon, editor, disabled, onClick }) => {
  const isActive = EditorCommands.isBlockActive(editor, command);

  return (
    <Button
      icon={icon}
      ariaLabel="AI Enhance"
      onPointerDown={e => {
        e.preventDefault();
        onClick();
      }}
      className={styles.toolbarButton}
      isColored={true}
      isSelected={isActive}
      disabled={disabled}
      data-cy={buildTestId(AI_TEST_ID, 'AIMenuButton')}
    />
  );
};

/**
 * React component for rendering the Editor Toolbar component.
 * @param {Object} props Toolbar component props.
 * @param {Boolean} [props.disabled] The editor's, and subsequently the toolbar's, disabled state.
 * @param {Function} props.handleImageChange A method to handle the image change when using the FormFileInput component.
 * @param {Function} props.toggleLinkDialog A method for use with the LinkButton.
 * @param {'email'|'texting'} [props.variant] The variant that the editor is in.
 */
export const Toolbar = props => {
  const editor = useSlate();

  const { disabled, handleImageChange, toggleLinkDialog, variant = 'email', setIsLoading, mode = 'email' } = props;
  const isEmailEditor = variant === 'email';

  const currentFontColor = EditorCommands.getSelectionMarkValue(editor, 'fontColor');
  const currentFontBackground = EditorCommands.getSelectionMarkValue(editor, 'fontBackground');
  const currentFontFamily = EditorCommands.getSelectionMarkValue(editor, 'fontFamily');
  const currentFontSize = EditorCommands.getSelectionMarkValue(editor, 'fontSize');
  const currentAlignment = EditorCommands.getSelectionNodeValue(editor, 'alignment');

  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.user);
  const { AI } = useSelector(store => store.settings.eula) || {};
  const isAIEulaValid = AI?.accepted === true;

  const [isAIMenuOpen, setIsAIMenuOpen] = useState(false);

  const serializedBody = emailSanitizer(serialize(editor));

  const handleOpenDialog = () => {
    dispatch(openAIDialog());
  };

  const handleAISubmit = async prompt => {
    const newSelection = editor.savedSelection || Editor.start(editor, [0, 0]);
    Transforms.select(editor, newSelection);
    ReactEditor.focus(editor);

    EditorCommands.insertRawTemplate(editor, prompt);
    dispatch(closeAIDialog());
  };

  const toggleAIMenu = () => {
    setIsAIMenuOpen(!isAIMenuOpen);
  };

  const handleAIEnhance = style => {
    const newMessages = [
      ...getInitialMessages(),
      {
        role: 'user',
        content: `${serializedBody}Please enhance this email by following the request: ${style}`
      }
    ];

    dispatch(
      getResponse({ messages: newMessages, user: userId, type: isEmailEditor ? 'email' : 'text' }, setIsLoading)
    ).then(({ response }) => {
      const newContent = response.content;

      // 1. Set focus and get cursor
      ReactEditor.focus(editor);
      const point = { path: [0, 0], offset: 0 };
      editor.selection = { anchor: point, focus: point };

      // 2. Delete existing content
      Transforms.delete(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, [])
        }
      });

      // 3. Remove remaining template/mergecode nodes, can happen when old content ends with mergecode {{}}}
      const [, path] = EditorCommands.getMatchingNode(editor, 'template');

      if (path) {
        Transforms.removeNodes(editor, { at: path });
      }
      // 4. Reset cursor and focus
      const newSelection = Editor.start(editor, [0, 0]);
      Transforms.select(editor, newSelection);
      ReactEditor.focus(editor);

      // 5. Insert AI response
      EditorCommands.insertRawTemplate(editor, newContent);
    });
  };

  const isAIEnabled = mode !== 'signature';

  return (
    <>
      {isAIMenuOpen && (
        <div className={styles.toolbar}>
          <div className={styles.buttonGroup}>
            <AIEnhanceButton
              command="friendly"
              editor={editor}
              label="Friendly"
              icon="handshake"
              isDisabled={disabled}
              onClick={() => handleAIEnhance(STYLE_DESCRIPTIONS.friendly)}
            />
            <AIEnhanceButton
              command="emoji"
              label="emoji"
              editor={editor}
              icon="emoji"
              isDisabled={disabled}
              onClick={() => handleAIEnhance(STYLE_DESCRIPTIONS.emoji)}
            />
            <AIEnhanceButton
              command="professional"
              label="professional"
              editor={editor}
              icon="briefcase"
              isDisabled={disabled}
              onClick={() => handleAIEnhance(STYLE_DESCRIPTIONS.professional)}
            />
            <AIEnhanceButton
              command="casual"
              label="casual"
              editor={editor}
              icon="cool"
              isDisabled={disabled}
              onClick={() => handleAIEnhance(STYLE_DESCRIPTIONS.casual)}
            />
            <AIEnhanceButton
              command="concise"
              label="concise"
              editor={editor}
              icon="compress"
              isDisabled={disabled}
              onClick={() => handleAIEnhance(STYLE_DESCRIPTIONS.concise)}
            />
          </div>
          <div className={styles.disclaimer}>AI technology can make mistakes. Review all content before sending.</div>
        </div>
      )}
      <div className={styles.toolbar}>
        {isAIEnabled && (
          <div className={styles.buttonGroup}>
            {isAIEulaValid && (
              <AIMenuButton
                command="bold"
                editor={editor}
                icon="enhance"
                isDisabled={disabled}
                onClick={toggleAIMenu}
              />
            )}
            {mode !== 'template' && (
              <AIButton
                command="bold"
                editor={editor}
                icon="aiAuthor"
                isDisabled={disabled}
                setIsOpen={handleOpenDialog}
              />
            )}
          </div>
        )}
        <div className={styles.buttonGroup}>
          <HistoryButton command="undo" editor={editor} icon="undo" isDisabled={disabled} />
          <HistoryButton command="redo" editor={editor} icon="redo" isDisabled={disabled} />
        </div>
        {isEmailEditor && (
          <Fragment>
            <div className={styles.buttonGroup}>
              <ButtonFontFamily currentFontFamily={currentFontFamily} isDisabled={disabled} />
            </div>

            <div className={styles.buttonGroup}>
              <ButtonFontSize currentFontSize={currentFontSize} isDisabled={disabled} />
            </div>
            <div className={styles.buttonGroup}>
              <MarkButton command="bold" editor={editor} icon="bold" isDisabled={disabled} />
              <MarkButton command="italic" editor={editor} icon="italic" isDisabled={disabled} />
              <MarkButton command="underline" editor={editor} icon="underline" isDisabled={disabled} />
              <ButtonFontColor
                currentFontBackground={currentFontBackground}
                currentFontColor={currentFontColor}
                isDisabled={disabled}
              />
            </div>
            <div className={styles.buttonGroup}>
              <ButtonAlignment currentAlignment={currentAlignment} isDisabled={disabled} />
              <BlockButton command="ordered-list" editor={editor} icon="orderedlist" isDisabled={disabled} />
              <BlockButton command="bulleted-list" editor={editor} icon="bulletedlist" isDisabled={disabled} />
              <BlockButton command="block-quote" editor={editor} icon="blockquote" isDisabled={disabled} />
            </div>
            <div className={styles.buttonGroup}>
              <MarkButton command="strikethrough" editor={editor} icon="strikethrough" isDisabled={disabled} />
            </div>
          </Fragment>
        )}
        <div className={styles.buttonGroup}>
          {isEmailEditor && (
            <Fragment>
              <LinkButton editor={editor} isDisabled={disabled} toggleLinkDialog={toggleLinkDialog} />
              <FormFileInput
                ariaLabel="Insert image"
                className={styles.toolbarButton}
                disabled={disabled}
                id="fileImage"
                icon="image"
                onChange={handleImageChange}
              />
            </Fragment>
          )}
          <Button
            label="{{&nbsp;&nbsp;}}"
            ariaLabel="Template"
            onPointerDown={e => {
              e.preventDefault();
              EditorCommands.insertTemplate(editor);
            }}
            data-type="template"
            styleType="white"
            className={styles.templateButton}
            disabled={disabled}
          />
        </div>
      </div>
      <AIDialog onClick={handleAISubmit} type={isEmailEditor ? 'email' : 'texting'} />
    </>
  );
};

Toolbar.propTypes = {
  disabled: bool,
  handleImageChange: func,
  toggleLinkDialog: func,
  variant: oneOf(['email', 'texting'])
};
