import { string } from 'prop-types';
import React, { Fragment } from 'react';
import { isValidMlsNumber } from '../../utils/strings';
import { FormField } from '../FormField';
import { FormFieldset } from '../FormFieldset';
import { FormRow } from '../FormRow';
import { ManualAddAddressForm } from './ManualAddAddressForm';
import { SET_FIELDS } from './manualAddTransactionFormReducer';
import formFieldStyles from '../FormField/FormField.css';
import { FormLabel } from '../FormLabel';
import { Dropdown } from '../Dropdown';
import { getPropertyTypes } from '../../utils/transactions';

const mlsNumberValidationMessage = 'Invalid input';
export const ManualAddTransactionForm = props => {
  const { data, dispatchFormState } = props;
  // Required fields for manual add
  // address (unit, street_number, street_name, street_suffix, country, state_code, city, postal_code
  // mls number, listing date, listing price, bed, bath, sqft, year built

  const { fields, isFormSubmitting, isFormValid } = data;
  const { address, mlsNumber, listPrice, beds, baths, sqft, yearBuilt, propertyType } = fields;

  const PROPTYPE_LIST = getPropertyTypes();

  const handleChange = e => {
    const { target } = e;

    const { id, value } = target;

    const stateKey = id;

    dispatchFormState({
      type: SET_FIELDS,
      id: stateKey,
      value: {
        [stateKey]: {
          value,
          isValid: target.checkValidity()
        }
      }
    });
  };
  const handleNumberFieldChange = e => {
    const { target } = e;

    const { id, value } = target;

    const stateKey = id;

    dispatchFormState({
      type: SET_FIELDS,
      id: stateKey,
      value: {
        [stateKey]: {
          value,
          isValid: (target.checkValidity() && isValidMlsNumber(value)) || value === ''
        }
      }
    });
  };
  return (
    <Fragment>
      <ManualAddAddressForm
        address={address}
        isFormSubmitting={isFormSubmitting}
        isFormValid={isFormValid}
        onChange={handleChange}
        dispatchFormState={dispatchFormState}
      />
      <FormFieldset label="Listing information">
        <FormRow>
          <FormField
            id="mlsNumber"
            label="MLS Number"
            value={mlsNumber.value || ''}
            fieldIsValid={mlsNumber.isValid}
            onChange={handleNumberFieldChange}
            disabled={isFormSubmitting}
            formIsValid={isFormValid}
            required={mlsNumber.isRequired}
            validationMessage={mlsNumberValidationMessage}
          />
        </FormRow>
        <FormRow>
          <div className={formFieldStyles.field}>
            <FormLabel htmlFor="propertyType" required={propertyType.isRequired}>
              Property type
            </FormLabel>
            <Dropdown
              id="propertyType"
              items={PROPTYPE_LIST}
              onChange={handleChange}
              defaultValue=" "
              value={propertyType.value ?? ''}
              fieldIsValid={propertyType.isValid}
              formIsValid={isFormValid}
              disabled={isFormSubmitting}
            />
          </div>
        </FormRow>
        <FormRow>
          <FormField
            id="listPrice"
            label="List Price"
            value={listPrice.value || ''}
            fieldIsValid={listPrice.isValid}
            required={listPrice.isRequired}
            onChange={handleNumberFieldChange}
            disabled={isFormSubmitting}
            formIsValid={isFormValid}
          />
        </FormRow>
        <FormRow>
          <FormField
            id="beds"
            label="Beds"
            value={beds.value || ''}
            fieldIsValid={beds.isValid}
            onChange={handleNumberFieldChange}
            disabled={isFormSubmitting}
            formIsValid={isFormValid}
            required={beds.isRequired}
          />
        </FormRow>

        <FormRow>
          <FormField
            id="baths"
            label="Baths"
            value={baths.value || ''}
            fieldIsValid={baths.isValid}
            onChange={handleNumberFieldChange}
            disabled={isFormSubmitting}
            formIsValid={isFormValid}
            required={baths.isRequired}
          />
        </FormRow>

        <FormRow>
          <FormField
            id="sqft"
            label="Sqft"
            value={sqft.value || ''}
            fieldIsValid={sqft.isValid}
            onChange={handleNumberFieldChange}
            disabled={isFormSubmitting}
            formIsValid={isFormValid}
            required={sqft.isRequired}
          />
        </FormRow>

        <FormRow>
          <FormField
            id="yearBuilt"
            label="Year Built"
            value={yearBuilt.value || ''}
            fieldIsValid={yearBuilt.isValid}
            onChange={handleNumberFieldChange}
            disabled={isFormSubmitting}
            formIsValid={isFormValid}
            required={yearBuilt.isRequired}
          />
        </FormRow>
      </FormFieldset>
    </Fragment>
  );
};

ManualAddTransactionForm.propTypes = {
  className: string
};
