import { request } from '../utils';

import { RECAPTCHA_RESPONSE } from '../reducers/reCaptcha';
import { showMessage } from './message';
import { CAPTCHA_ERROR_MESSAGE, CAPTCHA_THRESHOLD } from '../constants';

/**
 * Check whether user registered for texting.
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to check texting registration
 * @param {String} [options.id] - the user id to check texting registration.
 */
export const reCaptchaCall = options => {
  const { action, onSuccess } = options || {};

  return async dispatch => {
    // eslint-disable-next-line no-undef
    grecaptcha.ready(() => {
      // eslint-disable-next-line no-undef
      grecaptcha.execute(process.env.RECAPTCHA_KEY, { action }).then(async token => {
        const requestOptions = {
          baseUrlKey: 'api',
          method: 'POST',
          path: 'recaptcha/siteverify',
          payload: { token, remoteIp: '' },
          shouldBeCached: false
        };

        const { data } = await request(requestOptions);

        const { success, score } = data;

        let threshold =
          // process.env.PUBLIC_HOST === 'https://crm-staging.topproducer.com' ||
          process.env.PUBLIC_HOST === 'https://crm-sqan.topproducer.com' ||
          process.env.PUBLIC_HOST === 'https://crm-itgn.topproducer.com'
            ? 0
            : CAPTCHA_THRESHOLD;

        dispatch({
          type: RECAPTCHA_RESPONSE,
          reCaptchaVerified: success && score >= threshold
        });

        // @TODO: Reintroduce threshold once recaptcha is figured out.
        if (success && score >= threshold) {
          onSuccess();
        } else {
          dispatch(
            showMessage(
              {
                message: CAPTCHA_ERROR_MESSAGE,
                type: 'error'
              },
              false
            )
          );
        }
      });
    });
  };
};
