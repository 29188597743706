import React from 'react';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import styles from './View.css';

export const View = props => {
  const { children, className, isDetailsOpen = false } = props;

  if (!children || children.length === 0) {
    return null;
  }

  const classes = classnames({
    [styles.view]: !className,
    detailsOpen: isDetailsOpen,
    [className]: !!className
  });

  return <div className={classes}>{children}</div>;
};

View.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  className: string,
  isDetailsOpen: bool
};
