import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import '../../assets/illustrations.svg';
import styles from './ListNoResults.css';

const ListNoResults = props => {
  const {
    className,
    isNested = false,
    noResultsIcon = 'clipboard',
    noResultsMessage = 'No matching contacts.'
  } = props;

  const displayAsNested = noResultsMessage.includes('insight') ? false : isNested;

  const classes = classnames({
    [styles.noResults]: !className,
    [styles.isNested]: displayAsNested,
    [className]: !!className
  });

  return (
    <div className={classes} data-cy="global-noresults">
      <svg role="presentation" focusable="false" className={styles.svg}>
        <use xlinkHref={`#illustrations_${noResultsIcon}`} />
      </svg>
      <div className={styles.message}>{noResultsMessage}</div>
    </div>
  );
};

ListNoResults.propTypes = {
  className: PropTypes.string,
  isNested: PropTypes.bool,
  noResultsIcon: PropTypes.oneOf(['clipboard', 'contact']),
  noResultsMessage: PropTypes.string
};

export default ListNoResults;
