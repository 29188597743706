import { getRoot, getIndex } from '../IndexedTree';
import { TASK_PLAN_ADJUSTMENTS, TASK_PLAN_ADJUSTMENT_MAP, TASK_PLAN_ADJUSTMENT_DIRECTIONS } from '../tasks';
import { TASK_PLAN_CATEGORIES } from '.';

/**
 * @typedef PlanItem
 * @property {String} id
 * @property {String} prevItemId
 * @property {Number} adjustEvent
 * @property {Number} adjustDirection @see TASK_PLAN_ADJUSTMENT_DIRECTIONS
 * @property {Number} adjustDays number of adjusted days (e.g., 1 day from plan start, or 2 days from previous task)
 */

/**
 * This class should be treated as an "private" base class, i.e., it should not be instantiated directly
 * This class should be extended by implementing the following properties:
 *
 * @property {import('../IndexedTree').IndexedTree} currentList the tree of items from which we want to group
 * @property {String} key the items in currentList will be grouped by this attribute
 * @property {any} initialValue
 * @property {(item: PlanItem, previousValue: Number) => PlanItem} transform
 * @property {(item: PlanItem) => String} getGroupName
 */
class BaseGrouper {
  constructor(currentList) {
    this.currentList = currentList;
  }

  /**
   * @typedef {Group & { adjustEvent: Number }} AdjustEventGroup
   * @returns {AdjustEventGroup[]} an array of named groups based on and sorted by adjustEvent
   */
  group = () => {
    const items = this.getTransformedItems(this.currentList);
    const groups = this.buildGroups(items);

    return groups;
  };

  /**
   * getTreeItemsWithDateType
   * Calculate the dateType of each item in the list and returns an array
   * of objects containing all the items data including the computed dateType
   *
   * Rule:
   * dateType = item.adjustEvent == "By Previous" ? prevItem.dateType : item.adjustEvent
   *
   * @param {import('../IndexedTree').IndexedTree} currentList the tree of items from which we want to compute dateType
   * @returns {Object[]} array of items with dateType computed
   */
  getTransformedItems = currentList => {
    const root = getRoot(currentList);

    if (!root) {
      return [];
    }

    return this.getNodeItems(root, this.initialValue);
  };

  /**
   * @param {import('../IndexedTree').Node} node the node to get the items from
   * @param {Number} previousValue previous task's adjustEvent value
   * @returns {Object[]} array of items with adjustEvent computed
   *
   * @param {import('../IndexedTree').Node} node the node to get the items from
   * @param {Number} previousValue previous task's daysFromStart value
   * @returns {Object[]} array of items with daysFromStart computed
   */
  getNodeItems = (node, previousValue) => {
    const { data, children } = node;
    const parentItem = this.transform(data, previousValue);

    const childrenArray = children ? Array.from(children).map(([, child]) => child) : [];

    const partial = [];
    for (const child of childrenArray) {
      const childPreviousValue = parentItem?.[this.key] || previousValue;
      const childNodeItems = this.getNodeItems(child, childPreviousValue);
      partial.push(...childNodeItems);
    }

    const items = [parentItem, ...partial];

    const filteredItems = items.filter(item => item);
    return filteredItems;
  };

  /**
   * Build groups of items based on adjustEvent and return them sorted by adjustEvent
   * @typedef {{items: Object[], name: String}} Group
   * @typedef {Group & { adjustEvent: Number }} AdjustEventGroup
   * @param {Object[]} items Array of items containing adjustEvent and the rest of each plan item data
   * @returns {adjustEventGroup[]} an array of named groups based on and sorted by adjustEvent
   */
  buildGroups = items => {
    const groups = new Map();

    for (const item of items) {
      const group = groups.get(item[this.key]);

      if (group) {
        group.push(item);
      } else {
        groups.set(item[this.key], [item]);
      }
    }

    const array = Array.from(groups).map(([groupValue, items]) => ({
      [this.key]: groupValue,
      items,
      name: this.getGroupName({ [this.key]: groupValue })
    }));

    array.sort((a, b) => a[this.key] - b[this.key]);

    return array;
  };
}

class DateTypeGrouper extends BaseGrouper {
  constructor(currentList) {
    super(currentList);
    this.initialValue = null;
    this.key = 'dateType';
  }

  /**
   * @param {PlanItem} item
   * @param {Number} previousValue previous task's dateType value
   */
  getDateType = (item, previousValue) => {
    const { adjustEvent } = item;
    if (adjustEvent === TASK_PLAN_ADJUSTMENTS.By_Previous_Item.value) {
      return previousValue;
    }

    return adjustEvent;
  };

  /**
   * Returns an object containing the task item data including the field dateType
   * @param {PlanItem} item Task item data
   * @param {Number} previousDateType previous task's dateType value
   * @returns {PlanItem | null} an object containing the task item data including the field dateType or null if data is falsey
   */
  transform = (item, previousDateType) => {
    if (!item) {
      // the root of an IndexedTree does not contain data
      // so this check is necessary.
      return null;
    }

    if (!previousDateType) {
      // root
      previousDateType = item.adjustEvent;
    }

    const dateType = this.getDateType(item, previousDateType);

    return {
      ...item,
      dateType
    };
  };

  /**
   * @param {PlanItem} item
   */
  getGroupName(item) {
    const dateTypeKey = TASK_PLAN_ADJUSTMENT_MAP.get(item.dateType);
    return TASK_PLAN_ADJUSTMENTS[dateTypeKey]?.label || 'Other';
  }
}

class DaysFromStartGrouper extends BaseGrouper {
  constructor(currentList) {
    super(currentList);
    this.initialValue = 0;
    this.key = 'daysFromStart';
  }

  /**
   * Returns the number of days from plan start date, based on:
   * - current task's adjustDirection ("on" or "after");
   * - current task's adjustDays; and
   * - previous task's daysFromStart.
   *
   * @param {PlanItem} item
   * @param {Number} previousValue number of days from start of the previous task
   */
  getDaysFromStart = (item, previousValue) => {
    const { adjustDirection, adjustDays } = item;
    if (adjustDirection === 0 || adjustDirection === TASK_PLAN_ADJUSTMENT_DIRECTIONS.on.value) {
      // this item happens in the same day of the previous task
      // Notice: after some operations such as deleting a task
      // the back-end may change the adjustDirection of an item to zero
      // that is the reason why we are also comparing to zero here.
      return previousValue;
    }

    if (adjustDirection === TASK_PLAN_ADJUSTMENT_DIRECTIONS.after.value) {
      // this item happens X days after previous task
      return previousValue + adjustDays;
    }

    // This function is not meant to be used with other adjustDirection values (like "before").
    // if adjustDirection === "before" then the item should neither based on
    // the Previous Item nor based on Plan Start Date.
    // Therefore, we throw an error, to fail fast.
    throw new Error(`Can not compute daysFromStart for adjustDirection=${adjustDirection}`);
  };

  /**
   * Returns an object containing the task item data including the field daysFromStart
   * @param {Object} data Task item data
   * @param {Number} previousDaysFromStart previous task's daysFromStart value
   * @returns {Object|null} an object containing the task item data including the field daysFromStart or null if data is falsey
   */
  transform = (item, previousDaysFromStart) => {
    if (!item) {
      // the root of an IndexedTree does not contain data
      // so this check is necessary.
      return null;
    }

    const daysFromStart = this.getDaysFromStart(item, previousDaysFromStart);

    return {
      ...item,
      daysFromStart
    };
  };

  /**
   * @param {PlanItem} item
   */
  getGroupName = item => {
    const { daysFromStart } = item;
    if (!daysFromStart) {
      return 'Day 0 - Plan Start Date';
    }

    return `Day ${daysFromStart}`;
  };
}

/**
 * Calculate the daysFromStart of each item in the list and returns an array
 * of objects containing all the items data including the computed daysFromStart
 *
 * Build groups of items based on and sorted by daysFromStart
 *
 * @param {import('../IndexedTree').IndexedTree} currentList the tree of items from which we want to compute daysFromStart
 * @returns {DaysFromStartGroup[]} an array of named groups based on and sorted by daysFromStart
 */
function groupByDaysFromStart(currentList) {
  const grouper = new DaysFromStartGrouper(currentList);
  return grouper.group();
}

/**
 *  @typedef {Group & { adjustEvent: Number }} AdjustEventGroup
 * @param {import('../IndexedTree').IndexedTree} currentList the tree of items from which we want to group
 * @returns {import('./groupByDaysFromStart').AdjustEventGroup[]} an array of named groups based on and sorted by adjustEvent
 */
function groupByDateType(currentList) {
  const grouper = new DateTypeGrouper(currentList);
  return grouper.group();
}

/**
 * @param {import('../IndexedTree').IndexedTree} currentList the tree of items from which we want to group
 * @returns {import('./groupByDaysFromStart').AdjustEventGroup[]} an array with a single group with no name.
 */
function singleGroup(currentList) {
  if (!currentList) {
    return [];
  }

  return [
    {
      name: '',
      items: Array.from(getIndex(currentList)).map(([, value]) => value.data)
    }
  ];
}

export const ListGroupers = {
  groupByDateType,
  groupByDaysFromStart,
  singleGroup
};

export function getCategoryGrouping(categoryId) {
  switch (categoryId) {
    case TASK_PLAN_CATEGORIES.transactions:
      return ListGroupers.groupByDateType;
    case TASK_PLAN_CATEGORIES.contacts:
      return ListGroupers.groupByDaysFromStart;
    default:
      return ListGroupers.singleGroup;
  }
}
