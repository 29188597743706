/** @module */
import {
  deleteFromCollection,
  deleteFromGroup,
  getMergedEntities,
  getMergedSetOfIds,
  sortCollectionByDate
} from '../utils/collections';

export const IMPORTANT_DATES_TOGGLE_FORM = 'IMPORTANT_DATES_TOGGLE_FORM';
export const IMPORTANT_DATES_CURRENT_GROUP = 'IMPORTANT_DATES_CURRENT_GROUP';
export const IMPORTANT_DATES_SET = 'IMPORTANT_DATES_SET';
export const IMPORTANT_DATES_DELETE = 'IMPORTANT_DATES_DELETE';
export const IMPORTANT_DATES_LOADING = 'IMPORTANT_DATES_LOADING';
export const IMPORTANT_DATES_MARKDONE = 'IMPORTANT_DATES_MARKDONE';

export const initialState = {
  entities: {},
  groups: {},
  currentGroup: 'important::current',
  donePaging: {},
  toggleForm: false,
  dateToEdit: null,
  isLoading: false
};

/**
 * The tasks redux reducer.
 * @param {Object} state - the current state of the tasks store.
 * @param {Object} action - the action to take on the tasks store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 * @param {Boolean} [action.isPaging] - Denotes whether the request data is due to paging.
 * @param {String} [action.activityType] - The request query acts as a key when storing groups of tasks.
 * @param {Boolean} [action.isLoading] - Denotes whether the data request is in progress.
 */
export const importantDatesReducer = (state = initialState, action = {}) => {
  const { type = 'default', data = {}, isPaging, query, isLoading, setCurrentGroup, donePaging = true } = action; // What all values supported for activity type not known
  const { id, entityId, group } = data; // entityId is usually the contact

  switch (type) {
    case IMPORTANT_DATES_SET:
      const mergedEntities = getMergedEntities(state.entities, data.records);

      return {
        ...state,
        currentGroup: setCurrentGroup ? query : state.currentGroup,
        isLoading: isLoading,
        entities: mergedEntities,
        groups: {
          ...state.groups,
          [query]: getMergedSetOfIds(state.groups[query], data.records, {
            isPaging,
            sortFunction: sortCollectionByDate,
            sortDirection: 'asc',
            sortKey: 'importantDate',
            entities: mergedEntities
          })
        },
        donePaging: {
          ...state.donePaging,
          [query]: donePaging
        }
      };
    case IMPORTANT_DATES_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: query,
        isLoading: isLoading
      };
    case IMPORTANT_DATES_LOADING:
      return {
        ...state,
        isLoading: isLoading
      };
    case IMPORTANT_DATES_MARKDONE: // reminders don't really have a done state per se, we simply remove them from the current list under tasks
      return {
        ...state,
        groups: {
          ...state.groups,
          'important::current': deleteFromGroup(state.groups['important::current'], id)
        }
      };
    case IMPORTANT_DATES_DELETE:
      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, id)
        },
        groups: {
          ...state.groups,
          [group]: deleteFromGroup(state.groups[group], id),
          // we also clean-up the tasks-based important-dates tab.
          'important::current':
            group === 'important::current'
              ? 'important::current'
              : deleteFromGroup(state.groups['important::current'], id),
          [`important::next::${entityId}`]: deleteFromGroup(state.groups[`important::next::${entityId}`], id)
        }
      };
    case IMPORTANT_DATES_TOGGLE_FORM:
      return {
        ...state,
        toggleForm: !state.toggleForm,
        dateToEdit: id || null
      };
    default:
      return state;
  }
};
