import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './FormRadio.css';

export const FormRadioGroup = props => {
  const { children, className, fieldIsValid, formIsValid, name, disabled } = props;
  const showInvalid = formIsValid === false && fieldIsValid === false;

  const classes = classnames({
    [styles.group]: !className,
    [styles.invalid]: showInvalid,
    [className]: !!className
  });

  return (
    <div className={disabled ? styles.groupWrapDisabled : styles.groupWrap}>
      <div className={classes} role="radiogroup">
        {Children.map(children, child => {
          return cloneElement(child, {
            name,
            disabled
          });
        })}
      </div>
    </div>
  );
};

FormRadioGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};
