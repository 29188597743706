import { deleteFromCollection, deleteFromGroup, getMergedEntities, getMergedSetOfIds } from '../utils';

export const GET_CONTACT_PROPERTIES = 'GET_CONTACT_PROPERTIES';
export const GET_CONTACT_PROPERTIES_SUCCESS = 'GET_CONTACT_PROPERTIES_SUCCESS';
export const CREATE_NEW_CONTACT_PROPERTY = 'CREATE_NEW_CONTACT_PROPERTY';
export const CONTACT_PROPERTY_FORM_TOGGLE = 'CONTACT_PROPERTY_FORM_TOGGLE';
export const SET_CONTACT_PROPERTY_DETAILS_FOR_FORM = 'SET_CONTACT_PROPERTY_DETAILS_FOR_FORM';
export const SET_PREFILLED_CONTACT_PROPERTY_FORM_DATA = 'SET_PREFILLED_CONTACT_PROPERTY_FORM_DATA';
export const SET_CONTACT_PROPERTY_FORM_EDIT_MODE = 'SET_CONTACT_PROPERTY_FORM_EDIT_MODE';
export const DELETE_CONTACT_PROPERTY = 'DELETE_CONTACT_PROPERTY';
export const TOGGLE_TRANSACTION_FORM = 'TOGGLE_TRANSACTION_FORM';

export const initialState = {
  currentGroup: '0', // group name format: ${status}::${representationType}::${startDate}::${endDate}, otherwise contactId
  entities: {},
  groups: {},
  togglePropertyForm: false,
  selectedPropertyDetails: {},
  prefillFormData: {},
  isLoading: true,
  toggleAddTransactionForm: false,
  contactId: null,
  propertyId: null
};

export const propertiesReducer = (state = initialState, action = {}) => {
  const {
    togglePropertyFormModal,
    group,
    prefillFormData,
    propertyFormIsEditMode,
    propertyRecords,
    selectedPropertyDetails,
    propertyIdToDelete,
    propertyId,
    contactId,
    type = 'default'
  } = action;

  switch (type) {
    case DELETE_CONTACT_PROPERTY:
      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, propertyIdToDelete)
        },
        groups: Object.keys(state.groups).reduce((acc, group) => {
          acc[group] = deleteFromGroup(state.groups[group], propertyIdToDelete);
          return acc;
        }, {})
      };
    case GET_CONTACT_PROPERTIES:
      return {
        ...state,
        isLoading: true
      };
    case GET_CONTACT_PROPERTIES_SUCCESS:
      const mergedEntities = getMergedEntities(state.entities, propertyRecords);
      return {
        ...state,
        entities: mergedEntities,
        groups: {
          ...state.groups,
          [group]: getMergedSetOfIds(state.groups[group], propertyRecords, {
            entities: mergedEntities
          })
        },
        currentGroup: group,
        isLoading: false
      };
    case CONTACT_PROPERTY_FORM_TOGGLE:
      return {
        ...state,
        togglePropertyFormModal
      };
    case SET_CONTACT_PROPERTY_DETAILS_FOR_FORM:
      return {
        ...state,
        selectedPropertyDetails
      };
    case SET_PREFILLED_CONTACT_PROPERTY_FORM_DATA:
      return {
        ...state,
        selectedPropertyDetails: {},
        prefillFormData
      };
    case SET_CONTACT_PROPERTY_FORM_EDIT_MODE:
      return {
        ...state,
        propertyFormIsEditMode
      };
    case TOGGLE_TRANSACTION_FORM:
      return {
        ...state,
        toggleAddTransactionForm: !state.toggleAddTransactionForm,
        propertyId,
        contactId
      };
    default:
      return state;
  }
};
