import classnames from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Container, FormRadio, FormRadioGroup, Icon, Loading } from '../';
import { getContactStatus } from '../../utils';

import styles from './ContactStatus.css';

const DEFAULT_STATUSES = [10, 20, 40, 60, 70];
const EXTENDED_STATUSES = [80, 50];

const StatusRadio = props => {
  const { status, currentStatus, handleChange, showIcon = true, type, source = 'tpx' } = props;
  const displayStatus = getContactStatus(status);

  const iconSelector = displayStatus.toLowerCase().replace(' ', '');

  // capitalize first word
  const words = displayStatus.split(' ');
  const identifier = words
    .map(word => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join('');

  // We don't show an icon for EXTENDED_STATUSES.
  const iconName = showIcon ? iconSelector : null;

  const classes = classnames({
    [styles.radio]: true,
    [styles.noGrow]: source === 'zillow'
  });

  return (
    <FormRadio
      className={classes}
      key={`${type}${identifier}`}
      icon={iconName}
      id={`${type}Status${identifier}`}
      label={displayStatus}
      value={status}
      onChange={handleChange}
      size="s"
      checked={parseInt(status) === parseInt(currentStatus)}
      data-cy={`${type}Status${identifier}`}
    />
  );
};

export const ContactStatus = props => {
  const { zillowContactStatuses } = useSelector(store => store.zillow);
  const { isLoading } = useSelector(store => store.contacts);
  const {
    currentStatus,
    contact,
    handleChange,
    showAllStatuses = false,
    type = 'contact',
    selectedStatus = null,
    externalContactStatusSource,
    showAllZillowStatuses = false
  } = props;

  const { externalContactStatus, contactTypes } = contact || {};

  const [showAllZ, setShowAllZ] = useState(showAllZillowStatuses);

  const handleZillowStatusChange = (internalStatusId, externalStatusId) => {
    setShowAllZ(false);
    handleChange(internalStatusId, externalStatusId);
  };

  const displayStatusRadios = () => {
    if (externalContactStatusSource !== 1) {
      return DEFAULT_STATUSES.map(status => (
        <StatusRadio
          key={status}
          status={status}
          handleChange={handleChange}
          currentStatus={currentStatus}
          type={type}
        />
      ));
    }

    if (externalContactStatusSource === 1) {
      const isBuyer = contactTypes.includes('Buyer');
      const isSeller = contactTypes.includes('Seller');
      const groupedByinternalStatusId = zillowContactStatuses
        .filter(status => {
          const { externalStatusId } = status;
          const isSellerExternalStatus = externalStatusId === '535' || externalStatusId === '540';
          const isBuyerExternalStatus = externalStatusId === '530' || externalStatusId === '565';

          // exclude buyer/seller-specific statuses when contact type is not categorize as either
          if (
            (!contactTypes || !contactTypes.length || !(isBuyer || isSeller)) &&
            !isSellerExternalStatus &&
            !isBuyerExternalStatus
          ) {
            return status;
          }

          if (isBuyer && !isSellerExternalStatus) {
            return status;
          }

          if (isSeller && !isBuyerExternalStatus) {
            return status;
          }
        })
        .reduce((acc, obj) => {
          const { internalStatusId } = obj;
          if (acc[internalStatusId]) {
            acc[internalStatusId].push(obj);
          } else {
            acc[internalStatusId] = [obj];
          }
          return acc;
        }, {});

      if (showAllZ) {
        return (
          <>
            {Object.keys(groupedByinternalStatusId).map((status, idx) => {
              const groupLabel = getContactStatus(status).toLowerCase().replace(' ', '');

              return (
                <div key={`status-${status}`} className={styles.zillowGroup}>
                  <div className={styles.title}>
                    <div className={styles.titleLabel}>
                      <Icon
                        name={groupLabel}
                        size="s"
                        className={`${parseInt(status) === currentStatus ? styles.radioIconActive : null} ${
                          styles[groupLabel]
                        }`}
                      />
                      {getContactStatus(status)}
                    </div>

                    <div className={styles.group}>
                      {groupedByinternalStatusId[status].map(status => {
                        const { externalStatusId, internalStatusId } = status;

                        return (
                          <StatusRadio
                            key={externalStatusId}
                            status={parseInt(externalStatusId)}
                            handleChange={() => handleZillowStatusChange(internalStatusId, externalStatusId)}
                            currentStatus={type === 'contact' ? externalContactStatus : selectedStatus}
                            source="zillow"
                            showIcon={false}
                            type={type}
                          />
                        );
                      })}
                    </div>

                    {idx === 0 && (
                      <Button
                        icon="undo"
                        ariaLabel="Cancel"
                        size="xs"
                        tooltipPos="left"
                        onClick={() => {
                          setShowAllZ(false);
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </>
        );
      } else {
        const tpxGroupName = getContactStatus(currentStatus).toLowerCase().replace(' ', '');

        return (
          <div className={styles.zillowGroup}>
            <div className={styles.title}>
              <div className={styles.titleLabel}>
                <Icon name={tpxGroupName} size="s" className={`${styles.radioIconActive} ${styles[tpxGroupName]}`} />
                {getContactStatus(currentStatus)}
              </div>

              <Button icon="edit" ariaLabel="Change" size="xs" tooltipPos="left" onClick={() => setShowAllZ(true)} />
            </div>

            <div className={styles.group}>
              {groupedByinternalStatusId[currentStatus]?.map(zillowChildStatus => {
                const { externalStatusId, externalStatusName, internalStatusId } = zillowChildStatus;
                return (
                  <StatusRadio
                    key={externalStatusName}
                    status={parseInt(externalStatusId)}
                    handleChange={() => handleZillowStatusChange(internalStatusId, externalStatusId)}
                    currentStatus={type === 'contact' ? externalContactStatus : selectedStatus}
                    source="zillow"
                    showIcon={false}
                    type={type}
                  />
                );
              })}
            </div>
          </div>
        );
      }
    }
  };

  const classes = classnames({
    [styles.isZillow]: externalContactStatusSource === 1
  });

  return (
    <Container className={styles.container}>
      {isLoading ? (
        <div className={styles.loadingWrapper}>
          <Loading loading={true} />
        </div>
      ) : (
        <>
          <FormRadioGroup className={classes} name="status">
            {displayStatusRadios()}
          </FormRadioGroup>
          {showAllStatuses && externalContactStatusSource !== 1 && (
            <FormRadioGroup name="status">
              {EXTENDED_STATUSES.map(status => (
                <StatusRadio
                  key={status}
                  status={status}
                  handleChange={handleChange}
                  currentStatus={currentStatus}
                  type={type}
                />
              ))}
            </FormRadioGroup>
          )}
        </>
      )}
    </Container>
  );
};

ContactStatus.propTypes = {
  currentStatus: PropTypes.oneOf([10, 20, 40, 50, 60, 70, 80]),
  handleChange: PropTypes.func,
  showAllStatuses: PropTypes.bool,
  type: PropTypes.oneOf(['addContact', 'contact', 'importContact', 'wrapup'])
};
