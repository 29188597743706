// 0 and 5 are not real BE values
export const REPEATING_PATTERNS = [
  { title: 'Does not repeat ', value: '0', id: 'noReeat' },
  { title: 'Daily', value: '1', id: 'daily', repeatType: '1' },
  { title: 'Weekly on ', value: '2', id: 'weekly', repeatType: '2' },
  { title: 'Monthly on the ', value: '3', id: 'monthlyOnDate', repeatType: '3' },
  { title: 'Monthly on the ', value: '4', id: 'monthlyOnDay', repeatType: '3' },
  { title: 'Monthly on the ', value: '5', id: 'monthly', repeatType: '3' },
  { title: 'Annually on', value: '6', id: 'yearly', repeatType: '4' },
  { title: 'Custom', value: 'custom', id: 'custom', repeatType: '5' }
];

export const CUSTOM_REPEAT_OPTIONS = [
  { title: 'day', value: '1' },
  { title: 'week', value: '2' },
  { title: 'month', value: '3' },
  { title: 'year', value: '4' }
];

export const END_AFTER_OPTIONS = {
  1: { default: '20', max: '365' }, // day
  2: { default: '12', max: '156' }, // week
  3: { default: '12', max: '60' }, // month
  4: { default: '5', max: '10' } // year
};

export const HI_REPORT_FREQUENCY = {
  disabled: { label: 'Do not send automated HomeIntelle reports', value: '-1' },
  monthly: { label: 'Auto-send HomeIntelle monthly', value: '1' },
  quarterly: { label: 'Auto-send HomeIntelle quarterly', value: '3' },
  biAnnually: { label: 'Auto-send HomeIntelle bi-annually', value: '6' }
};

export const HI_NOTIFICATION_FREQUENCY = {
  immediately: { label: 'Immediately', value: 'immediately' },
  daily: { label: 'Daily Summary', value: 'daily' },
  weekly: { label: 'Weekly Summary', value: 'weekly' },
  monthly: { label: 'Monthly Summary', value: 'monthly' }
};

export const HI_ACTIVITY_EVENT_TYPE = {
  ADDRESS_UPDATE: 'ADDRESS_UPDATE',
  VALUE_ADJUSTMENT: 'VALUE_ADJUSTMENT',
  MORTGAGE_ADJUSTMENT: 'MORTGAGE_ADJUSTMENT',
  QUESTION_SUBMITTED: 'QUESTION_SUBMITTED',
  CTA_CLICK: 'CTA_CLICK',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  UNSUBSCRIBE_REASON_SUBMISSION: 'UNSUBSCRIBE_REASON_SUBMISSION',
  RESUBSCRIBE: 'RESUBSCRIBE'
};

export const HI_HISTORY_EVENT_TYPE = {
  REPORT_GENERATION_SUCCESS: 'REPORT_GENERATION_SUCCESS',
  REPORT_GENERATION_ERROR: 'REPORT_GENERATION_ERROR',
  CONSUMER_CREATE_ERROR: 'CONSUMER_CREATE_ERROR',
  CONSUMER_UPDATE_ERROR: 'CONSUMER_UPDATE_ERROR',
  EMAIL_SENT: 'EMAIL_SENT',
  WELCOME_EMAIL_SENT: 'WELCOME_EMAIL_SENT',
  REPORT_VISIT: 'REPORT_VISIT',
  EMAIL_DELIVERED: 'EMAIL_DELIVERED',
  EMAIL_FAILED: 'EMAIL_FAILED',
  EMAIL_OPENED: 'EMAIL_OPENED'
};
