import React, { useState, useEffect, useRef } from 'react';
import { number, string } from 'prop-types';

const TypingEffect = ({ text, speed = 5, className }) => {
  const [displayedText, setDisplayedText] = useState('');

  const messagesEndRef = useRef(null);

  useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    let currentIndex = 0;

    const intervalId = setInterval(() => {
      setDisplayedText(prev => {
        if (currentIndex <= text.length) {
          currentIndex += 1;

          if (currentIndex % 10 === 0 && currentIndex > 0) {
            scrollToBottom();
          }
          return text.substring(0, currentIndex);
        }
        clearInterval(intervalId);

        return prev;
      });
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed]);

  if (messagesEndRef == null) {
    return <div>{displayedText}</div>;
  }

  // Typing Effect is only on for the last message, so we also we put the ref there to keep it within view
  return (
    <>
      <div className={className}>
        {displayedText}
        <span ref={messagesEndRef}></span>
      </div>
    </>
  );
};

TypingEffect.propTypes = {
  className: string,
  test: string.isRequired,
  speed: number
};

export default TypingEffect;
