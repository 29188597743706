import { REFER_A_FRIEND_LINK } from '../constants';
import { getHelpLink } from '../utils/help';

const SUPPORT_SITE_LINK = getHelpLink('home');

export const USER_MENU_SUPPORT_LINKS = [
  {
    icon: 'support',
    id: 'visitSupport',
    label: 'Support site',
    url: SUPPORT_SITE_LINK
  },
  {
    icon: 'referfriend',
    id: 'referFriend',
    label: 'Refer a friend',
    url: REFER_A_FRIEND_LINK
  }
];
