import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './FormRow.css';

export const FormRow = props => {
  const { children, className } = props;

  if (!children) {
    return null;
  }

  const classes = classnames({
    [styles.row]: !className,
    [className]: !!className
  });

  return <div className={classes}>{children}</div>;
};

FormRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
};
