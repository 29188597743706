import { getTemplateKeyFromMergeCode, TEMPLATE_MERGE_CODE_REGEX } from '../components/Editor/editor-utils';
import { REGEX, UNICODE } from '../constants';
import { DEFAULT_STATES_LIST } from '../data/states';
import { getAddressStr, getSpouseStr, validateEmail, validateUrl } from './strings';
import { getMergeCodeContent } from './templates';

const { ZERO_WIDTH_SPACE } = UNICODE;

/** Gets an object's key value using a string or string using dot notation.
 * @param {object} obj - the object.
 * @param {string} str - the string to lookup.
 */
export const getObjValueFromStr = (obj, str) => {
  if (!obj || !str) {
    return null;
  }

  return str.split('.').reduce((acc, fragment) => acc && acc[fragment], obj);
};

/**
 * Returns a properly formatted street address string.
 * @param {Object} address - an address.
 */
export const getStreetAddressStr = (address, options) => {
  const { forGoogleMaps = false } = options || {};

  if (!address) {
    return '';
  }

  const {
    addressLine,
    line,
    dirPrefix = '',
    dirSuffix = '',
    houseNumber = '',
    streetName = '',
    unit = '',
    streetType = ''
  } = address;

  const prefixFragment = dirPrefix === '' ? ' ' : ` ${dirPrefix} `;

  if ((addressLine || line) && !forGoogleMaps) {
    return addressLine || line;
  }

  const streetFragment = `${houseNumber}${prefixFragment}${streetName} ${streetType}${dirSuffix}`.trim();

  if (forGoogleMaps) {
    return streetFragment;
  }

  const unitFragment = unit ? `#${unit} - ` : ' ';

  return `${unitFragment}${streetFragment}`.trim();
};

/**
 * Inserts data from a contact or user into a template string.
 * @param {string} template - the template to convert
 * @param {object} contact - a contact entity.
 * @param {object} agent - the data from user and userInfo.
 * @param {object} contactFormattedStrings - supplementary formatted data for the contact.
 * @param {object} agentFormattedStrings - supplementary formatted data for the agent.
 */
export const interpolate = (
  template,
  contact,
  agent,
  relationship,
  contactFormattedStrings,
  agentFormattedStrings,
  relationshipFormattedStrings,
  isText = false,
  fallback
) => {
  const supportedDataTypes = ['contact', 'agent', 'relationship'];

  return template?.replace(TEMPLATE_MERGE_CODE_REGEX, match => {
    const templateKey = getTemplateKeyFromMergeCode(match);
    const keyArray = templateKey.replaceAll(ZERO_WIDTH_SPACE, '').split('.');

    // data keys in templates should be preceded by their type
    const dataType = keyArray.shift();
    const path = keyArray.join('.');
    const templatefallback = fallback != null ? fallback : `{{${templateKey}}}`;
    if (supportedDataTypes.includes(dataType)) {
      const entity = (() => {
        switch (dataType) {
          case 'contact':
            return contactFormattedStrings?.[path] ? contactFormattedStrings : contact;
          case 'agent':
            return agentFormattedStrings?.[path] ? agentFormattedStrings : agent;
          case 'relationship':
            return getObjValueFromStr(relationshipFormattedStrings, path) ? relationshipFormattedStrings : relationship;
        }
      })();
      let dataStr = match || templatefallback;

      const mergeData = getObjValueFromStr(entity, path);
      if (mergeData) {
        const mergeCodeContent = getMergeCodeContent(match);
        dataStr = mergeCodeContent.replace(templateKey, mergeData);
      }

      // Some merge codes aren't simple string insertions, but need supporting HTML, since we are inserting the entire
      // template's content at once, and not just a node. If it were just a link for instance the withLinks plugin would suffice.
      const isUrl = validateUrl(dataStr, REGEX.URL_SIMPLE_CASE_INSENSITIVE);
      const isEmail = validateEmail(dataStr);
      const isAddressObj = typeof dataStr === 'object' && (dataStr.addressLine || dataStr.line);

      if (!isText && (isUrl || isEmail)) {
        const uriScheme = isEmail ? 'mailto:' : '';
        dataStr = `<a href="${uriScheme}${dataStr}" target="_blank" rel="noopener" />${dataStr}</a>`;
      }

      if (!isText && isAddressObj) {
        dataStr = getStreetAddressStr(dataStr);
      }

      return dataStr;
    }

    return '';
  });
};

/**
 * Returns a properly formatted city, state, zip address line.
 * @param {Object} address - an address.
 */
export const getCityStateStr = address => {
  if (!address) {
    return '';
  }

  const { city, state, zip } = address;

  const cityStateFragment = city && state ? `${city}, ${state}` : city || state || '';
  const zipFragment = zip ? zip : '';

  return `${cityStateFragment} ${zipFragment}`.trim();
};

/**
 * Returns a Google Maps url from an address
 * @param {Object} address - an address.
 */
export const getGoogleMapsUrlFromAddress = address => {
  if (!address) {
    return null;
  }

  const addressFragment = getStreetAddressStr(address, { forGoogleMaps: true });
  const hasAddressFragment = addressFragment.length > 0;

  const cityStateFragment = getCityStateStr(address);
  const hasCityStateFragment = cityStateFragment.length > 0;

  const str =
    hasAddressFragment && hasCityStateFragment
      ? `${addressFragment},${cityStateFragment}`
      : addressFragment || cityStateFragment || '';

  return `https://www.google.com/maps/search/?api=1&query=${encodeURI(str)}`;
};

/**
 * Checks if object is empty
 * @param {Object} obj
 */
export const checkIfObjectIsEmpty = obj => {
  if (obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  return true;
};

/**
 * Appends a string to the value of objects in an array
 * @param {Object[]} obj
 * @param {String} key
 * @param {String} str
 */
export const appendToObject = (data, key, str) => {
  if (data == null) {
    return null;
  }
  if (data.length < 1 || key == null || key === '') {
    return data;
  }
  return data.reduce((acc, item) => {
    if (item[key]) {
      acc.push({
        ...item,
        [key]: `${item[key]} ${str}`
      });
    }
    return acc;
  }, []);
};

export const getStateCodeStr = input => {
  const stateData = DEFAULT_STATES_LIST.find(state => state.title.toLowerCase() === input?.toLowerCase());
  return stateData?.value || input?.toLowerCase() || '';
};

export const hasSecondaryPerson = contact => {
  return contact?.secondaryPerson?.firstName?.length > 0 || contact?.secondaryPerson?.lastName?.length > 0;
};

export const getSpouseObject = (contact, relationship) => {
  return hasSecondaryPerson(contact)
    ? {
        relatedContactFirstName: contact.secondaryPerson.firstName,
        relatedContactLastName: contact.secondaryPerson.lastName,
        relatedContactFullName: contact.secondaryPerson.fullName
      }
    : relationship?.find(item => item?.relationshipTypeName?.toLowerCase() === 'spouse');
};

export const interpolateAddressData = (contactData, userInfo, relationship) => {
  const contactFormatted = {
    address: getAddressStr(contactData?.address)
  };

  const agentFormatted = {
    address: getAddressStr(userInfo?.agentAddress),
    googleMapsLink: getGoogleMapsUrlFromAddress(userInfo?.agentAddress)
  };

  const foundSpouse = getSpouseObject(contactData, relationship);
  const relationshipFormatted = getSpouseStr(foundSpouse);

  return { contactFormatted, agentFormatted, relationshipFormatted };
};
