// Don't change the order of the following two imports
import '!style-loader!css-loader!react-responsive-carousel/lib/styles/carousel.min.css';
import React, { Component } from 'react';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import classnames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { signInUser } from '../../actions';

import { clearMessage, showMessage } from '../../actions/message';
import { reCaptchaCall } from '../../actions/reCaptcha';
import { Button, ButtonGroup, Footer, FormField, FormRow, Loading, Logo, Panel } from '../../components';
import { Header } from '../../components/Header';
import { MarketingCarousel } from '../../components/MarketingCarousel/MarketingCarousel';
import { BROKER_HOST, HOST } from '../../constants';
import { trackEvent } from '../../utils/analytics';
import { getHelpLink } from '../../utils/help';
import { generateTitleTag } from '../../utils/strings';

import styles from './SignIn.css';
import { SignInErrorCallToAction } from '../../components/CallToAction/SignInErrorCallToAction';

export class SignIn extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      fields: {
        name: {
          value: '',
          isValid: false
        },
        password: {
          value: '',
          isValid: false
        }
      },
      formIsValid: null,
      formIsSubmitting: false,
      needSetup: false,
      signInError: null,
      userId: null
    };
  }

  handleChange = e => {
    const { target } = e;
    this.setState({
      fields: {
        ...this.state.fields,
        [target.id]: {
          value: target.value,
          isValid: target.checkValidity()
        }
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { clearMessage, reCaptchaCall } = this.props;

    // clear any existing app messages
    clearMessage();

    const formIsValid = this.formRef.current.checkValidity();

    if (!formIsValid) {
      this.setState({
        formIsValid
      });

      return;
    }

    const formData = Object.keys(this.state.fields).reduce((acc, key) => {
      if (!acc[key]) {
        acc[key] = this.state.fields[key].value;
      }

      return acc;
    }, {});

    trackEvent('authentication', 'sign in');

    // put the form into submitting state
    this.setState({ formIsSubmitting: true });

    if (process.env.ENV !== 'dev') {
      reCaptchaCall({
        action: 'signin',
        onSuccess: () => {
          this.signIn(formData);
        }
      });
    } else {
      this.signIn(formData);
    }
  };

  signIn = async formData => {
    const { signInUser } = this.props;

    signInUser(formData).then(result => {
      const { needSetup, userId, id, exceptionType } = result || {};

      if (needSetup) {
        this.setState({ needSetup, userId });
      }

      if (exceptionType) {
        if (id !== 60001) {
          this.setState({ signInError: id });
        }
      }
    });
  };

  componentDidUpdate({}, prevState) {
    const { fields, needSetup, userId } = this.state;

    if (prevState.formIsSubmitting) {
      // the form can only be submitting for one round  of state changes,
      // so we set it to false if the state is changed a second time
      this.setState({ formIsSubmitting: false });
    }

    if (prevState.needSetup !== needSetup) {
      const { name, password } = fields;

      navigate('/setupuser', {
        state: {
          authCreds: {
            name: name.value.toLowerCase(),
            password: password.value,
            userId
          }
        }
      });
    }
  }
  render() {
    const { signInError } = this.state;

    const { message, reCaptcha } = this.props;

    const classes = classnames({
      [styles.signInPanel]: true,
      [styles.shakePanel]: message && message.id === 60001 // LoginExceptionç
    });

    const helpLink = getHelpLink('demo', { isCampusLink: false, utmCampaign: 'tp-sign-in' });
    const statusLink = getHelpLink('status', { isCampusLink: false, utmCampaign: 'tp-sign-in' });
    return (
      <>
        <Helmet>
          <title>{generateTitleTag('Sign In')}</title>
        </Helmet>
        <div className={styles.layout}>
          <div className={styles.columnForm}>
            <Header className={styles.header}>
              <ButtonGroup>
                <Button
                  ariaLabel="Broker Tools"
                  data-cy="brokerTools"
                  externalLink={true}
                  label="Broker Tools"
                  url={BROKER_HOST}
                />
                <Button
                  ariaLabel="Top Producer 8i"
                  data-cy="topProducer8i"
                  externalLink={true}
                  label="Top Producer 8i"
                  styleType="white"
                  url={HOST}
                />
              </ButtonGroup>
            </Header>
            <div className={styles.formContainer}>
              <Logo name="full" className={styles.logoSignIn} />
              {reCaptcha.isHuman && (
                <>
                  {signInError ? (
                    <SignInErrorCallToAction errorType={signInError} />
                  ) : (
                    <>
                      <Panel className={classes}>
                        <form id="signInForm" ref={this.formRef} data-testid="sign-in-form">
                          <FormRow>
                            <FormField
                              autocomplete="username"
                              data-cy="username"
                              disabled={this.state.formIsSubmitting}
                              id="name"
                              inputType="text"
                              label="Username"
                              fieldIsValid={this.state.fields.name.isValid}
                              formIsValid={this.state.formIsValid}
                              onChange={this.handleChange}
                              size="xl"
                              value={this.state.fields.name.value}
                              autoCapitalize="off"
                              required
                            />
                          </FormRow>
                          <FormRow>
                            <FormField
                              autocomplete="current-password"
                              data-cy="password"
                              disabled={this.state.formIsSubmitting}
                              enterKeyHint="go"
                              id="password"
                              inputType="password"
                              label="Password"
                              fieldIsValid={this.state.fields.password.isValid}
                              formIsValid={this.state.formIsValid}
                              onChange={this.handleChange}
                              size="xl"
                              value={this.state.fields.password.value}
                              required
                            />
                          </FormRow>
                          <ButtonGroup className={styles.buttonGroup}>
                            <Button
                              ariaLabel="Sign in to Top Producer"
                              className={styles.primaryButton}
                              data-cy="submit"
                              disabled={this.state.formIsSubmitting}
                              label="Sign In"
                              onClick={this.handleSubmit}
                              styleType="primary"
                              type="submit"
                            />
                            <Link className={styles.forgotPassword} data-cy="forgotPassword" to="/reset-password">
                              Forgot Password?
                            </Link>
                          </ButtonGroup>
                        </form>
                      </Panel>

                      <p className={styles.cta}>
                        Don't have a real estate CRM?{' '}
                        <a href={helpLink} className={styles.link} target="_blank" rel="noopener" data-cy="requestDemo">
                          Get a free demo
                        </a>
                        .
                      </p>

                      <p className={styles.cta}>
                        Having issues? Visit our{' '}
                        <a href={statusLink} className={styles.link} target="_blank" rel="noopener">
                          status page
                        </a>
                        .
                      </p>

                      <Loading loading={this.state.formIsSubmitting} className={styles.loading} />
                    </>
                  )}
                </>
              )}
            </div>
            <Footer />
          </div>
          <div className={styles.columnMkt}>
            <div className={styles.mktContainer}>
              <MarketingCarousel />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  message: state.message,
  reCaptcha: state.reCaptcha,
  user: state.user,
  userProfile: state.userProfile
});

const mapDispatchToProps = {
  clearMessage,
  signInUser: state => signInUser(state),
  reCaptchaCall,
  showMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
