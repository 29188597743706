import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setNotesCurrentGroup } from '../../actions/notes';
import { NotesList } from '../../components';
import { View } from '../../components/View';
import { Details } from '../Details';

import styles from './Notes.css';

export class Notes extends Component {
  componentDidMount() {
    const { setNotesCurrentGroup } = this.props;

    setNotesCurrentGroup({ group: 'myNotes' });
  }

  render() {
    const { children, location } = this.props;
    const { pathname } = location;

    const detailsOpen = pathname.startsWith('/notes/'); // isDetailsPage

    return (
      <View isDetailsOpen={detailsOpen}>
        <Details className={styles.detailsContainer}>{children}</Details>
        <NotesList group="myNotes" />
      </View>
    );
  }
}

const mapStateToProps = state => ({
  currentGroup: state.notes.currentGroup
});

const mapDispatchToProps = {
  setNotesCurrentGroup
};

Notes.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  location: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
