import React, { Suspense, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { array, bool, func, object, oneOf } from 'prop-types';
import { createEditor, Transforms } from 'slate';
import { Slate, Editable, ReactEditor, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import classnames from 'classnames';
import { isKeyHotkey } from 'is-hotkey';

import { IMAGE_FILE_SIZE_FAILURE_MESSAGE, isImageFileSizeSupported } from '../../actions/images';
import { showMessage } from '../../actions/message';
import { getEmailTemplates, getTextTemplates, getTemplateById } from '../../actions/templates';
import { KEYCODE_MAP, UNICODE } from '../../constants';
import { SUPPORTED_TEMPLATE_OBJECT_TYPES, TEMPLATES_SUBTYPE } from '../../constants/templates';
import { Attachments } from './Attachments';
import { EDITOR_TEMPLATES, filterAndSortTemplates } from './editor-templates';
import { Toolbar } from './Toolbar/Toolbar';
import { Container } from '../Container';
import { Dialog, DialogHeader } from '../Dialog';
import { InsertLinkForm } from '../InsertLinkForm/InsertLinkForm';
import { Loading } from '../Loading';
import { Element } from './Nodes/Element';
import { Leaf } from './Nodes/Leaf';
import { EditorCommands } from './editor-commands';
import { withHtml, withImages, withLinks, withTemplates } from './editor-plugins';
import {
  reducer,
  SET_CONTACT,
  SET_CURRENT_MODE,
  SET_CURRENT_TEMPLATE,
  SET_EDITOR_METHODS,
  SET_RELATIONSHIP,
  SET_TEMPLATES,
  SET_TEMPLATE_MENU_INDEX
} from './editor-reducer';
import { DEFAULT_EDITOR_NODE } from './editor-utils';
import {
  getGoogleMapsUrlFromAddress,
  getObjValueFromStr,
  getSpouseObject,
  hasSecondaryPerson,
  interpolate
} from '../../utils/data';
import { checkIfHtml, fileToBase64 } from '../../utils/dom';
import { getParsedTemplateBody, isSupportedTemplateObjectType } from '../../utils/templates';
import { delay } from '../../utils/timer';

import styles from './Editor.css';
import { getAddressStr, getSpouseStr } from '../../utils/strings';
import { useMediaQueryContext } from '../MediaQueryProvider/MediaQueryProvider';

const { ZERO_WIDTH_SPACE } = UNICODE;

const HTML_HOTKEYS = {
  // MARKS
  'mod+b': { command: 'bold', type: 'mark' },
  'mod+i': { command: 'italic', type: 'mark' },
  'mod+u': { command: 'underline', type: 'mark' },
  'mod+shift+x': { command: 'strikethrough', type: 'mark' },
  // BLOCKS
  'mod+shift+9': { command: 'block-quote', type: 'block' }
};

const UNIVERSAL_HOTKEYS = {
  // HISTORY
  'mod+z': { command: 'undo', type: 'history' },
  'mod+shift+z': { command: 'redo', type: 'history' },
  // TEMPLATES
  'shift+{': { command: 'insert', type: 'template' } // Note: You can't use a variable in the key - is-hotkey doesn't support it.
};

const TEMPLATE_MENU_KEY_CODES = [KEYCODE_MAP.ENTER, KEYCODE_MAP.DOWN, KEYCODE_MAP.UP];
const SORTED_FE_TEMPLATES = filterAndSortTemplates(EDITOR_TEMPLATES);

const initialState = {
  contact: {},
  currentTemplate: null,
  templates: SORTED_FE_TEMPLATES, // We start by populating the system snippet templates stored in editor-templates.js
  templateCount: Object.keys(SORTED_FE_TEMPLATES).length,
  templateMenuIndex: 0,
  isLinkDialogOpen: false,
  linkDialogueDefaultText: '',
  linkDialogueDefaultUrl: '',
  relationship: null
};

export const TemplateMenuContext = React.createContext(initialState);

/**
 * React component for rendering the Slate Editor.
 * @param {Object} props Editor component props.
 * @param {Object} [props.attachments] Attachments.
 * @param {Boolean} [props.autoFocus] Should the editor be auto-focussed.
 * @param {Function} [props.changeSubject] A callback function to be called on template insert.
 * @param {Object} [props.contact] A contact's data.
 * @param {Array} [props.defaultBody] Content to pre-populate the editor with.
 * @param {'app'|'dialog'|'dialogTemplate'|'drawer'} [props.editorPortal] The portal where the editor is rendered.
 * @param {'default', 'preview', 'signature', 'template'} [props.mode] The mode that the editor is in.
 * @param {'email'|'texting'} [props.variant] The variant that the editor is in.
 * @param {Function} props.getEditorValue A callback function to send the editor content to the parent component.
 * @param {Function} [props.handleAttachmentRemoval] A callback function to handle attachment removal.
 * @param {Function} [props.handleBlur] A callback function to be called on blur of the editor.
 * @param {Function} [props.handleFileUpload] A callback function to handle file upload.
 * @param {Boolean} [props.isDisabled] A boolean to set the editor as disabled.
 * @param {Boolean} [props.isLoading] A boolean to set the editor as loading - often for async template.
 * @param {Boolean} [props.isValid] Whether the content of the editor is valid.
 * @param {Boolean} [props.spellCheck] A boolean to enable spellCheck.
 */

export const Editor = props => {
  const {
    attachments,
    autoFocus = false,
    changeSubject,
    contact: propContact,
    defaultBody,
    editorPortal,
    getEditorValue,
    handleAttachmentRemoval,
    handleBlur: propsBlurHandler,
    handleFileUpload,
    isDisabled = false,
    isLoading: propIsLoading = false,
    isValid = true,
    mode = 'default',
    spellCheck = true,
    variant = 'email',
    relationship: propRelationship,
    className
  } = props || {};

  const isEmailEditor = variant === 'email';

  const dispatch = useDispatch();

  const { isTabletPortraitAndUp } = useMediaQueryContext() || {};

  // Create the Slate editor object.
  const editor = useMemo(
    () =>
      isEmailEditor
        ? withHtml(withTemplates(withImages(withLinks(withHistory(withReact(createEditor()))))))
        : withTemplates(withHistory(withReact(createEditor()))),
    [isEmailEditor]
  );
  // Keep track of state for the value of the editor.
  const [value, setValue] = useState(defaultBody || [DEFAULT_EDITOR_NODE]);
  const [isLoading, setIsLoading] = useState(propIsLoading);
  const templates = useSelector(state => state.templates);
  const user = useSelector(state => state.user);
  const userInfo = useSelector(state => state.userProfile.userInfo);

  const [contact, setContact] = useState(propContact);
  const [relationship, setRelationship] = useState(propRelationship);
  const [isLinkDialogOpen, setLinkDialogOpen] = useState(false);
  const [linkDialogueDefaultText, setLinkDialogueDefaultText] = useState('');
  const [linkDialogueDefaultUrl, setLinkDialogueDefaultUrl] = useState('');
  const [state, dispatchReducer] = useReducer(reducer, {
    ...initialState,
    contact,
    relationship,
    editorPortal,
    mode
  });

  const getTemplateContent = async (template, contact, relationship) => {
    const { body: entityBody, fileId, key, objectType } = template;

    const isText = !isEmailEditor;
    const bodyObj = getParsedTemplateBody(entityBody);

    let { body, subject } = bodyObj;
    let content;

    const contactFormattedStrings = { address: getAddressStr(contact?.address, isText) };

    const agentFormattedStrings = {
      agentAddress: getAddressStr(userInfo?.agentAddress, isText),
      googleMapsLink: getGoogleMapsUrlFromAddress(userInfo?.agentAddress, isText)
    };

    const foundSpouse = getSpouseObject(contact, relationship);

    const relationshipFormattedStrings = getSpouseStr(foundSpouse);

    if (objectType === 'contact') {
      content = contact && key === 'address' ? contactFormattedStrings.address : getObjValueFromStr(contact, key);
    } else if (objectType === 'agent') {
      content = agentFormattedStrings[key] || getObjValueFromStr(userInfo, key);
    } else if (objectType === 'relationship') {
      //rely primarily on the contact object for spouse data, else go with relationship object
      //this was done to support a common UI subheading of 'relationship' for all spouse use cases
      if (key.startsWith('secondaryPerson')) {
        content = getObjValueFromStr(contact, key);
      } else if (relationship) {
        const keyFragments = key.split('.');
        const parsedKey = keyFragments[0];
        const foundRelationship = relationship?.find(item => item.relationshipTypeName.toLowerCase() === parsedKey);

        const valueKey = keyFragments.slice(-1)[0];

        content = getObjValueFromStr(foundRelationship, valueKey);
      }
    } else if (isSupportedTemplateObjectType(objectType)) {
      if (!body) {
        setIsLoading(true);
        const data = await dispatch(getTemplateById(fileId, { objectType }))
          .then(data => {
            return data;
          })
          .finally(() => {
            setIsLoading(false);
          });
        const bodyObj = getParsedTemplateBody(data.body);
        body = bodyObj.body;
        subject = bodyObj.subject;
      }

      content = interpolate(
        body,
        { ...contact },
        { ...user, ...userInfo },
        { ...relationship },
        contactFormattedStrings,
        agentFormattedStrings,
        relationshipFormattedStrings,
        isText
      );
    }

    return [content || '', subject]; // When we don't have a data match from the template, we want to ensure there is an empty string to insert.
  };

  const insertTemplateContent = async (template, contact, relationship) => {
    // If the editor isn't focused and selection is null (as when using the mouse to click a template menu suggestion),
    // we need to set the selection in the editor to the last savedSelection.
    if (!editor.selection) {
      Transforms.select(editor, editor.savedSelection);
    }

    const [, path] = EditorCommands.getMatchingNode(editor, 'template');
    Transforms.removeNodes(editor, { at: path });

    const content = template ? await getTemplateContent(template, contact, relationship) : [];
    const [body, subject] = content;

    if (template && body === '') {
      dispatch(
        showMessage(
          {
            message: `There is no data for the ${
              template.objectType || template.type
            } merge code you are trying to use.`,
            type: 'error'
          },
          true
        )
      );
    }

    /*
      IMPORTANT TEST SCENARIOS

      If the editor is blank:
        1- When the template mark is first in the doc.
        2- When the template mark is last in a line of text.
        3- When the template mark is in the middle of a line of text.

      If the editor has a signature at the end of the document:
        1- When the template mark is first in the doc.
        2- When the template mark is last in a line of text.
        3- When the template mark is in the middle of a line of text.

    */

    const isHtml = isEmailEditor && checkIfHtml(body);

    // Finally we make sure there is focus to the editor, and we insert the template.
    ReactEditor.focus(editor);
    if (isHtml) {
      editor.insertData(body);
    } else {
      // if it is a link or text, we do a straight insert so the editors plugins will wrap the link node around the text.
      editor.insertText(body);
    }

    if (changeSubject && isSupportedTemplateObjectType(template?.objectType)) {
      changeSubject(subject);
    }
  };

  const insertRawTemplateContent = async template => {
    const { file, fileId, objectType } = template;
    let { body } = file || {};

    if (!body) {
      setIsLoading(false);
      const data = await dispatch(getTemplateById(fileId, { objectType }))
        .then(data => {
          return data;
        })
        .finally(() => {
          setIsLoading(false);
        });
      body = data.body;
    }

    EditorCommands.insertRawTemplate(editor, body);
  };

  const insertMergeCode = template => {
    const { key, objectType } = template;
    const mergeCode = `${objectType}.${key}`;

    // 1. We make sure there is focus to the editor, and we insert the template.
    ReactEditor.focus(editor);

    // 2. We select and delete the current template node.
    const [, path] = EditorCommands.getMatchingNode(editor, 'template');
    Transforms.delete(editor, { at: path });

    if (SUPPORTED_TEMPLATE_OBJECT_TYPES.includes(template.objectType)) {
      insertRawTemplateContent(template);
      return;
    }

    // 3. We create and insert a new template node with the merge code text as the child.
    const templateNode = { type: 'template', children: [{ text: mergeCode }] };
    Transforms.insertNodes(editor, templateNode, { select: false, split: true });
    // 4. Hack - We toggle the template command off by inserting empty text to allow the cursor to sit outside the merge code.
    editor.insertText('');
  };

  const handleTemplateChange = (template, contact, relationship, mode) => {
    // If the editor is being used to create a template, we want to insert the merge code instead of the template's content.
    if (mode === 'template') {
      insertMergeCode(template);
      return;
    }

    insertTemplateContent(template, contact, relationship);
  };

  const updateCurrentTemplate = currentTemplate => {
    dispatchReducer({
      type: SET_CURRENT_TEMPLATE,
      value: currentTemplate
    });
  };

  const updateTemplateMenuIndex = (templateMenuIndex, templateCount) => {
    dispatchReducer({
      type: SET_TEMPLATE_MENU_INDEX,
      value: { templateMenuIndex, templateCount }
    });
  };

  const toggleLinkDialog = () => {
    const command = 'link';
    let text = '';
    let url = '';

    if (!isLinkDialogOpen) {
      const isLinkActive = EditorCommands.isBlockActive(editor, command);

      if (isLinkActive) {
        // If there is an active link, we find the link node.
        const [node] = EditorCommands.getMatchingNode(editor, command);
        // Once we have the text and url from the link node, we set the values in preparation for a state update.
        url = node.url;
        text = node.children[0].text;
      } else {
        // If there is NOT an active link, we use the selection to get the text fragment.
        text = EditorCommands.getSelectionText(editor);
      }
    }

    // The default text and url values come from local state, and once we set the open state, it will show in the insert link form.
    setLinkDialogOpen(!isLinkDialogOpen);
    setLinkDialogueDefaultText(text);
    setLinkDialogueDefaultUrl(url);
  };

  const handleEditorChange = newValue => {
    if (!newValue) {
      return;
    }

    setValue(newValue);

    if (getEditorValue) {
      getEditorValue({ children: newValue }); // We set our own object instead of passing the whole editor obj.
    }
  };

  useEffect(() => {
    setContact(propContact);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propContact]);

  useEffect(() => {
    setRelationship(propRelationship);
  }, [propRelationship, setRelationship]);

  useEffect(() => {
    dispatch(isEmailEditor ? getEmailTemplates() : getTextTemplates());
    dispatchReducer({
      type: SET_EDITOR_METHODS,
      value: {
        handleTemplateChange,
        toggleLinkDialog,
        updateCurrentTemplate,
        updateTemplateMenuIndex
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subType = TEMPLATES_SUBTYPE[variant].value;
    const personalTemplateGroup = templates.groups[`1::${subType}::0`] || [];
    const systemTemplateGroup = templates.groups[`99::${subType}::0`] || [];
    const templateEntities = [...systemTemplateGroup, ...personalTemplateGroup].reduce((acc, id) => {
      acc[id] = templates.entities[id];
      return acc;
    }, {});

    const hasSecondaryName = mode !== 'template' && hasSecondaryPerson(contact);

    EDITOR_TEMPLATES.spouseFirstName.hidden = hasSecondaryName;
    EDITOR_TEMPLATES.spouseLastName.hidden = hasSecondaryName;
    EDITOR_TEMPLATES.spouseFullName.hidden = hasSecondaryName;
    EDITOR_TEMPLATES.secondaryFirstName.hidden = !hasSecondaryName;
    EDITOR_TEMPLATES.secondaryLastName.hidden = !hasSecondaryName;
    EDITOR_TEMPLATES.secondaryFullName.hidden = !hasSecondaryName;

    const newTemplates = { ...EDITOR_TEMPLATES, ...templateEntities };
    const newSortedTemplates = filterAndSortTemplates(newTemplates);

    dispatchReducer({
      type: SET_TEMPLATES,
      value: newSortedTemplates
    });
  }, [templates, variant, contact, mode]);

  useEffect(() => {
    Transforms.deselect(editor);
    editor.savedSelection = null;

    const newBody = defaultBody || [DEFAULT_EDITOR_NODE];
    handleEditorChange(newBody);
    // Since version 0.67.0 of slate-react, updating the value prop of the Slate component does not update the editor.
    // The value prop is only used on initial render, and can't be used with async fetched template bodies.
    // To update the editor, we need to subsequently set editor.children to the new template body.
    editor.children = newBody;

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBody]);

  useEffect(() => {
    dispatchReducer({
      type: SET_CONTACT,
      value: propContact
    });
  }, [propContact]);

  useEffect(() => {
    dispatchReducer({
      type: SET_RELATIONSHIP,
      value: propRelationship
    });
  }, [propRelationship]);

  useEffect(() => {
    dispatchReducer({
      type: SET_CURRENT_MODE,
      value: mode
    });
  }, [mode]);

  const focusEditor = async () => {
    // A 0 ms delay to ensure we can focus the field.
    await delay();
    ReactEditor.focus(editor);
  };

  useEffect(() => {
    if (autoFocus) {
      focusEditor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus]);

  useEffect(() => {
    setIsLoading(propIsLoading);
  }, [propIsLoading]);

  const renderElement = useCallback(props => {
    return <Element {...props} />;
  }, []);

  const renderLeaf = useCallback(props => {
    return <Leaf {...props} />;
  }, []);

  const handleImageChange = async e => {
    e.persist();
    const supportsAttachments = mode === 'default'; // Email editor is in default mode by default. signature and template modes do not support attachments.

    const { target } = e;
    const { files } = target;
    const file = files[0];
    const { type } = file;
    const [mime] = type.split('/');

    // Restore the previous selection when coming from the file selection dialogue.
    Transforms.select(editor, editor.savedSelection);
    // We check to see if the selection is collapsed (ie nothing is selected).
    const isCollapsed = EditorCommands.isCollapsed(editor);

    if (!isCollapsed) {
      // If the selection is expanded (has something visibly selected), we collapse the selection and place the cursor at the end.
      // We don't want to lose data by inserting the image, so we make sure the selection is collapsed (ie a single point in the editor).
      EditorCommands.deselectAndPlaceCursorAfter(editor);
    }

    if (mime !== 'image') {
      const message = supportsAttachments
        ? 'You can only insert images into the email body. Use the attach file button to add an attachment of another file type.'
        : `You can only insert images into your ${mode}.`;

      dispatch(
        showMessage(
          {
            message,
            type: 'error'
          },
          false
        )
      );

      ReactEditor.focus(editor);
      return;
    }

    if (!isImageFileSizeSupported(file)) {
      ReactEditor.focus(editor);
      dispatch(showMessage({ message: IMAGE_FILE_SIZE_FAILURE_MESSAGE, type: 'error' }, false));
      return;
    }

    // We first place a Base64 version into the editor for performance reasons (we don't want to wait for upload).
    const fileAsBase64 = await fileToBase64(file);
    EditorCommands.insertImage(editor, fileAsBase64); // insertImage selects the image in the editor by default.
    const [, path] = EditorCommands.getMatchingNode(editor, 'image');

    EditorCommands.giveFocusAndMoveCursorForward(editor);

    // Next we upload the image.
    const imageAttachment = await handleFileUpload(e, 'inline');
    // Then we set the data from the upload on the image node.

    const imageData = supportsAttachments
      ? { url: fileAsBase64, cid: imageAttachment.id }
      : { url: imageAttachment.url };
    Transforms.setNodes(editor, imageData, { at: path });
  };

  const insertLinkChange = data => {
    const { link, text } = data;

    EditorCommands.insertLink(editor, link, text);

    toggleLinkDialog();

    const isImageBeingLinked = EditorCommands.isBlockActive(editor, 'image');

    if (isImageBeingLinked) {
      // When an image is linked, we move the cursor off the image and place it after.
      EditorCommands.giveFocusAndMoveCursorForward(editor);
    }
  };

  const handleBlur = e => {
    // On blur of the editor we want to save the current selection so it can be restored if necessary.

    const isTemplateNode = EditorCommands.isBlockActive(editor, 'template');
    const isCollapsed = EditorCommands.isCollapsed(editor);

    if (isTemplateNode && isCollapsed) {
      // We need to make sure that the template mark has content, empty nodes can fail and error out.
      // is template node and nothing is selected.
      editor.insertText(ZERO_WIDTH_SPACE);
    }
    editor.savedSelection = editor.selection;

    if (propsBlurHandler) {
      propsBlurHandler(e);
    }
  };

  const handleKeyDown = e => {
    // We only want to stopPropagation and not preventDefault. preventDefault happens at the end of this method and
    // only for key downs that match.
    // We stopPropagation so that arrow keys and other listeners from parent components like Contact Details don't fire.
    e.stopPropagation();
    const { which: keycode } = e;
    const isTemplateNode = EditorCommands.isBlockActive(editor, 'template');

    if (keycode === KEYCODE_MAP.BACKSPACE) {
      // When pressing backspace, the editor sometimes needs to do some clean-up of marks/blocks.
      // For instance, removing the template mark when it is the first thing on a line.
      EditorCommands.keyBackspace(editor);
      return;
    } else if (isTemplateNode && TEMPLATE_MENU_KEY_CODES.includes(keycode)) {
      // IS TEMPLATE && (ENTER || DOWN || UP)
      // Note: this if needs to appear above other ENTER, UP, DOWN checks.
      if (keycode === KEYCODE_MAP.ENTER) {
        handleTemplateChange(state.templates[state.currentTemplate], state.contact, state.relationship, state.mode);
        //return false; // Slate.js needs this when detecting enter
      } else if (keycode === KEYCODE_MAP.DOWN) {
        const isTemplateIndexAtEnd = state.templateMenuIndex === state.templateCount - 1;

        if (!isTemplateIndexAtEnd) {
          updateTemplateMenuIndex(state.templateMenuIndex + 1);
        }
      } else if (keycode === KEYCODE_MAP.UP) {
        const isTemplateIndexAtStart = state.templateMenuIndex === 0;

        if (!isTemplateIndexAtStart) {
          updateTemplateMenuIndex(state.templateMenuIndex - 1);
        }
      }

      e.preventDefault();
      return;
    } else if (keycode === KEYCODE_MAP.ENTER) {
      EditorCommands.keyEnter(editor);
      return;
    }

    const HOTKEYS = isEmailEditor ? { ...HTML_HOTKEYS, ...UNIVERSAL_HOTKEYS } : { ...UNIVERSAL_HOTKEYS };

    for (const hotkey in HOTKEYS) {
      if (isKeyHotkey(hotkey, e)) {
        e.preventDefault();

        const shortcut = HOTKEYS[hotkey];
        const { command, type } = shortcut;

        if (type === 'mark') {
          EditorCommands.toggleMark(editor, command);
        } else if (type === 'history') {
          EditorCommands.history(editor, command);
        } else if (type === 'template') {
          EditorCommands.insertTemplateCheck(editor, command);
        } else {
          // type === 'block'
          EditorCommands.toggleBlock(editor, command);
        }
      }
    }
  };

  const editorClasses = classnames({
    [styles.editor]: true,
    [styles[`editor--${variant}`]]: true,
    [styles[`editor--${mode}`]]: true,
    [styles.editorDisabled]: isDisabled && mode !== 'preview',
    [styles.editorInvalid]: !isValid
  });

  const containerClasses = classnames({
    [styles.container]: !className,
    [className]: className
  });
  const showToolbar = mode !== 'preview' && isTabletPortraitAndUp;

  return (
    <Container className={containerClasses}>
      <TemplateMenuContext.Provider value={state}>
        <Slate editor={editor} value={value} onChange={newValue => handleEditorChange(newValue)}>
          <Editable
            id={`${editorPortal}Editor`}
            className={editorClasses}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            readOnly={isDisabled || isLoading}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck={spellCheck}
          />
          {handleAttachmentRemoval && (
            <Attachments
              attachments={attachments}
              disabled={isDisabled || isLoading}
              handleAttachmentRemoval={handleAttachmentRemoval}
            />
          )}
          {showToolbar && (
            <Toolbar
              disabled={isDisabled || isLoading}
              handleImageChange={handleImageChange}
              variant={variant}
              toggleLinkDialog={toggleLinkDialog}
              setValue={setValue}
              setIsLoading={setIsLoading}
              mode={mode}
            />
          )}
          <Dialog isOpen={isLinkDialogOpen}>
            <DialogHeader title="Insert Link" icon="link" clearHandler={toggleLinkDialog} />
            <Suspense>
              <InsertLinkForm
                defaultLinkText={linkDialogueDefaultText}
                defaultLinkUrl={linkDialogueDefaultUrl}
                insertLinkChange={insertLinkChange}
              />
            </Suspense>
          </Dialog>
        </Slate>
      </TemplateMenuContext.Provider>
      {isLoading && (
        <div className={styles.loadingOverlay}>
          <Loading loading={isLoading} className={styles.loading} />
        </div>
      )}
    </Container>
  );
};

Editor.propTypes = {
  attachments: object,
  autoFocus: bool,
  changeSubject: func,
  contact: object,
  defaultBody: array,
  editorPortal: oneOf(['app', 'dialog', 'dialogTemplate', 'drawer']),
  getEditorValue: func,
  handleAttachmentRemoval: func,
  handleBlur: func,
  handleFileUpload: func,
  isDisabled: bool,
  isLoading: bool,
  isValid: bool,
  mode: oneOf(['default', 'preview', 'signature', 'template']),
  variant: oneOf(['email', 'texting']),
  spellCheck: bool
};
