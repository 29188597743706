export const getInitialState = (entity, agentAddress, contact) => {
  const {
    listingDate,
    address,
    county,
    mlsNumber,
    mlsSourceId: existingMlsSourceId,
    listPrice,
    beds,
    baths,
    sqft,
    year_built,
    representationTypeId,
    commissionRateType,
    stopReminder,
    signPlaced,
    expirationDate,
    reminderDate,
    inspectionDate,
    offerDate,
    offerExpirationDate,
    acceptanceDate,
    contractAgreementDate,
    closingDate,
    financialCommitmentDate,
    possessionDate,
    salePrice,
    lockboxNumber,
    howToShow,
    terms,
    fileNumber,
    commissionFirstAmount,
    commissionFirstRate,
    commissionFlatAmount,
    commissionRemainderRate,
    taxRate,
    brokerSplitRate,
    buyerPrimaryPropertyOption,
    listing,
    propertyType,
    source,
    referralAmount,
    referralAmountType
  } = entity || {};

  const { country: agentCountry = 'US' } = agentAddress || {};
  const { contactId, contactRole, contactSource } = contact;

  // listings will be null for all manually input transaction
  const {
    location,
    list_price: listingListPrice,
    description,
    property_id: rdcPropertyId,
    listing_id: rdcListingId,
    source: listingSource
  } = listing || {};
  const { contact_date: contractDate } = listingSource || {};

  const { address: listingAddress, county: listingCounty } = location || {};

  const {
    unit: listingUnit,
    street_number: listingHouseNumber,
    street_name: listingStreetName,
    street_suffix: listingStreetType,
    country: listingCountry,
    state_code: listingStataCode,
    city: listingCity,
    postal_code: listingPostalCode
  } = listingAddress || {};

  const {
    type: listingPropertyType,
    beds: listingBeds,
    baths: listingBaths,
    sqft: listingSqft,
    year_built: listingYearBuilt
  } = description || {};
  const { mlsNumber: listingsMlsNumber, id: mlsSourceId } = source || {};
  const {
    unit: manualUnit,
    streetNumber: manualStreetNumber,
    houseNumber,
    streetName: manualStreetName,
    streetSuffix: manualStreetSuffix,
    streetType,
    country: manualCountry,
    state: manualStateCode,
    city: manualCity,
    postalCode: manualPostalCode,
    zip: manualZip,
    county: manualCounty
  } = address || {};
  // Required fields for manual add
  // address (unit, street_number, street_name, street_suffix, country, state_code, city, postal_code
  // mls number, listing date, listing price, beds, baths, sqft, year built

  const initialState = {
    isFormSubmitting: false,
    isFormValid: true,
    fields: {
      address: {
        unit: {
          value: manualUnit || listingUnit || '',
          isValid: true,
          isRequired: false
        },
        streetNumber: {
          // streetNumber got renamed to houseNumber after submit
          value: manualStreetNumber || houseNumber || listingHouseNumber || '',
          isValid: true,
          isRequired: false
        },
        streetName: {
          value: manualStreetName || listingStreetName || '',
          isValid: true,
          isRequired: true
        },
        streetSuffix: {
          // streetSuffix got renamed to streetType after submit
          value: manualStreetSuffix || streetType || listingStreetType || '',
          isValid: true,
          isRequired: false
        },
        country: {
          value: manualCountry || listingCountry || agentCountry || 'US',
          isValid: true,
          isRequired: true
        },
        state: {
          value: manualStateCode || listingStataCode || '',
          isValid: true,
          isRequired: true
        },
        city: {
          value: manualCity || listingCity || '',
          isValid: true,
          isRequired: true
        },
        postalCode: {
          value: manualZip || manualPostalCode || listingPostalCode || '',
          isValid: true,
          isRequired: true
        },
        county: {
          value: manualCounty || county?.name || listingCounty?.name || '',
          isValid: true,
          isRequired: false
        }
      },
      mlsNumber: {
        value: mlsNumber || listingsMlsNumber || '',
        isValid: true,
        isRequired: false
      },
      listingDate: {
        value: contractDate || listingDate || '',
        isValid: true,
        isRequired: true
      },
      listPrice: {
        value: listPrice || listingListPrice || '',
        isValid: true,
        isRequired: true
      },
      beds: {
        value: beds || listingBeds || '',
        isValid: true,
        isRequired: false
      },
      baths: {
        value: baths || listingBaths || '',
        isValid: true,
        isRequired: false
      },
      sqft: {
        value: sqft || listingSqft || '',
        isValid: true,
        isRequired: false
      },
      yearBuilt: {
        value: year_built || listingYearBuilt || '',
        isValid: true,
        isRequired: false
      },
      representationTypeId: {
        value:
          representationTypeId == null
            ? contactRole?.toLowerCase()?.includes('seller')
              ? '2'
              : '1'
            : representationTypeId.toString(),
        isValid: true,
        isRequired: true
      },
      commissionRateType: {
        value: commissionRateType == null ? '1' : commissionRateType.toString(),
        isValid: true,
        isRequired: true
      },
      stopReminder: {
        value: stopReminder || false,
        isValid: true,
        isRequired: false
      },
      signPlaced: {
        value: signPlaced || false,
        isValid: true,
        isRequired: false
      },
      expirationDate: {
        value: expirationDate || '',
        isValid: true,
        isRequired: false
      },
      reminderDate: {
        value: reminderDate || '',
        isValid: true,
        isRequired: false
      },
      inspectionDate: {
        value: inspectionDate || '',
        isValid: true,
        isRequired: false
      },
      offerDate: {
        value: offerDate || '',
        isValid: true,
        isRequired: false
      },
      offerExpirationDate: {
        value: offerExpirationDate || '',
        isValid: true,
        isRequired: false
      },
      acceptanceDate: {
        value: acceptanceDate || '',
        isValid: true,
        isRequired: false
      },
      contractAgreementDate: {
        value: contractAgreementDate || '',
        isValid: true,
        isRequired: false
      },
      closingDate: {
        value: closingDate || '',
        isValid: true,
        isRequired: false
      },
      financialCommitmentDate: {
        value: financialCommitmentDate || '',
        isValid: true,
        isRequired: false
      },
      possessionDate: {
        value: possessionDate || '',
        isValid: true,
        isRequired: false
      },
      salePrice: {
        value: salePrice || '',
        isValid: true,
        isRequired: false
      },
      lockboxNumber: {
        value: lockboxNumber || '',
        isValid: true,
        isRequired: false
      },
      howToShow: {
        value: howToShow || '',
        isValid: true,
        isRequired: false
      },
      terms: {
        value: terms || '',
        isValid: true,
        isRequired: false
      },
      fileNumber: {
        value: fileNumber || '',
        isValid: true,
        isRequired: false
      },
      commissionFirstAmount: {
        value: commissionFirstAmount || '',
        isValid: true,
        isRequired: false
      },
      commissionFirstRate: {
        value: commissionFirstRate || '',
        isValid: true,
        isRequired: false
      },
      commissionFlatAmount: {
        value: commissionFlatAmount % 1 ? commissionFlatAmount.toFixed(2) : commissionFlatAmount || '',
        isValid: true,
        isRequired: false
      },
      commissionRemainderRate: {
        value: commissionRemainderRate || '',
        isValid: true,
        isRequired: false
      },
      taxRate: {
        value: taxRate || '',
        isValid: true,
        isRequired: false
      },
      brokerSplitRate: {
        value: brokerSplitRate || '',
        isValid: true,
        isRequired: false
      },
      buyerPrimaryPropertyOption: {
        value: buyerPrimaryPropertyOption || '',
        isValid: true,
        isRequired: false
      },
      propertyType: {
        value: propertyType || listingPropertyType || '',
        isValid: true,
        isRequired: true
      },
      rdcPropertyId: {
        value: rdcPropertyId || '',
        isValid: true,
        isRequired: false
      },
      rdcListingId: {
        value: rdcListingId || '',
        isValid: true,
        isRequired: false
      },
      contactId: {
        value: contactId || '',
        isValid: true,
        isRequired: false
      },
      contactRole: {
        value: contactRole || '',
        isValid: true,
        isRequired: !!contactId
      },
      source: {
        value: source || contactSource || '',
        hasCustomLeadSource: false,
        custom: '',
        customIsValid: false,
        isValid: true,
        isRequired: false
      },
      referralAmount: {
        value:
          referralAmount % 1 && referralAmountType === 1
            ? referralAmount.toFixed(2).toString()
            : referralAmount?.toString() || '',
        isValid: true,
        isRequired: false
      },
      referralAmountType: {
        value: referralAmountType?.toString() || '2', // Default: flat %
        isValid: true,
        isRequired: false
      },
      mlsSourceId: {
        value: existingMlsSourceId || mlsSourceId || '',
        isValid: true
      }
    },
    showAddContactDialog: false,
    tempContact: null
  };
  return initialState;
};
