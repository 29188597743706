import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func, oneOf, string } from 'prop-types';

import { Button, Icon } from '../';
import { ButtonGroup } from '../Button';
import { useMediaQueryContext } from '../MediaQueryProvider/MediaQueryProvider';
import { minimizeDrawer } from '../../actions/drawer';

import styles from './DialogHeader.css';

export const DialogHeader = props => {
  const {
    clearHandler,
    icon,
    iconSize = 'm',
    testId = 'DialogHeader',
    title,
    variant = 'modal',
    isColored = false
  } = props;
  const drawer = useSelector(state => state.drawer);
  const { isTabletPortraitAndUp } = useMediaQueryContext() || {};
  const { isMinimized } = drawer || {};

  const isDrawer = variant === 'drawer';

  const dispatch = useDispatch();

  const minimizeHandler = e => {
    e.target.blur(); // really just to lose focus.
    dispatch(minimizeDrawer());
  };

  const displayMinMax = isMinimized ? 'Maximize' : 'Minimize';
  const iconButtonSize = isTabletPortraitAndUp ? 'xs' : 's';

  return (
    <header className={styles.header}>
      <div className={styles.headerTitle}>
        <Icon name={icon} size={iconSize} isColored={isColored} />
        <h1 className={styles.h1}>{title}</h1>
      </div>
      <ButtonGroup>
        {isDrawer && (
          <Button
            data-testid={`${testId}-button-minimize`}
            ariaLabel={displayMinMax}
            title={displayMinMax}
            icon={displayMinMax.toLowerCase()}
            size={iconButtonSize}
            onClick={e => minimizeHandler(e)}
            tooltipPos="left"
          />
        )}
        <Button
          data-testid={`${testId}-button-close`}
          ariaLabel="Close"
          title="Close"
          icon="close"
          size={iconButtonSize}
          onClick={clearHandler}
          tooltipPos="left"
        />
      </ButtonGroup>
    </header>
  );
};

DialogHeader.propTypes = {
  clearHandler: func.isRequired,
  icon: string,
  iconSize: oneOf(['xxxs', 'xxs', 'xs', 's', 'm', 'l', 'xl']),
  testId: string,
  title: string.isRequired,
  variant: oneOf(['modal', 'drawer'])
};
