import React from 'react';
import { FormRow, FormRadio, FormRadioGroup, FormLabel } from '../../components';
import formFieldStyles from '../FormField/FormField.css';
import { useDispatch } from 'react-redux';
import { handleAddressOrMLSChange } from '../../utils/mlsBoards';

const PropertyTypeForm = props => {
  const { formIsSubmitting, inputType, setInputType, showNoTempListingsError, lookupIsValid, setLookupIsValid } = props;
  const dispatch = useDispatch();
  const handleTypeChange = (e, type) => {
    handleAddressOrMLSChange(e, dispatch, showNoTempListingsError, lookupIsValid);
    setInputType(type);
    setLookupIsValid(true);
  };

  return (
    <FormRow>
      <div className={formFieldStyles.field}>
        <FormLabel htmlFor="tabSearchAddress">Search for property by</FormLabel>

        <FormRadioGroup name="tabType">
          <FormRadio
            id="tabSearchAddress"
            label="Address"
            value="address"
            onChange={e => handleTypeChange(e, 'address')}
            checked={inputType === 'address'}
            disabled={formIsSubmitting}
          />
          <FormRadio
            id="tabSearchMls"
            label="MLS ID"
            value="mls"
            onChange={e => handleTypeChange(e, 'mls')}
            checked={inputType === 'mls'}
            disabled={formIsSubmitting}
          />
        </FormRadioGroup>
      </div>
    </FormRow>
  );
};

export default PropertyTypeForm;
