import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { array, bool, func, string } from 'prop-types';
import { useLocation } from '@reach/router';
import { navigate } from '@reach/router';
import { clearAlert, getContactDetail, getTaskDetail, showAlert } from '../../actions';
import { getTemplateById, saveTemplate } from '../../actions/templates';
import { showMessage } from '../../actions/message';
import { UNICODE } from '../../constants';
import { parseParamsStr, serializeToParamsStr } from '../../utils/strings';
import { checkEmailIntegrationSetup, interpolate, TASK_TYPES } from '../../utils';
import { getParsedTemplateBody } from '../../utils/templates';
import { emailSanitizer } from '../../utils/email';
import { isFieldFilled } from '../../utils/validation';
import { getDisplayTaskPreviewIcon, getDisplayTaskType } from '../../utils/tasks';
import { Dialog, DialogHeader } from '../Dialog';
import { Button, ButtonGroup } from '../Button';
import { FormRow } from '../FormRow';
import { FormLabel } from '../FormLabel';
import { FormTextInput } from '../FormTextInput';
import { FormFieldReadOnly } from '../FormFieldReadOnly';
import { Loading } from '../Loading';
import { Editor } from '../Editor/Editor';
import { cleanAndDeserializeForSlate, serialize, serializeForTexting } from '../Editor/editor-utils';
import styles from './PreviewEmailTaskDialog.css';
import { interpolateAddressData } from '../../utils/data';

export const PreviewTaskDialog = props => {
  const {
    taskId,
    disabled,
    fileId,
    contactId: propsContactId,
    isOpen,
    handleSubmit,
    handlePreview,
    handleTemplateChange,
    showSaveTemplate = false, // should Show Save and Close Preview button
    isEmailTask
  } = props || {};

  const location = useLocation();
  const { mode, taskId: mountedTaskId, contactId: mountedContactId } = parseParamsStr(location.search) || {};

  const id = mountedTaskId || taskId;
  const isNested = isOpen == null;

  const userInfo = useSelector(store => store.userProfile.userInfo);
  const user = useSelector(store => store.user);
  const contacts = useSelector(store => store.contacts.entities);

  const templateEntities = useSelector(store => store.templates.entities);
  const tasksEntities = useSelector(store => store.tasks.entities);
  const { groups: relationshipsGroups, entities: relationshipsEntities } = useSelector(store => store.relationships);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const [subjectIsValid, setSubjectIsValid] = useState(true);
  const [defaultBody, setDefaultBody] = useState(null);
  const [editor, setEditor] = useState(null);
  const [isCustomizeMode, setIsCustomizeMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // When the customized template is saved locally in TaskForm
  const [isSavedTemplate, setIsSavedTemplate] = useState(false);
  const [currentFileId, setCurrentFileId] = useState(null);
  const [isEmail, setIsEmail] = useState(true);

  const agentEmailAccounts = useSelector(store => store.settings.email);
  const hasEmailIntegrationSetup = checkEmailIntegrationSetup(agentEmailAccounts);

  const dispatch = useDispatch();

  const realFileId = fileId || tasksEntities?.[id]?.taskTemplate?.templateFileId;
  const contactId =
    mountedContactId || propsContactId || tasksEntities?.[id]?.contactId || tasksEntities?.[id]?.contacts?.[0]?.id;
  // We get type directly from existing task data or from isEmailTask when creating new task
  const type = tasksEntities?.[id]?.type || (isEmailTask ? TASK_TYPES.tpxEmail.value : TASK_TYPES.texting.value);

  const existingTemplate = templateEntities?.[realFileId];
  const isDeleted = existingTemplate?.isDeleted === 1;

  const typeLabel = type && getDisplayTaskType(type);
  const typePreviewIcon = type && getDisplayTaskPreviewIcon(type);

  const relationship = relationshipsGroups?.[contactId]?.map(id => relationshipsEntities?.[id]);

  const updateDefaultBody = useCallback(
    (htmlStr, shouldBeInterpolated) => {
      if (htmlStr && contactId) {
        dispatch(getContactDetail({ id: contactId, forceRefresh: true })).then(data => {
          const contactData = data || contacts[contactId];
          const { contactFormatted, agentFormatted, relationshipFormatted } = interpolateAddressData(
            contactData,
            userInfo,
            relationship
          );
          const content = interpolate(
            htmlStr,
            { ...contactData },
            { ...user, ...userInfo },
            relationship,
            contactFormatted,
            agentFormatted,
            relationshipFormatted
          );

          const newBody = shouldBeInterpolated
            ? cleanAndDeserializeForSlate(content)
            : cleanAndDeserializeForSlate(htmlStr);

          setDefaultBody(newBody);

          setEditor(newBody);
        });
        return;
      }

      setDefaultBody(null);
      setEditor(null);
    },
    [contactId, dispatch, contacts, user, userInfo, relationship]
  );

  const clearHandler = useCallback(() => {
    setDefaultBody(null);
    setEditor(null);
    setSubject('');
    setIsCustomizeMode(false);
    setIsSavedTemplate(false);
    setCurrentFileId(null);
    setIsDialogOpen(false);

    handlePreview();

    if (isNested) {
      const currentParams = parseParamsStr(location.search) || {};
      const { mode, contactId, taskId, ...newParams } = currentParams;

      const newParamsStr = serializeToParamsStr(newParams);
      const newPath = newParamsStr.length > 0 ? `${location.pathname}?${newParamsStr}` : location.pathname;
      navigate(newPath);
    }
  }, [handlePreview, isNested, location.pathname, location.search]);

  useEffect(() => {
    const isPreview = mode === 'preview';
    if (isDeleted && (isPreview || isOpen)) {
      dispatch(
        showMessage({ message: `This template has been deleted so it cannot be previewed.`, type: 'error' }, true)
      );
      clearHandler();
      return;
    }

    setIsDialogOpen(Boolean(isOpen) || isPreview);
  }, [isOpen, mode, setIsDialogOpen, isDeleted, dispatch, clearHandler]);

  useEffect(() => {
    setIsEmail(Boolean(type !== TASK_TYPES.texting.value || isEmailTask));
  }, [isEmailTask, type]);

  useEffect(() => {
    if (isDialogOpen && id) {
      setIsLoading(true);
      dispatch(getTaskDetail({ id, shouldBeCached: false })).finally(() => {
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen, setIsLoading]);

  useEffect(() => {
    if (isDialogOpen) {
      // Update deafult body to saved customized template only when fileId is the same
      if (editor && isSavedTemplate && currentFileId !== null && currentFileId === fileId) {
        const bodyValue = Array.isArray(editor) ? editor[0] : editor;
        const templateBody = isEmail ? emailSanitizer(serialize(bodyValue)) : serializeForTexting(bodyValue).trim();
        updateDefaultBody(templateBody, true);
        return;
      }

      if (realFileId) {
        setCurrentFileId(realFileId);
        setIsSavedTemplate(false);

        const templateBodyObj = getParsedTemplateBody(templateEntities[realFileId]?.body);
        const { body, subject } = templateBodyObj;
        if (body) {
          updateDefaultBody(body, !isCustomizeMode);
          setSubject(subject);
        } else {
          setIsLoading(true);
          dispatch(getTemplateById(realFileId, { objectType: 1 }))
            .then(data => {
              const { body: entityBody } = data || {};
              const { body } = getParsedTemplateBody(entityBody);
              updateDefaultBody(body, !isCustomizeMode);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tasksEntities,
    isDialogOpen,
    dispatch,
    fileId,
    templateEntities,
    isCustomizeMode,
    isSavedTemplate,
    currentFileId,
    setCurrentFileId,
    isEmail
  ]);

  const handleSaveCustomizeTemplate = (e, shouldSendNow = false) => {
    const { ZERO_WIDTH_SPACE } = UNICODE;
    const CUSTOMIZED_TEMPLATE_OBJECT_TYPE = 3;
    const bodyValue = Array.isArray(editor) ? editor[0] : editor;
    const templateBody = isEmail ? emailSanitizer(serialize(bodyValue)) : serializeForTexting(bodyValue).trim();
    const isExistingTemplateCustomized = existingTemplate?.objectType === CUSTOMIZED_TEMPLATE_OBJECT_TYPE;
    const options = {
      // There's a zero width space from sanitizer when adding merge code from preview
      body: templateBody.replace(ZERO_WIDTH_SPACE, ''),
      category: null,
      description: isEmail ? subject : 'Customized Texting Template',
      fileId: isExistingTemplateCustomized ? existingTemplate.fileId : null,
      objectType: CUSTOMIZED_TEMPLATE_OBJECT_TYPE,
      subject: isEmail ? subject : ''
    };

    setIsLoading(true);
    return dispatch(saveTemplate(options, { shouldShowMessage: false }))
      .then(data => {
        const { fileId } = data;

        handleTemplateChange({ id: fileId, taskId: id }).then(() => {
          if (shouldSendNow) {
            handleSubmit(e, { sendTemplateEmail: true, isEmail, id });
            dispatch(clearAlert());
            clearHandler();
          }
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveCustomizedTemplateHandler = e => {
    handleSaveCustomizeTemplate(e).then(() => {
      setIsSavedTemplate(true);
      setIsCustomizeMode(false);
      handlePreview();
    });
  };

  const submitHandler = e => {
    handleSubmit(e, { sendTemplateEmail: true, isEmail, id });
    clearHandler();
    dispatch(clearAlert());
  };

  const handleConfirmSubmit = () => {
    if (isEmail && !hasEmailIntegrationSetup) {
      dispatch(
        showAlert({
          message: 'It appears that email integration is not yet set up. Would you like to set up email integration?',
          icon: 'error',
          iconSize: 'm',
          primaryButtonLabel: 'Configure email',
          primaryButtonHandler: () => {
            dispatch(clearAlert());
            navigate('/settings/email-integration');
          },
          secondaryButtonLabel: 'Cancel'
        })
      );
      return;
    }
    dispatch(
      showAlert({
        message: `Are you sure you want to send the ${typeLabel?.toLowerCase()} now?`,
        icon: 'error',
        iconSize: 'm',
        primaryButtonHandler:
          !isCustomizeMode && !isSavedTemplate ? e => submitHandler(e) : e => handleSaveCustomizeTemplate(e, true),
        primaryButtonLabel: 'Yes, send now'
      })
    );
  };

  const handleCustomize = () => {
    setIsCustomizeMode(true);
  };

  const handleEditorChange = value => {
    setEditor(value);
  };

  const handleChangeSubject = e => {
    const { target } = e;
    const { value } = target;

    const isValid = target.checkValidity() && isFieldFilled(value);
    setSubject(value);
    setSubjectIsValid(isValid);
  };

  const shouldNotShowSaveTemplate = !showSaveTemplate || !isCustomizeMode;
  const editorMode = !isCustomizeMode ? 'preview' : 'template';

  return (
    <Dialog id="dialogTemplate" isOpen={isDialogOpen} size="xl">
      <DialogHeader
        testId={`previewEmail-FormDialogHeader`}
        title={`Preview ${typeLabel}`}
        icon={typePreviewIcon}
        clearHandler={clearHandler}
      />
      {!isLoading && isEmail && (
        <FormRow>
          <div className={styles.subjectContainer}>
            <FormLabel htmlFor="subject" required={isCustomizeMode}>
              {typeLabel} Subject
            </FormLabel>
            {isCustomizeMode ? (
              <FormTextInput
                id="subject"
                onChange={handleChangeSubject}
                size="l"
                type="text"
                value={subject}
                disabled={!isCustomizeMode}
                showInvalid={!subjectIsValid}
                required={isCustomizeMode}
              />
            ) : (
              <FormFieldReadOnly id="subject" value={subject} />
            )}
          </div>
        </FormRow>
      )}
      {defaultBody ? (
        <div className={styles.editorContainer}>
          <Editor
            defaultBody={defaultBody}
            editorPortal="dialog"
            isDisabled={!isCustomizeMode}
            mode={editorMode}
            getEditorValue={!isCustomizeMode ? null : handleEditorChange}
            isLoading={isLoading}
            variant={!isEmail ? 'texting' : 'email'}
            contact={isCustomizeMode ? contacts[contactId] : null}
            relationship={isCustomizeMode ? relationship : null}
          />
        </div>
      ) : (
        <Loading />
      )}
      <footer className={styles.footer}>
        <ButtonGroup className={styles.buttonGroup}>
          {!isCustomizeMode && !isLoading && (
            <Button
              label={`Customize ${typeLabel}`}
              ariaLabel={`Customize ${typeLabel?.toLowerCase()}`}
              type="submit"
              styleType="white"
              onClick={handleCustomize}
              disabled={disabled}
            />
          )}
          {!isLoading && (
            <Button
              ariaLabel="Send Now."
              label="Send Now"
              onClick={handleConfirmSubmit}
              styleType="white"
              disabled={disabled}
            />
          )}
          <Button
            ariaLabel={shouldNotShowSaveTemplate ? 'Close Preview.' : 'Save And Close preview.'}
            label={shouldNotShowSaveTemplate ? 'Close Preview' : 'Save And Close Preview'}
            onClick={shouldNotShowSaveTemplate ? clearHandler : saveCustomizedTemplateHandler}
            styleType="primary"
            disabled={disabled || isLoading}
          />
        </ButtonGroup>
      </footer>
    </Dialog>
  );
};

PreviewTaskDialog.propTypes = {
  className: string,
  isOpen: bool,
  defaultBody: array,
  handleSubmit: func.isRequired,
  handlePreview: func.isRequired,
  handleTemplateChange: func.isRequired,
  disabled: bool,
  taskId: string,
  isFromTaskCard: bool
};
