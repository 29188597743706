export const HOME_TYPE_DEFAULT = [
  { id: 'Single-family', value: 'Residential' },
  { id: 'Condo', value: 'Condominium' },
  { id: 'Town-Home', value: 'Townhouse' },
  { id: 'Manufactured/Mobile', value: 'Mobile/Manufactured' },
  { id: 'Condo/Town-Home', value: 'Condominium/Townhouse' },
  { id: 'Land', value: 'Land' }
];

export const HOME_TYPE_SUPPORT_MULTIFAMILY = [
  { id: 'Single-family', value: 'Residential' },
  { id: 'Condo', value: 'Condominium' },
  { id: 'Town-Home', value: 'Townhouse' },
  { id: 'Manufactured/Mobile', value: 'Mobile/Manufactured' },
  { id: 'Condo/Town-Home', value: 'Condominium/Townhouse' },
  { id: 'Multifamily', value: 'Multi-Family' },
  { id: 'Land', value: 'Land' }
];

export const BEDROOMS = [
  { id: '0', value: '0' },
  { id: '1', value: '1' },
  { id: '2', value: '2' },
  { id: '3', value: '3' },
  { id: '4', value: '4' },
  { id: '5', value: '5' },
  { id: '6', value: '6' },
  { id: '7', value: '7' },
  { id: '8', value: '8' },
  { id: '9', value: '9' }
];

export const MIN_BEDROOMS = [...BEDROOMS, { id: '10+', value: '10+' }];

export const BATHROOMS = [
  { id: '1', value: '1' },
  { id: '1.5', value: '1.5' },
  { id: '2', value: '2' },
  { id: '2.5', value: '2.5' },
  { id: '3', value: '3' },
  { id: '3.5', value: '3.5' },
  { id: '4', value: '4' },
  { id: '4.5', value: '4.5' },
  { id: '5', value: '5' },
  { id: '6', value: '6' },
  { id: '7', value: '7' },
  { id: '8', value: '8' },
  { id: '9', value: '9' }
];

export const MIN_BATHROOMS = [...BATHROOMS, { id: '10+', value: '10+' }];

export const REPORT_FREQUENCY = [
  { id: '1', value: 'weekly' },
  { id: '2', value: 'every 2 weeks' },
  { id: '4', value: 'every 4 weeks' },
  { id: '6', value: 'every 6 weeks' },
  { id: '8', value: 'every 8 weeks' },
  { id: '13', value: 'quarterly' },
  { id: '26', value: 'every 6 months' },
  { id: '52', value: 'yearly' }
];

export const PRICE = [
  { id: '50000', value: '50,000' },
  { id: '75000', value: '75,000' },
  { id: '100000', value: '100,000' },
  { id: '125000', value: '125,000' },
  { id: '150000', value: '150,000' },
  { id: '175000', value: '175,000' },
  { id: '200000', value: '200,000' },
  { id: '225000', value: '225,000' },
  { id: '250000', value: '250,000' },
  { id: '275000', value: '275,000' },
  { id: '300000', value: '300,000' },
  { id: '350000', value: '350,000' },
  { id: '400000', value: '400,000' },
  { id: '450000', value: '450,000' },
  { id: '500000', value: '500,000' },
  { id: '550000', value: '550,000' },
  { id: '600000', value: '600,000' },
  { id: '650000', value: '650,000' },
  { id: '700000', value: '700,000' },
  { id: '750000', value: '750,000' },
  { id: '800000', value: '800,000' },
  { id: '900000', value: '900,000' },
  { id: '1000000', value: '1,000,000' },
  { id: '1500000', value: '1,500,000' },
  { id: '2000000', value: '2,000,000' },
  { id: '2500000', value: '2,500,000' },
  { id: '3000000', value: '3,000,000' },
  { id: '4000000', value: '4,000,000' },
  { id: '5000000', value: '5,000,000' }
];

export const MIN_PRICE = [{ id: '0', value: '0' }, ...PRICE];

export const MAX_PRICE = [...PRICE, { id: '99000000', value: 'Unlimited' }];

export const MIN_GARAGE = [
  { id: '0', value: '0' },
  { id: '1', value: '1' },
  { id: '2', value: '2' },
  { id: '3', value: '3' },
  { id: '4', value: '4' },
  { id: 'Unlimited', value: 'Unlimited' }
];

export const MIN_BEDROOMS_OPTIONAL = [...BEDROOMS, { id: '10', value: '10' }];
export const MAX_BEDROOMS_OPTIONAL = [...BEDROOMS, { id: '10', value: '10' }, { id: 'Unlimited', value: 'Unlimited' }];

export const MIN_BATHROOMS_OPTIONAL = [...BATHROOMS, { id: '10', value: '10' }];
export const MAX_BATHROOMS_OPTIONAL = [
  ...BATHROOMS,
  { id: '10', value: '10' },
  { id: 'Unlimited', value: 'Unlimited' }
];

export const MIN_SQFT = [
  { id: '0', value: '0' },
  { id: '500', value: '500' },
  { id: '750', value: '750' },
  { id: '1000', value: '1000' },
  { id: '1250', value: '1250' },
  { id: '1500', value: '1500' },
  { id: '1750', value: '1750' },
  { id: '2000', value: '2000' },
  { id: '2250', value: '2250' },
  { id: '2500', value: '2500' },
  { id: '2750', value: '2750' },
  { id: '3000', value: '3000' },
  { id: '3250', value: '3250' },
  { id: '3500', value: '3500' },
  { id: '5000', value: '5000' },
  { id: '7500', value: '7500' }
];

export const MAX_SQFT = [
  { id: '750', value: '750' },
  { id: '1000', value: '1000' },
  { id: '1250', value: '1250' },
  { id: '1500', value: '1500' },
  { id: '1750', value: '1750' },
  { id: '2000', value: '2000' },
  { id: '2250', value: '2250' },
  { id: '2500', value: '2500' },
  { id: '2750', value: '2750' },
  { id: '3000', value: '3000' },
  { id: '3250', value: '3250' },
  { id: '3500', value: '3500' },
  { id: '5000', value: '5000' },
  { id: '7500', value: '7500' },
  { id: '10000', value: '10000' }
];
