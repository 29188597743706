import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, ButtonGroup, Dialog, FormField, Icon, Loading } from '../';

import styles from './AlertDialog.css';
import { isExternalLink } from '../../utils/urls';

export const AlertDialog = React.forwardRef((props, ref) => {
  const { data, clearHandler, testId = 'AlertDialog' } = props;

  const {
    className,
    confirmDeleteInputValue,
    icon,
    iconIsColored = false,
    iconSize = 's',
    hasConfirmDeleteInput,
    hint,
    isOpen,
    loading,
    message,
    primaryButtonLabel,
    primaryButtonIcon,
    primaryButtonHandler,
    primaryButtonUrl,
    primaryButtonDataCy,
    secondaryButtonLabel,
    secondaryButtonIcon,
    secondaryButtonHandler,
    secondaryButtonUrl,
    tertiaryButtonLabel, // tertiary button should almost never be used.  It is a fallback when you need two actions, but still need cancel.
    tertiaryButtonIcon,
    tertiaryButtonHandler,
    tertiaryButtonUrl
  } = data || {};

  const focusButtonRef = useRef(null);

  const [confirmInput, setConfirmInput] = useState('');

  useEffect(() => {
    if (isOpen) {
      focusButtonRef.current.focus();
    } else {
      setConfirmInput('');
    }
  }, [isOpen]);

  const hasSecondaryButton = secondaryButtonLabel || secondaryButtonIcon;
  const secondaryHandler = secondaryButtonHandler || clearHandler; // this defaults to the standard clearAlert passed via a clearHandler prop.

  const hasTertiaryButton = tertiaryButtonLabel && (tertiaryButtonHandler || tertiaryButtonUrl);

  if (!message || !isOpen) {
    return null;
  }

  const classes = classnames({
    [styles.alert]: !className,
    [className]: !!className
  });

  const handleConfirmInput = e => {
    setConfirmInput(e.target.value);
  };

  const checkIfDisabled = () => {
    if (hasConfirmDeleteInput) {
      if (confirmInput !== confirmDeleteInputValue) {
        return true;
      }

      return false;
    }
  };

  return (
    <Dialog ref={ref} isOpen={isOpen} className={classes}>
      <div data-testid={`${testId}-body`} className={styles.body}>
        <Icon name={icon} size={iconSize} className={styles.icon} isColored={iconIsColored} />
        <h1 className={styles.h1}> {message}</h1>
        {hint && <p className={styles.hint}>{hint}</p>}
        {hasConfirmDeleteInput && (
          <div className={styles.confirmInput}>
            <FormField
              type="input"
              inputType="text"
              id="confirmInput"
              label="Type DELETE to confirm"
              onChange={handleConfirmInput}
            />
          </div>
        )}
      </div>
      <footer className={styles.footer}>
        <ButtonGroup>
          <Loading loading={loading} className={styles.loading} />
          {hasTertiaryButton && (
            <Button
              ariaLabel={tertiaryButtonLabel}
              type="button"
              label={tertiaryButtonLabel}
              icon={tertiaryButtonIcon}
              onClick={tertiaryButtonHandler}
              className={styles.button}
              url={tertiaryButtonUrl}
              externalLink={isExternalLink(tertiaryButtonUrl)}
              data-testid={`${testId}-tertiaryButton`}
              styleType="white"
            />
          )}
          {hasSecondaryButton && (
            <Button
              ariaLabel={secondaryButtonLabel}
              type="button"
              label={secondaryButtonLabel}
              icon={secondaryButtonIcon}
              onClick={secondaryHandler}
              className={styles.button}
              url={secondaryButtonUrl}
              externalLink={isExternalLink(secondaryButtonUrl)}
              data-testid={`${testId}-secondaryButton`}
              styleType="white"
            />
          )}
          <Button
            ariaLabel={primaryButtonLabel}
            type="button"
            label={primaryButtonLabel}
            icon={primaryButtonIcon}
            onClick={primaryButtonHandler}
            disabled={loading || checkIfDisabled()}
            styleType="primary"
            className={styles.button}
            url={primaryButtonUrl}
            externalLink={isExternalLink(primaryButtonUrl)}
            data-testid={`${testId}-primaryButton`}
            ref={focusButtonRef}
            data-cy={primaryButtonDataCy}
          />
        </ButtonGroup>
      </footer>
    </Dialog>
  );
});

AlertDialog.propTypes = {
  clearHandler: PropTypes.func,
  data: PropTypes.object
};
