import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { arrayOf, node, oneOfType } from 'prop-types';
import { Transforms } from 'slate';
import { ReactEditor, useSelected, useSlate } from 'slate-react';
import classnames from 'classnames';
import isAfter from 'date-fns/isAfter';

import { NoResultsItem, Tag, TagGroup } from '../../';
import { UNICODE } from '../../../constants';
import { TEMPLATE_OBJECT_TYPE_MAP } from '../../../constants/templates';
import dropdownStyles from '../../Dropdown/Dropdown.css';
import { TemplateMenuContext } from '../Editor';
import { DEFAULT_TEMPLATE_TEXT } from '../editor-utils';
import { EDITOR_TEMPLATE_CATEGORIES } from '../editor-templates';
import { isNewlyAdded, parseISODate } from '../../../utils/dates';
import { isSupportedTemplateObjectType } from '../../../utils/templates';

import styles from './Template.css';
import { useSelector } from 'react-redux';

const { ZERO_WIDTH_SPACE } = UNICODE;

const ALLOWED_TEMPLATE_TYPES = {
  signature: ['agent'],
  template: ['agent', 'contact', 'relationship'],
  default: [1, 99, 'agent', 'contact', 'template', 'relationship']
};

/**
 * React component for rendering Template suggestion menu items.
 * @param {Object} props MenuItem component props.
 * @param {Function} props.handleTemplateChange A method for handling menu items click events.
 * @param {Boolean} props.isHighlighted A boolean to tell whether the current menu item is highlighted for selection.
 * @param {Function} props.updateCurrentTemplate A method for updating the current template to ensure it is scrolled into view in the menu.
 * @param {Object} props.template The template details to show in the MenuItem.
 * @param {Function} props.templateKey An id-like key used by updateCurrentTemplate.
 */
const MenuItem = props => {
  const {
    contact,
    template,
    templateKey,
    handleTemplateChange,
    isHighlighted,
    updateCurrentTemplate,
    relationship,
    mode
  } = props;

  const { description, file, objectType, whenCreated } = template;
  const { category } = file || {};
  const templateCategory = EDITOR_TEMPLATE_CATEGORIES.find(item => item.id === category);
  const displayDescription = category ? `${templateCategory.value} - ${description}` : description;
  const displayObjectType = TEMPLATE_OBJECT_TYPE_MAP.get(objectType);

  const parsedWhenCreated = parseISODate(whenCreated);
  const isAfterLaunchOfTemplates = isAfter(parsedWhenCreated, new Date(2021, 4, 20)); // May 20, 2021 (months are zero-based)
  const isNew = isAfterLaunchOfTemplates && isNewlyAdded(whenCreated);

  const activeMenuItemRef = useRef(null);

  const { drawerType, isOpen } = useSelector(state => state.drawer);

  const shouldScrollIntoView = drawerType !== 'taskForm' || !isOpen;

  const templateCallback = useCallback(
    e => {
      e.stopPropagation();
      handleTemplateChange(template, contact, relationship, mode);
    },
    [handleTemplateChange, template, contact, relationship, mode]
  );

  useEffect(() => {
    if (isHighlighted) {
      updateCurrentTemplate(templateKey);
      if (shouldScrollIntoView) {
        activeMenuItemRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    }
  }, [updateCurrentTemplate, templateKey, isHighlighted, shouldScrollIntoView]);

  const classes = classnames({
    [dropdownStyles.item]: true,
    [styles.item]: true,
    [dropdownStyles.hovered]: isHighlighted
  });

  const itemProps = {
    className: classes,
    onPointerDown: templateCallback
  };

  if (isHighlighted) {
    itemProps.ref = activeMenuItemRef;
  }

  return (
    <li {...itemProps} data-id="templateMenuItem">
      {displayDescription}
      <TagGroup>
        {isNew && <Tag label="new" />}
        <Tag label={displayObjectType} />
      </TagGroup>
    </li>
  );
};

const getTemplatesToShow = (templates, mode, text) =>
  Object.keys(templates).reduce((acc, key) => {
    // We need to account for the ZERO_WIDTH_SPACE possibly inserted by the editor keydown when triggering a template.
    const template = templates[key];
    const { description, key: templateKey = 'template', objectType, file } = template;
    const { category } = file || {};
    const templateCategory = EDITOR_TEMPLATE_CATEGORIES.find(item => item.id === category);
    const displayObjectType = TEMPLATE_OBJECT_TYPE_MAP.get(objectType);

    const allowedTemplates = ALLOWED_TEMPLATE_TYPES[mode] || ALLOWED_TEMPLATE_TYPES.default;

    if (!allowedTemplates.includes(template.objectType)) {
      // We want to throw away templates that are not allowed for the variant.
      return acc;
    }

    // If there is no text entered in the template mark, or the template mark is equal to ZERO_WIDTH_SPACE, we match anything.
    // Otherwise, we match against a clean version of the text.
    const matchText =
      !text || text === DEFAULT_TEMPLATE_TEXT
        ? ''
        : text
            .split(ZERO_WIDTH_SPACE)
            .join('') // split then join is a replace all of ZERO_WIDTH_SPACE
            .toLowerCase()
            .trim();

    if (
      // Show matching type ahead items that match either the template description or objectType.
      description.toLowerCase().includes(matchText) ||
      templateCategory?.value.toLowerCase().includes(matchText) ||
      displayObjectType.includes(matchText) || // can include system, personal, or an objectType for a snippet like contact or agent.
      (isSupportedTemplateObjectType(objectType) && 'template'.includes(matchText)) || // user is typing `template`
      `${objectType}.${templateKey}`.toLowerCase().includes(matchText)
    ) {
      acc[key] = template;
    }

    return acc;
  }, {});

/**
 * React component for rendering Template nodes.
 * @param {Object} props Template component props.
 * @param {Object} props.attributes The node's Slate attributes.
 * @param {Object} props.element The Slate node.
 * @param {Array} props.children The child nodes of a template - usually the string used in auto-suggest.
 */
export const Template = props => {
  const {
    contact,
    editorPortal = 'app',
    handleTemplateChange,
    mode,
    templates,
    templateMenuIndex,
    updateCurrentTemplate,
    updateTemplateMenuIndex,
    relationship
  } = useContext(TemplateMenuContext);

  const portalTarget = document.getElementById(editorPortal) || document.body;
  const templateRef = useRef();
  const editor = useSlate();
  const [cachedCursorRect, setCursorRect] = useState(null);
  const [cachedPortalRect, setPortalRect] = useState(null);
  const [cachedScrollTop, setScrollTop] = useState(null);
  const isTemplateSelected = useSelected();

  const { children, attributes } = props;
  const { text } = children[0].props.text;
  const [templatesToShow, setTemplatesToShow] = useState(getTemplatesToShow(templates, mode, text));

  const isTemplateActive = !!text && isTemplateSelected && mode !== 'preview';

  const templateToShowKeys = Object.keys(templatesToShow);
  const templateToShowCount = templateToShowKeys.length;

  const getWindowSelectionRange = () => {
    const nativeWindowSelection = window.getSelection();
    if (nativeWindowSelection.rangeCount <= 0) {
      return null;
    }

    return nativeWindowSelection.getRangeAt(0);
  };

  useEffect(() => {
    const range = getWindowSelectionRange();
    if (!range) {
      return;
    }
    setCursorRect(range.getBoundingClientRect());
    setPortalRect(portalTarget.getBoundingClientRect());
    setScrollTop(portalTarget.scrollTop);

    return () => {
      if (updateTemplateMenuIndex) {
        updateTemplateMenuIndex(0, templates.length);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isTemplateSelected) {
      setCursorRect(null);
      setPortalRect(null);
      setScrollTop(null);
      if (updateTemplateMenuIndex) {
        updateTemplateMenuIndex(0, templateToShowCount);
      }
    }
  }, [isTemplateSelected, templateToShowCount, updateTemplateMenuIndex]);

  useEffect(() => {
    // This check cleans up any empty template nodes.
    if (!isTemplateActive && (!text || text === '' || text === DEFAULT_TEMPLATE_TEXT)) {
      const path = ReactEditor.findPath(editor, props.element);
      Transforms.removeNodes(editor, { at: path });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplateActive]);

  useEffect(() => {
    setTemplatesToShow(getTemplatesToShow(templates, mode, text));
  }, [templates, mode, text, updateTemplateMenuIndex]);

  useEffect(() => {
    if (updateTemplateMenuIndex) {
      updateTemplateMenuIndex(0, templateToShowCount);
    }
  }, [templateToShowCount, updateTemplateMenuIndex]);

  const getMenuStyles = (cachedCursorRect, cachedPortalRect, cachedScrollTop) => {
    const range = getWindowSelectionRange();
    const isRangeTextNode = range?.commonAncestorContainer.nodeType === Node.TEXT_NODE;

    if (!range || !isRangeTextNode) {
      return null;
    }

    const cursorRect = cachedCursorRect || range.getBoundingClientRect(); // we prefer to use rect which is the value on mount, but we fallback to ensure the render isn't stopped.
    const portalTargetRect = cachedPortalRect || portalTarget.getBoundingClientRect(); // we prefer to use rect which is the value on mount, but we fallback to ensure the render isn't stopped.
    const portalScrollTop = cachedScrollTop || portalTarget.scrollTop; // We assume that the scrolling the portal horizontally won't be needed, so there is no portalScrollLeft.

    // We need to set up the menu position.
    const isMenuPositionedLeft = cursorRect.left - portalTargetRect.left < portalTargetRect.width / 2;
    // 4 is a UI spacer/magic number just to make the position a bit more appealing to the eye.
    const UI_SPACER = 4;
    const leftPosition = isMenuPositionedLeft
      ? portalTarget === 'body'
        ? cursorRect.left - UI_SPACER
        : cursorRect.left - portalTargetRect.left - UI_SPACER
      : null;

    const rightPosition = !isMenuPositionedLeft
      ? portalTarget === 'body'
        ? cursorRect.right - UI_SPACER
        : portalTargetRect.right - cursorRect.right - UI_SPACER
      : null;

    const topPosition =
      portalTarget === 'body'
        ? cursorRect.top + cursorRect.height + UI_SPACER
        : cursorRect.top + portalScrollTop - portalTargetRect.top + cursorRect.height + UI_SPACER;

    const menuStyles = {
      top: topPosition
    };

    if (isMenuPositionedLeft) {
      menuStyles.left = leftPosition;
    } else {
      menuStyles.right = rightPosition;
    }

    return menuStyles;
  };

  const menuStyles = getMenuStyles(cachedCursorRect, cachedPortalRect, cachedScrollTop);

  const menuClasses = classnames({
    [dropdownStyles.menu]: true,
    [styles.menu]: true
  });

  const noResultsFound = !!templatesToShow && templateToShowCount === 0;

  return (
    <div className={styles.container} ref={templateRef}>
      <span className={styles.template} spellCheck={false} {...attributes}>
        {children}
      </span>
      {isTemplateActive &&
        menuStyles &&
        ReactDOM.createPortal(
          <ul data-id="templateMenu" className={menuClasses} style={menuStyles}>
            {noResultsFound ? (
              <NoResultsItem />
            ) : (
              templateToShowKeys.map((key, i) => {
                const isHighlighted = templateMenuIndex === i;

                return (
                  <MenuItem
                    contact={contact}
                    key={i}
                    templateKey={key}
                    template={templatesToShow[key]}
                    handleTemplateChange={handleTemplateChange}
                    isHighlighted={isHighlighted}
                    updateCurrentTemplate={updateCurrentTemplate}
                    relationship={relationship}
                    mode={mode}
                  />
                );
              })
            )}
          </ul>,
          portalTarget
        )}
    </div>
  );
};

Template.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired
};
