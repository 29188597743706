import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './FormFieldset.css';
import { isFieldFilled } from '../../utils/validation';

export const FormFieldset = props => {
  const { children, className, label } = props;

  if (!children) {
    return null;
  }

  const hadLabel = isFieldFilled(label);
  const classes = classnames({
    [styles.fieldset]: !className,
    [styles.noLabel]: !hadLabel,
    [className]: !!className
  });

  return (
    <section className={classes}>
      <h2 className={styles.h2}>{label}</h2>
      <div className={styles.fieldContainer}>{children}</div>
    </section>
  );
};

FormFieldset.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  label: PropTypes.string
};
