import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { Button } from './';
import { Icon } from '../Icon';
import { useMediaQueryContext } from '../MediaQueryProvider/MediaQueryProvider';

import { clearAlert, showAlert } from '../../actions';
import { clearDrawer, showDrawer } from '../../actions/drawer';
import { checkUnsubscribe, cleanPhoneNumber, getPrimaryMethod, URI_SCHEMES_BY_COMM_TYPE } from '../../utils';

import buttonStyles from './Button.css';
import styles from './ContactMethodButton.css';

export const ContactMethodButton = props => {
  const dispatch = useDispatch();
  const { isDesktopUpCommon } = useMediaQueryContext() || {};
  const { texting } = useSelector(store => store.settings);
  const { isUSAgent } = useSelector(store => store.userProfile.userInfo);

  const { contact, data, hasEmailIntegrationSetup } = props;
  const { defaultCommId, id: contactId, isUnsubscribed } = contact;
  const { id, tag, type, value } = data || {};
  const primaryContactMethod = getPrimaryMethod(contact) || {};
  const cleanValue = type === 'phone' ? cleanPhoneNumber(value) : value;
  const isPrimary = defaultCommId ? id === defaultCommId : cleanValue === primaryContactMethod.value;
  const isEmail = type === 'email';
  const isPhone = type === 'phone';
  const isTexting = isPhone && isUSAgent && texting?.registered && tag === 'Mobile';

  const labelVerb = isTexting ? 'Text' : isPhone ? 'Call' : 'Email';
  const ariaLabel = `${labelVerb} ${cleanValue}`;
  const href = `${URI_SCHEMES_BY_COMM_TYPE[type]}:${cleanValue}`;

  const handleClick = useCallback(
    e => {
      e.preventDefault();

      if (hasEmailIntegrationSetup && isEmail) {
        dispatch(showDrawer({ drawerType: 'emailForm', contactId }));
        return;
      }

      if (isTexting) {
        dispatch(showDrawer({ drawerType: 'textForm', contactId }));
        return;
      }

      checkUnsubscribe(
        { href, isUnsubscribed, commMethodType: type, showAlert, clearAlert, clearDrawer, callBack: null },
        e
      );
    },
    [dispatch, contactId, hasEmailIntegrationSetup, href, isEmail, isTexting, isUnsubscribed, type]
  );

  const getIcon = () => {
    switch (type) {
      case 'email':
        return 'email';
      case 'phone':
        if (isTexting) {
          return 'texting';
        }
        return 'call';
      default:
        return;
    }
  };

  if (!cleanValue || cleanValue === '') {
    return null;
  }

  const classes = classnames({
    [buttonStyles.primarycontact]: true,
    [styles.button]: true
  });

  const wrapClasses = classnames({
    [styles.wrap]: true,
    [styles.isSecondary]: !isPrimary
  });

  return (
    <div className={wrapClasses}>
      <div className={styles.wrapInner}>
        <object className="nestedLinkWrapper">
          <Button
            ariaLabel={ariaLabel}
            className={classes}
            label={isDesktopUpCommon ? cleanValue : null}
            icon={getIcon()}
            onClick={handleClick}
            size="s"
            styleType={isDesktopUpCommon ? 'inline' : null}
          />
        </object>
        {isPrimary && (
          <Icon
            name="flag"
            size="xxxs"
            isColored={true}
            className={styles.defaultCommIcon}
            title="Primary contact method"
          />
        )}
      </div>
    </div>
  );
};
export default ContactMethodButton;
