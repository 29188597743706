import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import { deepEqual } from 'fast-equals';
import classnames from 'classnames';

import { clearAlert, showAlert } from '../../actions';
import { getContactDetail, getContacts } from '../../actions/contacts';
import { clearDrawer } from '../../actions/drawer';
import { showMessage } from '../../actions/message';
import { FormTextInput, Icon, Tag, TagGroup } from '../';
import { KEYCODE_MAP, REGEX } from '../../constants';
import { checkAlreadyRecipient, checkUnsubscribeEmail, getContactEmailName, deleteObjectFromArray } from '../../utils';

import styles from './Lookup.css';
import dropdownStyles from '../Dropdown/Dropdown.css';
import tagStyles from '../Tag/Tag.css';

export class LookupEmail extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const { entities } = this.props;

    return {
      search: '',
      selectedEntities: !entities
        ? []
        : entities.reduce((acc, entity) => {
            acc.push(entity);

            return acc;
          }, [])
    };
  };

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      this.focusInput();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { changeHandler, entities, field, formIsSubmitting } = this.props;
    const { entities: prevEntities, formIsSubmitting: prevFormIsSubmitting } = prevProps;
    const { selectedEntities } = this.state;
    const { selectedEntities: prevSelectedEntities } = prevState;

    if (!deepEqual(selectedEntities, prevSelectedEntities)) {
      changeHandler(field, selectedEntities);
    }

    if (entities.length !== prevEntities.length) {
      this.setState(this.getInitialState());
      return;
    }

    if (
      (prevFormIsSubmitting && !formIsSubmitting) ||
      (entities && entities[0] && prevEntities && prevEntities[0] && entities[0].email !== prevEntities[0].email)
    ) {
      // the parent form was submitted
      this.setState(this.getInitialState());
    }
  }

  handleChange = e => {
    const { value: searchTerm } = e.target;
    const isFieldBlank = searchTerm.trim().length === 0;

    this.setState({
      search: searchTerm
    });

    if (isFieldBlank) {
      // we short circuit the change handler if the field is empty
      return;
    }

    this.suggestContacts(searchTerm);
  };

  suggestContacts = debounce(q => {
    const { getContacts } = this.props;

    getContacts({ q, setCurrentGroup: false, ignorePreexisting: true });
  }, 250);

  handleKeyDown = e => {
    const { selectedEntities } = this.state;
    const { target, which: keycode } = e;
    const { value: inputValue } = target;

    const supportedKeyCodes = [KEYCODE_MAP.BACKSPACE, KEYCODE_MAP.COMMA, KEYCODE_MAP.SEMICOLON, KEYCODE_MAP.TAB];

    if (!supportedKeyCodes.includes(keycode)) {
      // we exit early if it isn't a supported key for email handling.
      return;
    }

    if (inputValue === '' && keycode === KEYCODE_MAP.TAB) {
      // if the field is empty and tab is pressed, we want to
      return;
    }

    // if the email input is blank, we delete previously selected emails from the field's recipient list.
    if (inputValue.length === 0 && keycode === KEYCODE_MAP.BACKSPACE) {
      e.preventDefault();
      this.setState({
        selectedEntities: [...selectedEntities.slice(0, -1)]
      });
    } else if ([KEYCODE_MAP.COMMA, KEYCODE_MAP.SEMICOLON, KEYCODE_MAP.TAB].includes(keycode)) {
      e.preventDefault();
      // if either the SPACE, COMMMA, or SEMICOLON keys are pressed, the user is trying to complete the entering of an email.
      this.getRecipientFromInput(inputValue);
    }
  };

  addToRecipients = recipient => {
    const { clearAlert, clearDrawer, showMessage, showAlert } = this.props;
    const { selectedEntities } = this.state;
    const { isUnsubscribed } = recipient;

    const isAlreadyRecipient = checkAlreadyRecipient(selectedEntities, recipient);

    if (isAlreadyRecipient) {
      showMessage({ message: 'That email address is already in the recipients list.', type: 'error' }, true);
      return;
    }

    if (isUnsubscribed) {
      const primaryCallBack = () => {
        this.updateSelectedEntities(recipient);
      };

      const secondaryCallBack = () => {
        this.focusInput();
      };

      checkUnsubscribeEmail({
        isUnsubscribed,
        showAlert,
        clearAlert,
        clearDrawer,
        primaryCallBack,
        secondaryCallBack
      });

      return;
    }

    this.updateSelectedEntities(recipient);
  };

  focusInput = () => {
    this.inputRef.current.focus();
  };

  updateSelectedEntities = recipient => {
    const { alertIsOpen } = this.props;
    const { selectedEntities } = this.state;

    this.setState({
      search: '',
      selectedEntities: [...selectedEntities, recipient]
    });

    if (alertIsOpen) {
      this.focusInput();
    }
  };

  getRecipientFromInput = inputValue => {
    const isEmailValid = REGEX.EMAIL.test(inputValue);
    const recipient = { email: inputValue, isValid: isEmailValid };

    this.addToRecipients(recipient);
  };

  handleBlur = e => {
    const { alertIsOpen, blurHandler } = this.props;
    const { value: inputValue } = e.target;
    blurHandler(e);

    if (alertIsOpen || inputValue === '') {
      return;
    }

    this.getRecipientFromInput(inputValue);
  };

  handleDrop = e => {
    e.preventDefault();

    const { contact } = this.props;
    const { emails: contactEmails } = contact;
    const droppedText = e.dataTransfer.getData('text');
    const emailDropped = droppedText.replace('mailto:', '');
    const isEmailValid = REGEX.EMAIL.test(emailDropped);

    if (!isEmailValid) {
      return;
    }

    const isEmailFromContact = contactEmails.find(email => {
      return emailDropped === email.value;
    });

    // Currently, we only support dropped emails from the current contact.
    if (!isEmailFromContact) {
      return;
    }

    this.receiveAndAddContact(contact, emailDropped);
  };

  receiveAndAddContact = (contact, email) => {
    const emailName = getContactEmailName(contact, email);
    const recipient = {
      contactId: contact.id,
      email,
      name: emailName,
      isValid: true,
      isUnsubscribed: contact.is_unsubscribed
    };

    this.addToRecipients(recipient);
  };

  handleMenuClick = item => {
    const { contacts, getContactDetail } = this.props;
    const { contactId, email } = item || {};
    const contact = contacts[contactId];

    if (!contact) {
      return;
    }

    this.receiveAndAddContact(contact, email.value);
    getContactDetail({ id: contactId }); // we fetch the contact details in the background to make sure the email template can fill properly.
  };

  editInvalidRecipient = e => {
    const { selectedEntities } = this.state;
    const { currentTarget } = e;
    const { dataset } = currentTarget;

    this.setState({
      search: dataset.id,
      selectedEntities: deleteObjectFromArray(selectedEntities, 'email', dataset.id)
    });

    this.focusInput();
  };

  handleRecipientDelete = e => {
    e.stopPropagation();
    const { selectedEntities } = this.state;
    const { target } = e;
    const { dataset } = target;

    this.setState({
      selectedEntities: deleteObjectFromArray(selectedEntities, 'email', dataset.id)
    });
  };

  itemToString = item => {
    return item ? item.value : '';
  };

  render() {
    const { contacts, field, formIsSubmitting, groups } = this.props;
    const { search, selectedEntities } = this.state;

    const list = groups[`${search.toLowerCase().replace(/\s/g, '')}::name::0`];
    // We need to clean the list of contacts associated with the search, since some contacts do not have emails.
    const cleanList =
      list &&
      list.reduce((acc, id) => {
        const contact = contacts[id];
        const { emails } = contact;

        if (emails && emails.length > 0) {
          // Do not show contacts with no emails
          acc.push(contact);
        }

        return acc;
      }, []);

    const hasItemsInList = cleanList && cleanList.length > 0;

    const emailLookupClasses = classnames({
      [styles.emailLookup]: true,
      [styles.disabled]: formIsSubmitting
    });

    return (
      <Fragment>
        <Downshift
          defaultHighlightedIndex={0}
          inputValue={search}
          onSelect={this.handleMenuClick}
          itemToString={this.itemToString}
        >
          {({ getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex }) => (
            <div className={emailLookupClasses}>
              {selectedEntities && (
                <TagGroup className={tagStyles.emailAddressTagGroup}>
                  {selectedEntities.map((entity, i) => {
                    const { email, isUnsubscribed, isValid, name } = entity;
                    const label = isValid && name ? `${name} (${email && email.split('@')[1]})` : email; // Bob Smith (gmail.com)
                    const title = isValid && name ? `${name} (${email})` : email;

                    const classes = classnames({
                      [tagStyles.emailAddressTag]: true,
                      [tagStyles.tagInvalid]: isUnsubscribed || isValid === false // We make the label red when it is an invalid email OR the contact isUnsubscribed.
                    });

                    return (
                      <Tag
                        className={classes}
                        key={`${email}-${i}`}
                        entityId={email}
                        label={label}
                        title={title}
                        removable={true}
                        handleClick={isValid ? null : this.editInvalidRecipient}
                        handleRemove={this.handleRecipientDelete}
                        shouldTruncateLabel={false}
                        removeTooltipPos="left"
                      />
                    );
                  })}
                  <FormTextInput
                    id="contactSearchInput"
                    ref={this.inputRef}
                    {...getInputProps({
                      onChange: this.handleChange,
                      onKeyDown: this.handleKeyDown,
                      onBlur: this.handleBlur,
                      onDrop: this.handleDrop
                    })}
                    className={styles.emailInput}
                    disabled={formIsSubmitting}
                    data-field={field}
                  />
                </TagGroup>
              )}
              {isOpen && hasItemsInList && (
                <ul {...getMenuProps()} className={dropdownStyles.menu}>
                  {hasItemsInList &&
                    cleanList.map((contact, index) => {
                      const { company, emails, fullNameFirstNameFirst, id } = contact;

                      const searchLowerCased = search && search.toLowerCase();
                      const isNameMatch = fullNameFirstNameFirst.toLowerCase().includes(searchLowerCased);
                      const isEmailMatch = emails.find(email => {
                        return email.value.includes(searchLowerCased);
                      });
                      const matchingEmailValue = (isEmailMatch && isEmailMatch.value) || emails[0].value;
                      const isCompanyMatch =
                        !isNameMatch && !isEmailMatch && company && company.toLowerCase().includes(searchLowerCased);

                      if (isCompanyMatch) {
                        // We want to exclude company matches only from appearing in the email UI.
                        return null;
                      }

                      const isSelected = selectedEntities[contact];

                      const classes = classnames({
                        [dropdownStyles.item]: true,
                        [dropdownStyles.hovered]: highlightedIndex === index,
                        [styles.selected]: isSelected
                      });

                      return (
                        <li
                          className={classes}
                          {...getItemProps({
                            key: id,
                            index,
                            item: {
                              contactId: id,
                              email: isEmailMatch || emails[0]
                            }
                          })}
                        >
                          {fullNameFirstNameFirst} {matchingEmailValue}{' '}
                          {isSelected && <Icon name="check" size="s" isColored={true} />}
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          )}
        </Downshift>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  contacts: state.contacts.entities,
  groups: state.contacts.groups,
  alertIsOpen: state.alert.isOpen
});

const mapDispatchToProps = {
  clearAlert,
  clearDrawer,
  getContactDetail,
  getContacts,
  showAlert,
  showMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(LookupEmail);

LookupEmail.propTypes = {
  autoFocus: PropTypes.bool,
  changeHandler: PropTypes.func.isRequired,
  contact: PropTypes.object,
  entities: PropTypes.array,
  formIsSubmitting: PropTypes.bool
};
