import { parseISODate, sameOrBefore } from './dates';
import { isSameDay, addDays, subDays } from 'date-fns';
import { showMessage } from '../actions/message';
import {
  TASK_PLAN_ADJUSTMENTS_TRANSACTION_DATES,
  TASK_PLAN_ADJUSTMENT_DIRECTIONS,
  TASK_PLAN_ADJUSTMENT_MAP
} from './tasks';
import { ENTITY_TYPES } from './notes';
import { request } from './request';
import { SNAPSHOT_PROPERTY_TYPE_MAPPINGS } from './snapshot';
import { parseSnakeCase } from './strings';
import { getDisplayName } from './contacts';

export const AGENT_TYPE = [
  { label: "Seller's Agent", value: 1, representationTypeId: 2 },
  { label: "Buyer's Agent", value: 2, representationTypeId: 1 },
  { label: "Buyer's and Seller's agent", value: 3, representationTypeId: 3 }
];

export const TRANSACTION_STATUSES = [
  { id: 1, value: 'Active' },
  { id: 2, value: 'Active under contract' },
  { id: 3, value: 'Pending' },
  { id: 4, value: 'Closed' },
  { id: 5, value: 'Cancelled' },
  { id: 6, value: 'Expired' },
  { id: 7, value: 'Withdrawn' },
  { id: 8, value: 'Coming soon - non MLS' }
];

// Less than 4 means active, greater or equal means non-active
export const NON_ACTIVE_FLAG = 4;

export const ALL_ACTIVE = 0;

export const TRANSACTION_ALL_STATUSES = [{ id: 0, value: 'All active' }, ...TRANSACTION_STATUSES];

export const TRANSACTION_LISTINGS_STATUSES = TRANSACTION_ALL_STATUSES.filter(status => status.id !== NON_ACTIVE_FLAG);

export const COMMISSION_RATE_TYPE = [
  { id: 1, value: 'Flat' },
  { id: 2, value: 'First and remainder' },
  { id: 3, value: 'Dollar amount' }
];

export const REPRESENTATION_TYPE_MAP = new Map([
  [1, 'Buyer'],
  [2, 'Seller'],
  [3, 'Buyer_and_seller']
]);

export const REPRESENTATION_TYPES = {
  Buyer: { value: 1, label: 'Buyer' },
  Seller: { value: 2, label: 'Seller' },
  Buyer_and_seller: { value: 3, label: 'Buyer and seller' }
};

/**
 * @param {Number} representationTypeId
 * @returns {{ value: Number, label: String }} Representation Type
 */
export const getRepresentationType = representationTypeId => {
  return REPRESENTATION_TYPES[REPRESENTATION_TYPE_MAP.get(representationTypeId)];
};

export const TRANSACTION_PARTY_ROLE_MAP = new Map([
  [3, 'Primary_seller'],
  [0, 'Seller'],
  [4, 'Primary_buyer'],
  [1, 'Buyer'],
  [2, 'Other']
]);

export const TRANSACTION_PARTY_ROLES = {
  Primary_seller: { value: 3, label: 'Primary seller' },
  Seller: { value: 0, label: 'Seller' },
  Primary_buyer: { value: 4, label: 'Primary buyer' },
  Buyer: { value: 1, label: 'Buyer' },
  Other: { value: 2, label: 'Other' }
};

export const REFERRAL_FEE_TYPE = [
  { id: 2, value: 'Flat %' },
  { id: 1, value: 'Dollar amount' }
];

/**
 * @param {Number} roleId
 * @returns {{ value: Number, label: String }} Transaction Party Role
 */
export const getTransactionPartyRole = roleId => {
  return TRANSACTION_PARTY_ROLES[TRANSACTION_PARTY_ROLE_MAP.get(roleId)];
};

/**
 * Returns the Transaction Party Roles in an array that can be used in a Dropdown compoment
 * @returns {{ id: Number, value: String }[]} Transaction Party Role
 */
export const getTransactionPartyRoleOptions = () => {
  return Object.values(TRANSACTION_PARTY_ROLES).map(({ value, label }) => ({
    id: value,
    value: label
  }));
};

// 0 - Not set: If the primary buyer has a primary property with no empty address, the new property type will be investment; if the primary buyer's primary property address is empty, the new property will replace the primary property.
// 1 - Set as the primary: Set this new property as the primary buyer's primary property, and change the primary buyer's original primary property as investment.
// 2 - Replace the primary: Set this new property as the primary buyer's primary property, and remove the primary buyer's original primary property.

export const BUYER_PRIMARY_PROPERTY_OPTION_TYPE = [
  { id: 0, value: 'Investment' },
  { id: 1, value: 'Primary' },
  { id: 2, value: 'Primary, but replace the current primary' }
];

/**
 * Delete a transaction party with transactionId and contactId
 * @param {Array} transactionParties the array
 * @param {string} transactionId associated with the transaction party
 * @param {string} contactId associated with the transaction party
 */
export const deletePartyFromState = (transactionParties, transactionId, contactId) => {
  return transactionParties.filter(item => !(item.transactionId === transactionId && item.contactId === contactId));
};

/**
 * Uses the Hestia normalized status (mls_status) to check if the property has a Sold status.
 * @param {String} mls_status - normalized status (mls_status from Hestia)
 */
export const checkIsSold = mls_status => {
  if (!mls_status) {
    return false;
  }

  // mls_status is a normalized status across all boards, so it is safe to check against it.
  return (
    mls_status.toLowerCase() === 'sold' ||
    mls_status.toLowerCase() === 'sold & closed' ||
    mls_status.toLowerCase() === 'closed'
  );
};

/**
 * Gets the preferred transaction price when displaying a property or calculating a commission.
 * @param {Object} listing - the MLS listing data from Hestia.
 * @param {String} transactionSalePrice - the salePrice field from a TPX transaction.
 * @param {String} transactionListPrice - the listPrice field from a TPX transaction.
 */
export const getTransactionPrice = (listing, transactionSalePrice, transactionListPrice) => {
  const { list_price: listPrice, last_sold_price: lastSoldPrice, mls_status, description } = listing || {};

  const isSoldStatus = checkIsSold(mls_status);

  if (isSoldStatus) {
    // If the listing is sold, return the salePrice or if that's not available return the lastSoldPrice or 0 if both are unavailable.
    return transactionSalePrice || lastSoldPrice || description.sold_price || 0;
  }

  // While still on market, we use the TPX transactionSalePrice if available, next we fallback to the TPX transactionListPrice,
  // otherwise we use MLS listing price. This allows the agent to make use of the salePrice field from a TPX transaction
  // to capture an agreed upon price before the property is officially sold/closed, or 0 if no prices for some reason.
  return transactionSalePrice || transactionListPrice || listPrice || 0;
};

/**
 * Return the commission given a single transaction regardless of it's status
 * @param {Object} data a single piece of transaction data
 */
export const getTransactionCommission = data => {
  const {
    listing,
    brokerSplitRate,
    commissionRateType,
    commissionFirstAmount,
    commissionFirstRate,
    commissionFlatAmount,
    commissionRemainderRate,
    listPrice,
    salePrice,
    taxRate,
    referralAmountType,
    referralAmount
  } = data;

  if (!commissionRateType) {
    return 0;
  }

  const getDecimalFromRate = rate => {
    return rate / 100;
  };

  const getSplitDecimalFromRate = rate => {
    return (100 - rate) / 100;
  };

  const getAfterTaxCommission = (preTaxCommission, taxRate) => {
    return taxRate ? (preTaxCommission * (100 - taxRate)) / 100 : preTaxCommission;
  };

  const price = getTransactionPrice(listing, salePrice, listPrice);

  const getCommissionAfterReferral = (preTaxCommission, referralAmountType, referralAmount) => {
    if (referralAmountType === 1) {
      return preTaxCommission - referralAmount;
    }
    if (referralAmountType === 2) {
      return preTaxCommission * getSplitDecimalFromRate(referralAmount);
    }
    return preTaxCommission;
  };
  // We can't use default assignment to set it to zero, so we fallback to 0 when it is null, or falsy in general;
  const cleanBrokerSplitRate = brokerSplitRate || 0;
  const splitDecimalCommission = getSplitDecimalFromRate(cleanBrokerSplitRate);

  if (commissionRateType === 1) {
    // If commissionRateType is flat (1)
    const commission = price * getDecimalFromRate(commissionRemainderRate);
    const preTaxCommission = commission * splitDecimalCommission;
    const preTaxCommissionAfterReferral = getCommissionAfterReferral(
      preTaxCommission,
      referralAmountType,
      referralAmount
    );

    return getAfterTaxCommission(preTaxCommissionAfterReferral, taxRate);
  } else if (commissionRateType === 2 && commissionFirstAmount && commissionFirstRate) {
    // If commissionRateType is first and remainder (2)
    const remainder = price - commissionFirstAmount;
    if (remainder <= 0) {
      return 0;
    }

    const firstCalculated = commissionFirstAmount * getDecimalFromRate(commissionFirstRate);
    const remainderCalculated = remainder * getDecimalFromRate(commissionRemainderRate);

    const preTaxCommission = (firstCalculated + remainderCalculated) * splitDecimalCommission;

    const preTaxCommissionAfterReferral = getCommissionAfterReferral(
      preTaxCommission,
      referralAmountType,
      referralAmount
    );
    return getAfterTaxCommission(preTaxCommissionAfterReferral, taxRate);
  } else if (commissionRateType === 3 && commissionFlatAmount) {
    const commission = commissionFlatAmount;
    const preTaxCommission = commission * splitDecimalCommission;
    const preTaxCommissionAfterReferral = getCommissionAfterReferral(
      preTaxCommission,
      referralAmountType,
      referralAmount
    );
    return getAfterTaxCommission(preTaxCommissionAfterReferral, taxRate);
  }

  return 0;
};

/**
 * Calculate the total commission for both last 365 days and since start of year
 * @param {Object} data transaction data from redux
 * @param {Object} entities entities to be filtered from
 */
export const getTotalCommission = (data, entities) => {
  const getCommissionDate = data => {
    const { closingDate, listingDate, listing } = data;

    const parsedClosingDate = parseISODate(closingDate);
    const parsedListingDate = parseISODate(listing?.source?.contract_date || listingDate);

    return parsedClosingDate || parsedListingDate;
  };

  const commission = data
    ? data.reduce((acc, curr) => {
        if (entities[curr] == null) {
          return acc;
        }

        const entity = entities[curr];

        const dateForCommission = getCommissionDate(entity);

        if (dateForCommission) {
          return acc + getTransactionCommission(entity);
        }

        return acc;
      }, 0)
    : 0;

  return commission;
};

/**
 * Returns a list of transaction id for this contacts
 * @param {String} contactId
 * @param {Object} entities
 */
export const getContactTransaction = (contactId, entities) => {
  // First sorted by status, then by closing date
  const sorted = [...Object.values(entities)].sort((a, b) => a.statusId - b.statusId || a.closingDate - b.closingDate);

  const transaction = [];
  const url = [];
  sorted.map(item => {
    const { transactionParties, transactionId, listing } = item;
    const { href } = listing || {};
    // Match transactions based on transaction parties
    if (!transactionParties || transactionParties.length === 0) {
      return;
    }
    transactionParties.map(party => {
      const { contactId: partyContactId } = party;
      if (contactId === partyContactId) {
        transaction.push(transactionId);
        url.push(href);
        return;
      }
    });
  });

  return [transaction, url];
};

/**
 * Since we fetch transaction parties separately, this function preserve preloaded ones from the state
 * @param {Object} entities from Redux state`
 * @param {Object} newEntities
 */
export const preserveTransactionParties = (entities, newEntities) => {
  return (
    newEntities &&
    Object.keys(newEntities).reduce((acc, key) => {
      const oldEntity = entities[key];
      const newentity = newEntities[key];
      if (oldEntity) {
        // newEntities from BE will have transactionParties being null
        // safe to replace here
        return {
          ...acc,
          [key]: {
            ...newentity,
            transactionParties: oldEntity?.transactionParties
          }
        };
      }

      return {
        ...acc,
        [key]: newentity
      };
    }, {})
  );
};

/**
 * Gets an array of property statuses from the TPX transaction status and raw MLS status (as they can be different).
 * @param {String} tpxStatus
 * @param {String} rawStatus
 */
export const getPropertyStatuses = (tpxStatus, rawStatus) => {
  if (!tpxStatus) {
    return [rawStatus];
  }

  if (!rawStatus || tpxStatus.toLowerCase() === rawStatus.toLowerCase()) {
    return [tpxStatus];
  }

  return [tpxStatus, `MLS ${rawStatus}`];
};

export const didDatesChange = (entity, dateFields) => {
  return (
    Boolean(entity) &&
    Object.keys(dateFields).some(field => {
      // let's default all falsey dates to empty strings
      // to keep it normalized against the date input fields
      // We use a FAKE_FUTURE_DATE to test null values so we don't pass a string OR null.
      const FAKE_FUTURE_DATE = '2200-01-01';
      const entityValue = parseISODate(entity[field] || FAKE_FUTURE_DATE);
      const fieldDateValue = parseISODate(dateFields[field].date.value || FAKE_FUTURE_DATE);

      // we want to return true if the original
      // data doesn't match with the one in the form
      return !isSameDay(entityValue, fieldDateValue);
    })
  );
};

export const didStopReminderChange = (entity, current) => {
  return Boolean(entity) && entity.stopReminder !== current;
};

/**
 * BE supported repType
 * null or 0: all <- FE: Buyer T, Seller T
 * 1: buyer only
 * 2: seller only
 * 3: buyer and seller only
 * 4: buyer, or buyer and seller <- FE: buyer T, seller F
 * 5: seller, or buyer and seller <- FE: buyer F, seller T
 */
const REPRESENTATION_TYPE_FILTERS = {
  ...REPRESENTATION_TYPES,
  Buyer_OR_Buyer_and_seller: { value: 4 },
  Seller_OR_Buyer_and_seller: { value: 5 },
  All: { value: 0 }
};

/**
 * Get representation type for BE endpoint according to FE value
 * @param {Boolean} buyer
 * @param {Boolean} seller
 * @returns {Number} raw repType to send to BE, except -1 for combining 4 and 5
 */
export const getTransactionRepTypeFilter = (buyer, seller) => {
  if (buyer && !seller) {
    return REPRESENTATION_TYPE_FILTERS.Buyer_OR_Buyer_and_seller.value;
  }
  if (!buyer && seller) {
    return REPRESENTATION_TYPE_FILTERS.Seller_OR_Buyer_and_seller.value;
  }

  // return all transactions without repType filtering
  return REPRESENTATION_TYPE_FILTERS.All.value;
};

/**
 * Get buyer and seller filter boolean values from repType
 * @param {Number} repType
 * @returns {Object} buyer and seller as booleans
 */
export const getTransactionRepTypeFilterValues = repType => {
  const buyer = repType === REPRESENTATION_TYPE_FILTERS.Seller_OR_Buyer_and_seller.value.toString() ? false : true;
  const seller = repType === REPRESENTATION_TYPE_FILTERS.Buyer_OR_Buyer_and_seller.value.toString() ? false : true;

  return { buyer, seller };
};

/**
 * Get the group name for the transaction list, based on the the action options
 * Use this to generate group name to access group in redux
 * @param {Object} actionOptions options to fetch a list of transactions
 * @param {Number} [actionOptions.status] When fetching from the transaction page, id is the status, see TRANSACTION_ALL_STATUSES. Id is contactId instead when fetching from the contact.
 * @param {Boolean} [actionOptions.buyer]
 * @param {Boolean} [actionOptions.seller]
 * @param {String} [actionOptions.startDate]
 * @param {String} [actionOptions.endDate]
 * @returns {String} The name of the group based on the options passed (format: ${id}::${representationType})
 */
export const buildTransactionsGroupName = actionOptions => {
  const { status = 0, buyer, seller, startDate, endDate, source } = actionOptions || {};
  const baseQueryKey = `${status}`;
  const repType = getTransactionRepTypeFilter(buyer, seller);
  return `${baseQueryKey}::${repType.toString() || ''}::${startDate || ''}::${endDate || ''}::${source || ''}`;
};

/**
 * Check if the date range is valid, display error message for invalid range
 * @param {Object} ref ref for startDate form input
 * @param {String} startDate
 * @param {String} endDate
 * @param {Object} dispatch
 */
export const validateDateRange = (ref, startDate, endDate, dispatch) => {
  const isValid = ((start, end) => {
    // date could be empty string instead of null if directly parsed from path
    if (start == null || end == null || start === '' || end === '') {
      return true;
    }

    return sameOrBefore(new Date(start), new Date(end));
  })(startDate, endDate);

  if (!isValid) {
    // We need to dispatch a click to close the endDate calendar Popover.
    document.dispatchEvent(new Event('click'));
    // Focus on the start date input.
    ref.current.focus();
    // Show a message.
    dispatch(
      showMessage(
        {
          message: 'Invalid date range. Start date must come before end date.',
          type: 'error'
        },
        true
      )
    );
  }
  return isValid;
};

/**
 * Get sorted transaction parties for displaying tags in a particular order.
 * @param {Array} transactionParties
 */
export const getSortedTransactionParties = transactionParties => {
  if (!transactionParties) {
    return null;
  }

  return transactionParties
    .map(party => {
      const cleanRoleName = party.roleName.toLowerCase();

      // We want the roleGroups applied in order so we can sort like Primary Buyer, Buyer, Primary Seller, Seller, all other roles in alpha.
      const tagSortArray = ['primary buyer', 'buyer', 'primary seller', 'seller'];
      // If the roleName is unknown, we make the roleGroup 5.
      const roleGroup = tagSortArray.includes(cleanRoleName) ? tagSortArray.indexOf(cleanRoleName) : 5;

      return { ...party, roleGroup };
    })
    .sort((a, b) => {
      // We use the roleGroup and roleName together to do a simple sort on the tags.
      const aSort = `${a.roleGroup}${a.roleName}`;
      const bSort = `${b.roleGroup}${b.roleName}`;
      // Sorting gives us Primary Buyer, Buyer, Primary Seller, Seller, all other roles in alpha.
      return aSort.localeCompare(bSort);
    });
};

/**
 * Returns the specific transaction date corresponding to the given adjustEvent.
 * @see TASK_PLAN_ADJUSTMENTS_TRANSACTION_DATES
 *
 * @param {Object} transaction Transaction entity
 * @param {Number} adjustEvent Adjust Event @see TASK_PLAN_ADJUSTMENTS
 *
 * @returns {String} The specific transaction date corresponding to the given adjustEvent
 */
export const getTransactionDateByEvent = (transaction, adjustEvent) => {
  const adjustEventKey = TASK_PLAN_ADJUSTMENT_MAP.get(adjustEvent);
  const dateKey = TASK_PLAN_ADJUSTMENTS_TRANSACTION_DATES[adjustEventKey];
  const value = transaction?.[dateKey];

  return value;
};

/**
 * Returns a date object corresponding to the adjusted date, based on adjustEvent, adjustDirection, and adjustDays.
 * @see TASK_PLAN_ADJUSTMENTS_TRANSACTION_DATES
 *
 * @param {Object} transaction Transaction entity
 * @param {Number} adjustEvent Adjust Event @see TASK_PLAN_ADJUSTMENTS
 * @param {Number} adjustDirection Adjust Direction @see TASK_PLAN_ADJUSTMENT_DIRECTIONS
 * @param {Number|String} adjustDays Number of days to add/subtract
 * @returns {Date}
 */
export const getTransactionDateAdjusted = (transaction, adjustEvent, adjustDirection, adjustDays) => {
  const transactionDate = getTransactionDateByEvent(transaction, adjustEvent);
  if (!transactionDate) {
    return null;
  }

  const parsedTransactionDate = parseISODate(transactionDate);
  const parsedAdjustDays = adjustDays ? parseInt(adjustDays, 10) : 0;

  // same date or no days to adjust
  if (adjustDirection === TASK_PLAN_ADJUSTMENT_DIRECTIONS.on.value || !parsedAdjustDays) {
    return parsedTransactionDate;
  }

  // after date
  if (adjustDirection === TASK_PLAN_ADJUSTMENT_DIRECTIONS.after.value) {
    return addDays(parsedTransactionDate, parsedAdjustDays);
  }

  // before date
  return subDays(parsedTransactionDate, parsedAdjustDays);
};

/**
 * @param {{ representationTypeId: Number }} transaction
 * @returns {Boolean} true if representationTypeId is Buyer or Buyer_and_seller
 */
const buyerIsRepresented = transaction => {
  const { representationTypeId } = transaction || {};
  return [REPRESENTATION_TYPES.Buyer.value, REPRESENTATION_TYPES.Buyer_and_seller.value].includes(representationTypeId);
};

/**
 * @param {{ representationTypeId: Number }} transaction
 * @returns {Boolean} true if representationTypeId is Seller or Buyer_and_seller
 */
const sellerIsRepresented = transaction => {
  const { representationTypeId } = transaction || {};
  return [REPRESENTATION_TYPES.Seller.value, REPRESENTATION_TYPES.Buyer_and_seller.value].includes(
    representationTypeId
  );
};

/**
 * @param {{ transactionParties: { contactId: String, roleId: Number }[] }} transaction
 * @returns {{ contactId: String, roleId: Number }} the Primary Buyer
 */
const getPrimaryBuyer = transaction => {
  const { transactionParties = [] } = transaction || {};
  return transactionParties.find(party => party.roleId === TRANSACTION_PARTY_ROLES.Primary_buyer.value);
};

/**
 * @param {{ transactionParties: { contactId: String, roleId: Number }[] }} transaction
 * @returns {{ contactId: String, roleId: Number }} the Primary Seller
 */
const getPrimarySeller = transaction => {
  const { transactionParties = [] } = transaction || {};
  return transactionParties.find(party => party.roleId === TRANSACTION_PARTY_ROLES.Primary_seller.value);
};

/**
 * Converts a transaciton party into a contact field value
 * @param {{ contactId: String, roleId: Number }} party Party to be converted
 * @returns {{ id: String, type: 4 }} an object containing the contact id,
 *  and the entity type 4 (ENTITY_TYPES.contact)
 */
const getContactFromParty = party => {
  if (!party) {
    return null;
  }

  const { contactId: id, fullName, firstName, lastName } = party;
  // Need name to display the tags in Lookup
  const name = getDisplayName(fullName, firstName, lastName);

  return { id, type: ENTITY_TYPES.contact, name };
};

/**
 * @param {{ representationTypeId: Number, transactionParties: { contactId: String, roleId: Number }[] }} transaction
 * @returns {{ id: String, type: 4 }[]} an array of contact field values corresponding to the primary parties
 *  represented by the agent in the transaction.
 */
export const getRepresentedContacts = transaction => {
  const contacts = [];

  const primarySeller = getContactFromParty(getPrimarySeller(transaction));
  if (primarySeller && sellerIsRepresented(transaction)) {
    contacts.push(primarySeller);
  }

  const primaryBuyer = getContactFromParty(getPrimaryBuyer(transaction));
  if (primaryBuyer && buyerIsRepresented(transaction)) {
    contacts.push(primaryBuyer);
  }

  return contacts;
};

/**
 * Get dropdown list data from property type from SNAPSHOT_PROPERTY_TYPE_MAPPINGS
 */
export const getPropertyTypes = () => {
  const rawValues = Object.keys(SNAPSHOT_PROPERTY_TYPE_MAPPINGS)
    .map(item => SNAPSHOT_PROPERTY_TYPE_MAPPINGS[item].replace(/\s/g, '').split(','))
    .flat(1);
  const deduppedList = [...new Set(rawValues)];
  return deduppedList.map(data => ({
    value: data,
    title: parseSnakeCase(data)
  }));
};

/**
 * Gets the correct data to show in transaction and insight cards shown in transactions.
 * @param {Object} mlsData The MLS/Hestia data from the listing key in a transaction.
 * @param {Object} tpxData The listing related data from the manual fields of a transaction.
 */
export const getTransactionCardData = (mlsData, tpxData) => {
  const { description: mlsDescription } = mlsData || {};
  const { baths: mlsBaths, beds: mlsBeds, sqft: mlsSqft } = mlsDescription || {};
  const { baths, beds, listingDate, mlsNumber, sqft, listing } = tpxData || {};

  return {
    ...mlsData,
    mlsNumber,
    listingDate: listing?.source?.contract_date || listingDate,
    description: {
      ...mlsDescription,
      baths: baths || mlsBaths,
      beds: beds || mlsBeds,
      sqft: sqft || mlsSqft
    }
  };
};

/**
 * Checks whether a agent can edit a transaction
 * @param {Boolena} userInfo from userProfile/userInfo, containing flags to check
 * @param {Number} status transaction status id
 */
export const isTransactionEditable = (userInfo, status) => {
  // Allow the following:
  // 1. single agent account
  // 2. single agent account with assistant -> only allow the "agent"
  // 3. partnership account -> allow the RA
  const { isResponsibleAgent, isAssistant, isTeamOrPartnershipAccount } = userInfo || {};

  if (isResponsibleAgent == null || status == null) {
    return false;
  }
  // All agents should be able to edit 'Coming soon - non MLS'
  if (status === 8) {
    return true;
  }

  const canEditClosedTransaction =
    (isTeamOrPartnershipAccount && isResponsibleAgent) || (!isTeamOrPartnershipAccount && !isAssistant);
  return canEditClosedTransaction ? status <= NON_ACTIVE_FLAG : status < NON_ACTIVE_FLAG;
};

/**
 * Converts the array of transactions from store into an object in entities form, mostly for EmailForm.
 * @param {Array} transactions the array of transactions
 * @returns {Object} the object in entities form.
 */
export const convertTransactionsToTransactionPartyEntities = transactions => {
  const transactionParties = Object.keys(transactions).reduce((acc, transaction) => {
    return [...acc, ...transactions[transaction].transactionParties];
  }, []);
  return transactionParties.reduce(
    (acc, party) => ({
      ...acc,
      [party.contactId]: {
        party,
        fullNameFirstNameFirst: party.fullNameFirstNameFirst || `${party.firstName} ${party.lastName}`,
        emails: [{ isUnsubscribed: party.isUnsubscribed, value: party.emailAddress }]
      }
    }),
    {}
  );
};

/**
 * This function does an async fetch for up to 100 notes and returns them as an array,
 * for the purpose of allowing the detailed transaction report button to print most transaction notes.
 * Mostly copied over directly from the existing getNotes action.
 * @param {Object} options The fetch options.
 * @returns {Object} The fetched tasks in array format.
 **/
export const getTransactionReportNotes = async options => {
  const { group, dir = 2, entity, forceRefresh = true, id = '', key = '', page_size = 100 } = options || {};
  const searchRequest = entity ? { searchTags: [entity] } : { all: group !== 'myNotes' };

  const requestOptions = {
    apiServiceType: 'note',
    forceRefresh,
    method: 'POST',
    path: 'list',
    payload: { dir, id, key, page_size, searchRequest }
  };

  const notesRequest = await request(requestOptions);

  if (!notesRequest) {
    return;
  }

  const { data } = notesRequest;

  const notePromises = data.records.map(note => {
    const options = {
      apiServiceType: 'note',
      forceRefresh,
      path: `details/${note.id}`,
      shouldBeCached: false
    };
    return request(options);
  });

  return Promise.all(notePromises).then(res => res.map(e => e.data));
};

/**
 * Get a transaction's assigned agents in a team account
 * @param {string} status transaction status id
 * @returns {Object} The fetched tasks in array format.
 */
export const getTransactionReportAgents = async options => {
  const { transactionId, shouldBeCached = false, forceRefresh = false } = options;

  const fetchOptions = {
    baseUrlKey: 'api',
    path: `transactions/${transactionId}/agents`,
    shouldBeCached,
    forceRefresh
  };

  const response = await request(fetchOptions);

  const { data } = response || [];

  return data;
};
