/** @module */

import { showMessage } from '../actions/message';
import { PREFS_SAVE } from '../reducers/preferences';
import { request, requestError } from '../utils';

/**
 * Gets the user's preferences from the generic settings API.
 * If success, the preferences are stored in Redux.
 * If fails, shows error message via <Toast />.
 */
export const getPreferences = () => {
  return async dispatch => {
    const options = {
      apiServiceType: 'userProfile',
      method: 'GET',
      params: { feature: 'preferences' },
      path: 'users/current/settings',
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (!data.value) {
        // If the user has no preferences, we return out.
        return;
      }

      dispatch({
        type: PREFS_SAVE,
        data: JSON.parse(data.value)
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Saves the user's preferences from the generic settings API.
 * If success, saves the preferences.
 * If fails, shows error message via <Toast />.
 * @param {Object} preferences - data object which includes the whole, newly updated preferences object.
 * @param {Object} prevPreferences - data object which includes the previous preferences object.
 * @param {Boolean} silent - boolean which determines if preferences saved message should be disabled.
 */
export const savePreferences = (preferences, prevPreferences, silent = false) => {
  return async dispatch => {
    const options = {
      apiServiceType: 'userProfile',
      method: 'POST',
      path: 'users/current/settings',
      payload: { feature: 'preferences', value: JSON.stringify(preferences) },
      shouldBeCached: false
    };

    // We optimistically dispatch the preference save.
    dispatch({
      type: PREFS_SAVE,
      data: preferences
    });

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (!silent) {
        dispatch(showMessage({ message: 'Preference saved successfully.', type: 'success' }, true));
      } // useTimer set to true

      return true;
    } catch (error) {
      // If it errors, we fallback to the prevPreferences.
      dispatch({
        type: PREFS_SAVE,
        data: prevPreferences
      });

      requestError(error, dispatch);

      return false;
    }
  };
};
