import React, { useEffect, useRef, useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { REGEX } from '../../constants';
import {
  Button,
  Dialog,
  DialogHeader,
  Form,
  FormFieldset,
  FormFooter,
  FormLabel,
  FormMessage,
  FormTextInput
} from '../../components';
import { savePreferences } from '../../actions/preferences';

import styles from './FollowUpReminderModal.css';

const FollowUpReminderModal = props => {
  const { clearHandler, contactListId, currentTabLabel, currentDays, isOpen } = props;

  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const preferences = useSelector(state => state.preferences);

  const [hasInitialValue] = useState(currentDays);
  const [numberOfDays, setNumberOfDays] = useState(currentDays);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formIsValid, setFormIsValid] = useState(true);

  useEffect(() => {
    setNumberOfDays(currentDays);
    inputRef.current.focus();
  }, [contactListId, currentDays, isOpen]);

  if (!isOpen) {
    return null;
  }

  const resetForm = () => {
    setFormIsValid(true);
    setFormIsSubmitting(false);
  };

  const handleClear = () => {
    resetForm();
    clearHandler();
  };

  const dispatchSavePreferences = newPrefs => {
    dispatch(savePreferences(newPrefs, preferences)).then(() => {
      handleClear();
    });
  };

  const handleChange = e => {
    const { target } = e;
    const { value } = target;

    if (!formIsValid) {
      setFormIsValid(true);
    }

    setNumberOfDays(value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setFormIsSubmitting(true);

    if (!numberOfDays || !REGEX.IS_NUMERIC.test(numberOfDays) || numberOfDays === '0') {
      setFormIsValid(false);
      setFormIsSubmitting(false);
      inputRef.current.focus();
      return;
    }

    const newPrefs = {
      ...preferences,
      followUpReminders: {
        ...preferences.followUpReminders,
        [contactListId]: parseInt(numberOfDays) // should be a number
      }
    };

    dispatchSavePreferences(newPrefs);
  };

  const handleDelete = () => {
    const newPrefs = {
      ...preferences
    };

    delete newPrefs.followUpReminders[contactListId];

    dispatchSavePreferences(newPrefs);
  };

  const validationMessage = formIsValid ? null : 'Number of days required.';

  return (
    <Dialog isOpen={isOpen}>
      <DialogHeader
        title={`Set follow-up reminder for ${currentTabLabel}`}
        icon="reminder"
        clearHandler={handleClear}
      />
      <Form id="followUpReminderForm">
        <FormFieldset label="Remind me if last&nbsp;touch is more than">
          <FormMessage message={validationMessage} className={styles.validationMessage} />
          <FormTextInput
            id="days"
            inputMode="numeric"
            onChange={handleChange}
            ref={inputRef}
            showInvalid={!formIsValid}
            size="xs"
            value={numberOfDays}
          />
          <FormLabel htmlFor="days" className={styles.label}>
            days ago
          </FormLabel>
        </FormFieldset>
      </Form>
      <FormFooter loading={formIsSubmitting}>
        {hasInitialValue && (
          <Button
            label="Delete"
            ariaLabel="Delete follow-up reminder"
            icon="delete"
            onClick={handleDelete}
            styleType="white"
          />
        )}
        <Button
          label="Save Follow-up Reminder"
          ariaLabel="Save follow-up reminder."
          type="submit"
          styleType="primary"
          form="followUpReminderForm"
          onClick={handleSubmit}
        />
      </FormFooter>
    </Dialog>
  );
};

FollowUpReminderModal.propTypes = {
  clearHandler: func.isRequired,
  contactListId: string.isRequired,
  currentDays: number,
  currentTabLabel: string,
  isOpen: bool
};

export default FollowUpReminderModal;
