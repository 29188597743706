import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './FormMessage.css';

export const FormMessage = props => {
  const { className, message } = props;

  if (!message) {
    return null;
  }

  const classes = classnames({
    [styles.message]: !className,
    [className]: !!className
  });

  return (
    <div role="alert" className={classes}>
      {message}
    </div>
  );
};

FormMessage.propTypes = {
  children: PropTypes.string
};
