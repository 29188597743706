/** @module */

export const CAMPAIGN_LOADING = 'CAMPAIGN_LOADING';
export const CAMPAIGN_SET = 'CAMPAIGN_SET';
export const CAMPAIGN_CHECK_SET = 'CAMPAIGN_CHECK_SET';
export const CAMPAIGN_CHECK_SET_INIT = 'CAMPAIGN_CHECK_SET_INIT';
export const CAMPAIGN_LAST_UPDATED = 'CAMPAIGN_LAST_UPDATED';
export const CAMPAIGN_UPDATE_CITIES = 'CAMPAIGN_UPDATE_CITIES';
export const CAMPAIGN_SET_SKIP_MLS = 'CAMPAIGN_SET_SKIP_MLS';

export const initialState = {
  isLoading: false,
  campaign: {},
  isActive: null,
  lastUpdated: null,
  lastAction: null,
  cities: [],
  skipMls: false
};

/**
 * The social connect redux reducer, including campaign data
 * @param {Object} state - the current state of the social connect store.
 * @param {Object} action - the action to take on the social connect store
 * @param {String} [action.type=default] - the action to take.
 * @param {Array} [action.payload]
 * @param {String} [action.group] - Acts as a key when storing a team.
 */
export const socialConnectReducer = (state = initialState, action = {}) => {
  const { type, payload, isActive, lastUpdated, lastAction, cities, isLoading, skipMls } = action;

  switch (type) {
    case CAMPAIGN_SET:
      return {
        ...state,
        campaign: payload,
        isLoading: false,
        lastUpdated
      };
    case CAMPAIGN_LOADING:
      return {
        ...state,
        isLoading
      };
    case CAMPAIGN_CHECK_SET:
      return {
        ...state,
        isActive
      };
    case CAMPAIGN_LAST_UPDATED:
      return {
        ...state,
        lastAction
      };
    case CAMPAIGN_UPDATE_CITIES:
      return {
        ...state,
        cities
      };
    case CAMPAIGN_CHECK_SET_INIT:
      return {
        ...state,
        campaign: null,
        isActive: false
      };
    case CAMPAIGN_SET_SKIP_MLS:
      return {
        ...state,
        skipMls
      };
    default:
      return state;
  }
};
