import { combineReducers } from 'redux';
import { clearLocalStorage } from '../utils';
import { alertReducer } from './alert';
import { aiAuthorReducer } from './aiAuthor';
import { authReducer, AUTH_LOGOUT, AUTH_LOGOUT_SERVER_OFFLINE, RESET_ENTITY_DATA } from './authentication';
import { calendarReducer } from './calendar';
import { calendarSyncReducer } from './calendarSync';
import { contactReducer } from './contacts';
import { contactSyncReducer } from './contactSync';
import { dialogReducer } from './dialog';
import { drawerReducer } from './drawer';
import { emailReducer } from './email';
import { envReducer } from './environment';
import { fullContactReducer } from './fullContact';
import { geoPropertiesReducer } from './geoProperties';
import { importantDatesReducer } from './importantDates';
import { listingAlertsReducer } from './listingAlerts';
import { messageReducer } from './message';
import { mlsReducer } from './mls';
import { noteReducer } from './notes';
import { prefsReducer } from './preferences';
import { propertyInsightsReducer } from './propertyInsights';
import { salesforceReducer } from './salesforce';
import { serverStatusReducer } from './serverStatus';
import { settingsReducer } from './settings';
import { setupUserReducer } from './setupUser';
import { snapshotReducer } from './snapshot';
import { ssoReducer } from './sso';
import { subscriptionsReducer } from './subscriptions';
import { taskReducer } from './tasks';
import { taskPlansReducer } from './taskPlans';
import { teamReducer } from './team';
import { templatesReducer } from './templates';
import { textsReducer } from './texts';
import { transactionDocumentsReducer } from './transactionDocuments';
import { transactionsReducer } from './transactions';
import { userProfileReducer } from './userProfile';
import { wrapupReducer } from './wrapup';
import { photoViewerReducer } from './photoViewer';
import { propertiesReducer } from './properties';
import { reCaptchaReducer } from './reCaptcha';
import { cirrusPropertiesReducer } from './cirrusProperties';
import { relationshipsReducer } from './relationships';
import { smartzipReducer } from './smartzip';
import { zillowReducer } from './zillow';
import { snapshotPrefsReducer } from './snapshotPreferences';
import { leadAlertReducer } from './leadAlert';
import { socialConnectReducer } from './socialConnect';
import { advancedSearchReducer } from './advancedSearch';
import { dashboardReducer } from './dashboard';
import { taskReminderReducer } from './taskReminder';
import { homeIntelReducer } from './homeintel';
import { adminReducer } from './admin';

const appReducer = combineReducers({
  advancedSearch: advancedSearchReducer,
  aiAuthor: aiAuthorReducer,
  alert: alertReducer,
  calendar: calendarReducer,
  calendarSync: calendarSyncReducer,
  contacts: contactReducer,
  contactSync: contactSyncReducer,
  cirrusProperties: cirrusPropertiesReducer,
  dashboard: dashboardReducer,
  dialog: dialogReducer,
  drawer: drawerReducer,
  emails: emailReducer,
  environment: envReducer,
  fullContact: fullContactReducer,
  geoProperties: geoPropertiesReducer,
  homeIntel: homeIntelReducer,
  importantDates: importantDatesReducer,
  leadAlert: leadAlertReducer,
  listingAlerts: listingAlertsReducer,
  message: messageReducer,
  mls: mlsReducer,
  notes: noteReducer,
  photoViewer: photoViewerReducer,
  preferences: prefsReducer,
  properties: propertiesReducer,
  propertyInsights: propertyInsightsReducer,
  reCaptcha: reCaptchaReducer,
  relationships: relationshipsReducer,
  salesforce: salesforceReducer,
  serverStatus: serverStatusReducer,
  settings: settingsReducer,
  setupUser: setupUserReducer,
  smartzip: smartzipReducer,
  snapshot: snapshotReducer,
  snapshotPreferences: snapshotPrefsReducer,
  socialConnect: socialConnectReducer,
  sso: ssoReducer,
  subscriptions: subscriptionsReducer,
  taskPlans: taskPlansReducer,
  tasks: taskReducer,
  team: teamReducer,
  templates: templatesReducer,
  texts: textsReducer,
  transactions: transactionsReducer,
  transactionDocuments: transactionDocumentsReducer,
  user: authReducer,
  admin: adminReducer,
  userProfile: userProfileReducer,
  wrapup: wrapupReducer,
  zillow: zillowReducer,
  taskReminder: taskReminderReducer
});

export const reducers = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    // we clear localStorage and all the cached request identifiers prior to returning the app's initial logged out state
    clearLocalStorage();
    state = undefined;
  }

  if (action.type === RESET_ENTITY_DATA) {
    // We destructure to unused vars the entity keys in state that we need to purge when resetting entity data.
    const { contacts, importantDates, notes, tasks, ...loggedInState } = state;
    state = { ...loggedInState };
  }

  if (action.type === AUTH_LOGOUT_SERVER_OFFLINE) {
    // By not clearring serverStatus, users won't get redirectly to the root page
    // from the the maintenance page after logging out due to server offline.
    clearLocalStorage();
    const { serverStatus } = state;
    state = { serverStatus };
  }

  return appReducer(state, action);
};
