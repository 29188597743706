import React from 'react';
import { ViewHeader } from '../ViewHeader';
import { Button } from '../Button';
import { SOCIAL_CONNECT_LINK } from '../../constants';
import documentStyles from '../Document/Document.css';
import styles from './SocialConnect.css';
import { bool } from 'prop-types';
import '../../assets/real-estate-leads-social-connect.png';

const SocialConnectSetupLanding = props => {
  const { isInSetupMode = false } = props;

  return (
    <>
      <ViewHeader title="Social Connect" titleAs="h1" titleIcon="facebook" />
      <img src="../../assets/real-estate-leads-social-connect.png" className={styles.img} />

      {isInSetupMode ? (
        <>
          <h2 className={documentStyles.h2}>It looks like you haven't set up Social Connect yet...</h2>

          <Button
            ariaLabel="Set Up Social Connect Now"
            label="Set Up Social Connect Now"
            styleType="primary"
            url={'/marketing/social-connect'}
            data-cy="smartTargetingLearnMore"
          />
        </>
      ) : (
        <>
          <h2 className={documentStyles.h2}>Need an Affordable Way to Grow Your Database?</h2>
          <p className={documentStyles.p}>
            With Social Connect, you can consistently grow your contact database with a steady stream of exclusive
            leads—<strong>30 leads/mo for just $300/mo!</strong>
          </p>
          <ul className={documentStyles.ul}>
            <li className={documentStyles.li}>Eliminate peaks & valleys—get a high volume of leads, every month.</li>
            <li className={documentStyles.li}>
              Leads are consumers who’ve shown interest in real estate in your local market.
            </li>
            <li className={documentStyles.li}>
              Done-for-you lead generation: Experts create and optimize your campaigns.
            </li>
            <li className={documentStyles.li}>
              Leads automatically & immediately engaged on your behalf—they receive valuable real estate info, making
              you their go-to resource.
            </li>
          </ul>

          <Button
            ariaLabel="Get More Leads"
            externalLink
            label="Get More Leads"
            styleType="primary"
            url={SOCIAL_CONNECT_LINK}
            data-cy="smartTargetingLearnMore"
          />
        </>
      )}
    </>
  );
};

SocialConnectSetupLanding.propTypes = {
  isInSetupMode: bool
};

export default SocialConnectSetupLanding;
