import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, FormField, FormLabel, FormRow } from '../';
import { MIN_BEDROOMS, MIN_BATHROOMS, MIN_PRICE, MAX_PRICE } from '../../data/snapshot';

import styles from './SnapshotForm.css';
import formFieldStyles from '../FormField/FormField.css';

export const BuyerPrefsForm = props => {
  const {
    baths,
    beds,
    minPrice,
    minPriceList = MIN_PRICE,
    maxPrice,
    maxPriceList = MAX_PRICE,
    sqrFt,
    isSeller = false,
    isSnapshot = false,
    onChange,
    disabled,
    formIsValid,
    inputRef
  } = props;

  function handleOnKeyPress(e) {
    const { charCode: code } = e;

    // ToDo: What keys are these?  We should move this to constants/KEYCODE_MAP or use isKeyHotkey.
    if ((code < 48 || code > 57) && (code < 96 || code > 105)) {
      e.preventDefault();
    }
  }

  return (
    <Fragment>
      {!isSeller && (
        <FormRow>
          <div className={formFieldStyles.field}>
            <FormLabel htmlFor="minPrice">Min Price</FormLabel>
            <Dropdown
              id="minPrice"
              items={minPriceList}
              className={styles.select}
              onChange={onChange}
              defaultValue="Select a price..."
              value={minPrice}
              disabled={disabled}
              ref={inputRef}
            />
          </div>
          <div className={formFieldStyles.field}>
            <FormLabel htmlFor="maxPrice">Max Price</FormLabel>
            <Dropdown
              id="maxPrice"
              items={maxPriceList}
              className={styles.select}
              onChange={onChange}
              defaultValue="Select a price..."
              value={maxPrice}
              disabled={disabled}
            />
          </div>
        </FormRow>
      )}
      <FormRow>
        <div className={formFieldStyles.field}>
          <FormLabel htmlFor="beds">Beds</FormLabel>
          <Dropdown
            id="beds"
            items={MIN_BEDROOMS}
            className={styles.select}
            onChange={onChange}
            defaultValue="Select beds..."
            value={beds}
            disabled={disabled}
          />
        </div>
        <div className={formFieldStyles.field}>
          <FormLabel htmlFor="baths">Baths</FormLabel>
          <Dropdown
            id="baths"
            items={MIN_BATHROOMS}
            className={styles.select}
            onChange={onChange}
            defaultValue="Select baths..."
            value={baths}
            disabled={disabled}
          />
        </div>
      </FormRow>
      {!isSnapshot && (
        <FormRow>
          <div className={formFieldStyles.field}>
            <FormField
              id="sqrFt"
              type="input"
              inputType="number"
              label="Sq.ft."
              value={sqrFt || ''}
              maxLength="32"
              onKeyPress={handleOnKeyPress}
              validationMessage="Enter valid square feet"
              fieldIsValid={!!parseInt(sqrFt)}
              formIsValid={formIsValid}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
        </FormRow>
      )}
    </Fragment>
  );
};

BuyerPrefsForm.propTypes = {
  baths: PropTypes.string,
  beds: PropTypes.string,
  minPrice: PropTypes.string,
  maxPrice: PropTypes.string,
  onChange: PropTypes.func,
  isSeller: PropTypes.bool,
  isSnapshot: PropTypes.bool
};
