/** @module */

export const GET_LISTING_ALERT_STATS = 'GET_LISTING_ALERT_STATS';
export const GET_LISTING_ALERT_STATS_SUCCESS = 'GET_LISTING_ALERT_STATS_SUCCESS';
export const SET_LISTING_ALERT_ADDITIONAL_PROPERTY_DATA = 'SET_LISTING_ALERT_ADDITIONAL_PROPERTY_DATA';

export const initialState = {
  isLoading: null,
  listingAlertsStatistics: [],
  listingAlertPropertyData: [],
  isDateRangeValid: true,
  donePaging: false,
  pageIndex: 1
};

/**
 * The message redux reducer.
 * @param {Object} state - the current state of the message store.
 * @param {Object} action - the action to take on the message store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - A data object that could be an error object or a server created exception object.
 * @param {Object} [action.useTimer] - Flag set to auto close the message toast.
 */
export const listingAlertsReducer = (state = initialState, action = {}) => {
  const { type = 'default', donePaging, listingAlertsStatistics, listingAlertPropertyData, pageIndex } = action;

  switch (type) {
    case GET_LISTING_ALERT_STATS:
      return {
        ...state,
        isLoading: true
      };
    case GET_LISTING_ALERT_STATS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        donePaging,
        pageIndex,
        listingAlertsStatistics:
          pageIndex === 1 ? listingAlertsStatistics : state.listingAlertsStatistics.concat(listingAlertsStatistics)
      };
    case SET_LISTING_ALERT_ADDITIONAL_PROPERTY_DATA:
      return {
        ...state,
        listingAlertPropertyData
      };
    default:
      return state;
  }
};
