import React from 'react';
import { bool } from 'prop-types';

import styles from './MenuButton.css';

export const MenuButtonArrow = props => {
  const { showArrow } = props;

  if (!showArrow) {
    return null;
  }

  return (
    <span className={styles.arrow} aria-hidden>
      ▾
    </span>
  );
};

MenuButtonArrow.propTypes = {
  showArrow: bool
};
