/** @module */

import { navigate } from '@reach/router';

import { ERROR_CODES } from '../constants/errorCodes';
import {
  AUTH_LOGOUT,
  AUTH_SET_USER,
  REINIT_PASSWORD_RESET,
  RESET_ENTITY_DATA,
  RESET_PASSWORD_EXPIRED,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  SEND_RESET_PASSWORD_EMAIL_FAIL,
  SEND_RESET_PASSWORD_EMAIL_REQUEST,
  SEND_RESET_PASSWORD_EMAIL_SUCCESS
} from '../reducers/authentication';
import { PREFS_SAVE } from '../reducers/preferences';
import { SALESFORCE_REQUEST_RESET } from '../reducers/salesforce';
import { request, requestError, searchAndRemoveFromStorage, setRequestDefaults } from '../utils';
import { trackEvent } from '../utils/analytics';
import { getSalesforceUrl } from './salesforce';

const HOST = process.env.HOST;

export const checkAuthCredentials = options => {
  const { loginName, needSetup, token } = options || {};

  const hasToken = token && token !== '';
  const hasLoginName = loginName && loginName !== '';

  if ((!hasToken || !hasLoginName) && !needSetup) {
    throw { message: `Authentication has failed due to missing a ${!hasToken ? 'token' : 'loginName'}.` };
  }
};

/**
 * Makes a authentication request (sign in) for the user.
 * If success, navigates the app to the contact page.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - data object from the sign in form
 * @param {String} options.name - the username value
 * @param {String} options.password - the password value
 */
export const signInUser = ({ name, password }) => {
  const lowercaseName = name.toLowerCase();

  return async dispatch => {
    const options = {
      apiServiceType: 'user',
      headers: {
        loginName: lowercaseName,
        saveActiveLog: 2
      },
      method: 'POST',
      payload: { name: lowercaseName, password },
      path: 'login',
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);

      const { exception, needSetup, isProbationAccount } = data || {};

      dispatch({ type: SALESFORCE_REQUEST_RESET });

      if (exception) {
        if ([60154, 67153].includes(exception?.id) || isProbationAccount) {
          dispatch(getSalesforceUrl({ username: lowercaseName, password }));
        }
        const signOutAction = {
          label: 'Back',
          url: '/sign-out'
        };
        if (exception) {
          throw {
            ...exception,
            action: signOutAction
          };
        }
      }

      // We check to make sure the data returned from authentication has a token and loginName.
      checkAuthCredentials(data);

      // we need to keep the request defaults (loginName, token) fresh
      setRequestDefaults(data);

      if (!needSetup && data.preferences) {
        const preferences = JSON.parse(data.preferences.value);
        if (!preferences.contactsTabs.includes('recent')) {
          preferences.contactsTabs = [...preferences.contactsTabs, 'recent'];
        }
        if (!preferences.contactsTabs.includes('selected')) {
          preferences.contactsTabs = [...preferences.contactsTabs, 'selected'];
        }
        if (!preferences.contactsTabs.includes('facebook')) {
          preferences.contactsTabs = [...preferences.contactsTabs, 'facebook'];
        }
        dispatch({ type: PREFS_SAVE, data: preferences });
      }

      dispatch({
        type: AUTH_SET_USER,
        data
      });

      return data;
    } catch (error) {
      const { id, action } = error || {};
      const needsCustomMessage = id === 60054; // Account closed error code.
      const useTimer = needsCustomMessage ? false : true;
      const customMessage = needsCustomMessage ? ERROR_CODES.get(id) : null;
      requestError(error, dispatch, useTimer, customMessage, action);

      return error;
    }
  };
};

/**
 * Cleans up all cached request identifiers from localStorage pertaining to entitty data.
 */
export const resetEntityData = () => {
  return dispatch => {
    searchAndRemoveFromStorage('leadlist', 'pageSize');
    searchAndRemoveFromStorage('contactlist', 'pageSize');
    searchAndRemoveFromStorage('contactsearch', 'pageSize');
    searchAndRemoveFromStorage('tasklist', 'pageSize');
    searchAndRemoveFromStorage('notelist', 'pagesize');
    searchAndRemoveFromStorage('importantDates', 'getnext');
    searchAndRemoveFromStorage('contactOld', 'contactType');

    dispatch({
      type: RESET_ENTITY_DATA
    });
  };
};

/**
 * Signs the user out of the application.
 * Dispatch of AUTH_LOGOUT results in localStorage being cleared by the store subscriber.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - user object for the current user.
 * @param {String} options.token - the user's auth token.
 * @param {String} options.path - the path to get redirected to after logging out.
 * @param {String} options.signOutType - the type of this sign out.
 */
export const signOutUser = options => {
  const { token, path = '/', signOutType = AUTH_LOGOUT } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'user',
      method: 'POST',
      path: 'logout',
      payload: { token },
      shouldBeCached: false
    };

    // the dispatch and navigae are outside of the try/catch because we want them to be optimistic.
    // They should always clean up state and clear storage regardless of server request success.
    dispatch({
      type: signOutType
    });
    // send the user to the home route on sign out
    navigate(path);

    if (!token) {
      return;
    }

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      // track sign out
      trackEvent('authentication', 'sign out', {
        hitCallback: () => window.location.reload(true) // We want to refresh to cleanup pollution from 3rd party scripts.
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const sendResetPasswordLink = options => {
  return async dispatch => {
    const { username, sourceApp, email } = options;

    const requestOptions = {
      apiServiceType: 'users',
      baseUrlKey: 'api',
      method: 'POST',
      path: 'resetPassword',
      payload: { username, sourceApp, email },
      shouldBeCached: false
    };

    dispatch({
      type: SEND_RESET_PASSWORD_EMAIL_REQUEST
    });

    try {
      const { status } = await request(requestOptions);

      if (status === 200) {
        dispatch({
          type: SEND_RESET_PASSWORD_EMAIL_SUCCESS
        });
      } else {
        dispatch({
          type: SEND_RESET_PASSWORD_EMAIL_FAIL,
          showTopProducerException: true
        });
      }
    } catch (error) {
      dispatch({
        type: SEND_RESET_PASSWORD_EMAIL_FAIL,
        showTopProducerException: false
      });

      requestError(error, dispatch);
    }
  };
};

export const updatePassword = options => {
  return async dispatch => {
    const { userId, token, newPassword, sourceApp } = options;

    const requestOptions = {
      apiServiceType: 'users',
      baseUrlKey: 'api',
      method: 'POST',
      path: 'updatePassword',
      payload: { userId, token, newPassword },
      shouldBeCached: false
    };

    dispatch({
      type: RESET_PASSWORD_REQUEST
    });

    try {
      await request(requestOptions);
      dispatch({ type: RESET_PASSWORD_SUCCESS });

      if (sourceApp === 'tp8i') {
        navigate(HOST);
      }
    } catch (error) {
      if (error.response.status === 410) {
        dispatch({ type: RESET_PASSWORD_EXPIRED });
      } else {
        dispatch({ type: RESET_PASSWORD_FAIL });
      }

      requestError(error, dispatch);
    }
  };
};

export const reInitPasswordReset = () => {
  return dispatch => {
    dispatch({
      type: REINIT_PASSWORD_RESET
    });
  };
};

export const backToAuth = () => {
  return dispatch => {
    dispatch(reInitPasswordReset());
    navigate('/');
  };
};
