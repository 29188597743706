/** @module */
export const CONTACT_SYNC_ACCOUNTS = 'CONTACT_SYNC_ACCOUNTS';
export const CONTACT_SYNC_ACCOUNTS_DELETE = 'CONTACT_SYNC_ACCOUNTS_DELETE';

export const initialState = {
  accounts: []
};

/**
 * The Nylas Contact Snyc redux reducer.
 * @param {Object} state - the current state of the settings store.
 * @param {Object} action - the action to take on the settings store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const contactSyncReducer = (state = initialState, action = {}) => {
  const { type } = action;

  switch (type) {
    case CONTACT_SYNC_ACCOUNTS:
      return {
        ...state,
        accounts: action.data
      };
    case CONTACT_SYNC_ACCOUNTS_DELETE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
