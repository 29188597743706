/** @module */

export const DRAWER_SET = 'DRAWER_SET';
export const DRAWER_CLEAR = 'DRAWER_CLEAR';
export const DRAWER_CLEAR_EMAIL = 'DRAWER_CLEAR_EMAIL';
export const DRAWER_MINIMIZE = 'DRAWER_MINIMIZE';

export const initialState = {
  contactId: null,
  transactionId: null,
  email: null,
  drawerType: null,
  isOpen: false,
  isMinimized: false
};

/**
 * The drawer redux reducer.
 * @param {Object} state - the current state of the drawer store.
 * @param {Object} [action.type=default] - the action to take on the drawer store
 * @param {Object} [action.data] - A data object that represents the drawer's details.
 */
export const drawerReducer = (state = initialState, action = {}) => {
  const { data, type = 'default' } = action;

  switch (type) {
    case DRAWER_SET:
      return {
        ...state,
        ...data,
        isOpen: true,
        isMinimized: false
      };
    case DRAWER_CLEAR_EMAIL:
      return {
        ...state,
        email: null
      };
    case DRAWER_MINIMIZE:
      return {
        ...state,
        isMinimized: !state.isMinimized
      };
    case DRAWER_CLEAR:
      return initialState;
    default:
      return state;
  }
};
