import React, { useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import composeRefs from '@seznam/compose-react-refs';
import styles from './Popover.css';

const POSITION_BUFFER = 8;

const getAbsolutePosition = (openerOffset, rect) => {
  if (!openerOffset || !rect) {
    return null;
  }

  const { top, bottom, left } = openerOffset;

  // ToDo: This needs to be extended to support other positions (this case is hard coded for bottomLeft and when bottomLeft goes offscreen.)

  const isCollidingWithBottom = window.innerHeight - bottom < rect.height;

  if (isCollidingWithBottom) {
    return {
      top: top - POSITION_BUFFER - rect.height,
      left
    };
  }

  return {
    top: bottom + POSITION_BUFFER,
    left
  };
};

export const Popover = React.forwardRef((props, ref) => {
  const { children, isOpen, openerOffset, position = 'bottomLeft', className } = props;
  const innerRef = useRef(null);
  const [absolutePosition, setAbsolutePosition] = useState(null);

  useLayoutEffect(() => {
    if (!isOpen) {
      return;
    }

    const rect = innerRef.current.getBoundingClientRect();
    setAbsolutePosition(getAbsolutePosition(openerOffset, rect));
  }, [innerRef, isOpen, openerOffset]);

  if (!children || !isOpen) {
    return null;
  }

  const classes = classnames({
    [styles.popover]: !className,
    [styles[position]]: !!position,
    [className]: !!className
  });

  const portalTarget = document.getElementById('app') || document.body;

  return ReactDOM.createPortal(
    <div className={classes} style={absolutePosition} ref={composeRefs(ref, innerRef)}>
      {children}
    </div>,
    portalTarget
  );
});

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isOpen: PropTypes.bool,
  openerOffset: PropTypes.object,
  position: PropTypes.oneOf(['topLeft', 'topMiddle', 'topRight', 'bottomLeft', 'bottomMiddle', 'bottomRight', 'adv']),
  className: PropTypes.string
};
