// Don't change the order of the following two imports
import '!style-loader!css-loader!react-responsive-carousel/lib/styles/carousel.min.css';
import '!style-loader!css-loader!./overrides.css';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import { MARKETING_CAROUSEL_DATA } from '../../data/marketing-carousel';

import { MarketingCard } from '../Card/MarketingCard';

export const MarketingCarousel = () => {
  return (
    <Carousel
      autoPlay={true}
      centerMode={false}
      infiniteLoop={true}
      interval={4000}
      showArrows={false}
      showIndicators={true}
      showStatus={false}
      showThumbs={false}
      stopOnHover={true}
      swipeable={true}
    >
      {MARKETING_CAROUSEL_DATA.map(item => {
        return <MarketingCard data={item} key={item.title} />;
      })}
    </Carousel>
  );
};
