import classnames from 'classnames';
import Downshift from 'downshift';
import { array, arrayOf, bool, object, string, func } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { deepEqual } from 'fast-equals';

import { Button, FormTextInput, Icon, NoResultsItem, Tag, TagGroup } from '../';
import { addContactType } from '../../actions/contacts';
import { deleteFromCollection } from '../../utils';
import dropdownStyles from '../Dropdown/Dropdown.css';
import searchStyles from '../Search/Search.css';
import styles from './Lookup.css';

const NoResults = props => {
  const {
    inputValue,
    handleEntityCreationClick,
    handleMenuClose,
    hovered,
    isEntityCreationEnabled,
    label,
    ...itemProps
  } = props;

  if (isEntityCreationEnabled) {
    const classes = classnames({
      [dropdownStyles.item]: true,
      [dropdownStyles.hovered]: hovered
    });

    return (
      <li className={classes} {...itemProps}>
        <span className={styles.entityCreationText}>
          Add <Tag label={inputValue} /> as a new {label}?
        </span>
        <Button label="Add" ariaLabel={`Add ${label}`} styleType="primary" />
      </li>
    );
  }

  return <NoResultsItem />;
};

export class LookupBasic extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = this.getInitialState();
  }

  static defaultProps = {
    isMultiSelect: true
  };

  getInitialState = () => {
    const { entities, id, searchKey, searchList } = this.props;

    return {
      search: '',
      selectedEntities: !entities
        ? {}
        : entities.reduce((acc, entity) => {
            // For updating hestia MS, use state and city to filter
            // Cities and schools data from MS in HestiasnapshotForm need special treatment for initial props.
            if (id === 'extCityField') {
              acc[entity.city] =
                searchList.find(item => item[searchKey] === entity.city && item?.state_code === entity.state_code) ||
                {};
            } else if (id === 'extSchoolField') {
              acc[entity] = searchList.find(item => item.id === entity) || {};
            } else {
              // Sometimes, we don't have all the data.
              // For instance, contact types can be assigned, but not be in the assignable list.
              // We need to make sure we fallback with the OR scenario below.
              acc[entity] = searchList.find(item => item[searchKey] === entity) || {
                [searchKey]: entity,
                name: entity
              };
            }
            return acc;
          }, {})
    };
  };

  handleChange = e => {
    const { target } = e;
    this.setState({
      search: target.value
    });
  };

  handleMenuClick = selectedItem => {
    const { addContactType, isMultiSelect, searchGroup, searchKey, addTagType } = this.props || {};
    const { selectedEntities } = this.state;
    if (!selectedEntities[searchKey] && selectedItem) {
      // allow adding contact type if the lookup allows isMultiSelect.
      if (isMultiSelect) {
        if (searchKey === 'contactType' && searchGroup && !searchGroup.includes(selectedItem[searchKey])) {
          addContactType(selectedItem);
        } else if (addTagType) {
          addTagType(selectedItem);
        }
      }

      this.setState({
        search: '',
        selectedEntities: isMultiSelect
          ? {
              ...selectedEntities,
              [selectedItem[searchKey]]: selectedItem
            }
          : {
              [selectedItem[searchKey]]: selectedItem
            }
      });
    }
  };

  itemToString = item => {
    return item ? item.value : '';
  };

  handleTagDelete = e => {
    const { selectedEntities } = this.state;

    this.setState({
      selectedEntities: deleteFromCollection(selectedEntities, e.target.dataset.id)
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { id, key, changeHandler, entities, formIsSubmitting } = this.props;
    const { entities: prevEntities, formIsSubmitting: prevFormIsSubmitting } = prevProps;
    const { selectedEntities } = this.state;
    const { selectedEntities: prevSelectedEntities } = prevState;

    if (!deepEqual(selectedEntities, prevSelectedEntities)) {
      changeHandler(selectedEntities, id);
    }

    /*
      We reset the state when:
        1- the key changes
        2- the parent form was submitted
        3- the prop entities don't match the prevPropEntities, while the entities prop doesn't match the selectedEntities
    */
    if (
      prevProps.key !== key ||
      (prevFormIsSubmitting && !formIsSubmitting) ||
      (!deepEqual(entities, selectedEntities) && entities !== prevEntities)
    ) {
      this.setState(this.getInitialState());
    }
  }

  render() {
    const {
      fieldIsValid,
      formIsValid,
      id,
      isEntityCreationEnabled,
      searchKey = 'name',
      searchList,
      inputRef,
      isMultiSelect,
      tagColor,
      label = 'contact type'
    } = this.props || {};
    const { search, selectedEntities } = this.state;

    const list =
      searchList &&
      searchList.reduce((acc, item) => {
        if (item[searchKey].toLowerCase().includes(search.toLowerCase())) {
          acc.push(item);
        }

        return acc;
      }, []);

    // isEntityCreationEnabled == false, partial compare to show no result
    const showNoResults =
      search.toLowerCase().length > 0 &&
      list?.find(item => {
        return isEntityCreationEnabled
          ? item[searchKey].toLowerCase() === search.toLowerCase()
          : item[searchKey].toLowerCase().includes(search.toLowerCase());
      }) === undefined;

    const showInvalid = formIsValid === false && fieldIsValid === false;

    const menuClasses = classnames({
      [dropdownStyles.menu]: true,
      [styles.menuEntityCreation]: showNoResults && isEntityCreationEnabled,
      [dropdownStyles.menuNoResults]: showNoResults && !isEntityCreationEnabled
    });
    const hasSelectedEntities = selectedEntities && Object.keys(selectedEntities).length > 0;
    const showLookupField = isMultiSelect || (!isMultiSelect && !hasSelectedEntities);

    return (
      <Fragment>
        {selectedEntities && (
          <TagGroup className={styles.tagGroup}>
            {Object.keys(selectedEntities).map(id => {
              const entity = selectedEntities[id];

              return (
                <Tag
                  key={id}
                  entityId={id}
                  label={entity[searchKey]} // we need to support contacts and tasks
                  removable={true}
                  size="l"
                  handleRemove={this.handleTagDelete}
                  color={tagColor}
                />
              );
            })}
          </TagGroup>
        )}
        {showLookupField && (
          <Downshift inputValue={search} onSelect={this.handleMenuClick} itemToString={this.itemToString}>
            {({ getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex, openMenu, closeMenu }) => {
              return (
                <div className={searchStyles.field}>
                  <FormTextInput
                    id={id}
                    name={id}
                    type="search"
                    placeholder="Search to add..."
                    ref={inputRef}
                    className={styles.searchInput}
                    showInvalid={showInvalid}
                    {...getInputProps({
                      onChange: this.handleChange,
                      onClick: openMenu
                    })}
                  />
                  <Icon name="search" size="s" className={searchStyles.icon} />
                  {isOpen && list && (
                    <ul {...getMenuProps()} className={menuClasses}>
                      {showNoResults && (
                        <NoResults
                          inputValue={search}
                          handleEntityCreationClick={this.handleMenuClick}
                          handleMenuClose={closeMenu}
                          hovered={highlightedIndex === 0}
                          isEntityCreationEnabled={isEntityCreationEnabled}
                          label={label}
                          {...getItemProps({ item: { [searchKey]: search } })}
                        />
                      )}
                      {list.map((item, index) => {
                        // When checking if the item should show selected, we first check the searchKey, but fall back to the id.
                        const isSelected = selectedEntities[item[searchKey]] || selectedEntities[item.id];

                        const displayName = item[searchKey];

                        // Offset for no result
                        const newIndex = index + 1;

                        const classes = classnames({
                          [dropdownStyles.item]: true,
                          [dropdownStyles.hovered]: highlightedIndex === newIndex,
                          [styles.selected]: isSelected
                        });

                        return (
                          <li
                            className={classes}
                            {...getItemProps({
                              key: `${item[searchKey]}-${newIndex}`,
                              index: newIndex,
                              item
                            })}
                          >
                            {displayName} {isSelected && <Icon name="check" size="s" isColored={true} />}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              );
            }}
          </Downshift>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  addContactType
};

export default connect(null, mapDispatchToProps)(LookupBasic);

LookupBasic.propTypes = {
  changeHandler: func,
  className: string,
  entities: array,
  fieldIsValid: bool,
  formIsValid: bool,
  id: string,
  isEntityCreationEnabled: bool,
  isMultiSelect: bool,
  searchGroup: arrayOf(string),
  searchKey: string.isRequired,
  searchList: arrayOf(object),
  tagColor: string
};
