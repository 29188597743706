import throttle from 'lodash/throttle';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';

import { reducers } from './reducers';
import { getLocalStorageItem, setLocalStorageItem, setRequestDefaults } from './utils';

const STORE_THROTTLE_DURATION = 1000;

/**
 * Configures the redux store for the app using redux-thunk middleware.
 * Sets up a subscriber to save the store in localStorage and reuse it on reload of the page.
 */
export const configureStore = () => {
  const middleware = [thunk];
  const persistedState = getLocalStorageItem('state');

  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25
  });

  const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(...middleware)));

  // Before mounting the app, we need to ensure the request defaults are set
  setRequestDefaults(store.getState().user);

  // everytime the store updates (throttled), we save the state to localStorage
  store.subscribe(
    throttle(() => {
      setLocalStorageItem('state', store.getState());
    }, STORE_THROTTLE_DURATION)
  );

  return store;
};
