/** @module */

import {
  API_HOST,
  API_TMK_HOST,
  BUILD_VERSION,
  ENV,
  GIT_SHA,
  LOCAL_STORAGE_VERSION,
  PUBLIC_HOST,
  SVC_HOST
} from '../constants';

export const initialState = {
  env: ENV,
  publicHost: PUBLIC_HOST,
  svcHost: SVC_HOST,
  apiHost: API_HOST,
  tmkHost: API_TMK_HOST,
  buildVer: BUILD_VERSION,
  gitSha: GIT_SHA,
  localStorageVer: LOCAL_STORAGE_VERSION
};

/**
 * The environment redux reducer.
 * @param {Object} state - the current state of the env store.
 * @param {Object} action - the action to take on the env store
 * @param {String} [action.type=default] - the action to take.
 */
export const envReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state;
  }
};
