/** @module */

import { DIALOG_OPEN, DIALOG_CLEAR } from '../reducers/dialog';

/**
 * Sets a the dialog as open.
 */
export const dialogOpen = () => {
  return dispatch => {
    dispatch({
      type: DIALOG_OPEN
    });
  };
};

/**
 * Resets the dialog state - for now closes it.
 */
export const dialogClear = () => {
  return dispatch => {
    dispatch({
      type: DIALOG_CLEAR
    });
  };
};
