import { titleCase } from 'title-case';

/** @module */

/**
 * Merges the current set of note IDs with the new set of IDs fron the request.
 * @param {Array} arrayOfOldIds - the current set of note IDs in the group.
 * @param {Object} newData - a list of note data returned in the request.
 * @param {Object} options - Options for isPaging and idKey
 * @param {Boolean} [options.isPaging] - tells us whether the request is due to paging and helps use understand how to merge the new set of IDs
 * @param {String} [options.idKey=id] - allows us to pass a specific key from the object to denote the item's id.
 */
export const getMergedSetOfIds = (
  arrayOfOldIds = [],
  newData,
  { entities, idKey = 'id', isPaging, sortFunction, sortDirection, sortKey, sortGuidsFirst } = {}
) => {
  const arrayOfNewIds = newData.map(entity => {
    return entity[idKey];
  });

  // We destructure and join the original set of IDs (default is an empty array) along with a mapped array of IDs from newData.
  // If newData is the result of paging, we append it after the original set of old IDs during destructuring.
  // Otherwise, if we are refreshing an API call, we want to prepend it during destructuring so that any new ids are shown in the proper order.
  // ToDo: We might need to support new data on pages other than the first, which is all this currently supports.
  // ToDo: If 5 pages are already loaded, and a new item has been added to page 3, it will not be shown currently.
  // ToDo: One possible way to do this would be that on force refresh, the note group length is limited to the DEFAULT_PAGING_SIZE

  const combinedArrayOfIds = isPaging ? [...arrayOfOldIds, ...arrayOfNewIds] : [...arrayOfNewIds, ...arrayOfOldIds];

  // new Set dedupes the orignal array and the new array of IDs
  const newSetOfIds = [...new Set(combinedArrayOfIds)];

  if (sortGuidsFirst !== undefined && sortGuidsFirst === true) {
    //Force Guids to always show ontop (email history - queue delay)
    const isValidGuid = str =>
      typeof str !== 'string' ? false : /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/.test(str);

    const newArray = newSetOfIds.sort((x, y) => (isValidGuid(x) ? -1 : isValidGuid(y) ? 1 : 0));

    // If a sort function is passed in, we sort the new set of IDs
    return sortFunction ? sortFunction(newArray, entities, { direction: sortDirection, sortKey }) : newArray;
  } else {
    // If a sort function is passed in, we sort the new set of IDs
    return sortFunction ? sortFunction(newSetOfIds, entities, { direction: sortDirection, sortKey }) : newSetOfIds;
  }
};

/**
 * Merges the current set of note entities in the store with the new notes from the request.
 * @param {Object} originalEntities - the current entities from the store
 * @param {Array} newData - a list of note data returned in the request.
 * @param {Object} options - Options for idKey
 * @param {String} [options.idKey=id] - allows us to pass a specific key from the object to denote the item's id.
 */
export const getMergedEntities = (originalEntities, newData, { idKey = 'id', ignorePreexisting = false } = {}) => {
  return {
    ...originalEntities,
    ...newData.reduce((acc, entity) => {
      const id = entity[idKey];

      acc[id] = {
        ...originalEntities[id],
        ...(ignorePreexisting && originalEntities[id] ? {} : entity)
      };

      return acc;
    }, {})
  };
};

/**
 * Returns the collection with the specified entities deleted.
 * @param {Object} collection - A collection of entities.
 * @param {String[]} arrayOfIds - the array of ID of the entities to delete.
 */
export const deleteAllFromCollection = (collection, arrayOfIds) => {
  const clone = Object.assign({}, collection);

  for (const id of arrayOfIds) {
    delete clone[id];
  }

  return clone;
};

/**
 * Returns the collection with the specified entity deleted.
 * @param {Object} collection - A collection of entities.
 * @param {String} id - the ID of the entity to delete.
 */
export const deleteFromCollection = (collection, id) => {
  return deleteAllFromCollection(collection, [id]);
};

/**
 * Returns a new array of entity keys minus the one specified by ID.
 * @param {Array} group - an array of entities.
 * @param {String} id - the ID of the entity to delete from the group.
 */
export const deleteFromGroup = (group, id) => {
  if (!group) {
    return null;
  }

  const index = group.indexOf(id);

  if (index < 0) {
    return group;
  }

  return [...group.slice(0, index), ...group.slice(index + 1)];
};

/**
 * Returns a new groups object with a given id removed from each group. Use when deleting an entity or if
 * the save entity re-fetches the new group.
 * @param {Array} groups - an array of groups.
 * @param {String} id - the ID of the entity to delete from the group.
 */
export const deleteFromAllGroups = (groups, id) => {
  if (!id) {
    return groups;
  }

  return Object.keys(groups).reduce((acc, group) => {
    acc[group] = deleteFromGroup(groups[group], id);

    return acc;
  }, {});
};

/**
 * Returns a new group object with an array of ids removed from each group.
 * @param {Array} group - an array of entities.
 * @param {String[]} ids - an array of the ID of the entities to delete from the group.
 */
export const deleteAllFromAllGroups = (groups, ids) => {
  if (ids == null || ids.length < 1) {
    return groups;
  }
  const res = Object.keys(groups).reduce((acc, group) => {
    const currentGroup = groups[group];

    const found = ids.some(id => currentGroup.includes(id));
    if (found) {
      // If currentGroup contains at least one id
      // Delete each matching id from the currentGroup
      ids.forEach(id => {
        if (currentGroup.includes(id)) {
          acc[group] = deleteFromGroup(currentGroup, id);
        }
      });
    } else {
      // Keep the group as is elsewise
      acc[group] = currentGroup;
    }
    return acc;
  }, {});

  return res;
};
/**
 * Returns a whole new set of groups with the contact moved to the new group and the contact removed where appropriate.
 * @param {String} id - a contact id
 * @param {Array} newGroup - the new group where the contact should end up.
 * @param {Object} stateGroups - the current contact groups in state to add and remove the contact from.
 */
export const moveGroups = (id, newGroup, stateGroups) => {
  const contactStatusGroups = ['new', 'engage', 'future', 'active', 'closed', 'inactive', 'nonclient'];
  const isContactStatusChange = contactStatusGroups.includes(newGroup);
  const newGroupType = newGroup.split('::')[0];

  const movedGroups = Object.keys(stateGroups).reduce((acc, group) => {
    const groupType = group.split('::')[0];

    const isInThisGroup = stateGroups[group]?.includes(id);
    // make sure we don't miss any `fav` group with sorting in the group name
    const newGroupIsThisGroup =
      newGroupType !== 'fav' && !isContactStatusChange ? newGroup === group : newGroupType === groupType;

    if (isInThisGroup && !newGroupIsThisGroup) {
      if (
        (isContactStatusChange && contactStatusGroups.includes(groupType)) || // is contact status
        (groupType === 'fav' && newGroup === 'unfav') || // is fav
        groupType === 'task' // is task
      ) {
        // Remove it from this group.
        acc[group] = deleteFromGroup(stateGroups[group], id);
      }
    }

    if (!isInThisGroup && newGroupIsThisGroup) {
      // When not in this group, we make sure we add it if this group id matches the new group.

      acc[group] = [...stateGroups[group], id];
    }

    return acc;
  }, {});

  return movedGroups;
};

/**
 * Returns the last item from an array.
 * @param {Array} group - an array.
 */
export const getLastFromGroup = group => {
  if (!group) {
    return null;
  }

  return group.slice(-1)[0];
};

/**
 * Returns a new array of entity keys minus the one specified by ID.
 * @param {Array} array - an array of objects/entities.
 * @param {String} key - the object key to use in matching.
 * @param {String} value - the value to use in matching.
 */
export const deleteObjectFromArray = (array, key, value) => {
  if (!array) {
    return null;
  }

  const index = array.findIndex(obj => {
    return obj[key] === value;
  });

  if (index < 0) {
    return array;
  }

  return [...array.slice(0, index), ...array.slice(index + 1)];
};

/**
 * Sort a group alphabetically by key - usually fullName.
 * @param {Array} group - a redux group
 * @param {Object} entities - typically a set of entities returned in an API response
 * @param {Object} [options] - a set of options to use in sorting
 * @param {String} [options.sortKey=fullName] - the object key to use in sorting
 * @param {String} [options.direction=asc] - the sort direction, should be either asc or dsc
 */
export const sortCollection = (group, entities, { sortKey = 'fullName', direction = 'asc' } = {}) => {
  return group.sort((a, b) => {
    const sortA = direction === 'dsc' ? b : a;
    const sortB = direction === 'dsc' ? a : b;

    return entities[sortA][sortKey].localeCompare(entities[sortB][sortKey]);
  });
};

/**
 * Sort a group by entity date.
 * @param {Array} group - a redux group
 * @param {Object} entities - typically a set of entities returned in an API response
 * @param {Object} [options] - a set of options to use in sorting
 * @param {String} [options.sortKey=date] - the object key to use in sorting
 * @param {String} [options.direction=dsc] - the sort direction, should be either asc or dsc
 */
export const sortCollectionByDate = (group, entities, { sortKey = 'date', direction = 'dsc' } = {}) => {
  return group.sort((a, b) => {
    const sortA = direction === 'dsc' ? b : a;
    const sortB = direction === 'dsc' ? a : b;

    // When the date field is optional
    if (entities[sortA] == null) {
      return direction === 'dsc' ? -1 : 1;
    }
    if (entities[sortB] == null) {
      return direction === 'dsc' ? 1 : -1;
    }

    return new Date(entities[sortA][sortKey]).getTime() - new Date(entities[sortB][sortKey]).getTime();
  });
};

/**
 * Sorts an array of objects.
 * @param {Array} arr - The array to sort.
 * @param {Object} options - a set of options to use in sorting.
 * @param {String} [options.direction=asc] - the sort direction, should be either asc or dsc.
 * @param {String} [options.sortKey=id] - the object key to use in sorting.
 * @param {String} [options.sortType=numberic] - sort using numeric.
 * @param {String} [options.sortType=alpha] - sort using alpha.
 * @param {String} [options.sortType=date] - sort using date.
 */
export const sortArrayOfObjects = (arr, { sortKey = 'id', sortType = 'numeric', direction = 'asc' } = {}) => {
  return [...arr].sort((a, b) => {
    const sortA = direction === 'dsc' ? b : a;
    const sortB = direction === 'dsc' ? a : b;

    if (sortType === 'numeric') {
      return parseInt(sortA[sortKey]) - parseInt(sortB[sortKey]);
    }

    if (sortType === 'date') {
      return new Date(sortA[sortKey]).getTime() - new Date(sortB[sortKey]).getTime();
    }

    return sortA[sortKey].localeCompare(sortB[sortKey]);
  });
};

/**
 * Returns an array of entities from a collection.
 * @param {Array} collection - an array of IDs,
 */
export const getArrayOfEntities = collection => {
  // return an empty array if no ids, otherwise return array of entites [{ id: 'abc'}, { id: 'def'}]
  return Object.keys(collection).length < 1
    ? []
    : Object.keys(collection).reduce((acc, item) => {
        acc.push(collection[item]);

        return acc;
      }, []);
};

/**
 * Turn arrays into entities
 * @param {Array} arr - input array
 * @returns {Object} Array of entities
 */
export const getEntitiesFromArray = arr => {
  return arr != null
    ? arr.reduce((acc, item, index) => {
        acc[index] = {
          id: item,
          name: item
        };
        return acc;
      }, [])
    : [];
};

/**
 * Returns an entity id from the collection based on the direction and current list items.
 * @param {Array} collection - an array of IDs.
 * @param {String} currentEntity - The ID of the currently active/visible entity.
 * @param {Number} currentIndex - the index of the currently active/visible entity.active.
 * @param {Number} dir - the direction of next or prev.
 */
export const getNextPrevEntityFromCollection = (collection, currentEntity, currentIndex, dir) => {
  // We need to check for the current index of the selected item to make sure it is still present in the list, and
  // either use the currentIndex or the currentIndex - 1. This allows us to catch cases where the item could have been
  // removed from the list, such as when the user has changed the contact status of the contact, and
  // it has been removed from the list.
  const currentVisibleIndex = collection.indexOf(currentEntity) < 0 ? currentIndex - 1 : currentIndex;

  if (currentVisibleIndex < 0) {
    if (collection.length === 0) {
      // when the entity details are open over a list that has zero results
      return null;
    }

    return collection[0];
  }

  let newIndex;
  if (dir === 'next') {
    newIndex = currentVisibleIndex === collection.length - 1 ? currentVisibleIndex : currentVisibleIndex + 1;
  } else {
    newIndex = currentVisibleIndex > 0 ? currentVisibleIndex - 1 : 0;
  }
  return collection[newIndex];
};

export const getGroupByEntity = (entityId, groups) => {
  const contactId = Object.keys(groups).map(item => {
    const group = groups[item];
    if (group.includes(entityId)) {
      return item;
    }
  });
  return contactId[0];
};

/**
 * @param {Array} matches
 */
export const getCleanMatches = matches => {
  const cleanSortedMatches = matches
    .map(match => {
      return titleCase(match.toLowerCase().trim().replace(/_/g, ' ')).replace(/(?:^|[\s-/])\w/g, match =>
        match.toUpperCase()
      ); // When using titleCase(), you should lowercase, trim, & replace _. The second Regex/replace expression used for upper-casing letters following a slash
    })
    .sort();

  // Dedupe the matches.
  const dedupedMatches = [...new Set(cleanSortedMatches)];
  // Get entities from matches.
  const entities = getEntitiesFromArray(dedupedMatches);

  return entities;
};
