import React from 'react';
import { array, bool, string } from 'prop-types';
import { MessageBanner } from './MessageBanner';
import { MLS_SOURCES_ERROR_MESSAGE, MLS_CREDS_ERROR_MESSAGE } from '../../utils/mlsBoards';

export const MlsErrorMessageBanner = props => {
  const { isVisible = true, message = MLS_CREDS_ERROR_MESSAGE, sources } = props || {};
  const errorMessage = sources == null ? MLS_SOURCES_ERROR_MESSAGE : MLS_CREDS_ERROR_MESSAGE;
  return <MessageBanner icon="error" isVisible={isVisible} message={message || errorMessage} />;
};

MlsErrorMessageBanner.propTypes = {
  className: string,
  isVisible: bool,
  message: string,
  sources: array
};
