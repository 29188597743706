import React, { Component } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bool, number, object, string } from 'prop-types';

import { showDrawer } from '../../actions/drawer';
import { fetchNotes, getNotes, setCurrentNote, setNotesCurrentGroup } from '../../actions/notes';
import { Button, ButtonGroup, Container, List, ListPlaceholder, NoteCard } from '../';
import { View } from '../../components/View';
import { ViewHeader } from '../../components/ViewHeader';
import NotesCallToAction from '../../components/CallToAction/NotesCallToAction';
import { ENTITY_TYPES } from '../../utils/notes';

import styles from '../../components/List/List.css';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { ListFooter } from '../ListFooter';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { INFINITE_SCROLL_ROOT_MARGIN } from '../../constants';

/**
 * Add infinite scrolling hook to class component
 * @returns NoteList with infinite scrolling hook
 */
const withHookNotelist = () => {
  return props => {
    const { group, entity, shouldSetCurrentGroup, currentList, notes } = props;
    const lastNoteId = currentList?.slice(-1)[0];
    const date = notes?.[lastNoteId]?.date;

    const { currentGroup, groups, isLoading, donePaging } = useSelector(store => store.notes);

    const dispatch = useDispatch();

    const onLoadMore = debounce(() => {
      if (!isEmpty(groups[currentGroup])) {
        dispatch(fetchNotes({ group, entity, shouldSetCurrentGroup }, { id: lastNoteId, key: date }));
      }
    }, 250);

    const id = entity?.id || 'myNotes';

    const [infiniteRef] = useInfiniteScroll({
      loading: isLoading,
      hasNextPage: !donePaging[id],
      onLoadMore: onLoadMore,
      rootMargin: INFINITE_SCROLL_ROOT_MARGIN
    });
    return <NotesList infiniteRef={infiniteRef} {...props} />;
  };
};

export class NotesList extends Component {
  componentDidMount() {
    const { fetchNotes, group, entity, shouldSetCurrentGroup } = this.props;
    fetchNotes({ group, entity, shouldSetCurrentGroup }, { isSync: true, forceRefresh: true });
  }

  componentDidUpdate(prevProps) {
    const { fetchNotes, group, entity, shouldSetCurrentGroup } = this.props;

    if (group !== prevProps.group) {
      fetchNotes({ group, entity, shouldSetCurrentGroup }, { isSync: true, forceRefresh: true });
    }
  }

  shouldComponentUpdate(nextProps) {
    const { currentGroup, currentList, currentNote, entity } = this.props;

    const {
      currentGroup: nextCurrentGroup,
      currentList: nextCurrentList,
      currentNote: nextCurrentNote,
      entity: nextEntity
    } = nextProps;

    if (
      entity !== nextEntity ||
      currentGroup !== nextCurrentGroup ||
      currentNote !== nextCurrentNote ||
      currentList !== nextCurrentList
    ) {
      return true;
    }

    return false;
  }

  handlePaging = () => {
    const { notes, currentList, fetchNotes, group, entity, shouldSetCurrentGroup } = this.props;

    if (currentList.length === 0) {
      fetchNotes({ group, entity, shouldSetCurrentGroup }, { forceRefresh: true });
    } else {
      const lastNoteId = currentList.slice(-1)[0];
      const date = notes[lastNoteId].date;

      fetchNotes({ group, entity, shouldSetCurrentGroup }, { id: lastNoteId, key: date });
    }
  };

  handleClick = e => {
    const { currentNote, entity, setCurrentNote } = this.props;
    const isNested = !!entity;

    if (isNested) {
      // stop the nest note link from firing
      e.preventDefault();
    }

    if (currentNote === e.currentTarget.id) {
      setCurrentNote({ id: null });
      return;
    }

    setCurrentNote({ id: e.currentTarget.id });
  };

  handleAddNote = () => {
    const { showDrawer, group } = this.props;

    if (group) {
      const pathElm = group.split('::');
      if (pathElm[0] === 'insight' && pathElm.length > 1) {
        showDrawer({ drawerType: 'notesForm', insightId: pathElm[1] });
        return;
      }
    }
    showDrawer({ drawerType: 'notesForm', group });
  };

  handleLoadMore = debounce(() => {
    const { group, entity, shouldSetCurrentGroup, currentList, notes, donePaging, fetchNotes } = this.props;
    const lastNoteId = currentList?.slice(-1)[0];
    const date = notes?.[lastNoteId]?.date;

    const id = entity?.id || 'myNotes';

    if (!Boolean(donePaging?.[id]) && !isEmpty(currentList)) {
      fetchNotes({ group, entity, shouldSetCurrentGroup }, { id: lastNoteId, key: date });
    }
  }, 250);

  render() {
    const {
      notes,
      currentList,
      currentNote,
      entity,
      group,
      isLoading,
      donePaging,
      location,
      max,
      title = 'Notes',
      titleAs,
      infiniteRef
    } = this.props || {};

    const { pathname, search } = location || {};
    const isNested = !!entity;

    const showAddNote = location?.pathname?.split('/')[1] !== 'calendar';

    const placeholderRows = max ? max : isNested ? 3 : 4;
    const displayTitleAs = titleAs ? titleAs : isNested ? 'h2' : 'h1';

    const shouldShowAllNotesButtonByEntityType = [ENTITY_TYPES.contact, ENTITY_TYPES.transaction];

    const showAllNotesButton =
      currentList && max < currentList.length && shouldShowAllNotesButtonByEntityType.includes(entity?.type);

    const displayList = currentList && max ? currentList.slice(0, max) : currentList;

    const pathnameFragments = pathname?.split('/');

    const showAllNotesButtonUrl = showAllNotesButton
      ? `${pathnameFragments.slice(0, -1).join('/')}/notes${search}`
      : null;

    // To prevent NoteList under Contact/Dashboard from auto loading
    const shouldInfiniteScroll = !isNested || pathnameFragments?.[pathnameFragments.length - 1] === 'notes';

    return (
      <View>
        <ViewHeader title={title} titleAs={displayTitleAs} titleIcon="notes" helpKey="notes">
          <ButtonGroup>
            {showAllNotesButton && (
              // All notes button appears on the dashboard notes list inside a contact.
              <Button
                ariaLabel="See all notes."
                icon="notes"
                label="All Notes"
                styleType="white"
                url={showAllNotesButtonUrl}
              />
            )}

            {showAddNote && (
              <Button
                ariaLabel="Add a note."
                data-cy="addNoteFromList"
                icon="noteAdd"
                label="Add Note"
                labelShort={showAllNotesButton ? 'Add' : undefined}
                onClick={this.handleAddNote}
                styleType="primary"
              />
            )}
          </ButtonGroup>
        </ViewHeader>
        <Container className={styles.listContainer}>
          <List
            isNested={isNested}
            noResultsIcon="clipboard"
            noResultsComponent={<NotesCallToAction />}
            testId="NotesList"
            isVirtuoso={false}
          >
            {displayList ? (
              displayList.map(id => (
                <li key={id} className={styles.listItem}>
                  <NoteCard
                    data={notes[id]}
                    isNested={isNested}
                    currentNote={currentNote}
                    group={group}
                    onClick={this.handleClick}
                    variant={showAllNotesButton ? 'compact' : 'default'}
                  />
                </li>
              ))
            ) : (
              <ListPlaceholder rows={placeholderRows} isNested={isNested} />
            )}
          </List>
        </Container>
        <ListFooter
          donePaging={donePaging || showAllNotesButtonUrl}
          isLoading={isLoading}
          infiniteRef={shouldInfiniteScroll ? infiniteRef : null}
        />
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  notes: state.notes.entities,
  currentList: state.notes.groups[ownProps.group],
  currentGroup: state.notes.currentGroup,
  currentNote: state.notes.currentEntity,
  donePaging: state.notes.donePaging[ownProps.group],
  isLoading: state.notes.isLoading
});

const mapDispatchToProps = {
  getNotes,
  setCurrentNote,
  showDrawer,
  setNotesCurrentGroup,
  fetchNotes
};

NotesList.propTypes = {
  entity: object,
  group: string,
  location: object,
  max: number,
  shouldSetCurrentGroup: bool,
  title: string,
  titleAs: string
};

export default connect(mapStateToProps, mapDispatchToProps)(withHookNotelist(NotesList));
