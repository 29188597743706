/**
 * Builds a string to be used as testId for a component.
 * That is, to be added as an HTML attribute `data-testid`.
 * The format is as follows: [<prefix>-]<core>
 * Examples:
 *  buildTestId('NavBar', 'LinkList'); // returns 'NavBar-LinkList'
 *  buildTestId(undefined, 'LinkList'); // returns 'LinkList'
 *
 * @param {string} prefix The prefix part of the testId (e.g., the parent's testId)
 * @param {string} core The core part of the testId.
 */
export const buildTestId = (prefix, core) => {
  return `${prefix ? `${prefix}-` : ''}${core}`;
};
