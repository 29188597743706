/** @module */

import camelCase from 'camelcase';
import dompurify from 'dompurify';

import { isExternalLink } from './urls';

/**
 * Calculates the offset of an element in the DOM.
 * @param {Node} el - takes a DOM node from event target
 */
export const getElementOffset = el => {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  return {
    top: rect.top + scrollTop,
    right: rect.right + scrollLeft,
    bottom: rect.bottom + scrollTop,
    left: rect.left + scrollLeft
  };
};

/**
 * Triggers a download of a blob by creating and triggering a link click.
 * @param {String} str - an inline CSS string.
 */
export const downloadBlob = (blob, filename) => {
  // Create an object URL for the blob object.
  const url = URL.createObjectURL(blob);

  // Create an anchor element.
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';

  // Click handler that releases the object URL after the element has been clicked.
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element.
  a.addEventListener('click', clickHandler, false);

  // Trigger a click on the anchor element.
  a.click();

  // Return the anchor element in case you want to attach it to the DOM or use it in some other way.
  return a;
};

export const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

/**
 * Gets a React friendly style object from a CSS string.
 * @param {String} str - an inline CSS string.
 */
export const getStyleObjectFromStr = str => {
  if (!str) {
    return null;
  }

  const styleObj = str.split(';').reduce((acc, rule) => {
    const ruleArr = rule.split(':');
    acc[camelCase(ruleArr[0])] = ruleArr[1];
    return acc;
  }, {});

  return styleObj;
};

/**
 * Check's whether an event target is a form element used for text entry.
 * @param {Object} target - an event target object.
 */
export const isElementForDataEntry = target => {
  if (!target) {
    return false;
  }

  if (target.getAttribute('contenteditable') === 'true') {
    return true;
  }

  return ['textarea', 'input', 'select'].includes(target.tagName.toLowerCase());
};

/**
 * Gives focus to the first
 * @param {String} - the form ID of the parent form.
 */
export const focusFirstInvalidField = formId => {
  const requiredSelector = '[aria-invalid=true]';
  const selector = formId ? `#${formId} ${requiredSelector}` : requiredSelector;
  const elToFocus = document.querySelector(selector);

  if (!elToFocus) {
    return;
  }

  elToFocus.focus();
};

/**
 * Check to see if a string has html tags in it.
 * @param {String} str An HTML or text string.
 * @returns Boolean
 */
export const checkIfHtml = str => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return [...doc.body.childNodes].some(node => node.nodeType === 1);
};

/**
 * Cleans the HTML string of unwanted or HTML, or adds attributes.
 * @param {Node} node - a DOM node.
 */
const cleanHtmlNode = node => {
  const tagName = node.nodeName.toLowerCase();

  if (tagName === 'a') {
    const url = node.getAttribute('href');
    const linkNeedsAttributes = isExternalLink(url);

    if (linkNeedsAttributes) {
      // Make all external links open in a new window.
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
      return node;
    }
  }
};

dompurify.addHook('afterSanitizeAttributes', node => {
  cleanHtmlNode(node);
});

/**
 * Sanitizes HTML to ensure safe DOM use.
 */
export const sanitizer = dompurify.sanitize;

// Sets --scrollbar-size same as css-scrollbar-size did.
export const setScrollbarSize = () => {
  const sentinelSize = 100;
  const sentinel = document.createElement('div');
  const styles = `display: block; position: absolute; top: -9999px; width: ${sentinelSize}px; height: ${sentinelSize}px; overflow: scroll;`;
  sentinel.setAttribute('style', styles);
  document.body.appendChild(sentinel);
  if (sentinel.clientWidth === 0) {
    document.body.removeChild(sentinel);
    setTimeout(setScrollbarSize, 100);
    return;
  }
  const size = sentinel.offsetWidth - sentinel.clientWidth;
  document.body.removeChild(sentinel);
  document.documentElement.style.setProperty('--scrollbar-size', ''.concat(size, 'px'));
};
