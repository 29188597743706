/** @module */

import differenceInHours from 'date-fns/differenceInHours';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isYesterday from 'date-fns/isYesterday';
import escape from 'lodash/escape';
import { DATE_FORMATS, REGEX, UNICODE, DEFAULT_TITLE_TAG } from '../constants';
import { cleanPhoneNumber } from './';
import { getCityStateStr, getStreetAddressStr } from './data';
import { parseISODate } from './dates';
import { STATES_LIST } from '../data/states';

const { EN_DASH } = UNICODE;

/**
 * Validate url against regex
 * @param {String} url
 * @param {String} pattern
 */
export const validateUrl = (url, pattern = REGEX.URL_SIMPLE) => {
  if (!url) {
    return false;
  }

  return pattern.test(url);
};

export const validateEmail = email => {
  if (!email) {
    return false;
  }

  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateRGB = str => {
  if (!str) {
    return false;
  }
  return REGEX.IS_RGB_STRING.test(str);
};

export const validateHexString = str => {
  if (!str) {
    return false;
  }
  return REGEX.IS_HEX_STRING.test(str);
};

/**
 * Generates an object from a URL param string
 * @param {String} str - a query string to parse
 */
export const parseParamsStr = str => {
  if (!str || str.length < 3) {
    return null;
  }

  const searchParams = new URLSearchParams(str);

  return [...searchParams.keys()].reduce((acc, key) => {
    acc[key] = searchParams.get(key);

    return acc;
  }, {});
};

/**
 * Gets a specific param from location search string (aka params str)
 * @param {String} str - the location search str
 * @param {String} param - the param to retrieve
 */
export const getParamFromSearch = (str, param) => {
  if (!str || str.length < 1 || !param) {
    return null;
  }

  const searchParams = new URLSearchParams(str);

  return searchParams.get(param);
};

/**
 * Generates a param string with spaces replaced
 * @param {String} str - a string to format
 */
export const getParamStr = str => {
  // `== null` only covers both null and undefined
  if (str == null) {
    return null;
  }

  return encodeURIComponent(str.toString().trim());
};

/**
 * Generates a URL param string from an object
 * @param {Object} obj - an object to stringify into an request param string
 */
export const serializeToParamsStr = obj => {
  if (!obj || typeof obj !== 'object' || obj.constructor !== Object) {
    return null;
  }

  return Object.keys(obj)
    .reduce((acc, key) => {
      if (obj[key] !== null && obj[key] !== undefined) {
        acc.push(`${key}=${getParamStr(obj[key])}`);
      }

      return acc;
    }, [])
    .join('&');
};

/**
 * Cleans the given params object and generates a URL param string from the given params object
 * @param {Object} params - an object containing key=value attributes to stringify into a request param string
 */
export const generateCleanParamsStr = params => {
  const cleanParams = Object.keys(params).reduce((acc, param) => {
    if (!params[param]) {
      return acc;
    }

    acc[param] = params[param];

    return acc;
  }, {});

  const paramsStr = serializeToParamsStr(cleanParams);

  return paramsStr.length > 0 ? `?${paramsStr}` : '';
};

/**
 * Converts a timestamp into a friendly format for use with the Date constructor new Date()
 * @param {String} timestamp - a timestamp from the API
 */
export const convertToDate = timestamp => {
  if (!timestamp) {
    return null;
  }

  return timestamp.replace(/-/g, '/');
};

/**
 * Converts a UTC date to local device date
 * @param {String} dateUtc - a UTC date string
 */
export const convertDateUtcToLocal = dateUtc => {
  if (!dateUtc) {
    return null;
  }

  const parseUTC = dateUtc => {
    const arr = dateUtc.split(/\D/);
    return new Date(Date.UTC(arr[0], --arr[1], arr[2], arr[3], arr[4], arr[5]));
  };

  return parseUTC(dateUtc);
};

/**
 * Converts local device date to UTC
 * @param {String} dateLocal - a local date string
 */
export const convertDateLocalToUtc = dateLocal => {
  if (!dateLocal) {
    return null;
  }

  const date = new Date(dateLocal);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  ).toISOString();
};

/**
 * Takes a timestamp and returns a long date in the form LLLL d, yyyy
 * @param {string} timestamp - timestamp returned from the API
 */
export const formatLongDate = (timestamp, dateFormat = DATE_FORMATS.LONG_DATE) => {
  if (!timestamp) {
    return;
  }
  const parsedTimestamp = parseISODate(timestamp);

  return format(parsedTimestamp, dateFormat);
};

/**
 * Returns a human friendly date showing elapsed time.
 * @param {string} timestamp - timestamp returned from the API
 * @param {string} now - a timestamp representing now, defaults to new Date()
 */
export const formatFriendlyDate = (timestamp, now = new Date()) => {
  const TODAY = 24;
  const WEEK = TODAY * 7; // in hours

  if (!timestamp) {
    return null;
  }
  const parsedTimestamp = parseISODate(timestamp);

  const diffInHours = differenceInHours(now, parsedTimestamp);

  if (diffInHours < 0 || diffInHours >= WEEK) {
    return formatLongDate(parsedTimestamp, DATE_FORMATS.SHORT_DATE_WITH_YEAR);
  }

  return `${formatDistanceToNow(parsedTimestamp)} ago`;
};

/**
 * Returns a human friendly due date.
 * @param {string} timestamp - timestamp returned from the API
 * @param {string} now - a timestamp representing now, defaults to new Date()
 */
export const formatFriendlyDueDate = (timestamp, now = new Date()) => {
  if (!timestamp) {
    return null;
  }
  const parsedTimestamp = parseISODate(timestamp);
  //conversion affects the timestamp in safari browser
  //const date = new Date(timestamp);

  if (isTomorrow(parsedTimestamp)) {
    return 'Tomorrow';
  }

  if (isToday(parsedTimestamp)) {
    return 'Today';
  }

  if (isYesterday(parsedTimestamp)) {
    return 'Yesterday';
  }

  return formatFriendlyDate(parsedTimestamp, now);
};

/**
 * Returns a human friendly price
 * @param {string} price - a price string
 */
export const formatPrice = price => {
  const THOUSAND = 1000;
  const MILLION = THOUSAND * THOUSAND;

  if (price >= MILLION) {
    const splitPrice = parseFloat(price / MILLION)
      .toFixed(1)
      .split('.');
    const formattedPrice = splitPrice[1] === '0' ? splitPrice[0] : splitPrice.join('.');

    return `$${formattedPrice}M`;
  }

  return `$${Math.round(price / THOUSAND)}k`;
};

/**
 * Returns a price string as a comma delimited price string
 * @param {string} price - a price string
 */
export const formatPriceWithCommas = price => {
  if (price == null) {
    return null;
  }

  return parseInt(Math.round(price)).toLocaleString('en');
};

/**
 * Returns a price string with no decimals.
 * @param {string} price - a price string that is a float
 */
export const formatPriceFromFloat = price => {
  if (price == null) {
    return null;
  }

  return parseInt(Math.round(price)).toString();
};

/**
 * Returns a price string as a comma delimited price string with decimals
 * @param {string} price - a price string
 */
export const formatPriceWithCommasFromFloat = price => {
  if (price == null) {
    return null;
  }

  return parseFloat(price).toLocaleString('en');
};

/**
 * Returns a human friendly price range
 * @param {string} minPrice - the minimum price
 * @param {string} maxPrice - the maximum price
 */
export const formatPriceRange = (minPrice, maxPrice) => {
  let range;

  if (!!minPrice && Math.round(minPrice) !== 0) {
    range = formatPrice(minPrice);

    if (maxPrice && minPrice === maxPrice) {
      return range;
    }
  }

  if (!!maxPrice && Math.round(maxPrice) !== 0) {
    const formattedMax = formatPrice(maxPrice);
    range = range ? `${range} ${EN_DASH} ${formattedMax}` : formattedMax;
  }

  return range;
};

/**
 * Returns a human friendly formatted count
 * @param {number} count - a contact count
 * @returns {string} a friendlier count with k for thousands, M for millions.
 */
export const formatCount = count => {
  const THOUSAND = 1000;
  const MILLION = THOUSAND * THOUSAND;

  if (count >= MILLION) {
    const splitCount = parseFloat(count / MILLION)
      .toFixed(1)
      .split('.');
    const formattedCount = splitCount[1] === '0' ? splitCount[0] : splitCount.join('.');

    return `${formattedCount}M`;
  } else if (count >= THOUSAND) {
    const splitCount = parseFloat(count / THOUSAND)
      .toFixed(1)
      .split('.');
    const formattedCount = splitCount[1] === '0' ? splitCount[0] : splitCount.join('.');

    return `${formattedCount}k`;
  }
  return `${count}`;
};

/**
 * Returns a string with all url, email, and telephone string partials replaces with html link fragments.
 * @param {String} str
 */
export const linkifyText = str => {
  // ToDo:  We probably need to move these REGEX out into constants, but we need a better way of making them reusable

  if (!str) {
    return null;
  }

  // Replace URLs, and ensure any URLs that start with www. are prepended with https://
  str = str.replace(
    /\b((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)\b/g,
    match =>
      `<a href="${match.includes('@') ? 'mailto:' : ''}${match.replace(
        /^www\./g,
        'https://www.'
      )}" target="_blank" rel="noopener" contentEditable="false" class="link">${match}</a>`
  );

  // Replace PHONES
  str = str.replace(
    /(((?:\+?\b(\d{1,3}))?[-.(]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?)\b)/g,
    match => `<a href="tel:${cleanPhoneNumber(match)}" contentEditable="false" class="link">${match}</a>`
  );

  return str;
};

/**
 * Loosely cleans a string of symbols
 * @param {String} str
 */
export const cleanStrOfSymbols = str => {
  return str.replace(/[\W_]+/g, '');
};

/**
 * Takes in a number and formats it into a human friendly count.
 * @param {String} str - A number to format.
 */
export const formatFriendlyNumber = str => {
  if (!str) {
    return '';
  }

  const ranges = [
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' }
  ];

  for (let i = 0, rangeLength = ranges.length; i < rangeLength; i++) {
    if (str >= ranges[i].divider) {
      str = Math.round((str / ranges[i].divider) * 10) / 10 + ranges[i].suffix;
    }
  }

  return str.toString();
};

/**
 * Gets the byte size of string.
 * @param {String} str
 */
export const getByteSize = str => new Blob([str]).size;

/**
 * Takes in the number of bytes and formats it into a human friendly file size.
 * @param {Number} bytes - A file size in bytes.
 */
export const formatBytes = bytes => {
  if (!bytes || bytes === 0) {
    return '0 Bytes';
  }

  const k = 1000; // Alternatively, we could use 1024, but that makes it harder for the average user.
  const dm = bytes > 999999 ? 1 : 0;
  const sizes = ['B', 'KB', 'MB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Capitalize the first char of the string
 * @param {string} word
 * @returns {string} formatted word
 */
export const capitalize = word => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

/**
 * Turn snake_case into normal word and only capitalize the first char
 * @param {string} word - snake_case strings
 * @returns {string} Formatted data
 */
export const parseSnakeCase = word => {
  if (word != null) {
    return capitalize(word.toLowerCase()).replace(/_/g, ' ');
  }
  return '';
};

/**
 * Turn array of snake_case string into normal word and only capitalize the first char
 * @param {string[]} data - array of snake_case strings
 * @returns {string[]} Formatted data
 */
export const parseSnakeCaseArray = data => {
  if (data == null) {
    return null;
  }
  return data.map(word => parseSnakeCase(word));
};

export const toSnakeCase = str => {
  return str.replace(/\s/g, '_');
};

/**
 * Remove commas and plus sign from price string
 * @param {string} price price string with commas
 * @return {int} price
 */
export const parsePrice = price => {
  if (!price || price.toLowerCase() === 'unlimited') {
    return '';
  }
  const parsedPrice = price.replace(/\+/, '');
  return parseFloat(parsedPrice.replace(/\,/g, ''));
};

/**
 * Return 'Yes' or 'No' string depending on boolean.
 * @param {boolean} value the boolean input
 * @returns {string} either 'Yes' or 'No'
 */
export const parseBoolToYesNo = value => {
  return value ? 'Yes' : 'No';
};

/**
 * Returns any digits from a string.
 * @param {String} str - a string.
 */
export const leaveOnlyDigits = str => {
  if (str == null) {
    return null;
  }

  if (typeof str === 'number') {
    return str;
  }

  const remaining = str.replace(/[^0-9]+/g, '');

  if (!remaining) {
    return null;
  }
  // Replace all non-digits and make it an integer.
  return parseInt(remaining);
};

/**
 * Gets a whole number from a string.
 * @param {String} value - the number.
 */
export const getValueAsWholeNumber = value => {
  if (value == null) {
    return false;
  }
  // Replace all non-digits, except decimal/period, and make it an integer.
  return parseInt(value.toString().replace(/[^0-9.]+/g, ''));
};

/**
 * Parse tag in case of transaction and property insight notes
 * @param {String} tag
 * @param {Number} entityType
 * @param {Array} expectedTypes Array of expected ENTITY_TYPES
 */
export const parseTag = (tag, entityType, expectedTypes) => {
  if (tag == null || !Array.isArray(expectedTypes)) {
    return '';
  }
  // transaction and property insight name format: mlsNumber::address
  // fall back to mlsNumber in the absence of address
  const tagLabel = tag.split('::');

  return expectedTypes.includes(entityType)
    ? tagLabel.length > 1 && tagLabel[1] !== ''
      ? tagLabel[1]
      : tagLabel[0]
    : tag;
};

/**
 * Pluralizes a string with a given suffix based on a count.
 * @param {Number} count - the number of items associated with the label.
 * @param {String} label - the base label to pluralize.
 * @param {String} suffix - the suffix to use if count is !== 1.
 */
export const pluralize = (count, label, suffix = 's') => {
  return count === 1 ? label : `${label}${suffix}`;
};

/**
 * Format url with http:// when it doesn't have prefix
 * @param {String} url
 */
export const formatUrl = url => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);

/**
 * Check if a string is a non-negative integer
 * @param {String} str - the string input to be checked
 */
export const isNonNegativeInteger = str => {
  if (typeof str !== 'string') {
    return false;
  }
  return /^(0|[1-9]\d*)$/.test(str);
};

/**
 * Check if a string is a positive integer
 * @param {String} str - the string input to be checked
 */
export const isPositiveInteger = str => {
  if (!isNonNegativeInteger(str)) {
    return false;
  }

  return parseInt(str, 10) > 0;
};

/**
 * Check if a string is a valid non-negative float.
 * @param {String} str - the string input to be checked
 */
export const isNonNegativeFloat = str => {
  const mathSign = Math.sign(str);
  const isNonNegativeNumber = mathSign === 0 || mathSign === 1;

  if (!isNonNegativeNumber) {
    return false;
  }

  return true;
};

/**
 * Check if a string is a valid non-negative percentage.
 * @param {String} str - the string input to be checked
 */
export const isNonNegativePercentage = str => {
  if (isNonNegativeFloat(str) && parseFloat(str) <= 100) {
    return true;
  }

  return false;
};

/**
 * Check if a string is in a valid MLS number format,
 * in that it contains letters, numbers, dashes, or
 * dots but no other characters.
 * @param {String} str - the string input to be checked
 */
export const isValidMlsNumber = str => {
  if (typeof str !== 'string') {
    return false;
  }
  return /^[a-zA-Z0-9-.]*$/.test(str);
};

/**
 * Return an array of strings given a character separated string
 * @param {String} str
 * @param {String} sep separator
 */
export const getArrayFromString = (str, sep = ',') => {
  if (str == null) {
    return null;
  }
  return str
    .split(sep)
    .filter(s => s.trim() !== '')
    .map(s => s.trim());
};

export const ordinalize = num => {
  const arr = ['th', 'st', 'nd', 'rd'];
  const rem = num % 100;
  return num + (arr[(rem - 20) % 10] || arr[rem] || arr[0]);
};

export const generateTitleTag = prefix => {
  if (!prefix || prefix === '') {
    return DEFAULT_TITLE_TAG;
  }
  return `${prefix} - ${DEFAULT_TITLE_TAG}`;
};

export const formatMergeCodeString = (str, isText) => {
  return isText ? str : `<div>${str}</div>`;
};

// This is an editor specific string formatter.
export const getAddressStr = (address, isText = false) => {
  const addressFragment = getStreetAddressStr(address);
  const hasAddressFragment = addressFragment.length > 0;

  const cityStateFragment = getCityStateStr(address);
  const hasCityStateFragment = cityStateFragment.length > 0;

  if (hasAddressFragment && hasCityStateFragment) {
    return isText
      ? `${addressFragment}\n${cityStateFragment}`
      : `<div>${addressFragment}</div><div>${cityStateFragment}</div>`;
  }

  const addressOrCityFragment = addressFragment || cityStateFragment;

  if (addressOrCityFragment) {
    return formatMergeCodeString(addressOrCityFragment, isText);
  }

  return '';
};

/**
 * @param {String} str boolean value as a string
 * @returns string parsed into boolean
 */
export const parseStringToBoolean = str => {
  if (str == null || typeof str === 'boolean') {
    return str;
  }

  if (typeof str !== 'string') {
    return false;
  }

  return str.toLowerCase() === 'true';
};

export const getSpouseStr = spouse => {
  return spouse
    ? {
        spouse: {
          relatedContactFirstName: spouse?.relatedContactFirstName,
          relatedContactLastName: spouse?.relatedContactLastName,
          relatedContactFullName: spouse?.relatedContactFullName
        }
      }
    : null;
};

export const simplifyPhoneNumber = num => {
  const simplifiedNum = num.replace(/\D/g, '');

  return simplifiedNum.slice(-10);
};

export const getAdvancedText = (search, teamList, isRestrictedAgent = false) => {
  const { firstName, lastName, email, phone, address = {}, contactTypes: types, sources, assignedTo } = search;
  const { streetNumber: sNumber, streetName: sName, city, state, zip } = address;

  const nameFields = `${firstName ? `First Name: ${firstName} - ` : ''}${lastName ? `Last Name: ${lastName} - ` : ''}`;
  const contactFields = `${email ? `Email: ${email} - ` : ''}${phone ? `Phone: ${phone} - ` : ''}`;
  const streetFields = `${sNumber ? `Street Number: ${sNumber} - ` : ''}${sName ? `Street Name: ${sName} - ` : ''}`;
  const stateName = STATES_LIST.filter(e => e.value === state)[0]?.title;
  const cityStateField = `${city ? `City: ${city} - ` : ''}${stateName ? `State/Province: ${stateName} - ` : ''}`;
  const zipField = `${zip ? `Zip/Postal Code: ${zip} - ` : ''}`;

  const typesField = types?.length > 0 ? `${pluralize(types.length, 'Contact Type')}: ${types.join(', ')} - ` : '';
  const sourcesField = sources?.length > 0 ? `${pluralize(sources.length, 'Source')}: ${sources?.join(', ')} - ` : '';
  const assignedToField = assignedTo ? `Assigned To: ${teamList.filter(e => e.id === assignedTo)[0].value}` : '';

  const addressFields = `${streetFields}${cityStateField}${zipField}`;
  const otherFields = `${typesField}${sourcesField}${!isRestrictedAgent ? assignedToField : ''}`;

  return `${nameFields}${contactFields}${addressFields}${otherFields}`.trim().replace(/ -\s*$/, '');
};

/**
 * Decode URI, safely handling percent signs %
 * @param {String} str - the string input to be checked
 */
export const decodeURIComponentSafe = str => {
  if (typeof str !== 'string') {
    return str;
  }
  try {
    return decodeURIComponent(str.replace(/%(?![0-9a-fA-F])+/g, '%25'));
  } catch {
    return str;
  }
};

/**
 * Check if a string is in a valid GUID
 * @param {String} str - the string input to be checked
 */
export const isValidGuid = str =>
  typeof str !== 'string' || str === '00000000-0000-0000-0000-000000000000'
    ? false
    : /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/.test(str);

/**
 * @param {Object} input the input that requires value(s) to be escaped
 * @returns the safe escaped input
 */
export const deepEscape = input => {
  if (!input) {
    return input;
  } else if (typeof input === 'string') {
    return escape(input);
  } else if (Array.isArray(input)) {
    return input.map(e => deepEscape(e));
  } else if (typeof input === 'object') {
    return Object.keys(input).reduce((acc, val) => {
      return { ...acc, [val]: deepEscape(input[val]) };
    }, {});
  } else {
    return input;
  }
};
