/** @module */

import { WRAPUP_SET, WRAPUP_CLEAR } from '../reducers/wrapup';

/**
 * Shows the wrapup dialog.
 * @param {Object} data - something.
 */
export const showWrapup = data => {
  return dispatch => {
    dispatch({
      type: WRAPUP_SET,
      data
    });
  };
};

/**
 * Closes the wrapup dialog.
 */
export const clearWrapup = () => {
  return dispatch => {
    dispatch({
      type: WRAPUP_CLEAR
    });
  };
};
