import React from 'react';

import { FormField, FormFieldset, Button, Form, FormRow } from '../../components';
import { handleUpdateMLS, handleSearchMLS } from '../../utils/mlsBoards';
import styles from '../PropertyInsightsForm/PropertyInsightsForm.css';
import { useDispatch, useSelector } from 'react-redux';

export const LookupByMLSNumberForm = props => {
  const dispatch = useDispatch();
  const { lookupIsValid, mlsBoard, mlsSearchValue, setListingPreview, setLookupIsValid, setMlsSearchValue } = props;
  const { showNoTempListingsError } = useSelector(state => state.propertyInsights);
  return (
    <Form id="nestedMLSNumberForm">
      <FormFieldset>
        <FormRow>
          <FormField
            formIsValid={!showNoTempListingsError && lookupIsValid}
            fieldIsValid={!showNoTempListingsError && lookupIsValid}
            id="mls"
            placeholder="Search by MLS Number"
            onChange={e => handleUpdateMLS(e, dispatch, setMlsSearchValue, showNoTempListingsError, lookupIsValid)}
            required
            validationMessage={showNoTempListingsError || 'MLS number is required.'}
          />
          <Button
            className={styles.mlsButton}
            ariaLabel="Search for Property"
            type="submit"
            label="Search"
            styleType="primary"
            onClick={e => handleSearchMLS(e, dispatch, mlsSearchValue, mlsBoard, setListingPreview, setLookupIsValid)}
          />
        </FormRow>
      </FormFieldset>
    </Form>
  );
};
