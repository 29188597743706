import { navigate } from '@reach/router';
import classnames from 'classnames';
import { bool, object } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert, showAlert } from '../../actions';
import { showMessage } from '../../actions/message';
import {
  deleteSelectedContacts,
  setCurrentlySelectedContacts,
  setDisplayContactTypeModal,
  setDisplayMassEmailModal,
  setSelectAll,
  getContactDetail,
  massDeleteContacts,
  resetIgnoreData,
  resetPaging,
  setPageSize
} from '../../actions/contacts';
import { syncContacts } from '../../actions/contactSync';
import { savePreferences } from '../../actions/preferences';
import { Button, ButtonGroup, ContactCard, Container, FormCheckBox, List, ListHeader, Loading } from '../../components';
import { useMediaQueryContext } from '../../components/MediaQueryProvider/MediaQueryProvider';
import { View } from '../../components/View';
import contactStyles from '../../pages/Contacts/Contacts.css';
import { convertDateUtcToLocal, parseParamsStr, serializeToParamsStr, getParamFromSearch } from '../../utils';
import { pluralize } from '../../utils/strings';
import { setupContactSyncAlert } from '../../utils/sync';
import {
  DEFAULT_CONTACT_LIST_COLUMNS,
  getContactListIdentifier,
  getContactListType,
  getFollowupWindow,
  isFollowUpUpcoming,
  isFollowUpOverdue,
  PAGING_SIZE_OPTIONS
} from '../../utils/contacts';
import { getNumberOfDaysFromToday } from '../../utils/dates';
import { getAdvancedText } from '../../utils/strings';
import { checkIfIsOnTeamAccount, getAssignedToOptions } from '../../utils/team';
import { Dropdown } from '../Dropdown';
import { Filters } from '../Filters';
import { FormLabel } from '../FormLabel';
import ContactTypeModal from './ContactTypeModal';
import FollowUpReminderModal from './FollowUpReminderModal';

import styles from './List.css';
import tabContainerStyles from '../Tab/TabContainer.css';
import socialConnectStyles from '../SocialConnect/SocialConnect.css';
import MassEmailModal from './MassEmailModal';
import { Tag } from '../Tag';
import TaskPlanModal from './TaskPlanModal';
import { OWNER_TYPE } from '../../utils/taskPlans';
import { addDays, differenceInSeconds } from 'date-fns';
import { MASS_ACTION_LIMIT } from '../../constants/tasks';
import SocialConnectEmptyLanding from '../SocialConnect/SocialConnectSetupLanding';
import { getCampaignCheck } from '../../actions/socialConnect';
import { checkIsFromDashboard } from '../../utils/urls';

const ASSIGNED_TO_COLUMN = { id: 'assignedTo', label: 'Assigned To' };
const ACTIONS_COLUMN = { id: 'actions', label: 'Contact Method' };
const getColumnData = (isAssignedTo, isDashboard) => {
  if (isDashboard) {
    let columns = [
      { id: 'name', label: 'Name' },
      { id: 'source', label: 'Source' },
      { id: 'type', label: 'Type' }
    ];

    columns.push(ACTIONS_COLUMN);

    return columns;
  } else {
    let columns = [
      { id: 'check', label: '' },
      { id: 'name', label: 'Name' },
      { id: 'touch', label: 'Last Action' },
      { id: 'consumer_touch', label: 'Last Response' },
      { id: 'status', label: 'Status' },
      { id: 'source', label: 'Source' },
      { id: 'type', label: 'Type' },
      { id: 'prefs', label: 'Buyer Prefs' }
    ];

    if (isAssignedTo) {
      columns.push(ASSIGNED_TO_COLUMN);
    }

    columns.push(ACTIONS_COLUMN);

    return columns;
  }
};

const SORTABLE_COLUMNS = ['name', 'source', 'touch', 'consumer_touch'];

export const ContactList = props => {
  const {
    contacts,
    followUpReminders,
    isLoading,
    list,
    location,
    pageHandler,
    donePaging,
    tabData,
    isSearchTab,
    listClassName
  } = props || {};
  const { search, pathname } = location;
  const { pageSize } = useSelector(state => state.contacts);
  const { search: advancedSearch } = useSelector(state => state.advancedSearch);

  const isFromDashboard = checkIsFromDashboard(pathname);

  const searchTerm = getParamFromSearch(search, 'q');
  const searchType = getParamFromSearch(search, 'searchType');
  const contactListId = getContactListIdentifier(search); // Used to ID the list for checking the follow-up preference.

  const dispatch = useDispatch();
  const hasPageBeenRendered = useRef(false);

  const {
    currentlySelected,
    displayContactTypeModal,
    displayMassEmailModal,
    selectAll,
    statusCounts,
    currentGroup,
    ignoreData
  } = useSelector(state => state.contacts);

  const filteredCurrentlySelected = currentlySelected.filter(id => !ignoreData.ignoredContactsArray?.includes(id));
  const { defaultContactsPath, recentContacts } = useSelector(state => state.preferences);

  const [localSelectAll, setLocalSelectAll] = useState(false);
  const { isTabletPortraitAndUp } = useMediaQueryContext() || {};

  const searchCount = statusCounts?.[searchType || searchTerm?.toLowerCase()?.replace(/is:(\s+)/, 'is:')];

  // This block will hopefully be turned into a hook
  const [isShiftDown, setIsShiftDown] = useState(false);

  const [lastSelected, setLastSelected] = useState(null);

  const followUpReminderDays = useSelector(state => state.preferences.followUpReminders[contactListId]);

  const dayStr = followUpReminderDays && followUpReminderDays > 1 ? `${followUpReminderDays} days` : 'day';
  const followUpButtonLabel = followUpReminderDays ? `Every ${dayStr}` : 'Add Reminder';

  const preferences = useSelector(state => state.preferences);

  const [displayMultiSelectActions, setDisplayMultiSelectActions] = useState(false);

  // Follow-up reminders
  const [displayReminderModal, setReminderModal] = useState(false);

  // For AssignTo column
  const { userId, isRestrictedAgent, isAssistant } = useSelector(store => store.user);
  const { userInfo } = useSelector(store => store.userProfile);

  // For TaskPlans
  const [isTaskPlansFormModalOpen, setIsTaskPlansFormModalOpen] = useState(false);

  // For Mass Email
  const [isLoadingEmails, setIsLoadingEmails] = useState(false);

  const locationParams = parseParamsStr(search) || {};

  const { assignedTo, q, sortBy: currentSortColumn, status } = locationParams;

  const listType = getContactListType(q, status);
  const team = useSelector(store => store.team);
  const { groups, entities } = team || {};
  const teamList = getAssignedToOptions(team, 'agents');

  // For Social Connect
  const {
    isActive: socialConnectIsActive,
    isLoading: socialConnectIsLoading,
    assignedTo: socialConnectAssignedTo
  } = useSelector(store => store?.socialConnect);
  const inSocialConnect = q === 'social connect' || q === 'social connect 2';
  const agentsForDropdown = getAssignedToOptions(team, 'agents');

  const [assignedToDropdown, setAssignedToDropdown] = useState('all');
  const isTeamAccount = checkIfIsOnTeamAccount(groups.all);

  const unassignedAgentOption = !inSocialConnect ? { id: 'unassigned', value: 'Unassigned' } : null;
  const agentsDropDownItems = [{ id: 'all', value: 'All' }, ...agentsForDropdown, unassignedAgentOption].filter(
    Boolean
  );

  // Contact Sync
  const contactSync = useSelector(state => state.contactSync);
  const { accounts } = contactSync;
  const isContactSyncSetup = Object.keys(accounts).length > 0;
  const { allowNylasContactSync } = userInfo;
  const showContactSyncButton = displayMultiSelectActions && allowNylasContactSync;

  // Show/hide mass delete button
  const canDelete = userInfo.canDelete;

  const filteredList = list.filter(i => !ignoreData.ignoredContactsArray?.includes(i));

  const handleKeyUp = e => {
    if (e.key === 'Shift') {
      setIsShiftDown(false);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Shift') {
      setIsShiftDown(true);
    }
  };

  const shouldDisplayTeamMemberDropdown = () => {
    // leads listType is the contact status tabs.
    return (
      isTeamAccount &&
      (listType === 'leads' || inSocialConnect) &&
      (userInfo?.isResponsibleAgent || !isRestrictedAgent) &&
      !isFromDashboard &&
      !searchType
    );
  };

  const handleDeselectAll = () => {
    dispatch(setCurrentlySelectedContacts([]));
    dispatch(setSelectAll(false));
    setLocalSelectAll(false);
    if (q === 'selected') {
      navigate(defaultContactsPath);
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      dispatch(setCurrentlySelectedContacts(list));
      dispatch(setSelectAll(true));
      setLocalSelectAll(true);

      return;
    }

    // Clear selected list
    if (localSelectAll) {
      handleDeselectAll();
      return;
    }
    // Append current list to selected when selected is not empty
    dispatch(setCurrentlySelectedContacts(list));
    setLocalSelectAll(true);
  };

  useEffect(() => {
    const { ignoredContactsArray, deleteTimestamp } = ignoreData;

    if (ignoredContactsArray.length) {
      if (differenceInSeconds(new Date(), addDays(deleteTimestamp, 1)) >= 1) {
        dispatch(resetIgnoreData());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleKeyDown);
      if (!location.pathname.startsWith('/contacts')) {
        dispatch(setPageSize(PAGING_SIZE_OPTIONS[0].value));
        handleDeselectAll();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filteredCurrentlySelected.length < currentlySelected.length) {
      dispatch(setCurrentlySelectedContacts(filteredCurrentlySelected, true));
    }
  }, [filteredCurrentlySelected, dispatch, currentlySelected]);

  useEffect(() => {
    const showActions = currentlySelected.length > 0 && !isFromDashboard;

    setDisplayMultiSelectActions(showActions);
  }, [currentlySelected.length, isFromDashboard]);

  useEffect(() => {
    // Check if all contacts in current list are currently selected

    if (filteredList?.length > 0 && filteredList.every(i => currentlySelected.includes(i))) {
      if (!localSelectAll) {
        setLocalSelectAll(true);
        dispatch(setSelectAll(true));
      }
      return;
    }
    if (localSelectAll) {
      setLocalSelectAll(false);
    }
  }, [filteredList, currentlySelected, dispatch, localSelectAll, ignoreData, donePaging, pageHandler]);

  useEffect(() => {
    if (filteredList.length === 0 && !donePaging && pageHandler) {
      pageHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredList, pageHandler]);

  useEffect(() => {
    if (hasPageBeenRendered.current) {
      dispatch(resetPaging());
      return;
    }
    hasPageBeenRendered.current = true; // Skip effect on first load to avoid dupe searches
  }, [advancedSearch, dispatch, pathname, q]);

  useEffect(() => {
    if (isFromDashboard) {
      return;
    }
    let initialAssignedToDropdownState;

    if (entities[assignedTo]) {
      initialAssignedToDropdownState = `${entities[assignedTo].firstName} ${entities[assignedTo].lastName}`;
    } else if (assignedTo === 'all') {
      initialAssignedToDropdownState = 'All';
    } else if (assignedTo === 'unassigned') {
      initialAssignedToDropdownState = inSocialConnect ? 'all' : 'Unassigned';

      if (inSocialConnect) {
        const newParams = { ...locationParams, assignedTo: initialAssignedToDropdownState };
        const serializedParams = serializeToParamsStr(newParams);
        setAssignedToDropdown(initialAssignedToDropdownState);
        navigate(`${pathname}?${serializedParams}`);
        return;
      }
    } else {
      if (userInfo?.isResponsibleAgent || isAssistant) {
        initialAssignedToDropdownState = 'All';
      } else {
        initialAssignedToDropdownState = userInfo?.agentName;
        const newParams = { ...locationParams, assignedTo: userId };
        const serializedParams = serializeToParamsStr(newParams);

        setAssignedToDropdown(initialAssignedToDropdownState);
        navigate(`${pathname}?${serializedParams}`);
        return;
      }
    }

    setAssignedToDropdown(initialAssignedToDropdownState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedTo, dispatch, inSocialConnect, entities, userId, socialConnectAssignedTo, userId, isFromDashboard]);

  const handleEntitySelect = id => {
    if (isShiftDown && lastSelected) {
      const listClone = [...list];

      const intersection =
        listClone.indexOf(id) > listClone.indexOf(lastSelected)
          ? listClone.slice(listClone.indexOf(lastSelected), listClone.indexOf(id) + 1)
          : listClone.slice(listClone.indexOf(id), listClone.indexOf(lastSelected) + 1);

      if (currentlySelected.includes(id)) {
        dispatch(deleteSelectedContacts(intersection));
      } else {
        dispatch(setCurrentlySelectedContacts(Array.from(new Set([...currentlySelected, ...intersection]))));
      }
    } else {
      if (currentlySelected.includes(id)) {
        dispatch(deleteSelectedContacts([id]));
      } else {
        dispatch(setCurrentlySelectedContacts(Array.from(new Set([...currentlySelected, id]))));
      }
    }

    setLastSelected(id);
    if (id) {
      dispatch(getContactDetail({ id, shouldBeCached: true }));
    }
  };

  const handleShowContactTypeModal = () => {
    dispatch(setDisplayContactTypeModal(true));
  };

  const handleCloseContactTypeModal = () => {
    dispatch(setDisplayContactTypeModal(false));
  };

  const handleShowMassEmailModal = () => {
    const instancesCount = currentlySelected?.reduce((acc, key) => {
      const { emails } = contacts[key] || {};
      if (emails?.length > 0) {
        return acc + emails.length;
      }
      return acc;
    }, 0);

    if (instancesCount > MASS_ACTION_LIMIT) {
      dispatch(
        showMessage(
          {
            message: `A maximum of ${MASS_ACTION_LIMIT} emails can be selected to send as part of a group email at one time.`,
            type: 'error'
          },
          true
        )
      );
    } else {
      setIsLoadingEmails(true);

      dispatch(setDisplayMassEmailModal(true));
    }
  };

  const handleShowMassApplyTaskPlanModal = () => {
    setIsTaskPlansFormModalOpen(true);
  };

  const handleApplyPlanSubmitCallback = () => {
    setIsTaskPlansFormModalOpen(false);
  };

  const handleMassDelete = () => {
    const count = currentlySelected.length;
    const contactStr = pluralize(count, 'contact');
    if (count > MASS_ACTION_LIMIT) {
      dispatch(
        showMessage(
          {
            message: `A maximum of ${MASS_ACTION_LIMIT} contacts can be selected for mass actions.`,
            type: 'error'
          },
          true
        )
      );
      return;
    }

    const toRemove = new Set(currentlySelected);

    const newRecentContacts = recentContacts.filter(({ contact }) => !toRemove.has(contact.id));

    dispatch(savePreferences({ ...preferences, recentContacts: newRecentContacts }, preferences, true));

    dispatch(
      showAlert({
        hasConfirmDeleteInput: true,
        confirmDeleteInputValue: 'DELETE',
        hint: 'The selected contacts will be permanently deleted and will not be recoverable.',
        message: `Are sure you want to delete ${count} ${contactStr}?`,
        icon: 'delete',
        iconSize: 'm',
        primaryButtonLabel: `Yes, delete ${contactStr}`,
        primaryButtonHandler: () => {
          dispatch(massDeleteContacts(currentlySelected, currentGroup)).then(() => {
            const toRemove = new Set(currentlySelected);
            const newRecentContacts = recentContacts.filter(({ contact }) => !toRemove.has(contact.id));
            dispatch(savePreferences({ ...preferences, recentContacts: newRecentContacts }, preferences, true));
            pageHandler();
            dispatch(clearAlert());
            handleDeselectAll([]);
            const url = `contacts${location.search}`;
            navigate(url);
          });
        }
      })
    );
  };
  const syncHandler = () => {
    dispatch(syncContacts(currentlySelected));
    dispatch(clearAlert());
  };

  const handleShowContactSyncDialog = () => {
    const count = currentlySelected.length;
    const contactStr = pluralize(count, 'contact');

    if (!isContactSyncSetup) {
      dispatch(
        showAlert({
          ...setupContactSyncAlert,
          primaryButtonHandler: () => {
            navigate(`/settings/contact-sync`);
            dispatch(clearAlert());
          }
        })
      );
    } else if (count > 50) {
      dispatch(
        showMessage({ message: `A maximum of 50 contacts can be added to sync at one time.`, type: 'error' }, true)
      );
    } else {
      dispatch(
        showAlert({
          message: `Are you sure you want to add the selected ${contactStr} to the sync process?`,
          primaryButtonHandler: syncHandler,
          primaryButtonLabel: `Yes, sync ${contactStr}`,
          icon: 'contactsync',
          iconSize: 'm',
          hint: `${count} ${contactStr} selected.`
        })
      );
    }
  };

  const handleShowFollowupReminderModal = () => {
    setReminderModal(true);
  };

  const handleCloseFollowupReminderModal = () => {
    setReminderModal(false);
  };

  const handleToggleMassEmailModal = () => {
    dispatch(setDisplayMassEmailModal(!displayMassEmailModal));

    const url = `contacts${location.search}`;
    navigate(url);
  };

  const handleClearRecentContacts = () => {
    dispatch(savePreferences({ ...preferences, recentContacts: [] }, preferences, true)).then(() => {
      dispatch(clearAlert());
    });
  };

  const confirmClearRecentContactsHandler = () => {
    dispatch(
      showAlert({
        message: 'Are you sure you want to clear your recent contacts?',
        icon: 'delete',
        iconSize: 'm',
        primaryButtonLabel: 'Clear Recent Contacts',
        primaryButtonHandler: handleClearRecentContacts
      })
    );
  };

  const currentContactTab = Object.keys(tabData).find(id => {
    const tabListId = getContactListIdentifier(tabData[id].url);

    return tabListId === contactListId;
  });

  // Favorites has no label key, so we fallback to the title.
  const followupReminderLabel = tabData[currentContactTab]
    ? tabData[currentContactTab].label || tabData[currentContactTab].title
    : null;

  const showFollowUpReminderButton =
    !displayMultiSelectActions &&
    !['qall', 'qrecent'].includes(contactListId) &&
    followupReminderLabel &&
    !isSearchTab &&
    !isFromDashboard;

  const showClearRecentContactsButton = !displayMultiSelectActions && contactListId === 'qrecent' && list.length > 0;

  // Each list type has a default column;
  const defaultColumn = DEFAULT_CONTACT_LIST_COLUMNS[listType];
  const columnSortBy = currentSortColumn || defaultColumn;

  const changePageSize = e => {
    const { target } = e;
    const { value } = target;
    const newParams = { ...locationParams, pageSize: value };
    const serializedParams = serializeToParamsStr(newParams);

    dispatch(setPageSize(value));
    handleDeselectAll();

    navigate(`contacts?${serializedParams}`);
  };

  const handleSelectTeamMember = e => {
    const { target } = e;
    const { selectedIndex, value } = target;
    const { id } = target[selectedIndex];

    const newParams = { ...locationParams, assignedTo: id };
    const serializedParams = serializeToParamsStr(newParams);

    setAssignedToDropdown(value);

    navigate(`contacts?${serializedParams}`);
  };

  const containerStyles = classnames({
    [styles.listContainer]: true,
    [styles[`${columnSortBy}Sort`]]: true
  });

  const listHeaderStyles = classnames({
    [contactStyles.listHeader]: !isFromDashboard,
    [contactStyles.listHeaderNoAssignedTo]: !isTeamAccount && !isFromDashboard,
    [contactStyles.dashboardListHeader]: isFromDashboard
  });

  const listHeaderColumns = getColumnData(isTeamAccount, isFromDashboard);
  const displaySelected = isTabletPortraitAndUp ? 'selected' : 'slctd';
  const searchText = !!searchType ? getAdvancedText(advancedSearch, teamList, isRestrictedAgent) : searchTerm;

  const renderList = () => {
    return (
      <>
        {!isFromDashboard && (
          <div className={styles.listActionsHeader}>
            <div className={styles.listActions}>
              {isTabletPortraitAndUp && !isFromDashboard && (
                <section className={styles.listActionsCheck}>
                  <FormCheckBox id="msSelectAll" isChecked={localSelectAll} changeHandler={handleSelectAll} />
                </section>
              )}
              {displayMultiSelectActions && (
                <ButtonGroup variant="icon">
                  <Tag
                    entityId="deselectAll"
                    label={`${currentlySelected.length} ${displaySelected}`}
                    handleRemove={handleDeselectAll}
                    removable
                    removeTooltip="Deselect all"
                  />
                  <Button
                    onClick={handleShowContactTypeModal}
                    ariaLabel="Add contact type"
                    title="Contact Type"
                    icon="tag"
                    size="m"
                    tooltipPos="below"
                    data-cy="addContactTypeFromListButton"
                  />
                  {showContactSyncButton && (
                    <Button
                      onClick={handleShowContactSyncDialog}
                      ariaLabel="Add to contact sync"
                      title="Contact Sync"
                      icon="contactsync"
                      size="m"
                      tooltipPos="below"
                      data-cy="addContactSyncFromListButton"
                    />
                  )}
                  <Button
                    onClick={handleShowMassEmailModal}
                    ariaLabel="Send group email"
                    title="Send group email"
                    icon="email"
                    size="m"
                    tooltipPos="below"
                    isLoading={isLoadingEmails}
                    disabled={isLoadingEmails}
                    data-cy="sendGroupEmailFromListButton"
                  />
                  <Button
                    onClick={handleShowMassApplyTaskPlanModal}
                    ariaLabel="Apply task plan"
                    title="Apply task plan"
                    icon="plan"
                    size="m"
                    tooltipPos="below"
                    data-cy="applyPlanFromListButton"
                  />
                  {canDelete && (
                    <Button
                      onClick={handleMassDelete}
                      ariaLabel="Delete selected contacts"
                      title="Delete selected contacts"
                      icon="delete"
                      size="m"
                      tooltipPos="below"
                    />
                  )}
                </ButtonGroup>
              )}
            </div>
            <Filters isLoading={isLoading} title="Filter contacts">
              <div className={tabContainerStyles.filterContainer}>
                <section className={tabContainerStyles.filterSection}>
                  <FormLabel htmlFor="pagingDropdown" className={tabContainerStyles.filterLabel}>
                    Page size
                  </FormLabel>
                  <Dropdown
                    id="pagingDropdown"
                    value={pageSize}
                    items={PAGING_SIZE_OPTIONS}
                    onChange={changePageSize}
                    size="s"
                  />
                </section>
                {showFollowUpReminderButton && (
                  <section className={tabContainerStyles.filterSection}>
                    <FormLabel htmlFor="startDate" className={tabContainerStyles.filterLabel}>
                      Follow-up reminder
                    </FormLabel>

                    <Button
                      icon="reminder"
                      label={followUpButtonLabel}
                      ariaLabel={followUpButtonLabel}
                      data-cy="addReminderButton"
                      onClick={handleShowFollowupReminderModal}
                    />
                  </section>
                )}
                {shouldDisplayTeamMemberDropdown() && (
                  <section className={tabContainerStyles.filterSection}>
                    <FormLabel htmlFor="startDate" className={tabContainerStyles.filterLabel}>
                      Assigned to
                    </FormLabel>
                    <Dropdown
                      id="agentsDropdown"
                      value={assignedToDropdown}
                      items={agentsDropDownItems}
                      onChange={handleSelectTeamMember}
                      size="s"
                    />
                  </section>
                )}
              </div>
            </Filters>
            <div className={styles.listActionsRightAligned}>
              {(showClearRecentContactsButton || isSearchTab) && (
                <>
                  {showClearRecentContactsButton && (
                    <Button
                      icon="delete"
                      label="Clear Recent Contacts"
                      ariaLabel="Clear Recent Contacts"
                      onClick={confirmClearRecentContactsHandler}
                    />
                  )}
                  {isSearchTab && !displayMultiSelectActions && (
                    <>
                      <em className={styles.em}>{searchCount}</em> {pluralize(searchCount, 'result')} for{' '}
                      <em className={styles.em}>{searchText}</em>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <div>
          <ListHeader
            columns={listHeaderColumns}
            sortableColumns={
              !isFromDashboard ? (!['qrecent', 'qselected'].includes(contactListId) ? SORTABLE_COLUMNS : []) : []
            }
            location={location}
            className={listHeaderStyles}
          />
          <List
            noResultsIcon="contact"
            headerHeightOffset={isFromDashboard ? 50 : q === 'all' ? 285 : 325}
            donePaging={donePaging}
            pageHandler={pageHandler}
            isVirtuoso={!isFromDashboard}
            listClassName={listClassName}
          >
            {list
              .filter(id => !ignoreData.ignoredContactsArray?.includes(id))
              .map(id => {
                const data = contacts[id];
                const { lastTouchPoint, whenCreated } = data || {};
                const followUpDate = lastTouchPoint ? lastTouchPoint.dateUtc : whenCreated;
                // We need to account for followUpDate being undefined.

                const numberOfDaysFromToday = followUpDate
                  ? getNumberOfDaysFromToday(convertDateUtcToLocal(followUpDate))
                  : 0;
                const reminderInDays = followUpReminders?.[contactListId];
                const followupWindow = !isFromDashboard ? getFollowupWindow(reminderInDays) : false;
                const upcomingFollowUp = !isFromDashboard
                  ? isFollowUpUpcoming(reminderInDays, numberOfDaysFromToday, followupWindow)
                  : false;
                const lateFollowUp = !isFromDashboard
                  ? isFollowUpOverdue(reminderInDays, numberOfDaysFromToday, followupWindow)
                  : false;

                const contactIdFromURL = (() => {
                  const pathFragments = pathname.split('contacts/');

                  if (pathFragments.length < 2) {
                    return null;
                  }

                  const idPart = pathFragments?.[1]?.split('/')?.[0];

                  return idPart;
                })();

                // When in dashboard set isChecked by id from url
                const isChecked = !isFromDashboard
                  ? currentlySelected.indexOf(id) !== -1
                    ? true
                    : false
                  : contactIdFromURL === id;
                const listItemClasses = classnames({
                  [styles.listItem]: true,
                  [styles.followup]: lateFollowUp || upcomingFollowUp,
                  [styles.lateFollowUp]: lateFollowUp,
                  [styles.upcomingFollowUp]: upcomingFollowUp,
                  [styles.isChecked]: isChecked
                });

                return (
                  <div key={id} className={listItemClasses}>
                    <ContactCard
                      key={id}
                      data={data}
                      isChecked={isChecked}
                      handleEntitySelect={handleEntitySelect}
                      location={location}
                      handleClick={props.handleCardClick}
                      {...props}
                    />
                  </div>
                );
              })}
          </List>
        </div>
      </>
    );
  };

  const renderSocialConnectStates = () => {
    if (socialConnectIsLoading) {
      return <Loading isLoading={true} />;
    }

    if (userInfo.hasFacebookAdLicense) {
      if (socialConnectIsActive === false) {
        return (
          <div className={socialConnectStyles.document}>
            <SocialConnectEmptyLanding isInSetupMode={true} />
          </div>
        );
      } else {
        return renderList();
      }
    } else {
      return (
        <div className={socialConnectStyles.document}>
          <SocialConnectEmptyLanding />
        </div>
      );
    }
  };

  useEffect(() => {
    if (q === 'social connect' || q === 'social connect 2') {
      dispatch(getCampaignCheck({ agentId: userId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q]);

  return (
    <>
      <Container className={containerStyles}>
        {/* @TODO: ¿Turn this into its own component? */}
        <View>{inSocialConnect ? renderSocialConnectStates() : renderList()}</View>
      </Container>

      {displayContactTypeModal && (
        <ContactTypeModal
          listOfSelected={currentlySelected}
          isOpen={displayContactTypeModal}
          clearHandler={handleCloseContactTypeModal}
        />
      )}

      {isTaskPlansFormModalOpen && (
        <TaskPlanModal
          isOpen={isTaskPlansFormModalOpen}
          closeHandler={() => setIsTaskPlansFormModalOpen(false)}
          testId={'TaskPlansMassApply'}
          ownerType={OWNER_TYPE.contacts}
          selectedContacts={currentlySelected}
          handleRemoveContact={handleEntitySelect}
          handleApplyPlanSubmitCallback={handleApplyPlanSubmitCallback}
        />
      )}

      {displayReminderModal && (
        <FollowUpReminderModal
          isOpen={displayReminderModal}
          clearHandler={handleCloseFollowupReminderModal}
          contactListId={contactListId}
          currentDays={followUpReminderDays}
          currentTabLabel={followupReminderLabel}
        />
      )}
      {displayMassEmailModal && (
        <MassEmailModal
          listOfSelected={currentlySelected}
          isOpen={displayMassEmailModal}
          clearHandler={handleToggleMassEmailModal}
        />
      )}
    </>
  );
};

ContactList.propTypes = {
  entity: object,
  tabData: object,
  isSearchTab: bool
};

export default ContactList;
