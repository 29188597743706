import React, { Fragment } from 'react';
import { bool, func, number, object, oneOf, oneOfType, string } from 'prop-types';
import { Link, Match } from '@reach/router';
import classnames from 'classnames';

import { Icon, Loading, Tag } from '../';
import { UNDELETABLE_TABS } from '../../pages/Contacts/Contacts';
import { buildTestId, parseParamsStr } from '../../utils';
import { decodeURIComponentSafe } from '../../utils/strings';

import styles from './Tab.css';

const TabChildren = ({ count, displayIsSelected, icon, iconLabel, isLoading, isRemovable, label }) => {
  const showTabLoader = isLoading && displayIsSelected;
  const displayIcon = icon && isRemovable ? 'delete' : icon;

  const labelClasses = classnames({
    [styles.label]: true,
    [styles.tabLoading]: showTabLoader
  });

  return (
    <Fragment>
      <Icon name={displayIcon} size="s" className={styles.tabIcon} isColored={displayIsSelected} label={iconLabel} />
      <span className={labelClasses}>{label}</span>
      <Loading loading={showTabLoader} />
      {count !== 0 && <Tag label={count} styleType="count" />}
    </Fragment>
  );
};

export const Tab = React.forwardRef((props, ref) => {
  const {
    className,
    count,
    draggingOver,
    icon,
    iconLabel,
    id,
    isDragging,
    isLoading = false,
    isSelected,
    label,
    matchKey = 'pathname',
    matchParams,
    onClick,
    replace = false,
    size = 'm',
    style,
    title,
    url,
    testId,
    ...otherProps
  } = props;

  if (!label && !icon) {
    return null;
  }

  const isRemovable = !UNDELETABLE_TABS.includes(id) && !!isDragging && !draggingOver;

  const classes = classnames({
    [styles.tab]: !className,
    [styles.withIcon]: icon && label,
    [styles.iconOnly]: icon && !label,
    [styles.isDragging]: !!isDragging,
    [styles.isRemovable]: isRemovable,
    [className]: !!className
  });

  const linkClasses = classnames({
    [styles.tabLink]: !className,
    [styles[`${size}Size`]]: true
  });

  return (
    <Match path={url}>
      {matchProps => {
        const getSelectedState = (isSelected, matchProps) => {
          if (isSelected !== undefined) {
            return isSelected;
          }

          // Note: the matchKey prop is used to as a matcher against the app's location.
          // We use the matchKey or matchParams to decide whether the tab should be selected.
          const { location } = matchProps;
          const { pathname, search } = location;
          const locationParamsObject = parseParamsStr(search) || {};
          const { searchType } = locationParamsObject;

          let paramsMatch = false;
          for (const param in matchParams) {
            const paramToDecode = matchParams[param];
            const decodedMatchParam = paramToDecode ? decodeURIComponentSafe(paramToDecode) : paramToDecode;
            paramsMatch = decodedMatchParam === locationParamsObject[param];
            if (paramsMatch === false) {
              break;
            }
          }

          // A tab is styled as selected under four scenarios:
          // 1- the tab loosely matches a route view the match params.
          // 2- the url matches the pathname and search from location.
          // 3- the matchKey starts with the url (this scenario exists so we can fuzzy match against non-critical params like sort).
          // 4 - the matchKey query matches the url query - to select tab if queries match
          const urlQuery = parseParamsStr(url)
            ?.q?.toLowerCase()
            ?.replace(/is:(\s+)/, 'is:');
          const matchKeyQuery = parseParamsStr(location[matchKey])
            ?.q?.toLowerCase()
            ?.replace(/is:(\s+)/, 'is:');

          return matchParams
            ? paramsMatch && !searchType
            : matchKey === 'url'
            ? decodeURIComponentSafe(`${pathname}${search}`) === decodeURIComponentSafe(url)
            : decodeURIComponentSafe(location[matchKey]).startsWith(decodeURIComponentSafe(url)) ||
              !!(matchKeyQuery && matchKeyQuery === urlQuery) ||
              (title === 'search' && searchType === 'advanced');
        };

        const displayIsSelected = getSelectedState(isSelected, matchProps);
        const isExternalLink = url?.startsWith('http');
        const safeAttrs = isExternalLink
          ? {
              target: '_blank',
              rel: 'noopener'
            }
          : null;

        const tabChildrenProps = {
          count,
          displayIsSelected,
          icon,
          iconLabel,
          isLoading,
          isRemovable,
          label
        };

        return (
          <li
            ref={ref}
            className={classes}
            title={title || label}
            data-selected={displayIsSelected}
            data-testid={testId}
            onClick={onClick}
            style={style}
            {...otherProps}
          >
            {isExternalLink ? (
              <a href={url} className={linkClasses} {...safeAttrs} data-testid={buildTestId(testId, 'link')}>
                <TabChildren {...tabChildrenProps} />
              </a>
            ) : (
              <Link to={url} replace={replace} className={linkClasses} data-testid={buildTestId(testId, 'link')}>
                <TabChildren {...tabChildrenProps} />
              </Link>
            )}
          </li>
        );
      }}
    </Match>
  );
});

Tab.propTypes = {
  className: string,
  count: oneOfType([string, number]),
  draggingOver: string,
  icon: string,
  iconLabel: string,
  id: string,
  isLoading: bool,
  isSelected: bool,
  label: string,
  isDragging: bool,
  matchKey: string,
  matchParams: object,
  onClick: func,
  replace: bool,
  size: oneOf(['s', 'm', 'l']),
  title: string,
  url: string
};
