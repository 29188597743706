/** @module */

export const CREATE_ZILLOW_INTEGRATION = 'CREATE_ZILLOW_INTEGRATION';
export const CREATE_ZILLOW_INTEGRATION_SUCCESS = 'CREATE_ZILLOW_INTEGRATION_SUCCESS';
export const DELETE_ZILLOW_INTEGRATION = 'DELETE_ZILLOW_INTEGRATION';
export const DELETE_ZILLOW_INTEGRATION_SUCCESS = 'DELETE_ZILLOW_INTEGRATION_SUCCESS';
export const GET_ZILLOW_INTEGRATION_STATUS = 'GET_ZILLOW_INTEGRATION_STATUS';
export const GET_ZILLOW_INTEGRATION_STATUS_SUCCESS = 'GET_ZILLOW_INTEGRATION_STATUS_SUCCESS';
export const SET_ZILLOW_OAUTHURL = 'SET_ZILLOW_OAUTHURL';
export const SET_OAUTH_IS_LOADING = 'SET_OAUTH_IS_LOADING';

export const initialState = {
  oAuthIsLoading: false,
  oauthUrl: null,
  zillowStatusIsLoading: false,
  zillowStatus: null,
  zillowContactStatuses: [
    {
      internalStatusId: 10,
      externalSourceId: 1,
      externalStatusId: '500',
      externalStatusName: 'New'
    },
    {
      internalStatusId: 10,
      externalSourceId: 1,
      externalStatusId: '505',
      externalStatusName: 'Attempted contact'
    },
    {
      internalStatusId: 20,
      externalSourceId: 1,
      externalStatusId: '510',
      externalStatusName: 'Spoke with customer'
    },
    {
      internalStatusId: 20,
      externalSourceId: 1,
      externalStatusId: '515',
      externalStatusName: 'Appointment set'
    },
    {
      internalStatusId: 20,
      externalSourceId: 1,
      externalStatusId: '520',
      externalStatusName: 'Met with customer'
    },
    {
      internalStatusId: 60,
      externalSourceId: 1,
      externalStatusId: '530',
      externalStatusName: 'Submitting offers'
    },
    {
      internalStatusId: 60,
      externalSourceId: 1,
      externalStatusId: '535',
      externalStatusName: 'Listing agreement'
    },
    {
      internalStatusId: 60,
      externalSourceId: 1,
      externalStatusId: '540',
      externalStatusName: 'Active listing'
    },
    {
      internalStatusId: 60,
      externalSourceId: 1,
      externalStatusId: '545',
      externalStatusName: 'Under contract'
    },
    {
      internalStatusId: 70,
      externalSourceId: 1,
      externalStatusId: '550',
      externalStatusName: 'Sale closed'
    },
    {
      internalStatusId: 40,
      externalSourceId: 1,
      externalStatusId: '555',
      externalStatusName: 'Nurture'
    },
    {
      internalStatusId: 80,
      externalSourceId: 1,
      externalStatusId: '560',
      externalStatusName: 'Rejected'
    },
    {
      internalStatusId: 60,
      externalSourceId: 1,
      externalStatusId: '565',
      externalStatusName: 'Showing homes'
    }
  ]
};

export const zillowReducer = (state = initialState, action = {}) => {
  const { type = 'default', oauthUrl, oAuthIsLoading, zillowStatus } = action;

  switch (type) {
    case GET_ZILLOW_INTEGRATION_STATUS:
    case CREATE_ZILLOW_INTEGRATION:
    case DELETE_ZILLOW_INTEGRATION:
      return {
        ...state,
        zillowStatusIsLoading: true
      };
    case CREATE_ZILLOW_INTEGRATION_SUCCESS:
    case DELETE_ZILLOW_INTEGRATION_SUCCESS:
      return {
        ...state,
        zillowStatusIsLoading: false
      };
    case SET_OAUTH_IS_LOADING:
      return {
        ...state,
        oAuthIsLoading
      };
    case SET_ZILLOW_OAUTHURL:
      return {
        ...state,
        oAuthIsLoading: false,
        oauthUrl
      };
    case GET_ZILLOW_INTEGRATION_STATUS_SUCCESS:
      return {
        ...state,
        oAuthIsLoading: false,
        zillowStatusIsLoading: false,
        zillowStatus
      };

    default:
      return state;
  }
};
