import React from 'react';
import { bool, number } from 'prop-types';
import { useSlate } from 'slate-react';

import { EditorCommands } from '../editor-commands';
import { fontSizes, fontSizeKeywordMap } from '../Nodes/Leaf';
import { MenuButton } from '../../MenuButton';

/**
 * React component for rendering the ButtonFontSize component.
 * @param {Object} props ButtonFontSize component props.
 * @param {String} [props.currentFontSize=2] The current font size.
 * @param {Boolean} [props.isDisabled] The button's disabled state.
 */
export const ButtonFontSize = props => {
  const editor = useSlate();
  const { currentFontSize = 2, isDisabled } = props;

  const menuItems = fontSizes.map(fontSize => {
    const { id, label, size } = fontSize;
    const fontSizeAsKeyword = id === 'regular' ? 'inherit' : fontSizeKeywordMap.get(size);

    return {
      id,
      isSelected: size === currentFontSize,
      label,
      onSelect: () => EditorCommands.handleMarkStyleChange(editor, 'fontSize', size),
      style: {
        fontSize: fontSizeAsKeyword
      }
    };
  });

  return (
    <MenuButton
      disabled={isDisabled}
      icon="fontsize"
      menuItems={menuItems}
      position="above"
      showArrow={true}
      toolTip="Size"
      tooltipPos="below"
    />
  );
};

ButtonFontSize.propTypes = {
  currentFontSize: number,
  isDisabled: bool
};
