/**
 * Validates whether a string has a digit (number) in it.
 * @param {String} str - a string to validate.
 */
export const hasDigit = str => {
  return /\d/.test(str);
};

/**
 * Validate that the value is set and not an empty string.
 * @param {String} value - the field's value.
 */
export const isFieldFilled = value => {
  return !!(value != null && value !== '');
};
