import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { navigate } from '@reach/router';

import { Dialog, DialogHeader } from '../Dialog';
import TransactionForm from './TransactionForm';
import { TRANSACTIONS_FORM_TOGGLE } from '../../reducers/transactions';
import { parseParamsStr, serializeToParamsStr } from '../../utils/strings';

export const toggleTransactionFormDialog = (e, dispatch, location) => {
  e.preventDefault();
  const { pathname, search } = location;

  dispatch({
    type: TRANSACTIONS_FORM_TOGGLE
  });

  const currentParams = parseParamsStr(search) || {};
  const { mode, ...newParams } = currentParams;

  const newParamsStr = serializeToParamsStr(newParams);
  const newPath = newParamsStr.length > 0 ? `${pathname}?${newParamsStr}` : pathname;
  navigate(newPath);
};

const TransactionFormDialog = props => {
  const { clearHandler, contactId, isOpen, location, title } = props;

  return (
    <Dialog isOpen={isOpen}>
      <DialogHeader title={title} icon="transaction" clearHandler={clearHandler} />
      <TransactionForm contactId={contactId} location={location} />
    </Dialog>
  );
};

export default TransactionFormDialog;

TransactionFormDialog.propTypes = {
  clearHandler: func,
  contactId: string,
  isOpen: bool,
  location: object.isRequired,
  title: string.isRequired
};
