import { string } from 'prop-types';
import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ListPlaceholder } from '../../components';
import { MessageBanner } from '../../components/MessageBanner';
import dashboardStyles from './Dashboard.css';
import styles from './MarketSnapshotStats.css';
import { navigate } from '@reach/router';

export const MarketSnapshotStats = ({ data }) => {
  if (!data) {
    return <ListPlaceholder rows={3} />;
  }

  const isAllZero = data.every(item => item.marketSnapshots === 0 && item.listingAlerts === 0);
  const integerTickFormatter = value => {
    return Number.isInteger(value) ? value.toString() : '';
  };
  const dataMax = data.reduce((acc, val) => {
    const localMax = Math.max(val.marketSnapshots, val.listingAlerts);
    return localMax > acc ? localMax : acc;
  }, 0);

  const tickCount = dataMax > 0 ? dataMax + 1 : 0;

  const handleLegendClick = e => {
    const { value } = e;

    if (value === 'marketSnapshots') {
      navigate('/marketing/market-snapshots');
      return;
    }

    navigate('/marketing/listing-alerts');
  };
  return (
    <div className={dashboardStyles.msContainer}>
      {isAllZero && <MessageBanner icon="snapshot" message="No activity data available for this week." />}
      <ResponsiveContainer width={'99%'} height={'99%'}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: -10,
            bottom: 5
          }}
          barGap={5}
          barCategoryGap={10}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" className={styles.chartStyles} />
          <YAxis tickCount={tickCount} tickFormatter={integerTickFormatter} className={styles.chartStyles} />
          <Tooltip
            formatter={(value, name) => {
              if (name === 'marketSnapshots') {
                return [value, 'Market Snapshots'];
              }
              return [value, 'Listing Alerts'];
            }}
            className={styles.chartStyles}
          />
          <Legend
            formatter={value => {
              if (value === 'marketSnapshots') {
                return <span className={styles.legendStyles}>Market Snapshots</span>;
              }
              return <span className={styles.legendStyles}>Listing Alerts</span>;
            }}
            iconSize="28"
            onClick={handleLegendClick}
            wrapperStyle={{ cursor: 'pointer' }}
          />
          <Bar dataKey="marketSnapshots" fill="#004b8d" barSize={28} radius={[0, 0, 0, 0]} />
          <Bar dataKey="listingAlerts" fill="#39b54a" barSize={28} radius={[0, 0, 0, 0]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

MarketSnapshotStats.propTypes = {
  className: string
};
