import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Loading.css';
import { Icon } from '../';

export const Loading = props => {
  const { children = <Icon name="loading" size={props.size || 'xl'} />, className, loading = true } = props;

  if (!loading) {
    return false;
  }

  const classes = classnames({
    [styles.loading]: !className,
    [className]: !!className
  });

  return <div className={classes}>{children}</div>;
};

Loading.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool
};
