import React from 'react';

import { Icon } from '../';

import styles from './Dropdown.css';

export const NoResultsItem = () => {
  return (
    <li className={styles.noResults}>
      <Icon name="error" size="s" /> Sorry, we couldn't find a match.
    </li>
  );
};
