import React from 'react';
import { oneOf, string } from 'prop-types';
import classnames from 'classnames';

import taskStyles from '../../pages/Tasks/Tasks.css';

const Priorities = {
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3
};

export const PriorityIndicator = props => {
  const { priority = Priorities.MEDIUM, testId = 'PriorityIndicator' } = props;

  const priorityClasses = classnames({
    [taskStyles.priority]: true,
    [taskStyles[`priority${priority}`]]: true
  });

  return (
    <svg data-testid={testId} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={priorityClasses}>
      <circle r="8" cy="12" cx="12" />
    </svg>
  );
};

PriorityIndicator.propTypes = {
  priority: oneOf(Object.values(Priorities)),
  testId: string
};
