/** @module */

import { DRAWER_SET, DRAWER_CLEAR, DRAWER_CLEAR_EMAIL, DRAWER_MINIMIZE } from '../reducers/drawer';

/**
 * Shows an drawer via <Drawer />.
 * @param {Object} data - a data object that represents the details and actions of the drawer.
 */
export const showDrawer = data => {
  return dispatch => {
    dispatch({
      type: DRAWER_SET,
      data
    });
  };
};

/**
 * Clears the drawers initial email.
 */
export const clearDrawerEmail = () => {
  return dispatch => {
    dispatch({
      type: DRAWER_CLEAR_EMAIL
    });
  };
};

/**
 * Closes the <Drawer />
 */
export const clearDrawer = () => {
  return dispatch => {
    dispatch({
      type: DRAWER_CLEAR
    });
  };
};

export const minimizeDrawer = () => {
  return dispatch => {
    dispatch({
      type: DRAWER_MINIMIZE
    });
  };
};
