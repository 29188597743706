/** @module */

import { CONTACT_SYNC_ACCOUNTS, CONTACT_SYNC_ACCOUNTS_DELETE } from '../reducers/contactSync';
import { CONTACTS_UPDATE_CONTACT } from '../reducers/contacts';
import { getMergedEntities, request, requestError } from '../utils';
import { SYNC_STATUSES } from '../utils/sync';
import { showMessage } from './message';

const errorMsg = 'Issue authenticating with Nylas email service.';
const showReauthorizeMessage = () => {
  return showMessage({
    message: `Please reauthorize your contact account in settings.`,
    actionLabel: 'Settings',
    actionUrl: '/settings/contact-sync'
  });
};

/**
 * Request for getting contact sync account
 */
export const getContactSyncAccounts = options => {
  const { forceRefresh = false, nylasSyncType = 'contact', shouldBeCached } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      forceRefresh,
      method: 'GET',
      path: 'getAccountBySyncTypeAsync',
      params: {
        nylasSyncType
      },
      shouldBeCached
    };

    try {
      const response = await request(requestOptions);
      const { exception, status } = response;
      if (exception) {
        throw exception;
      }
      const data = getMergedEntities([], response.data, { idKey: 'NylasAccountId' });

      if (status === 200) {
        dispatch({
          type: CONTACT_SYNC_ACCOUNTS,
          data: data
        });
      }
    } catch (error) {
      // Sync not set up
      if (error?.response?.status === 404) {
        return;
      }
      requestError(error, dispatch);
    }
  };
};

export const getNylasAuthUrl = options => {
  const callbackUrl = '/settings/contact-sync';

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      method: 'POST',
      path: 'getAuthorizeUrl',
      payload: {
        ...options,
        callbackUrl: `${window.location.origin}${callbackUrl}`
      },
      shouldBeCached: false
    };

    try {
      const { data, status } = await request(requestOptions);
      const { exception } = data;

      if (exception || status !== 200) {
        const errorToThrow = exception ? exception : { message: errorMsg };
        throw errorToThrow;
      }

      const url = new URL(data.url).toString();
      return url;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Authenticate with Nylas.
 * @param {String} code - the url parameter returned by the email provider must be sent to Nylas.
 */
export const authenticateUsingNylas = (code, email) => {
  const callbackUrl = '/settings/contact-sync';

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      method: 'POST',
      path: 'authorizeAccount',
      params: {
        code,
        emailAddress: email,
        nylas3SyncType: 'contact',
        callbackUrl: `${window.location.origin}${callbackUrl}`
      },
      shouldBeCached: false
    };

    try {
      const { data, status } = await request(requestOptions);
      const { exception } = data;

      if (exception || status !== 200) {
        const errorToThrow = exception ? exception : { message: errorMsg };
        throw errorToThrow;
      }

      dispatch(getContactSyncAccounts({ forceRefresh: true }));
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Deletes an email accounts from TP DB & Nylas.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 */
export const deleteEmailAccount = (accountId, version) => {
  return async dispatch => {
    const requestOptions =
      version === 3
        ? {
            apiServiceType: 'nylasV3',
            baseUrlKey: 'api',
            method: 'DELETE',
            path: `deleteAccount`,
            params: {
              grantId: accountId,
              nylas3SyncType: 'contact'
            },
            shouldBeCached: false
          }
        : {
            apiServiceType: 'contactSync',
            baseUrlKey: 'api',
            method: 'DELETE',
            path: `accounts/${accountId}`,
            shouldBeCached: false
          };
    try {
      const response = await request(requestOptions);
      const { data } = response;
      const { exception } = data;

      const status = data?.status || data?.Status;
      if (exception || status !== 'success') {
        const errorToThrow = exception ? exception : { message: errorMsg };
        throw errorToThrow;
      }

      dispatch({ type: CONTACT_SYNC_ACCOUNTS_DELETE });

      return data;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Request for getting contact sync status
 */
export const getContactSyncStatus = options => {
  const { forceRefresh = false, shouldBeCached } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contactSync',
      baseUrlKey: 'api',
      forceRefresh,
      path: 'autosyncstatus', // Previous api path of `status` still exists, `autosyncstatus` uses Nylas web hooks.
      shouldBeCached
    };

    try {
      const response = await request(requestOptions);
      const { exception, status } = response;
      if (exception) {
        throw exception;
      }

      if (status === 200) {
        const data = response.data;
        if (data?.Status && SYNC_STATUSES[data.Status.toLowerCase()]?.statusIsPositive === false) {
          dispatch(showReauthorizeMessage());
        }
        return Promise.resolve(data?.Status);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      requestError(error, dispatch);
      return Promise.reject(error);
    }
  };
};

/**
 * Adds an array of contactIds to Nylas Contact Sync.
 * @param {Array} contactIds - an array of up to 50 contact IDs.
 */
export const syncContacts = contactIds => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contactSync',
      baseUrlKey: 'api',
      method: 'POST',
      path: 'addToSync',
      payload: {
        contactIds
      },
      shouldBeCached: false
    };

    try {
      const response = await request(requestOptions);

      const { status, exception } = response;
      if (exception || status !== 200) {
        const errorToThrow = exception ? exception : { message: 'Failed to add contact(s) to the sync process.' };
        throw errorToThrow;
      }

      const successMessageFragment = contactIds.length > 1 ? 'Contacts were ' : 'Contact was';

      dispatch(
        showMessage({ message: `${successMessageFragment} added to sync successfully.`, type: 'success' }, true)
      );

      if (contactIds.length === 1) {
        dispatch({
          type: CONTACTS_UPDATE_CONTACT,
          contactId: contactIds[0],
          updatedKeys: { hasContactSync: true }
        });
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Request for getting contact batch sync status
 */
export const getBatchSyncStatus = options => {
  const { forceRefresh = false, shouldBeCached } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contactSync',
      baseUrlKey: 'api',
      forceRefresh,
      path: 'batchsyncstatus',
      shouldBeCached
    };

    try {
      const response = await request(requestOptions);
      const { exception, status } = response;

      if (exception) {
        throw exception;
      }

      if (status === 200) {
        const data = response.data;
        return Promise.resolve(data?.Status);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      requestError(error, dispatch);
      return Promise.reject(error);
    }
  };
};

/**
 * Force start contact batch sync
 */
export const startBatchSync = () => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contactSync',
      baseUrlKey: 'api',
      method: 'POST',
      path: 'startbatchsync',
      shouldBeCached: false
    };

    try {
      const response = await request(requestOptions);

      const { status, exception } = response;

      if (exception || status !== 200) {
        const errorToThrow = exception ? exception : { message: 'Failed to start contact sync.' };
        throw errorToThrow;
      }

      dispatch(showMessage({ message: `Contact sync started successfully.`, type: 'success' }, true));
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};
