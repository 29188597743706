import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import { Button, Popover } from '../';
import { withMediaQueryContextHOC } from '../MediaQueryProvider/MediaQueryProvider';
import { ALLOWED_SEARCH_QUERIES } from '../../constants';
import { MAX_RECENT_SEARCHES } from '../../constants/search';
import { getParamStr, parseParamsStr, searchAndRemoveFromStorage } from '../../utils';

import { trackEvent } from '../../utils/analytics';
import { savePreferences } from '../../actions/preferences';
import { activateAdvancedSearch, showAdvancedSearch } from '../../actions/advancedSearch';

import { AdvancedSearchForm } from '../../components/AdvancedSearchForm/AdvancedSearchForm';
import { LookupSearch } from '../../components/Lookup/LookupSearch';

import styles from './Search.css';

class Search extends PureComponent {
  constructor(props) {
    super(props);
    const { location } = props;

    this.formRef = React.createRef();
    this.advancedSearchRef = React.createRef();

    this.state = { search: this.getSearchStrFromLocation(location) };
  }

  handleChange = e => {
    const { target } = e;
    this.setState({ search: target.value });
  };

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location.search === prevProps.location.search) {
      return;
    }

    this.setState({ search: this.getSearchStrFromLocation(location) });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.removePopover);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.removePopover);
  }

  removePopover = e => {
    const shouldRemove = this.props.advancedSearch.isOpen && !this.advancedSearchRef?.current?.contains(e.target);
    if (shouldRemove && e.target.id !== 'AdvancedSearchButton') {
      this.props.showAdvancedSearch(false);
    }
  };

  getSearchStrFromLocation = location => {
    // When navigating around the app, we need to make sure the search field populates
    // with the proper term when viewing a page of search results
    // we also need to make sure we clear the field when on a contact list query or a leads list
    const { q } = parseParamsStr(location.search) || {};

    const isSearchQuery = q && !ALLOWED_SEARCH_QUERIES.includes(q);

    return isSearchQuery ? q.replace(/\+g/, ' ') : '';
  };

  handleSubmit = e => {
    e.preventDefault();

    const searchTerm = getParamStr(this.state.search);

    // If searchTerm is null then no need to navigate
    if (!searchTerm) {
      return;
    }

    this.handleSearch(searchTerm);
  };

  handleSelect = item => {
    const { searchTerm } = item;

    const search =
      item.type === 'letterSearch'
        ? this.state.search.toUpperCase()
        : item.type === 'searchSuggestion'
        ? encodeURIComponent(this.state.search)
        : searchTerm;

    // Do nothing if search is null or undefined.
    if (!search) {
      return;
    }

    this.handleSearch(search);
  };

  handleSearch = searchTerm => {
    const { activateAdvancedSearch, preferences, savePreferences } = this.props;
    trackEvent('search', 'search', { dimension1: searchTerm });

    // Add to front of recent searches, de-duping and restricting to max.
    const whenCreated = new Date().toISOString();
    const newSearches = [
      { searchTerm, whenCreated },
      ...preferences.recentSearches.filter(search => search?.searchTerm !== searchTerm)
    ].splice(0, MAX_RECENT_SEARCHES);
    savePreferences({ ...preferences, recentSearches: newSearches }, preferences, true);

    activateAdvancedSearch(false);
    searchAndRemoveFromStorage('contactSearch', searchTerm.replace(/is%3A(?:%20)*/, ''));

    navigate(`/contacts?q=${searchTerm}`);
    // We blur focus on the search field so that the keyboard closes on mobile.
    document.getElementById('searchLookup-input').blur();
  };

  handleAdvancedSearch = () => this.props.showAdvancedSearch(!this.props.advancedSearch.isOpen);

  render() {
    const { isTabletPortraitAndUp } = this.props?.mediaQueryContext;
    return (
      <form action="#" id="contactSearchForm" ref={this.formRef} className={styles.form} onSubmit={this.handleSubmit}>
        <LookupSearch
          value={this.state.search}
          list={this.props.preferences.recentSearches}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        />
        <span className={styles.advancedSearch}>
          <Button
            {...{ ariaLabel: 'Advanced Search', icon: 'advancedSearch', tooltipPos: 'left' }}
            {...{ id: 'AdvancedSearchButton', ['data-cy']: 'advancedSearchButton', styleType: 'white' }}
            label={isTabletPortraitAndUp ? 'Advanced Search' : null}
            size={isTabletPortraitAndUp ? 'm' : 'l'}
            onClick={this.handleAdvancedSearch}
          />
        </span>
        <Button
          className={styles.button}
          data-cy="searchButton"
          type="submit"
          label="Search"
          ariaLabel="Search contacts."
          styleType="primary"
        />
        <Popover isOpen={this.props.advancedSearch.isOpen} ref={this.advancedSearchRef} position="adv">
          <AdvancedSearchForm />
        </Popover>
      </form>
    );
  }
}

Search.propTypes = { location: PropTypes.object.isRequired };

const mapStateToProps = state => ({
  preferences: state.preferences,
  advancedSearch: state.advancedSearch
});
const mapDispatchToProps = { savePreferences, showAdvancedSearch, activateAdvancedSearch };

export default withMediaQueryContextHOC(connect(mapStateToProps, mapDispatchToProps)(Search));
