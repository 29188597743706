/** @module */

export const SET_CALENDAR_VIEW = 'SET_CALENDAR_VIEW';
export const SET_CALENDAR_MODAL = 'SET_CALENDAR_MODAL';
export const SET_CALENDAR_MODAL_DATA = 'SET_CALENDAR_MODAL_DATA';
export const SET_HACK_DRILLDOWN_CLICKED = 'SET_HACK_DRILLDOWN_CLICKED';
export const SET_CALENDAR_ASSIGNED_TO_ID = 'SET_CALENDAR_ASSIGNED_TO_ID';

export const initialState = {
  currentView: null,
  date: new Date().toISOString(),
  modalData: {},
  modalContentType: null,
  showModal: false,
  showSettingsModal: false,
  hack_drillDownClicked: false,
  assignedToId: null
};

/**
 * The calendar redux reducer.
 * @param {Object} state - the current state of the alert store.
 * @param {Object} action - the action to take on the alert store
 * @param {String} [action.type=default] - the action to take.
 */
export const calendarReducer = (state = initialState, action = {}) => {
  const {
    type = 'default',
    hack_drillDownClicked,
    currentView,
    date,
    modalData,
    modalContentType,
    showModal,
    showSettingsModal,
    assignedToId
  } = action;

  switch (type) {
    case SET_CALENDAR_MODAL:
      return {
        ...state,
        modalContentType,
        showModal,
        showSettingsModal,
        modalData
      };
    case SET_CALENDAR_VIEW:
      return {
        ...state,
        currentView,
        date
      };
    case SET_HACK_DRILLDOWN_CLICKED:
      return {
        ...state,
        hack_drillDownClicked
      };
    case SET_CALENDAR_MODAL_DATA:
      return {
        ...state,
        modalData
      };
    case SET_CALENDAR_ASSIGNED_TO_ID:
      return {
        ...state,
        assignedToId
      };
    default:
      return state;
  }
};
