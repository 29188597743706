/** @module */

export const TASK_REMINDER_SAVE = 'TASK_REMINDER_SAVE';

export const initialState = {
  receiveTaskReminderEmail: false,
  recipientEmail: '',
  receiveTaskReminderText: false,
  recipientPhone: ''
};

/**
 * The task reminder preferences redux reducer.
 * @param {Object} state - the current state of the user store.
 * @param {Object} action - the action to take on the user store
 * @param {String} [action.type = default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const taskReminderReducer = (state = initialState, action = {}) => {
  const { data } = action;

  switch (action.type) {
    case TASK_REMINDER_SAVE:
      return {
        ...state,
        ...data
      };
    default:
      return state;
  }
};
