export const SUPPORT_LINKS = [
  {
    url: '/whats-new',
    label: "What's New",
    icon: 'announcement'
  },
  {
    url: '/feedback', // This route isn't active, but is present to give the user better feedback.
    label: 'Feedback',
    icon: 'feedback',
    openAs: 'pendoHack'
  },
  {
    url: '/settings',
    label: 'Settings',
    icon: 'settings',
    matchKey: 'startsWithUrl'
  }
];
