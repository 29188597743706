import { Component } from 'react';
import PropTypes from 'prop-types';

export class TrackPageViews extends Component {
  rewritePathname = (pathname, isDetailsView = false) => {
    if (!isDetailsView) {
      return pathname;
    }

    const pathArray = pathname.split('/');
    // we can be pretty explicit here since we know it isDetailsView
    pathArray[2] = 'details';

    return pathArray.join('/');
  };

  track = pathname => {
    if (!window.ga) {
      return;
    }

    const isDetailsView =
      pathname.startsWith('/contacts/') || pathname.startsWith('/tasks/') || pathname.startsWith('/notes/');
    const trackingPath = this.rewritePathname(pathname, isDetailsView);

    window.ga('send', 'pageview', trackingPath);
  };

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location !== location && location.pathname !== 'unsubscribe') {
      this.track(location.pathname);
    }
  }

  render() {
    return null;
  }
}

TrackPageViews.propTypes = {
  location: PropTypes.object.isRequired
};
