import React, { Fragment, useEffect } from 'react';
import { navigate } from '@reach/router';

const NotFound = () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return <Fragment />;
};

export default NotFound;
