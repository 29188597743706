import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormLabel, FormMessage, FormTextArea, FormTextInput } from '../';

import styles from './FormField.css';
import { FieldCounter } from '../FieldCounter/FieldCounter';

const FormElement = React.forwardRef((props, ref) => {
  const { inputType, type, value, ...attrs } = props;

  if (type === 'textarea') {
    return <FormTextArea ref={ref} value={value} {...attrs} />;
  }

  return <FormTextInput ref={ref} type={inputType} value={value} {...attrs} />;
});

export const FormField = React.forwardRef((props, ref) => {
  const {
    autocomplete,
    className,
    disabled,
    fieldIsValid,
    formIsValid,
    id,
    inputType = 'text',
    label,
    maxLength,
    onChange,
    placeholder,
    required,
    size,
    type = 'input',
    validationMessage = 'Field required',
    value,
    onValidate,
    pattern: propsPattern,
    ...attrs
  } = props;

  const showInvalid = formIsValid === false && fieldIsValid === false;
  const isFieldCounterVisible = type === 'textarea' && maxLength != null;
  const displayValidationMessage =
    isFieldCounterVisible && value?.length > parseInt(maxLength)
      ? 'Input exceeds the character limit.'
      : validationMessage;
  const fieldMessage = showInvalid ? displayValidationMessage : null;
  const pattern = propsPattern || (inputType === 'text' && required ? '.*\\S+.*' : null);

  const classes = classnames({
    [styles.field]: !className,
    [styles.fullWidth]: type === 'textarea' || size === 'xl',
    [className]: !!className
  });

  return (
    <div className={classes}>
      {(label || fieldMessage || isFieldCounterVisible) && (
        <div className={styles.labelWrap}>
          <FormLabel htmlFor={id} size={size} required={required}>
            {label}
          </FormLabel>
          <div className={styles.fieldState}>
            <FormMessage message={fieldMessage} />

            <FieldCounter
              maxLength={parseInt(maxLength)}
              currentLength={value?.length}
              onValidate={onValidate}
              fieldIsValid={fieldIsValid}
              isVisible={isFieldCounterVisible}
            />
          </div>
        </div>
      )}

      <FormElement
        type={type}
        autoComplete={autocomplete}
        disabled={disabled}
        id={id}
        name={id}
        inputType={inputType}
        showInvalid={showInvalid}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={isFieldCounterVisible ? null : maxLength}
        ref={ref}
        required={required}
        size={size}
        aria-required={required}
        aria-invalid={fieldIsValid === false}
        value={value}
        pattern={pattern}
        {...attrs}
      />
    </div>
  );
});

FormField.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['input', 'textarea'])
};
