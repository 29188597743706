export const MAX_RECENT_SEARCHES = 50;

export const MAX_RECENT_CONTACTS = 25;

export const MAX_ITEMS = 10;

export const SEARCH_ICONS = {
  recentSearch: 'recent',
  contactType: 'tag',
  letterSearch: 'alphabetize',
  searchSuggestion: 'search'
};
