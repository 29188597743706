/** @module */
import { getTime } from 'date-fns';
import { showMessage } from '../actions/message';
import { getNotes } from '../actions/notes';
import { DEFAULT_PAGING_SIZE, FOLLOWUP_PAGING_SIZE, LIST_QUERIES } from '../constants';
import {
  CONTACTS_ASSIGN_TYPES,
  CONTACTS_CURRENT_GROUP,
  CONTACTS_DELETE,
  CONTACTS_TOGGLE_DISPLAY_MASS_ASSIGN_TYPES_MODAL,
  CONTACTS_DETAILS,
  CONTACTS_DONE_PAGING,
  CONTACTS_LEAD_SOURCE_PICKLIST,
  CONTACTS_LOADING,
  CONTACTS_MOVE,
  CONTACTS_SET,
  CONTACTS_STATUS_COUNTS,
  CONTACTS_TYPE,
  CONTACTS_UPDATE_CONTACT,
  CONTACTS_UPDATE_SELECTED,
  CONTACTS_SHOW_EMAIL_MODAL,
  CONTACTS_SET_SELECT_ALL,
  CONTACTS_DELETE_SELECTED,
  CONTACTS_DELETE_FROM_GROUP,
  CONTACTS_SET_LAST_SEARCHED_DATE,
  CONTACTS_DELETE_FROM_ALL_GROUPS,
  CONTACTS_IGNORE_TEMPORARILY,
  CONTACTS_RESET_PAGING,
  CONTACTS_ADD_LEAD_SOURCE_PICKLIST,
  CONTACTS_SET_PAGING_SIZE
} from '../reducers/contacts';
import {
  calculateFollowUpCount,
  getContactStatus,
  parseParamsStr,
  request,
  requestError,
  searchAndRemoveFromStorage,
  sortArrayOfObjects
} from '../utils';
import {
  DEFAULT_CONTACTS_PARAMS,
  DEFAULT_CONTACT_LIST_COLUMNS,
  DEFAULT_QUERIES,
  getContactGroupFromParams,
  getContactListSortDirection,
  getContactListType,
  getDefaultAssignedToParam,
  getLastTouchFromAction
} from '../utils/contacts';
import { deepEscape, pluralize, isValidGuid } from '../utils/strings';
import { fetchProperties } from './properties';

export const isSupportedListQuery = q => {
  return LIST_QUERIES.includes(q) || q.length === 1;
};

const getContactSearchPath = q => {
  if (isSupportedListQuery(q)) {
    return 'list';
  }

  if (q.toLowerCase().startsWith('is:')) {
    return 'searchByContactTypes';
  }

  return 'search';
};

/**
 * Takes the request options and maps them to the proper format depending on the service needed.
 * @param {Object} options - options to fetch a list of contacts
 */

export const getContactRequestOptions = options => {
  const {
    assignedTo,
    cursor,
    dir,
    pageSize = DEFAULT_PAGING_SIZE,
    q,
    sortBy,
    status,
    contactStatus,
    type,
    forceRefresh = false,
    search,
    pageNumber,
    searchType
  } = options || {};

  const apiServiceType = q || searchType ? 'contact' : 'lead';
  const parsedContactStatus = contactStatus || status;

  if (apiServiceType === 'contact') {
    // we handle followup like a contact search, but with a slightly different API call
    if (q === 'followup') {
      return {
        apiServiceType: 'contact',
        path: 'basicContactInfoFollowUp',
        params: {
          pageSize: FOLLOWUP_PAGING_SIZE,
          type
        },
        shouldBeCached: false
      };
    }

    const path = searchType === 'advanced' ? 'searchByFields' : getContactSearchPath(q);
    const isSearchByFields = path === 'searchByFields';

    const listType = getContactListType(q, parsedContactStatus);
    // Each list type has a default column;
    const defaultColumn = DEFAULT_CONTACT_LIST_COLUMNS[listType];
    const reqSortBy = sortBy || defaultColumn;
    const reqDirection = getContactListSortDirection(dir, reqSortBy);
    const contactTypes = search?.contactTypes.length > 0 ? { logicalOperator: 1, values: search.contactTypes } : null;
    const searchByFields = { ...search, searchType, contactTypes };
    const requestBody = {
      dir: reqDirection,
      key: cursor,
      pageNumber,
      pageSize,
      sortBy: reqSortBy,
      // BE does not support all for assignedToId
      assignedToId: (q === 'social connect' || q === 'social connect 2') && assignedTo !== 'all' ? assignedTo : null,
      ...(isSearchByFields ? searchByFields : {})
    };
    const isPostRequest = path === 'searchByContactTypes' || isSearchByFields;

    if (path === 'searchByContactTypes') {
      requestBody.contactTypes = q
        .toLowerCase()
        .replace(/is:/, '')
        .split(',')
        .map(type => {
          return type.trim();
        });
    } else {
      requestBody.q = q;
    }

    // Maps existing sortBy fields to new searchByFields sortBy fields.
    if (isSearchByFields) {
      const { sortBy } = requestBody;
      requestBody.sortBy =
        sortBy === 'consumer_touch' ? 'consumer_last_touch' : sortBy === 'touch' ? 'agent_last_touch' : sortBy;
    }

    return {
      apiServiceType: 'contactSearch',
      method: isPostRequest ? 'POST' : 'GET',
      params: isPostRequest ? null : requestBody,
      path,
      payload: isPostRequest ? (isSearchByFields ? deepEscape(requestBody) : requestBody) : null,
      forceRefresh,
      ...(isSearchByFields ? { timeout: 60000 } : {})
    };
  }

  const reqSortBy = sortBy || DEFAULT_CONTACT_LIST_COLUMNS.leads;
  const reqDirection = getContactListSortDirection(dir, reqSortBy);
  const reqStatus = parsedContactStatus || 10;

  // else use lead service

  let assignedToProps;

  if (assignedTo) {
    if (assignedTo === 'all') {
      assignedToProps = {
        filterType: 0
      };
    } else if (assignedTo === 'unassigned') {
      assignedToProps = {
        filterType: 2
      };
    } else {
      assignedToProps = {
        filterType: 1,
        assignedToUserId: assignedTo
      };
    }
  } else {
    assignedToProps = {
      filterType: 0
    };
  }

  return {
    apiServiceType,
    method: 'POST',
    path: 'leadlist',
    forceRefresh,
    payload: {
      pageSize: pageSize.toString(),
      direction: reqDirection,
      sortBy: reqSortBy,
      key: cursor,
      dateFrom: '',
      dateTo: '',
      assignedTo: assignedToProps,
      queryType: { all: 0, status: [reqStatus.toString()] } // ToDo:  We will need to treat status different if we want to support multiple statuses
    }
  };
};

/**
 * Fetches a list of contacts.
 * If success, dispatches the setting of the contacts in the store, and optionally whether the list has reached the last page.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to fetch a list of contacts
 * @param {Number} [options.dir=0] - the sort direction of the list of results 0 is forward, 1 is reverse
 * @param {String} [options.cursor] - used to fetch the next contacts while paging.
 * @param {Number} [options.pageSize=25] - the number of contacts to fetch with the request.
 * @param {String} [options.q=all] - the query string to use when fetching contacts. all is used for all contacts. fav for favorites.
 * @param {Boolean} [options.ignorePreexisting] - allows us to not merge subsequent searches overtop of detail view info during CONTACTS_SET.
 * isLoading - Flag to show data request is in progress/completed.
 */
export const getContacts = options => {
  const {
    cursor,
    pageSize = DEFAULT_PAGING_SIZE,
    q,
    setCurrentGroup = true,
    replaceGroup,
    ignorePreexisting,
    currentGroup,
    advancedSearch,
    searchType
  } = options || {};
  return async dispatch => {
    // the various API services have different keys for the same thing, so we need to map them for each service
    const requestOptions = getContactRequestOptions({ ...options, search: advancedSearch?.search });
    const group = currentGroup || getContactGroupFromParams({ ...requestOptions });

    if (
      requestOptions.path === 'searchByFields' &&
      (!requestOptions.payload.address || (!!requestOptions.payload.key && !isValidGuid(requestOptions.payload.key)))
    ) {
      // Little safety catch for occasional errors caused by rapid tab switching.
      return;
    }

    try {
      if (setCurrentGroup) {
        dispatch({
          type: CONTACTS_LOADING,
          isLoading: true
        });
      }

      const contactsRequest = await request(requestOptions).catch(() => {
        // catch when we abort the request
        if (setCurrentGroup) {
          dispatch({
            type: CONTACTS_CURRENT_GROUP,
            group,
            isLoading: false
          });
        }
      });

      if (!contactsRequest) {
        return;
      }

      const { data } = contactsRequest;

      const { exception, cursor: newCursor, records, followUpContactInfo, totalCount } = data;

      if (exception) {
        throw exception;
      }

      const contactRecords = records ? records : followUpContactInfo; // Unfortunately, the follow-up API does not return a recrods array
      if (contactRecords.length < pageSize) {
        // if the results set returned has less than the page size, we have reached the end of the list
        dispatch({
          type: CONTACTS_DONE_PAGING,
          group
        });
      } else {
        dispatch({ type: CONTACTS_RESET_PAGING });
      }

      if (q === 'followup') {
        const followUpCount = calculateFollowUpCount(records);

        dispatch({
          type: CONTACTS_STATUS_COUNTS,
          statusCounts: { followup: followUpCount }
        });
      }

      if ((q && !DEFAULT_QUERIES.includes(q)) || searchType) {
        dispatch({
          type: CONTACTS_STATUS_COUNTS,
          statusCounts: { [searchType || q.toLowerCase().replace(/is:(\s+)/, 'is:')]: totalCount }
        });
      }

      dispatch({
        type: CONTACTS_SET,
        contactRecords,
        group,
        cursor: newCursor,
        isPaging: !!cursor, // we set isPaging to true if there is an id set in options
        isLoading: false,
        setCurrentGroup,
        replaceGroup, // instead of merging the set of IDs in a group, the group gets completely replaced
        ignorePreexisting // allows us to not merge subsequent searches overtop of detail view info during CONTACTS_SET
      });
    } catch (error) {
      dispatch({
        type: CONTACTS_LOADING,
        isLoading: false
      });

      requestError(error, dispatch);
    }
  };
};

/**
 * Gets the status counts for leads/contacts (New, Engage, etc).
 */
export const getStatusCounts = assignedTo => {
  return async dispatch => {
    let assignedToPath;

    if (!assignedTo) {
      assignedToPath = '';
    } else if (assignedTo === 'all') {
      assignedToPath = '?filterType=0';
    } else if (assignedTo === 'unassigned') {
      assignedToPath = '?filterType=2';
    } else {
      assignedToPath = `?filterType=1&assignedToUserId=${assignedTo}`;
    }

    const requestOptions = {
      apiServiceType: 'contactOld',
      path: `salesPipelineCount${assignedToPath}`,
      shouldBeCached: false
      // New service doesn't return a breakdown
      // It looks like this and support a type param if we ever need to use it
      // apiServiceType: 'lead',
      // path: 'LeadCount',
      // params: { type: '10' }
    };

    try {
      const contactTypeRequest = await request(requestOptions);

      if (!contactTypeRequest) {
        return;
      }

      const { data } = contactTypeRequest;

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: CONTACTS_STATUS_COUNTS,
        statusCounts: data
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Get one row of All to get totalCount and update all count in statusCounts.
 */
export const getAllCount = () => {
  const options = { q: 'all', forceRefresh: true, pageSize: 1 };

  return async dispatch => {
    const requestOptions = getContactRequestOptions(options);

    try {
      const contactsRequest = await request(requestOptions);

      if (!contactsRequest) {
        return;
      }

      const { data } = contactsRequest;
      const { exception, totalCount } = data;

      if (exception) {
        throw exception;
      }

      dispatch({ type: CONTACTS_STATUS_COUNTS, statusCounts: { all: totalCount } });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Wrapper function to getStatusCounts and getAllCount at same time.
 * @param {String} assignedTo - the assigned to ID to pass to getStatusCounts.
 */
export const getCounts = assignedTo => {
  return async dispatch => {
    dispatch(getStatusCounts(assignedTo));
    dispatch(getAllCount());
  };
};

/**
 * Gets the status counts for follow up contacts.
 */
export const getFollowUpCount = () => {
  return async dispatch => {
    const options = {
      apiServiceType: 'contact',
      path: 'basicContactInfoFollowUp',
      params: {
        pageSize: FOLLOWUP_PAGING_SIZE
      },
      shouldBeCached: false
    };

    try {
      const followUpRequest = await request(options);

      if (!followUpRequest) {
        return;
      }

      const { data } = followUpRequest;

      const { exception, records } = data;

      if (exception) {
        throw exception;
      }

      const followUpCount = calculateFollowUpCount(records);

      dispatch({
        type: CONTACTS_STATUS_COUNTS,
        statusCounts: { followup: followUpCount }
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Move contact action.
 * @param {String} id - the ID of a contact to delete.
 * @param {String} newGroup - the name of the group that the contact is moving to.
 */
export const moveContact = (id, newGroup) => {
  return dispatch => {
    dispatch({
      type: CONTACTS_MOVE,
      contactId: id,
      newGroup
    });
    dispatch(getCounts());
    dispatch(getFollowUpCount());
  };
};

export const addContactType = contactType => {
  return dispatch => {
    dispatch({
      type: CONTACTS_TYPE,
      contactTypes: [contactType],
      group: 'contactType::all'
    });
  };
};

const clearContactTypeSearchesFromCache = () => {
  // Remove all cached requests for contact type searches when doing a mass add contact type.
  searchAndRemoveFromStorage('contactSearch', 'searchByContactTypes');
};

export const addContact = (options, dispatchers) => {
  const { contactCurrentGroup, shouldUpdateNotes, statusGroup } = dispatchers || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contactOld',
      method: 'POST',
      path: 'add',
      payload: options,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception, contactStatus } = data;

      if (exception) {
        throw exception;
      }

      if (shouldUpdateNotes) {
        // get my notes
        dispatch(getNotes({ currentGroup: 'myNotes', setCurrentGroup: false, forceRefresh: true }));
      }

      const statusFromcurrentGroup = contactCurrentGroup.split('::')[0]?.toLowerCase()?.replace(' ', '');
      const statusFromContactData = getContactStatus(contactStatus)?.toLowerCase()?.replace(' ', '');

      // If the status is matching current view
      // we add contact directly to current group and refresh current group
      if (statusFromcurrentGroup === statusFromContactData) {
        dispatch({
          type: CONTACTS_SET,
          contactRecords: [data],
          group: contactCurrentGroup,
          setCurrentGroup: false
        });
      } // If not matching, we add to the status group. The list will get refreshed when needed
      else {
        dispatch({
          type: CONTACTS_SET,
          contactRecords: [data],
          group: statusGroup,
          setCurrentGroup: false
        });

        dispatch(getContacts({ q: statusGroup, setCurrentGroup: false, forceRefresh: true }));
      }

      dispatch(getContacts({ q: contactCurrentGroup, setCurrentGroup: false, forceRefresh: true }));

      dispatch(getCounts());

      clearContactTypeSearchesFromCache();

      searchAndRemoveFromStorage('contactSearch', 'qall'); // Fixes new contacts stickied to top of All name ascending.

      dispatch(showMessage({ message: 'Contact saved successfully.', type: 'success' }, true)); // useTimer set to true

      return data;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/* cleanContact contact action.
 * @param {String} id - the Id of a contact to clean.
 * @param {String} group - the name of the group of entities
 */
export const cleanContact = (id, group) => {
  return dispatch => {
    dispatch({
      type: CONTACTS_UPDATE_CONTACT,
      contactId: id,
      updatedKeys: {
        is_removed: true
      }
    });
    dispatch({
      type: CONTACTS_DELETE,
      contactId: id,
      group: group
    });
    dispatch(showMessage({ message: 'Contact has been deleted.', type: 'error' }, true)); // useTimer set to true
  };
};

/**
 * Saves contact data when updating a contact record.
 * @param {Object} options - the contact data.
 * @param {Boolean} reassign - whether to reassign incomplete tasks to new agent.
 * @returns {Object} - the updated contact data.
 */
export const saveContact = (options, reassign) => {
  const params = reassign ? { reassign } : {};
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contactOld',
      method: 'POST',
      params,
      path: 'update',
      payload: options,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception, contactStatus } = data;

      if (exception) {
        throw exception;
      }

      const group = getContactStatus(contactStatus).toLowerCase().replace(' ', '');

      dispatch({
        type: CONTACTS_SET,
        contactRecords: [data],
        setCurrentGroup: false
      });

      dispatch(moveContact(data.id, group));

      // We purge any cached queries with the status.
      if (contactStatus) {
        searchAndRemoveFromStorage(`status${contactStatus}`, 'leadlist');
      }

      clearContactTypeSearchesFromCache();

      dispatch(showMessage({ message: 'Contact saved successfully.', type: 'success' }, true)); // useTimer set to true

      dispatch(fetchProperties(data.id));

      return data;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * Get the details of a contact.
 * @param {Object} options - options to request the contact details
 * @param {String} options.id - the id of the contact to fetch.
 * @param {string} options.group - current group of contacts displayed.
 * @param {Boolean} options.is_lead=false - whether the contact should be fetched as lead
 */
export const getContactDetail = options => {
  const {
    id,
    group,
    is_lead = false,
    suppressWarning = false,
    shouldBeCached = false,
    forceRefresh = false,
    timeout
  } = options || {};

  return async dispatch => {
    const options = {
      apiServiceType: 'contact',
      params: {
        is_lead
      },
      path: `details/${id}`,
      shouldBeCached,
      forceRefresh,
      timeout
    };

    dispatch({
      type: CONTACTS_LOADING,
      isLoading: true
    });

    return new Promise(async (resolve, reject) => {
      try {
        const contactDetails = await request(options);

        if (!contactDetails) {
          return;
        }

        const { data } = contactDetails;
        const { exception } = data;

        if (exception) {
          if (
            exception.exceptionType === 'RecordNotFoundException' ||
            exception.exceptionType === 'NoPermissionException'
          ) {
            if (!suppressWarning) {
              dispatch(cleanContact(id, group));
            }
            return;
          } else {
            throw exception;
          }
        }

        dispatch({
          type: CONTACTS_DETAILS,
          contactId: id,
          contactDetails: data
        });

        dispatch({
          type: CONTACTS_LOADING,
          isLoading: false
        });

        resolve(data);

        return data;
      } catch (error) {
        requestError(error, dispatch);
        reject(error);
      }
    });
  };
};

/**
 * Get the basic contact info - mainly isUSAgent.
 * @param {Object} options - options to request the contact details
 * @param {String} options.contactId - the id of the contact to fetch.
 * @param {string} options.forceRefresh=false - whether to force the request through regardless of cached.
 * @param {Boolean} options.shouldBeCached=true - whether the requrst should be cached.
 * @param {Boolean} options.userId - the current user's ID.
 */
export const getContactBasic = options => {
  //fetch doesn't allow to set shouldBeCached to true, hence no forceRefresh required.
  const { contactId, forceRefresh = false, shouldBeCached = false, userId } = options || {};

  if (!contactId || !userId) {
    return;
  }

  return async dispatch => {
    const options = {
      apiServiceType: 'users',
      baseUrlKey: 'api',
      method: 'GET',
      forceRefresh,
      path: `${userId}/contacts/${contactId}/basic`,
      shouldBeCached
    };

    try {
      const contactBasic = await request(options);

      if (!contactBasic) {
        return;
      }

      const { data } = contactBasic;
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: CONTACTS_DETAILS,
        contactId,
        contactDetails: data
      });

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Fetches the master list of contact types, and the list of linked contact types.
 * If success, dispatches the contact types in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options for requesting the contact types.
 * @returns {Object} - the list of contact types.
 */
export const getContactTypes = options => {
  const { forceRefresh = false } = options || {};
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contact',
      path: 'contactTypes',
      forceRefresh
    };

    const linkedTypesRequestOptions = {
      apiServiceType: 'contact',
      params: { q: '' },
      path: 'contactTypes/search'
    };

    try {
      const contactTypesRequest = request(requestOptions);
      const linkedContactTypesRequest = request(linkedTypesRequestOptions);

      const [contactTypes, linkedContactTypes] = await Promise.all([contactTypesRequest, linkedContactTypesRequest]);

      if (!contactTypes) {
        return;
      }

      const { data } = contactTypes;
      const { data: linkedData } = linkedContactTypes;

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      const allContactTypes = [...data, ...linkedData];
      const dedupedContactTypes = allContactTypes.reduce((acc, item) => {
        const { contactType } = item;

        if (acc[contactType]) {
          acc[contactType] = {
            ...acc[contactType],
            ...item
          };
        } else {
          acc[contactType] = item;
        }

        return acc;
      }, {});
      const arrayOfDedupedContactTypes = Object.keys(dedupedContactTypes).reduce((acc, key) => {
        acc.push(dedupedContactTypes[key]);

        return acc;
      }, []);

      const sortedContactTypes = sortArrayOfObjects(arrayOfDedupedContactTypes, {
        sortKey: 'contactType',
        sortType: 'string'
      });

      dispatch({
        type: CONTACTS_TYPE,
        contactTypes: sortedContactTypes,
        group: 'contactType::all'
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const changeContactStatus = (contactId, status, externalStatus) => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contactOld',
      method: 'POST',
      params: externalStatus ? { id: contactId, status, externalStatus, externalSource: 1 } : { id: contactId, status },
      path: 'changeConactStatus',
      shouldBeCached: false
    };
    try {
      const changeStatusRequest = await request(requestOptions);
      if (!changeStatusRequest) {
        return;
      }
      const { data } = changeStatusRequest;
      const { exception } = data;
      if (exception) {
        throw exception;
      }
      if (data === true) {
        dispatch({
          type: CONTACTS_UPDATE_CONTACT,
          contactId: contactId,
          updatedKeys: externalStatus
            ? {
                contactStatus: status,
                lastTouchPoint: getLastTouchFromAction('status', `Status changed to ${getContactStatus(status)}`),
                externalContactStatus: externalStatus
              }
            : {
                contactStatus: status,
                lastTouchPoint: getLastTouchFromAction('status', `Status changed to ${getContactStatus(status)}`)
              }
        });
        // We ensure that the next time we navigate to the new status, we get the latest (so we remove the requestId)
        searchAndRemoveFromStorage(`status${status}`, 'leadlist');
        dispatch(moveContact(contactId, getContactStatus(status).toLowerCase().replace(' ', '')));
        dispatch(
          showMessage({ message: `Contact status switched to ${getContactStatus(status)}.`, type: 'success' }, true)
        ); // useTimer set to true
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const toggleContactFavorite = (contactId, method) => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contactOld',
      method: 'POST',
      params: { method },
      path: `fav/${contactId}`,
      shouldBeCached: false
    };

    try {
      const toggleFavoriteRequest = await request(requestOptions);

      if (!toggleFavoriteRequest) {
        return;
      }

      const { data } = toggleFavoriteRequest;

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (data === '1') {
        const isFavorite = method === 'add' ? true : false;
        dispatch({
          type: CONTACTS_UPDATE_CONTACT,
          contactId: contactId,
          updatedKeys: {
            is_favorite: isFavorite
          }
        });
        dispatch(moveContact(contactId, isFavorite ? 'fav' : 'unfav'));
        // We ensure that the next time we navigate to favs, we get the latest (so we remove the requestId)
        searchAndRemoveFromStorage(`qfav`, 'contactSearch');
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const skipFollowup = contactId => {
  const description = 'Follow-up skipped';

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contactOld',
      method: 'POST',
      path: 'dismissSystemFollowUp',
      payload: { id: contactId, description },
      shouldBeCached: false
    };

    try {
      const skipFollowupRequest = await request(requestOptions);

      if (!skipFollowupRequest) {
        return;
      }

      const { data } = skipFollowupRequest;

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (data) {
        dispatch({
          type: CONTACTS_UPDATE_CONTACT,
          contactId: contactId,
          updatedKeys: {
            is_valid: false
          }
        });

        dispatch(showMessage({ message: `Follow-up skipped.`, type: 'success' }, true)); // useTimer set to true

        dispatch(getFollowUpCount());
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const refreshFollowup = () => {
  return dispatch => {
    dispatch(getContacts({ q: 'followup', type: 'next', replaceGroup: true }));
  };
};

/**
 * Delete contact action.
 * @param {String} id - the ID of a contact to delete.
 * @param {String} group - the name of a group of entities
 */
export const deleteContact = (id, group) => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contactOld',
      method: 'POST',
      path: `delete/${id}`,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: CONTACTS_DELETE,
        contactId: id,
        group: group
      });
      dispatch(getCounts());
      dispatch(getFollowUpCount());
      dispatch(showMessage({ message: 'Contact deleted successfully.', type: 'success' }, true)); // useTimer set to true

      return true;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * Delete contact action.
 * @param {String} id - the ID of a contact to delete.
 * @param {String} group - the name of a group of entities
 */
export const massDeleteContacts = (ids, group) => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contacts',
      baseUrlKey: 'api',
      method: 'DELETE',
      path: ` `,
      payload: ids,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      ids.map(id => {
        dispatch({
          type: CONTACTS_DELETE,
          contactId: id,
          group: group
        });

        dispatch({
          type: CONTACTS_DELETE_FROM_ALL_GROUPS,
          contactId: id
        });
      });

      dispatch({
        type: CONTACTS_IGNORE_TEMPORARILY,
        ignoreData: {
          ignoredContactsArray: ids,
          deleteTimestamp: getTime(new Date())
        }
      });
      dispatch({
        type: CONTACTS_RESET_PAGING
      });
      dispatch(getCounts());
      dispatch(getFollowUpCount());

      dispatch(
        showMessage(
          {
            message: `${ids.length} ${pluralize(
              ids.length,
              'contact',
              's are'
            )} being deleted. This process might take a some time…`,
            type: 'success'
          },
          true
        )
      );
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * Resets deletion cache
 */
export const resetIgnoreData = () => {
  return dispatch => {
    dispatch({
      type: CONTACTS_IGNORE_TEMPORARILY,
      ignoreData: {
        ignoredContactsArray: [],
        deleteTimestamp: null
      }
    });
  };
};

/**
 * Updates buyer preference of contact from Market snapshot data.
 * @param {Object} options - buyer preference data
 */
export const updateContactBuyerPreference = options => {
  const { contact_id } = options;
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contact',
      method: 'POST',
      path: `update/mscriteria`,
      payload: options,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch(getContactDetail({ id: contact_id }));

      return true;
    } catch (error) {
      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Fetches Contact Details from FullContact.io
 * @param {Object} options - contactId, profile, imageURL
 */
export const updateFullContactProfile = options => {
  const { contactId, profile, imageURL } = options;
  return dispatch => {
    const param = profile
      ? {
          fullContactProfile: profile
        }
      : {
          fullContactImage: imageURL
        };

    dispatch({
      type: CONTACTS_UPDATE_CONTACT,
      contactId: contactId,
      updatedKeys: param
    });
  };
};

/**
 * Get list of contact lead source picklists
 */
export const getContactLeadSourcePicklist = options => {
  const { shouldBeCached = true, forceRefresh = false } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contact',
      forceRefresh,
      method: 'GET',
      path: `picklist/source`,
      shouldBeCached
    };

    try {
      const { data } = await request(requestOptions);

      if (data) {
        const leadSources = data.source.map(item => ({ id: item, value: item }));

        leadSources.push({ id: 'Other', value: 'Other' });

        dispatch({
          type: CONTACTS_LEAD_SOURCE_PICKLIST,
          leadSourcePicklist: leadSources
        });
      }

      return true;
    } catch (error) {
      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Get list of contact lead source picklists
 */
export const saveContactLeadSource = options => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'contact',
      method: 'POST',
      path: `picklist/source`,
      payload: options,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      if (data) {
        // when saving a new source, we refetch.
        dispatch(getContactLeadSourcePicklist({ forceRefresh: true }));
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const setDisplayContactTypeModal = shouldShow => {
  return dispatch => {
    dispatch({
      type: CONTACTS_TOGGLE_DISPLAY_MASS_ASSIGN_TYPES_MODAL,
      displayContactTypeModal: shouldShow
    });
  };
};

/**
 * Get list of contact lead source picklists
 * @param {Array} contactIds - the ID of a contacts to update.
 * @param {Array} contactTypes - the string value of contact types to add.
 */
export const assignContactTypes = options => {
  const { contactIds, contactTypes } = options;

  return async (dispatch, getState) => {
    const requestOptions = {
      apiServiceType: 'contacts',
      baseUrlKey: 'api',
      method: 'POST',
      path: `assignContactTypes`,
      payload: { contactIds, contactTypes },
      shouldBeCached: false
    };

    try {
      dispatch({
        type: CONTACTS_LOADING,
        isLoading: true
      });

      await request(requestOptions);
      const { entities } = getState().contacts;
      let updatedEntities = Object.assign({}, entities);

      contactIds.forEach(idToUpdate => {
        updatedEntities[idToUpdate].contactTypes = Array.from(
          new Set([...updatedEntities[idToUpdate].contactTypes, ...contactTypes])
        );
      });

      dispatch({
        type: CONTACTS_ASSIGN_TYPES,
        updatedEntities
      });

      dispatch({
        type: CONTACTS_LOADING,
        isLoading: false
      });

      dispatch(setDisplayContactTypeModal(false));

      clearContactTypeSearchesFromCache();
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Filter out contact ids without primary email
 * @param {String[]} group list of ids from redux
 * @param {Object[]} contacts entities directly from redux
 * @returns a list of contact id with primary email
 */
export const filterContactGroupByEmail = (group, contacts) => {
  return (
    group?.filter(item => {
      if (contacts[item].emails.length > 0) {
        const noEmail = contacts[item].emails.length === 0;
        if (!noEmail) {
          return true;
        }
      }
      return false;
    }) || []
  );
};

/**
 * Filter out contact ids without primary phone
 * @param {String[]} group list of ids from redux
 * @param {Object[]} contacts entities directly from redux
 * @returns a list of contact id with primary phone
 */
export const filterContactGroupByPhone = (group, contacts) => {
  return group?.filter(item => contacts[item].phones.length > 0) || [];
};

export const setCurrentlySelectedContacts = (currentlySelected, shouldReplace = false) => {
  return dispatch => {
    dispatch({
      type: CONTACTS_UPDATE_SELECTED,
      currentlySelected,
      shouldReplace
    });
  };
};

export const deleteSelectedContacts = currentlySelected => {
  return dispatch => {
    dispatch({
      type: CONTACTS_DELETE_SELECTED,
      currentlySelected
    });
  };
};

export const setDisplayMassEmailModal = displayEmailModal => {
  return dispatch => {
    dispatch({
      type: CONTACTS_SHOW_EMAIL_MODAL,
      displayEmailModal
    });
  };
};

export const handlePaging = props => {
  const {
    advancedSearch: search,
    currentCursor,
    currentGroup,
    groups,
    user,
    userInfo,
    statusCounts,
    pagingSize
  } = props;
  const contactList = groups[currentGroup];

  const getPageNumber = () => {
    const totalPages = Math.ceil(statusCounts[currentGroup.split('::')[0]] / DEFAULT_PAGING_SIZE);
    const currentPage = Math.ceil(contactList.length / DEFAULT_PAGING_SIZE);
    return currentPage < totalPages ? currentPage + 1 : 1;
  };
  const pageNumber = currentGroup.split('::')[0] === 'advanced' ? getPageNumber() : 1;

  return async dispatch => {
    if (contactList.length === 0) {
      const params = { ...(parseParamsStr(location.search) || DEFAULT_CONTACTS_PARAMS), pageNumber };
      return dispatch(
        getContacts({
          ...getDefaultAssignedToParam(user, userInfo),
          ...params,
          pageSize: parseInt(pagingSize),
          forceRefresh: true
        })
      );
    }

    const advancedSearch = { ...search, assignedTo: user.isRestrictedAgent ? user.userId : search.assignedTo };
    const params = { ...(parseParamsStr(location.search) || DEFAULT_CONTACTS_PARAMS), pageNumber, advancedSearch };

    return dispatch(
      getContacts({
        ...getDefaultAssignedToParam(user, userInfo),
        ...params,
        cursor: currentCursor,
        pageSize: parseInt(pagingSize),
        forceRefresh: true
      })
    );
  };
};

export const setSelectAll = value => {
  return dispatch => {
    dispatch({
      type: CONTACTS_SET_SELECT_ALL,
      selectAll: value
    });
  };
};

export const deleteContactFromGroup = (id, group) => {
  return dispatch => {
    dispatch({
      type: CONTACTS_DELETE_FROM_GROUP,
      contactId: id,
      group: group
    });
  };
};

export const setContactLastSearchedDate = date => {
  return dispatch => {
    dispatch({
      type: CONTACTS_SET_LAST_SEARCHED_DATE,
      date
    });
  };
};

export const addLeadSourcePickList = item => {
  return dispatch => {
    dispatch({
      type: CONTACTS_ADD_LEAD_SOURCE_PICKLIST,
      leadSource: { ...item, value: item?.id }
    });
  };
};

export const resetPaging = () => dispatch => dispatch({ type: CONTACTS_RESET_PAGING });

export const setPageSize = pageSize => dispatch => dispatch({ type: CONTACTS_SET_PAGING_SIZE, pageSize: pageSize });
