import {
  CIRRUS_PROPERTIES_GET_START,
  CIRRUS_PROPERTIES_GET_SUCCCESS,
  CIRRUS_PROPERTIES_GET_ERROR
} from '../reducers/cirrusProperties';
import { getGroupNameFromInput, requestError } from '../utils';
import { request } from '../utils/request';

/**
 * Get Cirrus Addresses Suggest
 */
export const getMlsAddressSuggest = ({ input, source, limit }) => {
  return async dispatch => {
    if (!source) {
      source = '';
    }
    const addressSuggestPath = `mls/address_suggest?input=${input}&source=${source}&limit=${limit}`;

    const requestOptions = {
      baseUrlKey: 'api',
      path: addressSuggestPath,
      shouldBeCached: false
    };

    if (!input) {
      return;
    }
    const groupName = getGroupNameFromInput(input);

    // first we select the group to show, according to the input
    dispatch({
      type: CIRRUS_PROPERTIES_GET_START,
      payload: { groupName }
    });

    try {
      const response = await request(requestOptions);

      const { data, status } = response;

      if (status === 200) {
        dispatch({
          type: CIRRUS_PROPERTIES_GET_SUCCCESS,
          payload: { data, groupName }
        });

        return data;
      }
    } catch (error) {
      dispatch({ type: CIRRUS_PROPERTIES_GET_ERROR });
      requestError(error, dispatch);
    }
  };
};
