import React from 'react';
import { arrayOf, bool, node, oneOf, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import { Icon } from '../Icon';

import styles from './MessageBanner.css';

export const MessageBanner = props => {
  const { children, className, dataCy, icon, iconSize = 's', message, isVisible = true, variant = 'default' } = props;

  if (!message || !isVisible) {
    return null;
  }

  const classes = classnames({
    [styles.banner]: !className,
    [styles[`${variant}Variant`]]: true,
    [className]: !!className
  });

  return (
    <div className={classes} data-cy={dataCy}>
      {icon && <Icon name={icon} size={iconSize} isColored={true} />}
      {message}
      {children}
    </div>
  );
};

MessageBanner.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  className: string,
  icon: string,
  isVisible: bool,
  message: string.isRequired,
  variant: oneOf(['default', 'error', 'success'])
};
