import React from 'react';
import { object, oneOf, string } from 'prop-types';
import classnames from 'classnames';

import { buildTestId } from '../../utils';

import { TemplateCard } from '../Card/TemplateCard';
import { TemplateList } from '../List/TemplateList';

import styles from './TemplateCategory.css';

export const TemplateCategory = props => {
  const { category, selectedTemplate, variant = 'default' } = props;
  const { label, templates } = category || {};

  if (!category || templates.length === 0) {
    return null;
  }

  const classes = classnames({
    [styles[`category--${variant}`]]: true
  });

  return (
    <div className={classes}>
      <div className={styles.categoryHeader}>
        <h2 className={styles.h2}>{label}</h2>
      </div>
      <TemplateList variant={variant}>
        {templates
          ?.filter(entity => entity?.isDeleted !== 1)
          ?.map(template => {
            const { fileId } = template || {};
            const isSelected = fileId === selectedTemplate;

            return (
              <li
                key={fileId}
                data-testid={buildTestId(`TemplateList-${variant}`, `template-${fileId}`)}
                className={styles.listItem}
              >
                <TemplateCard data={template} isSelected={isSelected} variant={variant} />
              </li>
            );
          })}
      </TemplateList>
    </div>
  );
};

TemplateCategory.propTypes = {
  category: object,
  selectedTemplate: string,
  variant: oneOf(['default', 'simple'])
};
