import classnames from 'classnames';
import { bool, object } from 'prop-types';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert, showAlert } from '../../actions';
import { deleteTransaction, openEditForm } from '../../actions/transactions';
import { DATE_FORMATS } from '../../constants';
import { TRANSACTIONS_ADD_PARTY_FORM_TOGGLE } from '../../reducers/transactions';
import { createEntityTagUrl } from '../../utils/contacts';
import { getCityStateStr } from '../../utils/data';
import { parseISODate } from '../../utils/dates';
import { getDisplayImage } from '../../utils/image';
import { formatLongDate, formatPriceWithCommas, parseParamsStr } from '../../utils/strings';
import {
  checkIsSold,
  getPropertyStatuses,
  getRepresentationType,
  getSortedTransactionParties,
  getTransactionPrice,
  isTransactionEditable,
  NON_ACTIVE_FLAG,
  TRANSACTION_STATUSES
} from '../../utils/transactions';
import { Card } from './Card';
import { PropertyCardImage } from './PropertyCardImage';
import { Button, ButtonGroup } from '../Button';
import { Tag, TagGroup } from '../Tag';
const TRANSACTION_URL = '/transactions';

import insightCardStyles from './PropertyInsightCard.css';
import tagStyles from '../Tag/Tag.css';
import { navigate } from '@reach/router';
import { isAfter } from 'date-fns';
import { checkIsFromDashboard } from '../../utils/urls';

const TransactionCard = props => {
  const dispatch = useDispatch();

  const userInfo = useSelector(store => store.userProfile.userInfo);
  // isNested = true when used in contacts, default is set in TransactionsList
  const { data, location, isNested } = props;
  const { pathname, search } = location;

  const { status: mountedStatus } = parseParamsStr(search) || {};

  const isOnClosingsTab = mountedStatus === NON_ACTIVE_FLAG.toString();

  const {
    closingDate,
    contractAgreementDate,
    expirationDate,
    lastModified,
    listingDate,
    listPrice,
    listing,
    mlsNumber,
    representationTypeId,
    salePrice,
    statusId,
    transactionId,
    transactionParties,
    address: transactionAddress
  } = data || {};
  const {
    days_on_market,
    href,
    last_sold_price,
    location: propertyLocation,
    mls_status,
    primary_photo,
    source
  } = listing || {};
  const { address } = propertyLocation || {};
  const addressObj = transactionAddress || address;

  const { contract_date } = source || {};

  const { line, city, state_code: stateCode, state, postal_code: postalCode, zip } = addressObj || {};
  const { href: primaryPhotoHref } = primary_photo || {};

  const cityStateStr = getCityStateStr({ city, state: stateCode || state, zip: postalCode || zip });

  const displayAddress = `${line == null ? '' : `${line}, `} ${cityStateStr}`.trim();

  const status = TRANSACTION_STATUSES.find(item => item.id === statusId);
  const tpxStatus = statusId ? status.value : null;
  const isSoldStatus = checkIsSold(mls_status);
  const representation = getRepresentationType(representationTypeId)?.label || null;
  const displayExpirationDate = expirationDate
    ? formatLongDate(parseISODate(expirationDate), DATE_FORMATS.SHORT_DATE_WITH_YEAR)
    : '';
  const displayListingDate = listingDate
    ? formatLongDate(parseISODate(contract_date || listingDate), DATE_FORMATS.SHORT_DATE_WITH_YEAR)
    : '';
  const displayClosingDate = closingDate
    ? formatLongDate(parseISODate(closingDate), DATE_FORMATS.SHORT_DATE_WITH_YEAR)
    : '';
  const displayContractAgreementDate = contractAgreementDate
    ? formatLongDate(contractAgreementDate, DATE_FORMATS.SHORT_DATE_WITH_YEAR)
    : '';

  const isClosingInFuture = isAfter(new Date(closingDate), new Date());

  const displayDOM = days_on_market ? `(${days_on_market} DOM)` : '';
  const displayUrl = href ? `${href}?utm_source=tpx&utm_medium=transactions` : null;

  const displayLastSoldPrice = formatPriceWithCommas(last_sold_price);
  const displayListPrice = formatPriceWithCommas(listPrice);
  const displayPrice = formatPriceWithCommas(getTransactionPrice(listing, salePrice, listPrice));
  const displaySalePrice = formatPriceWithCommas(salePrice);
  const displaySoldPrice = displaySalePrice || displayLastSoldPrice;
  const listedPriceTitle = `Listed at $${displayListPrice}.`;
  const priceTitle =
    isSoldStatus && displaySoldPrice ? `Sold for $${displaySoldPrice}. ${listedPriceTitle}` : listedPriceTitle;

  const displayImage = getDisplayImage(primaryPhotoHref);

  const isEditable = isTransactionEditable(userInfo, statusId);

  const handleUpdate = (e, transactionId) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(openEditForm(transactionId));
  };

  const handleDelete = (transactionId, statusId) => {
    dispatch(deleteTransaction(transactionId, statusId)).then(() => {
      dispatch(clearAlert());
    });
  };

  const handleAddParty = (e, transactionId) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({
      type: TRANSACTIONS_ADD_PARTY_FORM_TOGGLE,
      transactionId
    });
  };

  const handleNestedLink = e => {
    e.stopPropagation();
  };

  const confirmDeleteHandler = (e, transactionId, statusId) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      showAlert({
        message: `Are you sure you want to delete the transaction for ${displayAddress}?`,
        primaryButtonHandler: () => handleDelete(transactionId, statusId)
      })
    );
  };

  const handleCardClick = () => {
    // We use navigate function over Card url prop, because we want card details to be copy/pasteable.
    const basePath = `${TRANSACTION_URL}/${transactionId}`;

    const isFromDashboard = checkIsFromDashboard(pathname);
    const transactionUrl = !isFromDashboard
      ? pathname.includes('/transactions')
        ? `${basePath}${search}`
        : basePath
      : `/dashboard/view/transactions/${transactionId}`;

    navigate(transactionUrl);
  };

  if (!transactionId) {
    return null;
  }

  const displayStatus = mls_status === 'Expired' ? mls_status : mls_status || source?.raw?.status;
  const statuses = getPropertyStatuses(tpxStatus, displayStatus);
  const sortedTransactionParties = getSortedTransactionParties(transactionParties);

  const cardClasses = classnames({
    [insightCardStyles.propertyInsightCard]: true,
    [insightCardStyles.isEditable]: !isNested,
    [insightCardStyles.isClickable]: true
  });

  return (
    <Card id={transactionId} className={cardClasses} onClick={handleCardClick}>
      <div className={insightCardStyles.grid}>
        <PropertyCardImage
          address={displayAddress}
          hasPropertyData={!!lastModified}
          tags={statuses}
          url={displayImage}
        />
        <div className={insightCardStyles.content}>
          <div className={insightCardStyles.contentWrap}>
            <div className={insightCardStyles.title}>
              <div className={insightCardStyles.titleWrap}>
                <span className={insightCardStyles.address}>{displayAddress}</span>
              </div>
              {displayPrice && (
                <div className={insightCardStyles.priceWrap}>
                  <div className={insightCardStyles.price} title={priceTitle}>
                    <div>${displayPrice}</div>
                    {isSoldStatus && <div className={insightCardStyles.isSold}>${displayListPrice}</div>}
                  </div>
                </div>
              )}
            </div>
            {(displayLastSoldPrice || mlsNumber) && (
              <div className={insightCardStyles.features}>
                {mlsNumber && (
                  <div className={insightCardStyles.detail}>
                    <span className={insightCardStyles.label}>MLS ID</span> {mlsNumber}
                  </div>
                )}
                {displayLastSoldPrice && (
                  <div className={insightCardStyles.detail}>
                    <span className={insightCardStyles.label}>Last Sold Price</span> ${displayLastSoldPrice}
                  </div>
                )}
              </div>
            )}
            {(displayListingDate || displayExpirationDate || displayClosingDate) && (
              <div className={insightCardStyles.details}>
                <div className={insightCardStyles.dates}>
                  {displayListingDate && (
                    <div className={insightCardStyles.detail}>
                      <span className={insightCardStyles.label}>Listed</span> {displayListingDate} {displayDOM}
                    </div>
                  )}
                  {displayClosingDate && (
                    <div className={insightCardStyles.detail}>
                      <span className={insightCardStyles.label}>
                        {isClosingInFuture && !isOnClosingsTab ? 'Closing' : 'Closed'}
                      </span>
                      {displayClosingDate}
                    </div>
                  )}
                  {displayExpirationDate && (
                    <div className={insightCardStyles.detail}>
                      <span className={insightCardStyles.label}>Expires</span> {displayExpirationDate}
                    </div>
                  )}
                  {displayContractAgreementDate && (
                    <div className={insightCardStyles.detail}>
                      <span className={insightCardStyles.label}>Agreement Date</span> {displayContractAgreementDate}
                    </div>
                  )}
                </div>
              </div>
            )}
            {transactionParties && (
              <div className={insightCardStyles.tags}>
                <TagGroup>
                  <Tag label={representation} />
                  {Object.keys(sortedTransactionParties).map(item => {
                    const { contactId, roleName, fullName } = sortedTransactionParties[item];
                    const lowerCasedLabel = roleName.toLowerCase().trim().replace(/\s/g, '').replace(/,/g, '');
                    const tagClasses = classnames({
                      [tagStyles.tag]: true,
                      [tagStyles[`${lowerCasedLabel}Tag`]]: true
                    });

                    const key = `${fullName}tag_${roleName}`;
                    return (
                      <Tag
                        entityType={4}
                        key={key}
                        label={fullName}
                        className={tagClasses}
                        url={createEntityTagUrl(contactId, '4')}
                      />
                    );
                  })}
                </TagGroup>
              </div>
            )}
          </div>

          {!isNested && (
            <div className={insightCardStyles.actions}>
              <ButtonGroup variant="icon">
                {isEditable && (
                  <Fragment>
                    <Button
                      ariaLabel="Edit transaction"
                      icon="edit"
                      onClick={e => handleUpdate(e, transactionId)}
                      tooltipPos="left"
                    />
                    <Button
                      icon="transactionparty"
                      ariaLabel="Add transaction party"
                      onClick={e => handleAddParty(e, transactionId)}
                      tooltipPos="left"
                    />
                    {displayUrl && (
                      <object className="nestedLinkWrapper">
                        <Button
                          url={displayUrl}
                          icon="link"
                          ariaLabel="Open realtor.com"
                          externalLink={true}
                          onClick={handleNestedLink}
                          tooltipPos="left"
                        />
                      </object>
                    )}
                  </Fragment>
                )}
                <Button
                  ariaLabel="Delete transaction"
                  icon="delete"
                  onClick={e => confirmDeleteHandler(e, transactionId, statusId)}
                  tooltipPos="left"
                />
              </ButtonGroup>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default TransactionCard;

TransactionCard.propTypes = {
  data: object,
  isNested: bool,
  location: object.isRequired
};
