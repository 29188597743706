import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

export const MediaQueryContext = createContext(null);

const mediaQueries = {
  // Note: These keys represent a subset of the actual available media queries. New keys should always match the CSS media queries.
  // These should always match src/css/variables/custom-media.css
  isTabletPortraitAndUp: '(min-width: 737px)',
  isTabletLandscapeAndUp: '(min-width: 881px)',
  isDesktopUpCommon: '(min-width: 1280px)',
  supportsHover: '(hover: hover)'
};

export const MediaQueryProvider = props => {
  const { children } = props;

  const [currentMatchMedia, setCurrentMatchMedia] = useState({});

  const getCurrentMatches = () => {
    const currentMatches = {};
    for (const query in mediaQueries) {
      currentMatches[query] = window.matchMedia(mediaQueries[query]).matches;
    }

    setCurrentMatchMedia(currentMatches);
  };

  const handleResize = debounce(() => {
    getCurrentMatches();
  }, 250);

  useEffect(() => {
    getCurrentMatches();
    window.addEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => currentMatchMedia, [currentMatchMedia]);

  return <MediaQueryContext.Provider value={value}>{children}</MediaQueryContext.Provider>;
};

export const useMediaQueryContext = () => {
  return useContext(MediaQueryContext);
};

// Basic HOC to allow access to the standard mediaQueryContext in class components
export const withMediaQueryContextHOC = Component => props =>
  <Component mediaQueryContext={useMediaQueryContext()} {...props} />;
