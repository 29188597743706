import { bool, func } from 'prop-types';
import React from 'react';
import { MessageBanner } from '../../components/MessageBanner';
import { SUPPORT, UNICODE } from '../../constants';
import { MLS_CREDS_ERROR_MESSAGE } from '../../utils/mlsBoards';
import MLSCredentialsButton from './MlsCredentialsButton';

const ErrorMLSCredentials = props => {
  const { clickCallback, hasCredentials, hasOneCredential, isMlsDataAccessApproved, isMlsValid, mlsCredsError } =
    props || {};

  if (hasCredentials && isMlsValid && isMlsDataAccessApproved) {
    return null;
  }

  function getMessage() {
    if (mlsCredsError) {
      return MLS_CREDS_ERROR_MESSAGE;
    }
    if (!hasCredentials) {
      return `No MLS credentials have been linked to your account in TPX.`;
    }

    if (!isMlsDataAccessApproved) {
      return (
        <>
          <span>
            Your MLS data access agreement has not yet been approved. Please email {SUPPORT.MLS_SUPPORT_EMAIL} for
            assistance.
          </span>
        </>
      );
    }

    if (hasOneCredential) {
      return `The MLS credentials linked to your account in TPX are${UNICODE.NBSP}invalid.`;
    }

    return `The MLS selected has invalid credentials linked to your account in${UNICODE.NBSP}TPX.`;
  }

  const icon = !hasCredentials || isMlsDataAccessApproved ? 'error' : 'support';
  const message = getMessage();
  const showButton = (isMlsDataAccessApproved || !hasCredentials) && mlsCredsError !== true;
  const buttonLabel = hasCredentials ? 'Manage Credentials' : undefined;
  const buttonUrl = hasCredentials ? '/settings/mls-credentials' : undefined;

  return (
    <MessageBanner icon={icon} message={message}>
      {showButton && (
        <MLSCredentialsButton
          buttonClick={clickCallback}
          buttonLabel={buttonLabel}
          url={buttonUrl}
          styleType="transparent"
        />
      )}
    </MessageBanner>
  );
};

export default ErrorMLSCredentials;

ErrorMLSCredentials.propTypes = {
  clickCallback: func,
  hasCredentials: bool,
  hasOneCredential: bool,
  isMlsDataAccessApproved: bool,
  isMlsValid: bool
};
