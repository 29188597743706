import React, { Fragment, useState } from 'react';
import { bool } from 'prop-types';
import { setLocalStorageItem, getLocalStorageItem } from '../../utils';
import { ANNOUNCEMENT_LINK, ANNOUNCEMENT_LOCAL_STORAGE_KEY } from '../../constants';
import { AlertDialog } from '../Dialog';

const shouldHideAnnouncement = hideAnnouncement => {
  const shown = getLocalStorageItem(ANNOUNCEMENT_LOCAL_STORAGE_KEY);

  return shown || hideAnnouncement;
};

export const AnnouncementFragment = () => {
  return (
    <Fragment>
      {/* We'd <Icon name="heart" size="xs" isColored={true} className={iconStyles.inlineWithCopy} /> to hear your thoughts
      on Top&nbsp;Producer® products. */}
      Get a <span style={{ color: 'var(--color-secondary-700)' }}>$50 credit</span> for every friend you refer to Top
      Producer® CRM.
    </Fragment>
  );
};

export const Announcement = props => {
  const { hideAnnouncement } = props;
  const [isOpen, setOpen] = useState(true);

  const markAsShown = () => {
    setOpen(false);
    setLocalStorageItem(ANNOUNCEMENT_LOCAL_STORAGE_KEY, true);
  };

  if (shouldHideAnnouncement(hideAnnouncement)) {
    return null;
  }

  const alertProps = {
    isOpen,
    icon: 'smartzip', //announcement | maintenance
    iconIsColored: true, // false
    iconSize: 'l',
    loading: false,
    hint: 'Learn how Smart Targeting can get you in front of your next seller.', //'Take a quick survey.,
    message: "What if you could speak with potential sellers even before your competitors know they're ready to list?",
    primaryButtonLabel: 'Learn more', //Take Survey
    secondaryButtonLabel: 'No, thanks',
    primaryButtonUrl: ANNOUNCEMENT_LINK,
    primaryButtonHandler: markAsShown,
    secondaryButtonHandler: markAsShown
  };

  return <AlertDialog testId={'Announcement-AlertDialog'} data={alertProps} />;
};

Announcement.propTypes = {
  hideAnnouncement: bool // This is to provide an external suppression mechanism to the announcement.
};
