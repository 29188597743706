import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

import { Button } from '../Button';
import { getHelpLink } from '../../utils/help';
import { openPopup } from '../../utils/popup';

export const HelpButton = props => {
  const { className, helpKey, iconSize = 's' } = props;

  if (!helpKey) {
    return null;
  }

  const HELP_LINK = getHelpLink(helpKey);

  const classes = classnames({
    [className]: !!className
  });

  return (
    <Button
      ariaLabel="Learn more"
      className={classes}
      externalLink={true}
      icon="help"
      key={helpKey}
      onClick={e => {
        e.preventDefault();
        openPopup(HELP_LINK);
      }}
      size={iconSize}
      tooltipPos="right"
      url={HELP_LINK}
    />
  );
};

HelpButton.propTypes = {
  className: string,
  helpKey: string.isRequired,
  iconSize: string
};
