import {
  deleteFromCollection,
  deleteFromGroup,
  getMergedEntities,
  getMergedSetOfIds,
  sortCollectionByDate
} from '../utils/collections';

/** @module */

export const NOTES_CURRENT_ENTITY = 'NOTES_CURRENT_ENTITY';
export const NOTES_CURRENT_GROUP = 'NOTES_CURRENT_GROUP';
export const NOTES_DETAILS = 'NOTES_DETAILS';
export const NOTES_DELETE = 'NOTES_DELETE';
export const NOTES_DONE_PAGING = 'NOTES_DONE_PAGING';
export const NOTES_SET = 'NOTES_SET';
export const NOTES_LOADING = 'NOTES_LOADING';
export const NOTES_UPDATE = 'NOTES_UPDATE';

export const initialState = {
  entities: {},
  groups: {},
  currentEntity: null,
  currentGroup: null,
  donePaging: {},
  isLoading: false
};

/**
 * The notes redux reducer.
 * @param {Object} state - the current state of the notes store.
 * @param {Object} action - the action to take on the notes store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 * @param {Boolean} [action.isPaging] - Denotes whether the request data is due to paging.
 * @param {String} [action.searchRequest] - The request query acts as a key when storing groups of notes.
 * @param {Boolean} [action.isLoading] - Denotes whether the data request is in progress.
 */
export const noteReducer = (state = initialState, action = {}) => {
  const { type = 'default', data = {}, group, isPaging, isLoading, isSync, updatedKeys, setCurrentGroup } = action;
  const { id } = data;

  switch (type) {
    case NOTES_SET:
      if (data.records === null) {
        return state;
      }

      const mergedEntities = getMergedEntities(state.entities, data.records, { ignorePreexisting: false }); // SAAS-16277 disabling flag 'ignorePreexisting' for updating FE to reflect updated contacts on Notes view

      const preExistingData = isSync ? [] : state.groups[group];

      return {
        ...state,
        isLoading: isLoading,
        currentGroup: setCurrentGroup ? group : state.currentGroup,
        currentEntity: null,
        entities: mergedEntities,
        groups: {
          ...state.groups,
          [group]: getMergedSetOfIds(preExistingData, data.records, {
            isPaging,
            sortFunction: sortCollectionByDate,
            entities: mergedEntities
          })
        }
      };
    case NOTES_CURRENT_ENTITY:
      return {
        ...state,
        currentEntity: id
      };
    case NOTES_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: group,
        isLoading: isLoading
      };
    case NOTES_DETAILS:
      if (data === null) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            ...state.entities[id],
            ...data
          }
        }
      };
    case NOTES_DELETE:
      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, id)
        },
        groups: Object.keys(state.groups).reduce((acc, group) => {
          acc[group] = deleteFromGroup(state.groups[group], id);

          return acc;
        }, {})
      };
    case NOTES_DONE_PAGING:
      return {
        ...state,
        donePaging: {
          ...state.donePaging,
          [group]: true
        }
      };
    case NOTES_LOADING:
      return {
        ...state,
        isLoading: isLoading
      };
    case NOTES_UPDATE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            ...state.entities[id],
            ...updatedKeys
          }
        }
      };
    default:
      return state;
  }
};
