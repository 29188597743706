/** @module */

export const ADMIN_SET_ADMIN_USER = 'ADMIN_SET_ADMIN_USER';

const initialState = {
  isAdmin: false
};

/**
 * The admin redux reducer.
 * @param {Object} state - the current state of the user store.
 * @param {Object} action - the action to take on the user store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const adminReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADMIN_SET_ADMIN_USER:
      return {
        ...state,
        isAdmin: true
      };
    default:
      return state;
  }
};
