export const COLOR_TAG_DEFAULT = 'rgb(224,230,236)';
export const COLOR_TAG_PERSONAL = 'rgb(219,234,254)';
export const COLOR_TAG_BUYER = 'rgb(177,219,178)';
export const COLOR_COACH = 'rgb(237,231,248)';
export const COLOR_TERTIARY_100 = 'rgb(248,161,123)';
export const COLOR_TERTIARY_200 = 'rgb(247,139,92)';
export const COLOR_TAG_SELLER = 'rgb(255,201,206)';
export const COLOR_TAG_CLIENT = 'rgb(252,229,136)';
export const COLOR_DARK_ORANGE = 'rgb(255,140,85)';
export const COLOR_ORANGE = 'rgb(253,186,116)';
export const COLOR_YELLOW = 'rgb(252,211,77)';
export const COLOR_YELLOW_LIGHT = 'rgb(254,240,138)';
export const COLOR_GREEN = 'rgb(217,249,157)';
export const COLOR_GREEN_DARK = 'rgb(132,204,22)';
export const COLOR_GREEN_LIGHT = 'rgb(187,247,208)';
export const COLOR_BLUE = 'rgb(147,197,253)';
export const COLOR_BLUE_LIGHT = 'rgb(186,230,253)';
export const COLOR_BLUE_LIGHT2 = 'rgb(191,219,254)';
export const COLOR_RED = 'rgb(253,164,175)';
export const COLOR_PINK = 'rgb(254,226,226)';
export const COLOR_PURPLE = 'rgb(251,207,232)';

export const DEFAULT_COLOR_LIST = [
  COLOR_TAG_DEFAULT,
  COLOR_YELLOW,
  COLOR_TAG_CLIENT,
  COLOR_YELLOW_LIGHT,
  COLOR_GREEN_DARK,
  COLOR_GREEN,
  COLOR_GREEN_LIGHT,
  COLOR_TAG_BUYER,
  COLOR_BLUE,
  COLOR_BLUE_LIGHT,
  COLOR_BLUE_LIGHT2,
  COLOR_TAG_PERSONAL,
  COLOR_TERTIARY_100,
  COLOR_TERTIARY_200,
  COLOR_ORANGE,
  COLOR_COACH,
  COLOR_RED,
  COLOR_TAG_SELLER,
  COLOR_PINK,
  COLOR_PURPLE
];

export const BAR_CHART_COLOR_LIST = ['#00427c', '#8cbd8e', COLOR_ORANGE, COLOR_BLUE, COLOR_YELLOW, COLOR_BLUE];
export const DEFAULT_TAG_COLORS = {
  default: { label: 'Default', value: COLOR_TAG_DEFAULT },
  personal: { label: 'Personal', value: COLOR_TAG_PERSONAL },
  buyer: { label: 'Buyer', value: COLOR_TAG_BUYER },
  primary: { label: 'Primary', value: COLOR_TAG_BUYER },
  currentbuyer: { label: 'Current Buyer', value: COLOR_TAG_BUYER },
  primarybuyer: { label: 'Primary Buyer', value: COLOR_TAG_BUYER },
  latest: { label: 'Latest', value: COLOR_TAG_BUYER },
  active: { label: 'Active', value: COLOR_TAG_BUYER },
  buyersagent: { label: "Buyer's Agent", value: COLOR_TAG_BUYER },
  prospecting: { label: 'Prospecting', value: COLOR_COACH },
  leadconversion: { label: 'Lead Conversion', value: COLOR_TERTIARY_100 },
  priceChange: { label: 'Price Change', value: COLOR_TERTIARY_200 },
  seller: { label: 'Seller', value: COLOR_TAG_SELLER },
  currentseller: { label: 'Current Seller', value: COLOR_TAG_SELLER },
  primaryseller: { label: 'Primary Seller', value: COLOR_TAG_SELLER },
  overdue: { label: 'Overdue', value: COLOR_TAG_SELLER },
  pending: { label: 'Pending', value: COLOR_TAG_SELLER },
  pendingshow: { label: 'Pending Show', value: COLOR_TAG_SELLER },
  sold: { label: 'Sold', value: COLOR_TAG_SELLER },
  closed: { label: 'Closed', value: COLOR_TAG_SELLER },
  soldclosed: { label: 'Sold & Closed', value: COLOR_TAG_SELLER },
  sellersagent: { label: "Seller's Agent", value: COLOR_TAG_SELLER },
  client: { label: 'Client', value: COLOR_TAG_CLIENT },
  new: { label: 'New', value: COLOR_TAG_CLIENT },
  suspended: { label: 'Suspended', value: COLOR_ORANGE },
  pastclient: { label: 'Past Client', value: COLOR_TAG_CLIENT },
  comingsoon: { label: 'Coming Soon', value: COLOR_TAG_CLIENT }
};

export const DATE_RANGES_FROM_NOW = {
  current: {
    // 7 days prior and 7 days after today - not shown in UI
    dateFrom: -7,
    dateTo: 7
  },
  overdue: {
    // Previous 30 days excluding today
    dateFrom: -30,
    dateTo: -1
  },
  today: {
    dateFrom: 0,
    dateTo: 0
  },
  tomorrow: {
    dateFrom: 1,
    dateTo: 1
  },
  future: {
    // Next 7 days including today
    dateFrom: 0,
    dateTo: 6
  },
  currentYear: {
    // Past 30 days and Next 365 days including today
    dateFrom: -30,
    dateTo: 365
  },
  custom: {
    dateFrom: 0,
    dateTo: 14
  },
  pastMonth: {
    // Previous 30 days including today
    dateFrom: -30,
    dateTo: 1
  },
  currentTransaction: {
    // Past 2 years including today
    dateFrom: -729,
    dateTo: 1
  },
  threeMonths: {
    dateFrom: -90,
    dateTo: 0
  },
  transactionTask: {
    dateFrom: -30,
    dateTo: 90
  },
  twoYearsWrapped: {
    dateFrom: -365,
    dateTo: 365
  }
};

export const DEFAULT_DATE_RANGES = {
  transaction: {
    ...DATE_RANGES_FROM_NOW.currentTransaction,
    label: 'Transaction Listings'
  },
  task: {
    ...DATE_RANGES_FROM_NOW.custom,
    label: 'Tasks'
  },
  contactTask: {
    ...DATE_RANGES_FROM_NOW.transactionTask,
    label: 'Contact Tasks'
  },
  transactionTask: {
    ...DATE_RANGES_FROM_NOW.transactionTask,
    label: 'Transaction Tasks'
  },
  closing: {
    ...DATE_RANGES_FROM_NOW.currentTransaction,
    label: 'Transaction Closings'
  }
};

export const DEFAULT_TRANSACTION_SETTINGS = {
  transactionRange: {
    ...DATE_RANGES_FROM_NOW.twoYearsWrapped,
    label: 'Transaction Listings'
  },
  transactionClosingRange: {
    ...DATE_RANGES_FROM_NOW.twoYearsWrapped,
    label: 'Transaction Closings'
  },
  transactionTaskRange: {
    ...DATE_RANGES_FROM_NOW.transactionTask,
    label: 'Transaction Tasks'
  }
};

export const DEFAULT_CONTACT_SETTINGS = {
  taskRange: {
    ...DATE_RANGES_FROM_NOW.transactionTask,
    label: 'Contact Tasks'
  }
};

export const DEFAULT_TASK_SETTINGS = {
  taskRange: {
    ...DATE_RANGES_FROM_NOW.custom,
    label: 'Tasks'
  }
};
