import React from 'react';
import { bool, func, string } from 'prop-types';
import classnames from 'classnames';
import Switch from 'react-switch';

import { Icon } from '../';

import styles from './FormToggle.css';

export const FormToggle = props => {
  const { ariaLabel, className, changeHandler, disabled = false, id, isChecked = false, label, subLabel } = props;

  if (!changeHandler && !disabled) {
    return null;
  }

  const classes = classnames({
    [styles.toggle]: true,
    [className]: !!className
  });

  const handleChange = changeHandler || (() => {});

  return (
    <div>
      <label htmlFor={id} className={classes} data-testid={`FormToggle.${id}`}>
        <Switch
          aria-label={ariaLabel}
          id={id}
          name={id}
          onChange={handleChange}
          checked={isChecked}
          checkedIcon={<Icon name="check" size="xs" className={styles.icon} isColored={true} />}
          uncheckedIcon={false}
          onColor="#e8ebf2"
          offColor="#f9f9fb"
          onHandleColor="#fff"
          boxShadow="0 1px 3px rgba(0,0,0, .2)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, .1)"
          height={26}
          width={50}
          className={styles.switch}
          disabled={disabled}
        />
        {label}
      </label>
      {subLabel && <label className={styles.subLabel}>{subLabel}</label>}
    </div>
  );
};

FormToggle.propTypes = {
  ariaLabel: string.isRequired,
  className: string,
  changeHandler: func,
  disabled: bool,
  id: string.isRequired,
  isChecked: bool,
  label: string,
  subLabel: string
};
