/** @module */

export const ALERT_SET = 'ALERT_SET';
export const ALERT_CLEAR = 'ALERT_CLEAR';
export const ALERT_LOADING = 'ALERT_LOADING';

export const initialState = {
  isOpen: false,
  loading: false,
  icon: 'delete',
  iconSize: 's',
  hint: null,
  message: null,
  primaryButtonLabel: 'Delete',
  primaryButtonHandler: null,
  primaryButtonIcon: null,
  secondaryButtonLabel: 'Cancel',
  secondaryButtonHandler: null,
  secondaryButtonIcon: null,
  tertiaryButtonLabel: null,
  tertiaryButtonHandler: null
};

/**
 * The alert redux reducer.
 * @param {Object} state - the current state of the alert store.
 * @param {Object} action - the action to take on the alert store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - A data object that represents the alert's details.
 */
export const alertReducer = (state = initialState, action = {}) => {
  const { data, type = 'default' } = action;

  switch (type) {
    case ALERT_SET:
      return {
        ...state,
        ...data,
        isOpen: true
      };
    case ALERT_LOADING:
      return {
        ...state,
        loading: true
      };
    case ALERT_CLEAR:
      return initialState;
    default:
      return state;
  }
};
