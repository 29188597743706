/** @module */

export const HOME_INTEL_SAVE_SETTINGS = 'HOME_INTEL_SAVE_SETTINGS';
export const HOME_INTEL_SAVE_AGENT_DETAILS = 'HOME_INTEL_SAVE_AGENT_DETAILS';
export const HOME_INTEL_SAVE_CONTACT_EVENTS = 'HOME_INTEL_SAVE_CONTACT_EVENTS';
export const HOME_INTEL_HAS_RETRIEVED_SETTINGS = 'HOME_INTEL_HAS_RETRIEVED_SETTINGS';
export const HOME_INTEL_SUBSCRIPTION_REQUESTED = 'HOME_INTEL_SUBSCRIPTION_REQUESTED';
export const HOME_INTEL_SETTINGS_COOLDOWN = 'HOME_INTEL_SETTINGS_COOLDOWN';

export const initialState = {
  reportFrequency: 'monthly', //monthly,quarterly,biAnnually
  settings: null,
  notifications: null,
  agentDetails: null,
  hasRetrievedSettings: false,
  subscriptionRequested: false
};

/**
 * The home intel preferences reducer redux reducer.
 * @param {Object} state - the current state of the user store.
 * @param {Object} action - the action to take on the user store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const homeIntelReducer = (state = initialState, action = {}) => {
  const { data } = action;
  switch (action.type) {
    case HOME_INTEL_SAVE_AGENT_DETAILS:
      return {
        ...state,
        agentDetails: data
      };
    case HOME_INTEL_SAVE_SETTINGS:
      return {
        ...state,
        ...data
      };
    case HOME_INTEL_SAVE_CONTACT_EVENTS:
      return {
        ...state,
        contactEvents: data
      };
    case HOME_INTEL_HAS_RETRIEVED_SETTINGS:
      return {
        ...state,
        hasRetrievedSettings: data
      };
    case HOME_INTEL_SUBSCRIPTION_REQUESTED:
      return {
        ...state,
        subscriptionRequested: data
      };
    case HOME_INTEL_SETTINGS_COOLDOWN:
      return {
        ...state,
        coolDownTimeStamp: data
      };
    default:
      return state;
  }
};
