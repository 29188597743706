import React from 'react';
import { Link, Match } from '@reach/router';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Card.css';

export const Card = props => {
  const { children, className, id, isNested, isFollowup, isSelected, onClick, url } = props;

  if (!children) {
    return null;
  }

  const classes = classnames({
    [styles.card]: !className,
    [styles.isNested]: isNested,
    [styles.isFollowup]: isFollowup,
    [styles.isClickable]: !!onClick,
    [className]: !!className
  });

  if (!url) {
    // ToDo: this likely doesn't have all the props it needs.
    return (
      <div className={classes} id={id} onClick={onClick}>
        {children}
      </div>
    );
  }

  return (
    <div className={classes}>
      <Match path={url}>
        {matchProps => {
          const { location } = matchProps;
          const { pathname } = location;

          const urlSplit = url.split('?');

          // ToDo: this should be chunked out into a utility method for testing and later refinement
          // to see if a url matches and should be selected, we test to see if starts with the url and has a certain number of "/"
          const selected = isSelected || (pathname.startsWith(urlSplit[0]) && !isNested);

          if (url.startsWith('mailto')) {
            return (
              <a href={url} id={id} className={styles.cardLink} data-selected={selected} onClick={onClick}>
                {children}
              </a>
            );
          }

          if (url.startsWith('http')) {
            return (
              <a
                href={url}
                id={id}
                className={styles.cardLink}
                data-selected={selected}
                onClick={onClick}
                rel="noopener"
                target="_blank"
              >
                {children}
              </a>
            );
          }

          return (
            <Link to={url} id={id} className={styles.cardLink} data-selected={selected} onClick={onClick}>
              {children}
            </Link>
          );
        }}
      </Match>
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
