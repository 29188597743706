import { IndexedTree } from '../IndexedTree';
import { getGroupNameFromInput } from '../reducer';

/**
 * @typedef {8|9} OwnerType 8: contacts; 9: transactions.
 */
export const OWNER_TYPE = {
  contacts: 8,
  transactions: 9
};

export const TASK_PLAN_CATEGORIES = {
  all: 0,
  ...OWNER_TYPE
};

export const CATEGORY_ITEMS = {
  [TASK_PLAN_CATEGORIES.contacts]: 'Contact',
  [TASK_PLAN_CATEGORIES.transactions]: 'Transaction'
};

/**
 * Get the category id's corresponding label
 * @param {Number} categoryId 8=Contacts, 9=Transactions
 */
export const getCategoryLabel = categoryId => CATEGORY_ITEMS[categoryId];

const templateTreeOptions = { idKey: 'itemCode', prevItemIdKey: 'prevItemCode' };

/**
 * Get the items in the template in the for of an IndexedTree
 * @param {Object} template Template from which we ant to get the items
 * @param {Object[]} [templante.items]
 * @returns {IndexedTree} the indexed tree of items or null if template is not passed
 */
export const getTemplateItems = template => {
  return template?.items ? new IndexedTree(template?.items, templateTreeOptions) : null;
};

/**
 * Convert an indexed tree of plan items into an array of template items
 * by replacing "id" and "prevItemId" with their corresponding template keys (@see templateTreeOptions)
 * @param {IndexedTree} items
 * @returns {Object[]} Template Items.
 */
const convertItemsToTemplateItems = items => {
  const arr =
    Array.from(items?.getIndex?.() || new Map())
      .map(([, child]) => child) // convert tree into array
      .map(c => c.data) // extract data from each node
      .map(item => {
        // for each item, we replace the 'id', and the 'prevItemId'
        // with the corresponding template keys (i.e.: itemCode, and prevItemCode)
        const { id, prevItemId, ...rest } = item;
        return {
          [templateTreeOptions.idKey]: id,
          [templateTreeOptions.prevItemIdKey]: prevItemId,
          ...rest
        };
      }) || [];

  return arr;
};

/**
 * Convert a Plan into a Template by replacing "id" and "prevItemId" with their corresponding template keys
 * on each item in the plan (@see templateTreeOptions)
 * @param {{ categoryId: Number, description: String, items: IndexedTree, name: String}} plan Plan to be converted
 * @returns {{ categoryId: Number, description: String, items: Object[], name: String}} plan Plan to be converted
 */
export const convertPlanToTemplate = plan => {
  const { categoryId, description, items, name, autoPlanType, stopOption } = plan || {};

  return {
    categoryId: autoPlanType != null ? 'leadResponse' : categoryId,
    description,
    items: convertItemsToTemplateItems(items),
    name,
    stopOption: autoPlanType != null ? stopOption : null
  };
};

/**
 * @param {IndexedTree?} items
 * @param {String} id item id
 * @returns {Object?} item with the corresponding id
 */
export const getItemData = (items, id) => {
  return items?.get?.(id)?.data;
};

export const PlanFilter = {
  all: '0',
  unapplied: '1',
  applied: '2'
};

/**
 * Get the group name for applied plans, based on the owner type and owner id.
 * @param {String} ownerId id to fetch by
 * @param {number} ownerType owner type @see OwnerType (utils/taskPlans.js)
 */
export const getAppliedPlansGroupName = (ownerId, ownerType) => {
  const filter = PlanFilter.applied;
  return getGroupNameFromInput(`${ownerType}::${ownerId}::${filter}`);
};

/**
 * Get the query params for fetching applied plans, based on the owner type and owner id.
 * @param {String} ownerId id to fetch by
 * @param {number} ownerType owner type @see OwnerType (utils/taskPlans.js)
 */
export const getAppliedPlansParams = (ownerId, ownerType) => {
  const filter = PlanFilter.applied;
  return { ownerType, ownerId, filter };
};

/**
 * Get the group name for plans, based on the category id and owner autoPlanType.
 * @param {number} categoryId
 * @param {String} autoPlanType
 */
export const getPlansGroupName = (categoryId, autoPlanType) => {
  return getGroupNameFromInput(Boolean(autoPlanType) ? `${categoryId}::${autoPlanType}` : categoryId.toString());
};
