export const SETUP_USER_REQUEST = 'SETUP_USER_REQUEST';
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS';
export const SETUP_USER_FAIL = 'SETUP_USER_FAIL';
export const SETUP_USER_EULA_DISPLAY = 'SETUP_USER_EULA_DISPLAY';

export const initialState = {
  displayEula: true,
  isEulaAccepted: true,
  isLoading: false
};

/**
 * The alert redux reducer.
 * @param {Object} state - the current state of the alert store.
 * @param {Object} action - the action to take on the alert store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - A data object that represents the alert's details.
 */
export const setupUserReducer = (state = initialState, action = {}) => {
  const { type = 'default', displayEula, isEulaAccepted } = action;

  switch (type) {
    case SETUP_USER_EULA_DISPLAY:
      return {
        ...state,
        displayEula,
        isEulaAccepted
      };
    case SETUP_USER_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case SETUP_USER_SUCCESS:
      return {
        ...state,
        isSuccessful: true,
        isLoading: false
      };
    case SETUP_USER_FAIL:
      return {
        ...state,
        isSuccessful: false,
        isLoading: false
      };
    default:
      return state;
  }
};
