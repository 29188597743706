export class IndexedTree {
  /**
   * An IndexedTree maintains two internal data structures based on the items added to it:
   * - A tree accessed by its root: `this.getRoot()`
   * - An index of the elements in the tree, that is used to get direct access
   *   to the elements, e.g.: `this.get('1')`
   *
   * It places the items in the tree according to its `prevItemId` field
   * - If the item does not have a prevItemId, then, it is placed in the root of the tree
   * - If the item have a prevItemId, it is placed as a child to the item with the matching id
   * - Each node in the tree has a `children` Map pointing to its children
   * - If the node does not have any child, then, its `children` object will be an empty Map (children.size === 0)
   *
   * e.g.:
   * ```
   * const items = [
   *  { id: '1', description: 'Task 1' },
   *  { id: '2', description: 'Sub-Task 1', prevItemId: '1' },
   *  { id: '3', description: 'Task 2', prevItemId: null }
   * ];
   *
   * const tree = new HashTree(items);
   *
   *  // tree.getRoot():
   *  const expected =   Map {
   *    '1' => {
   *    	data: { id: '1', description: 'Task 1' },
   *      id: '1',
   *      parentId: undefined,
   *      children: Map {
   *        '2' => {
   *        	data: { id: '2', description: 'Sub-Task 1', prevItemId: '1' },
   *          id: '2',
   *          parentId: '1',
   *          children: Map {}
   *        }
   *      }
   *    },
   *    '3' => {
   *    	data: { id: '3', description: 'Task 2', prevItemId: null },
   *      id: '3',
   *      parentId: null,
   *      children: Map {}
   *    }
   * }
   * ```
   *
   * @param {Object[]} items Array of items to use when building the tree
   * @param {Object} options
   * @param {String} [options.idKey] key that represents the id in each item object
   * @param {String} [options.prevItemIdKey] key that represents the parent's id in each item object
   */
  constructor(items = [], options) {
    if (items && !Array.isArray(items)) {
      throw new Error(`items must be an array, but got: ${JSON.stringify(items)}`);
    }

    this._idKey = options?.idKey || 'id';
    this._prevItemIdKey = options?.prevItemIdKey || 'prevItemId';
    this._root = { children: new Map() };
    this._index = new Map();

    items.slice().forEach(this.add);
  }

  /**
   * @returns {{ children: Map<string, Node> }} the items in the root of the tree
   */
  getRoot = () => {
    return this._root;
  };

  /**
   * @returns {Map<string, Node>} a map with all items in the iree
   */
  getIndex = () => {
    return this._index;
  };

  /**
   * @returns {Object} the item indexed by id
   */
  get = id => {
    return this._index.get(id);
  };

  /**
   * @returns {Number} the number of elements in the tree in total
   */
  size = () => {
    return this._index.size;
  };

  /**
   * Add and index an item into the right position according to its parent id.
   * @param {Object} item item to add into the tree.
   */
  add = item => {
    const node = this._createNode(item);

    let parent = this.get(node.parentId);
    if (!parent) {
      parent = this._root;
    }

    parent.children.set(node.id, node);
    this._index.set(node.id, node);
  };

  /**
   * Delete an item based on its id
   * @param {any} itemId id of the item to be deleted
   */
  delete = itemId => {
    const node = this.get(itemId);

    // if node isn't found
    if (!node) {
      // do nothing
      return;
    }

    // if item has children
    if (node.children.size) {
      // put all children in the root
      for (const [id, child] of node.children) {
        this._root.children.set(id, child);
      }
    }

    let parent = this.get(this.get(itemId)?.parentId);
    // if item has no parent
    if (!parent) {
      // default to the root
      parent = this._root;
    }

    // delete item from its parent
    parent.children.delete(itemId);
    this._index.delete(itemId);
  };

  /**
   * @param {Object} item
   * @returns {string} item's parent id based on `options.prevItemIdKey` field
   */
  _getPrevItemId = item => {
    return item[this._prevItemIdKey];
  };

  /**
   * @param {Object} item
   * @returns {string} item's id based on `options.idKey` field
   */
  _getItemId = item => {
    return item[this._idKey];
  };

  /**
   * @param {Object} item
   * @returns {{data: Object, id: String, parentId: String, children: Map<String, Object>}} node
   */
  _createNode = item => {
    this._validateItem(item);
    return {
      data: item,
      id: this._getItemId(item),
      parentId: this._getPrevItemId(item),
      children: new Map()
    };
  };

  /**
   * @param {Object} item item to validate
   * @throws Throws an Error if an id is not found in item
   */
  _validateItem = item => {
    // The only requirement is that: the item must have an id
    // prevItemId can be absent meaning that the item belongs to the root
    if (!this._getItemId(item)) {
      throw new Error(`Item must have an identifier! ${this._idKey} not found in: ${JSON.stringify(item)}`);
    }
  };
}

export const getRoot = tree => {
  return tree?.getRoot?.();
};

/**
 * @param {IndexedTree} tree
 */
export const getIndex = tree => {
  return tree?.getIndex?.() || new Map();
};

/**
 * @typedef Node
 * @property {Object} data
 * @property {Map<string, Node>} children
 */
