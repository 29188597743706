import { isFieldFilled } from './validation';
import {
  SUPPORTED_TEMPLATE_OBJECT_TYPES,
  SYSTEM_EMAIL_TEMPLATE_GROUP_NAME,
  SYSTEM_TEXT_TEMPLATE_GROUP_NAME,
  USER_EMAIL_TEMPLATE_GROUP_NAME,
  USER_TEXT_TEMPLATE_GROUP_NAME
} from '../constants/templates';
import { serializeForTexting } from '../components/Editor/editor-utils';
import { TEXT_MESSAGE_MAXLENGTH } from './texts';

// Field from template object
export const ONE_OFF_TEMPLATE_OBJECT_TYPE = 3;
// Field from taskTemplate within task/activity object
export const ONE_OFF_TEMPLATE_OBJECT_TYPE_ID = 5;
/**
 * Checks if the objectType is currently supported in TPX FE.
 * @param {-1|0|1|3|99} objectType -1 = all;  0 = team/profile; 1 = user; 3 = user level, one-off template; 99 = system
 * @returns {boolean}.
 */
export const isSupportedTemplateObjectType = objectType => SUPPORTED_TEMPLATE_OBJECT_TYPES.includes(objectType);

export const isOneOffTemplate = template => {
  const { templateTypeId } = template || {};
  if (!templateTypeId) {
    return false;
  }
  return templateTypeId === ONE_OFF_TEMPLATE_OBJECT_TYPE_ID;
};

export const getInitialFields = props => {
  const { id, entity, subType } = props;
  const { body: entityBody, category, description } = entity || {};

  const { body, category: entityCategory, subject } = entityBody || {};
  return {
    body: {
      value: body,
      isValid: true
    },
    fileId: {
      value: id
    },
    category: { value: category || entityCategory || '', isValid: true }, // We migrated the entity shape from entity.file.category to entity.category, so we support both versions.
    name: { value: description || '', isValid: isFieldFilled(description) },
    subject: { value: subject || '', isValid: isFieldFilled(subject) },
    type: {
      value: parseInt(subType) || 1
    }
  };
};

/**
 * Removes the HTML tags from a given string
 * @param {string} html a string, potentially, with HTML
 * @returns {string} the text content without HTML
 */
export const removeHtml = html => {
  if (!html) {
    return html;
  }

  return new DOMParser().parseFromString(html, 'text/html').body.textContent;
};

/**
 * Gets an array of merge code areas used in the template body.
 * @param {string} body The template body.
 * @returns {Array} Array of merge code areas.
 */
export const getMergeCodeAreas = body => {
  const SUPPORTED_MERGE_CODE_AREAS = ['agent', 'contact', 'transaction', 'relationship'];
  const cleanBody = removeHtml(body);

  return SUPPORTED_MERGE_CODE_AREAS.filter(mergeCodeArea => cleanBody?.includes(`{{${mergeCodeArea}.`));
};

/**
 * JSON parses a stringified body object that has the shape { area, body, category, subject }.
 * @param {Object} body A JSON stringified body object.
 */
export const getParsedTemplateBody = body => {
  return body ? JSON.parse(body) : {};
};

/**
 * Gets a list of templates used in <LookupTemplate/> given an array of group names
 * @param {Object} templates templates object from redux store
 * @param {String[]} groupNames names of the wanted group
 * @returns {Object[]}
 */
export const getTemplateListByGroups = (templates, groupNames) => {
  if (!templates || !groupNames || groupNames.length < 1) {
    return [];
  }
  // Get entities by group name
  const entities = Object.keys(templates.entities).reduce((acc, key) => {
    if (groupNames.some(group => templates.groups?.[group]?.includes(key))) {
      acc = { ...acc, ...{ [key]: templates.entities[key] } };
    }
    return acc;
  }, {});

  return (
    entities &&
    Object.values(entities).map(item => ({
      category: item.category,
      objectType: item.objectType,
      id: item.fileId,
      title: item.description
    }))
  );
};

export const getTemplatesEntitiesByType = (entities, groups, type = '1') => {
  const emailTemplateGroups = Object.keys(groups).filter(name => name.split('::')[1] === type);
  return emailTemplateGroups.reduce((acc, key) => {
    return groups[key].reduce((acc, groupKey) => {
      return { ...acc, ...{ [groupKey]: entities[groupKey] } };
    }, acc);
  }, {});
};

export const getTemplateList = (templates, type) => {
  return getTemplateListByGroups(
    templates,
    type === 'tpxEmail'
      ? [SYSTEM_EMAIL_TEMPLATE_GROUP_NAME, USER_EMAIL_TEMPLATE_GROUP_NAME]
      : [SYSTEM_TEXT_TEMPLATE_GROUP_NAME, USER_TEXT_TEMPLATE_GROUP_NAME]
  );
};

export const getSocialConnectTaskPlan = (plans, isUSAgent) => {
  const planToKeep = isUSAgent ? 'Social Connect' : 'Social Connect (No Texting)';
  return plans.find(template => template.name === planToKeep);
};

export const getSocialConnect2TaskPlan = (plans, isUSAgent) => {
  const planToKeep = isUSAgent ? 'Social Connect 2' : 'Social Connect 2 (No Texting)';
  return plans.find(template => template.name === planToKeep);
};

export const getTaskPlanTemplates = (plans, isUSAgent) => {
  return isUSAgent
    ? plans
    : plans.filter(template => template.name !== 'Social Connect' && template.name !== 'Social Connect 2');
};

/**
 * Takes a merge code like `{{ something }}` and returns `something`; this could contain HTML formatting
 * @param {String} mergeCode - A merge code string.
 */
export const getMergeCodeContent = mergeCode => {
  // We splice the {{ }} from the match to leave just the key.
  return mergeCode.slice(2, -2).trim();
};

export const isEditorEmpty = editor => {
  if (!editor) {
    return true;
  }
  const bodyValue = Array.isArray(editor) ? editor[0] : editor;
  const templateBody = serializeForTexting(bodyValue).trim();

  return ['', '<br>'].includes(templateBody);
};

export const isTextEditorValid = editor => {
  if (!editor) {
    return true;
  }
  const bodyValue = Array.isArray(editor) ? editor[0] : editor;
  const templateBody = serializeForTexting(bodyValue).trim();

  return templateBody?.length <= TEXT_MESSAGE_MAXLENGTH;
};
