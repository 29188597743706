import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Loading } from '../../components';

import styles from './ListFooter.css';

export const ListFooter = props => {
  const { isLoading, donePaging = false, className, infiniteRef } = props || {};

  if (donePaging === true) {
    return null;
  }

  const classes = classnames({
    [styles.footer]: !className,
    [className]: !!className
  });

  const children = () => {
    if (infiniteRef) {
      return (
        <>
          {(isLoading || !donePaging) && (
            <div ref={infiniteRef}>
              <Loading />
            </div>
          )}
        </>
      );
    }

    if (isLoading) {
      return <Loading loading={true} className={styles.loading} />;
    }
  };

  return <footer className={classes}>{children()}</footer>;
};

ListFooter.propTypes = {
  isLoading: PropTypes.bool,
  donePaging: PropTypes.bool
};
