import {
  GET_RELATIONSHIPS_FAIL,
  GET_RELATIONSHIPS,
  GET_RELATIONSHIPS_SUCCESS,
  GET_RELATIONSHIP_TYPES,
  GET_RELATIONSHIP_TYPES_SUCCESS,
  GET_RELATIONSHIP_TYPES_FAIL,
  SET_RELATIONSHIPS_DIALOG,
  ADD_RELATIONSHIP_SUCCESS,
  ADD_RELATIONSHIP_FAIL,
  DELETE_RELATIONSHIP_FAIL,
  DELETE_RELATIONSHIP_SUCCESS,
  CLEAR_RELATED_CONTACT_ID
} from '../reducers/relationships';
import { TASKS_RESET } from '../reducers/tasks';
import { request, requestError } from '../utils';
import { clearAlert } from './alert';
import { getContactDetail } from './contacts';
import { showMessage } from './message';

/**
 *
 * @param {Boolean} status - isOpen or isClosed
 * @param {Object} formData - for editing an existing relationship
 */
export const setRelationshipDialog = (status, formData) => {
  return dispatch => {
    dispatch({
      type: SET_RELATIONSHIPS_DIALOG,
      formIsShowing: status,
      relationshipFormData: formData
    });
  };
};

/**
 *
 * @param {String} contactId - ContactId
 * @returns relationships based on ContactId
 */
export const getRelationships = options => {
  const { contactId } = options;

  return async (dispatch, getStore) => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'GET',
      path: `contacts/${contactId}/relationships`,
      shouldBeCached: false
    };

    dispatch({ type: GET_RELATIONSHIPS });

    try {
      const { data } = await request(requestOptions);

      const { entities: contactEntities } = getStore().contacts;

      dispatch({
        type: GET_RELATIONSHIPS_SUCCESS,
        group: contactId,
        relationships: data
      });

      const promises = [];
      const existingContacts = [];

      data.forEach(relationship => {
        if (contactEntities[relationship.relatedContactId]) {
          existingContacts.push(contactEntities[relationship.relatedContactId]);
        } else {
          promises.push(dispatch(getContactDetail({ id: relationship.relatedContactId, suppressWarning: true })));
        }
      });

      return Promise.all(promises).then(resolution => {
        return { relationshipData: data, contactRelationshipData: existingContacts.concat(resolution) };
      });
    } catch (error) {
      requestError(error, dispatch);

      dispatch({ type: GET_RELATIONSHIPS_FAIL });
    }
  };
};

/**
 * Fetches relationship types
 */
export const getRelationshipTypes = () => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'GET',
      path: `contacts/relationships/types`,
      shouldBeCached: true
    };

    dispatch({ type: GET_RELATIONSHIP_TYPES });

    try {
      const { data } = await request(options);

      dispatch({
        type: GET_RELATIONSHIP_TYPES_SUCCESS,
        relationshipTypes: data
      });
    } catch (error) {
      requestError(error, dispatch);

      dispatch({ type: GET_RELATIONSHIP_TYPES_FAIL });
    }
  };
};

/**
 *
 * @param {*} contactId
 * @param {*} relatedContactId
 * @param {*} relationshipTypeId
 * @returns
 */
export const saveRelationship = data => {
  const { contactId, relatedContactId, relationshipTypeId, relationshipId = null } = data;

  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'POST',
      path: relationshipId
        ? `contacts/${contactId}/relationships/${relationshipId}`
        : `contacts/${contactId}/relationships`,
      payload: {
        relatedContactId,
        relationshipTypeId
      },
      shouldBeCached: false
    };

    try {
      await request(options);
      dispatch({ type: ADD_RELATIONSHIP_SUCCESS });
      dispatch(getRelationships({ contactId }));
      dispatch(showMessage({ message: 'Relationship created successfully.', type: 'success' }, true));
    } catch (error) {
      requestError(error, dispatch);
      dispatch({ type: ADD_RELATIONSHIP_FAIL });
    }
  };
};

/**
 *
 * @param {*} contactId
 * @param {*} relationshipId
 * @param {*} relatedContactId
 * @param {*} relationshipTypeId
 */
export const deleteRelationship = data => {
  const { contactId, relatedContactId, relationshipTypeId, relationshipId } = data;
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'DELETE',
      path: `contacts/${contactId}/relationships/${relationshipId}`,
      payload: {
        relatedContactId,
        relationshipTypeId
      },
      shouldBeCached: false
    };

    try {
      await request(options);

      dispatch({ type: DELETE_RELATIONSHIP_SUCCESS, relationshipId });
      dispatch({ type: CLEAR_RELATED_CONTACT_ID, relatedContactId });
      dispatch(getRelationships({ contactId }));
      dispatch(clearAlert());
      dispatch(showMessage({ message: 'Relationship deleted successfully.', type: 'success' }, true));
    } catch (error) {
      requestError(error, dispatch);
      dispatch({ type: DELETE_RELATIONSHIP_FAIL });
    }
  };
};

/**
 * @param {*} contactId
 */
export const convertRelationship = data => {
  const { contactId } = data;

  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'POST',
      path: `contacts/relationships/convert`,
      payload: {
        contactId
      },
      shouldBeCached: false
    };

    try {
      await request(options);
      dispatch(getContactDetail({ id: contactId }));
      dispatch({ type: ADD_RELATIONSHIP_SUCCESS });
      dispatch({ type: TASKS_RESET });
      dispatch(getRelationships({ contactId }));
      dispatch(showMessage({ message: 'New spouse relationship created.', type: 'success' }, true));
    } catch (error) {
      requestError(error, dispatch);
      dispatch({ type: ADD_RELATIONSHIP_FAIL });
    }
  };
};
