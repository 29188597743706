import React, { Fragment } from 'react';
import { func, object } from 'prop-types';
import { Dropdown, FormLabel, FormRow, FormToggle, FormHint, FormField } from '../';
import { REPORT_FREQUENCY } from '../../data/snapshot';

import styles from './SnapshotForm.css';
import formFieldStyles from '../FormField/FormField.css';
import formToggleStyles from '../FormToggle/FormToggle.css';

export const SnapshotSettingsForm = props => {
  const {
    board,
    disabled,
    formIsValid,
    multiZips,
    onListingAlertChange,
    onSelectChange,
    handleMultiZipChange,
    reportFrequency
  } = props;

  const { listing_alert_setting } = board;
  const { new_listing, sold, price_change } = listing_alert_setting;

  return (
    <Fragment>
      <FormRow>
        <div className={formFieldStyles.field}>
          <FormLabel htmlFor="reportFrequency">Report frequency</FormLabel>
          <Dropdown
            id="reportFrequency"
            items={REPORT_FREQUENCY}
            className={styles.select}
            onChange={onSelectChange}
            value={reportFrequency.value}
            disabled={disabled}
          />
        </div>
      </FormRow>
      {board && board.listing_alert_setting.enable_listing_alert ? (
        <Fragment>
          <FormRow className={styles.verticalRow}>
            <FormLabel htmlFor="new_listing">Which listing alerts do you want to send?</FormLabel>
            <div className={formToggleStyles.toggleContainer}>
              {new_listing !== null && (
                <FormToggle
                  id="new_listing"
                  value="new_listing"
                  label="Just Listed"
                  ariaLabel="Just Listed"
                  changeHandler={onListingAlertChange}
                  isChecked={new_listing}
                />
              )}
              {price_change !== null && (
                <FormToggle
                  id="price_change"
                  value="price_change"
                  label="Price Change"
                  ariaLabel="Price Change"
                  changeHandler={onListingAlertChange}
                  isChecked={price_change}
                />
              )}
              {sold !== null && (
                <FormToggle
                  id="sold"
                  value="sold"
                  label="Just Sold"
                  ariaLabel="Just Sold"
                  changeHandler={onListingAlertChange}
                  isChecked={sold}
                />
              )}
            </div>
          </FormRow>
          <FormRow>
            <div className={formFieldStyles.field}>
              <FormField
                id="multiZips"
                label="Include listing alerts for these additional zip codes?"
                value={multiZips.value}
                inputType="text"
                size="l"
                maxLength="256"
                validationMessage="Invalid or too many zip codes"
                fieldIsValid={multiZips.isValid}
                formIsValid={formIsValid}
                onChange={handleMultiZipChange}
                disabled={disabled}
              />
              <FormHint className={styles.hint}>Enter up to 10 zips separated by commas.</FormHint>
            </div>
          </FormRow>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

SnapshotSettingsForm.propTypes = {
  board: object,
  onListingAlertChange: func,
  onSelectChange: func,
  reportFrequency: object
};
