import React, { Fragment, lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showDrawer } from '../../actions/drawer';
import { getEmailTemplates, getTextTemplates } from '../../actions/templates';
import { showWrapup } from '../../actions/wrapup';

import { Loading, Tab, TabContainer, TabGroup, TaskList } from '../../components';
import { Button, ButtonGroup } from '../../components/Button';
import { View } from '../../components/View';
import { ViewHeader } from '../../components/ViewHeader';
import { DATE_FORMATS } from '../../constants';
import {
  getParamFromSearch,
  getTasksMatchingDuePeriod,
  parseParamsStr,
  TASK_TABS_WITH_DATE_RANGE,
  TASK_TYPES_MAP
} from '../../utils';
import { TASK_CATEGORIES, getDateFromToday } from '../../utils/tasks';

import format from 'date-fns/format';
import { parseISODate } from '../../utils/dates';
import { generateCleanParamsStr } from '../../utils/strings';
import { getAssignedToOptions } from '../../utils/team';
import styles from './Tasks.css';
import { DATE_RANGES_FROM_NOW } from '../../constants/preferences';
const PrintButton = lazy(() => import('../../components/Button/PrintButton'));

const COLUMN_DATA = [
  { id: 'check', label: '' },
  { id: 'task', label: 'Task' },
  { id: 'priority', label: 'Priority' },
  { id: 'due', label: 'Due' },
  { id: 'contact', label: 'Contact' },
  { id: 'assignedTo', label: 'Assigned to' },
  { id: 'actions', label: '' }
];

const Tasks = props => {
  const { children, location } = props;
  const { pathname, search } = location;
  const isTasksImportantDates = pathname === '/tasks/important-dates';
  const mountedParams = parseParamsStr(search) || {};
  const { due: paramDue = 'today', complete, incomplete, assignedTo } = mountedParams;
  const due = isTasksImportantDates ? null : paramDue;
  const mountedAssignTo = assignedTo;

  const [mountedComplete, setMountedComplete] = useState(false);
  const [mountedInComplete, setMountedIncomplete] = useState(true);

  const dispatch = useDispatch();
  const { counts, currentGroup, entities, groups, isLoading, isLoadingImportantDates, lastSearchedDate } = useSelector(
    state => state.tasks
  );

  const team = useSelector(state => state.team);

  const { tasks } = useSelector(state => state.preferences);

  const { dateFrom, dateTo } = tasks.taskRange || {};

  const teamList = getAssignedToOptions(team, 'all');
  teamList.unshift({ id: 'all', value: 'All' });

  useEffect(() => {
    dispatch(getEmailTemplates());
    dispatch(getTextTemplates());

    if (!complete && !incomplete) {
      setMountedIncomplete(true);
      setMountedComplete(false);
    }

    if (complete) {
      setMountedComplete(complete);
    }

    if (incomplete) {
      setMountedIncomplete(incomplete);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompleteTask = task => {
    dispatch(showWrapup({ task }));
  };

  const handleAddTask = () => {
    dispatch(showDrawer({ drawerType: 'taskForm' }));
  };

  const generateTabUrl = (due, activityType, complete, incomplete, assignedTo) => {
    const params = { due, activityType, complete, incomplete, assignedTo };

    if (TASK_TABS_WITH_DATE_RANGE.includes(due)) {
      if (due === 'custom') {
        params.start =
          lastSearchedDate?.dateFrom || format(parseISODate(getDateFromToday(dateFrom)), DATE_FORMATS.ISO_DATE);
        params.end = lastSearchedDate?.dateTo || format(parseISODate(getDateFromToday(dateTo)), DATE_FORMATS.ISO_DATE);
      } else {
        params.start = format(
          parseISODate(getDateFromToday(DATE_RANGES_FROM_NOW[due].dateFrom)),
          DATE_FORMATS.ISO_DATE
        );
        params.end = format(parseISODate(getDateFromToday(DATE_RANGES_FROM_NOW[due].dateTo)), DATE_FORMATS.ISO_DATE);
      }
    }

    return generateCleanParamsStr(params);
  };

  const selectedTaskTypeId = getParamFromSearch(search, 'activityType');
  // OR condition is for when incomplete is selected by default and not present in the URL
  // const selectedIncomplete = getParamFromSearch(search, 'incomplete') || incompleteIsActive;
  const selectedTaskType = TASK_TYPES_MAP.get(parseInt(selectedTaskTypeId));

  // the details are open if the pathname is NOT one of these urls
  const detailsOpen = !['/tasks', '/tasks/important-dates'].includes(location.pathname);

  const todayIconLabel = new Date().getDate().toString();

  const countType = selectedTaskType ? `${selectedTaskType}s` : 'all';

  const hasData = !!getTasksMatchingDuePeriod(groups[currentGroup], entities, due)?.length;

  return (
    <View isDetailsOpen={detailsOpen}>
      <ViewHeader title="Tasks" titleAs="h1" titleIcon="tasks">
        <ButtonGroup>
          <Suspense fallback={<Loading loading={true} />}>
            <PrintButton
              assignedTo={mountedAssignTo}
              data={{ currentGroup, entities, groups }}
              hasData={hasData}
              location={location}
              title="Tasks"
              variant="tasks"
            />
          </Suspense>
          <Button
            ariaLabel="Add task"
            data-cy="addTaskButton"
            icon="addtask"
            label="Add Task"
            onClick={handleAddTask}
            styleType="primary"
          />
        </ButtonGroup>
      </ViewHeader>
      <TabContainer>
        <TabGroup>
          <Tab
            id="overdue"
            url={generateTabUrl('overdue', selectedTaskTypeId, false, mountedInComplete, mountedAssignTo)}
            label="Overdue"
            matchParams={{ due: 'overdue' }}
            isLoading={isLoading}
            count={counts?.overdue?.[countType] || null}
          />
          <Tab
            id="today"
            url={`/tasks${generateTabUrl(
              null,
              selectedTaskTypeId,
              mountedComplete,
              mountedInComplete,
              mountedAssignTo
            )}`}
            label="Today"
            icon="calendar"
            iconLabel={todayIconLabel}
            isSelected={due === 'today'}
            isLoading={isLoading}
            count={counts?.today?.[countType] || null}
          />
          <Tab
            id="tomorrow"
            url={generateTabUrl('tomorrow', selectedTaskTypeId, mountedComplete, mountedInComplete, mountedAssignTo)}
            label="Tomorrow"
            matchParams={{ due: 'tomorrow' }}
            isLoading={isLoading}
            count={counts?.tomorrow?.[countType] || null}
          />
          <Tab
            id="future"
            url={generateTabUrl('future', selectedTaskTypeId, mountedComplete, mountedInComplete, mountedAssignTo)}
            label="Next 7 Days"
            matchParams={{ due: 'future' }}
            isLoading={isLoading}
            count={counts?.future?.[countType] || null}
          />
          <Tab
            id="customDateRange"
            url={generateTabUrl('custom', selectedTaskTypeId, mountedComplete, mountedInComplete, mountedAssignTo)}
            icon="calendardate"
            matchParams={{ due: 'custom' }}
            label="Custom Date Range"
            isLoading={isLoading}
            count={counts?.custom?.[countType] || null}
          />
          <Tab
            id="importantDates"
            url="/tasks/important-dates"
            icon="importantdates"
            label="Important Dates"
            isLoading={isLoadingImportantDates}
          />
        </TabGroup>
      </TabContainer>
      {!isTasksImportantDates && ( // We don't render this when we click the important dates tab.
        <TaskList
          assignedTo={mountedAssignTo}
          category={TASK_CATEGORIES.all}
          handleCompleteTask={handleCompleteTask}
          location={location}
          headerColumns={COLUMN_DATA}
          headerClassName={styles.headerGrid}
        />
      )}
      <Fragment>{children}</Fragment>
    </View>
  );
};

export default Tasks;
