import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { array, bool, func, object, string, number } from 'prop-types';
import composeRefs from '@seznam/compose-react-refs';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import { deepEqual } from 'fast-equals';
import classnames from 'classnames';

import { clearAlert, showAlert } from '../../actions/alert';
import { filterContactGroupByPhone, getContacts } from '../../actions/contacts';
import { showMessage } from '../../actions/message';
import { FormTextInput, Icon, NoResultsItem, Tag, TagGroup } from '../';
import { deleteFromCollection } from '../../utils';
import { UNSUBSCRIBE_ALERT_DEFAULTS } from '../../utils/communication';
import { ENTITY_TYPES } from '../../utils/notes';

import styles from './Lookup.css';
import dropdownStyles from '../Dropdown/Dropdown.css';
import searchStyles from '../Search/Search.css';
import { filterContactGroupByEmail } from '../../actions/contacts';

const MENU_MAX_HEIGHT = 212;

export class Lookup extends Component {
  constructor(props) {
    super(props);

    this.lookupRef = React.createRef();

    this.state = this.getInitialState();
  }

  static defaultProps = {
    isMultiSelect: true,
    isFilteredByEmail: false,
    isFilteredByPhone: false,
    type: ENTITY_TYPES.contact
  };

  getInitialState = () => {
    const { entities } = this.props;
    return {
      isMeasurable: false, // we need to measure the position of the input, but need to wait until the field has been interacted with.
      menuBottomPos: 'auto',
      search: '',
      selectedEntities: !entities
        ? {}
        : entities.reduce((acc, entity) => {
            acc[entity.id] = entity;

            return acc;
          }, {})
    };
  };

  handleChange = e => {
    const { value: searchTerm } = e.target;
    const { isMeasurable } = this.state;

    const stateToChange = {
      search: searchTerm
    };

    if (!isMeasurable) {
      stateToChange.isMeasurable = true;
    }

    this.setState({
      ...stateToChange
    });

    this.suggestContacts(searchTerm);
  };

  suggestContacts = debounce(q => {
    const { getContacts } = this.props;

    if (!q || q.length === 0) {
      // We don't want to make a request when q is empty.
      return;
    }
    getContacts({ q, setCurrentGroup: false });
  }, 250);

  handleMenuClick = id => {
    const { isMultiSelect, type } = this.props;
    const { selectedEntities } = this.state;

    if (id && !selectedEntities[id]) {
      this.setState({
        search: '',
        selectedEntities: isMultiSelect
          ? {
              ...selectedEntities,
              [id]: {
                id,
                type
              }
            }
          : {
              [id]: {
                id,
                type
              }
            }
      });
    }
  };

  handleTagDelete = e => {
    const { selectedEntities } = this.state;

    this.setState({
      selectedEntities: deleteFromCollection(selectedEntities, e.target.dataset.id)
    });
  };

  handleClear = () => {
    this.setState({
      selectedEntities: {}
    });
  };

  checkIfUnsubscribed = contactId => {
    const { changeHandler, clearAlert, contacts, showAlert } = this.props;
    const { selectedEntities } = this.state;

    const selectedContact = contacts[contactId];
    const { is_unsubscribed } = selectedContact || {};

    if (is_unsubscribed) {
      showAlert({
        ...UNSUBSCRIBE_ALERT_DEFAULTS,
        primaryButtonHandler: () => {
          clearAlert();
          changeHandler(selectedEntities);
        },
        secondaryButtonHandler: () => {
          clearAlert();
          this.handleClear();
        }
      });

      return true;
    }

    return false;
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      changeHandler,
      contacts,
      entities,
      formIsSubmitting,
      isFilteredByEmail,
      isMultiSelect,
      showMessage,
      isFilteredByPhone
    } = this.props;
    const {
      entities: prevEntities,
      formIsSubmitting: prevFormIsSubmitting,
      isFilteredByEmail: prevIsFilteredByEmail,
      isFilteredByPhone: prevIsFilteredByPhone
    } = prevProps;
    const { isMeasurable, selectedEntities } = this.state;
    const { isMeasurable: prevIsMeasurable, selectedEntities: prevSelectedEntities } = prevState;

    if (!deepEqual(selectedEntities, prevSelectedEntities)) {
      // We check if we are doing an email dependent action, it is single select.
      const shouldCheckUnsubscribed = isFilteredByEmail && !isMultiSelect && Object.keys(selectedEntities).length === 1;
      if (shouldCheckUnsubscribed) {
        const selectedContactId = Object.keys(selectedEntities)[0];
        const isUnsubscribed = this.checkIfUnsubscribed(selectedContactId);

        if (isUnsubscribed) {
          return;
        }
      }
      changeHandler(selectedEntities);

      if (this.lookupRef.current) {
        // Focus on deletion of a tag only.
        this.lookupRef.current.focus();
      }
    }

    const firstSelectedContactId = Object.keys(selectedEntities)[0];
    if (isFilteredByEmail && isFilteredByEmail !== prevIsFilteredByEmail) {
      if (firstSelectedContactId) {
        // We need to check for more than one contact when switching to an email task from another task type.
        if (Object.keys(selectedEntities).length > 1) {
          const newSelectedEntities = { [firstSelectedContactId]: selectedEntities[firstSelectedContactId] };
          this.setState({
            selectedEntities: newSelectedEntities
          });
          showMessage(
            { message: 'Contacts attached to this email task have been reduced to one.', type: 'error' },
            true
          );
        }

        // We also need to check if the contact is unsubscribed.
        this.checkIfUnsubscribed(firstSelectedContactId);

        const noEmail = contacts?.[firstSelectedContactId]?.emails?.length === 0; // Check if contact has an email address.
        if (noEmail) {
          showMessage(
            { message: 'The contact associated to this task does not have an email address.', type: 'error' },
            false
          );
        }
      }
    } else if (isFilteredByPhone && isFilteredByPhone !== prevIsFilteredByPhone) {
      const noPhone = contacts?.[firstSelectedContactId]?.phones?.length === 0; // Check if contact has a phone.
      if (noPhone) {
        showMessage(
          { message: 'The contact associated to this task does not have a phone number.', type: 'error' },
          false
        );
      }
    }

    if (this.lookupRef.current && isMeasurable !== prevIsMeasurable) {
      const lookupRect = this.lookupRef.current.getBoundingClientRect();
      const { bottom, height } = lookupRect;
      const distanceFromBottom = window.innerHeight - bottom;

      if (distanceFromBottom < MENU_MAX_HEIGHT) {
        // We rely on a const for the menu max height, as the real height is dynamic and we don't want to wait for render.
        this.setState({ menuBottomPos: `${height}px` });
      }
    }

    if (
      (prevFormIsSubmitting && !formIsSubmitting) ||
      entities?.[0]?.id !== prevEntities?.[0]?.id ||
      entities?.length !== prevEntities?.length
    ) {
      // the parent form was submitted
      this.setState(this.getInitialState());
    }
  }

  render() {
    const {
      contacts,
      dataCy,
      disabled = false,
      externalRef,
      fieldIsValid,
      formIsValid,
      groups,
      isMultiSelect,
      isFilteredByEmail,
      isFilteredByPhone,
      required,
      entities
    } = this.props;

    const { menuBottomPos, search, selectedEntities } = this.state;

    const hasSelectedEntities = selectedEntities && Object.keys(selectedEntities).length > 0;
    const showLookupField = isMultiSelect || (!isMultiSelect && !hasSelectedEntities);

    // When finding the group, the search must be cleaned similar to the q param in getContactGroupFromParams.
    const cleanSearch = search.toLowerCase().replace(/\s/g, '');
    const group = groups[`${cleanSearch}::name::0`];

    const cleanGroup = isFilteredByEmail
      ? filterContactGroupByEmail(group, contacts)
      : isFilteredByPhone
      ? filterContactGroupByPhone(group, contacts)
      : group;
    const list = search && cleanGroup;

    const noResultsFound = !!list && list.length === 0;
    const showInvalid = formIsValid === false && fieldIsValid === false;

    const menuClasses = classnames({
      [dropdownStyles.menu]: true,
      [dropdownStyles.menuNoResults]: noResultsFound
    });

    return (
      <Fragment>
        {hasSelectedEntities && (
          <TagGroup className={styles.tagGroup}>
            {Object.keys(selectedEntities).map(id => {
              // Task linked contact entities won't have type
              if (selectedEntities[id].type && selectedEntities[id].type !== ENTITY_TYPES.contact) {
                // do not show the tag if not of type contact tp display in the Contacts lookup
                return;
              }

              const entity = contacts[id] || {};
              const nameFromEntity = entities && entities.find(item => item.id === id)?.name;
              return (
                <Tag
                  key={id}
                  entityId={id}
                  handleRemove={this.handleTagDelete}
                  label={entity?.fullName || entity?.name || nameFromEntity} // add entity.description to support tasks
                  removable={!disabled}
                  size="l"
                />
              );
            })}
          </TagGroup>
        )}
        {showLookupField && (
          <Downshift inputValue={search} onSelect={this.handleMenuClick}>
            {({ getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex }) => {
              return (
                <div className={styles.field}>
                  <FormTextInput
                    data-cy={dataCy}
                    aria-required={required}
                    aria-invalid={fieldIsValid === false}
                    className={styles.searchInput}
                    id="contactSearchInput"
                    placeholder="Search to add..."
                    ref={composeRefs(this.lookupRef, externalRef)}
                    showInvalid={showInvalid}
                    required={required}
                    type="search"
                    {...getInputProps({
                      onChange: this.handleChange
                    })}
                  />
                  <Icon name="search" size="s" className={searchStyles.icon} />
                  {isOpen && list && (
                    <ul {...getMenuProps()} className={menuClasses} style={{ bottom: menuBottomPos }}>
                      {noResultsFound ? (
                        <NoResultsItem />
                      ) : (
                        list.map((item, index) => {
                          const contact = contacts[item];
                          const { id, fullNameFirstNameFirst } = contact;

                          const isSelected = selectedEntities[item];

                          const classes = classnames({
                            [dropdownStyles.item]: true,
                            [dropdownStyles.hovered]: highlightedIndex === index,
                            [styles.selected]: isSelected
                          });

                          return (
                            <li
                              className={classes}
                              {...getItemProps({
                                key: id,
                                index,
                                item
                              })}
                            >
                              {fullNameFirstNameFirst} {isSelected && <Icon name="check" size="s" isColored={true} />}
                            </li>
                          );
                        })
                      )}
                    </ul>
                  )}
                </div>
              );
            }}
          </Downshift>
        )}
      </Fragment>
    );
  }
}

Lookup.propTypes = {
  changeHandler: func,
  className: string,
  disabled: bool,
  entities: array,
  externalRef: object,
  fieldIsValid: bool,
  formIsSubmitting: bool,
  formIsValid: bool,
  isMultiSelect: bool,
  required: bool,
  type: number,
  isFilteredByEmail: bool,
  isFilteredByPhone: bool
};

const mapStateToProps = state => ({
  contacts: state.contacts.entities,
  groups: state.contacts.groups
});

const mapDispatchToProps = {
  clearAlert,
  getContacts,
  showAlert,
  showMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(Lookup);
