import React from 'react';
import { arrayOf, node, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import { HelpButton } from '../Button/HelpButton';
import { Icon } from '../Icon';

import styles from './ViewHeader.css';

const Title = ({ as = 'h2', children, helpKey, titleIcon, size, ...props }) => {
  const elProps = {
    ...props,
    className: styles[as]
  };

  if (!children) {
    return null;
  }

  const titleChildren =
    titleIcon && as !== 'h3'
      ? [
          <Icon key={`${as}-${titleIcon}`} name={titleIcon} isColored={true} size={size} className={styles.icon} />,
          ...children,
          helpKey && <HelpButton key={helpKey} helpKey={helpKey} className={styles.helpButton} />
        ]
      : children;

  return React.createElement(as, elProps, titleChildren);
};

export const ViewHeader = props => {
  const { children, className, helpKey, title, titleAs = 'h2', titleIcon, titleIconSize = 'm' } = props;

  if (!title && !children) {
    return null;
  }

  const classes = classnames({
    [styles.header]: !className,
    [styles[`header--${titleAs}`]]: true,
    [className]: !!className
  });

  return (
    <div className={classes}>
      <Title as={titleAs} titleIcon={titleIcon} helpKey={helpKey} size={titleIconSize}>
        {title}
      </Title>
      {children}
    </div>
  );
};

ViewHeader.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  className: string,
  helpKey: string,
  title: string,
  titleAs: string,
  titleIcon: string
};
