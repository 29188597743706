import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogHeader, Loading } from '../';
const WrapupForm = lazy(() => import('../../components/WrapupForm/WrapupForm'));
import { TASK_TYPES, TASK_TYPES_MAP } from '../../utils';

export const WrapupDialog = props => {
  const { task, contactId, contactStatus, importantDate, isOpen, clearHandler } = props || {};
  const { activityId, assignedToId, assignedTo } = task || {};
  const { id: reminderId, description: reminderDescription } = importantDate || {};

  const wrapupType =
    (task && TASK_TYPES[TASK_TYPES_MAP.get(task.activityType)].label.toLowerCase()) ||
    (importantDate && importantDate.description.toLowerCase()) ||
    'task';

  return (
    <Dialog isOpen={isOpen}>
      <DialogHeader title={`Wrap-up ${wrapupType}`} icon="wrapup" clearHandler={clearHandler} />
      <Suspense fallback={<Loading />}>
        <WrapupForm
          activityId={activityId}
          contactId={contactId}
          contactStatus={contactStatus}
          reminderId={reminderId}
          reminderDescription={reminderDescription}
          assignedTo={assignedTo}
          assignedToId={assignedToId}
          task={task}
        />
      </Suspense>
    </Dialog>
  );
};

WrapupDialog.propTypes = {
  activity: PropTypes.string,
  contactId: PropTypes.string,
  contactStatus: PropTypes.number,
  isOpen: PropTypes.bool,
  className: PropTypes.string
};
