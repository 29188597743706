import classnames from 'classnames';
import { string } from 'prop-types';
import React from 'react';
import { TextCard } from '../Card';
import { List } from '../List';
import textsListStyles from '../List/TextsList.css';
import styles from './AIConvo.css';

export const AIConvo = props => {
  const { messages, isLoading } = props;

  return (
    <div className={styles.listContainer}>
      <List noResultsMessage="No text messages." className={textsListStyles.messageList} isVirtuoso={false}>
        {messages.map((text, textIndex) => {
          const { role, content } = text || {};
          if (role === 'system') {
            return null;
          }
          const shouldTypeEffect = textIndex === messages.length - 1 && role === 'assistant';

          const data = { actor: role, message: content };
          const listItemClasses = classnames({
            [textsListStyles.listItem]: true,
            [textsListStyles[`${role}ListItem`]]: true
          });

          return (
            <div key={`text${textIndex}`} className={listItemClasses}>
              <TextCard data={data} shouldTypeEffect={shouldTypeEffect} isLoading={isLoading} isAIMessage={true} />
            </div>
          );
        })}
      </List>
    </div>
  );
};

AIConvo.propTypes = {
  className: string
};
