/** @module */

import { getMergedEntities, getMergedSetOfIds } from '../utils';
import { deleteAllFromAllGroups, deleteFromCollection } from '../utils/collections';

export const GET_RELATIONSHIPS = 'GET_RELATIONSHIPS';
export const GET_RELATIONSHIPS_SUCCESS = 'GET_RELATIONSHIPS_SUCCESS';
export const GET_RELATIONSHIPS_FAIL = 'GET_RELATIONSHIPS_FAIL';
export const GET_RELATIONSHIP_TYPES = 'GET_RELATIONSHIP_TYPES';
export const GET_RELATIONSHIP_TYPES_SUCCESS = 'GET_RELATIONSHIP_TYPES_SUCCESS';
export const GET_RELATIONSHIP_TYPES_FAIL = 'GET_RELATIONSHIP_TYPES_FAIL';
export const SET_RELATIONSHIPS_DIALOG = 'SET_RELATIONSHIPS_DIALOG';
export const ADD_RELATIONSHIP = 'ADD_RELATIONSHIP';
export const ADD_RELATIONSHIP_SUCCESS = 'ADD_RELATIONSHIP_SUCCESS';
export const ADD_RELATIONSHIP_FAIL = 'ADD_RELATIONSHIP_FAIL';
export const DELETE_RELATIONSHIP_SUCCESS = 'DELETE_RELATIONSHIP_SUCCESS';
export const DELETE_RELATIONSHIP_FAIL = 'DELETE_RELATIONSHIP_FAIL';
export const CLEAR_RELATED_CONTACT_ID = 'CLEAR_RELATED_CONTACT_ID';

export const initialState = {
  entities: {},
  groups: {},
  isLoading: false,
  formIsShowing: false,
  relationshipFormData: null,
  relationshipTypes: []
};

/**
 * The preferences reducer redux reducer.
 * @param {Object} state - the current state of the user store.
 * @param {Object} action - the action to take on the user store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const relationshipsReducer = (state = initialState, action = {}) => {
  const {
    formIsShowing,
    group,
    relatedContactId,
    relationshipId,
    relationships,
    relationshipFormData,
    relationshipTypes
  } = action;
  switch (action.type) {
    case ADD_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        formIsShowing: false
      };
    case CLEAR_RELATED_CONTACT_ID:
      return {
        ...state,
        groups: {
          [relatedContactId]: []
        }
      };
    case ADD_RELATIONSHIP:
    case GET_RELATIONSHIPS:
    case GET_RELATIONSHIP_TYPES:
      return {
        ...state,
        isLoading: true
      };
    case GET_RELATIONSHIPS_SUCCESS:
      const mergedEntities = getMergedEntities(state.entities, relationships, {
        idKey: 'relationshipId',
        ignorePreexisting: false
      });
      return {
        ...state,
        isLoading: false,
        entities: mergedEntities,
        groups: {
          ...state.groups,
          [group]: getMergedSetOfIds(state.groups[group], relationships, {
            entities: mergedEntities,
            idKey: 'relationshipId'
          })
        }
      };
    case GET_RELATIONSHIP_TYPES_SUCCESS:
      return {
        ...state,
        relationshipTypes
      };
    case ADD_RELATIONSHIP_FAIL:
    case GET_RELATIONSHIP_TYPES_FAIL:
    case GET_RELATIONSHIPS_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case DELETE_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, relationshipId)
        },
        // groups: deleteFromAllGroups(state.groups, relationshipId)
        groups: deleteAllFromAllGroups(state.groups, [relationshipId])
      };
    case SET_RELATIONSHIPS_DIALOG:
      return {
        ...state,
        formIsShowing,
        relationshipFormData
      };
    default:
      return state;
  }
};
