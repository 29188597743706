/** @module */

import { showMessage } from '../actions/message';
import {
  CLEAR_TEMP_LISTINGS,
  GET_LISTINGS_FAIL,
  GET_LISTINGS_LOADING,
  GET_LISTINGS_SUCCESS,
  PROPERTY_INSIGHTS_CREATE_SUCCESS,
  PROPERTY_INSIGHTS_DELETE,
  PROPERTY_INSIGHTS_FORM_TOGGLE,
  PROPERTY_INSIGHTS_LOADING,
  PROPERTY_INSIGHTS_SET,
  PROPERTY_INSIGHTS_UPDATE,
  PROPERTY_INSIGHTS_TRANSACTION_FORM_TOGGLE,
  PROPERTY_INSIGHTS_FAIL
} from '../reducers/propertyInsights';
import { request, requestError } from '../utils';
import { getArrayOfEntities, sortArrayOfObjects } from '../utils/collections';

/**
 * Fetches Property Insights
 */
export const getPropertyInsights = (contactId, options) => {
  const { forceRefresh = false } = options || {};

  const requestOptions = {
    apiServiceType: 'contacts',
    baseUrlKey: 'api',
    forceRefresh,
    path: `${contactId}/propertyinsights`
  };

  return async dispatch => {
    dispatch({
      type: PROPERTY_INSIGHTS_LOADING,
      currentGroup: contactId
    });

    try {
      const response = await request(requestOptions);
      if (response.status === 200) {
        const propertyInsightRecords = response.data;

        dispatch({
          type: PROPERTY_INSIGHTS_SET,
          propertyInsightRecords,
          group: contactId,
          currentGroup: contactId
        });
      }
    } catch (error) {
      dispatch({
        type: PROPERTY_INSIGHTS_FAIL
      });
      requestError(error, dispatch);
    }
  };
};

/**
 * Toggles Property Insight Form Display
 */
export const togglePropertyInsightsForm = () => {
  return async dispatch => {
    dispatch({
      type: PROPERTY_INSIGHTS_FORM_TOGGLE
    });
  };
};

/**
 * Toggles Add Transaction Form Display
 */
export const togglePropertyInsightsTransactionForm = (insightId, transactionPartyContactId) => {
  return async dispatch => {
    dispatch({
      type: PROPERTY_INSIGHTS_TRANSACTION_FORM_TOGGLE,
      transactionPartyContactId,
      insightId
    });
  };
};

/**
 * Toggles Property Insight Form Display
 */
export const savePropertyInsight = (
  contactId,
  { property_id, listing_id, insight_status_id, mls_number, address_line, mls_source_id }
) => {
  const options = {
    apiServiceType: 'contacts',
    baseUrlKey: 'api',
    method: 'POST',
    path: `${contactId}/propertyinsights`,
    payload: {
      property_id,
      listing_id,
      insight_status_id,
      mls_number,
      address_line,
      mls_source_id
    },
    shouldBeCached: false
  };
  return async dispatch => {
    dispatch({
      type: PROPERTY_INSIGHTS_LOADING
    });

    try {
      const response = await request(options);
      if (response.status === 200) {
        const propertyInsightRecords = response.data;

        dispatch({
          type: PROPERTY_INSIGHTS_CREATE_SUCCESS,
          propertyInsightRecords,
          group: { [contactId]: propertyInsightRecords.map(obj => obj.insight_id) },
          currentGroup: contactId
        });

        dispatch(togglePropertyInsightsForm());
      }
    } catch (error) {
      dispatch({
        type: PROPERTY_INSIGHTS_FAIL
      });
      requestError(error, dispatch);
    }
  };
};

/**
 * Request for getting property listings by property id and source
 */
export const getPropertyById = ({ source, propertyId, mlsNumber }) => {
  const options = {
    baseUrlKey: 'api',
    path: 'mls/listings',
    params: propertyId
      ? {
          source,
          propertyId
        }
      : {
          source,
          mlsNumber
        },
    shouldBeCached: false
  };
  return async dispatch => {
    dispatch({
      type: GET_LISTINGS_LOADING
    });
    try {
      const response = await request(options);

      if (response.status === 200) {
        // Some addresses return multiple properties, so we sort them asc, and get the last one (most recent).
        const sortedResults = sortArrayOfObjects(
          getArrayOfEntities(response.data.results).map(entity => {
            return {
              ...entity,
              sortableContractDate: entity.source.contract_date // we creat this additional key for sorting, as our sorter doesn't support nested objects.
            };
          }),
          {
            sortKey: 'sortableContractDate',
            sortType: 'alpha'
          }
        );

        const listings = sortedResults.length === 0 ? sortedResults : [sortedResults.pop()];

        dispatch({
          type: GET_LISTINGS_SUCCESS,
          tempListings: listings,
          showNoTempListingsError: response.data.count === 0
        });

        return true;
      } else {
        dispatch({
          type: GET_LISTINGS_FAIL
        });

        return false;
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const clearTempListings = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_TEMP_LISTINGS
    });
  };
};

/**
 * Updates Property Insight
 */
export const updatePropertyInsight = (
  contactId,
  { insight_id, mls_number, address_line, insight_status_id, mls_source_id }
) => {
  const options = {
    apiServiceType: 'contacts',
    baseUrlKey: 'api',
    method: 'PUT',
    path: `${contactId}/propertyinsights/${insight_id}`,
    payload: {
      insight_id,
      mls_number,
      address_line,
      insight_status_id,
      mls_source_id
    },
    shouldBeCached: false
  };

  return async dispatch => {
    try {
      const response = await request(options);
      if (response.status === 204) {
        dispatch({
          type: PROPERTY_INSIGHTS_UPDATE,
          insight_id,
          insight_status_id
        });
        dispatch(showMessage({ message: 'Interest level saved successfully.', type: 'success' }, true)); // useTimer set to true

        return true;
      }

      return false;
    } catch (error) {
      dispatch({
        type: PROPERTY_INSIGHTS_FAIL
      });
      requestError(error, dispatch);
    }
  };
};

/**
 * Deletes Property Insight
 */
export const deletePropertyInsight = (contactId, insight_id) => {
  const options = {
    apiServiceType: 'contacts',
    baseUrlKey: 'api',
    method: 'DELETE',
    path: `${contactId}/propertyinsights/${insight_id}`,
    shouldBeCached: false
  };
  return async dispatch => {
    try {
      const response = await request(options);
      if (response.status === 204) {
        dispatch({
          type: PROPERTY_INSIGHTS_DELETE,
          insight_id,
          currentGroup: contactId
        });

        return true;
      }

      return false;
    } catch (error) {
      dispatch({
        type: PROPERTY_INSIGHTS_FAIL
      });
      requestError(error, dispatch);
    }
  };
};

/**
 * Fetch Property Insights by insight id only, used to redirect from notes page to contact details
 */
export const getPropertyInsightsByInsightId = (insightId, options) => {
  const { forceRefresh = false } = options || {};

  const requestOptions = {
    baseUrlKey: 'api',
    forceRefresh,
    path: `propertyinsights/${insightId}`
  };

  return async dispatch => {
    dispatch({
      type: PROPERTY_INSIGHTS_LOADING
    });

    try {
      const response = await request(requestOptions);

      if (response.status === 200) {
        const { insight_contact_id: contactId } = response?.data || {};
        const propertyInsightRecords = [response.data];

        dispatch({
          type: PROPERTY_INSIGHTS_SET,
          propertyInsightRecords,
          group: contactId,
          currentGroup: contactId
        });

        return contactId;
      }
    } catch (error) {
      dispatch({
        type: PROPERTY_INSIGHTS_FAIL
      });
      requestError(error, dispatch);
    }
  };
};
