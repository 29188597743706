import { format, subDays } from 'date-fns';
import { string } from 'prop-types';
import React, { Suspense, useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import '!style-loader!css-loader!react-grid-layout/css/styles.css';
import '!style-loader!css-loader!react-resizable/css/styles.css';

import classnames from 'classnames';

import { Button, ButtonGroup, Loading } from '../../components';
import { View } from '../../components/View';
import { ViewBody } from '../../components/ViewBody';
import { ViewHeader } from '../../components/ViewHeader';

import { useDispatch, useSelector } from 'react-redux';
import { getContacts, getCounts } from '../../actions/contacts';
import { FollowUp } from './FollowUp';
import { TaskTile } from './TaskTile';

import styles from './Dashboard.css';

import { getMSLSCounts } from '../../actions/snapshot';
import { DATE_FORMATS } from '../../constants';
import { Details } from '../Details';
import { Commissions } from './Commissions';
import { Listings } from './Listings/Listings';
import { MarketSnapshotStats } from './MarketSnapshotStats';
import { NewContacts } from './NewContacts';
import { RecentActivity } from './RecentActivity/RecentActivity';
import { SalesPipelineStats } from './SalesPipelineStats';
import { shouldNewUserBePrompted } from '../../utils/authentication';
import { clearAlert, showAlert } from '../../actions';
import { navigate } from '@reach/router';
import { getDefaultLayout, getMinMaxForLayout } from '../../utils/dashboard';
import { getDashboardPreferences, saveDashboardPreferences } from '../../actions/dashboard';
import { getHomeIntelPreferences } from '../../actions/homeintel';

const Tile = props => {
  const { id, title, content, isEditMode } = props;
  const classes = classnames({
    [styles.tile]: !isEditMode,
    [styles.editTile]: isEditMode
  });

  return (
    <div id={`dashboard-${id}`} data-cy={`dashboard-${id}`} className={classes}>
      <label className={styles.title}>{title}</label>

      <div className={styles.content}>{content}</div>
    </div>
  );
};

const alertAction = (url, dispatch) => {
  dispatch(clearAlert());
  navigate(url);
};

// HOC that determines width on initializtion
const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname } = location;

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const { userId, isAssistant, assistantDefaultAgentId } = useSelector(store => store.user);
  const { hasContacts, statusCounts } = useSelector(store => store.contacts);
  const { msAccountDetails } = useSelector(store => store.snapshot);
  const hasRetrievedHomeIntelSettings = useSelector(store => store.homeIntel.hasRetrievedSettings);

  const { userInfo } = useSelector(store => store.userProfile);
  const { displayFirstName, firstName, hasHomeIntelLicense } = userInfo || {};

  const { layouts } = useSelector(store => store.dashboard);

  const [msStats, setMsStats] = useState(null);
  const hasSnapshot = !!msAccountDetails?.isAgentActive;

  const currentUserId = !isAssistant ? userId : assistantDefaultAgentId;
  const [isEditMode, setIsEditMode] = useState(false);
  const [canShowHomeIntelPopup, setCanShowHomeIntelPopup] = useState(false);

  useEffect(() => {
    if (canShowHomeIntelPopup) {
      if (hasHomeIntelLicense && !hasRetrievedHomeIntelSettings) {
        dispatch(getHomeIntelPreferences()).then(homeIntelSettings => {
          if (!homeIntelSettings?.settings) {
            dispatch(
              showAlert({
                isOpen: true,
                icon: 'homeintelsettings',
                iconSize: 'm',
                loading: false,
                message: 'Welcome to Top Producer X! You can now leverage the new HomeIntelle feature!',
                hint: 'Get started by configuring your HomeIntelle settings.',
                primaryButtonLabel: 'Go To HomeIntelle Settings',
                primaryButtonHandler: () => alertAction('/settings/homeintelle-settings', dispatch),
                secondaryButtonLabel: 'Cancel',
                secondaryButtonHandler: () => dispatch(clearAlert())
              })
            );
          }
        });
      }
    }
  }, [dispatch, hasHomeIntelLicense, canShowHomeIntelPopup, hasRetrievedHomeIntelSettings]);

  useEffect(() => {
    const promptNewUser = shouldNewUserBePrompted(hasContacts, pathname, isAssistant, statusCounts);
    if (promptNewUser) {
      // There are exactly zero contacts in the status counts and the all count has loaded.
      dispatch(
        showAlert({
          isOpen: !hasContacts,
          icon: 'addcontact',
          iconSize: 'm',
          loading: false,
          hint: 'Start by adding or importing to your database.',
          message: 'Welcome to Top Producer X! A great CRM starts with your contacts.',
          primaryButtonLabel: 'Add Contact',
          primaryButtonHandler: () => alertAction('/contacts/add', dispatch),
          secondaryButtonLabel: 'Import Contacts',
          secondaryButtonHandler: () => alertAction('/settings/import', dispatch)
        })
      );
    } else if (hasContacts) {
      setCanShowHomeIntelPopup(true);
    }
  }, [dispatch, pathname, hasContacts, statusCounts, isAssistant]);

  useEffect(() => {
    if (pathname.split('/')[2] === 'view') {
      setIsDetailsOpen(true);
      return;
    }
    setIsDetailsOpen(false);
  }, [pathname]);

  useEffect(() => {
    // Followup coach
    dispatch(
      getContacts({
        assignedTo: currentUserId,
        q: 'followup'
      })
    );
    // New contacts
    dispatch(
      getContacts({
        assignedTo: currentUserId,
        forceRefresh: true,
        status: 10,
        setCurrentGroup: false
      })
    );
    // We fetch social connect for it's contact count
    dispatch(
      getContacts({
        assignedTo: currentUserId,
        q: 'social connect',
        forceRefresh: true,
        setCurrentGroup: false
      })
    );
    // Recent activity, chronological order starting from most recent
    dispatch(
      getContacts({
        assignedTo: currentUserId,
        dir: '1',
        sortBy: 'consumer_touch',
        forceRefresh: true,
        setCurrentGroup: false,
        q: 'all'
      })
    );
  }, [dispatch, currentUserId]);

  useEffect(() => {
    if (currentUserId) {
      dispatch(getCounts(currentUserId));
    }

    const currentDate = new Date();

    dispatch(
      getMSLSCounts({
        tpoAgentId: currentUserId,
        startDate: format(subDays(currentDate, 7), DATE_FORMATS.ISO_DATE),
        endDate: format(currentDate, DATE_FORMATS.ISO_DATE)
      })
    )
      .then(data => {
        setMsStats(data);
      })
      .catch(() => {
        setMsStats([]);
      });
  }, [currentUserId, dispatch]);

  const dataConfig = [
    { title: 'Follow-up Coach', content: <FollowUp />, id: 'followupCoach' },
    {
      title: 'Sales Pipeline Statistics',
      content: <SalesPipelineStats userId={currentUserId} />,
      id: 'salesStats'
    },
    { title: 'Tasks', content: <TaskTile />, id: 'tasks' },
    {
      title: 'New Contacts',
      content: <NewContacts isDetailsOpen={isDetailsOpen} userId={currentUserId} />,
      id: 'newContacts'
    },
    ...(hasSnapshot
      ? [{ title: 'Market Snapshot Stats', content: <MarketSnapshotStats data={msStats} />, id: 'msStats' }]
      : []),
    {
      title: 'Recent Contact Activity',
      content: <RecentActivity userId={currentUserId} />,
      id: 'recentActivity'
    },
    { title: 'Listings', content: <Listings />, id: 'listings' },
    { title: 'Commissions', content: <Commissions />, id: 'commissions' }
  ];
  const defaultLayout = layouts || getDefaultLayout(hasSnapshot);

  const [currentLayout, setCurrentLayout] = useState(getMinMaxForLayout(defaultLayout));
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');

  useEffect(() => {
    dispatch(getDashboardPreferences()).then(preferences => {
      const { layouts } = preferences || {};
      if (layouts) {
        setCurrentLayout(getMinMaxForLayout(layouts));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (hasSnapshot) {
      setCurrentLayout(getMinMaxForLayout(defaultLayout));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSnapshot]);

  // react-grid-layout will normalize the config data by adding more properties
  // extract essential properties for comparing and saving.
  const extractLayoutProperties = layoutArray => {
    if (!layoutArray) {
      return null;
    }
    return layoutArray.map(item => {
      const { i, x, y, w, h } = item;
      return { i, x, y, w, h };
    });
  };

  const onLayoutChange = layout => {
    const newLayout = { ...currentLayout, [currentBreakpoint]: layout };
    setCurrentLayout(newLayout);
  };

  const handleBreakpointChange = newBreakpoint => {
    setCurrentBreakpoint(newBreakpoint);
  };

  const saveLayout = () => {
    setIsEditMode(false);
    const newLayout = extractLayoutProperties(currentLayout[currentBreakpoint]);

    dispatch(saveDashboardPreferences({ layouts: { ...defaultLayout, [currentBreakpoint]: newLayout } }, layouts));
  };

  const resetLayout = () => {
    setIsEditMode(false);
    dispatch(saveDashboardPreferences({ layouts: null }, layouts)).then(() => {
      navigate('/');
    });
    dispatch(clearAlert());
  };

  const confirmResetHandler = () => {
    dispatch(
      showAlert({
        message: 'Are you sure you want to reset your dashboard layout to the default configuration?',
        icon: 'settings',
        iconSize: 'm',
        primaryButtonLabel: 'Reset',
        primaryButtonHandler: resetLayout
      })
    );
  };

  return (
    <Suspense fallback={<Loading />}>
      <View isDetailsOpen={isDetailsOpen}>
        <ViewHeader
          title={`Welcome${displayFirstName || firstName ? `, ${displayFirstName || firstName}` : ''}!`}
          titleAs="h1"
        >
          <ButtonGroup>
            {isEditMode && <Button onClick={confirmResetHandler} label="Reset" />}
            <Button
              onClick={!isEditMode ? () => setIsEditMode(!isEditMode) : () => saveLayout()}
              label={`${!isEditMode ? 'Edit' : 'Save'} Layout`}
              ariaLabel={`${!isEditMode ? 'Edit' : 'Save'} Layout`}
              styleType={!isEditMode ? 'white' : 'primary'}
              icon={isEditMode ? null : 'editLayout'}
              labelShort={`${!isEditMode ? 'Edit' : 'Save'}`}
            />
          </ButtonGroup>
        </ViewHeader>
        <ViewBody>
          <ResponsiveGridLayout
            className="layout"
            layouts={currentLayout}
            // Make sure breakpoints are in sync with @custom-media and responsive rules in css
            breakpoints={{ lg: 1133, md: 881, sm: 737, xs: 480 }}
            cols={{ lg: 4, md: 4, sm: 2, xs: 2 }}
            onLayoutChange={onLayoutChange}
            onBreakpointChange={handleBreakpointChange}
            resizeHandles={['se', 'e', 's']}
            isBounded={true}
            autoSize={true}
            isDraggable={isEditMode}
            isResizable={isEditMode}
          >
            {dataConfig.map(item => {
              const { id, title, content } = item;
              return (
                <div key={id}>
                  <Tile id={id} title={title} content={content} isEditMode={isEditMode} />
                </div>
              );
            })}
          </ResponsiveGridLayout>
        </ViewBody>
        <Details>{children}</Details>
      </View>
    </Suspense>
  );
};

Dashboard.propTypes = {
  className: string
};

export default Dashboard;
