import React from 'react';

import { CallToAction } from '../';
import { useSelector } from 'react-redux';

import { postSalesforceSSO } from '../../actions/salesforce';
import {
  CLOSED_MESSAGE,
  CLOSED_MESSAGE_TEAM,
  CLOSED_TITLE,
  SUSPENSION_MESSAGE,
  SUSPENSION_MESSAGE_RA,
  SUSPENSION_MESSAGE_TEAM,
  SUSPENSION_TITLE
} from '../../constants/signin';

export const SignInErrorCallToAction = ({ errorType }) => {
  const { recipient, samlResponse } = useSelector(state => state.salesforce?.salesforceStatus) || {};

  const ERRORS = {
    // Single agent account closed
    60154: {
      type: 'closed',
      title: CLOSED_TITLE,
      message: CLOSED_MESSAGE,
      label: 'Update Payment'
    },
    // Team - Non RA Closed
    60054: {
      type: 'closed',
      title: CLOSED_TITLE,
      message: CLOSED_MESSAGE_TEAM
    },
    106: {
      type: 'suspension',
      title: SUSPENSION_TITLE,
      message: SUSPENSION_MESSAGE_TEAM
    },
    // Single agent account suspended
    67053: {
      type: 'suspension',
      title: SUSPENSION_TITLE,
      message: SUSPENSION_MESSAGE_TEAM
    },
    // Single agent account suspended
    67153: {
      type: 'suspension',
      title: SUSPENSION_TITLE,
      message: SUSPENSION_MESSAGE,
      label: 'Update Payment'
    },
    // Team account suspended
    60053: {
      type: 'suspension',
      title: SUSPENSION_TITLE,
      message: SUSPENSION_MESSAGE_RA
    }
  };
  if (!ERRORS[errorType]) {
    return null;
  }

  const handleRedirect = e => {
    e.preventDefault();
    postSalesforceSSO({ recipient, samlResponse });
  };

  return (
    ERRORS[errorType] && (
      <CallToAction
        icon="plan"
        isNested={true}
        title={ERRORS[errorType].title}
        ariaLabel={ERRORS[errorType].message}
        message={ERRORS[errorType].message}
        variant="default"
        clickHandler={recipient ? handleRedirect : null}
        buttonLabel={ERRORS[errorType]?.label}
      />
    )
  );
};
