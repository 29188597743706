import format from 'date-fns/format';
import { DATE_FORMATS } from '../constants';
import { parseISODate } from './dates';

export const TEXT_MESSAGE_MAXLENGTH = 800;
export const TEXT_INVALID_MESSAGE = `Message required and must be less than ${TEXT_MESSAGE_MAXLENGTH} characters`;

export const TEXT_TYPES_MAP = new Map([
  [0, 'all'],
  [1, 'notification'],
  [2, 'inbound'],
  [3, 'outbound']
]);

/**
 * Get urls from texts redux data
 * @param {Object} texts from texts redux store
 */
export const getUrlsFromTexts = texts => {
  return Object.keys(texts).reduce((acc, key) => {
    const { timeStamp, media } = texts[key];
    if (media != null) {
      const time = format(parseISODate(timeStamp), DATE_FORMATS.LONG_DATE_TIME);
      media.map(data => {
        const { ContentType, Url } = data;
        if (ContentType.startsWith('image')) {
          acc.push({
            url: Url,
            timeStamp: time
          });
        }
      });
    }
    return acc;
  }, []);
};
