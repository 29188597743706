import { navigate } from '@reach/router';
import debounce from 'lodash/debounce';
import { string } from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert, showAlert } from '../../actions';
import {
  getTaskDetail,
  getTasks,
  markTaskIncomplete,
  massDeleteTasks,
  massMarkDone,
  saveTask,
  sendNow,
  setCurrentTask
} from '../../actions/tasks';
import { parseParamsStr, serializeToParamsStr, trackEvent } from '../../utils';
import { pluralize } from '../../utils/strings';
import {
  buildTasksGroupName,
  getFetchOptions,
  getPagingOptions,
  getTasksMatchingDuePeriod,
  TASK_CATEGORIES
} from '../../utils/tasks';
import { Button, ButtonGroup } from '../Button';
import { TaskCard } from '../Card';
import { Container } from '../Container';
import { EditTaskDialog } from '../Dialog/EditTaskDialog/EditTaskDialog';
import { Filters } from '../Filters';
import { ListFooter } from '../ListFooter';
import { ListHeader } from '../ListHeader';
import { PreviewTaskDialog } from '../PreviewEmailTaskDialog/PreviewEmailTaskDialog';
import { Tag } from '../Tag';
import { List } from './List';
import listStyles from './List.css';
import { ListPlaceholder } from './ListPlaceholder';
import { FormCheckBox } from '../FormCheckBox';
import { View } from '../View';
import { TaskFilter } from '../../pages/Tasks/TaskFilter';
import { showMessage } from '../../actions/message';
import { addDays, differenceInSeconds } from 'date-fns';
import { resetIgnoreData } from '../../actions/tasks';
import { MASS_ACTION_LIMIT } from '../../constants/tasks';
import isEmpty from 'lodash/isEmpty';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { INFINITE_SCROLL_ROOT_MARGIN } from '../../constants';

/**
 * This function is used inside mapStateToProps to get `currentGroup` and `duePeriod` correctly.
 * If the TaskList is being shown inside a Task Details component (ownProps.taskId is present),
 * then, it will calculate currentGroup and duePeriod based on `getFetchOptions` function;
 * Else: it will get currentGroup and due from current state of the redux store.
 *
 * @see getFetchOptions
 * @param {Object} state Current state of the redux store
 * @param {Object} ownProps props manually passed to TaskList component
 */
const getCurrentGroupAndDuePeriod = (tasks, userId, ownProps) => {
  const options = getFetchOptions(ownProps);

  if (ownProps.isOnDashboard) {
    return { currentGroup: tasks.dashboardGroup, duePeriod: tasks.dashboardDue };
  }

  // When viewing a task list outside task details, we don't want to use the currentGroup that is set in the store.
  // With this check, it's possible to render both lists on the same time
  // - Global Task Lists, and Contact's Task List (nested inside the contact tab)
  const currentGroup = ownProps.taskId
    ? buildTasksGroupName(options, ownProps?.assignedTo ?? userId)
    : tasks.currentGroup;
  const duePeriod = ownProps.taskId ? options.due : tasks.due;

  return { currentGroup, duePeriod };
};

const TaskList = props => {
  const {
    entity,
    assignedTo,
    location,
    handleCompleteTask,
    category = TASK_CATEGORIES.contact,
    headerColumns,
    headerClassName,
    listClassName,
    isVirtuoso,
    isMinimalView = false,
    limitDisplayCount,
    isOnDashboard = false
  } = props || {};

  const isNested = !!entity;

  const dispatch = useDispatch();

  const tasks = useSelector(state => state.tasks);
  const {
    entities: tasksEntities,
    groups,
    currentEntity: currentTask,
    ignoreData,
    isLoading,
    donePaging,
    dashboardDue
  } = tasks;
  const userId = useSelector(state => state.user.userId);
  const { currentGroup, duePeriod } = getCurrentGroupAndDuePeriod(tasks, userId, props);

  const [isShiftDown, setIsShiftDown] = useState(false);
  const [lastSelected, setLastSelected] = useState(null);
  const [displayMultiSelectActions, setDisplayMultiSelectActions] = useState(false);
  const [currentlySelected, setCurrentlySelected] = useState([]);
  const [localSelectAll, setLocalSelectAll] = useState(false);

  const urlParams = parseParamsStr(location.search);

  const isCompleteFlagOn = urlParams?.complete === 'true';

  const currentList = groups?.[currentGroup];
  // We want to clean the currentList, because the next day the local app state could have tasks in the wrong group.
  const cleanList = getTasksMatchingDuePeriod(currentList, tasksEntities, duePeriod);
  // Only show what's not in the ignored list, unless complete is true and last action is mass mark done
  const filteredCurrentlySelected = currentlySelected?.filter(id => {
    if (ignoreData.massDelete.timestamp && ignoreData.massDelete?.list?.includes(id)) {
      return false;
    }
    if (!isCompleteFlagOn && ignoreData.massMarkdone.timestamp && ignoreData.massMarkdone?.list?.includes(id)) {
      return false;
    }
    return true;
  });

  const currentlySelectedCount = filteredCurrentlySelected.length;

  const handleKeyUp = e => {
    if (e.key === 'Shift') {
      setIsShiftDown(false);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Shift') {
      setIsShiftDown(true);
    }
  };

  const createFetchOptions = () => {
    const options = getFetchOptions({ ...props, location, category });

    // In the specific case where the dashboard task list has loaded in the background of a details view,
    // remove dependency on location search parameters
    if (isOnDashboard) {
      return { ...options, due: dashboardDue ?? 'today', start: null, end: null };
    }

    return options;
  };

  useEffect(() => {
    const options = createFetchOptions();

    dispatch(getTasks(options, assignedTo || userId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, category, assignedTo, userId, dashboardDue]);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { massDelete, massMarkdone } = ignoreData;

    if (massDelete?.list?.length && differenceInSeconds(new Date(), addDays(massDelete.markdoneTimestamp, 1)) >= 1) {
      dispatch(resetIgnoreData('massDelete'));
    }
    if (
      massMarkdone?.list?.length &&
      differenceInSeconds(new Date(), addDays(massMarkdone.markdoneTimestamp, 1)) >= 1
    ) {
      dispatch(resetIgnoreData('massMarkdone'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroup]);

  useEffect(() => {
    const showActions = currentlySelectedCount > 0;

    setDisplayMultiSelectActions(showActions);
  }, [currentlySelectedCount]);

  // Keep currentlySelected in sync with current view
  useEffect(() => {
    if (currentlySelectedCount > 0) {
      if (!currentlySelected?.every(i => cleanList?.includes(i))) {
        const newSelectedList = currentlySelected.filter(id => cleanList?.includes(id));

        setCurrentlySelected(newSelectedList);
      }
    }
  }, [cleanList, currentlySelected, currentlySelectedCount]);

  useEffect(() => {
    // Check if all tasks in current list are currently selected
    if (currentlySelectedCount > 0 && cleanList?.every(i => currentlySelected.includes(i))) {
      if (!localSelectAll) {
        setLocalSelectAll(true);
      }
      return;
    }
    if (localSelectAll) {
      setLocalSelectAll(false);
    }
  }, [cleanList, currentlySelected, dispatch, localSelectAll, currentlySelectedCount]);

  const handleWrapup = (e, task) => {
    e.preventDefault();

    const { id, completed, isDone } = task || {};

    // non in plan
    if (isDone || completed) {
      dispatch(markTaskIncomplete({ id }, { currentTasksGroup: currentGroup }));
      return;
    }

    handleCompleteTask(task, currentGroup);
  };

  const handleCardClick = e => {
    const { currentTask } = props || {};

    if (isNested) {
      e.preventDefault();
      // stop the nest note link from firing
    }
    if (currentTask === e.currentTarget.id) {
      dispatch(setCurrentTask({ id: null }));
      return;
    }

    dispatch(setCurrentTask({ id: e.currentTarget.id }));
  };

  const toggleEditMode = (e, id) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const currentParams = parseParamsStr(location.search) || {};

    const newParamsStr = serializeToParamsStr({
      ...currentParams,
      mode: 'edit',
      taskId: id
    });

    navigate(`${location.pathname}?${newParamsStr}`);

    trackEvent('taskLists', 'editOpen');
  };

  const togglePreviewMode = (e, id) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { contacts, contactId } = tasksEntities?.[id] || {};

    const currentParams = parseParamsStr(location.search) || {};

    const newParamsStr = serializeToParamsStr({
      ...currentParams,
      mode: 'preview',
      taskId: id,
      contactId: contactId || contacts?.[0]?.id
    });

    navigate(`${location.pathname}?${newParamsStr}`);

    trackEvent('taskLists', 'previewOpen');
  };

  // Update the task with the new fileId from the one-off template
  const handleTemplateChange = ({ id: templateFileId, taskId }) => {
    const {
      taskTemplate,
      contacts,
      assignedTo,
      assignedToId,
      description,
      descriptionNote,
      endOfEvent,
      id,
      isTemplate,
      startOfEvent,
      activityType
    } = tasksEntities?.[taskId] || {};

    const payload = {
      assignedTo,
      assignedToId,
      description,
      descriptionNote,
      contacts,
      endOfEvent,
      id,
      isTemplate: isTemplate ? '1' : '0',
      startOfEvent,
      taskTemplate: { ...taskTemplate, templateFileId },
      type: activityType
    };
    return dispatch(saveTask(payload));
  };

  const handleSubmitFromPreview = (e, { isEmail, id }) => {
    const { currentGroup } = props;

    dispatch(sendNow(id, currentGroup, isEmail)).then(() => {
      dispatch(getTaskDetail({ id, forceRefresh: true }));
    });
  };

  const noResultsMessage = `No matching tasks.`;

  const onLoadMore = debounce(() => {
    const options = createFetchOptions();
    const paging = getPagingOptions({ currentGroup, groups, tasks: tasksEntities, category });

    if (!Boolean(donePaging[currentGroup]) && !isEmpty(groups[currentGroup])) {
      dispatch(getTasks({ ...options, ...paging }, assignedTo || userId));
    }
  }, 250);

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: donePaging[currentGroup] !== true,
    onLoadMore: () => dispatch(onLoadMore),
    rootMargin: INFINITE_SCROLL_ROOT_MARGIN
  });

  const handleEntitySelect = id => {
    if (isShiftDown && lastSelected) {
      const listClone = [...cleanList];

      const intersection =
        listClone.indexOf(id) > listClone.indexOf(lastSelected)
          ? listClone.slice(listClone.indexOf(lastSelected), listClone.indexOf(id) + 1)
          : listClone.slice(listClone.indexOf(id), listClone.indexOf(lastSelected) + 1);

      if (currentlySelected.includes(id)) {
        setCurrentlySelected(currentlySelected.filter(item => !intersection.includes(item)));
      } else {
        setCurrentlySelected(Array.from(new Set([...currentlySelected, ...intersection])));
      }
    } else {
      if (currentlySelected.includes(id)) {
        setCurrentlySelected(currentlySelected.filter(item => item !== id));
      } else {
        const newSelectedList = Array.from(new Set([...currentlySelected, id]));
        setCurrentlySelected(newSelectedList);
      }
    }

    setLastSelected(id);
    if (id) {
      dispatch(getTaskDetail({ id, forceRefresh: true }));
    }
  };

  const handleLoadMore = debounce(() => {
    const options = createFetchOptions();
    const paging = getPagingOptions({ currentGroup, groups, tasks: tasksEntities, category });

    if (!Boolean(donePaging[currentGroup]) && !isEmpty(groups[currentGroup])) {
      dispatch(getTasks({ ...options, ...paging }, assignedTo || userId));
    }
  }, 250);

  const handleMassDelete = () => {
    dispatch(massDeleteTasks(filteredCurrentlySelected, currentGroup)).then(() => {
      setCurrentlySelected([]);
      setLocalSelectAll(false);
      handleLoadMore();
      dispatch(clearAlert());
    });
  };

  const checkMassActionLimit = () => {
    if (currentlySelectedCount > MASS_ACTION_LIMIT) {
      dispatch(
        showMessage(
          {
            message: `A maximum of ${MASS_ACTION_LIMIT} tasks can be selected for mass actions.`,
            type: 'error'
          },
          true
        )
      );
      return false;
    }
    return true;
  };
  const handleMassDeleteConfirmation = () => {
    if (checkMassActionLimit()) {
      dispatch(
        showAlert({
          message: `Are you sure you want to delete ${currentlySelectedCount} selected ${pluralize(
            currentlySelectedCount,
            'task'
          )}?`,
          icon: 'delete',
          iconSize: 'm',
          primaryButtonLabel: 'Yes, delete',
          primaryButtonHandler: handleMassDelete
        })
      );
    }
  };

  const handleMarkDone = () => {
    // Because FE modifies task entities before BE responds, we do not want to move completed tasks
    const cleanList = filteredCurrentlySelected?.filter(
      id => tasksEntities?.[id]?.isDone === false || tasksEntities?.[id]?.completed === false
    );
    if (cleanList.length > 0) {
      dispatch(massMarkDone(cleanList, currentGroup)).then(() => {
        setCurrentlySelected([]);
        handleLoadMore();
        dispatch(clearAlert());
      });
    }
    dispatch(clearAlert());
  };

  const handleMassMarkDoneConfirmation = () => {
    if (checkMassActionLimit()) {
      dispatch(
        showAlert({
          message: `Are you sure you want to mark ${currentlySelectedCount} selected ${pluralize(
            currentlySelectedCount,
            'task'
          )} as done?`,
          icon: 'check',
          iconSize: 'm',
          primaryButtonLabel: 'Yes, mark done',
          primaryButtonHandler: handleMarkDone
        })
      );
    }
  };

  const handleDeselectAll = () => {
    setCurrentlySelected([]);
    setLocalSelectAll(false);
  };

  const handleSelectAll = () => {
    // Clear selected list
    if (localSelectAll) {
      handleDeselectAll();
      return;
    }
    // Append current list to selected when selected is not empty
    setLocalSelectAll(true);
    setCurrentlySelected(cleanList);
  };

  const processCleanList = cleanList => {
    const filteredCleanList = cleanList.filter(id => {
      if (ignoreData.massDelete.timestamp && ignoreData.massDelete?.list?.includes(id)) {
        return false;
      }
      // When complete = true, we want to display those newly marked done tasks instead of ignoring

      if (!isCompleteFlagOn && ignoreData.massMarkdone.timestamp && ignoreData.massMarkdone?.list?.includes(id)) {
        return false;
      }
      return true;
    });
    filteredCleanList.splice(limitDisplayCount ?? cleanList.length);

    return filteredCleanList;
  };

  return (
    <Fragment>
      <View>
        {!isMinimalView && (
          <div className={listStyles.listActionsHeader}>
            <div className={listStyles.listActions}>
              {cleanList?.length > 0 && (
                <section className={listStyles.listActionsCheck}>
                  <FormCheckBox id="msSelectAll" isChecked={localSelectAll} changeHandler={handleSelectAll} />
                </section>
              )}
              {displayMultiSelectActions && (
                <ButtonGroup variant="icon">
                  <Tag
                    entityId="deselectAll"
                    label={`${currentlySelectedCount} selected`}
                    handleRemove={handleDeselectAll}
                    removable
                    removeTooltip="Deselect all"
                  />
                  // Hide when complete flag is explicitly true, shown by default
                  {!isCompleteFlagOn && (
                    <Button
                      onClick={handleMassMarkDoneConfirmation}
                      ariaLabel="Mark done"
                      title="Mark done"
                      icon="check"
                      size="m"
                      tooltipPos="below"
                    />
                  )}
                  <Button
                    onClick={handleMassDeleteConfirmation}
                    ariaLabel="Delete"
                    title="Delete"
                    icon="delete"
                    size="m"
                    tooltipPos="below"
                  />
                </ButtonGroup>
              )}
            </div>
            <Filters isLoading={isLoading} isVisible={!isNested} title="Filter tasks">
              <TaskFilter location={location} />
            </Filters>
          </div>
        )}
        <Container className={listStyles.listContainer}>
          <ListHeader columns={headerColumns} location={location} className={headerClassName} />

          <List
            isNested={isNested}
            noResultsMessage={noResultsMessage}
            pageHandler={handleLoadMore}
            donePaging={donePaging[currentGroup]}
            testId="TaskList"
            headerHeightOffset={320}
            isVirtuoso={isVirtuoso}
            listClassName={listClassName}
          >
            {cleanList ? (
              processCleanList(cleanList).map(id => {
                const isChecked = currentlySelected.indexOf(id) !== -1 ? true : false;
                return (
                  <li key={id} className={listStyles.listItem}>
                    <TaskCard
                      group={currentGroup}
                      data={tasksEntities[id]}
                      isNested={isNested}
                      location={location}
                      wrapupHandler={handleWrapup}
                      onClick={handleCardClick}
                      currentTask={currentTask}
                      handleEditMode={e => toggleEditMode(e, id)}
                      handlePreviewMode={e => togglePreviewMode(e, id)}
                      handleEntitySelect={!isMinimalView && handleEntitySelect}
                      isChecked={isChecked}
                    />
                  </li>
                );
              })
            ) : (
              <ListPlaceholder rows={3} isNested={isNested} />
            )}
          </List>
        </Container>
      </View>

      {isVirtuoso === false && (
        <ListFooter donePaging={donePaging[currentGroup]} isLoading={isLoading} infiniteRef={infiniteRef} />
      )}

      <EditTaskDialog />
      <PreviewTaskDialog
        handlePreview={togglePreviewMode}
        handleSubmit={handleSubmitFromPreview}
        handleTemplateChange={handleTemplateChange}
        showSaveTemplate={false}
        disabled={isLoading}
      />
    </Fragment>
  );
};

TaskList.propTypes = {
  className: string
};

export default TaskList;
