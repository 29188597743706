import { useState } from 'react';

/**
 * Custom hook for FormRadio.
 */
export const useFormRadio = initialState => {
  const [value, setValue] = useState(initialState);

  const onChange = e => {
    const { target } = e;
    if (target) {
      setValue(target.value);
      return;
    }

    // if onChange is called directly
    setValue(e);
  };

  return { value, onChange };
};

/**
 * Custom hook for useFormCheckBox.
 */
export const useFormCheckBox = initialState => {
  const [value, setValue] = useState(initialState);
  // use this to flip the boolean or set value
  const onChange = val => {
    if (val != null) {
      setValue(val);
    } else {
      setValue(!value);
    }
  };

  return { value, onChange };
};

/**
 * Custom hook for useFormDateInput.
 */
export const useFormDateInput = initialState => {
  const [value, setValue] = useState(initialState == null ? '' : initialState);
  const onChange = data => {
    setValue(data);
  };

  return { value, onChange };
};

/**
 * Custom hook for useFormField.
 */
export const useFormField = initialState => {
  const [value, setValue] = useState(initialState == null ? '' : initialState);
  const [isValid, setIsValid] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const onChange = e => {
    if (e == null) {
      setValue('');
      return;
    }

    const { target } = e;
    if (target) {
      setValue(target.value);
      return;
    }
    // if onChange is called directly
    setValue(e);
  };

  return { value, onChange, disabled, setDisabled, isValid, setIsValid };
};
