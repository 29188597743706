import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';

import { AlertDialog } from '../';
import { signOutUser } from '../../actions';
import { trackError } from '../../utils/analytics';

const ERROR_MESSAGE = "Apologies. We've encountered an issue.";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  componentDidCatch(error, info) {
    if (!window.ga) {
      return;
    }

    trackError(`${error} ${JSON.stringify(info)}`, true);
  }

  resetState() {
    this.setState({ error: false });
  }

  backClickHandler = e => {
    e.preventDefault();
    window.history.go(-1);
    navigate('/');
    this.resetState();
  };

  signOutHandler = e => {
    e.preventDefault();
    //Handle Signout here...
    const { signOutUser, user } = this.props;
    signOutUser(user);
    this.resetState();
  };

  render() {
    if (this.state.error) {
      const alertProps = {
        isOpen: true,
        icon: 'error',
        iconIsColored: true,
        loading: false,
        message: ERROR_MESSAGE,
        primaryButtonLabel: 'Sign Out',
        primaryButtonHandler: this.signOutHandler,
        secondaryButtonLabel: 'Go Back',
        secondaryButtonHandler: this.backClickHandler,
        secondaryButtonIcon: 'arrowleft'
      };

      return <AlertDialog data={alertProps} />;
    }

    return this.props.children;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  signOutUser: state => signOutUser(state)
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
