/** @module */
export const CALENDAR_SYNC_ACCOUNTS = 'CALENDAR_SYNC_ACCOUNTS';
export const CALENDAR_SYNC_ACCOUNTS_DELETE = 'CALENDAR_SYNC_ACCOUNTS_DELETE';
export const CALENDAR_SYNC_CALENDARS = 'CALENDAR_SYNC_CALENDARS';

export const initialState = {
  accounts: {},
  calendars: []
};

/**
 * The Nylas Calendar Snyc redux reducer.
 * @param {Object} state - the current state of the settings store.
 * @param {Object} action - the action to take on the settings store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const calendarSyncReducer = (state = initialState, action = {}) => {
  const { type } = action;

  switch (type) {
    case CALENDAR_SYNC_ACCOUNTS:
      return {
        ...state,
        accounts: action.data
      };

    case CALENDAR_SYNC_CALENDARS:
      return {
        ...state,
        calendars: action.data
      };

    case CALENDAR_SYNC_ACCOUNTS_DELETE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
