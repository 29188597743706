import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon } from '../';
import { getContactStatus } from '../../utils';

import styles from './ContactCard.css';

export const ContactStatus = props => {
  const { className, contactStatus } = props;

  if (!contactStatus) {
    return null;
  }

  const classes = classnames({
    [styles.contactStatus]: !className,
    [className]: !!className
  });

  const contactStatusLabel = getContactStatus(contactStatus);
  const contactStatusIcon = contactStatusLabel ? contactStatusLabel.toLowerCase().replace(' ', '') : 'new';

  return (
    <div className={classes}>
      <Icon name={contactStatusIcon} size="s" className={styles.contactStatusIcon} isColored={true} />
      {contactStatusLabel}
    </div>
  );
};

ContactStatus.propTypes = {
  className: PropTypes.string,
  contactStatus: PropTypes.number
};
