import React from 'react';
import { LiveChatButton } from '../components/Button/LiveChatButton';
import { SUPPORT } from '../constants';

export const SYNC_STATUSES = {
  // v2 sync statuses - Used by latest contact/calendar sync.
  initializing: {
    displayStatus: 'Initializing...',
    statusIsPositive: true,
    message: 'Your first sync will begin shortly.'
  },
  running: {
    displayStatus: 'Running',
    statusIsPositive: true,
    message: 'Sync runs in the background automatically, keeping your account up-to-date.'
  },
  invalidcredentials: {
    displayStatus: 'Invalid credentials',
    statusIsPositive: false,
    message: 'Please delete, and then reauthorize your account.'
  },
  stopped: {
    displayStatus: 'Stopped',
    statusIsPositive: false,
    message: 'Contact sync is currently stopped (most likely due to a service interruption). Please check back later.'
  },
  error: {
    displayStatus: 'Error',
    statusIsPositive: false,
    message: (
      <>
        An unexpected error has occurred. Please start a {<LiveChatButton />} with customer care or email at{' '}
        {SUPPORT.EMAIL}.
      </>
    )
  },
  default: {
    displayStatus: 'Loading...',
    statusIsPositive: true
  }
};

export const getDisplayStatus = status => {
  return SYNC_STATUSES[status] || SYNC_STATUSES.default;
};

export const setupContactSyncAlert = {
  message: `You don't yet have contact sync set up. Would you like to enable it?`,
  primaryButtonLabel: `Setup Contact Sync`,
  icon: 'contactsync',
  iconSize: 'm'
};
