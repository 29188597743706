import React, { useCallback } from 'react';
import PropTypes, { oneOf } from 'prop-types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { ContactLastTouch, ContactStatus } from './';
import { Avatar, BuyerPrefs, FormCheckBox, Tag, TagGroup } from '../';
import { useMediaQueryContext } from '../MediaQueryProvider/MediaQueryProvider';
import { createContactTypeTagUrl } from '../../utils/contacts';
import { checkIfIsOnTeamAccount } from '../../utils/team';
import { getContactDisplayName } from '../../utils/contacts';

import styles from './ContactCard.css';
import cardStyles from './Card.css';
import { PrimaryContactMethods } from '../PrimaryContactMethods/PrimaryContactMethods';

export const ContactCardBasic = props => {
  const { isTabletPortraitAndUp } = useMediaQueryContext() || {};
  const { data, isChecked, handleEntitySelect, variant = 'default' } = props;

  const isDashboard = variant === 'dashboard';
  const { groups } = useSelector(store => store.team);
  const isTeamAccount = checkIfIsOnTeamAccount(groups.all);

  const {
    assignedTo,
    emails,
    buyerPrefs,
    contactStatus,
    contactTypes = [],
    fullName,
    id,
    lastTouchPoint = {}, // lastTouchPoint can empty for brand new automated leads
    consumerLastTouchPoint = {},
    pictureURL,
    primaryPerson,
    source,
    whenCreated
  } = data || {};

  const handleMultiSelectCheck = useCallback(
    e => {
      e.stopPropagation();

      handleEntitySelect(id);
    },
    [handleEntitySelect, id]
  );

  if (!data) {
    return null;
  }

  const displayAssignedToName = getContactDisplayName(assignedTo?.name);
  const gridStyles = classnames({
    [styles.grid]: !isDashboard,
    [styles.gridNoAssignedTo]: !isTeamAccount && !isDashboard,
    [styles.dashboardGrid]: isDashboard
  });

  const avatarSize = isTabletPortraitAndUp ? 's' : 'm';

  return (
    <div className={gridStyles}>
      {!isDashboard && (
        <div className={styles.gridAreaCheckbox}>
          <FormCheckBox id={`ms-${id}`} isChecked={isChecked} changeHandler={handleMultiSelectCheck} />
        </div>
      )}
      <div className={styles.gridAreaMain}>
        {isTabletPortraitAndUp ? (
          <Avatar
            emails={emails}
            name={fullName}
            pictureURL={pictureURL}
            primaryPerson={primaryPerson}
            size={avatarSize}
            hideImage={true}
          />
        ) : null}

        <div className={cardStyles.titleContainer}>
          <div className={cardStyles.cardTitle} title={fullName}>
            {fullName}
          </div>
        </div>
      </div>
      {!isDashboard && (
        <>
          <div className={styles.gridAreaLastTouch}>
            <ContactLastTouch
              lastTouchPoint={lastTouchPoint}
              showLastTouchLabel={false}
              touchType="action"
              whenCreated={whenCreated}
            />
          </div>
          <div className={styles.gridAreaConsumerLastTouch}>
            <ContactLastTouch
              consumerLastTouchPoint={consumerLastTouchPoint}
              showLastTouchLabel={false}
              touchType="response"
              whenCreated={whenCreated}
            />
          </div>
        </>
      )}
      {!isDashboard && (
        <div className={styles.gridAreaStatus}>
          <ContactStatus contactStatus={contactStatus} />
        </div>
      )}
      <div className={styles.gridAreaSource}>
        <div className={cardStyles.cardDetails} title={source}>
          {source}
        </div>
      </div>
      <div className={styles.gridAreaTags}>
        <TagGroup className={styles.tagGroup} limit={5}>
          {contactTypes?.map(tag => (
            <Tag key={tag} label={tag} url={createContactTypeTagUrl(tag)} />
          ))}
        </TagGroup>
      </div>
      {!isDashboard && (
        <div className={styles.gridAreaBuyerPrefs}>
          <BuyerPrefs buyerPrefs={buyerPrefs} />
        </div>
      )}
      {isTeamAccount && !isDashboard && (
        <div className={styles.gridAreaAssignedTo}>
          <div className={cardStyles.cardDetails} title={displayAssignedToName}>
            {displayAssignedToName}
          </div>
        </div>
      )}
      <div className={styles.gridAreaActions}>
        <PrimaryContactMethods contact={data} />
      </div>
    </div>
  );
};

ContactCardBasic.propTypes = {
  data: PropTypes.object.isRequired,
  variant: oneOf(['default', 'dashboard']).isRequired
};
