/** @module */

export const SMARTZIP_REQUEST = 'SMARTZIP_REQUEST';
export const SET_SMARTZIP_STATUS = 'SET_SMARTZIP_STATUS';
export const SET_SMARTZIP_SSO_URL = 'SET_SMARTZIP_SSO_URL';

export const initialState = {
  isLoading: false,
  smartzipStatus: null,
  smartzipSsoUrl: null
};

export const smartzipReducer = (state = initialState, action = {}) => {
  const { smartzipSsoUrl, smartzipStatus } = action;
  switch (action.type) {
    case SMARTZIP_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case SET_SMARTZIP_STATUS:
      return {
        ...state,
        isLoading: false,
        smartzipStatus
      };
    case SET_SMARTZIP_SSO_URL:
      return {
        ...state,
        isLoading: false,
        smartzipSsoUrl
      };

    default:
      return state;
  }
};
