import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Tag } from './';

import styles from './TagGroup.css';

export const TagGroup = props => {
  const { children, className, limit } = props;

  if (!children || children.length === 0) {
    return null;
  }

  const classes = classnames({
    [styles.tagGroup]: !className,
    [className]: !!className
  });

  // using the limit prop, we can limit the number of children to display
  const displayChildren = limit ? children.slice(0, limit) : children;
  const numberOverLimit = limit ? children.length - limit : 0;
  const hasMoreThanLimit = numberOverLimit > 0;

  return (
    <div data-testid="TagGroup.container" className={classes}>
      {displayChildren}
      {hasMoreThanLimit && <Tag key="more" label={`+ ${numberOverLimit} more`} />}
    </div>
  );
};

TagGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  limit: PropTypes.number
};
