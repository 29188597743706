export const OPT_IN_STATUS = {
  0: 'No Record',
  1: 'Opted-in',
  2: 'Opted-out',
  3: 'Pending request',
  4: 'Not provided (default opted-out)',
  5: 'Not provided (default opted-in)' // Social Connect
};

export const PHONE_TYPE = {
  0: 'Home',
  1: 'Business',
  2: 'Pager',
  3: 'Mobile',
  4: 'Fax',
  5: 'Other'
};

export const WARNING_MESSAGE = {
  EMAIL_NOT_SETUP: 'In order to use this feature you must set up your email integration.',
  TEXTING_NOT_SETUP: 'In order to use this feature you must set up texting through Top Producer.',
  TEXTING_REG_IN_PROGRESS:
    'Your texting registration is currently in process. Once it is approved you will be able to use this feature. Will will notify you via text once your registration is active.'
};
