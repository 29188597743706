import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button } from '../Button';

import styles from './DetailsHeader.css';

export const DetailsHeader = props => {
  const { children, className, closeLabel = 'Close.', closeUrl, isNested = false } = props;

  const classes = classnames({
    [styles.detailsHeader]: !className,
    [styles.isNested]: isNested,
    [className]: !!className
  });

  return (
    <header className={classes}>
      {!isNested && <Button url={closeUrl} ariaLabel={closeLabel} title={closeLabel} icon="close" size="s" />}
      {children}
    </header>
  );
};

DetailsHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  closeLabel: PropTypes.string,
  closeUrl: PropTypes.string
};
