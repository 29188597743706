/** @module */

import { SALESFORCE_REQUEST, SALESFORCE_REQUEST_FAIL, SALESFORCE_REQUEST_SUCCESS } from '../reducers/salesforce';
import { request, requestError } from '../utils';

export const postSalesforceSSO = ({ recipient, samlResponse }) => {
  var formSSO = document.createElement('form');
  formSSO.setAttribute('id', 'salesforceSSOForm');
  formSSO.method = 'POST';
  formSSO.action = recipient;
  formSSO.target = '_blank';

  var hiddenField0 = document.createElement('input');
  hiddenField0.type = 'hidden';
  hiddenField0.name = 'SAMLResponse';
  hiddenField0.value = samlResponse;

  formSSO.appendChild(hiddenField0);

  var hiddenField1 = document.createElement('input');
  hiddenField1.type = 'hidden';
  hiddenField1.name = 'idpConfig.recipient';
  hiddenField1.value = recipient;

  formSSO.appendChild(hiddenField1);

  var hiddenField2 = document.createElement('input');
  hiddenField2.type = 'hidden';
  hiddenField2.name = 'samlPoster_RelayState';
  hiddenField2.value = '';

  formSSO.appendChild(hiddenField2);

  document.body.appendChild(formSSO);

  formSSO.submit();

  document.getElementById('salesforceSSOForm').remove();
};

export const getSalesforceStatus = (currentUrl, shouldRedirect = true) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'GET',
      path: `tpxsso/salesforce?client=${currentUrl}`,
      shouldBeCached: false
    };

    dispatch({ type: SALESFORCE_REQUEST });

    try {
      const { data } = await request(options);

      if (shouldRedirect) {
        dispatch(postSalesforceSSO(data));
      }

      dispatch({ type: SALESFORCE_REQUEST_SUCCESS, salesforceStatus: data });
    } catch (error) {
      dispatch({ type: SALESFORCE_REQUEST_FAIL });

      requestError({ ...error }, dispatch);
      return Promise.reject(error);
    }
  };
};

export const getSalesforceUrl = options => {
  const { client = process.env.PUBLIC_HOST, username, password, id = '' } = options;
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      params: { client, username, password, id },
      path: 'identityprovider/ssoresponse',
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      if (data) {
        dispatch({ type: SALESFORCE_REQUEST_SUCCESS, salesforceStatus: data });
      } else {
        return Promise.reject();
      }
    } catch (error) {}
  };
};
