import React, { Fragment, useEffect, useMemo } from 'react';
import { array, oneOf } from 'prop-types';
import { Link } from '@reach/router';
import camelCase from 'camelcase';
import classnames from 'classnames';

import { Icon } from '../';
import { useMediaQueryContext } from '../MediaQueryProvider/MediaQueryProvider';
import { openPopup } from '../../utils';
import { EXTERNAL_LINK_ATTRS, fuzzyMatchUrl, isExternalLink } from '../../utils/urls';

import styles from './LinkList.css';

const LinkChildren = props => {
  const { isTabletLandscapeAndUp } = useMediaQueryContext() || {};
  const { icon, iconLabel, label, selected, type } = props;
  const iconSize = type === 'nav' ? 'l' : isTabletLandscapeAndUp ? 's' : 'm';

  const iconClasses = classnames({
    [styles[`icon--${type}`]]: !!type
  });

  return (
    <Fragment>
      <Icon name={icon} size={iconSize} label={iconLabel} isColored={selected} className={iconClasses} />
      {label}
    </Fragment>
  );
};

const LinkListLink = props => {
  const { isTabletLandscapeAndUp, supportsHover } = useMediaQueryContext() || {};
  const { className, item, selected, type, testId } = props;
  const { url, icon, iconLabel, label, openAs, replace, onClick } = item || {};

  const linkIsExternal = isExternalLink(url) || openAs === 'pendoHack';
  let otherAttrs = linkIsExternal ? { ...EXTERNAL_LINK_ATTRS, onClick } : { onClick };

  if (openAs === 'pendoHack') {
    otherAttrs['data-pendo'] = true;
  }

  if (openAs === 'popup' && isTabletLandscapeAndUp && supportsHover) {
    otherAttrs.onClick = e => {
      e.preventDefault();
      if (onClick) {
        onClick();
      }
      openPopup(url, { position: 'center' });
    };
  }

  if (openAs === 'aiAuthor' && onClick) {
    otherAttrs.onClick = e => {
      e.preventDefault();

      onClick();
    };
  }

  useEffect(() => {
    const pendoEls = [...document.querySelectorAll('[data-pendo]')];
    if (openAs === 'pendoHack') {
      function pendoFeedback() {
        window?.pendo?.feedback.loginAndRedirect({ anchor: this });
        return false;
      }

      pendoEls.forEach(el => {
        el.onclick = pendoFeedback;
      });
    }

    return () => {
      pendoEls.forEach(el => {
        el.onclick = null;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTabletLandscapeAndUp]);

  if (linkIsExternal || (!!onClick && !url)) {
    return (
      <a
        href={url}
        data-cy={camelCase(label)}
        className={className}
        data-selected={selected}
        data-testid={testId}
        {...otherAttrs}
      >
        <LinkChildren icon={icon} iconLabel={iconLabel} label={label} selected={selected} type={type} />
      </a>
    );
  }

  return (
    <Link
      to={url}
      className={className}
      data-cy={camelCase(label).replace("'", '')}
      data-selected={selected}
      data-testid={testId}
      replace={replace}
      {...otherAttrs}
    >
      <LinkChildren icon={icon} iconLabel={iconLabel} label={label} selected={selected} type={type} />
    </Link>
  );
};

export const LinkList = props => {
  const location = window.location;
  const { className, data, orientation = 'horizontal', type, position = 'top', testId = 'LinkList' } = props;

  const selectedIndex = useMemo(() => {
    return data.findIndex(item => {
      const { matchKey, url } = item;
      return fuzzyMatchUrl(url, location, matchKey);
    });
  }, [data, location]);

  if (!data) {
    return null;
  }

  const classes = classnames({
    [styles['list']]: !className,
    [styles[`list--${type}`]]: !!type,
    [styles[`list--${orientation}`]]: !!orientation,
    [styles[`list--selectedIndex${selectedIndex}`]]: true,
    [className]: !!className
  });

  const itemClasses = classnames({
    [styles['item']]: true,
    [styles[`item--${type}`]]: !!type,
    [styles[`item--${orientation}`]]: !!orientation,
    [styles[`item--${position}`]]: !!position
  });

  const actionClasses = classnames({
    [styles[`action`]]: true,
    [styles[`action--${type}`]]: !!type,
    [styles[`action--${orientation}`]]: !!orientation
  });

  return (
    <ul data-testid={testId} className={classes}>
      {data.map((item, i) => {
        const itemTestId = `${testId}-action${i}`;

        return (
          <li data-testid={itemTestId} key={item.label} className={itemClasses}>
            <LinkListLink
              testId={`${itemTestId}-link`}
              item={item}
              className={actionClasses}
              selected={i === selectedIndex}
              type={type}
            />
          </li>
        );
      })}
    </ul>
  );
};

LinkList.propTypes = {
  data: array.isRequired,
  orientation: oneOf(['horizontal', 'vertical']),
  type: oneOf(['hamburger', 'nav', 'subNav', 'copy']),
  position: oneOf(['top', 'bottom'])
};
