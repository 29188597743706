import React, { useEffect } from 'react';
import { bool, oneOf, object } from 'prop-types';
import { useDispatch } from 'react-redux';
import { navigate, useLocation } from '@reach/router';
import classnames from 'classnames';

import { Card } from './Card';
import { Button, ButtonGroup } from '../Button';
import { Tag } from '../Tag';
import { useMediaQueryContext } from '../MediaQueryProvider/MediaQueryProvider';
import { showAlert } from '../../actions';
import { deleteTemplate } from '../../actions/templates';
import { TEMPLATE_OBJECT_TYPE_MAP, TEMPLATES_URL } from '../../constants/templates';
import { isNewlyAdded } from '../../utils/dates';

import styles from './TemplateCard.css';
import cardStyles from './Card.css';

export const TemplateCard = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isTabletLandscapeAndUp } = useMediaQueryContext() || {};

  const { pathname, search } = location || {};
  const url = !pathname.includes('/contacts') ? TEMPLATES_URL : '/contacts/templates';

  const { data, isSelected, variant = 'default' } = props;

  // scrollPreviewIntoView is used to scroll the preview into view on mobile, tablet portrait.
  const scrollPreviewIntoView = () => {
    if (isTabletLandscapeAndUp) {
      return;
    }

    document.getElementById('preview')?.scrollIntoView({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    scrollPreviewIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { description, fileId, objectType, whenCreated, isReadOnly } = data || {};

  const isNew = isNewlyAdded(whenCreated);

  const displayObjectType = TEMPLATE_OBJECT_TYPE_MAP.get(objectType);
  const isTeam = Boolean(isReadOnly) && displayObjectType === 'personal';

  const confirmDeleteHandler = e => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      showAlert({
        message: `Are you sure you want to delete this template?`,
        primaryButtonHandler: () => dispatch(deleteTemplate(fileId))
      })
    );
  };

  const handleEdit = e => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`${url}/edit/${fileId}${search}`);
  };

  const showActions = variant === 'default' && displayObjectType === 'personal' && !isTeam;

  const cardClasses = classnames({
    [cardStyles.card]: true,
    [styles[`variant--${variant}`]]: true,
    [styles.isSelected]: isSelected
  });

  return (
    <Card className={cardClasses} url={`${url}/view/${fileId}${search}`} onClick={scrollPreviewIntoView}>
      <div className={styles.grid}>
        <div className={styles.gridAreaMain}>{description}</div>
        <div className={styles.gridAreaTags}>
          {isNew && <Tag label="new" />}
          {isTeam ? <Tag label="Team" /> : <Tag label={displayObjectType} />}
        </div>
        {showActions && (
          <div className={styles.gridAreaActions}>
            <ButtonGroup variant="icon">
              <Button ariaLabel="Edit" tooltipPos="left" icon="edit" onClick={handleEdit} />
              <Button ariaLabel="Delete" tooltipPos="left" icon="delete" onClick={confirmDeleteHandler} />
            </ButtonGroup>
          </div>
        )}
      </div>
    </Card>
  );
};

TemplateCard.propTypes = {
  data: object.isRequired,
  isSelected: bool,
  variant: oneOf(['default', 'simple'])
};
