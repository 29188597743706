import React from 'react';
import { Dropdown, FormFieldReadOnly, FormLabel, FormRow } from '../../components';
import formFieldStyles from '../FormField/FormField.css';
import { getMLSBoardDisplayName, getMLSBoardLabels } from '../../utils/mlsBoards';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';

const MlsDropdown = props => {
  const mls = useSelector(state => state.mls);
  const { credentials, sources } = mls || {};
  const { handleChangeMlsBoard, mlsBoard } = props;
  const readOnlyMls = mlsBoard && sources?.[mlsBoard] ? getMLSBoardDisplayName(sources[mlsBoard]) : 'Loading...';
  const hasOneCredential = Object.keys(credentials).length === 1;
  const mlsBoardLabels = getMLSBoardLabels(sources, credentials);
  return (
    <Fragment>
      <FormRow>
        <div className={formFieldStyles.field}>
          <FormLabel htmlFor="mlsCreds">MLS Board</FormLabel>
          {hasOneCredential ? (
            <FormFieldReadOnly id="mlsCreds" value={readOnlyMls} />
          ) : (
            <Dropdown
              id="mlsCreds"
              items={mlsBoardLabels}
              onChange={handleChangeMlsBoard}
              size="auto"
              value={mlsBoard}
            />
          )}
        </div>
      </FormRow>
    </Fragment>
  );
};

export default MlsDropdown;
