import React from 'react';
import { bool, string } from 'prop-types';
import classnames from 'classnames';

import { Icon } from '../Icon';

import styles from './MessageMicro.css';

export const MessageMicro = props => {
  const { className, icon, iconSize = 's', message, isVisible = true } = props;

  if (!message || !isVisible) {
    return null;
  }

  const classes = classnames({
    [styles.message]: !className,
    [className]: !!className
  });

  return (
    <div className={classes}>
      {icon && <Icon name={icon} size={iconSize} isColored={true} />}
      {message}
    </div>
  );
};

MessageMicro.propTypes = {
  className: string,
  icon: string,
  isVisible: bool,
  message: string.isRequired
};
