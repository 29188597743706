import React from 'react';
import { bool, string } from 'prop-types';
import { useSlate } from 'slate-react';

import { EditorCommands } from '../editor-commands';
import { MenuButton } from '../../MenuButton';

import styles from './ButtonFontColor.css';

const DEFAULT_FONT_COLOR = '#000';
const DEFAULT_FONT_BACKGROUND = '#fff';

/**
 * React component for rendering the ButtonFontColor component.
 * @param {Object} props ButtonFontColor component props.
 * @param {String} [props.currentFontBackground=DEFAULT_FONT_BACKGROUND] The current font background color.
 * @param {String} [props.currentFontColor=DEFAULT_FONT_COLOR] The current font color.
 * @param {Boolean} [props.isDisabled] The button's disabled state.
 */
export const ButtonFontColor = props => {
  const editor = useSlate();
  const { currentFontBackground = DEFAULT_FONT_BACKGROUND, currentFontColor = DEFAULT_FONT_COLOR, isDisabled } = props;

  const colors = [
    DEFAULT_FONT_COLOR,
    'rgb(68, 68, 68)',
    'rgb(102, 102, 102)',
    'rgb(153, 153, 153)',
    'rgb(204, 204, 204)',
    'rgb(238, 238, 238)',
    'rgb(243, 243, 243)',
    DEFAULT_FONT_BACKGROUND,
    '#b80000',
    '#dB3e00',
    '#fccb00',
    '#008b02',
    '#006b76',
    '#1273de',
    '#004dcf',
    '#5300eb',
    '#eb9694',
    '#fad0c3',
    '#fef3bd',
    '#c1e1c5',
    '#bedadc',
    '#c4def6',
    '#bed3f3',
    '#d4c4fb'
  ];

  const fontColorMenuItems = colors.map(color => {
    return {
      className: styles.swatch,
      id: `${color}FontColor`,
      isSelected: color === currentFontColor,
      label: color,
      onSelect: () => EditorCommands.handleMarkStyleChange(editor, 'fontColor', color),
      style: { color, backgroundColor: color }
    };
  });

  const fontBackgroundMenuItems = colors.map(color => {
    return {
      className: styles.swatch,
      id: `${color}FontBG`,
      isSelected: color === currentFontBackground,
      label: color,
      onSelect: () => EditorCommands.handleMarkStyleChange(editor, 'fontBackground', color),
      style: { color, backgroundColor: color }
    };
  });

  const menuItems = [...fontColorMenuItems, ...fontBackgroundMenuItems];

  return (
    <MenuButton
      disabled={isDisabled}
      icon="colors"
      menuItems={menuItems}
      menuListClassName={styles.menuList}
      position="above"
      showArrow={true}
      toolTip="Text color"
      tooltipPos="below"
    />
  );
};

ButtonFontColor.propTypes = {
  currentFontBackground: string,
  currentFontColor: string,
  isDisabled: bool
};
