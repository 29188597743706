import React from 'react';
import { array, bool, func, object } from 'prop-types';
import { Dropdown, FormFieldReadOnly, FormFieldset, FormLabel, FormRow } from '../';

import styles from './SnapshotForm.css';
import formFieldStyles from '../FormField/FormField.css';

export const MLSFieldset = React.forwardRef((props, ref) => {
  const { board, disabled, mlsList, onMLSBoardChange } = props;

  if (!board) {
    return null;
  }

  const displayMlsList = mlsList.map(board => {
    // we augment the board list with a new title key which is used in the dropdown as the display label,
    return {
      ...board,
      title: `${board.value} (${board.board_id})`
    };
  });

  return (
    <FormFieldset label="Select your MLS">
      <FormRow className={styles.verticalRow}>
        <FormLabel htmlFor="board">MLS Board</FormLabel>
        <div className={formFieldStyles.field}>
          {displayMlsList && displayMlsList.length > 1 ? (
            <Dropdown
              id="board"
              items={displayMlsList}
              className={styles.select}
              onChange={onMLSBoardChange}
              value={board.value}
              disabled={disabled}
              ref={ref}
            />
          ) : (
            <FormFieldReadOnly id="board" value={board.value.replace('<default>', '')} />
          )}
        </div>
      </FormRow>
    </FormFieldset>
  );
});

MLSFieldset.propTypes = {
  board: object,
  disabled: bool,
  mlsList: array.isRequired,
  onMLSBoardChange: func.isRequired
};
