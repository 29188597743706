/** @module */

import { ACTIVATE_ADVANCED_SEARCH, SET_ADVANCED_SEARCH, SHOW_ADVANCED_SEARCH } from '../reducers/advancedSearch';
import { CONTACTS_STATUS_COUNTS } from '../reducers/contacts';

export const setAdvancedSearch = data => dispatch => dispatch({ type: SET_ADVANCED_SEARCH, data });
export const showAdvancedSearch = isOpen => dispatch => dispatch({ type: SHOW_ADVANCED_SEARCH, isOpen });
export const activateAdvancedSearch = isActive => dispatch => {
  dispatch({ type: ACTIVATE_ADVANCED_SEARCH, isActive });
  if (isActive) {
    dispatch({ type: CONTACTS_STATUS_COUNTS, statusCounts: { advanced: null } });
  }
};
