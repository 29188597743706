import { UNICODE } from '../constants';
import { EMAIL_TEMPLATE_FORMAT_VERSION, TEMPLATES_SUBTYPE } from '../constants/templates';
import { TEMPLATES_DELETE, TEMPLATES_FAIL, TEMPLATES_LOADING, TEMPLATES_SUCCESS } from '../reducers/templates';
import { request, requestError } from '../utils';
import { getByteSize } from '../utils/strings';
import { getMergeCodeAreas } from '../utils/templates';
import { clearAlert } from './alert';
import { showMessage } from './message';

const { ZERO_WIDTH_SPACE } = UNICODE;
const MAX_FILE_SIZE = 1300000;

/**
 * Get template group name
 * @param {Number} [props.objectType=1] templates linked to userId
 * @param {Number} [props.subType=1] email template
 * @param {Number} [props.isDeleted=0] not deleted
 * @param {Number} [props.isDeleted=1] deleted
 * @param {Number} [props.isDeleted=2] both
 */
const getTemplateGroupName = options => {
  const { objectType, subType, isDeleted } = options || {};
  return `${objectType}::${subType}::${isDeleted}`;
};

/**
 * Get templates by fileId or awsFileId
 * @param {String} id fileId
 * @param {Number} [options.hasBody=1] Numbered boolean whether the body should be included from AWS.
 * @param {String} [options.mode=fileId] get by fileId or awsFileId
 * @param {Number} [options.objectType=99] the objectType
 * @returns {Object[]}
 */
export const getTemplateById = (id, options) => {
  const { hasBody = 1, mode = 'fileId', objectType = 99, subType = 1 } = options || {};

  const requestOptions = {
    baseUrlKey: 'api',
    method: 'GET',
    path: `templates/${mode}/${id}?templateObjectType=${objectType}&hasBody=${hasBody}`,
    shouldBeCached: false
  };
  return async dispatch => {
    try {
      const response = await request(requestOptions);
      const { data, status } = response;
      if (status === 200) {
        const groupName = getTemplateGroupName({ objectType, subType, isDeleted: 0 });
        dispatch({
          type: TEMPLATES_SUCCESS,
          data: [data],
          group: groupName,
          setCurrentGroup: false
        });
        return data;
      } else {
        dispatch({
          type: TEMPLATES_FAIL
        });
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Get templates by type
 * @param {Number} [props.objectType=1] templates linked to userId
 * @param {Number} [props.subType=1] email template
 * @param {Number} [props.subType=2] text template
 * @param {Number} [props.isDeleted=0] not deleted
 * @param {Number} [props.isDeleted=1] deleted
 * @param {Number} [props.isDeleted=2] both
 */
export const getTemplatesByType = props => {
  const {
    objectType = 1,
    subType = 1,
    isDeleted = 0,
    forceRefresh = false,
    shouldBeCached = true,
    setCurrentGroup = true
  } = props || {};
  const options = {
    baseUrlKey: 'api',
    forceRefresh,
    method: 'GET',
    path: `templates`,
    params: {
      objectId: '', // Not used yet, keep as empty
      objectType,
      subType,
      isDeleted
    },
    shouldBeCached
  };
  return async dispatch => {
    dispatch({
      type: TEMPLATES_LOADING
    });
    try {
      const { data, status } = await request(options);
      if (status === 200) {
        const groupName = getTemplateGroupName({ objectType, subType, isDeleted });
        dispatch({
          type: TEMPLATES_SUCCESS,
          data,
          group: groupName,
          setCurrentGroup
        });
        return data;
      } else {
        dispatch({
          type: TEMPLATES_FAIL
        });
      }
    } catch (error) {
      requestError(error, dispatch);
      dispatch({
        type: TEMPLATES_FAIL
      });
    }
  };
};

/**
 * Dispatches a fetch of both system text templates and templates linked to userId.
 */
export const getTextTemplates = () => {
  return async dispatch => {
    dispatch(getTemplatesByType({ subType: 2, objectType: 99, setCurrentGroup: false }));
    dispatch(getTemplatesByType({ subType: 2 }));
  };
};

/**
 * Dispatches a fetch of both system email templates and templates linked to userId.
 */
export const getEmailTemplates = () => {
  return async dispatch => {
    dispatch(getTemplatesByType({ objectType: 99, setCurrentGroup: false }));
    dispatch(getTemplatesByType());
  };
};

/**
 * Save template - create and update
 * @param {Object} options
 * @param {String} options.body the template body
 * @param {String} options.description template description
 * @param {String} [options.category] template category
 * @param {String} [options.fileId] file ID for updating
 * @param {String} [options.objectType='1'] linked to userId
 * @param {String} [options.objectType='3'] one-off template
 * @param {String} [options.subject] template subject
 * @param {String} [options.subType='1'] for email
 * @param {String} [options.subType='2'] for text
 */
export const saveTemplate = (options, dispatchOptions) => {
  const {
    body,
    category,
    description,
    fileId,
    objectType = 1,
    subject,
    subType = TEMPLATES_SUBTYPE.email.value
  } = options;
  const { shouldShowMessage = true } = dispatchOptions || {};

  const isUpdate = !!fileId;
  const cleanBody = body.replaceAll(ZERO_WIDTH_SPACE, '');
  const area = getMergeCodeAreas(cleanBody);
  const stringifiedBody = JSON.stringify({
    area,
    body: cleanBody,
    subject
  });
  const bodySize = getByteSize(stringifiedBody);

  if (bodySize >= MAX_FILE_SIZE) {
    showMessage({ message: 'Templates must be less than 1.3 MB in size.', type: 'error' }, false);
    return;
  }

  const contentPayload = {
    body: stringifiedBody,
    category,
    description
  };

  const payload = isUpdate
    ? { ...contentPayload, fileId }
    : { ...contentPayload, formatVersion: EMAIL_TEMPLATE_FORMAT_VERSION, objectType, subType };

  const requestOptions = {
    baseUrlKey: 'api',
    method: isUpdate ? 'PUT' : 'POST',
    path: 'templates',
    payload,
    shouldBeCached: false
  };

  return async dispatch => {
    try {
      const { status, data } = await request(requestOptions);

      if (status === 200) {
        const groupName = getTemplateGroupName({ objectType, subType, isDeleted: 0 });
        dispatch({
          type: TEMPLATES_SUCCESS,
          data: [{ ...data }],
          group: groupName,
          setCurrentGroup: false
        });
        if (shouldShowMessage) {
          dispatch(showMessage({ message: 'Template saved successfully.', type: 'success' }, true));
        }
      }
      return data;
    } catch (error) {
      requestError(error, dispatch);
      return Promise.reject();
    }
  };
};

/**
 * Mark a file as deleted on BE
 * @param {String} id file ID
 */
export const deleteTemplate = id => {
  const options = {
    baseUrlKey: 'api',
    method: 'DELETE',
    path: `templates/${id}`,
    shouldBeCached: false
  };
  return async dispatch => {
    try {
      const { status } = await request(options);
      if (status === 204) {
        dispatch({
          type: TEMPLATES_DELETE,
          id
        });
        dispatch(clearAlert());
        dispatch(showMessage({ message: 'Template deleted successfully.', type: 'success' }, true));
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};
