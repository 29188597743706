import React from 'react';
import { arrayOf, bool, func, node, object, oneOf, oneOfType, shape, string } from 'prop-types';
import camelCase from 'camelcase';
import classnames from 'classnames';
import {
  Menu as ReachMenu,
  MenuList as ReachMenuList,
  MenuButton as ReachMenuButton,
  MenuItem as ReachMenuItem,
  MenuLink as ReachMenuLink
} from '@reach/menu-button';

import { Icon } from '../';
import { MenuButtonArrow } from './MenuButtonArrow';
import { EXTERNAL_LINK_ATTRS, isExternalLink } from '../../utils/urls';

import '@reach/menu-button/styles.css';
import buttonStyles from '../Button/Button.css';
import styles from './MenuButton.css';

export const MenuButton = props => {
  const {
    alignment = 'left',
    children,
    className,
    dataCy,
    disabled,
    icon,
    menuItems,
    menuListClassName,
    position = 'below',
    shouldUsePortal = false,
    showArrow,
    toolTip,
    tooltipPos
  } = props;

  if (!menuItems) {
    return null;
  }

  const showTooltip = !!tooltipPos && !!toolTip;

  const buttonClasses = classnames({
    [styles.button]: true,
    [styles.iconOnly]: true,
    [buttonStyles.showTooltip]: showTooltip,
    [buttonStyles[`${tooltipPos}TooltipPos`]]: showTooltip,
    [className]: !!className
  });

  const menuClasses = classnames({
    [styles.menu]: !menuListClassName,
    [styles[`${position}Position`]]: true,
    [styles[`${alignment}Align`]]: true,
    [menuListClassName]: !!menuListClassName
  });

  return (
    <ReachMenu>
      <ReachMenuButton data-cy={dataCy} disabled={disabled} className={buttonClasses} aria-label={toolTip}>
        <Icon name={icon} size="s" />
        {children}
        <MenuButtonArrow showArrow={showArrow} />
      </ReachMenuButton>
      <ReachMenuList className={menuClasses} portal={shouldUsePortal}>
        {menuItems.map(item => {
          const { className, icon, id, isSelected, label, onSelect, style, url } = item;

          const menuItemClasses = classnames({
            [styles.menuItem]: !className,
            [styles.hasIcon]: !!icon,
            [className]: !!className
          });

          const MenuItemComponent = url ? ReachMenuLink : ReachMenuItem;

          const defaultProps = {
            className: menuItemClasses,
            'data-cy': label === 'Settings' ? camelCase(`avatar${label}`) : camelCase(label),
            key: id,
            ['aria-label']: label,
            style
          };

          const externalLinkAttrs = isExternalLink(url) ? EXTERNAL_LINK_ATTRS : {};

          const itemProps = url
            ? {
                ...defaultProps,
                href: url,
                ...externalLinkAttrs
              }
            : {
                ...defaultProps,
                onSelect
              };

          return (
            <MenuItemComponent {...itemProps}>
              <Icon name={icon} size="s" />
              {label}
              {isSelected && <Icon name="check" size="s" isColored={true} />}
            </MenuItemComponent>
          );
        })}
      </ReachMenuList>
    </ReachMenu>
  );
};

MenuButton.propTypes = {
  alignment: oneOf(['center', 'left', 'right']),
  children: oneOfType([arrayOf(node), node]),
  className: string,
  dataCy: string,
  disabled: bool,
  icon: string,
  menuItems: arrayOf(
    shape({
      className: string,
      url: string,
      icon: string,
      id: string.isRequired,
      isSelected: bool,
      label: string.isRequired,
      onSelect: func,
      style: object
    })
  ).isRequired,
  menuListClassName: string,
  position: oneOf(['above', 'below']),
  shouldUsePortal: bool,
  showArrow: bool,
  toolTip: string,
  tooltipPos: oneOf(['below', 'above', 'left', 'right'])
};
