import classnames from 'classnames';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React from 'react';
import { Container, FormDateInput, FormTimeInput } from '../';
import { DATE_FORMATS } from '../../constants';
import { convertToDate, formatLongDate } from '../../utils';
import { parseISODate } from '../../utils/dates';
import styles from './FormDateTimeInput.css';

export const FormDateTimeInput = props => {
  const { id, onDateChange, onTimeChange } = props;
  let { timestamp = new Date().toUTCString(), timeIsValid } = props;
  let dateTime = new Date(convertToDate(timestamp));
  let time = format(dateTime, DATE_FORMATS.ISO_TIME);

  const classes = classnames({
    [styles.dateTimeContainer]: true
  });

  if (!isValidDate(timestamp)) {
    dateTime = new Date();
    dateTime.setHours(0, 0, 0);
    timestamp = dateTime.toUTCString();
    time = format(dateTime, DATE_FORMATS.ISO_TIME);
    timeIsValid = true;
  }

  function isValidDate(dt) {
    const d = new Date(convertToDate(dt));
    return d instanceof Date && !isNaN(d.getTime());
  }

  function onTimeChanged(t) {
    if (t !== null) {
      time = t;
    }
    onTimeChange(time);
  }

  return (
    <Container className={classes}>
      <FormDateInput
        id={id}
        onDateChange={onDateChange}
        placeholder={DATE_FORMATS.INPUT_PLACEHOLDER}
        timestamp={formatLongDate(parseISODate(timestamp), DATE_FORMATS.ISO_DATE)} // converting to this format to support safari browser
      />
      <FormTimeInput handleChange={onTimeChanged} isValid={timeIsValid} time={dateTime} />
    </Container>
  );
};

FormDateTimeInput.propTypes = {
  timestamp: PropTypes.string,
  timeIsValid: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired
};
