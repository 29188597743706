import { getStateCodeStr } from './data';

export const getCitiesFromCampaign = campaign => {
  const { cities } = campaign || {};

  if (cities == null || cities?.length === 0) {
    return [];
  }
  return (
    cities[0].targeting.geo_locations.cities.map(city => {
      return {
        key: city.key,
        name: city.name,
        region: city.region,
        country: city.country,
        label: `${city.name}, ${getStateCodeStr(city.region)}`
      };
    }) || []
  );
};
