import React, { Fragment } from 'react';
import { oneOf, string } from 'prop-types';
import classnames from 'classnames';

import cardStyles from './Card.css';
import styles from './ContactLastTouch.css';

import { convertDateUtcToLocal, formatFriendlyDate } from '../../utils';

export const ContactLastTouch = props => {
  const {
    lastTouchPoint,
    consumerLastTouchPoint,
    touchType = 'action',
    type = 'basic',
    whenCreated,
    showHomeIntel = false
  } = props;

  const { dateUtc, description, objectTypeId } = lastTouchPoint || consumerLastTouchPoint || {};

  const getDisplayDate = () => {
    if (consumerLastTouchPoint) {
      return dateUtc || null;
    }
    return dateUtc || whenCreated;
  };

  const getDisplayDescription = lastTouchFriendlyDate => {
    if (consumerLastTouchPoint) {
      return description ? `${description} ${lastTouchFriendlyDate}` : null;
    }
    return description ? `${description} ${lastTouchFriendlyDate}` : `Lead created ${formatFriendlyDate(whenCreated)}`;
  };

  const displayDate = getDisplayDate();
  const localDate = convertDateUtcToLocal(displayDate);
  const lastTouchFriendlyDate = formatFriendlyDate(localDate);

  const lastTouchLabel = touchType === 'action' ? 'Last action' : 'Last resp.';
  const displayTouchLabel = !showHomeIntel
    ? localDate
      ? lastTouchLabel
      : null
    : (() => {
        if ([4, 16, 18, 19].includes(objectTypeId)) {
          return 'Last activity';
        }
        return 'Last response';
      })();
  const lastTouchTitle = getDisplayDescription(lastTouchFriendlyDate);

  const classes = classnames({
    [cardStyles.cardDetails]: true,
    [styles[`${type}Type`]]: true,
    [styles.container]: true
  });

  return (
    <div className={classes} title={lastTouchTitle}>
      {showHomeIntel ? (
        <div className={styles.lastTouchContainer}>
          {displayTouchLabel && <span className={styles.leftColumn}>{displayTouchLabel}</span>}
          <div className={styles.rightColumn}>
            {lastTouchFriendlyDate && <em className={styles.topRow}>{lastTouchFriendlyDate}</em>}
            <span className={styles.bottomRow}>{description}</span>
          </div>
        </div>
      ) : (
        <Fragment>
          {displayTouchLabel && <span className={styles.lastTouchLabel}>{displayTouchLabel}</span>}{' '}
          {lastTouchFriendlyDate && <em className={cardStyles.em}>{lastTouchFriendlyDate}</em>}
        </Fragment>
      )}
    </div>
  );
};

ContactLastTouch.propTypes = {
  dateUtc: string,
  description: string,
  touchType: oneOf(['action', 'response']),
  type: oneOf(['basic', 'followup']),
  whenCreated: string
};
