import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';

import { Button, Dropdown, FormField, FormFieldset, FormFooter, FormLabel, FormRow } from '../';
import { addTransactionParty, updateTransactionTasks } from '../../actions/transactions';
import { TRANSACTIONS_ADD_PARTY_FORM_TOGGLE } from '../../reducers/transactions';
import { useFormField } from '../../utils/hook';
import {
  getTransactionPartyRole,
  getTransactionPartyRoleOptions,
  TRANSACTION_PARTY_ROLES
} from '../../utils/transactions';
import { Form } from '../Form';
import formFieldStyles from '../FormField/FormField.css';
import { FormHint } from '../FormHint';
import { Lookup } from '../Lookup';
import { MessageBanner } from '../MessageBanner';
import { showAlert } from '../../actions';

const TransactionPartyForm = props => {
  const { bannerMessage } = props;
  const formRef = React.createRef();
  const dispatch = useDispatch();

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formIsValid, setFormIsValid] = useState(true);
  const [contactIdIsValid, setContactIdIsValid] = useState(true);
  const [selectedRoleIsValid, setSelectedRoleIsValid] = useState(true);

  const contacts = useSelector(state => state.contacts);
  const transactions = useSelector(state => state.transactions);
  const { partyTransactionId, entities } = transactions;

  const [selectedRole, setSelectedRole] = useState();
  const [contactId, setContactId] = useState('');
  const roleName = useFormField('');

  const role = getTransactionPartyRole(selectedRole);
  const displaySelected = role ? role.label : '';

  // This is a business role that if it's the first buyer or seller, it's always primary.
  const isFirstSeller = entities[partyTransactionId]?.transactionParties?.every(
    party =>
      party.roleId !== TRANSACTION_PARTY_ROLES.Seller.value &&
      party.roleId !== TRANSACTION_PARTY_ROLES.Primary_seller.value
  );

  const isFirstBuyer = entities[partyTransactionId]?.transactionParties?.every(
    party =>
      party.roleId !== TRANSACTION_PARTY_ROLES.Buyer.value &&
      party.roleId !== TRANSACTION_PARTY_ROLES.Primary_buyer.value
  );

  const transactionPartyValues = getTransactionPartyRoleOptions();

  // Remove seller and buyer if first time
  const tempPartyRole = isFirstSeller
    ? transactionPartyValues.filter(item => item.id !== TRANSACTION_PARTY_ROLES.Seller.value)
    : transactionPartyValues;

  const displayPartyRole = isFirstBuyer
    ? tempPartyRole.filter(item => item.id !== TRANSACTION_PARTY_ROLES.Buyer.value)
    : tempPartyRole;

  const isOtherRole = selectedRole === TRANSACTION_PARTY_ROLES.Other.value;

  const handleRoleChange = e => {
    e.preventDefault();
    const selected = transactionPartyValues.find(i => i.value === e.target.value);

    setSelectedRole(selected?.id);
  };

  const handleContactsChange = selectedEntity => {
    const entity = Object.values(selectedEntity);
    if (entity.length > 0) {
      const { id } = entity[0];
      setContactId(id);
    } else {
      setContactId('');
    }
  };

  const handleSubmit = async shouldRefreshTasks => {
    if (isOtherRole) {
      await dispatch(addTransactionParty(partyTransactionId, contactId, selectedRole, roleName.value)).then(() => {
        dispatch({
          type: TRANSACTIONS_ADD_PARTY_FORM_TOGGLE
        });
      });
    } else {
      await dispatch(addTransactionParty(partyTransactionId, contactId, selectedRole)).then(() => {
        dispatch({
          type: TRANSACTIONS_ADD_PARTY_FORM_TOGGLE
        });
      });
    }

    if (shouldRefreshTasks) {
      dispatch(updateTransactionTasks(partyTransactionId));
    }
  };

  const handleConfirmSubmit = e => {
    e.preventDefault();

    setFormIsSubmitting(true);
    const contactSelected = contactId !== '';
    const formIsValidCheck = formRef.current.checkValidity() && contactSelected && selectedRole != null;

    if (!formIsValidCheck) {
      setFormIsSubmitting(false);
      setFormIsValid(false);
      setContactIdIsValid(contactSelected);
      setSelectedRoleIsValid(selectedRole);
      if (isOtherRole) {
        roleName.setIsValid(roleName === '');
      }
      return;
    }
    dispatch(
      showAlert({
        message: 'Would you like to update tasks related to this transaction?',
        icon: 'refreshtask',
        iconSize: 'm',
        primaryButtonLabel: 'Yes',
        primaryButtonHandler: e => handleSubmit(e, true),
        secondaryButtonLabel: 'No',
        secondaryButtonHandler: e => handleSubmit(e, false)
      })
    );
  };

  return (
    <Fragment>
      <Form id="transactionPartyForm" ref={formRef}>
        {bannerMessage && <MessageBanner icon="error" message={bannerMessage} />}
        <FormFieldset label="Select a contact and assign their role">
          <FormRow>
            <div className={formFieldStyles.field}>
              <FormLabel htmlFor="transactionPartyContact" required>
                Contact
              </FormLabel>
              <Lookup
                changeHandler={handleContactsChange}
                entities={contacts.value}
                externalRef={formRef}
                formIsSubmitting={formIsSubmitting}
                id="transactionPartyContact"
                isMultiSelect={false}
                formIsValid={formIsValid}
                fieldIsValid={contactIdIsValid}
              />
            </div>
          </FormRow>
          <FormRow>
            <div className={formFieldStyles.field}>
              <FormLabel htmlFor="transactionPartyRole" required>
                Transaction Role
              </FormLabel>
              <Dropdown
                defaultValue=" "
                items={displayPartyRole}
                value={displaySelected}
                id="transactionPartyRole"
                onChange={handleRoleChange}
                fieldIsValid={selectedRoleIsValid}
                formIsValid={formIsValid}
              />
            </div>
            {isOtherRole && (
              <FormField
                fieldIsValid={roleName.isValid}
                formIsValid={formIsValid}
                id="otherRuleName"
                label="Role Name"
                onChange={roleName.onChange}
                value={roleName.value}
                type="input"
                required={isOtherRole}
              />
            )}
          </FormRow>
          <FormHint>
            <strong>Note:</strong> Select "Other" to add a new transaction party role.
          </FormHint>
        </FormFieldset>
      </Form>
      <FormFooter loading={false}>
        <Button
          ariaLabel="Save transaction party"
          type="button"
          label="Save Transaction Party"
          disabled={formIsSubmitting}
          onClick={handleConfirmSubmit}
          styleType="primary"
        />
      </FormFooter>
    </Fragment>
  );
};

export default TransactionPartyForm;

TransactionPartyForm.propTypes = {
  bannerMessage: string
};
