import React from 'react';
import { arrayOf, bool, object, string } from 'prop-types';
import { Link } from '@reach/router';
import classnames from 'classnames';

import { Icon } from '../';
import {
  DEFAULT_CONTACT_LIST_COLUMNS,
  DEFAULT_CONTACT_SORT_DIRECTIONS,
  getContactListSortDirection,
  getContactListType
} from '../../utils/contacts';
import { parseParamsStr, serializeToParamsStr } from '../../utils';

import styles from './ListHeader.css';

const DEFAULT_SORT_DIRECTIONS = {
  ...DEFAULT_CONTACT_SORT_DIRECTIONS
};

const getSortDirection = (id, currentSortDir, isCurrentSortColumn) => {
  if (isCurrentSortColumn) {
    return parseInt(currentSortDir) === 0 ? 1 : 0; // 0 = asc, 1 = dsc
  }

  return DEFAULT_SORT_DIRECTIONS[id];
};

const getSortIcon = (id, dir, isCurrentSortColumn) => {
  // if the column is the current column, we use the current location's dir param, or we use the default dir for the column.
  const desiredDirection = isCurrentSortColumn && dir ? parseInt(dir) : DEFAULT_SORT_DIRECTIONS[id];

  return desiredDirection === 0 ? 'arrowup' : 'arrowdown';
};

const handleScrollToTop = () => {
  window.scrollTo(0, 0);
};

export const ListHeader = props => {
  const { className, columns, isNested, location, sortableColumns } = props;
  const { pathname, search } = location || {};

  if (!columns) {
    return null;
  }

  const classes = classnames({
    [styles.header]: !className,
    [styles.isNested]: !!isNested,
    [className]: !!className
  });

  return (
    <header className={classes}>
      {columns.map(column => {
        const { id, label } = column;
        const isSortable = sortableColumns && sortableColumns.includes(id);

        const locationParams = parseParamsStr(search) || {};
        const { dir, q, sortBy: currentSortColumn, status } = locationParams;

        const listType = getContactListType(q, status);
        // Each list type has a default column;
        const defaultColumn = DEFAULT_CONTACT_LIST_COLUMNS[listType];

        // if there is a sortBy param, we check it against the id, but if no sortBy param, we check against the list type's default sortColumn.
        const isCurrentSortColumn = currentSortColumn ? currentSortColumn === id : defaultColumn === id;
        // if there is a dir param, we use that as the sort dir, but if not, we use the default for the column type
        const currentSortDir = getContactListSortDirection(dir, id);

        const newSortBy = id;
        const newDir = getSortDirection(id, currentSortDir, isCurrentSortColumn);

        const newParams = { ...locationParams, dir: newDir, sortBy: newSortBy };
        const newSearch = serializeToParamsStr(newParams);
        const url = isSortable ? `${pathname}?${newSearch}` : null;

        const sortIcon = getSortIcon(id, dir, isCurrentSortColumn);

        return (
          <div key={id} className={styles.column}>
            {isSortable ? (
              <Link to={url} className={styles.sortButton} title={`Sort by ${label}`} onClick={handleScrollToTop}>
                <label className={styles.label}>{label}</label>{' '}
                <Icon name={sortIcon} className={styles.sortIcon} isColored={isCurrentSortColumn} />
              </Link>
            ) : (
              <label className={styles.label}>{label}</label>
            )}
          </div>
        );
      })}
    </header>
  );
};

ListHeader.propTypes = {
  columns: arrayOf(object).isRequired,
  className: string,
  isNested: bool,
  location: object,
  sortableColumns: arrayOf(string)
};
