import React, { Fragment } from 'react';
import { bool, func, oneOf, string } from 'prop-types';
import classnames from 'classnames';

import { Icon } from '../';

import styles from './FormFileInput.css';
import buttonStyles from '../Button/Button.css';

export const FormFileInput = props => {
  const {
    ariaLabel,
    className,
    disabled,
    icon,
    iconSize = 's',
    id,
    label,
    onChange,
    showButton = true,
    styleType,
    ...attrs
  } = props;

  const isIconOnly = !label && !!icon; // has icon prop but no label
  const showTooltip = isIconOnly;

  if (showTooltip) {
    // If we are going to show the tooltip, we want to get rid of the title attribute to ensure it doesn't conflict;
    delete attrs.title;
  }

  const fileLabelClasses = classnames({
    [styles.fileInputLabel]: !className,
    [buttonStyles[`${styleType}Button`]]: !!styleType,
    [buttonStyles.withIcon]: !!label && !!icon, // has icon prop AND label
    [buttonStyles.iconOnly]: isIconOnly,
    [buttonStyles.showTooltip]: showTooltip,
    [buttonStyles.belowTooltipPos]: showTooltip, // has icon prop but no label
    [buttonStyles.disabled]: disabled,
    [className]: !!className
  });

  const iconClasses = classnames({
    [buttonStyles.iconWithLabel]: !!label && !!icon
  });

  // We clear the file input onClick, so it is empty when the user activates it.
  function clearFileInput(e) {
    e.target.value = '';
  }

  return (
    <Fragment>
      {showButton && (
        <label aria-label={ariaLabel} htmlFor={id} className={fileLabelClasses}>
          <Icon name={icon} size={iconSize} className={iconClasses} />
          {label}
        </label>
      )}
      <input
        id={id}
        disabled={disabled}
        type="file"
        onChange={onChange}
        onClick={clearFileInput}
        className={styles.fileInput}
        {...attrs}
      />
    </Fragment>
  );
};

FormFileInput.propTypes = {
  ariaLabel: string,
  className: string,
  disabled: bool,
  icon: string,
  iconSize: string,
  id: string.isRequired,
  label: string,
  onChange: func.isRequired,
  showButton: bool,
  styleType: oneOf(['primary', 'transparent', 'white', 'inline'])
};
