/** @module */
import { navigate } from '@reach/router';
import format from 'date-fns/format';
import { DATE_FORMATS } from '../constants';
import {
  SET_CALENDAR_MODAL,
  SET_CALENDAR_MODAL_DATA,
  SET_CALENDAR_VIEW,
  SET_HACK_DRILLDOWN_CLICKED,
  SET_CALENDAR_ASSIGNED_TO_ID
} from '../reducers/calendar';

/**
 * Sets key parameters of the calendar.
 * @param {Object} options - a data object that represents the view and date of the calendar.
 */
export const setCalendarView = options => {
  const { view, date } = options;
  return dispatch => {
    dispatch({
      type: SET_CALENDAR_VIEW,
      currentView: view,
      date
    });

    if (location.pathname !== `/calendar/${view}/${format(new Date(date), DATE_FORMATS.DATE_WITH_SLASH)}`) {
      navigate(`calendar/${view}/${format(new Date(date), DATE_FORMATS.DATE_WITH_SLASH)}`, { replace: true });
    }
  };
};

/**
 * Sets key parameters of the task detail modal.
 * @param {Object} options - a data object that represents task details.
 */
export const setCalendarModal = options => {
  const { modalContentType, modalData, showModal, showSettingsModal } = options;
  return dispatch => {
    dispatch({
      type: SET_CALENDAR_MODAL,
      modalContentType,
      modalData,
      showModal,
      showSettingsModal
    });
  };
};

/**
 * Sets the data of the task detail modal.
 * @param {Object} options - a data object that represents task details.
 */
export const setCalendarModalData = options => {
  const { modalData } = options || {};
  if (modalData != null) {
    return dispatch => {
      dispatch({
        type: SET_CALENDAR_MODAL_DATA,
        modalData
      });
    };
  }
};

export const hack_setDrillDownClicked = isClicked => {
  return dispatch => {
    dispatch({
      type: SET_HACK_DRILLDOWN_CLICKED,
      hack_drillDownClicked: isClicked
    });
  };
};

/**
 * Sets calendar assignedToId.
 * @param {String} assignedToId - string of assignedToId.
 */
export const setCalendarAssignedToId = assignedToId => {
  if (assignedToId != null) {
    return dispatch => {
      dispatch({
        type: SET_CALENDAR_ASSIGNED_TO_ID,
        assignedToId
      });
    };
  }
};
