import React from 'react';
import { func, oneOf, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { buildTestId } from '../../utils/tests';
import { clearMessage } from '../../actions/message';
import { Button, ButtonGroup, Icon } from '../';

import styles from './Toast.css';

export const Toast = props => {
  const {
    actionLabel,
    actionUrl,
    actionClick,
    className,
    closeHandler,
    message,
    type = 'error',
    testId = 'Toast',
    ...attrs
  } = props;
  const dispatch = useDispatch();

  if (!message) {
    return null;
  }

  const hasActionButton = Boolean(actionLabel) && (Boolean(actionUrl) || Boolean(actionClick));

  const actionCallback = () => {
    if (actionClick) {
      actionClick();
    }
    dispatch(clearMessage());
  };

  const classes = classnames({
    [styles.toast]: !className,
    [styles[`${type}`]]: !!type,
    [className]: !!className
  });

  return (
    <div role="alert" className={classes} {...attrs}>
      <Icon name={type} size="m" />
      <div data-testid={buildTestId(testId, 'message')} className={styles.message}>
        {message}
      </div>
      <ButtonGroup>
        {hasActionButton && (
          <Button
            ariaLabel={actionLabel}
            label={actionLabel}
            onClick={actionCallback}
            styleType="transparent"
            url={actionUrl}
          />
        )}
        <Button ariaLabel="Close alert" icon="close" onClick={closeHandler} size="xs" />
      </ButtonGroup>
    </div>
  );
};

Toast.propTypes = {
  actionLabel: string,
  actionUrl: string,
  className: string,
  closeHandler: func,
  message: string.isRequired,
  type: oneOf(['success', 'error']),
  actionClick: func
};
