/** @module */

import { navigate } from '@reach/router';
import { showMessage } from '../actions/message';
import { DEFAULT_PAGING_SIZE } from '../constants';
import {
  CLEAR_PREVIEW_LISTINGS,
  GET_MARKET_SNAPSHOT_DETAILS_FAIL,
  GET_MARKET_SNAPSHOT_DETAILS_PAGING,
  GET_MARKET_SNAPSHOT_DETAILS_REQUEST,
  GET_MARKET_SNAPSHOT_DETAILS_SUCCESS,
  SAVE_PREVIEW_LISTINGS,
  SET_MARKET_SNAPSHOT_STATUS,
  SET_PAGE_INDEX,
  SNAPSHOT_ACCOUNT_DETAILS,
  SNAPSHOT_SETTINGS,
  UPDATE_AGENT_SETTING_FAIL,
  UPDATE_AGENT_SETTING_SUCCESS,
  UPDATE_LISTING_ALERT
} from '../reducers/snapshot';
import { request, requestError, trackEvent } from '../utils';
import { getRequestIdentifier } from '../utils/request';
import { filterListings } from '../utils/snapshot';
import { clearAlert, showAlert } from './alert';
import { getContactDetail } from './contacts';
import { clearDrawer } from './drawer';
import { clearMessage } from './message';

const GET_REPORTS_DELAY = 3000;

/**
 * Gets market snapshot account details.
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to fetch a snapshot account details
 */
export const getSnapshotAccountDetails = options => {
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      params: options,
      path: 'ms/getagentaccountdetail',
      shouldRemoveDefaultHeaders: true
    };

    try {
      const { data } = await request(requestOptions);

      if (!data) {
        return null;
      }

      const details = { tmkAgentId: data.tmk_agent_id, isAgentActive: data.is_agent_active };

      dispatch({
        type: SNAPSHOT_ACCOUNT_DETAILS,
        msAccountDetails: details
      });

      return details;
    } catch (error) {
      const { response } = error;
      const { status, data } = response || {};
      const { ErrorMsg: message } = data || {};

      if (status === 500 && message === 'TMK agent ID not found') {
        // We don't want to raise an error when a user doesn't have a license.
        console.error(message);
        return null;
      }

      requestError({ ...error }, dispatch);
      return null;
    }
  };
};

/**
 * Verify agents TMK settings and provide default if needed.
 * @param {Object} settings - settings fetched
 */
const verifyAgentSettings = settings => {
  const { market_snapshot_setting, listing_alert_setting, ...others } = settings;

  const boards = settings.boards.map(board => {
    const { listing_alert_setting, ...otherSettings } = board;

    // sometimes an agent hasn't completed their TMK settings, so we need to provide a default for listing alerts
    return {
      listing_alert_setting: listing_alert_setting
        ? listing_alert_setting
        : { enable_listing_alert: true, new_listing: true, sold: true, price_change: true },
      ...otherSettings
    };
  });

  return {
    ...others,
    listing_alert_setting,
    market_snapshot_setting,
    boards
  };
};

/**
 * Gets market snapshot settings.
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to fetch a snapshot agent settings
 */
export const getSnapshotSettings = options => {
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      params: options,
      path: 'ms/getagentsetting',
      shouldRemoveDefaultHeaders: true
    };

    try {
      const { data } = await request(requestOptions);
      const verifiedSettings = verifyAgentSettings(data);

      dispatch({
        type: SNAPSHOT_SETTINGS,
        msAgentSettings: verifiedSettings
      });
    } catch (error) {
      const { response } = error;

      if (!response) {
        // We need to return out for request cancellations.
        requestError(error, dispatch);
        return;
      }

      const { data, status } = response;
      const { Message: message, ExceptionMessage: exceptionMessage } = data || {};

      if (status === 500 && message && message.startsWith('Agent has no Market Snapshot')) {
        // We don't want to raise an error when a user doesn't have settings yet.
        // ToDo: Add a better first time user flow.
        console.error(message);
        return null;
      }

      if (status === 500 && message && exceptionMessage.startsWith('The record is deleted by other user')) {
        // We don't want to raise an error when a user's market snapshot has been deleted
        // ToDo: Add a better first time user flow.
        console.error(`getSnapshotSettings: ${exceptionMessage}`);
        return null;
      }

      requestError({ message: message || exceptionMessage }, dispatch);
    }
  };
};

export const setMarketSnapshotStatus = isSnapshotOnline => {
  return dispatch => {
    dispatch({
      type: SET_MARKET_SNAPSHOT_STATUS,
      isSnapshotOnline
    });

    requestError({ message: 'Market Snapshot is currently offline.' }, dispatch, false);
  };
};

/**
 * Gets market snapshot account Reports.
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to fetch a snapshot account reports
 */
export const getMarketSnapshotReports = options => {
  const {
    contactId,
    agentId,
    startDate,
    endDate,
    msStatus,
    pageSize = DEFAULT_PAGING_SIZE,
    pageIndex = 1, // starts at 1,
    forceRefresh = false,
    shouldBeCached = true,
    group
  } = options || {};
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      forceRefresh,
      path: 'ms/getmsdetail',
      params: {
        contactId,
        agentId,
        startDate,
        endDate,
        msStatus,
        // agent MS paging was done via `pageSize` and `pageIndex`
        pageSize: contactId ? null : pageSize,
        pageIndex: contactId ? null : pageIndex,
        // contact MS paging was done via `limit`
        limit: contactId ? pageIndex * DEFAULT_PAGING_SIZE : null
      },
      shouldBeCached,
      shouldRemoveDefaultHeaders: true
    };

    if (!agentId) {
      dispatch(setMarketSnapshotStatus(false));
    }

    try {
      dispatch({
        type: GET_MARKET_SNAPSHOT_DETAILS_REQUEST
      });

      const { data } = await request(requestOptions);

      dispatch({
        type: pageIndex !== 1 ? GET_MARKET_SNAPSHOT_DETAILS_PAGING : GET_MARKET_SNAPSHOT_DETAILS_SUCCESS,
        data,
        id: group
      });
    } catch (error) {
      dispatch({
        type: GET_MARKET_SNAPSHOT_DETAILS_FAIL
      });

      return null;
    }
  };
};

export const updateSnapshotAgentSetting = options => {
  const {
    autoSendMs,
    receiveWeeklySummaryEmail,
    notifyConsumerLikesProperty,
    notifyConsumerViewMsReport,
    buyerSold,
    buyerPending,
    buyerExpired,
    sellerSold,
    sellerPending,
    sellerExpired,
    newListing,
    justSold,
    priceChange,
    isDailyAlert,
    tmkAgentId
  } = options;

  const listAlertSettings = {
    new_listing: newListing.value,
    sold: justSold.value,
    price_change: priceChange.value,
    is_daily_alert: isDailyAlert.value.toString() === 'true'
  };

  const msSettings = {
    auto_send_ms: autoSendMs.value,
    receive_weekly_summary_email: receiveWeeklySummaryEmail.value,
    notify_consumer_likes_property: notifyConsumerLikesProperty.value,
    notify_consumer_view_ms_report: notifyConsumerViewMsReport.value,
    include_sold_listings_4_buyers: buyerSold.value,
    include_sold_listings_4_sellers: sellerSold.value,
    include_expired_listings_4_buyers: buyerExpired.value,
    include_expired_listings_4_sellers: sellerExpired.value,
    include_pending_listings_4_buyers: buyerPending.value,
    include_pending_listings_4_sellers: sellerPending.value
  };

  const payload = {
    tmk_agent_id: tmkAgentId,
    market_snapshot_setting: msSettings,
    listing_alert_setting: listAlertSettings
  };

  const errorMessage = "There's a problem updating Market Snapshot settings.";
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'snapshot',
      baseUrlKey: 'snapshot',
      method: 'POST',
      path: `UpdateAgentSetting`,
      payload: payload,
      shouldBeCached: false,
      shouldRemoveDefaultHeaders: true
    };
    try {
      const { data } = await request(requestOptions);
      const { ErrorMsg, StatusCode } = data;

      if (ErrorMsg) {
        throw { message: ErrorMsg };
      }

      if (StatusCode === 200) {
        dispatch(showMessage({ message: 'Market Snapshot settings saved successfully.', type: 'success' }, true));
        dispatch({
          type: UPDATE_AGENT_SETTING_SUCCESS,
          listAlertSettings,
          msSettings
        });
        // TMk backend will update some fields accroding to the update.
        dispatch(getSnapshotSettings({ agentId: tmkAgentId }));
      } else {
        dispatch(showMessage({ message: errorMessage, type: 'error' }, true));
        dispatch({
          type: UPDATE_AGENT_SETTING_FAIL
        });
      }
      return true;
    } catch (error) {
      requestError(errorMessage, dispatch);
      return false;
    }
  };
};

/**
 * Create Market Snapshot for hestia enabled MLS board.
 * @param {String} snapshotData - market data to send snapshot
 */
export const sendMarketSnapshot = (snapshotData, contactId, agentId, isUpdateMode, consumerId, isAgent, options) => {
  const payload = isUpdateMode ? { ...snapshotData, is_modify_ms: true, consumer_id: consumerId } : snapshotData;

  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'POST',
      path: `ms/createms`,
      payload: JSON.stringify(payload),
      shouldBeCached: false,
      shouldRemoveDefaultHeaders: true
    };

    try {
      const { data } = await request(requestOptions);
      const { message, status_code } = data;

      if (status_code === 200) {
        dispatch(showMessage({ message: 'Market Snapshot request sent successfully.', type: 'success' }, true)); // useTimer set to true
        setTimeout(() => {
          /*
            Note: This timeout is hacky, but the MS snapshot create is slow.
          */
          if (isAgent === 'contactSnapshotsList') {
            dispatch(
              getMarketSnapshotReports({
                contactId,
                agentId,
                group: options?.group,
                forceRefresh: true,
                shouldBeCached: false
              })
            );
          } else {
            dispatch(
              getMarketSnapshotReports({ ...options, agentId, pageIndex: 1, forceRefresh: true, shouldBeCached: false })
            );
          }
        }, GET_REPORTS_DELAY);
        return true;
      } else {
        dispatch(showMessage({ message, type: 'error' }, true));
        return false;
      }
    } catch (error) {
      const { AllowUserPrompt = false } = error.response.data;

      if (AllowUserPrompt) {
        dispatch(
          showAlert({
            message: `The Market Snapshot you're trying to generate has an address not known to our system. Would you like to proceed with a Market Snapshot anyway?`,
            primaryButtonHandler: () =>
              dispatch(
                sendMarketSnapshot(
                  { ...snapshotData, valid_address: 'true' },
                  contactId,
                  agentId,
                  isUpdateMode,
                  consumerId,
                  isAgent,
                  options
                )
              ).then(() => {
                dispatch(clearAlert());
                dispatch(clearDrawer());
                trackEvent('snapshotForm', 'Generate', 'Generate anyway');
              }),
            primaryButtonLabel: 'Yes, generate anyway',
            secondaryButtonLabel: 'No',
            icon: 'tasks',
            iconSize: 'm'
          }),
          true
        );
      }

      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Start market snapshot.
 * If success, show a success message to the user.
 * If fails, shows error message.
 * @param {Object} reportInstanceId - options to start a snapshot account
 */
export const startMarketSnapshot = (reportInstanceId, contactId, isAgent, options) => {
  const errorMsg = 'Your request to start the Market Snapshot failed.';

  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'POST',
      path: 'ms/startms',
      payload: JSON.stringify({
        report_instance_id: reportInstanceId
      }),
      shouldBeCached: false,
      shouldRemoveDefaultHeaders: true
    };

    try {
      const { data, status } = await request(requestOptions);

      const { msg } = data;

      if (status !== 200) {
        throw new Error(errorMsg);
      }
      // fetch latest ms details upon success.
      dispatch(getMarketSnapshotReports(options));

      dispatch(showMessage({ message: msg, type: 'success' }, true));
    } catch (error) {
      requestError({ message: errorMsg }, dispatch);
    }
  };
};

/**
 * Stop market snapshot.
 * If success, show a success message to the user.
 * If fails, shows error message.
 * @param {Object} reportInstanceId - options to start a snapshot account
 */
export const stopMarketSnapshot = (reportInstanceId, contactId, isAgent, options) => {
  const errorMsg = 'Your request to stop the Market Snapshot failed.';

  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'POST',
      path: 'ms/stopms',
      payload: JSON.stringify({
        report_instance_id: reportInstanceId
      }),
      shouldBeCached: false,
      shouldRemoveDefaultHeaders: true
    };

    try {
      const { data, status } = await request(requestOptions);
      const { msg } = data;
      if (status !== 200) {
        throw new Error(errorMsg);
      }

      dispatch(getMarketSnapshotReports(options));

      dispatch(showMessage({ message: msg, type: 'success' }, true));
    } catch (error) {
      requestError({ message: errorMsg }, dispatch);
    }
  };
};

/**
 * Delete market snapshot.
 * If success, show a success message to the user.
 * If fails, shows error message.
 * @param {Object} reportInstanceId - options to start a snapshot account
 */
export const deleteMarketSnapshot = (reportInstanceId, contactId, isAgent, options) => {
  const errorMsg = 'Your request to delete the Market Snapshot failed.';

  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'POST',
      path: 'ms/deletems',
      payload: JSON.stringify({
        report_instance_id: reportInstanceId
      }),
      shouldBeCached: false,
      shouldRemoveDefaultHeaders: true
    };

    try {
      const { data, status } = await request(requestOptions);
      const { msg } = data;
      if (status !== 200) {
        throw new Error(errorMsg);
      }

      dispatch(getMarketSnapshotReports(options));

      dispatch(showMessage({ message: msg, type: 'success' }, true));

      return true; // success
    } catch (error) {
      requestError({ message: errorMsg }, dispatch);
    }
  };
};

/**
 * Update only listing alert
 * @param {object} options payload
 * @param {string} contactId id to update redux state
 * @param {string} reportId id to update redux state
 */
export const updateListingAlert = (options, contactId, reportId, isAgent) => {
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'POST',
      path: `ms/updatelistingalert`,
      payload: JSON.stringify(options),
      shouldBeCached: false
    };

    try {
      dispatch(clearMessage());
      const { data: response } = await request(requestOptions);
      const { status_code, msg } = response;
      if (status_code !== 200 || msg != null) {
        throw { message: 'error' };
      }
      dispatch({
        type: UPDATE_LISTING_ALERT,
        data: options,
        reportId,
        contactId: isAgent ? 'myReports' : contactId
      });
      dispatch(showMessage({ message: 'Listing alert was updated successfully.', type: 'success' }, true));
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * This function calls the contactDetail endpoint to verify if lead_id from tmk exist in 8i
 * @param {String} contactId
 */
export const handleContactRedirect = (contactId, reportId) => {
  return async dispatch => {
    try {
      dispatch(getContactDetail({ id: contactId, suppressWarning: true })).then(isFound => {
        if (isFound != null) {
          navigate(`/contacts/${contactId}/snapshots/${reportId}`);
        }
        // Don't redirect if not found
      });
    } catch (error) {}
  };
};

export const clearPreviewListings = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_PREVIEW_LISTINGS });
  };
};

/**
 * Get preview listings, send 4 requests for each status and set current listings for display in redux
 * @param {Object} payload
 */
export const getPreviewListings = payload => {
  const path = `ms/previewlistings`;

  const requestId = getRequestIdentifier({ path, payload });

  const requestOptions = {
    baseUrlKey: 'api',
    method: 'POST',
    path,
    shouldBeCached: false,
    payload
  };

  return async dispatch => {
    dispatch(clearMessage());
    dispatch(clearPreviewListings());

    try {
      const response = await request(requestOptions);

      const { data } = response;

      let allPreviews = [];

      data.forEach(status => {
        allPreviews = [...allPreviews, ...status.data.results];
      });

      dispatch({
        type: SAVE_PREVIEW_LISTINGS,
        previewListingData: { requestId, allPreviews: filterListings(allPreviews) }
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Set page index for snapshot dashboard and contact
 * @param {Number} pageIndex
 */
export const setPageIndex = (pageIndex, id) => {
  return async dispatch => {
    dispatch({
      type: SET_PAGE_INDEX,
      pageIndex,
      id
    });
  };
};
/**
 * Get MS LS sent counts based on dates
 */
export const getMSLSCounts = options => {
  const { tpoAgentId, startDate, endDate } = options;
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      params: {
        tpoAgentId,
        startDate,
        endDate
      },
      path: 'ms/getMSLSCounts',
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      if (!data) {
        return null;
      }
      return data;
    } catch (error) {
      requestError({ ...error }, dispatch);
      return Promise.reject(error);
    }
  };
};
