/**
 * Get an array of Options containing all the team members in the object passed in the parameter.
 * The result of this function can be used in Dropdown component as the "items" prop.
 * There are essentially two team lists - one with all team members, that includes agents and assistants, and another which includes just agents.
 * Contacts and transactions can only be assigned to agents and use the group 'agents'.
 * Tasks and task plan items can be assigned to either agents or assistants and use the group 'all';.
 * @param {Object} team Team in the state
 * @param {String} group Team group in state - all is default.
 * @param {{ assignedTo: String[] }} [team.groups]
 * @param {{ [id]: Object }} [team.entities]
 * @param {{id: String, value: String}[]} initialOptions Initial options to use in the options array. Team options will be concatenated to this array.
 */
export const getAssignedToOptions = (team, group = 'all', initialOptions = []) => {
  return (
    team?.groups?.[group]?.reduce((acc, teamMember) => {
      const memberInfo = team.entities[teamMember];
      acc.push({
        id: memberInfo.id,
        value: `${memberInfo.firstName} ${memberInfo.lastName}`
      });

      return acc;
    }, initialOptions) || initialOptions
  );
};

/**
 * Get an array of Options containing all the team members in the object passed in the parameter.
 * The result of this function can be used in Dropdown component as the "items" prop.
 * There are essentially two team lists - one with all team members, that includes agents and assistants, and another which includes just agents.
 * Contacts and transactions can only be assigned to agents and use the group 'agents'.
 * Tasks and task plan items can be assigned to either agents or assistants and use the group 'all';.
 * @param {Object} team Team in the state
 * @param {String} group Team group in state - all is default.
 * @param {{ assignedTo: String[] }} [team.groups]
 * @param {{ [id]: Object }} [team.entities]
 * @param {{id: String, value: String}[]} initialOptions Initial options to use in the options array. Team options will be concatenated to this array.
 */
export const listTeamMembers = (team, group = 'all') => {
  return team?.groups?.[group]?.reduce((acc, teamMember) => {
    const memberInfo = team.entities[teamMember];
    acc.push({
      id: memberInfo.id,
      firstName: memberInfo.firstName,
      lastName: memberInfo.lastName
    });

    return acc;
  });
};

/**
 * Checks to see if account is a member of a team account
 * @param {array} members - The id of team members within the `all` group
 * @returns {bool}
 */
export const checkIfIsOnTeamAccount = members => {
  return members?.length > 1;
};

/**
 * Get list of values used for assignedTo dropdown
 * @param {Object[]]} teamList
 * @param {String} mountedAssignTo assign to value from url
 * @param {String} userId
 */
export const getAssignedToValue = (teamList, mountedAssignTo, userId) => {
  return mountedAssignTo
    ? teamList?.filter(item => item.id === mountedAssignTo)?.[0]?.value
    : teamList?.filter(item => item.id === userId)?.[0]?.value;
};

/**
 *
 * @param {String} id userId
 * @param {Object} entities team member entities
 * @returns {String} responsibleAgentId if it exists
 */
export const findResponsibleAgentId = (id, entities) => {
  if (entities.hasOwnProperty(id)) {
    return entities[id].responsibleAgentId;
  } else {
    return null; // Key not found
  }
};
