/** @module */
import format from 'date-fns/format';

import { showMessage } from '../actions/message';
import { DATE_FORMATS, DEFAULT_PAGING_SIZE } from '../constants';
import { DATE_RANGES_FROM_NOW } from '../constants/preferences';
import {
  IMPORTANT_DATES_TOGGLE_FORM,
  IMPORTANT_DATES_CURRENT_GROUP,
  IMPORTANT_DATES_SET,
  IMPORTANT_DATES_DELETE,
  IMPORTANT_DATES_LOADING
} from '../reducers/importantDates';
import { getDateFromToday, request, requestError } from '../utils';
import { ENTITY_TYPES } from '../utils/notes';
import { DEFAULT_DUE_PERIOD } from '../utils/tasks';

export const getImportantDates = options => {
  const {
    dir = 0, // 0 - asc - 1 desc
    recordKeyId, // last record recordKeyId on the previous page
    pageSize = DEFAULT_PAGING_SIZE,
    startDate = format(
      getDateFromToday(DATE_RANGES_FROM_NOW[DEFAULT_DUE_PERIOD['importantDates']].dateFrom),
      DATE_FORMATS.ISO_DATE
    ),
    endDate = format(
      getDateFromToday(DATE_RANGES_FROM_NOW[DEFAULT_DUE_PERIOD['importantDates']].dateTo),
      DATE_FORMATS.ISO_DATE
    ),
    sortBy = 2, // 1 - description, 2 - date, 3 - contact name
    setCurrentGroup = true
  } = options || {};

  return async dispatch => {
    const options = {
      apiServiceType: 'importantDates',
      method: 'GET',
      params: {
        dir,
        recordKeyId,
        pageSize,
        startDate,
        endDate,
        sortBy
      },
      path: 'list',
      shouldBeCached: false
    };

    try {
      if (setCurrentGroup) {
        dispatch({
          type: IMPORTANT_DATES_LOADING,
          isLoading: true
        });
      }

      const queryKey = 'important::current';

      const importantDatesRequest = await request(options).catch(() => {
        // catch when we abort the request
        if (setCurrentGroup) {
          dispatch({
            type: IMPORTANT_DATES_CURRENT_GROUP,
            query: queryKey,
            isLoading: false
          });
        }
      });

      if (!importantDatesRequest) {
        return;
      }

      const { data } = importantDatesRequest;

      const { exception, records } = data;

      if (exception) {
        throw exception;
      }

      const donePaging = records.length < pageSize;

      dispatch({
        type: IMPORTANT_DATES_SET,
        data: {
          records
        },
        query: queryKey,
        isPaging: !!recordKeyId, // we set isPaging to true if there is an id set in options
        isLoading: false,
        setCurrentGroup,
        donePaging
      });
    } catch (error) {
      dispatch({
        type: IMPORTANT_DATES_LOADING,
        isLoading: false
      });

      requestError(error, dispatch);
    }
  };
};

const getMappedRecords = data => {
  return data.map(date => {
    const { linked_entity_id, reminder_date, reminder_id, ...otherKeys } = date;

    return {
      contactId: linked_entity_id,
      importantDate: reminder_date,
      id: reminder_id,
      ...otherKeys
    };
  });
};

export const getImportantDatesForContact = options => {
  const today = format(new Date(), DATE_FORMATS.ISO_DATE);

  const {
    entityId,
    entityType = 4, // is contact
    currentDate = today,
    forceRefresh = false,
    setCurrentGroup = true
  } = options || {};

  return async dispatch => {
    const options = {
      apiServiceType: 'importantDatesLegacy',
      forceRefresh,
      method: 'GET',
      params: {
        entityId,
        entityType,
        currentDate
      },
      path: 'getimportantdates'
    };

    try {
      if (setCurrentGroup) {
        dispatch({
          type: IMPORTANT_DATES_LOADING,
          isLoading: true
        });
      }

      const queryKey = `important::${entityId}`;

      const importantDatesRequest = await request(options).catch(() => {
        // catch when we abort the request
        if (setCurrentGroup) {
          dispatch({
            type: IMPORTANT_DATES_CURRENT_GROUP,
            query: queryKey,
            isLoading: false
          });
        }
      });

      if (!importantDatesRequest) {
        return;
      }

      const { data } = importantDatesRequest;

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      const mappedRecords = getMappedRecords(data);

      dispatch({
        type: IMPORTANT_DATES_SET,
        data: {
          records: mappedRecords
        },
        query: queryKey,
        isLoading: false,
        setCurrentGroup,
        donePaging: true
      });
    } catch (error) {
      dispatch({
        type: IMPORTANT_DATES_LOADING,
        isLoading: false
      });

      requestError(error, dispatch);
    }
  };
};

export const getNextImportantDate = options => {
  const today = format(new Date(), DATE_FORMATS.ISO_DATE);

  const {
    entityId,
    entityType = 4, // is contact
    currentDate = today,
    forceRefresh = false,
    setCurrentGroup = false
  } = options || {};

  return async dispatch => {
    const options = {
      apiServiceType: 'importantDatesLegacy',
      forceRefresh,
      method: 'GET',
      params: {
        entityId,
        entityType,
        currentDate
      },
      path: 'getnextimportantdate'
    };

    try {
      const queryKey = `important::next::${entityId}`;

      const importantDatesRequest = await request(options);

      if (!importantDatesRequest) {
        return;
      }

      const { data } = importantDatesRequest;

      if (!data) {
        // this API returns nothing when there are no important dates.
        dispatch({
          type: IMPORTANT_DATES_SET,
          data: {
            records: []
          },
          query: queryKey,
          isLoading: false,
          setCurrentGroup
        });
        return;
      }

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      const mappedRecords = getMappedRecords([data]);

      dispatch({
        type: IMPORTANT_DATES_SET,
        data: {
          records: mappedRecords
        },
        query: queryKey,
        isLoading: false,
        setCurrentGroup
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const saveImportantDate = (options, currentGroup) => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'importantDatesLegacy',
      method: 'POST',
      path: options.reminder_id ? 'update' : 'add',
      payload: options,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      const mappedRecords = getMappedRecords([data]);

      dispatch({
        type: IMPORTANT_DATES_SET,
        data: {
          records: mappedRecords
        },
        query: currentGroup,
        isLoading: false,
        setCurrentGroup: true
      });

      // we make sure to get the next important date for the contact so that it is up to date.
      dispatch(
        getNextImportantDate({
          entityId: options.linked_entity_id,
          forceRefresh: true
        })
      );

      dispatch(showMessage({ message: 'Important date saved successfully.', type: 'success' }, true)); // useTimer set to true

      return true;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

export const deleteImportantDateLocally = (options, currentGroup) => {
  const { id, entityId } = options;

  return dispatch => {
    dispatch({
      type: IMPORTANT_DATES_DELETE,
      data: {
        id,
        group: currentGroup,
        entityId
      }
    });
  };
};

export const deleteImportantDate = (options, currentGroup) => {
  const { id, entityId, entityType = ENTITY_TYPES['contact'] } = options;

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'importantDatesLegacy',
      method: 'POST',
      params: {
        // This API is weird and takes query params and a POST
        reminderId: id,
        entityId,
        entityType
      },
      path: 'delete',
      shouldBeCached: false
    };
    try {
      const { data } = await request(requestOptions);
      const { exception } = data;
      if (exception) {
        throw exception;
      }

      if (currentGroup !== undefined) {
        dispatch(deleteImportantDateLocally(options, currentGroup));
      }

      dispatch(showMessage({ message: 'Important date deleted successfully.', type: 'success' }, true)); // useTimer set to true
      return true;
    } catch (error) {
      requestError(error, dispatch);
      return false;
    }
  };
};

export const toggleImportantDatesForm = id => {
  return dispatch => {
    dispatch({
      type: IMPORTANT_DATES_TOGGLE_FORM,
      data: {
        id
      }
    });
  };
};
