/** @module */
import { deleteFromCollection, deleteFromGroup, getMergedEntities, getMergedSetOfIds } from '../utils/collections';

export const TRANSACTIONS_DOCUMENTS_SET = 'TRANSACTIONS_DOCUMENTS_SET';
export const TRANSACTIONS_DOCUMENTS_LOADING = 'TRANSACTIONS_DOCUMENTS_LOADING';
export const TRANSACTIONS_DOCUMENTS_FETCH_SUCCESS = 'TRANSACTIONS_DOCUMENTS_FETCH_SUCCESS';
export const TRANSACTIONS_DOCUMENTS_FETCH_FAIL = 'TRANSACTIONS_DOCUMENTS_FETCH_FAIL';
export const TRANSACTIONS_DOCUMENTS_MODAL_SET = 'TRANSACTIONS_DOCUMENTS_MODAL_SET';
export const TRANSACTIONS_DOCUMENTS_DELETE = 'TRANSACTIONS_DOCUMENTS_DELETE';
export const TRANSACTIONS_DOCUMENTS_SAVE = 'TRANSACTIONS_DOCUMENTS_SAVE';

export const initialState = {
  currentGroup: '0',
  documentToEdit: null,
  entities: {},
  groups: {},
  isLoading: false,
  showModal: false
};

export const transactionDocumentsReducer = (state = initialState, action = {}) => {
  const {
    documentToEdit,
    group,
    idToDelete,
    idToUpdate,
    showModal,
    transactionDocumentRecords,
    savedTransactionDocument,
    type = 'default'
  } = action;

  const idKey = 'documentId';
  switch (type) {
    case TRANSACTIONS_DOCUMENTS_MODAL_SET:
      return {
        ...state,
        showModal,
        documentToEdit
      };
    case TRANSACTIONS_DOCUMENTS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case TRANSACTIONS_DOCUMENTS_SAVE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [idToUpdate]: savedTransactionDocument
        }
      };

    case TRANSACTIONS_DOCUMENTS_FETCH_SUCCESS:
      const mergedEntities = getMergedEntities(state.entities, transactionDocumentRecords, {
        idKey,
        ignorePreexisting: true
      });
      return {
        ...state,
        entities: mergedEntities,
        groups: {
          ...state.groups,
          [group]: getMergedSetOfIds(state.groups[group], transactionDocumentRecords, {
            idKey,
            entities: mergedEntities
          })
        },
        currentGroup: group,
        isLoading: false
      };
    case TRANSACTIONS_DOCUMENTS_FETCH_FAIL:
      return {
        ...state,
        isLoading: false
      };

    case TRANSACTIONS_DOCUMENTS_DELETE:
      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, idToDelete)
        },
        groups: Object.keys(state.groups).reduce((acc, group) => {
          acc[group] = deleteFromGroup(state.groups[group], idToDelete);

          return acc;
        }, {})
      };
    default:
      return state;
  }
};
