import React, { Fragment } from 'react';
import { string } from 'prop-types';
import { Form } from '../../Form';
import { FormRow } from '../../FormRow';
import { Button } from '../../Button';
import { FormLabel } from '../../FormLabel';
import styles from './AIEula.css';
import { updateEula } from '../../../actions';
import { useDispatch } from 'react-redux';
import { buildTestId, openPopup } from '../../../utils';
import documentStyles from '../../Document/Document.css';
import { AI_TEST_ID } from './AIDialog';

const OPENAI_TOC_URL = 'https://openai.com/policies/terms-of-use';
export const AIEula = () => {
  const dispatch = useDispatch();
  const handleSubmit = e => {
    e.preventDefault();

    dispatch(updateEula({ feature: 'AI', accepted: true }));
  };

  return (
    <Fragment>
      <Form id="eulaForm" className={styles.form}>
        <FormRow>
          <FormLabel className={styles.content} htmlFor="aieula">
            By using Top Producer AI Author, you acknowledge and agree that the output may contain errors,
            inconsistencies, biases or outdated information. AI technology can make mistakes. Review all content before
            sending. Use is subject to the underlying terms and conditions of ChatGPT, available at{' '}
            <a
              href={OPENAI_TOC_URL}
              target="_blank"
              rel="noopener"
              className={documentStyles.link}
              onClick={e => {
                e.preventDefault();
                openPopup(OPENAI_TOC_URL);
              }}
              data-cy={buildTestId(AI_TEST_ID, 'openaiTOC')}
            >
              {OPENAI_TOC_URL}.
            </a>
          </FormLabel>
        </FormRow>
        <FormRow className={styles.formRow}>
          <Button
            size={'xl'}
            onClick={handleSubmit}
            ariaLabel="I Agree"
            label="I Agree"
            styleType="primary"
            type="submit"
            data-cy={buildTestId(AI_TEST_ID, 'AgreeEulaButton')}
          />
        </FormRow>
      </Form>
    </Fragment>
  );
};

AIEula.propTypes = {
  className: string
};
