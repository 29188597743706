import classnames from 'classnames';
import { format } from 'date-fns';
import { bool, func, object, oneOf, string } from 'prop-types';
import React, { Fragment, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Tag, TagGroup, FormCheckBox } from '../';
import { clearAlert, confirmDeleteHandler, showAlert } from '../../actions';
import { DATE_FORMATS, TASK_PENDING_DATE_LABEL, TRANSACTION_FUTURE_DATE_LABEL } from '../../constants';

import {
  checkEmailIntegrationSetup,
  cleanPhoneNumber,
  formatFriendlyDueDate,
  formatLongDate,
  getContactDisplayName,
  getTaskDescription,
  getTaskPriorityLabel,
  getTaskTagLabels,
  getTimeRange,
  TASK_TYPES
} from '../../utils';
import { trackEvent } from '../../utils/analytics';
import { createEntityTagUrl } from '../../utils/contacts';
import { isFutureTransactionDate, isUntimedEvent, parseISODate } from '../../utils/dates';
import { getDisplayTaskIcon, getDisplayTaskPreviewIcon, getDisplayTaskType } from '../../utils/tasks';
import { ButtonGroup } from '../Button';
import { Loading } from '../Loading';
import { PriorityIndicator } from '../PriorityIndicator';
import { Card } from './';
import cardStyles from './Card.css';
import { TIME_RANGES } from '../../utils';
import styles from './TaskCard.css';
import { useCallback } from 'react';
import { navigate } from '@reach/router';
import { checkIsFromDashboard } from '../../utils/urls';

const TaskDetailSummary = lazy(() => import('../../pages/Tasks/TaskDetail/TaskDetailSummary'));

export const MeetingTime = props => {
  const { className, dateLong, endOfEvent, hasTime, startOfEvent } = props;

  if (!hasTime) {
    return null;
  }

  const displayTimeRange =
    startOfEvent === endOfEvent
      ? format(parseISODate(startOfEvent), DATE_FORMATS.SHORT_TIME)
      : getTimeRange(startOfEvent, endOfEvent);

  return (
    <div className={className} title={dateLong}>
      {displayTimeRange}
    </div>
  );
};

export const TaskCard = props => {
  const {
    group,
    className,
    data,
    isNested = false,
    view = 'default',
    wrapupHandler,
    onClick,
    currentTask,
    handleEditMode,
    handlePreviewMode,
    disableActions = false,
    isChecked = false,
    handleEntitySelect
  } = props || {};

  const dispatch = useDispatch();
  const calendarSyncAccounts = useSelector(state => state.calendarSync?.accounts);
  const previousLocation = useSelector(state => state.tasks?.location);

  const templateEntities = useSelector(store => store.templates.entities);
  const isCalendarSyncSetUp = calendarSyncAccounts && Object.keys(calendarSyncAccounts).length > 0;

  const agentEmailAccounts = useSelector(store => store.settings.email);

  const hasEmailIntegrationSetup = checkEmailIntegrationSetup(agentEmailAccounts);

  const {
    id,
    activityType,
    assignedTo,
    completed,
    contactName,
    description,
    endOfEvent,
    email,
    isDone,
    linkTo,
    phone,
    priority,
    startOfEvent,
    timeRange,
    isPending,
    taskTemplate
  } = data || {};

  const handleMultiSelectCheck = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      handleEntitySelect(id);
    },
    [handleEntitySelect, id]
  );

  if (!data) {
    return null;
  }

  const isEmail = activityType === TASK_TYPES.tpxEmail.value;
  const isTemplateDeleted = isEmail && templateEntities?.[taskTemplate?.templateFileId]?.isDeleted === 1;
  const shouldShowPreviewButton = (isEmail || activityType === TASK_TYPES.texting.value) && !isTemplateDeleted;

  const { address: transactionAddress, id: transactionId, type: transactionType } = linkTo || {};

  const parsedStartOfEvent = parseISODate(startOfEvent);
  const isFutureDate = isFutureTransactionDate(parsedStartOfEvent);
  const dateLong = !isFutureDate ? formatLongDate(parsedStartOfEvent) : TRANSACTION_FUTURE_DATE_LABEL;
  const dueDateFriendly = !Boolean(isPending)
    ? !isFutureDate
      ? formatFriendlyDueDate(parsedStartOfEvent)
      : TRANSACTION_FUTURE_DATE_LABEL
    : TASK_PENDING_DATE_LABEL;

  const displayDescription = getTaskDescription(description);
  const contactDisplayName = getContactDisplayName(contactName || data?.contacts?.[0]?.name);

  const cleanPhone = cleanPhoneNumber(phone);
  const priorityLabel = getTaskPriorityLabel(priority);
  const tagLabels = getTaskTagLabels(data);
  const taskComplete = isDone || completed;

  const displayAssignedToName = getContactDisplayName(assignedTo);

  function handleDoneClick(e) {
    e.preventDefault();
    e.stopPropagation();

    if (isEmail && !hasEmailIntegrationSetup) {
      dispatch(
        showAlert({
          message: 'It appears that email integration is not yet set up. Would you like to set up email integration?',
          icon: 'error',
          iconSize: 'm',
          primaryButtonLabel: 'Configure email',
          primaryButtonHandler: () => {
            dispatch(clearAlert());
            navigate('/settings/email-integration');
          },
          secondaryButtonLabel: 'Mark done anyway',
          secondaryButtonHandler: () => {
            dispatch(clearAlert());
            wrapupHandler(e, data);
          }
        })
      );
    } else {
      wrapupHandler(e, data);
    }

    trackEvent(isNested ? 'contactDetails' : 'tasks', 'markDone');
    return;
  }

  const deleteHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    const showCalendarSyncWarning =
      isCalendarSyncSetUp && data?.activityType === TASK_TYPES.appointment.value && data?.isRepeating;

    dispatch(confirmDeleteHandler(id, group, { showCalendarSyncWarning }));
  };

  const previewHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    handlePreviewMode(e);
  };

  const classes = classnames({
    [styles.taskCard]: !className,
    [styles.isNested]: isNested,
    [styles[`${view}View`]]: true,
    [className]: !!className
  });

  const titleClasses = classnames({
    [cardStyles.cardTitle]: true,
    [styles.isComplete]: taskComplete
  });

  const footerClasses = classnames({
    [cardStyles.footer]: true,
    [styles.footer]: true
  });

  const isNestedInDetailsView = !!isNested;
  const isCalendarDayView = view === 'compact';

  const iconName = getDisplayTaskIcon(activityType);

  const isSelected = id === currentTask;

  const pathType = location?.pathname?.split('/')[1];

  const isNestedInTaskView = pathType === 'tasks' && location?.pathname?.includes('/contact-');
  const isNestedInContact = pathType === 'contacts';
  const isFromDashboard = checkIsFromDashboard(location?.pathname);
  const locationSearchString = !isNestedInTaskView ? (location ? location.search : '') : previousLocation?.search || '';
  const url = (() => {
    if (isFromDashboard) {
      return `view/tasks/${id}${location.search}`;
    }
    return isNestedInContact ? `${location.pathname}${location.search}` : `/tasks/${id}${locationSearchString}`;
  })();
  const hasTime = !isUntimedEvent(startOfEvent, endOfEvent) || timeRange !== TIME_RANGES.notTimed;

  return (
    <Fragment>
      <Card id={id} url={url} className={classes} isNested={isNested} onClick={onClick} isSelected={isSelected}>
        <div className={styles.grid}>
          {handleEntitySelect && !isCalendarDayView && (
            <div className={styles.gridAreaCheckbox}>
              <FormCheckBox id={`ms-${id}`} isChecked={isChecked} changeHandler={handleMultiSelectCheck} />
            </div>
          )}
          <div className={styles.gridAreaMain}>
            <Icon name={iconName} size="s" />
            <div className={cardStyles.titleContainer}>
              <div className={titleClasses} title={description}>
                <span className={styles.title}>{displayDescription}</span>
              </div>
            </div>
          </div>

          <div className={styles.gridAreaPriority} title={`${priorityLabel} Priority`}>
            <PriorityIndicator priority={priority} />
          </div>

          {/* Show the cell if not isCalendarDayView, OR if it isCalendarDayView and there is a time */}
          {(!isCalendarDayView || (isCalendarDayView && hasTime)) && (
            <div className={styles.gridAreaDate}>
              {/* Show in all TaskCard views but the calendar day view. */}
              {!isCalendarDayView && (
                <div className={cardStyles.cardDetailsEm} title={dateLong}>
                  {dueDateFriendly}
                </div>
              )}
              {!Boolean(isPending) && (
                <MeetingTime
                  hasTime={hasTime}
                  activityType={activityType}
                  startOfEvent={startOfEvent}
                  endOfEvent={endOfEvent}
                  dateLong={dateLong}
                />
              )}
            </div>
          )}
          {/* Show in all nested TaskCard views - contact details, transaction details. */}
          {!isNestedInDetailsView && (
            <div className={styles.gridAreaContact}>
              {contactDisplayName && <div className={styles.contactName}>{contactDisplayName}</div>}
              {cleanPhone && <div className={styles.contactPhone}>{cleanPhone}</div>}
              {email && <div className={styles.contactEmail}>{email}</div>}
            </div>
          )}
          {/* Show ONLY in the main Tasks page TaskCard view. */}
          {!isCalendarDayView && (
            <div className={styles.gridAreaAssignedTo}>
              <div className={cardStyles.cardDetails} title={displayAssignedToName}>
                {displayAssignedToName}
              </div>
            </div>
          )}
          {!disableActions && (
            <div className={styles.gridAreaActions}>
              <ButtonGroup variant="icon">
                {shouldShowPreviewButton && !taskComplete && (
                  <Button
                    ariaLabel={`Preview ${getDisplayTaskType(activityType)}`}
                    icon={getDisplayTaskPreviewIcon(activityType)}
                    onClick={previewHandler}
                    tooltipPos="left"
                    data-cy="previewTaskButton"
                  />
                )}
                {!Boolean(isPending) && (
                  <Button
                    ariaLabel={taskComplete ? 'Mark incomplete' : 'Mark done'}
                    icon="check"
                    isColored={taskComplete}
                    onClick={handleDoneClick}
                    tooltipPos="left"
                    data-cy="markDoneTaskButton"
                  />
                )}
                <Button
                  ariaLabel="Edit task"
                  icon="edittask"
                  onClick={handleEditMode}
                  tooltipPos="left"
                  data-cy="editTaskButton"
                />
                <Button
                  ariaLabel="Delete task"
                  icon="delete"
                  onClick={deleteHandler}
                  tooltipPos="left"
                  data-cy="previewTaskButton"
                />
              </ButtonGroup>
            </div>
          )}
          {/* Show in all TaskCard views but the calendar day view. */}
          {!isCalendarDayView && (
            <div className={footerClasses}>
              <TagGroup className={cardStyles.tagGroup}>
                <Fragment>
                  {
                    // Don't show if no transactionAddress or 8i listing (1) or 8i closing (2)
                    transactionAddress && transactionType !== 1 && transactionType !== 2 && (
                      <Tag
                        key={transactionAddress}
                        label={transactionAddress}
                        url={createEntityTagUrl(transactionId, '6')}
                      />
                    )
                  }
                  {tagLabels.map(tag => (
                    <Tag key={tag} label={tag} />
                  ))}
                </Fragment>
              </TagGroup>
            </div>
          )}
        </div>
      </Card>

      {isSelected && isNested && (
        <Suspense fallback={<Loading />}>
          <TaskDetailSummary
            data={data}
            variant="nested"
            handleConfirmDelete={deleteHandler}
            handleEditMode={handleEditMode}
            handleWrapup={handleDoneClick}
          />
        </Suspense>
      )}
    </Fragment>
  );
};

TaskCard.propTypes = {
  className: string,
  data: object.isRequired,
  group: string.isRequired,
  handleEditMode: func.isRequired,
  handlePreviewMode: func.isRequired,
  isNested: bool,
  view: oneOf(['default', 'compact']),
  wrapupHandler: func.isRequired,
  disableActions: bool,
  isChecked: bool
};
