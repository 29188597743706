/** @module */

import { SMARTZIP_REQUEST, SET_SMARTZIP_SSO_URL, SET_SMARTZIP_STATUS } from '../reducers/smartzip';
import { request, requestError } from '../utils';

export const getSmartzipStatus = () => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'GET',
      path: `tpxsso/smartzipuser`,
      shouldBeCached: false
    };

    dispatch({ type: SMARTZIP_REQUEST });

    try {
      const { data } = await request(options);

      dispatch({
        type: SET_SMARTZIP_STATUS,
        smartzipStatus: data
      });

      return data;
    } catch (error) {
      const { response } = error;
      const { status } = response || {};

      if (status === 500) {
        console.error(error);
        return Promise.reject(error);
      }

      requestError({ ...error }, dispatch);
      return Promise.reject(error);
    }
  };
};

export const getSmartzipSsoUrl = () => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'GET',
      path: `tpxsso/smartzipurl`,
      shouldBeCached: false
    };

    dispatch({ type: SMARTZIP_REQUEST });

    try {
      const { data } = await request(options);

      const { SmartzipSsoUrl } = data;

      dispatch({
        type: SET_SMARTZIP_SSO_URL,
        smartzipSsoUrl: SmartzipSsoUrl
      });

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};
