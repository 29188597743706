/** @module */

import {
  TRANSACTION_DETAILS,
  TRANSACTIONS_FETCH_SUCCESS,
  TRANSACTIONS_LOADING,
  TRANSACTIONS_SET,
  TRANSACTIONS_FORM_TOGGLE,
  TRANSACTIONS_UPDATE_FORM_TOGGLE,
  TRANSACTIONS_SAVE,
  TRANSACTIONS_DELETE,
  TRANSACTIONS_ADD_PARTY,
  TRANSACTIONS_PARTY_SAVE,
  TRANSACTIONS_PARTY_DELETE,
  TRANSACTIONS_LOADING_FINISHED,
  TRANSACTIONS_SET_AGENTS,
  TRANSACTIONS_8I_IMPORT,
  TRANSACTIONS_SET_LAST_SEARCHED_DATE
} from '../reducers/transactions';
import { TASKS_RESET } from '../reducers/tasks';
import { cleanStrOfSymbols, request, requestError, searchAndRemoveFromStorage } from '../utils';
import { showMessage } from './message';
import {
  buildTransactionsGroupName,
  getTransactionRepTypeFilter,
  getTransactionRepTypeFilterValues
} from '../utils/transactions';
import { clearAlert, showAlert } from './alert';
import { getContactDetail } from './contacts';

/**
 * Get all transaction for this account by status id
 * @param {String} status transaction status id
 */
export const getTransactions = (options, setStatus = false) => {
  const { status, buyer, seller, startDate, endDate, source } = options;
  const groupName = buildTransactionsGroupName(options);
  const repType = getTransactionRepTypeFilter(buyer, seller);

  return async dispatch => {
    dispatch({
      type: TRANSACTIONS_LOADING
    });
    const fetchOptions = {
      baseUrlKey: 'api',
      path: 'transactions',
      params: {
        statusId: status,
        startDate,
        endDate,
        representationType: repType,
        source
      },
      shouldBeCached: false
    };

    try {
      const response = await request(fetchOptions).catch(() => {
        if (setStatus) {
          dispatch({
            type: TRANSACTIONS_SET,
            group: groupName
          });
        }
        dispatch({
          type: TRANSACTIONS_LOADING_FINISHED
        });
      });

      if (!response) {
        dispatch({
          type: TRANSACTIONS_LOADING_FINISHED
        });

        return;
      }
      const { data } = response;

      dispatch({
        type: TRANSACTIONS_FETCH_SUCCESS,
        group: groupName,
        transactionRecords: data,
        setStatus
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Set the current status for transactions
 * @param {String} status status id used for group
 */
export const setCurrentTransactionStatus = status => {
  const group = typeof status === 'number' ? status.toString() : status;
  return dispatch => {
    dispatch({
      type: TRANSACTIONS_SET,
      group
    });
  };
};

/**
 * Makes a call to BE to refresh dates of tasks associated with the identified transaction.
 * If successful, removes from the cache the reference of getTasksByPlanId with the corresponding transaction id.
 * @param {String} transactionId
 */
export const refreshTaskDates = transactionId => {
  return async () => {
    // host/tpx/activity.svc/refreshTaskDates/transactionId
    const taskRequest = await request({
      apiServiceType: 'task',
      shouldBeCached: false,
      path: `refreshTaskDates/${transactionId}`
    });

    if (!taskRequest) {
      return;
    }

    const { data } = taskRequest;

    const { exception } = data;

    if (exception) {
      throw exception;
    }

    // we want to clear the cache associated with getTasksByPlanId action.
    // these parameters are based on how getRequestIdentifier works.
    const apiServiceType = 'task';
    const path = 'listByPlan';
    const cleanKey = cleanStrOfSymbols(transactionId);
    searchAndRemoveFromStorage(`${apiServiceType}${path}`, cleanKey);
  };
};

/**
 * Show the AlertDialog asking if the user wants to refresh the task dates.
 * It only resolves after the user interacting with the Dialog.
 * If primaryButtonHandler is clicked, then it calls refreshTaskDates.
 * In both cases, it clears the dialog and resolves.
 *
 * @param {Object} options
 * @param {String} [options.transactionId]
 * @param {String} [options.primaryButtonLabel]
 * @param {String} [options.secondaryButtonLabel]
 */
export const showAlertToRefreshTaskDates = options => {
  const { transactionId, message, primaryButtonLabel, secondaryButtonLabel } = options;
  return async dispatch => {
    await new Promise((resolve, reject) => {
      const clearAlertAndResolve = () => {
        dispatch(clearAlert());
        resolve();
      };

      dispatch(
        showAlert({
          message,
          icon: 'appointment',
          iconSize: 'm',
          primaryButtonLabel,
          primaryButtonHandler: () => {
            dispatch(refreshTaskDates(transactionId)).then(clearAlertAndResolve).catch(reject);
          },
          secondaryButtonLabel,
          secondaryButtonHandler: clearAlertAndResolve
        })
      );
    });
  };
};

/**
 * This action is responsible for saving a Transaction in the back-end.
 * Depending on the [options.mode] parameter, it can do one of three things:
 * - mode=add: Creates a new Transaction, toggles Form Dialog ( @see AddTransactionForm ) and returns undefined
 * - mode=update: Updates an existing Transaction, toggles Form Dialog ( @see AddTransactionForm ) and returns undefined
 * - mode=insight: Creates a new Transaction and returns the transaction id
 *
 * @param {Object} payload Request payload (Transaction Data)
 * @param {Object} options Request payload (Transaction Data)
 * @param {Object} [options.listing]
 * @param {'add'|'update'|'insight'|'property'} [options.mode] identifier used for different usage.
 *  add: Make a post request (create a new Transaction)
 *  update: Make a put request (update an existing Transaction)
 *  insight: Same as 'add' but also returns the transactionId
 * @param {boolean} shouldRefreshTaskDates True means that some of the date fields were changed.
 *  This option is used for deciding wether dates of tasks linked to the transaction should be refreshed (true) or not (false).
 *
 * @returns {String|undefined} Returns the transaction id if mode=insight otherwise returns undefined
 */
export const saveTransaction = (payload, options) => {
  const { currentGroup, listing, mode, shouldRefreshTaskDates, shouldRefreshTasks } = options;
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: mode !== 'update' ? 'POST' : 'PUT',
      path: 'transactions',
      shouldBeCached: false,
      payload
    };

    try {
      const response = await request(requestOptions);
      const { data, status } = response;
      const { transactionId } = data;
      if (status === 201 || status === 200) {
        dispatch({
          type: TRANSACTIONS_SAVE,
          listing,
          data
        });

        if (!['property', 'insight'].includes(mode)) {
          dispatch({
            type: TRANSACTIONS_FORM_TOGGLE
          });
        }

        if (shouldRefreshTaskDates) {
          await dispatch(
            showAlertToRefreshTaskDates({
              transactionId: payload.transactionId,
              message:
                'Would you like to adjust the due dates of any dependent transaction tasks based on your changes?',
              primaryButtonLabel: 'Yes, adjust task dates',
              secondaryButtonLabel: 'No, thanks'
            })
          );
        }

        if (shouldRefreshTasks) {
          searchAndRemoveFromStorage('tasklist', 'pageSize');
          dispatch({ type: TASKS_RESET });
        }

        dispatch(showMessage({ message: 'Transaction saved successfully.', type: 'success' }, true));

        if (currentGroup) {
          const currentGroupParams = currentGroup.split('::');
          const currentStatus = currentGroupParams[0];
          const currentRepType = currentGroupParams[1];
          const currentStartDate = currentGroupParams[2];
          const currentEndDate = currentGroupParams[3];
          const currentSource = currentGroupParams[4];
          const buyerSeller = getTransactionRepTypeFilterValues(currentRepType);

          const getTransactionOptions = {
            status: currentStatus,
            startDate: currentStartDate,
            endDate: currentEndDate,
            source: currentSource,
            ...buyerSeller
          };

          dispatch(getTransactions(getTransactionOptions));
        }

        if (['add', 'insight', 'property'].includes(mode)) {
          return transactionId;
        }
      }
    } catch (error) {
      const { response } = error;
      const { status } = response || {};

      if (status !== 500) {
        requestError(error, dispatch);
      }
      return Promise.reject();
    }
  };
};

/**
 * Open the dialog to update the transaction
 * @param {String} transactionId
 */
export const openEditForm = (transactionId, focusId) => {
  return dispatch => {
    dispatch({
      type: TRANSACTIONS_UPDATE_FORM_TOGGLE,
      transactionId,
      focusId
    });
  };
};

/**
 * Delete one transaction and update the redux state
 * @param {String} transactionId
 */
export const deleteTransaction = transactionId => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'DELETE',
      path: `transactions/${transactionId}`,
      shouldBeCached: false
    };

    try {
      const response = await request(options);
      const { status } = response;

      if (status === 204) {
        dispatch({
          type: TRANSACTIONS_DELETE,
          transactionId
        });
        dispatch(showMessage({ message: 'Transaction deleted successfully.', type: 'success' }, true));
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Get one single transaction for viewing
 * @param {String} transactionId
 */
export const getTransaction = ({ transactionId, shouldBeCached = true, forceRefresh = false }) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      forceRefresh,
      path: `transactions/${transactionId}`,
      shouldBeCached
    };

    try {
      const response = await request(options);

      const { status, data } = response;
      // This call give null for transaction party data
      // We rely on getTransactionParty() to get latest party data
      delete data.transactionParties;

      if (status === 200) {
        dispatch({
          type: TRANSACTION_DETAILS,
          transactionId,
          data
        });
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Get transaction party data for a single transaction
 * @param {String} transactionId
 */
export const getTransactionParty = props => {
  const { transactionId, shouldBeCached = true, forceRefresh = false } = props;

  // This call won't get latest transaction party data
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      path: `transactions/${transactionId}/parties`,
      shouldBeCached,
      forceRefresh
    };
    try {
      const response = await request(options);
      const { status, data } = response;

      //Refresh contact data to make sure all is most recent for suggestions.
      data.forEach(item => {
        dispatch(getContactDetail({ id: item.contactId }));
      });

      if (status === 200) {
        dispatch({
          type: TRANSACTIONS_PARTY_SAVE,
          data,
          transactionId
        });
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Add a new transaction party
 * @param {Object} payload
 * @param {Object} listing
 * @param {String} mode identifier used for different usage.
 *    Add transaction from scratch, from property insights or update transaction
 */
export const addTransactionParty = (transactionId, contactId, roleId, roleName) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'POST',
      path: `transactions/${transactionId}/parties`,
      shouldBeCached: false,
      payload: {
        contactId,
        roleId,
        roleName
      }
    };

    try {
      const response = await request(options);
      const { data, status } = response;
      if (status === 201) {
        dispatch({
          type: TRANSACTIONS_ADD_PARTY,
          transactionId,
          data
        });
        dispatch(showMessage({ message: 'Transaction party saved successfully.', type: 'success' }, true));
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Delete a single transaction party
 * @param {String} transactionId
 */
export const deleteTransactionParty = (transactionId, contactId) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'DELETE',
      path: `transactions/${transactionId}/parties/${contactId}`,
      shouldBeCached: false,
      payload: null
    };

    try {
      const response = await request(options);
      const { status } = response;

      if (status === 204) {
        dispatch({
          type: TRANSACTIONS_PARTY_DELETE,
          contactId,
          transactionId
        });
        dispatch(showMessage({ message: 'Transaction party deleted successfully.', type: 'success' }, true));
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Get all transactions by contact id
 * @param {String} contactId
 */
export const getTransactionsByContact = contactId => {
  return async dispatch => {
    dispatch({
      type: TRANSACTIONS_LOADING
    });

    const options = {
      baseUrlKey: 'api',
      path: `contacts/${contactId}/transactions`,
      shouldBeCached: true
    };

    try {
      const response = await request(options).catch(() => {
        dispatch({
          type: TRANSACTIONS_LOADING_FINISHED
        });
      });

      if (!response) {
        return;
      }

      const { data } = response;

      if (!data) {
        dispatch({
          type: TRANSACTIONS_LOADING_FINISHED
        });

        return;
      }

      dispatch({
        type: TRANSACTIONS_FETCH_SUCCESS,
        group: contactId,
        transactionRecords: data
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Get a transaction's assigned agents in a team account
 * @param {string} status transaction status id
 */
export const getTransactionAgents = options => {
  const { transactionId, shouldBeCached = true, forceRefresh = false } = options;

  return async dispatch => {
    const fetchOptions = {
      baseUrlKey: 'api',
      path: `transactions/${transactionId}/agents`,
      shouldBeCached,
      forceRefresh
    };

    try {
      const response = await request(fetchOptions);

      const { data } = response || {};

      if (data) {
        dispatch({
          type: TRANSACTIONS_SET_AGENTS,
          transactionId,
          data
        });
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Add an agent to a transaction
 * @param {string} transactionId
 * @param {string} userId
 * @param {string} userFullName
 * @param {string} roleId
 */
export const addTransactionAgent = (transactionId, userId, userFullName, roleId) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'POST',
      path: `transactions/${transactionId}/agents`,
      shouldBeCached: false,
      payload: {
        userId,
        userFullName,
        roleId
      }
    };

    try {
      const response = await request(options);
      const { status } = response;
      if (status === 201) {
        dispatch(getTransactionAgents({ transactionId, forceRefresh: true }));
        dispatch(showMessage({ message: 'Transaction agent updated successfully.', type: 'success' }, true));
        return;
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Delete one transaction agent
 * @param {string} transactionId
 * @param {string} agentId
 */
export const deleteTransactionAgent = (transactionId, agentId, dispatchMessage = true) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'DELETE',
      path: `transactions/${transactionId}/agents/${agentId}`,
      shouldBeCached: false
    };

    try {
      const response = await request(options);
      const { status } = response;

      if (status === 204) {
        dispatch(getTransactionAgents({ transactionId, forceRefresh: true }));
        if (dispatchMessage) {
          dispatch(showMessage({ message: 'Transaction agent deleted successfully.', type: 'success' }, true));
        }
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Imports 8i closings.
 */
export const import8iClosings = () => {
  const options = {
    baseUrlKey: 'api',
    method: 'GET',
    path: `transactions/import8iclosings`,
    shouldBeCached: false
  };
  return async dispatch => {
    try {
      const response = await request(options);
      const { data, status } = response;
      if (status === 200) {
        dispatch(
          showMessage(
            {
              message: `An import of your 8i closings has started. Check back soon to see your transactions.`,
              type: 'success'
            },
            false
          )
        );
        dispatch({ type: TRANSACTIONS_8I_IMPORT });
        return data;
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Sync a manual transaction with a MLS listing
 * @param {string} transactionId
 * @param {string} source MLS source
 * @param {string} mlsNumber
 */
export const syncMlsListing = (transactionId, source, mlsNumber) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'POST',
      path: `transactions/${transactionId}/syncMlsListing`,
      shouldBeCached: false,
      payload: {
        source,
        mlsNumber
      }
    };

    try {
      const response = await request(options);
      const { status } = response;
      if (status === 200) {
        dispatch(getTransaction({ transactionId, forceRefresh: true }));
        dispatch(showMessage({ message: 'Transaction synced with MLS listing successfully.', type: 'success' }, true));
        return;
      }
    } catch (error) {
      requestError(error, dispatch);
      return Promise.reject();
    }
  };
};

/**
 * Save recent date range from recent search for transactions
 * @param {Object} date
 * @param {string} [date.dateTo] Start date
 * @param {string} [date.dateFrom] End date
 */
export const setTransactionLastSearchedDate = date => {
  return dispatch => {
    dispatch({
      type: TRANSACTIONS_SET_LAST_SEARCHED_DATE,
      data: date
    });
  };
};

/**
 * Call to update transaction tasks when the transaction parties are updated
 * @param {string} transactionId
 */
export const updateTransactionTasks = transactionId => {
  return async dispatch => {
    dispatch(clearAlert());

    const options = {
      baseUrlKey: 'api',
      method: 'GET',
      path: `plans/refreshTaskParties`,
      shouldBeCached: false,
      params: {
        transactionId
      }
    };

    try {
      const response = await request(options);
      const { status } = response;
      // Transaction task lists always fetch the latest data, no need to update tasks here.
      if (status === 200) {
        dispatch(showMessage({ message: 'Transaction tasks updated successfully.', type: 'success' }, true));
      }
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};
