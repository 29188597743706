/** @module */

import { showMessage } from '../actions/message';
import { DASHBOARD_SAVE } from '../reducers/dashboard';

import { request, requestError } from '../utils';
/**
 * Gets the user's preferences from the dashboard preference API.
 * If success, the preferences are stored in Redux.
 * If fails, shows error message via <Toast />.
 */
export const getDashboardPreferences = () => {
  return async dispatch => {
    const options = {
      apiServiceType: 'userProfile',
      method: 'GET',
      params: { feature: 'dashboard' },
      path: 'users/current/settings',
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (!data.value) {
        return;
      }

      const parsedData = JSON.parse(data.value);
      dispatch({
        type: DASHBOARD_SAVE,
        data: parsedData
      });

      return parsedData;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Saves the user's preferences from the generic settings API.
 * If success, saves the preferences.
 * If fails, shows error message via <Toast />.
 * @param {Object} preferences - data object which includes the whole, newly updated preferences object.
 * @param {Object} prevPreferences - data object which includes the previous preferences object.
 * @param {Boolean} silent - boolean which determines if preferences saved message should be disabled.
 */
export const saveDashboardPreferences = (preferences, prevPreferences, silent = false) => {
  const isReset = preferences?.layouts === null;

  return async dispatch => {
    const options = {
      apiServiceType: 'userProfile',
      method: 'POST',
      path: 'users/current/settings',
      payload: { feature: 'dashboard', value: JSON.stringify(preferences) },
      shouldBeCached: false
    };

    dispatch({
      type: DASHBOARD_SAVE,
      data: preferences
    });

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (!silent) {
        dispatch(
          showMessage(
            { message: `${!isReset ? 'New layout saved' : 'Layout reset'} successfully.`, type: 'success' },
            true
          )
        );
      } // useTimer set to true

      return true;
    } catch (error) {
      // If it errors, we fallback to the prevPreferences.
      dispatch({
        type: DASHBOARD_SAVE,
        data: prevPreferences
      });

      requestError(error, dispatch);

      return false;
    }
  };
};
