import { COUNTRIES_LIST } from '../../data/countries';

export const IS_FORM_SUBMITTING = 'IS_FORM_SUBMITTING';
export const IS_FORM_VALID = 'IS_FORM_VALID';
export const SET_FIELDS = 'SET_FIELDS';
export const SET_ADDRESS_FIELDS = 'SET_ADDRESS_FIELDS';
export const UPDATE_LISTING = 'UPDATE_LISTING';
export const TOGGLE_DIALOG = 'TOGGLE_DIALOG';
export const SAVE_TEMP_CONTACT = 'SAVE_TEMP_CONTACT';

export const reducer = (state, action) => {
  const { type, value, id } = action;

  switch (type) {
    case IS_FORM_SUBMITTING:
      return {
        ...state,
        isFormSubmitting: value
      };
    case IS_FORM_VALID:
      return {
        ...state,
        isFormValid: value
      };
    case SET_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          [id]: {
            ...state.fields[id],
            ...value[id]
          }
        }
      };
    case SET_ADDRESS_FIELDS:
      return {
        ...state,
        fields: {
          ...state.fields,
          address: {
            ...state.fields.address,
            [id]: {
              ...state.fields.address[id],
              ...value[id]
            }
          }
        }
      };
    case UPDATE_LISTING:
      // Import data from listings on load
      const {
        location,
        list_date,
        list_price,
        source,
        description,
        property_id,
        listing_id: rdcListingId
      } = value || {};
      const { type: propType, beds, baths, sqft, year_built } = description || {};
      const { listing_id: mlsNumber, id: mlsSourceId } = source || {};
      const { address } = location || {};
      const {
        unit: listingUnit,
        street_number: listingHouseNumber,
        street_name: listingStreetName,
        street_suffix: listingStreetType,
        country: listingCountry,
        line: listingAddressLine,
        state_code: listingStataCode,
        city: listingCity,
        postal_code: listingPostalCode,
        county: listingCounty
      } = address || {};

      const parsedCountry = listingCountry
        ? COUNTRIES_LIST.find(
            item =>
              item?.value.toLowerCase() === listingCountry.toLowerCase() ||
              item?.code?.toUpperCase() === listingCountry.toUpperCase()
          ) ?? { value: 'USA' }
        : { value: 'USA' };

      return {
        ...state,
        fields: {
          ...state.fields,
          address: {
            ...state.fields.address,
            city: {
              ...state.fields.address.city,
              value: listingCity
            },
            country: {
              ...state.fields.address.country,
              value: parsedCountry.code || parsedCountry.value || 'Canada'
            },
            county: {
              ...state.fields.address.county,
              value: listingCounty
            },
            postalCode: {
              ...state.fields.address.postalCode,
              value: listingPostalCode
            },
            state: {
              ...state.fields.address.state,
              value: listingStataCode
            },
            streetName: {
              ...state.fields.address.streetName,
              value: listingStreetName || listingAddressLine
            },
            streetNumber: {
              ...state.fields.address.streetNumber,
              value: listingHouseNumber
            },
            streetSuffix: {
              ...state.fields.address.streetSuffix,
              value: listingStreetType
            },
            unit: {
              ...state.fields.address.unit,
              value: listingUnit
            }
          },
          listPrice: {
            ...state.fields.listPrice,
            value: list_price
          },
          listingDate: {
            ...state.fields.listingDate,
            value: list_date
          },
          mlsNumber: {
            ...state.fields.mlsNumber,
            value: mlsNumber
          },
          rdcListingId: {
            ...state.fields.rdcListingId,
            value: rdcListingId
          },
          propertyType: {
            ...state.fields.propertyType,
            value: propType
          },
          rdcPropertyId: {
            ...state.fields.rdcPropertyId,
            value: property_id
          },
          beds: {
            ...state.fields.beds,
            value: beds
          },
          baths: {
            ...state.fields.baths,
            value: baths
          },
          sqft: {
            ...state.fields.sqft,
            value: sqft
          },
          yearBuilt: {
            ...state.fields.yearBuilt,
            value: year_built
          },
          mlsSourceId: {
            ...state.fields.mlsSourceId,
            value: mlsSourceId
          }
        }
      };
    case TOGGLE_DIALOG:
      return {
        ...state,
        showAddContactDialog: !state.showAddContactDialog
      };
    case SAVE_TEMP_CONTACT:
      return {
        ...state,
        tempContact: value
      };
    default:
      return state;
  }
};
