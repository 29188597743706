import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Dialog, DialogHeader, FormFieldset, FormFooter, FormHint, LookupBasic } from '../../components';
import { assignContactTypes } from '../../actions/contacts';
import { FormMessage } from '../FormMessage';

import styles from './ContactTypeModal.css';

const ContactTypeModal = props => {
  const { listOfSelected, isOpen, clearHandler } = props;

  const dispatch = useDispatch();

  const contactTypeGroups = useSelector(state => state.contacts.types.groups);
  const currentContactTypeGroup = useSelector(state => state.contacts.types.currentGroup);
  const contactTypeEntities = useSelector(state => state.contacts.types.entities);

  const [contactTypes, setContactTypes] = useState([]);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formIsValid, setFormIsValid] = useState(true);

  const numberOfContacts = listOfSelected.length;

  if (!isOpen || numberOfContacts === 0) {
    return null;
  }

  const contactsStr = numberOfContacts > 1 ? 'contacts' : 'contact';

  const contactTypeList = contactTypeGroups[currentContactTypeGroup].reduce((acc, id) => {
    acc.push(contactTypeEntities[id]);

    return acc;
  }, []);

  const resetForm = () => {
    setContactTypes([]);
    setFormIsValid(true);
    setFormIsSubmitting(false);
  };

  const handleContactTypeChange = selected => {
    const contactTypes = Object.keys(selected);

    if (!formIsValid) {
      setFormIsValid(true);
    }

    setContactTypes(contactTypes);
  };

  const handleSubmit = () => {
    setFormIsSubmitting(true);

    if (contactTypes.length === 0) {
      setFormIsValid(false);
      setFormIsSubmitting(false);
      return;
    }

    dispatch(assignContactTypes({ contactIds: listOfSelected, contactTypes })).then(() => {
      resetForm();
    });
  };

  const handleClear = () => {
    resetForm();
    clearHandler();
  };

  const validationMessage = formIsValid ? null : 'At least one contact type is required.';

  return (
    <Dialog isOpen={isOpen}>
      <DialogHeader title="Add contact types" icon="tag" iconSize="l" clearHandler={handleClear} />
      <Form id="contactTypeMultiSelectForm">
        <FormFieldset label="Search and select contact types">
          <FormMessage message={validationMessage} className={styles.validationMessage} />
          <LookupBasic
            searchKey="contactType"
            searchList={contactTypeList}
            searchGroup={contactTypeGroups[currentContactTypeGroup]}
            entities={contactTypes.value}
            isEntityCreationEnabled={true}
            changeHandler={handleContactTypeChange}
            formIsSubmitting={formIsSubmitting}
          />
          <FormHint>
            You have <strong>{numberOfContacts}</strong> {contactsStr} selected.
          </FormHint>
        </FormFieldset>
      </Form>
      <FormFooter loading={formIsSubmitting}>
        <Button
          label="Add Contact Types"
          ariaLabel="Add contact types."
          type="submit"
          styleType="primary"
          onClick={handleSubmit}
        />
      </FormFooter>
    </Dialog>
  );
};

export default ContactTypeModal;
