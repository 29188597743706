/** @module */

import {
  deleteFromCollection,
  deleteFromGroup,
  getMergedEntities,
  getMergedSetOfIds,
  sortCollectionByDate
} from '../utils/collections';

export const CLEAR_TEMP_LISTINGS = 'CLEAR_TEMP_LISTINGS';
export const GET_LISTINGS_LOADING = 'GET_LISTINGS_LOADING';
export const GET_LISTINGS_SUCCESS = 'GET_LISTINGS_SUCCESS';
export const GET_LISTINGS_FAIL = 'GET_LISTINGS_FAIL';
export const PROPERTY_INSIGHTS_CREATE_SUCCESS = 'PROPERTY_INSIGHTS_CREATE_SUCCESS';
export const PROPERTY_INSIGHTS_CREATE_FAIL = 'PROPERTY_INSIGHTS_CREATE_FAIL';
export const PROPERTY_INSIGHTS_DELETE = 'PROPERTY_INSIGHTS_DELETE';
export const PROPERTY_INSIGHTS_FORM_TOGGLE = 'PROPERTY_INSIGHTS_FORM_TOGGLE';
export const PROPERTY_INSIGHTS_LOADING = 'PROPERTY_INSIGHTS_LOADING';
export const PROPERTY_INSIGHTS_SET = 'PROPERTY_INSIGHTS_SET';
export const PROPERTY_INSIGHTS_UPDATE = 'PROPERTY_INSIGHTS_UPDATE';
export const PROPERTY_INSIGHTS_TRANSACTION_FORM_TOGGLE = 'PROPERTY_INSIGHTS_TRANSACTION_FORM_TOGGLE';
export const PROPERTY_INSIGHTS_FAIL = 'PROPERTY_INSIGHTS_FAIL';

export const initialState = {
  currentGroup: null,
  entities: {},
  groups: {},
  isLoading: false,
  toggleForm: false,
  toggleTransactionForm: false,
  tempListings: [],
  showNoTempListingsError: false,
  insightId: null,
  transactionPartyContactId: null
};

/**
 * The Property Insights redux reducer.
 * @param {Object} state - the current state of the contacts store.
 * @param {Object} action - the action to take on the contacts store
 * @param {String} [action.type=default] - the action to take.
 * @param {Boolean} [action.isPaging] - Denotes whether the request data is due to paging.
 * @param {String} [action.group] - Acts as a key when storing groups of contacts.
 * @param {Boolean} [action.isLoading] - Denotes whether the data request is in progress.
 */
export const propertyInsightsReducer = (state = initialState, action = {}) => {
  const {
    currentGroup,
    group,
    insight_id,
    insight_status_id,
    propertyInsightRecords,
    showNoTempListingsError,
    tempListings,
    insightId,
    transactionPartyContactId,
    type = 'default'
  } = action;

  switch (type) {
    case GET_LISTINGS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case GET_LISTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        showNoTempListingsError,
        tempListings
      };
    case GET_LISTINGS_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case PROPERTY_INSIGHTS_FORM_TOGGLE:
      return {
        ...state,
        toggleForm: !state.toggleForm
      };
    case PROPERTY_INSIGHTS_LOADING:
      return {
        ...state,
        currentGroup: currentGroup || state.currentGroup,
        isLoading: true
      };
    case PROPERTY_INSIGHTS_SET:
      const mergedEntities = getMergedEntities(state.entities, propertyInsightRecords, { idKey: 'insight_id' });

      return {
        ...state,
        entities: mergedEntities,
        groups: {
          ...state.groups,
          [group]: getMergedSetOfIds(state.groups[group], propertyInsightRecords, {
            idKey: 'insight_id',
            sortFunction: sortCollectionByDate,
            sortDirection: 'dsc',
            sortKey: 'insight_when_created',
            entities: mergedEntities
          })
        },
        currentGroup,
        isLoading: false
      };
    case PROPERTY_INSIGHTS_CREATE_SUCCESS:
      return {
        ...state,
        entities: getMergedEntities(state.entities, propertyInsightRecords, { idKey: 'insight_id' }),
        currentGroup,
        toggleForm: !state.toggleForm,
        isLoading: false
      };
    case PROPERTY_INSIGHTS_FAIL:
    case PROPERTY_INSIGHTS_CREATE_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case CLEAR_TEMP_LISTINGS: {
      return {
        ...state,
        showNoTempListingsError: false,
        tempListings: initialState.tempListings
      };
    }
    case PROPERTY_INSIGHTS_UPDATE: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [insight_id]: {
            ...state.entities[insight_id],
            insight_status_id
          }
        }
      };
    }
    case PROPERTY_INSIGHTS_DELETE: {
      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, insight_id)
        },
        groups: {
          ...state.groups,
          [currentGroup]: deleteFromGroup(state.groups[currentGroup], insight_id)
        }
      };
    }
    case PROPERTY_INSIGHTS_TRANSACTION_FORM_TOGGLE:
      return {
        ...state,
        toggleTransactionForm: !state.toggleTransactionForm,
        transactionPartyContactId,
        insightId
      };
    default:
      return state;
  }
};
