import { getGroupByEntity } from './collections';

/**
 * Creates insight note tag URL
 * @param {String} insightId - the insight ID.
 * @param {Groups} groups - the groups object.
 * @param {Function} dispatch - the dispatch function.
 */
export const createInsightNoteTagUrl = (insightId, groups) => {
  // Try to check if the insight has been loaded
  const contactId = getGroupByEntity(insightId, groups);

  if (contactId != null) {
    return `/contacts/${contactId}/property-insights`;
  }
  // Else return false to turn off the link until insight loaded.
  return false;
};
