import React from 'react';
import { bool, object } from 'prop-types';
import classnames from 'classnames';

import { getStyleObjectFromStr } from '../../../utils/dom';
import { leaveOnlyDigits } from '../../../utils/strings';
import { hasDigit } from '../../../utils/validation';

import styles from '../Editor.css';

/**
 * React component for rendering Slate Image nodes as a basic Image for serialization.
 * @param {Object} props ImageBasic component props.
 * @param {Object} [props.dimensions] The images height and width.
 * @param {Object} props.element HTML image related attribute and style values.
 * @param {Boolean} [props.isSelected] Whether the image is selected in the editor.
 * @param {Boolean} [props.isSerializing] Whether the node is in the process of being serialized.
 */
export const ImageBasic = React.forwardRef((props, ref) => {
  const { dimensions, element, isSelected = false, isSerializing = false } = props;
  const { cid, url, width: elWidth, height: elHeight } = element;
  const elStyles = typeof element.style === 'string' ? getStyleObjectFromStr(element.style) : element.style;

  const width = dimensions?.width || elWidth || elStyles?.width;
  const height = dimensions?.height || elHeight || elStyles?.height;
  const digitWidth = hasDigit(width) ? leaveOnlyDigits(width) : null;
  const digitHeight = hasDigit(height) ? leaveOnlyDigits(height) : null;
  const cleanWidth = digitWidth ? `${digitWidth}px` : 'auto';
  const cleanHeight = digitHeight ? `${digitHeight}px` : 'auto';

  const classes = classnames({
    [styles.isActive]: isSelected
  });

  const style = {
    width: cleanWidth,
    height: cleanHeight
  };

  const attrs = {
    'data-cid': isSerializing ? null : cid,
    ref,
    src: isSerializing && cid ? `cid:${cid}` : url,
    style,
    width: digitWidth ? digitWidth : null,
    height: digitHeight ? digitHeight : null
  };

  if (classes) {
    attrs.className = classes;
  }

  return <img {...attrs} />;
});

ImageBasic.propTypes = {
  dimensions: object,
  element: object.isRequired,
  isSelected: bool,
  isSerializing: bool
};
