/** @module */

export const MESSAGE_SET = 'MESSAGE_SET';
export const MESSAGE_CLEAR = 'MESSAGE_CLEAR';

export const initialState = {
  exceptionType: null,
  id: null,
  message: null
};

/**
 * The message redux reducer.
 * @param {Object} state - the current state of the message store.
 * @param {Object} action - the action to take on the message store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - A data object that could be an error object or a server created exception object.
 * @param {Object} [action.useTimer] - Flag set to auto close the message toast.
 */
export const messageReducer = (state = initialState, action = {}) => {
  const { data, type = 'default', useTimer = false } = action;

  if (type === MESSAGE_SET && useTimer && state.useTimer) {
    // if a message is already set using a timer, we avoid setting another message and timer.
    return state;
  }

  switch (type) {
    case MESSAGE_SET:
      return {
        ...state,
        ...data,
        useTimer
      };
    case MESSAGE_CLEAR:
      return initialState;
    default:
      return state;
  }
};
