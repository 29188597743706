import { TASK_PLAN_ADJUSTMENT_DIRECTIONS, TASK_PLAN_ADJUSTMENTS } from '../../../../utils/tasks';

export const ADJUST_DIRECTION_OPTIONS = [
  {
    id: TASK_PLAN_ADJUSTMENT_DIRECTIONS.before.value,
    value: TASK_PLAN_ADJUSTMENT_DIRECTIONS.before.label
  },
  {
    id: TASK_PLAN_ADJUSTMENT_DIRECTIONS.on.value,
    value: TASK_PLAN_ADJUSTMENT_DIRECTIONS.on.label
  },
  {
    id: TASK_PLAN_ADJUSTMENT_DIRECTIONS.after.value,
    value: TASK_PLAN_ADJUSTMENT_DIRECTIONS.after.label
  }
];

export const ADJUST_EVENT_OPTION_BY_PREVIOUS_ITEM = {
  id: TASK_PLAN_ADJUSTMENTS.By_Previous_Item.value,
  value: TASK_PLAN_ADJUSTMENTS.By_Previous_Item.label
};

// adjustEvent:
// Contact plans only support: Plan_Start_Date, By_Previous_Item, and Not_Adjust
export const ADJUST_EVENT_OPTIONS_FOR_CONTACTS = [
  // Not_Adjust is handle by another field
  {
    id: TASK_PLAN_ADJUSTMENTS.Plan_Start_Date.value,
    value: TASK_PLAN_ADJUSTMENTS.Plan_Start_Date.label
  }
];

// adjustEvent:
// Transaction standalone tasks only supports transaction date related events
export const ADJUST_EVENT_OPTIONS_FOR_TRANSACTIONS = [
  {
    id: TASK_PLAN_ADJUSTMENTS.Agreement_Date.value,
    value: TASK_PLAN_ADJUSTMENTS.Agreement_Date.label
  },
  { id: TASK_PLAN_ADJUSTMENTS.Closing_Date.value, value: TASK_PLAN_ADJUSTMENTS.Closing_Date.label },
  {
    id: TASK_PLAN_ADJUSTMENTS.Inspection_Date.value,
    value: TASK_PLAN_ADJUSTMENTS.Inspection_Date.label
  },
  { id: TASK_PLAN_ADJUSTMENTS.Offer_Date.value, value: TASK_PLAN_ADJUSTMENTS.Offer_Date.label },
  {
    id: TASK_PLAN_ADJUSTMENTS.Offer_Expiration_Date.value,
    value: TASK_PLAN_ADJUSTMENTS.Offer_Expiration_Date.label
  },
  { id: TASK_PLAN_ADJUSTMENTS.Listing_Date.value, value: TASK_PLAN_ADJUSTMENTS.Listing_Date.label },
  {
    id: TASK_PLAN_ADJUSTMENTS.Expiration_Date.value,
    value: TASK_PLAN_ADJUSTMENTS.Expiration_Date.label
  },
  {
    id: TASK_PLAN_ADJUSTMENTS.Acceptance_Date.value,
    value: TASK_PLAN_ADJUSTMENTS.Acceptance_Date.label
  },
  {
    id: TASK_PLAN_ADJUSTMENTS.Possession_Date.value,
    value: TASK_PLAN_ADJUSTMENTS.Possession_Date.label
  },
  {
    id: TASK_PLAN_ADJUSTMENTS.Financial_Commitment_Date.value,
    value: TASK_PLAN_ADJUSTMENTS.Financial_Commitment_Date.label
  }
];

// adjustEvent:
// Transaction plans support all events.
export const ADJUST_EVENT_OPTIONS_ALL = [
  ...ADJUST_EVENT_OPTIONS_FOR_CONTACTS,
  ...ADJUST_EVENT_OPTIONS_FOR_TRANSACTIONS
];

export const DEFAULT_ID = null;
export const ASSIGNED_TO_DEFAULT = 'Person who applies action plan';

export const ASSIGNED_TO_CONTACT_ID = '00000000-0000-0000-0000-000000000000';
export const ASSIGNED_TO_CONTACT = 'Person to whom contact is assigned';

export const PREVIOUS_ITEM_DEFAULT = 'Select a previous task';
