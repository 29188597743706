import React, { Component, Fragment } from 'react';
import { func, string } from 'prop-types';

import {
  Button,
  Form,
  FormField,
  FormFieldset,
  FormFooter,
  FormHint,
  FormRadio,
  FormRadioGroup,
  FormRow
} from '../../components';
import { REGEX } from '../../constants';
import { validateEmail, validateUrl } from '../../utils';

import formFieldStyles from '../FormField/FormField.css';

export class InsertLinkForm extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = this.getInitialState(props);
  }

  getInitialState = props => {
    const { defaultLinkText = '', defaultLinkUrl = '' } = props;

    const isEmail = defaultLinkUrl && defaultLinkUrl.startsWith('mailto:');

    const cleanLinkUrl = isEmail ? defaultLinkUrl.replace('mailto:', '') : defaultLinkUrl;

    const initialState = {
      fields: {
        linkType: {
          value: isEmail ? 'email' : 'website',
          isValid: true
        },
        link: {
          value: cleanLinkUrl,
          isValid: true
        },
        text: {
          value: defaultLinkText,
          isValid: true
        }
      },
      formIsValid: true,
      formIsSubmitting: false
    };

    return initialState;
  };

  handleChange = e => {
    const { target } = e;
    const { id, value } = target;

    const changes = {
      [id]: {
        value: value
      }
    };

    this.setState({
      fields: {
        ...this.state.fields,
        ...changes
      }
    });
  };

  handleTypeChange = e => {
    const { target } = e;
    const { value } = target;

    this.setState({
      fields: {
        ...this.state.fields,
        linkType: {
          value,
          isValid: true
        }
      },
      formIsValid: true
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    const { insertLinkChange } = this.props;
    const { fields } = this.state;
    const { link, linkType, text } = fields;

    const formIsValid =
      this.formRef.current.checkValidity() && linkType.value === 'website'
        ? validateUrl(link.value, REGEX.URL_SIMPLE_CASE_INSENSITIVE)
        : validateEmail(link.value);

    if (!formIsValid) {
      this.setState({
        formIsValid,
        fields: {
          ...fields,
          link: {
            ...fields.link,
            isValid: false
          }
        }
      });

      return;
    }

    // put the form into submitting state
    this.setState({ formIsSubmitting: true });

    // gather the form data and format it for insertion in the email
    const formData = Object.keys(fields).reduce((acc, key) => {
      if (key !== 'linkType' && !acc[key]) {
        const { value } = fields[key];
        let formattedValue = value;

        if (fields.linkType.value === 'website' && key === 'link' && !value.startsWith('http')) {
          formattedValue = `http://${value}`;
        } else if (key === 'text' && text.value.trim() === '') {
          formattedValue = fields.link.value;
        } else if (fields.linkType.value === 'email' && key === 'link') {
          formattedValue = `mailto:${value}`;
        }

        acc[key] = formattedValue;
      }

      return acc;
    }, {});

    insertLinkChange(formData);
  };

  render() {
    const { fields, formIsValid, formIsSubmitting } = this.state;
    const { link, linkType, text } = fields;

    const isWebsite = linkType.value === 'website';
    const linkFieldInputType = isWebsite ? 'text' : 'email'; // Note: we don't use url as the type for website, because we want websites entered as foo.com (with no protocol) to pass.
    const linkFieldLabel = isWebsite ? 'Website URL' : 'Email address';
    const linkFieldValidationMessage = isWebsite ? 'Invalid URL' : 'Invalid email';

    return (
      <Fragment>
        <Form id="insertLinkForm" ref={this.formRef} isNested={true}>
          <FormFieldset label="What would you like to link to?">
            <div className={formFieldStyles.field}>
              <FormRadioGroup name="linkType">
                <FormRadio
                  id="linkTypeWebsite"
                  label="Website"
                  value="website"
                  onChange={this.handleTypeChange}
                  checked={linkType.value === 'website'}
                  disabled={formIsSubmitting}
                />
                <FormRadio
                  id="linkTypeEmail"
                  label="Email address"
                  value="email"
                  onChange={this.handleTypeChange}
                  checked={linkType.value === 'email'}
                  disabled={formIsSubmitting}
                />
              </FormRadioGroup>
            </div>
          </FormFieldset>
          <FormFieldset label="Link details">
            <FormRow>
              <FormField
                type="input"
                inputType="text"
                id="text"
                label="Text to display linked"
                onChange={this.handleChange}
                value={text.value}
                disabled={formIsSubmitting}
                formIsValid={formIsValid}
              />
            </FormRow>
            <FormRow>
              <FormField
                disabled={formIsSubmitting}
                fieldIsValid={link.isValid}
                formIsValid={formIsValid}
                id="link"
                inputType={linkFieldInputType}
                label={linkFieldLabel}
                onChange={this.handleChange}
                required={true}
                size="l"
                type="input"
                validationMessage={linkFieldValidationMessage}
                value={link.value}
              />
            </FormRow>
            {linkType.value === 'website' && (
              <FormHint>
                <strong>Hint:</strong> In your browser, open the page that you want to link to. Then, copy the website
                URL from your browser's address bar, and paste it above.
              </FormHint>
            )}
          </FormFieldset>
        </Form>
        <FormFooter loading={this.state.formIsSubmitting}>
          <Button
            disabled={this.state.formIsSubmitting}
            label="Insert Link"
            ariaLabel="Create tab."
            type="submit"
            form="insertLinkForm"
            styleType="primary"
            onClick={this.handleSubmit}
          />
        </FormFooter>
      </Fragment>
    );
  }
}

InsertLinkForm.propTypes = {
  defaultLinkText: string,
  defaultLinkUrl: string,
  insertLinkChange: func.isRequired
};
