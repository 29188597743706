import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const PendoScript = () => {
  const user = useSelector(state => state.user);
  const userInfo = useSelector(state => state.userProfile.userInfo);

  useEffect(() => {
    if (!user || !userInfo) {
      return;
    }

    const { loginName, profileId, userId } = user;
    const { agentEmail, agentName, businessPhone, mobilePhone } = userInfo || {};

    window?.pendo?.initialize({
      visitor: {
        id: userId,
        email: agentEmail,
        full_name: agentName,
        loginName,
        mobilePhone,
        businessPhone
      },

      account: {
        id: profileId,
        name: agentName
      }
    });
  }, [user, userInfo]);

  return null;
};
