import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOutUser } from '../../actions/authentication';
import { checkIfServerIsOnline } from '../../actions/serverStatus';
import { AlertDialog } from '../Dialog';
import { Loading } from '../Loading';
import { AUTH_LOGOUT_SERVER_OFFLINE } from '../../reducers/authentication';
import { navigate } from '@reach/router';

const CheckOnline = ({ location }) => {
  const isOnline = useSelector(state => state.serverStatus.isOnline);
  const loading = useSelector(state => state.serverStatus.loading);
  const user = useSelector(state => state.user);
  const isAuthenticated = user.token !== null;
  const onMaintenancePage = location === 'maintenance';
  const logOutOptions = useMemo(
    () => ({ path: '/maintenance', signOutType: AUTH_LOGOUT_SERVER_OFFLINE, ...user }),
    [user]
  );
  const [countdown, setCountdown] = useState(15);

  // open alert dialog only when offline
  const isOpen = !isOnline && !onMaintenancePage;

  const hintVerb = isAuthenticated ? 'signed out' : 'redirected';
  const hint = `You will be ${hintVerb} in ${countdown} seconds.`;

  const dispatch = useDispatch();

  const signOutHandler = e => {
    e.preventDefault();
    dispatch(signOutUser(logOutOptions));
  };

  const redirectHandler = e => {
    e.preventDefault();
    navigate('/maintenance');
  };

  const alertProps = {
    isOpen: isOpen,
    icon: 'error',
    iconSize: 'm',
    loading: false,
    hint: hint,
    message: 'Top Producer CRM is going offline for maintenance.',
    primaryButtonLabel: isAuthenticated ? 'Sign out now' : 'Redirect now',
    primaryButtonHandler: isAuthenticated ? signOutHandler : redirectHandler
  };

  useEffect(() => {
    dispatch(checkIfServerIsOnline());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && isOpen) {
      const interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
      if (countdown === 0) {
        dispatch(signOutUser(logOutOptions));
      }
      return () => clearInterval(interval);
    }
  }, [countdown, dispatch, isOpen, loading, logOutOptions]);
  return loading ? <Loading /> : <AlertDialog data={alertProps} />;
};

export default CheckOnline;
