/** @module */
import { deleteFromCollection, deleteFromGroup, getMergedEntities, getMergedSetOfIds } from '../utils/collections';

export const TEAM_SET = 'TEAM_SET';
export const LICENSE_SET = 'LICENSE_SET';
export const SET_DISPLAY_SAVE_ACCOUNT_MODAL = 'SET_DISPLAY_SAVE_ACCOUNT_MODAL';
export const SET_DISPLAY_DELETE_ACCOUNT_MODAL = 'SET_DISPLAY_DELETE_ACCOUNT_MODAL';
export const SET_AGENT_TO_DELETE = 'SET_AGENT_TO_DELETE';
export const SET_AGENT_TO_UPDATE = 'SET_AGENT_TO_UPDATE';
export const DELETE_AGENT = 'DELETE_AGENT';

export const initialState = {
  entities: {},
  groups: {},
  licenses: {},
  currentGroup: 'all',
  displaySaveAccountModal: false,
  displayDeleteAccountModal: false,
  selectedAgentToUpdate: '',
  selectedAgentToDelete: ''
};

/**
 * The team redux reducer.
 * @param {Object} state - the current state of the team store.
 * @param {Object} action - the action to take on the team store
 * @param {String} [action.type=default] - the action to take.
 * @param {Array} [action.data] - An array of team members.
 * @param {String} [action.group] - Acts as a key when storing a team.
 */
export const teamReducer = (state = initialState, action = {}) => {
  const {
    agentId,
    type,
    data,
    group = 'all',
    licenses,
    displaySaveAccountModal,
    displayDeleteAccountModal,
    selectedAgentToDelete,
    selectedAgentToUpdate
  } = action;

  switch (type) {
    case SET_AGENT_TO_DELETE:
      return {
        ...state,
        selectedAgentToDelete
      };
    case SET_AGENT_TO_UPDATE:
      return {
        ...state,
        selectedAgentToUpdate
      };
    case SET_DISPLAY_SAVE_ACCOUNT_MODAL:
      return {
        ...state,
        displaySaveAccountModal
      };
    case SET_DISPLAY_DELETE_ACCOUNT_MODAL:
      return {
        ...state,
        displayDeleteAccountModal
      };
    case TEAM_SET:
      return {
        ...state,
        entities: getMergedEntities(state.entities, data),
        groups: {
          ...state.groups,
          [group]: getMergedSetOfIds(state.groups[group], data)
        },
        currentGroup: group
      };
    case DELETE_AGENT:
      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, agentId)
        },
        groups: {
          ...state.groups,
          all: deleteFromGroup(state.groups.all, agentId),
          agents: deleteFromGroup(state.groups.agents, agentId)
        }
      };
    case LICENSE_SET:
      return {
        ...state,
        licenses
      };
    default:
      return state;
  }
};
