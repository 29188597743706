import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import classnames from 'classnames';
import composeRefs from '@seznam/compose-react-refs';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { dialogClear, dialogOpen } from '../../actions/dialog';

import styles from './Dialog.css';

const stopDialogPropagation = e => {
  e.stopPropagation();
};

export const Dialog = React.forwardRef((props, ref) => {
  const { children, id = 'dialog', isOpen, className, size } = props || {};
  const dispatch = useDispatch();
  const scrollTargetRef = useRef(null);

  useEffect(() => {
    // We need these dispatches to manage 3rd party scripts like LiveChat.
    if (isOpen) {
      disableBodyScroll(scrollTargetRef.current);
      dispatch(dialogOpen());
    }

    return () => {
      clearAllBodyScrollLocks();
      dispatch(dialogClear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!children || !isOpen) {
    return null;
  }

  const classes = classnames({
    [styles.dialog]: !className,
    [styles[`${size}Size`]]: !!size,
    isDialog: true,
    [className]: !!className
  });

  const portalTarget = document.getElementById('app') || document.body;

  return ReactDOM.createPortal(
    <div id={id} ref={composeRefs(scrollTargetRef, ref)} className={styles.overlay}>
      <div className={styles.overlayWrap}>
        <div className={classes} onClick={stopDialogPropagation}>
          {children}
        </div>
      </div>
    </div>,
    portalTarget
  );
});

Dialog.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  id: string,
  isOpen: bool,
  className: string
};
