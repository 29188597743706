/** @module */
import { deleteFromCollection, getMergedEntities } from '../utils';
export const ADD_PICKLIST_DATA = 'ADD_PICKLIST_DATA';
export const MLS_CREDS_LOADING = 'MLS_CREDS_LOADING';
export const MLS_CREDS_LOADING_FINISHED = 'MLS_CREDS_LOADING_FINISHED';
export const MLS_CREDS_SUCCESS = 'MLS_CREDS_SUCCESS';
export const MLS_CREDS_FAIL = 'MLS_CREDS_FAIL';
export const MLS_SOURCES_LOADING = 'MLS_SOURCES_LOADING';
export const MLS_SOURCES_LOADING_FINISHED = 'MLS_SOURCES_LOADING_FINISHED';
export const MLS_SOURCES_SUCCESS = 'MLS_SOURCES_SUCCESS';
export const MLS_SOURCES_FAIL = 'MLS_SOURCES_FAIL';
export const MLS_CREDS_ADD = 'MLS_CREDS_ADD';
export const MLS_CREDS_DELETE = 'MLS_CREDS_DELETE';
export const MLS_CREDS_UPDATE = 'MLS_CREDS_UPDATE';

export const initialState = {
  credentialsLoading: false,
  credentials: [],
  sourcesLoading: false,
  sources: [],
  bymls: [],
  picklist: []
};

/**
 * The MLS credentials redux reducer.
 * @param {Object} state - the current state of the settings store.
 * @param {Object} action - the action to take on the settings store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const mlsReducer = (state = initialState, action = {}) => {
  const { type, data } = action;

  switch (type) {
    case ADD_PICKLIST_DATA:
      return {
        ...state,
        picklist: getMergedEntities(state.picklist, data, { idKey: 'name' })
      };
    case MLS_CREDS_LOADING:
      return {
        ...state,
        credentialsLoading: true
      };
    case MLS_CREDS_LOADING_FINISHED: {
      return {
        ...state,
        credentialsLoading: false
      };
    }
    case MLS_SOURCES_LOADING:
      return {
        ...state,
        sourcesLoading: true
      };
    case MLS_SOURCES_LOADING_FINISHED:
      return {
        ...state,
        sourcesLoading: false
      };
    case MLS_SOURCES_SUCCESS:
      return {
        ...state,
        sourcesLoading: false,
        sources: data
      };
    case MLS_CREDS_SUCCESS:
      return {
        ...state,
        credentialsLoading: false,
        credentials: data
      };
    case MLS_CREDS_UPDATE: // fallback to add
    case MLS_CREDS_ADD:
      return {
        ...state,
        credentials: getMergedEntities(state.credentials, [data], { idKey: 'source' })
      };
    case MLS_CREDS_DELETE:
      const filteredMlsCredentials = deleteFromCollection(state.credentials, action.source);

      return {
        ...state,
        credentials: filteredMlsCredentials
      };
    case MLS_SOURCES_FAIL:
      return { ...initialState, sources: null };
    case MLS_CREDS_FAIL:
      return { ...initialState, credentials: null };
    default:
      return state;
  }
};
