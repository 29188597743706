import { string } from 'prop-types';
import React from 'react';
import { openLiveChat } from '../../utils/popup';
import { Button } from './Button';

export const LiveChatButton = () => {
  const handleOnClick = e => {
    e.preventDefault();
    openLiveChat();
  };
  return (
    <Button
      ariaLabel="Live Chat"
      styleType="inline"
      label="live chat"
      onClick={handleOnClick}
      data-cy="liveChatButton"
    />
  );
};

LiveChatButton.propTypes = {
  className: string
};
