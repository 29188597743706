import React, { Component } from 'react';
import { func, object } from 'prop-types';

import { Toast } from '../';

import { trackError } from '../../utils/analytics';

const MESSAGE_INTERVAL = 3000;
const MESSAGE_ERROR_INTERVAL = 5000;

export class Message extends Component {
  messageClearInterval = null;

  componentDidMount() {
    this.setMessageInterval();
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    const { message, type, useTimer } = data;

    const isNewMessage = message && message !== prevProps.data.message;

    if (useTimer && isNewMessage) {
      if (this.messageClearInterval) {
        this.clearMessageInterval();
      }
      this.setMessageInterval();
      return;
    }

    if (type !== 'success' && isNewMessage) {
      trackError(message);
    }
  }

  componentWillUnmount() {
    this.clearMessageInterval();
  }

  setMessageInterval = () => {
    const { data } = this.props;
    const { type, useTimer = false } = data || {};

    if (!useTimer) {
      return;
    }

    const MESSAGE_TIMER = type === 'error' ? MESSAGE_ERROR_INTERVAL : MESSAGE_INTERVAL;

    this.messageClearInterval = setInterval(() => {
      this.closeHandler();
    }, MESSAGE_TIMER);
  };

  closeHandler = () => {
    const { clearHandler } = this.props;

    clearHandler();
    this.clearMessageInterval();
  };

  clearMessageInterval = () => {
    if (this.clearMessageInterval === null) {
      return;
    }

    clearInterval(this.messageClearInterval);
    this.messageClearInterval = null;
  };

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    const { actionLabel, actionUrl, actionClick, message, type = 'error' } = data;
    const displayMessage = message === 'Network Error' ? 'Your internet connection is currently unavailable.' : message;

    if (!message) {
      return null;
    }

    return (
      <Toast
        actionLabel={actionLabel}
        actionUrl={actionUrl}
        actionClick={actionClick}
        message={displayMessage}
        type={type}
        closeHandler={this.closeHandler}
      />
    );
  }
}

Message.propTypes = {
  clearHandler: func,
  data: object
};
