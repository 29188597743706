import React from 'react';
import { arrayOf, node, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import styles from './ViewBody.css';

export const ViewBody = props => {
  const { children, className } = props;

  if (!children || children.length === 0) {
    return null;
  }

  const classes = classnames({
    [styles.body]: !className,
    [className]: !!className
  });

  return <div className={classes}>{children}</div>;
};

ViewBody.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  className: string
};
