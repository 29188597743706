/** @module */

export const SERVER_STATUS_LOADING = 'SERVER_STATUS_LOADING';
export const SERVER_STATUS_SUCCESS = 'SERVER_STATUS_SUCCESS';
export const SERVER_STATUS_FAIL = 'SERVER_STATUS_FAIL';

const initialState = {
  loading: false,
  isOnline: true
};

/**
 * The server status redux reducer.
 * @param {Object} state - the current state of the server status store.
 * @param {Object} action - the action to take on the server status store
 * @param {String} [action.type=default] - the action to take.
 */
export const serverStatusReducer = (state = initialState, action = {}) => {
  const { type = 'default' } = action;

  switch (type) {
    case SERVER_STATUS_LOADING:
      return {
        ...state,
        loading: true
      };
    case SERVER_STATUS_SUCCESS:
      return initialState;
    case SERVER_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        isOnline: false
      };
    default:
      return state;
  }
};
