import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  addContact,
  getContactLeadSourcePicklist,
  saveContact,
  saveContactLeadSource,
  moveContact,
  deleteContactFromGroup,
  getContactRequestOptions
} from '../../actions/contacts';
import { clearMessage, showMessage } from '../../actions/message';
import { clearAlert, showAlert } from '../../actions/alert';
import {
  BuyerPrefsForm,
  ContactStatus,
  Dropdown,
  Form,
  FormField,
  FormFieldReadOnly,
  FormFieldset,
  FormFooter,
  FormHint,
  FormLabel,
  FormRow,
  Loading,
  LookupBasic
} from '../../components';
import { Button, ButtonGroup } from '../../components/Button';
import { DATE_FORMATS, DEFAULT_NOTE_MAX_LENGTH, REGEX } from '../../constants';
import { CONTACT_METHODS } from '../../data/contact-methods'; // ToDo: make a data barrel
import { MAX_PRICE, MIN_PRICE } from '../../data/snapshot';
import { STATES_LIST } from '../../data/states';
import {
  cleanPhoneNumber,
  cleanStrOfSymbols,
  formatPriceFromFloat,
  formatPriceWithCommas,
  getContactDisplayName,
  isValidPhone,
  sortArrayOfObjects
} from '../../utils';
import { trackEvent } from '../../utils/analytics';
import {
  ALLOWED_SOCIAL_PROFILES,
  SOCIAL_LINKS_MAP,
  getSelectedValue,
  getContactGroupFromParams
} from '../../utils/contacts';
import { focusFirstInvalidField } from '../../utils/dom';
import { clearCustomTabsFromStorage } from '../../utils/localStorage';
import { validateEmail, validateUrl } from '../../utils/strings';
import { getAssignedToOptions } from '../../utils/team';
import formFieldStyles from '../FormField/FormField.css';
import styles from './ContactForm.css';
import difference from 'lodash/difference';
import { convertRelationship } from '../../actions/relationships';

const CONTACT_STATUS_HINTS = new Map([
  [10, 'Should be contacted right away.'],
  [20, 'Followed up, but not yet qualified.'],
  [40, 'Long term opportunity.'],
  [50, ''],
  [60, 'Actively working on an opportunity.'],
  [70, 'Completed transaction.'],
  [80, '']
]);

const MAX_OTHER_PHONES = 7;
const MAX_OTHER_EMAILS = 9;
const MAX_OTHER_WEBSITES = 9;

class ContactForm extends Component {
  constructor(props) {
    super(props);
    const { contact } = props || {};

    this.formRef = React.createRef();
    this.addressRef = React.createRef();
    this.phoneRef = React.createRef();
    this.emailRef = React.createRef();
    this.companyRef = React.createRef();
    this.contactTypeRef = React.createRef();
    this.sourceRef = React.createRef();
    this.minPriceRef = React.createRef();
    this.websiteRef = React.createRef();

    const minPriceIsinList =
      contact && contact.buyerPrefs.minPrice
        ? MIN_PRICE.find(item => {
            if (item.id === formatPriceFromFloat(contact.buyerPrefs.minPrice)) {
              return true;
            }
          })
        : true;

    this.MIN_PRICE_LIST = minPriceIsinList
      ? MIN_PRICE
      : sortArrayOfObjects([
          ...MIN_PRICE,
          {
            id: formatPriceFromFloat(contact.buyerPrefs.minPrice),
            value: formatPriceWithCommas(contact.buyerPrefs.minPrice)
          }
        ]);

    const maxPriceIsinList =
      contact && contact.buyerPrefs.maxPrice
        ? MAX_PRICE.find(item => {
            if (item.id === formatPriceFromFloat(contact.buyerPrefs.maxPrice)) {
              return true;
            }
          })
        : true;

    this.MAX_PRICE_LIST = maxPriceIsinList
      ? MAX_PRICE
      : sortArrayOfObjects([
          ...MAX_PRICE,
          {
            id: formatPriceFromFloat(contact.buyerPrefs.maxPrice),
            value: formatPriceWithCommas(contact.buyerPrefs.maxPrice)
          }
        ]);

    this.state = this.getInitialState();
  }

  componentDidMount() {
    const { getContactLeadSourcePicklist, focusId } = this.props;
    getContactLeadSourcePicklist();

    if (focusId) {
      const focusRefs = {
        addressLine: this.addressRef.current,
        mobilePhone: this.phoneRef.current,
        primaryEmail: this.emailRef.current,
        company: this.companyRef.current,
        contactType: this.contactTypeRef.current,
        source: this.sourceRef.current,
        minPrice: this.minPriceRef.current,
        primaryWebsite: this.websiteRef.current
      };

      const focusRef = focusRefs[focusId];

      if (focusRef) {
        setTimeout(() => {
          /*
            Note: we could get around this setTimeout hack if we did focus at the FormTextInput level,
            but that might get overly verbose.
          */
          focusRef.focus();
        }, 300);
      }
    }
  }

  getInitialState = () => {
    const { address, teamList, contact = {}, user, isFromRelationship = false } = this.props;
    const {
      id,
      assignedTo = {},
      buyerPrefs,
      company,
      contactStatus,
      contactTypes,
      defaultCommMethod,
      emails,
      externalContactStatus = '',
      externalContactStatusSource = '',
      fullContactProfile,
      jobTitle,
      phones,
      primaryPerson,
      secondaryPerson,
      source,
      websites
    } = contact || {};
    const { areaOfInt, minPrice, maxPrice, noBaths, noBeds, sqrFt, propertyId } = buyerPrefs || {};
    const { title: fcTitle, organization: fcOrganization } = fullContactProfile || {};

    const loggedinUser = this.getLoggedInUser(teamList, user);

    const initialState = {
      fields: {
        id: {
          value: id
        },
        address: {
          value: {
            addressLine: { value: (address && address.addressLine) || '' },
            city: { value: (address && address.city) || '' },
            state: { value: (address && address.state) || '' },
            zip: { value: (address && address.zip) || '' }
          }
        },
        // Set default to current user on Add contact. Set it to unassigned on edit if no assigned to available
        assignedTo: {
          value: id ? (assignedTo.name ? getContactDisplayName(assignedTo.name) : null) : loggedinUser.value
        },
        assignedToId: {
          value: id ? assignedTo.id : loggedinUser.id
        },
        buyerPrefs: {
          value: {
            areaOfInt: areaOfInt || [],
            minPrice: (minPrice && formatPriceWithCommas(minPrice)) || '',
            maxPrice: (maxPrice && formatPriceWithCommas(maxPrice)) || '',
            noBaths: noBaths || '',
            noBeds: noBeds || '',
            sqrFt: sqrFt || '',
            propertyId: propertyId || ''
          }
        },
        company: {
          value: company || fcOrganization || ''
        },
        jobTitle: {
          value: jobTitle || fcTitle || ''
        },
        contactStatus: {
          // Non-client if quick adding from relationship
          value: contactStatus || (isFromRelationship ? 50 : 10)
        },
        contactTypes: {
          value: contactTypes || []
        },
        defaultCommMethod: {
          value: defaultCommMethod || 'Mobile'
        },
        emails: {
          value: this.getEmailState(emails),
          isValid: false
        },
        externalContactStatus: {
          value: externalContactStatus || ''
        },
        externalContactStatusSource: {
          value: externalContactStatusSource || ''
        },
        origin: {
          value: 'TPX'
        },
        phones: {
          value: this.getPhoneState(phones)
        },
        note: {
          value: '', // empty string so the textarea value isn't null in React
          isValid: true
        },
        primaryPersonFirstName: {
          value: (primaryPerson && primaryPerson.firstName) || '',
          isValid: !!(primaryPerson && primaryPerson.firstName) || false
        },
        primaryPersonLastName: {
          value: (primaryPerson && primaryPerson.lastName) || '',
          isValid: false
        },
        secondaryPersonFirstName: {
          value: (secondaryPerson && secondaryPerson.firstName) || '',
          isValid: false
        },
        secondaryPersonLastName: {
          value: (secondaryPerson && secondaryPerson.lastName) || '',
          isValid: false
        },
        secondaryPhone: {
          value: {
            comm_type: 'Home',
            tag: 'Home',
            value: (contact?.secondaryPhones && contact.secondaryPhones[0]?.value) || '',
            description: (contact?.secondaryPhones && contact.secondaryPhones[0]?.description) || '',
            type: 0,
            enum: 0
          },
          isValid: true
        },
        secondaryEmail: {
          value: {
            comm_type: 'Email',
            tag: 'Email',
            value: (contact?.secondaryEmails && contact.secondaryEmails[0]?.value) || '',
            description: (contact?.secondaryEmails && contact.secondaryEmails[0]?.description) || '',
            type: 0,
            enum: 1
          },
          isValid: true
        },
        source: {
          value: source || '',
          hasCustomLeadSource: false,
          custom: '',
          customIsValid: false
        },
        websites: {
          value: this.getWebsiteState(websites, fullContactProfile) // back fill otherWebsites with social bio
        }
      },
      formIsValid: null,
      formIsSubmitting: false,
      secondaryPersonIsConverting: false
    };
    this.initialAssignedToId = initialState.fields.assignedToId;
    return initialState;
  };

  getLoggedInUser = (teamList, user) => {
    if (teamList && teamList.length > 0) {
      return (
        teamList.find(teamMember => {
          return teamMember.id === user.userId;
        }) || { id: teamList[0].id, value: teamList[0].value }
      );
    }

    return { id: user.userId, value: `${user.firstName} ${user.lastName}` };
  };

  handleExternalContactStatusChange = (internalStatusId, externalStatusId) => {
    this.setState({
      fields: {
        ...this.state.fields,
        contactStatus: { value: internalStatusId },
        externalContactStatus: { value: parseInt(externalStatusId) },
        externalSource: 1
      }
    });
  };

  handleChange = e => {
    const { target } = e;
    const { id, value } = target;
    const getStateKey = id => {
      if (id.startsWith('addContactStatus')) {
        return 'contactStatus';
      }

      return id;
    };

    const stateKey = getStateKey(id);
    const newValue = stateKey === 'contactStatus' ? parseInt(value) : value; // contact status is number

    const changes = {
      [stateKey]: {
        value: newValue,
        isValid: target.checkValidity()
      }
    };

    this.setState({
      fields: {
        ...this.state.fields,
        ...changes
      }
    });
  };

  handlePhoneChange = e => {
    const { target } = e;
    const { dataset, id, value } = target;
    const cleanValue = cleanPhoneNumber(value) || '';

    if (id === 'businessExt') {
      this.setState({
        fields: {
          ...this.state.fields,
          phones: {
            value: {
              ...this.state.fields.phones.value,
              businessPhone: {
                ...this.state.fields.phones.value.businessPhone,
                ext: value
              }
            }
          }
        }
      });

      return;
    }

    if (id.startsWith('otherPhone')) {
      const { otherphone: otherPhoneId } = dataset;
      this.setState({
        fields: {
          ...this.state.fields,
          phones: {
            value: {
              ...this.state.fields.phones.value,
              otherPhones: Object.assign([...this.state.fields.phones.value.otherPhones], {
                [otherPhoneId]: {
                  ...this.state.fields.phones.value.otherPhones[otherPhoneId],
                  value: cleanValue
                }
              })
            }
          }
        }
      });

      return;
    }

    const isValid = cleanValue.length > 0 ? isValidPhone(cleanValue) : true;

    this.setState({
      fields: {
        ...this.state.fields,
        phones: {
          value: {
            ...this.state.fields.phones.value,
            [id]: {
              ...this.state.fields.phones.value[id],
              value: cleanValue,
              isValid
            }
          }
        }
      }
    });
  };

  handleSecondaryPhoneChange = e => {
    const { target } = e;
    const { value } = target;
    const cleanValue = cleanPhoneNumber(value) || '';
    const isValid = cleanValue.length === 0 || isValidPhone(cleanValue);

    this.setState({
      fields: {
        ...this.state.fields,
        secondaryPhone: {
          value: {
            comm_type: 'Home',
            tag: 'Home',
            value: cleanValue,
            description: this.state.fields.secondaryPhone.value.description,
            type: 0,
            enum: 0
          },
          isValid: isValid
        }
      }
    });
  };

  handleSecondaryPhoneDescChange = e => {
    const { target } = e;
    const { value } = target;
    const isValid =
      this.state.fields.secondaryPhone.value.value.length > 0
        ? isValidPhone(this.state.fields.secondaryPhone.value.value)
        : true;

    this.setState({
      fields: {
        ...this.state.fields,
        secondaryPhone: {
          value: {
            comm_type: 'Home',
            value: this.state.fields.secondaryPhone.value.value,
            tag: 'Home',
            description: value,
            type: 0,
            enum: 0
          },
          isValid: isValid
        }
      }
    });
  };

  handlePhoneDescChange = e => {
    const { target } = e;
    const { dataset, value } = target;
    const { otherphone: otherPhoneId } = dataset;

    this.setState({
      fields: {
        ...this.state.fields,
        phones: {
          value: {
            ...this.state.fields.phones.value,
            otherPhones: Object.assign([...this.state.fields.phones.value.otherPhones], {
              [otherPhoneId]: {
                ...this.state.fields.phones.value.otherPhones[otherPhoneId],
                tag: value
              }
            })
          }
        }
      }
    });
  };

  handleEmailChange = e => {
    const { target } = e;
    const { dataset, id, value } = target;
    const { otheremail: otherEmailId } = dataset;
    const isValid = value.length === 0 || validateEmail(value);

    const updatedFields = id.startsWith('otherEmail')
      ? {
          ...this.state.fields,
          emails: {
            value: {
              ...this.state.fields.emails.value,
              otherEmails: Object.assign([...this.state.fields.emails.value.otherEmails], {
                [otherEmailId]: {
                  data: {
                    ...this.state.fields.emails.value.otherEmails[otherEmailId].data,
                    value
                  },
                  isValid
                }
              })
            }
          }
        }
      : {
          ...this.state.fields,
          emails: {
            value: {
              ...this.state.fields.emails.value,
              [id]: {
                data: {
                  ...this.state.fields.emails.value[id].data,
                  value
                },
                isValid
              }
            }
          }
        };

    this.setState({
      fields: updatedFields,
      formIsValid: true
    });
  };

  handleSecondaryEmailChange = e => {
    const { target } = e;
    const { value } = target;
    const isValid = value.length === 0 || validateEmail(value);

    this.setState({
      fields: {
        ...this.state.fields,
        secondaryEmail: {
          value: {
            comm_type: 'Email',
            tag: 'Email',
            value: value,
            description: this.state.fields.secondaryEmail.value.description,
            type: 0,
            enum: 1
          },
          isValid: isValid
        }
      }
    });
  };

  handleSecondaryEmailDescChange = e => {
    const { target } = e;
    const { value } = target;
    const isValid =
      this.state.fields.secondaryEmail.value.value.length === 0 ||
      validateEmail(this.state.fields.secondaryEmail.value.value);

    this.setState({
      fields: {
        ...this.state.fields,
        secondaryEmail: {
          value: {
            comm_type: 'Email',
            tag: 'Email',
            value: this.state.fields.secondaryEmail.value.value,
            description: value,
            type: 0,
            enum: 1
          },
          isValid: isValid
        }
      }
    });
  };

  handleWebsiteChange = e => {
    const { target } = e;
    const { dataset, id, value } = target;
    const isValid = value.length === 0 || validateUrl(value, REGEX.URL_SIMPLE_CASE_INSENSITIVE);
    const { otherwebsite: otherWebsiteId } = dataset;

    const updatedFields = id.startsWith('otherWebsites')
      ? {
          ...this.state.fields,
          websites: {
            value: {
              ...this.state.fields.websites.value,
              otherWebsites: Object.assign([...this.state.fields.websites.value.otherWebsites], {
                [otherWebsiteId]: {
                  data: {
                    ...this.state.fields.websites.value.otherWebsites[otherWebsiteId].data,
                    value
                  },
                  isValid
                }
              })
            }
          }
        }
      : // primaryWebsite
        {
          ...this.state.fields,
          websites: {
            value: {
              ...this.state.fields.websites.value,
              [id]: {
                data: {
                  ...this.state.fields.websites.value[id].data,
                  value
                },
                isValid
              }
            }
          }
        };

    this.setState({
      fields: updatedFields,
      formIsValid: true
    });
  };

  handleEmailDescChange = e => {
    const { target } = e;
    const { dataset, value } = target;
    const { otheremail: otherEmailId } = dataset;

    this.setState({
      fields: {
        ...this.state.fields,
        emails: {
          value: {
            ...this.state.fields.emails.value,
            otherEmails: Object.assign([...this.state.fields.emails.value.otherEmails], {
              [otherEmailId]: {
                ...this.state.fields.emails.value.otherEmails[otherEmailId],
                data: {
                  ...this.state.fields.emails.value.otherEmails[otherEmailId].data,
                  description: value
                }
              }
            })
          }
        }
      }
    });
  };

  handleWebsiteDescChange = e => {
    const { target } = e;
    const { dataset, value } = target;
    const { otherwebsite: otherWebsiteId } = dataset;

    this.setState({
      fields: {
        ...this.state.fields,
        websites: {
          value: {
            ...this.state.fields.websites.value,
            otherWebsites: Object.assign([...this.state.fields.websites.value.otherWebsites], {
              [otherWebsiteId]: {
                ...this.state.fields.websites.value.otherWebsites[otherWebsiteId],
                data: {
                  ...this.state.fields.websites.value.otherWebsites[otherWebsiteId].data,
                  description: value
                }
              }
            })
          }
        }
      }
    });
  };

  handleAddressChange = e => {
    const { target } = e;
    const { id, value } = target;

    this.setState({
      fields: {
        ...this.state.fields,
        address: {
          value: {
            ...this.state.fields.address.value,
            [id]: {
              ...this.state.fields.address.value[id],
              value
            }
          }
        }
      }
    });
  };

  handleStateChange = e => {
    const { value } = e.target;

    this.setState({
      fields: {
        ...this.state.fields,
        address: {
          value: {
            ...this.state.fields.address.value,
            state: {
              ...this.state.fields.address.value.state,
              value
            }
          }
        }
      }
    });
  };

  handleLeadSourceChange = e => {
    const { value } = e.target;

    this.setState({
      fields: {
        ...this.state.fields,
        source: {
          ...this.state.fields.source,
          value,
          hasCustomLeadSource: value === 'Other'
        }
      }
    });
  };

  handleCustomLeadSourceChange = e => {
    const { value } = e.target;

    this.setState({
      fields: {
        ...this.state.fields,
        source: {
          ...this.state.fields.source,
          custom: value,
          customIsValid: value.trim() !== ''
        }
      }
    });
  };

  handleContactMethodChange = e => {
    const { value } = e.target;

    this.setState({
      fields: {
        ...this.state.fields,
        defaultCommMethod: {
          value
        }
      }
    });
  };

  handleAssignToChange = e => {
    const { target } = e;
    const { value, selectedIndex } = target;
    const { id } = target[selectedIndex];

    this.setState({
      fields: {
        ...this.state.fields,
        assignedTo: {
          value
        },
        assignedToId: {
          value: id
        }
      }
    });
  };

  handleBuyerPrefChange = e => {
    const { id: targetId, value: targetValue, selectedOptions } = e.target;

    const buyerPrefsKeyMap = {
      beds: 'noBeds',
      baths: 'noBaths'
    };

    const buyerPrefsKey = buyerPrefsKeyMap[targetId] || targetId;

    if (selectedOptions) {
      const { showMessage } = this.props;
      const { maxPrice, minPrice } = this.state.fields.buyerPrefs.value;
      const min = minPrice && cleanStrOfSymbols(this.getSelectedPrice(minPrice, this.MIN_PRICE_LIST));
      const max = maxPrice && cleanStrOfSymbols(this.getSelectedPrice(maxPrice, this.MAX_PRICE_LIST));
      const priceId = cleanStrOfSymbols(targetValue);

      // min price should be greater then max price.
      if (targetId === 'minPrice' && parseInt(priceId) > parseInt(max)) {
        showMessage(
          {
            message: 'Min price cannot be greater than Max price.',
            type: 'error'
          },
          true
        ); // useTimer set to true
        this.setMinMaxPrice(targetValue, targetId, 'maxPrice');
      } else if (targetId === 'maxPrice' && parseInt(priceId) < parseInt(min)) {
        showMessage(
          {
            message: 'Min price cannot be greater than Max price.',
            type: 'error'
          },
          true
        ); // useTimer set to true
        this.setMinMaxPrice(targetValue, targetId, 'minPrice');
      } else {
        this.setMinMaxPrice(targetValue, buyerPrefsKey);
      }
    } else {
      this.setMinMaxPrice(targetValue, buyerPrefsKey);
    }
  };

  setMinMaxPrice = (value, id, swapId) => {
    const data = swapId ? { [id]: value, [swapId]: value } : { [id]: value };

    this.setState({
      fields: {
        ...this.state.fields,
        buyerPrefs: {
          value: {
            ...this.state.fields.buyerPrefs.value,
            ...data
          }
        }
      }
    });
  };

  handleContactTypeChange = selectedEntities => {
    this.setState({
      fields: {
        ...this.state.fields,
        contactTypes: {
          value: Object.keys(selectedEntities).reduce((acc, id) => {
            acc.push(selectedEntities[id].contactType);

            return acc;
          }, [])
        }
      }
    });
  };

  handleAddPhone = () => {
    const { otherPhones } = this.state.fields.phones.value;

    if (otherPhones && otherPhones.length === MAX_OTHER_PHONES) {
      return;
    }

    this.setState({
      fields: {
        ...this.state.fields,
        phones: {
          value: {
            ...this.state.fields.phones.value,
            otherPhones: Object.assign([...this.state.fields.phones.value.otherPhones], {
              [otherPhones.length]: {
                tag: '',
                value: ''
              }
            })
          }
        }
      }
    });
  };

  handleAddEmail = () => {
    const { otherEmails } = this.state.fields.emails.value;

    if (otherEmails && otherEmails.length === MAX_OTHER_EMAILS) {
      return;
    }

    this.setState({
      fields: {
        ...this.state.fields,
        emails: {
          value: {
            ...this.state.fields.emails.value,
            otherEmails: Object.assign([...this.state.fields.emails.value.otherEmails], {
              [otherEmails.length]: {
                data: {
                  description: '',
                  tag: '',
                  value: ''
                }
              }
            })
          }
        }
      }
    });
  };

  handleAddWebsite = () => {
    const { otherWebsites } = this.state.fields.websites.value;

    if (otherWebsites && otherWebsites.length === MAX_OTHER_WEBSITES) {
      return;
    }

    this.setState({
      fields: {
        ...this.state.fields,
        websites: {
          value: {
            ...this.state.fields.websites.value,
            otherWebsites: Object.assign([...this.state.fields.websites.value.otherWebsites], {
              [otherWebsites.length]: {
                data: {
                  description: '',
                  value: '',
                  tag: ''
                }
              }
            })
          }
        }
      }
    });
  };

  handleNoteValidate = isValid => {
    this.setState({
      fields: {
        ...this.state.fields,
        note: { ...this.state.fields.note, isValid }
      }
    });
  };

  getPhoneState = methods => {
    const DEFAULT_STATE = {
      mobilePhone: { tag: 'Mobile', value: '', isValid: true },
      homePhone: { tag: 'Home', value: '' },
      businessPhone: { tag: 'Business', value: '', ext: '' },
      otherPhones: []
    };

    if (!methods) {
      return DEFAULT_STATE;
    }

    const methodState = methods.reduce((acc, method) => {
      const { enum: methodEnum, type } = method;
      let key = 'otherPhones';

      if (methodEnum === 0 && type === 0) {
        key = 'homePhone';
      } else if (methodEnum === 0 && type === 1) {
        key = 'businessPhone';
      } else if (methodEnum === 0 && type === 3) {
        key = 'mobilePhone';
      }

      if (key === 'otherPhones') {
        acc[key] = acc[key] ? [...acc[key], method] : [method];
        return acc;
      }

      acc[key] = { ...method, isValid: true };

      return acc;
    }, {});

    return { ...DEFAULT_STATE, ...methodState };
  };

  getPhoneData = phones => {
    const PHONE_ENUMS = {
      mobilePhone: 0,
      businessPhone: 0,
      homePhone: 0,
      default: 3
    };

    const PHONE_TYPES = {
      mobilePhone: 3,
      businessPhone: 1,
      homePhone: 0,
      default: 0
    };

    const { otherPhones, ...mainPhones } = phones;
    const phoneData = { ...otherPhones, ...mainPhones };

    const formData = Object.keys(phoneData).reduce((acc, phone) => {
      if (phone && (phoneData[phone].id || (phoneData[phone].value && phoneData[phone].value.trim().length > 0))) {
        acc.push({
          ...phoneData[phone],
          type: typeof PHONE_TYPES[phone] !== 'undefined' ? PHONE_TYPES[phone] : PHONE_TYPES['default'],
          enum: typeof PHONE_ENUMS[phone] !== 'undefined' ? PHONE_ENUMS[phone] : PHONE_ENUMS['default'],
          description: ''
        });
      }

      return acc;
    }, []);

    return formData;
  };

  getEmailState = methods => {
    const DEFAULT_STATE = {
      primaryEmail: { data: { description: 'Email', tag: 'Email', value: '' }, isValid: true },
      otherEmails: []
    };

    if (!methods) {
      return DEFAULT_STATE;
    }

    const methodState = methods.reduce((acc, method) => {
      const { enum: methodEnum, type, value } = method;
      const key = methodEnum === 1 && type === 0 ? 'primaryEmail' : 'otherEmails';
      const stateEmail = {
        data: method,
        isValid: value === '' || validateEmail(value)
      };
      if (key === 'otherEmails') {
        acc[key] = acc[key] ? [...acc[key], stateEmail] : [stateEmail];
      } else {
        // primary email
        acc[key] = stateEmail;
      }

      return acc;
    }, {});

    return { ...DEFAULT_STATE, ...methodState };
  };

  getWebsiteState = (methods, fullContactProfile) => {
    const DEFAULT_STATE = {
      primaryWebsite: {
        data: { description: 'Website', tag: 'Website', value: '' },
        isValid: true
      },
      otherWebsites: []
    };

    if (!methods) {
      return DEFAULT_STATE;
    }

    const methodState = methods.reduce((acc, method) => {
      const { enum: methodEnum, type, value } = method;
      const key = methodEnum === 2 && type === 0 ? 'primaryWebsite' : 'otherWebsites';
      const websiteState = {
        data: method,
        isValid: value === '' || validateUrl(value, REGEX.URL_SIMPLE_CASE_INSENSITIVE)
      };

      if (key === 'otherWebsites') {
        acc[key] = acc[key] ? [...acc[key], websiteState] : [websiteState];
      } else {
        acc[key] = websiteState;
      }

      return acc;
    }, {});

    const combinedWebsiteState = { ...DEFAULT_STATE, ...methodState };

    // Try to fill otherWebsites with social bio data from fullContactProfile within the limit
    // Prioritizing 8i data over social bio data
    if (fullContactProfile) {
      const socialProfiles = fullContactProfile?.details?.profiles;
      if (socialProfiles) {
        // First filter and sort the profile to ensure priority in ALLOWED_SOCIAL_PROFILES
        const filtered = ALLOWED_SOCIAL_PROFILES.reduce((acc, key) => {
          if (socialProfiles[key] != null) {
            return { ...acc, [key]: socialProfiles[key] };
          }
          return acc;
        }, {});

        Object.entries(filtered).reduce((acc, [social, data]) => {
          const { url } = data || {};
          if (url != null) {
            const formattedSocial = SOCIAL_LINKS_MAP.find(item => {
              if (item.value === social) {
                return item;
              }
            });
            const found = acc.find(website => website?.data?.value.toLowerCase() === url.toLowerCase());
            // Skip if the contact has existing data from 8i for this social site
            // Also skip if the contact has met the max website count
            if (!found && acc.length < MAX_OTHER_WEBSITES) {
              acc.push({
                data: {
                  description: formattedSocial ? formattedSocial.title : social,
                  tag: 'Other Website',
                  value: url,
                  enum: 5
                },
                isValid: true
              });
              return acc;
            }
          }
          return acc;
        }, combinedWebsiteState.otherWebsites);
      }
    }
    return combinedWebsiteState;
  };

  getEmailData = emails => {
    const EMAIL_ENUMS = {
      primaryEmail: 1,
      default: 4
    };

    const { otherEmails, ...mainEmails } = emails;
    const emailData = { ...otherEmails, ...mainEmails };

    const formData = Object.keys(emailData).reduce((acc, email) => {
      if (
        email &&
        (emailData[email].data.id || (emailData[email].data.value && emailData[email].data.value.trim().length > 0))
      ) {
        acc.push({
          ...emailData[email].data,
          type: 0,
          enum: EMAIL_ENUMS[email] || EMAIL_ENUMS['default']
        });
      }

      return acc;
    }, []);

    return formData;
  };

  getWebsiteData = websites => {
    const WEBSITE_ENUMS = {
      primaryWebsite: 2,
      default: 5
    };

    const { otherWebsites, ...mainWebsites } = websites;
    const websiteData = { ...otherWebsites, ...mainWebsites };

    const formData = Object.keys(websiteData).reduce((acc, website) => {
      if (
        website &&
        (websiteData[website].data.id ||
          (websiteData[website].data.value && websiteData[website].data.value.trim().length > 0))
      ) {
        acc.push({
          ...websiteData[website].data,
          type: 0,
          enum: WEBSITE_ENUMS[website] || WEBSITE_ENUMS['default']
          //description: emails[email].description
        });
      }

      return acc;
    }, []);

    return formData;
  };

  getSelectedPrice = (price, PRICE_LIST) => {
    if (!price) {
      return null;
    }

    const selectedPrice = PRICE_LIST.find(item => {
      if (item.id === cleanStrOfSymbols(price)) {
        return item;
      }
    });

    return selectedPrice ? selectedPrice.value : price;
  };

  getIdFromValue = (list, value) => {
    const match = list.find(item => {
      return item.value === value; // we find the matching item or return the value
    });

    return (match && match.id) || value;
  };

  getSubmissionData = formData => {
    const {
      address,
      assignedTo,
      assignedToId,
      buyerPrefs,
      emails,
      id,
      note,
      phones,
      primaryPersonFirstName,
      primaryPersonLastName,
      secondaryPersonFirstName,
      secondaryPersonLastName,
      secondaryEmail,
      secondaryPhone,
      source,
      websites,
      ...otherFields
    } = formData;

    const formattedFields = {
      address: {
        addressLine: address.addressLine.value,
        city: address.city.value,
        state: address.state.value,
        zip: address.zip.value
      },
      primaryPerson: {
        firstName: primaryPersonFirstName,
        lastName: primaryPersonLastName
      },
      secondaryPerson: {
        firstName: secondaryPersonFirstName,
        lastName: secondaryPersonLastName
      },
      emails: this.getEmailData(emails),
      phones: this.getPhoneData(phones),
      source: source !== 'Other' ? source : this.state.fields.source.custom,
      websites: this.getWebsiteData(websites),
      secondaryEmails: [secondaryEmail],
      secondaryPhones: [secondaryPhone]
    };

    if (assignedToId) {
      formattedFields.assignedTo = {
        id: assignedToId,
        name: assignedTo
      };
    }

    const noteText = note.trim();
    if (noteText.length > 0) {
      formattedFields.notes = [
        {
          noteText,
          date: format(Date.now(), DATE_FORMATS.ISO_DATETIME_Z)
        }
      ];
    }

    if (id) {
      // We should only have an id key during edit.
      formattedFields.id = id;
    }
    const { maxPrice, propertyId, ...otherBuyerPrefs } = buyerPrefs;
    const dummpyPropertyId = '00000000-0000-0000-0000-000000000000';

    const formattedMaxPrice = this.getIdFromValue(this.MAX_PRICE_LIST, maxPrice);
    formattedFields.buyerPrefs = {
      maxPrice: formattedMaxPrice,
      propertyId: propertyId === '' ? dummpyPropertyId : propertyId,
      ...otherBuyerPrefs
    };

    const data = {
      ...formattedFields,
      ...otherFields
    };

    if (data.externalContactStatusSource === '' && data.externalContactStatus === '') {
      delete data.externalContactStatusSource;
      delete data.externalContactStatus;
    }

    return data;
  };

  validateForm = () => {
    const { fields } = this.state;
    const { emails, phones, websites, note, secondaryEmail, secondaryPhone } = fields;

    const isValidMobile = phones.value.mobilePhone.isValid && secondaryPhone.isValid;
    const emailsAreValid =
      emails.value.primaryEmail.isValid &&
      (emails.value.otherEmails.length > 0 ? !emails.value.otherEmails.some(email => email.isValid === false) : true) &&
      secondaryEmail.isValid;
    const websitesAreValid =
      websites.value.primaryWebsite.isValid &&
      (websites.value.otherWebsites.length > 0
        ? !websites.value.otherWebsites.some(site => site.isValid === false)
        : true);

    const noteIsValid = note.isValid;
    const formIsValid =
      isValidMobile && emailsAreValid && websitesAreValid && noteIsValid && this.formRef.current.checkValidity();

    return formIsValid;
  };

  handleSubmit = (e, saveAndAddContact = false) => {
    e.preventDefault();

    const {
      clearAlert,
      clearMessage,
      contact,
      customTabs,
      addContact,
      contactCurrentGroup,
      saveContact,
      saveCallback,
      saveContactLeadSource,
      showAlert,
      handleSaveTempContact,
      moveContact,
      groups,
      deleteContactFromGroup
    } = this.props || {};
    const { fields } = this.state;
    const { source } = fields;

    const assignedToIdUpdated = this.initialAssignedToId !== fields.assignedToId;

    // clear any existing app errors
    clearMessage();

    const formIsValid = this.validateForm();

    if (source.hasCustomLeadSource && source.custom.trim() !== '') {
      saveContactLeadSource({ itemValue: source.custom });
    }

    if (!formIsValid) {
      this.setState({
        formIsValid
      });

      focusFirstInvalidField('contactForm');

      return;
    }

    // put the form into submitting state
    this.setState({ formIsSubmitting: true });

    // gather the form data that is currently saved in state
    const formData = Object.keys(this.state.fields).reduce((acc, key) => {
      if (!acc[key]) {
        const { value } = this.state.fields[key];
        acc[key] = value;
      }

      return acc;
    }, {});

    // process the formData so that it is in the proper form for the API
    const data = this.getSubmissionData(formData);
    if (handleSaveTempContact) {
      handleSaveTempContact(data);
      saveCallback();
      return;
    }

    const shouldUpdateNotes = data.notes;
    // Todo: consider combining add and save contact
    // editMode and save contact

    const handleSaveContact = (data, reassign) => {
      const { contactTypes, id } = data;
      saveContact(data, reassign).then(data => {
        if (data) {
          // the dispatch was successful
          this.setState(this.getInitialState());
          trackEvent('contactDetails', 'editContactSave');
          if (typeof saveCallback === 'function') {
            saveCallback();
          }

          clearCustomTabsFromStorage(customTabs);

          const { contactTypes: oldContactTypes } = contact || {};
          const removedTypes = difference(oldContactTypes || [], contactTypes || []);
          // Temporary FE fix
          Object.keys(groups).map(group => {
            const groupType = group.split('::')[0];
            // Add contact by existing contact type to any matching custom contact tab in cache to ensure those groups are updated
            contactTypes.map(type => {
              if (groupType.toLowerCase() === type.toLowerCase()) {
                moveContact(id, group);
              }
            });
            // Remove contact from related groups when when contact types were removed
            removedTypes.map(type => {
              if (groupType.toLowerCase() === type.toLowerCase()) {
                deleteContactFromGroup(id, group);
              }
            });
          });
        } else {
          // the dispatch failed, so allow the user to change the form
          this.setState({ formIsSubmitting: false });
        }
      });
      clearAlert();
    };

    /** EDIT FLOW */
    if (contact) {
      if (assignedToIdUpdated) {
        showAlert({
          message: 'Do you want to assign incomplete tasks to the newly assigned agent?',
          primaryButtonHandler: () => handleSaveContact(data, true),
          primaryButtonLabel: 'Yes, re-assign tasks',
          secondaryButtonHandler: () => handleSaveContact(data),
          secondaryButtonLabel: 'No',
          icon: 'tasks',
          iconSize: 'm'
        });
        return;
      }

      handleSaveContact(data);

      return;
    }

    // The group this new contact will be added to
    const statusGroup = getContactGroupFromParams(getContactRequestOptions({ ...data, assignedTo: 'all' }));

    this.setState({});

    /** ADD FLOW */
    addContact(data, { shouldUpdateNotes, contactCurrentGroup, statusGroup }).then(data => {
      if (data) {
        // the dispatch was successful
        this.setState(this.getInitialState());
        trackEvent('contacts', 'addContactSave');
        if (typeof saveCallback === 'function' && !saveAndAddContact) {
          // Navigate to new contact if save, don't navigate if save & add.
          saveCallback(data.id);
        }
        if (saveAndAddContact) {
          // Scroll smoothly to top if a save & add.
          document.getElementById('dialog').scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
        clearCustomTabsFromStorage(customTabs);
      } else {
        // the dispatch failed, so allow the user to change the form
        this.setState({ formIsSubmitting: false });
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.contactId !== this.props.contactId || prevProps.contactStatus !== this.props.contactStatus) {
      this.setState(this.getInitialState());
    }
  }

  handleConvertSecondaryPersonToRelationship = () => {
    const { contact, convertRelationship } = this.props;

    this.setState(
      {
        secondaryPersonIsConverting: true
      },
      () =>
        convertRelationship({ contactId: contact.id }).then(() =>
          this.setState({
            fields: {
              ...this.state.fields,
              secondaryPersonFirstName: {
                value: '',
                isValid: true
              },
              secondaryPersonLastName: {
                value: '',
                isValid: true
              }
            },
            secondaryPersonIsConverting: false
          })
        )
    );
  };

  renderLeadSources() {
    const { leadSourcePicklist } = this.props;
    const { fields, formIsValid, formIsSubmitting } = this.state;
    const { source } = fields;

    let selectedLeadSource;
    let displayList;

    // EDGE CASE: When lead is imported from external systems, the Source value is only associated to the contact record.
    // We're having to manually add the source to the list here. Yucky edge case hack. Ideally should be handled by BE.
    if (source.value !== '' && typeof leadSourcePicklist.find(item => item.value === source.value) === 'undefined') {
      const clonedLeadSourcePickList = [...leadSourcePicklist];
      clonedLeadSourcePickList.push({ id: source.value, value: source.value });
      displayList = clonedLeadSourcePickList;
      selectedLeadSource = getSelectedValue(source, clonedLeadSourcePickList);
    } else {
      displayList = leadSourcePicklist;
      selectedLeadSource = getSelectedValue(source, leadSourcePicklist);
    }

    if (leadSourcePicklist.length) {
      return (
        <Fragment>
          <FormRow>
            <div className={formFieldStyles.field}>
              <Dropdown
                id="source"
                items={displayList}
                value={selectedLeadSource ? selectedLeadSource.value : ''}
                defaultValue="Select a source..."
                ref={this.sourceRef}
                onChange={this.handleLeadSourceChange}
                disabled={formIsSubmitting}
              />
            </div>

            {source.value === 'Other' ? (
              <FormField
                type="input"
                inputType="text"
                id="otherSource"
                placeholder="Source"
                onChange={this.handleCustomLeadSourceChange}
                disabled={formIsSubmitting}
                fieldIsValid={source.customIsValid}
                formIsValid={formIsValid}
                required
              />
            ) : null}
          </FormRow>
          <FormHint>
            <strong>Note:</strong> Select "Other" to add a new source.
          </FormHint>
        </Fragment>
      );
    }

    return <Loading />;
  }

  render() {
    const {
      contact,
      contactTypeEntities,
      contactTypeGroups,
      currentContactTypeGroup,
      teamList,
      hideAddContact,
      isQuickAdd = false,
      isFromRelationship = false
    } = this.props;
    const { fields, formIsValid, formIsSubmitting } = this.state;

    const {
      address,
      assignedTo,
      assignedToId,
      id,
      buyerPrefs,
      company,
      contactStatus,
      externalContactStatus,
      contactTypes,
      defaultCommMethod,
      emails,
      jobTitle,
      phones,
      note,
      primaryPersonFirstName,
      primaryPersonLastName,
      secondaryPersonFirstName,
      secondaryPersonLastName,
      secondaryPhone,
      secondaryEmail,
      websites
    } = fields;
    const { addressLine, city, state, zip } = address.value;
    const { minPrice, maxPrice, noBaths, noBeds, sqrFt } = buyerPrefs.value;
    const { mobilePhone } = phones.value;

    const selectedState = getSelectedValue(state, STATES_LIST, {
      idKey: 'value'
    });
    const selectedCommMethod = getSelectedValue(defaultCommMethod, CONTACT_METHODS);

    const contactStatusHint = CONTACT_STATUS_HINTS.get(contactStatus.value);
    const contactTypeList = contactTypeGroups[currentContactTypeGroup].reduce((acc, id) => {
      acc.push(contactTypeEntities[id]);

      return acc;
    }, []);

    const isHiddenForQuickAdd = !isQuickAdd;

    const displaySaveAndAddContactButton = !contact && !hideAddContact;

    const { externalContactStatusSource } = contact || {};

    return (
      <Fragment>
        <Form id="contactForm" ref={this.formRef}>
          <FormFieldset label="Contact details">
            <FormRow>
              <FormField
                type="input"
                inputType="text"
                id="primaryPersonFirstName"
                label="Name"
                onChange={this.handleChange}
                placeholder="First Name"
                maxLength="256"
                value={primaryPersonFirstName.value}
                disabled={formIsSubmitting}
                fieldIsValid={primaryPersonFirstName.isValid}
                formIsValid={formIsValid}
                required
              />
              <FormField
                type="input"
                inputType="text"
                id="primaryPersonLastName"
                onChange={this.handleChange}
                placeholder="Last Name"
                maxLength="256"
                value={primaryPersonLastName.value}
                disabled={formIsSubmitting}
              />
            </FormRow>
            <FormRow>
              <FormField
                type="input"
                id="company"
                label="Company"
                maxLength="256"
                onChange={this.handleChange}
                value={company.value}
                disabled={formIsSubmitting}
                size="l"
                ref={this.companyRef}
              />
            </FormRow>
            <FormRow>
              <FormField
                type="input"
                id="jobTitle"
                label="Job Title"
                maxLength="256"
                onChange={this.handleChange}
                value={jobTitle.value}
                disabled={formIsSubmitting}
                size="l"
              />
            </FormRow>
          </FormFieldset>
          <FormFieldset label="Preferences">
            <FormRow>
              <div className={formFieldStyles.field}>
                <FormLabel htmlFor="defaultCommMethod" required={true}>
                  Primary Contact Method
                </FormLabel>
                <Dropdown
                  id="defaultCommMethod"
                  items={CONTACT_METHODS}
                  value={selectedCommMethod ? selectedCommMethod.value : ''}
                  onChange={this.handleContactMethodChange}
                />
              </div>
            </FormRow>
          </FormFieldset>
          <FormFieldset label="Phones">
            <FormRow>
              <FormField
                type="input"
                inputType="tel"
                id="mobilePhone"
                label="Mobile"
                maxLength="256"
                onChange={this.handlePhoneChange}
                ref={this.phoneRef}
                value={mobilePhone.value}
                fieldIsValid={mobilePhone.isValid}
                formIsValid={mobilePhone.isValid ? true : this.state.formIsValid}
                validationMessage="Invalid phone"
                disabled={formIsSubmitting}
              />
            </FormRow>
            <FormRow>
              <FormField
                type="input"
                inputType="tel"
                id="homePhone"
                label="Home"
                maxLength="256"
                onChange={this.handlePhoneChange}
                value={phones.value.homePhone.value}
                disabled={formIsSubmitting}
              />
            </FormRow>
            <FormRow>
              <FormField
                type="input"
                inputType="tel"
                id="businessPhone"
                label="Business"
                maxLength="256"
                onChange={this.handlePhoneChange}
                value={phones.value.businessPhone.value}
                disabled={formIsSubmitting}
              />
              <FormField
                type="input"
                inputType="tel"
                id="businessExt"
                label="Ext"
                maxLength="256"
                onChange={this.handlePhoneChange}
                value={phones.value.businessPhone.ext}
                disabled={formIsSubmitting}
                size="s"
              />
            </FormRow>
            {phones.value.otherPhones &&
              phones.value.otherPhones.map((phone, i) => (
                <FormRow key={`otherPhones${i}`} className={i === 0 ? styles.otherCommsRow : undefined}>
                  <FormField
                    type="input"
                    inputType="tel"
                    id={`otherPhone${i}`}
                    data-otherphone={i}
                    label={i === 0 ? 'Other Phone Numbers' : null}
                    maxLength="256"
                    onChange={this.handlePhoneChange}
                    value={phone.value}
                    placeholder="Number"
                    disabled={formIsSubmitting}
                  />
                  <FormField
                    type="input"
                    inputType="text"
                    id={`otherPhoneDesc${i}`}
                    data-otherphone={i}
                    maxLength="256"
                    onChange={this.handlePhoneDescChange}
                    value={phone.tag}
                    placeholder="Title"
                    disabled={formIsSubmitting}
                  />
                </FormRow>
              ))}
            {phones.value.otherPhones.length < MAX_OTHER_PHONES && (
              <FormRow>
                <Button
                  label="Add Another Phone"
                  ariaLabel="Add another phone type."
                  icon="call"
                  size="s"
                  styleType="white"
                  onClick={this.handleAddPhone}
                />
              </FormRow>
            )}
          </FormFieldset>
          <FormFieldset label="Emails">
            <FormRow>
              <FormField
                type="input"
                inputType="email"
                id="primaryEmail"
                label="Primary Email"
                maxLength="256"
                onChange={this.handleEmailChange}
                value={emails.value.primaryEmail.data.value}
                fieldIsValid={emails.value.primaryEmail.isValid}
                formIsValid={formIsValid}
                disabled={formIsSubmitting}
                ref={this.emailRef}
                validationMessage="Email invalid"
              />
            </FormRow>
            {emails.value.otherEmails &&
              emails.value.otherEmails.map((email, i) => {
                const { data, isValid } = email;
                const { value, description } = data || {};

                return (
                  <FormRow key={`otherEmails${i}`} className={i === 0 ? styles.otherCommsRow : undefined}>
                    <FormField
                      type="input"
                      inputType="email"
                      id={`otherEmail${i}`}
                      data-otheremail={i}
                      label={i === 0 ? 'Other Emails' : null}
                      maxLength="256"
                      onChange={this.handleEmailChange}
                      value={value}
                      placeholder="Email"
                      disabled={formIsSubmitting}
                      fieldIsValid={isValid}
                      formIsValid={formIsValid}
                      validationMessage="Email invalid"
                    />
                    <FormField
                      type="input"
                      inputType="text"
                      id={`otherEmailDesc${i}`}
                      data-otheremail={i}
                      maxLength="256"
                      onChange={this.handleEmailDescChange}
                      value={description || ''}
                      placeholder="Title"
                      disabled={formIsSubmitting}
                    />
                  </FormRow>
                );
              })}
            {emails.value.otherEmails.length < MAX_OTHER_EMAILS && (
              <FormRow>
                <Button
                  label="Add Another Email"
                  ariaLabel="Add another email address."
                  icon="email"
                  size="s"
                  styleType="white"
                  onClick={this.handleAddEmail}
                />
              </FormRow>
            )}
          </FormFieldset>
          <FormFieldset label="Spouse contact details">
            <FormRow>
              <FormField
                type="input"
                inputType="text"
                id="secondaryPersonFirstName"
                label="Name"
                onChange={this.handleChange}
                placeholder="First Name"
                maxLength="256"
                value={secondaryPersonFirstName.value}
                disabled={formIsSubmitting}
              />
              <FormField
                type="input"
                inputType="text"
                id="secondaryPersonLastName"
                onChange={this.handleChange}
                placeholder="Last Name"
                maxLength="256"
                value={secondaryPersonLastName.value}
                disabled={formIsSubmitting}
              />
            </FormRow>
            <FormRow>
              <FormField
                type="input"
                inputType="tel"
                id="secondaryPhone"
                label="Phone"
                placeholder="Number"
                maxLength="256"
                onChange={this.handleSecondaryPhoneChange}
                value={secondaryPhone.value.value}
                fieldIsValid={secondaryPhone.isValid}
                formIsValid={formIsValid}
                validationMessage="Invalid phone"
                disabled={formIsSubmitting}
              />
              <FormField
                type="input"
                inputType="text"
                id="secondaryPhoneDesc"
                maxLength="256"
                onChange={this.handleSecondaryPhoneDescChange}
                value={secondaryPhone.value.description}
                placeholder="Title"
                disabled={formIsSubmitting}
              />
            </FormRow>
            <FormRow>
              <FormField
                type="input"
                inputType="email"
                id="secondaryEmail"
                placeholder="Email"
                label="Email"
                onChange={this.handleSecondaryEmailChange}
                maxLength="256"
                value={secondaryEmail.value.value}
                fieldIsValid={secondaryEmail.isValid}
                disabled={formIsSubmitting}
                formIsValid={formIsValid}
                validationMessage="Email invalid"
              />
              <FormField
                type="input"
                inputType="text"
                id="secondaryEmailDesc"
                maxLength="256"
                onChange={this.handleSecondaryEmailDescChange}
                value={secondaryEmail.value.description}
                placeholder="Title"
                disabled={formIsSubmitting}
              />
            </FormRow>
          </FormFieldset>
          {isHiddenForQuickAdd && (
            <FormFieldset label="Websites">
              <FormRow>
                <FormField
                  autocomplete="off"
                  type="input"
                  id="primaryWebsite"
                  inputMode="url"
                  label="Primary Website"
                  maxLength="256"
                  onChange={this.handleWebsiteChange}
                  value={websites.value.primaryWebsite.data.value}
                  fieldIsValid={websites.value.primaryWebsite.isValid}
                  formIsValid={formIsValid}
                  disabled={formIsSubmitting}
                  ref={this.websiteRef}
                  validationMessage="URL invalid"
                />
              </FormRow>

              {websites.value.otherWebsites &&
                websites.value.otherWebsites.map((website, i) => {
                  const { data, isValid } = website;
                  const { value, description } = data || {};

                  return (
                    <FormRow key={`otherWebsites${i}`} className={i === 0 ? styles.otherCommsRow : undefined}>
                      <FormField
                        autocomplete="off"
                        type="input"
                        id={`otherWebsites${i}`}
                        inputMode="url"
                        data-otherwebsite={i}
                        label={i === 0 ? 'Other Websites' : null}
                        maxLength="256"
                        onChange={this.handleWebsiteChange}
                        value={value}
                        placeholder="Website"
                        disabled={formIsSubmitting}
                        fieldIsValid={isValid}
                        formIsValid={formIsValid}
                        validationMessage="URL invalid"
                      />
                      <FormField
                        type="input"
                        inputType="text"
                        id={`otherWebsitesDesc${i}`}
                        data-otherwebsite={i}
                        maxLength="256"
                        onChange={this.handleWebsiteDescChange}
                        value={description || ''}
                        placeholder="Title"
                        disabled={formIsSubmitting}
                      />
                    </FormRow>
                  );
                })}
              {websites.value.otherWebsites.length < MAX_OTHER_WEBSITES && (
                <FormRow>
                  <Button
                    label="Add Another Website"
                    ariaLabel="Add another website."
                    icon="website"
                    size="s"
                    styleType="white"
                    onClick={this.handleAddWebsite}
                  />
                </FormRow>
              )}
            </FormFieldset>
          )}
          {isHiddenForQuickAdd && (
            <FormFieldset label="Address">
              <FormRow>
                <FormField
                  id="addressLine"
                  ref={this.addressRef}
                  label="Street Address"
                  value={addressLine.value}
                  maxLength="256"
                  onChange={this.handleAddressChange}
                  disabled={formIsSubmitting}
                />
              </FormRow>
              <FormRow>
                <FormField
                  id="city"
                  type="input"
                  inputType="text"
                  label="City"
                  value={city.value}
                  maxLength="64"
                  onChange={this.handleAddressChange}
                  disabled={formIsSubmitting}
                />
                <div className={formFieldStyles.field}>
                  <FormLabel htmlFor="state">State/Province</FormLabel>
                  <Dropdown
                    id="state"
                    items={STATES_LIST}
                    className={styles.select}
                    onChange={this.handleStateChange}
                    value={selectedState ? selectedState.value : ''}
                    defaultValue="Select a state/prov..."
                    disabled={formIsSubmitting}
                  />
                </div>
              </FormRow>
              <FormRow>
                <FormField
                  id="zip"
                  type="input"
                  inputType="text"
                  label="Zip/Postal Code"
                  value={zip.value}
                  maxLength="32"
                  onChange={this.handleAddressChange}
                  disabled={formIsSubmitting}
                />
              </FormRow>
            </FormFieldset>
          )}
          {(isHiddenForQuickAdd || isFromRelationship) && (
            <FormFieldset label="Contact status">
              <FormHint className={styles.hint}>{contactStatusHint}</FormHint>
              <ContactStatus
                contact={contact}
                handleChange={externalContactStatusSource ? this.handleExternalContactStatusChange : this.handleChange}
                currentStatus={contactStatus.value}
                externalContactStatusSource={externalContactStatusSource}
                showAllZillowStatuses={true}
                selectedStatus={externalContactStatus.value}
                type="addContact"
                showAllStatuses={true}
              />
            </FormFieldset>
          )}
          <FormFieldset label="Contact types">
            <LookupBasic
              searchKey="contactType"
              searchList={contactTypeList}
              searchGroup={contactTypeGroups[currentContactTypeGroup]}
              entities={contactTypes.value}
              isEntityCreationEnabled={true}
              changeHandler={this.handleContactTypeChange}
              formIsSubmitting={formIsSubmitting}
              inputRef={this.contactTypeRef}
            />
            <FormHint>
              <strong>Note:</strong> To add a new contact type, type it in, and click the "Add" button that appears in
              the suggest menu.
            </FormHint>
          </FormFieldset>
          {isHiddenForQuickAdd && (
            <FormFieldset label="Buyer preferences">
              <BuyerPrefsForm
                isSeller={false}
                baths={noBaths}
                beds={noBeds}
                minPrice={this.getSelectedPrice(minPrice, this.MIN_PRICE_LIST) || ''}
                minPriceList={this.MIN_PRICE_LIST}
                maxPrice={this.getSelectedPrice(maxPrice, this.MAX_PRICE_LIST) || ''}
                maxPriceList={this.MAX_PRICE_LIST}
                sqrFt={sqrFt}
                onChange={this.handleBuyerPrefChange}
                disabled={this.state.formIsSubmitting}
                inputRef={this.minPriceRef}
              />
            </FormFieldset>
          )}
          <FormFieldset label="Source">
            <div>{this.renderLeadSources()}</div>
          </FormFieldset>
          {isHiddenForQuickAdd && !contact && (
            <FormFieldset label="Note">
              <FormField
                type="textarea"
                id="note"
                onChange={this.handleChange}
                placeholder="Add a note..."
                maxLength={DEFAULT_NOTE_MAX_LENGTH}
                value={note.value}
                size="s"
                disabled={formIsSubmitting}
                onValidate={this.handleNoteValidate}
                fieldIsValid={note.isValid}
                formIsValid={formIsValid}
              />
            </FormFieldset>
          )}

          <FormFieldset label="Assign to">
            <FormRow>
              {teamList && teamList.length > 1 ? (
                <Dropdown
                  id="assignedTo"
                  defaultValue={id.value && assignedToId.value ? '' : 'Unassigned'}
                  items={teamList}
                  value={assignedTo.value}
                  onChange={this.handleAssignToChange}
                />
              ) : (
                <FormFieldReadOnly id="assignedTo" value={assignedTo.value} />
              )}
            </FormRow>
          </FormFieldset>
        </Form>
        <FormFooter loading={formIsSubmitting}>
          <ButtonGroup>
            {displaySaveAndAddContactButton && (
              <Button
                form="contactForm"
                label="Save and Add Contact"
                ariaLabel="Save and add contact."
                type="submit"
                styleType="white"
                onClick={e => this.handleSubmit(e, true)}
                disabled={formIsSubmitting}
              />
            )}
            <Button
              form="contactForm"
              label="Save Contact"
              ariaLabel="Save contact."
              type="submit"
              styleType="primary"
              onClick={this.handleSubmit}
              disabled={formIsSubmitting}
            />
          </ButtonGroup>
        </FormFooter>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  teamList: getAssignedToOptions(state.team, 'agents'),
  contactCurrentGroup: state.contacts.currentGroup,
  contactTypeEntities: state.contacts.types.entities,
  contactTypeGroups: state.contacts.types.groups,
  currentContactTypeGroup: state.contacts.types.currentGroup,
  customTabs: state.preferences.customTabs,
  leadSourcePicklist: state.contacts.leadSourcePicklist,
  groups: state.contacts.groups,
  relationships: state.relationships
});

const mapDispatchToProps = {
  addContact,
  clearMessage,
  clearAlert,
  convertRelationship,
  getContactLeadSourcePicklist,
  saveContactLeadSource,
  saveContact,
  showAlert,
  showMessage,
  moveContact,
  deleteContactFromGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);

ContactForm.propTypes = {
  className: PropTypes.string
};
