import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { App } from './components';
import styles from './components/App/App.css';
import { configureStore } from './store';
import { checkLocalStorageVersion } from './utils';

if (window) {
  // before we do anything else, we need to check the local storage version to see if we need to purge it.
  checkLocalStorageVersion();
}

const store = configureStore();

const $app = document.getElementById('app');
// we add some module styles to the main #app element
$app.classList = styles.app;

ReactDOM.render(
  <Provider store={store}>
    <App className={styles.app} />
  </Provider>,
  $app
);
