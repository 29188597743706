import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Text } from 'slate';
import { jsx } from 'slate-hyperscript';
import collapse from 'collapse-whitespace';
import { default as parseStringToReact } from 'html-react-parser';

import { Element } from './Nodes/Element';
import { Leaf } from './Nodes/Leaf';
import { UNICODE } from '../../constants';
import { getStyleObjectFromStr } from '../../utils/dom';
import { emailSanitizer } from '../../utils/email';
import { removeHtml, getMergeCodeContent } from '../../utils/templates';

const { NBSP, ZERO_WIDTH_SPACE } = UNICODE;

// DEFAULT EDITOR CONFIGURATIONS

export const DEFAULT_EDITOR_BLOCK_ELEMENT = 'div';
export const DEFAULT_EDITOR_CONTENT = '';
export const DEFAULT_EDITOR_NODE = {
  type: DEFAULT_EDITOR_BLOCK_ELEMENT, // div
  children: [{ text: DEFAULT_EDITOR_CONTENT }]
};
export const EMAIL_SPACER_STR = '<div><br></div>';
export const TEMPLATE_HOTKEY = '{';
export const DEFAULT_TEMPLATE_TEXT = `${ZERO_WIDTH_SPACE}${ZERO_WIDTH_SPACE}`;
export const DEFAULT_EDITOR_SELECTION = {
  anchor: {
    offset: 0,
    path: [0, 0]
  },
  focus: {
    offset: 0,
    path: [0, 0]
  }
};

// EDITOR ELEMENT SUPPORT - including block, inline, and leaf nodes.

export const LIST_TYPES = ['ordered-list', 'bulleted-list'];
export const ELEMENT_TYPES = [
  'alignment',
  'block-quote',
  'div',
  'image',
  'link',
  'list-item',
  'template',
  ...LIST_TYPES
];

const BLOCK_NODES = {
  BLOCKQUOTE: () => ({ type: 'block-quote' }),
  DIV: el => ({ type: 'div', id: el.getAttribute('id') }), // We include id for div's so we can find some TPX specific IDs like tpxEmailSignature.
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'ordered-list' }),
  P: () => ({ type: 'div' }),
  TEMPLATE: () => ({ type: 'template' }),
  UL: () => ({ type: 'bulleted-list' })
};

const INLINE_NODES = {
  A: el => ({ type: 'link', url: el.getAttribute('href') }),
  IMG: el => ({
    type: 'image',
    url: el.getAttribute('src'),
    width: el.getAttribute('width'),
    height: el.getAttribute('height')
  })
};

const ELEMENT_NODES = {
  ...BLOCK_NODES,
  ...INLINE_NODES
};

const LEAF_NODES = {
  B: () => ({ bold: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  FONT: () => ({ font: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  SPAN: () => ({ span: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true })
};

// This regex looks for strings wrapped in double curly braces such as {{ something }}.
export const TEMPLATE_MERGE_CODE_REGEX = /{{[^{]+}}/g;

/**
 * Takes a merge code like `{{ something }}` and returns `something`, without the HTML tags.
 * @param {String} mergeCode - A merge code string.
 */
export const getTemplateKeyFromMergeCode = mergeCode => {
  const mergeCodeContent = getMergeCodeContent(mergeCode);
  const mergeCodeKey = removeHtml(mergeCodeContent);

  return mergeCodeKey;
};

/**
 * Takes an HTML string and wraps merge code strings with a temporary, DOMParser friendly span tag for slate node replacement.
 * @param {String} html - An HTML string.
 */
const interpolateHtml = html => {
  return html.replace(TEMPLATE_MERGE_CODE_REGEX, match => {
    const mergeCode = getMergeCodeContent(match);

    return `<span data-template="true">${mergeCode}</span>`;
  });
};

// EDITOR SERIALIZATION AND DESERIALIZATION METHODS

/**
 * Takes an HTML element and converts it to a Slate document.
 * @param {Object} el - The root HTML element node.
 */
export const deserialize = el => {
  const { nodeName, nodeType } = el;
  let tag = nodeName;

  if (nodeType === 3) {
    return el.textContent;
  } else if (nodeType !== 1) {
    return null;
  } else if (tag === 'BR') {
    return ''; // Note: In a document editor, \n would be the correct content here, but in email editing, this character replacement creates spacing bugs.
  }

  let children = [...el.childNodes].map(deserialize).flat();

  if (children.length === 0) {
    children.push({ text: '' });
  } else if (!children[0]) {
    children[0] = { text: '' };
  }

  // Before matching any normal html tags, we need to check for potential template tags and change the tag value if so.
  const isTemplateTag = el.dataset.template && el.dataset.template === 'true';
  if (isTemplateTag) {
    tag = 'TEMPLATE';
  }

  const styleAttr = el.getAttribute('style');
  const styles = styleAttr ? getStyleObjectFromStr(styleAttr) : {};
  const elAttrs = { styles };

  if (tag === 'FONT') {
    const colorAttr = el.getAttribute('color');
    const faceAttr = el.getAttribute('face');
    const sizeAttr = el.getAttribute('size');

    if (colorAttr) {
      if (!faceAttr && !sizeAttr) {
        // Some external editors don't use spans for font color, so we need to change the font tag to span.
        tag = 'SPAN';
      }

      elAttrs.fontColor = colorAttr;
    }

    if (faceAttr) {
      elAttrs.fontFamily = faceAttr;
    }

    if (sizeAttr) {
      elAttrs.fontSize = parseInt(sizeAttr);
    }
  }

  if (tag === 'SPAN') {
    const { backgroundColor, color, ...otherStyles } = styles;

    if (color) {
      elAttrs.fontColor = color;
    }

    if (backgroundColor) {
      elAttrs.fontBackground = backgroundColor;
    }

    elAttrs.style = otherStyles;
  } else {
    elAttrs.style = styleAttr;
  }

  if (tag === 'BODY') {
    return jsx('fragment', {}, children);
  }

  if (ELEMENT_NODES[tag]) {
    const attrs = { ...elAttrs, ...ELEMENT_NODES[tag](el) };

    return jsx('element', attrs, children);
  }

  if (LEAF_NODES[tag]) {
    const attrs = { ...elAttrs, ...LEAF_NODES[tag](el) };

    return children.map(child => jsx('text', attrs, child));
  }

  return children;
};

/**
 * Takes a Slate document and serializes it to an HTML string.
 * @param {Object} node - The root Slate document node.
 */
export const serialize = (node, options) => {
  const { children, text, type, ...otherProps } = node;
  const { cleanEmptyTemplateNodes = false } = options || {};

  // If the node is text, we return a Leaf or just the text.
  if (Text.isText(node)) {
    // Does the node only have a text key?
    const isOnlyText = text && Object.keys(node).length === 1;
    const isEmptyLeaf = text === '';
    const isLineBreak = text === '\n';

    if (isEmptyLeaf) {
      return '';
    }

    if (isLineBreak) {
      return '<br>';
    }

    // Replace "a   b c" with "a&nbsp;&nbsp; b c" (to match Gmail's behavior exactly.)
    // In a long run of spaces, all but the last space are converted to &nbsp;.
    // Note: This text is pushed through React's HTML serializer after we're done,
    // so we need to use `\u00A0` which is the unicode character for &nbsp;
    const serializedText = text.replace(/([ ]+) /g, match => `${match.replace(/ /g, NBSP)} `);

    if (isOnlyText) {
      return serializedText.replace(/\n/g, EMAIL_SPACER_STR); // on the final serialized return, we reshape this string spacer.
    }

    return ReactDOMServer.renderToStaticMarkup(<Leaf children={serializedText} leaf={otherProps} />);
  }

  // We use recursion to serialize each node's children.
  const serializedChildren =
    children
      ?.map(n => serialize(n, options))
      ?.join('')
      ?.trim() || '';

  if (ELEMENT_TYPES.includes(type)) {
    if (type === 'template') {
      if (cleanEmptyTemplateNodes) {
        return '';
      }

      return `{{${serializedChildren}}}`;
    }

    // When serializing, we don't want to use the image type, but rather image-basic, as we are outputting final HTML.
    const cleanType = type === 'image' ? 'image-basic' : type;
    const childrenAsReact = parseStringToReact(serializedChildren);

    return ReactDOMServer.renderToStaticMarkup(
      <Element children={childrenAsReact} element={{ type: cleanType, ...otherProps }} />
    );
  }

  // We don't want any div to end in an EMAIL_SPACER_STR, so we globally replace it by moving it outside the closing </div> tag.
  // We do this replace because line-breaks can exist inside text only nodes, and it is hard to fix this when recursively serializing above.
  return serializedChildren.replace(/<div><br><\/div><\/div>/g, '</div><div><br></div>');
};

/**
 * Takes a Slate document and serializes it to a document string.
 * @param {Object} node - The root Slate document node.
 */
export const serializeForTexting = node => {
  const { children, text, type } = node || {};

  // If the node is text, we return a Leaf or just the text.
  if (Text.isText(node)) {
    return text;
  }

  // We use recursion to serialize each node's children.
  const serializedChildren = children?.map(n => serializeForTexting(n))?.join('') || '';

  if (type === 'template') {
    return `{{${serializedChildren}}}`;
  }

  if (type === 'div') {
    return `${serializedChildren}<br>`;
  }

  // Replace break tags with line-breaks and then trim to clean any leading/trailing whitespace and line-breaks.
  return serializedChildren.replace(/<br>/g, '\n').trim();
};

/**
 * Takes a string of HTML and parses it into a format that is compatible with SlateJs.
 * In particular, Slate does not allow block nodes with a mix of text and block nodes as children.
 * This parser attempts to fix these child nesting issues, by wrapping text nodes blocks.
 * Modified from https://gist.github.com/bengotow/f5408e9cb543f22409d033df58e34579
 * @param {String} html - An HTML string.
 */
export const htmlParser = html => {
  const BLOCK_TAGS = Object.keys(BLOCK_NODES);
  const INLINE_TAGS = Object.keys(INLINE_NODES);
  const ELEMENT_TAGS = [...BLOCK_TAGS, ...INLINE_TAGS];
  const LEAF_TAGS = Object.keys(LEAF_NODES);

  // Prior to parsing the HTML string using DOMParser, we need to interpolate temporary template HTML for template strings like {{ something }}.
  const interpolatedHtml = interpolateHtml(html);

  // We create a tree from the wrapped HTML string. We make sure it is wrapped in a div so all of the HTML can
  // easily be deserialized.
  const parsed = new DOMParser().parseFromString(`<div>${interpolatedHtml}</div>`, 'text/html');
  const tree = parsed.body;

  // whitespace /between/ HTML nodes really confuses the parser because
  // it doesn't know these `text` elements are meaningless. Strip them all.
  collapse(tree);

  // Ensure that no BLOCKS contain both element children and text node children. This is
  // not allowed by Slate's core schema: blocks must contain inlines and text OR blocks.
  // https://docs.slatejs.org/guides/data-model#documents-and-nodes
  const elementWalker = document.createTreeWalker(tree, NodeFilter.SHOW_ELEMENT, {
    acceptNode: node => {
      return ELEMENT_TAGS.includes(node.nodeName) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });

  const needWrapping = [];
  while (elementWalker.nextNode()) {
    const element = elementWalker.currentNode;
    const hasBlockChild = [...element.childNodes].find(n => BLOCK_TAGS.includes(n.nodeName));
    if (hasBlockChild) {
      const textOrInlineChildren = [...element.childNodes].filter(n => !BLOCK_TAGS.includes(n.nodeName));
      needWrapping.push(...textOrInlineChildren);
    }

    // Slate's core doesn't support element or inline tags inside leaf tags, so we need to convert any leaf tags with
    // child element tags to an DIV tag (usually this means converting a span to div).
    const illegitimateLeafChildren = [...element.childNodes].filter(n => {
      if (!LEAF_TAGS.includes(n.nodeName)) {
        return false;
      }

      const hasElementChild = [...n.childNodes].find(el => ELEMENT_TAGS.includes(el.nodeName));

      return hasElementChild;
    });

    // For each illegitimate leaf child of the block (leaf element types we support that are wrapping a Slate element - which
    // violates Slate's core requirements), we change the leave to a div.
    illegitimateLeafChildren.forEach(leaf => {
      const newParentNode = document.createElement(DEFAULT_EDITOR_BLOCK_ELEMENT);
      leaf.parentNode.replaceChild(newParentNode, leaf);
      const leafFragment = document.createDocumentFragment();
      [...leaf.childNodes].forEach(n => {
        leafFragment.appendChild(n);
      });
      newParentNode.appendChild(leafFragment);
    });
  }

  needWrapping.forEach(n => {
    // no need to wrap <span></span> or a stray <a></a>, just remove these
    // pointless inline children
    if (n.textContent.length === 0) {
      n.remove();
      return;
    }

    const wrapped = document.createElement(DEFAULT_EDITOR_BLOCK_ELEMENT);
    n.parentNode.replaceChild(wrapped, n);
    wrapped.appendChild(n);

    // Now that we've wrapped the text node into a block, it forces a newline.
    // If it's preceded by a <br>, that <br> is no longer necessary.
    if (wrapped.previousSibling && wrapped.previousSibling.nodeName === 'BR') {
      wrapped.previousSibling.remove();
    }
  });

  // Any incoming <p> tags should be followed by <br> tags to maintain the intended spacing.
  const pWalker = document.createTreeWalker(tree, NodeFilter.SHOW_ELEMENT, {
    acceptNode: node => {
      return node.nodeName === 'P' ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });

  while (pWalker.nextNode()) {
    const p = pWalker.currentNode;
    if (p.nextSibling && p.textContent.trim().length) {
      const br = document.createElement('br');
      p.parentNode.insertBefore(br, p.nextSibling);
    }
  }

  return tree;
};

/**
 * Return a clean HTML string that has been sanitized and checked for Slate compatibility.
 * @param {String} htmlStr - An HTML string that needs to be sanitized and checked for Slate compatibility.
 * @returns {Sting} An HTML string.
 */
export const cleanHtmlForSlate = htmlStr => {
  // htmlParser wraps the content in a div that we don't need unless deserializing.
  return htmlParser(emailSanitizer(htmlStr)).querySelector('div').innerHTML;
};

/**
 * Sanitize, check HTML, and deserialize for Slate.
 * @param {String} htmlStr - An HTML string that needs to be sanitized and checked for Slate compatibility.
 * @returns {Object} A Slate compatible document.
 */
export const cleanAndDeserializeForSlate = htmlStr => {
  return deserialize(htmlParser(emailSanitizer(htmlStr)));
};

/**
 * Takes a string of text and converts it to HTML to use in the SlateJS editor.
 * @param {String} textMessage - A document style string.
 */
export const textToHtmlConverter = textMessage => {
  if (!textMessage) {
    return '';
  }

  const LINE_BREAK = '\n';

  const htmlMessage = textMessage?.split(LINE_BREAK).reduce((acc, str) => {
    const line = str === '' ? '<br>' : str;

    const htmlLine = `<div>${line}</div>`;

    return `${acc}${htmlLine}`;
  }, '');

  return htmlMessage;
};

/**
 * Convert text message and deserialize for Slate.
 * @param {String} textMessage - A document style string.
 * @returns {Object} A Slate compatible document.
 */
export const convertAndDeserializeTextMessageForSlate = textMessage => {
  return deserialize(htmlParser(textToHtmlConverter(textMessage)));
};

/**
 * Checks if a given message contains any unfilled merge codes.
 * A merge code is considered to be any text enclosed in double curly braces {{...}}.
 *
 * @param {string} message - The message string to be checked.
 * @returns {boolean} - Returns `true` if the message contains at least one merge code, otherwise `false`.
 */
export const hasMergeCode = message => {
  const regex = /\{\{[^{}]*\}\}/;
  return regex.test(message);
};
