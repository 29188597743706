import React from 'react';
import { string } from 'prop-types';
import { REGEX } from '../../constants';
import { getTimeFromDate } from '../../utils/dates';
import './FormTimeInput.css';

export const FormTimeInput = props => {
  const { id, time, disabled = false, isRequired, handleChange, handleValidation } = props;

  const formattedTime = getTimeFromDate(time);

  const handleOnChange = e => {
    const { value } = e.target;
    // input always return in 24-hour format
    handleChange(value);
  };

  return (
    <input
      type="time"
      id={id}
      required={isRequired}
      value={formattedTime}
      onChange={handleOnChange}
      onBlur={handleValidation}
      disabled={disabled}
      // input fallbacks to text when time input is not supported by the browser
      // this validates the text input
      pattern={REGEX.TIME_PICKER_FORMAT}
    />
  );
};

FormTimeInput.propTypes = {
  className: string
};
