/** @module */

import { deleteFromCollection, deleteFromGroup, getMergedEntities, getMergedSetOfIds } from '../utils/collections';

export const EMAILS_CURRENT_GROUP = 'EMAILS_CURRENT_GROUP';
export const EMAILS_DETAILS = 'EMAILS_DETAILS';
export const EMAILS_OPENED = 'EMAILS_OPENED';
export const EMAILS_DONE_PAGING = 'EMAILS_DONE_PAGING';
export const EMAILS_SET = 'EMAILS_SET';
export const EMAILS_MESSAGE_SET = 'EMAILS_MESSAGE_SET';
export const EMAILS_MESSAGE_UPDATE = 'EMAILS_MESSAGE_UPDATE';
export const EMAILS_ATTACHMENT_SET = 'EMAILS_ATTACHMENT_SET';
export const EMAILS_ATTACHMENTS_OBJECT_ID = 'EMAILS_ATTACHMENTS_OBJECT_ID';
export const EMAILS_LOADING = 'EMAILS_LOADING';
export const EMAILS_DELETE = 'EMAILS_DELETE';

export const initialState = {
  entities: {}, // threads
  messages: {}, // messages make up threads
  attachments: {}, // the attachments on a message
  groups: {}, // groups of threads
  attachmentGroups: {},
  isOpened: [],
  currentGroup: null,
  donePaging: {},
  isLoading: false,
  attachmentObjectId: null
};

/**
 * The contacts redux reducer.
 * @param {Object} state - the current state of the contacts store.
 * @param {Object} action - the action to take on the contacts store
 * @param {String} [action.type=default] - the action to take.
 * @param {Boolean} [action.isPaging] - Denotes whether the request data is due to paging.
 * @param {String} [action.group] - Acts as a key when storing groups of contacts.
 * @param {Boolean} [action.isLoading] - Denotes whether the data request is in progress.
 */
export const emailReducer = (state = initialState, action = {}) => {
  const {
    type = 'default',
    attachments,
    attachmentGroup,
    emailThreads,
    group = state.currentGroup,
    messages,
    messageId,
    messageDetails,
    openedEmails,
    isLoading,
    isPaging,
    attachmentObjectId,
    setCurrentGroup = true
  } = action;

  switch (type) {
    case EMAILS_SET:
      return {
        ...state,
        entities: getMergedEntities(state.entities, emailThreads),
        groups: {
          ...state.groups,
          [group]: getMergedSetOfIds(state.groups[group], emailThreads, { isPaging, sortGuidsFirst: true })
        },
        currentGroup: setCurrentGroup ? group : state.currentGroup,
        isLoading: isLoading
      };
    case EMAILS_CURRENT_GROUP:
      return {
        ...state,
        currentGroup: group,
        isLoading: isLoading
      };
    case EMAILS_MESSAGE_SET:
      return {
        ...state,
        messages: getMergedEntities(state.messages, messages)
      };
    case EMAILS_MESSAGE_UPDATE:
      return {
        ...state,
        messages: {
          ...state.messages,
          [messageId]: {
            ...state.messages[messageId],
            ...messageDetails
          }
        },
        entities: {
          ...state.entities,
          [messageDetails.thread_id]: {
            ...state.entities[messageDetails.thread_id],
            unread: messageDetails.unread
          }
        }
      };
    case EMAILS_ATTACHMENT_SET:
      return {
        ...state,
        attachments: getMergedEntities(state.attachments, attachments),
        attachmentGroups: {
          ...state.attachmentGroups,
          [attachmentGroup]: getMergedSetOfIds(state.attachmentGroups[attachmentGroup], attachments)
        }
      };
    case EMAILS_ATTACHMENTS_OBJECT_ID:
      return {
        ...state,
        attachmentObjectId: attachmentObjectId
      };
    case EMAILS_OPENED:
      return {
        ...state,
        isOpened: [...state.isOpened, ...openedEmails]
      };
    case EMAILS_DONE_PAGING:
      return {
        ...state,
        donePaging: {
          ...state.donePaging,
          [group]: true
        }
      };
    case EMAILS_LOADING:
      return {
        ...state,
        isLoading: isLoading
      };
    case EMAILS_DELETE:
      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, messageId)
        },
        groups: {
          ...state.groups,
          [group]: deleteFromGroup(state.groups[group], messageId)
        }
      };
    default:
      return state;
  }
};
