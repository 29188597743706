import React from 'react';
import { bool, number, shape, string } from 'prop-types';

import { Button } from '../Button';
import { Card } from './Card';
import { Tag } from '../Tag';

import styles from './MarketingCard.css';

export const MarketingCard = props => {
  const { data } = props;

  if (!data || !data.imgUrl) {
    return null;
  }

  function noop() {}

  const { description, imgSize, imgUrl, isNew = false, title, url, btnLabel = 'Learn more' } = data;
  const { height, width } = imgSize || {};

  return (
    <div className={styles.cardContainer}>
      <Card url={url}>
        <div className={styles.grid}>
          <img className={styles.img} src={imgUrl} loading="lazy" width={width} height={height} />
          <div className={styles.cardDetails}>
            <h2 className={styles.h2}>
              <span className={styles.cardTitle}>{title}</span> <Tag isVisible={isNew} label="New" />
            </h2>
            {description && <p className={styles.p}>{description}</p>}
          </div>
          {url && (
            <div className={styles.cardDetails}>
              <Button ariaLabel={btnLabel} label={btnLabel} onClick={noop} styleType="transparent" />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

MarketingCard.propTypes = {
  data: shape({
    isNew: bool,
    imgSize: shape({ width: number, height: number }),
    imgUrl: string.isRequired,
    description: string,
    title: string,
    url: string
  })
};
