export const PRIVACY_LINKS = [
  {
    url: 'https://www.topproducer.com/subscriberagreement/tp-tos.html',
    label: 'Subscriber Agreement',
    openAs: 'popup'
  },
  {
    url: 'https://www.topproducer.com/privacy/',
    label: 'Privacy Policy',
    openAs: 'popup'
  }
];
