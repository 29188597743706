import { Location, Redirect, Router } from '@reach/router';
import classnames from 'classnames';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { getCalendarSyncAccounts, getCalendarSyncStatus } from '../../actions/calendarSync';
import { getContactTypes, getFollowUpCount } from '../../actions/contacts';
import { getContactSyncAccounts, getContactSyncStatus } from '../../actions/contactSync';
import { showDrawer } from '../../actions/drawer';
import { getEmailAccountDetails } from '../../actions/email';
import { getEmailAccounts } from '../../actions/settings-email';
import { getSnapshotAccountDetails, getSnapshotSettings } from '../../actions/snapshot';
import { getMlsCredentials } from '../../actions/mls';
import { getTeam, getTeamAgents } from '../../actions/team';
import { checkTextingRegistration } from '../../actions/texting';
import { getEula, getUserInfo } from '../../actions/userProfile';
import { clearWrapup } from '../../actions/wrapup';
import { Announcement, Container, LinkList, NavBar, TrackPageViews, WrapupDialog } from '../../components';
import { CheckVersion } from '../../components/CheckVersion/CheckVersion';
import { ComposeButton } from '../../components/Button/ComposeButton';
import { HeaderAuthenticated } from '../../components/Header';
import { Drawer } from '../../components/Drawer/Drawer';
import { PendoScript } from '../../components/PendoScript';
import { ANNOUNCEMENT_LIVE } from '../../constants';
import { SUPPORT_LINKS } from '../../data/support-links';
import { Contacts, LazyPage, Notes, Settings, Tasks } from '../../pages';
import NotFound from '../../pages/NotFound/NotFound';
import { shouldNewUserBePrompted } from '../../utils/authentication';
import styles from './LayoutAuthenticated.css';
import Transactions from '../../pages/Transactions/Transactions';
import Marketing from '../../pages/Marketing/Marketing';
import Dashboard from '../../pages/Dashboard/Dashboard';
import { getSalesforceStatus } from '../../actions/salesforce';
import { clearAlert, showAlert } from '../../actions';
import { PROBATION_MESSAGE, PROBATION_MESSAGE_TEAM } from '../../constants/signin';
import { setScrollbarSize } from '../../utils/dom';
import AIDialog from '../../components/Editor/Toolbar/AIDialog';
import { checkIsTextingAllowed } from '../../utils/texting';

export class LayoutAuthenticated extends Component {
  async componentDidMount() {
    const {
      contacts,
      checkTextingRegistration,
      getContactSyncAccounts,
      getContactSyncStatus,
      getCalendarSyncAccounts,
      getCalendarSyncStatus,
      getContactTypes,
      getEmailAccounts,
      getEula,
      getMlsCredentials,
      getSnapshotAccountDetails,
      getSnapshotSettings,
      getTeam,
      getTeamAgents,
      getUserInfo,
      user
    } = this.props;
    const { userId } = user;

    // get the user info so we can know whether to show the texting feature
    await Promise.all([getUserInfo(), getTeam(), getTeamAgents()]);

    getSnapshotAccountDetails({ tpoAgentId: userId }).then(data => {
      if (data && data.tmkAgentId !== '00000000-0000-0000-0000-000000000000') {
        getSnapshotSettings({ agentId: data.tmkAgentId });
      }
      // Regardless of success, we need to check MLS credentials also.
      getMlsCredentials();
    });

    getEmailAccounts();
    getContactTypes({ q: contacts.types.currentGroup });
    checkTextingRegistration({ id: userId });
    // Get Eula for the texting feature
    getEula({ userid: user.userId, feature: 'SMS' });

    getEula({ userid: user.userId, feature: 'AI' });
    getContactSyncAccounts();
    getContactSyncStatus();
    getCalendarSyncAccounts();
    getCalendarSyncStatus();
    setScrollbarSize();
  }

  componentDidUpdate(prevProps) {
    // We load contact types on the first load of any contact data, not on mount, because this data is secondary and should wait for any other screens to load.
    // We might want to consider what happens when someone comes in via notes
    const { contacts: prevContacts, emailAccounts: prevEmailAccounts, userProfile: prevUserProfile } = prevProps;
    const {
      contacts,
      emailAccounts,
      getEmailAccountDetails,
      getFollowUpCount,
      getTeam,
      userProfile,
      getSalesforceStatus,
      showAlert,
      clearAlert,
      user
    } = this.props;

    const { hasSalesforceSSO: prevHasSalesforceSSO } = prevUserProfile?.userInfo || {};
    const { hasSalesforceSSO } = userProfile?.userInfo || {};

    if (prevHasSalesforceSSO == null && user?.isProbationAccount) {
      showAlert({
        message: hasSalesforceSSO ? PROBATION_MESSAGE : PROBATION_MESSAGE_TEAM,
        primaryButtonHandler: clearAlert,
        primaryButtonLabel: 'Continue',
        secondaryButtonLabel: hasSalesforceSSO ? 'Update Payment' : null,
        secondaryButtonHandler: hasSalesforceSSO ? () => getSalesforceStatus(window.location) : null,
        icon: 'error'
      });
    }
    if (prevContacts.currentGroup === null && contacts.currentGroup !== null) {
      getFollowUpCount();
      getTeam();
    }

    if (!window.location.href.includes('email-integration') && emailAccounts.group !== prevEmailAccounts.group) {
      emailAccounts.group.forEach(accountId => {
        getEmailAccountDetails({ accountId });
      });
    }
  }

  render() {
    const { clearWrapup, contacts, preferences, user, userProfile, wrapup } = this.props;
    const { hasContacts } = contacts;
    const { defaultContactsPath } = preferences || {};
    const isTextingAllowed = checkIsTextingAllowed(userProfile);

    return (
      <Container className={styles.layout}>
        <Location>
          {({ location }) => {
            const { pathname } = location;
            const pathArr = pathname.split('/');
            const locationDepth = pathArr.length - 1;
            const hasNeededDepth = locationDepth > 1;
            const firstPathFragment = pathArr[1];
            const isApprovedPath =
              ['calendar', 'marketing', 'settings'].includes(firstPathFragment) &&
              !pathname.startsWith('/marketing/task-plan-manager/');
            const isDetailsPage = hasNeededDepth && !isApprovedPath && pathArr[2] !== 'important-dates';

            // Check whether the announcement component should be rendered.
            const isContactsLoading = hasContacts === null;
            const promptNewUser = shouldNewUserBePrompted(
              hasContacts,
              pathname,
              user.isAssistant,
              contacts.statusCounts
            );
            const hideAnnouncement = !ANNOUNCEMENT_LIVE || isContactsLoading || promptNewUser;

            const sidebarClasses = classnames({
              [styles.sidebar]: true,
              [styles.isDetailsPage]: isDetailsPage // used when responsive size is smartphone.
            });

            return (
              <Fragment>
                <HeaderAuthenticated isDetailsPage={isDetailsPage} location={location} />

                <div className={sidebarClasses}>
                  <NavBar testId="NavBar" defaultContactsPath={defaultContactsPath} />
                  <div className={styles.composeButtonWrap}>
                    <ComposeButton isTextingAllowed={isTextingAllowed} />
                  </div>
                  <LinkList data={SUPPORT_LINKS} orientation="vertical" type="nav" className={styles.supportLinkList} />
                </div>

                <Container role="main" className={styles.body}>
                  <Router className={styles.pageContainer}>
                    <Redirect from="/" to={'dashboard'} noThrow />
                    {/* Chatter needs this redirect to support legacy texting links. */}
                    <Redirect from="contacts/:contactId/send-text/texts" to="contacts/:contactId/texts" noThrow />
                    <Contacts testId="Contacts" path="contacts">
                      <LazyPage path="add" page="contactAdd" />
                      <LazyPage path="create-tab" page="contactCreateTab" />
                      <LazyPage path=":contactId/*" page="contactDetail" />
                      <LazyPage path="quick-add" page="contactAdd" />
                    </Contacts>
                    <Contacts testId="Contacts" path="contacts/templates/*" />
                    <Tasks testId="Tasks" path="tasks">
                      <LazyPage testId="importantDates" path="important-dates" page="importantDates" variant="tasks" />
                      <LazyPage testId="taskDetail" path=":taskId/*" page="taskDetail" />
                    </Tasks>
                    <Notes path="notes">
                      <LazyPage path=":noteId" page="noteDetail" group="myNotes" />
                    </Notes>
                    <NotFound default />
                    <LazyPage path="menu" page="menu" defaultContactsPath={defaultContactsPath} />
                    <Settings path="settings">
                      <LazyPage path="texting" page="texting" />
                      <LazyPage path="import" page="import" />
                      <LazyPage path="lead-providers" page="leads" />
                      <LazyPage path="mls-credentials/*" page="mlsCredentials" />
                      <LazyPage path="market-snapshot" page="marketSnapshot" />
                      <LazyPage path="my-information" page="myInfo" />
                      <LazyPage path="google-sync" page="syncSetup" />
                      <LazyPage path="email-integration" page="emailIntegration" />
                      <LazyPage path="email-signatures/*" page="emailSignatures" />
                      <LazyPage path="calendar-sync" page="calendarSync" />
                      <LazyPage path="contact-sync" page="contactSync" />
                      <LazyPage path="homeintelle-settings" page="homeIntelSettings" />
                      <LazyPage path="team-management" page="teamManagement" />
                      <LazyPage path="ui-settings/*" page="uiSettings" />
                      <LazyPage path="z-connect" page="zConnect" />
                      <LazyPage path="notifications" page="notifications" />
                    </Settings>
                    <Redirect from="/marketing" to="/marketing/social-connect" noThrow />
                    <Marketing path="marketing">
                      <LazyPage path="/*" page="snapshotReports" showAgentReport={true} />
                      <LazyPage path="listing-alerts/*" page="listingAlerts" showAgentReport={true} />
                      <LazyPage path="home-intelle/*" page="homeIntelReports" showAgentReport={true} />
                      <LazyPage path="template-library/*" page="templates" />
                      <LazyPage path="task-plan-manager/*" page="taskPlans" />
                      <LazyPage path="task-plan-manager/:planId/:action/*" page="taskPlans" />
                      <LazyPage path="task-plan-templates/*" page="taskPlanTemplates" />
                      <LazyPage path="lead-response-rules/*" page="leadAutoResponse" />
                      <LazyPage path="smart-targeting" page="smartTargeting" />
                      <LazyPage path="social-connect" page="socialConnect" />
                    </Marketing>
                    <LazyPage path="whats-new" page="whatsNew" />
                    <LazyPage path="notice-texting-calling-emailing" page="noticeTexting" />
                    <LazyPage path="/unsubscribe/*" page="unsubscribe" />
                    <LazyPage path="/texting-opt-in/*" page="textingOptIn" />
                    <LazyPage path="/calendar/*" page="calendar" />
                    <Transactions testId="Transactions" path="transactions" location={location}>
                      <LazyPage path=":transactionId/*" page="transactionDetail" />
                    </Transactions>
                    <Dashboard path="/dashboard">
                      <LazyPage testId="taskDetail" path="view/tasks/:taskId/*" page="taskDetail" />
                      <LazyPage testId="contactDetail" path="view/contacts/:contactId/*" page="contactDetail" />
                      <LazyPage
                        testId="transactionDetail"
                        path="view/transactions/:transactionId/*"
                        page="transactionDetail"
                      />
                    </Dashboard>
                  </Router>
                </Container>

                <WrapupDialog {...wrapup} clearHandler={clearWrapup} />
                <AIDialog />
                <TrackPageViews location={location} />
                <Announcement hideAnnouncement={hideAnnouncement} />
                <CheckVersion location={location} />
                <Drawer location={location} />
              </Fragment>
            );
          }}
        </Location>
        <PendoScript />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  emailAccounts: state.settings.email,
  contacts: state.contacts,
  preferences: state.preferences,
  user: state.user,
  userProfile: state.userProfile,
  wrapup: state.wrapup
});

const mapDispatchToProps = {
  checkTextingRegistration,
  clearWrapup,
  getContactSyncAccounts,
  getContactSyncStatus,
  getCalendarSyncAccounts,
  getCalendarSyncStatus,
  getContactTypes,
  getEmailAccounts,
  getEmailAccountDetails,
  getFollowUpCount,
  getMlsCredentials,
  getTeam,
  getTeamAgents,
  getSnapshotAccountDetails,
  getSnapshotSettings,
  getEula,
  getUserInfo,
  showDrawer,
  getSalesforceStatus,
  showAlert,
  clearAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAuthenticated);
