import React, { Component, StrictMode } from 'react';
import { connect } from 'react-redux';
import { Location } from '@reach/router';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { clearAlert } from '../../actions/';
import { clearMessage } from '../../actions/message';
import { LiveAgentHelmet } from '../../components/LiveAgentHelmet';
import { AlertDialog, Authentication, Message } from '../';
import { default as ErrorBoundary } from '../ErrorBoundary';
import { CheckOnline } from '../CheckOnline';
import '../../assets/android-chrome-192x192.png';
import '../../assets/android-chrome-512x512.png';
import '../../assets/apple-touch-icon.png';
import '../../assets/favicon-16x16.png';
import '../../assets/favicon-32x32.png';
import '../../assets/favicon.ico';
import '../../assets/mstile-150x150.png';
import '../../assets/safari-pinned-tab.svg';

import '../../css/root.css';

import { DEFAULT_TITLE_TAG } from '../../constants';

export class App extends Component {
  render() {
    const { alert, clearAlert, clearMessage, message } = this.props;

    return (
      <StrictMode>
        <ErrorBoundary>
          <Location>
            {({ location }) => {
              const { pathname } = location;
              return <CheckOnline location={pathname.replace('/', '')} />;
            }}
          </Location>
          <HelmetProvider>
            <Helmet>
              <title>{DEFAULT_TITLE_TAG}</title>
            </Helmet>
            <LiveAgentHelmet />
            <Authentication />
          </HelmetProvider>
          <Message data={message} clearHandler={clearMessage} />
          <AlertDialog data={alert} clearHandler={clearAlert} />
        </ErrorBoundary>
      </StrictMode>
    );
  }
}

const mapStateToProps = state => ({
  alert: state.alert,
  message: state.message
});

const mapDispatchToProps = {
  clearAlert,
  clearMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
