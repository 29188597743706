import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { clearDrawer } from '../../actions/drawer';
import { clearWrapup } from '../../actions/wrapup';
import { DialogHeader } from '../Dialog/DialogHeader';
import { LazyPage } from '../../pages';
import { trackEvent } from '../../utils/analytics';
import { ENTITY_TYPES } from '../../utils/notes';
import { WRAPUP_TYPES } from '../../utils';
import { DATE_FORMATS } from '../../constants';

import styles from './Drawer.css';
import { setAIContent } from '../../actions/openai';

const SUPPORTED_DRAWER_TYPES = [
  'emailForm',
  'notesForm',
  'updateNotesForm',
  'snapshotForm',
  'taskForm',
  'textForm',
  'updateSnapshotForm',
  'wrapupForm'
];

const DRAWER_CONFIG = {
  emailForm: {
    icon: 'email',
    title: 'Compose email',
    trkCategory: 'email',
    trkEvent: 'composeEmailOpen'
  },
  notesForm: {
    icon: 'noteAdd',
    title: 'Add note',
    trkCategory: 'notes',
    trkEvent: 'addNoteOpen'
  },
  updateNotesForm: {
    icon: 'noteAdd',
    title: 'Edit note',
    trkCategory: 'notes',
    trkEvent: 'updateNoteOpen'
  },
  snapshotForm: {
    icon: 'snapshot',
    title: 'Send Snapshot',
    trkCategory: 'snapshot',
    trkEvent: 'sendSnapshotOpen'
  },
  updateSnapshotForm: {
    icon: 'snapshot',
    title: 'Update Snapshot',
    trkCategory: 'snapshot',
    trkEvent: 'updateSnapshotOpen'
  },
  taskForm: {
    icon: 'addtask',
    title: 'Add task',
    trkCategory: 'tasks',
    trkEvent: 'addTaskOpen'
  },
  textForm: {
    icon: 'texting',
    title: 'Send text',
    trkCategory: 'texting',
    trkEvent: 'sendTextOpen'
  },
  wrapupForm: {
    icon: 'wrapup',
    title: `Wrap-up`,
    trkCategory: 'wrapup',
    trkEvent: 'sendWrapupOpen'
  }
};

const DEFAULT_FORM_PROPS = {
  notesForm: {
    group: 'myNotes'
  },
  default: {} // We want this to be an empty object so that we render the drawer if no default form props are needed.
};

const getDrawerProps = (
  drawerType,
  teamMemberId,
  contactId,
  pathname,
  tasksEntities,
  insightId,
  insightsCurrentGroup,
  noteId,
  notesCurrentGroup,
  closeCallback,
  currentTask,
  contactStatus
) => {
  const pathArray = pathname.split('/');

  const pathType = pathArray[1];
  const isSubpage = pathArray.length > 2;
  const contactIdFromTaskSubpagePathname = extractContactIdFromTaskSubpagePathname(pathname);

  const subPageType = pathArray[3];
  const isDashboard = pathType === 'dashboard';
  const isDashboardContacts = isDashboard && subPageType === 'contacts';
  const isDashboardTransactions = isDashboard && subPageType === 'transactions';
  const isDashboardTasks = isDashboard && subPageType === 'tasks';

  // when invoked from NoteList nested in Contact/Tasks
  const isNoteFromNestedTask = pathArray[pathArray.length - 1] === 'tasks';

  // The id used for the drawer can either come from the open contact or task details url fragment or
  // an explicitly passed in id when opening the drawer.
  const id = contactId || (pathType === 'dashboard' ? pathArray[4] : pathArray[2]);

  // insight id is passed from redux instead.
  const isInsightNote =
    drawerType === 'notesForm' && insightId && pathArray.length === 4 && pathArray[3] === 'property-insights';
  if (isInsightNote) {
    return {
      group: `insight::${insightId}`,
      tags: [
        {
          id: insightId,
          type: ENTITY_TYPES.insight
        },
        {
          id: insightsCurrentGroup, // the current insight group is always the contact id
          type: ENTITY_TYPES.contact
        }
      ]
    };
  } else if (teamMemberId && drawerType === 'emailForm') {
    return {
      teamMemberId
    };
  } else if (contactId || (isSubpage && (pathType === 'contacts' || isDashboardContacts))) {
    // If notesForm engaged from contact details.
    if (drawerType === 'notesForm') {
      if (!isNoteFromNestedTask) {
        return {
          group: `contact::${id}`,
          tags: [
            {
              id,
              type: ENTITY_TYPES.contact
            }
          ]
        };
      }
      const tags = [
        {
          id: currentTask,
          type: ENTITY_TYPES.task
        }
      ];
      const taskContacts = tasksEntities[currentTask]?.contacts;

      if (taskContacts) {
        taskContacts.forEach(contact => {
          tags.push({
            id: contact.id,
            type: ENTITY_TYPES.contact
          });
        });
      }

      return {
        group: `task::${currentTask}`,
        tags
      };
    }
    if (drawerType === 'updateNotesForm') {
      return {
        group: `contact::${id}`,
        id: noteId,
        closeCallback
      };
    }
    if (drawerType === 'wrapupForm') {
      return {
        contactId: id,
        contactStatus
      };
    }
    // If all other forms engaged from contact details.
    return {
      contactId: id
    };
  } else if (isSubpage && (pathType === 'tasks' || isDashboardTasks) && drawerType === 'notesForm') {
    // If notesForm engaged from task details.
    const tags = [
      {
        id,
        type: ENTITY_TYPES.task
      }
    ];
    const taskContacts = tasksEntities[id]?.contacts;

    if (taskContacts) {
      taskContacts.forEach(contact => {
        tags.push({
          id: contact.id,
          type: ENTITY_TYPES.contact
        });
      });
    }

    return {
      group: contactIdFromTaskSubpagePathname ? `contact::${contactIdFromTaskSubpagePathname}` : `task::${id}`,
      tags
    };
  } else if (isSubpage && (pathType === 'transactions' || isDashboardTransactions)) {
    if (drawerType === 'notesForm') {
      if (isNoteFromNestedTask) {
        const tags = [
          {
            id: currentTask,
            type: ENTITY_TYPES.task
          }
        ];
        const taskContacts = tasksEntities[currentTask]?.contacts;

        if (taskContacts) {
          taskContacts.forEach(contact => {
            tags.push({
              id: contact.id,
              type: ENTITY_TYPES.contact
            });
          });
        }

        return {
          group: `task::${currentTask}`,
          tags
        };
      }
      return {
        group: `transaction::${id}`,
        tags: [
          {
            id,
            type: ENTITY_TYPES.transaction
          }
        ]
      };
    }
    if (drawerType === 'updateNotesForm') {
      return {
        group: `transaction::${id}`,
        tags: [
          {
            id,
            type: ENTITY_TYPES.transaction
          }
        ],
        id: noteId
      };
    }
    if (drawerType === 'taskForm') {
      return {
        transactionId: isDashboardTransactions ? pathArray[4] : pathArray[2]
      };
    }
  } else if (isSubpage && (pathType === 'tasks' || isDashboardTasks) && contactIdFromTaskSubpagePathname) {
    return {
      contactId: contactIdFromTaskSubpagePathname
    };
  } else if (drawerType === 'updateNotesForm') {
    return {
      id: noteId,
      group: notesCurrentGroup,
      closeCallback
    };
  } else if (drawerType === 'notesForm' && pathType === 'calendar') {
    const tags = [
      {
        id: currentTask,
        type: ENTITY_TYPES.task
      }
    ];
    const taskContacts = tasksEntities[currentTask]?.contacts;

    if (taskContacts) {
      taskContacts.forEach(contact => {
        tags.push({
          id: contact.id,
          type: ENTITY_TYPES.contact
        });
      });
    }

    return {
      group: `task::${currentTask}`,
      tags
    };
  }
  // If no contactId, or not a subpage, return the default props for the given drawerType.
  return DEFAULT_FORM_PROPS[drawerType] || DEFAULT_FORM_PROPS.default;
};

export const Drawer = props => {
  const dispatch = useDispatch();
  const [drawerProps, setDrawerProps] = useState(null);
  const drawer = useSelector(state => state.drawer);
  const tasksEntities = useSelector(state => state.tasks.entities);
  const currentTask = useSelector(state => state.tasks.currentEntity);
  const insightsCurrentGroup = useSelector(state => state.propertyInsights.currentGroup);
  const notesCurrentGroup = useSelector(state => state.notes.currentGroup);
  const wrapup = useSelector(state => state.wrapup);
  const { currentContent } = useSelector(state => state.aiAuthor);
  const { location } = props;
  const { pathname } = location;
  const { teamMemberId, insightId, contactId, drawerType, isOpen, noteId, closeCallback, isMinimized } = drawer || {};
  const { contactStatus } = wrapup;

  const { icon, title, trkCategory, trkEvent } = DRAWER_CONFIG[drawerType] || {};

  useEffect(() => {
    if (isOpen === true) {
      // We only want to set the drawerProps on open, as we don't want them to change if the user navigated while the drawer is open.
      setDrawerProps(
        getDrawerProps(
          drawerType,
          teamMemberId,
          contactId,
          pathname,
          tasksEntities,
          insightId,
          insightsCurrentGroup,
          noteId,
          notesCurrentGroup,
          closeCallback,
          currentTask,
          contactStatus
        )
      );
      // Track the open.
      trackEvent(trkCategory, trkEvent);
    } else {
      setDrawerProps(null);
    }

    // we need to reset drawer props only when it is just opened or when its type changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, drawerType]);

  if (!isOpen || !drawerProps || !SUPPORTED_DRAWER_TYPES.includes(drawerType)) {
    if (drawerType && !SUPPORTED_DRAWER_TYPES.includes(drawerType)) {
      console.warn(
        `The drawer type of ${drawerType} is not supported. Check that you've added it to SUPPORTED_DRAWER_TYPES.`
      );
    }
    return null;
  }

  const stopDialogPropagation = e => {
    e.stopPropagation();
  };

  const closeDialog = () => {
    setDrawerProps(null);
    if (currentContent) {
      dispatch(setAIContent());
    }
    dispatch(clearDrawer());
    if (wrapup.wrapupActivity) {
      dispatch(clearWrapup());
    }
  };

  const drawerClasses = classnames({
    [styles.drawer]: true,
    [styles.fadeIn]: !isMinimized
  });

  const drawerBodyClasses = classnames({
    [styles.isMinimized]: isMinimized
  });

  const wrapupTitle =
    drawerType === 'wrapupForm' &&
    `${title} - ${WRAPUP_TYPES[wrapup?.wrapupActivity || 'call']?.label} - ${format(
      Date.now(),
      DATE_FORMATS.SHORT_DATE_WITH_YEAR
    )}`;

  return (
    <div id="drawer" className={drawerClasses} onClick={stopDialogPropagation}>
      <DialogHeader title={wrapupTitle || title} icon={icon} clearHandler={closeDialog} variant="drawer" />

      <div className={drawerBodyClasses}>
        <LazyPage page={drawerType} location={location} isNested={true} closeCallback={closeDialog} {...drawerProps} />
      </div>
    </div>
  );
};

Drawer.propTypes = {
  location: object.isRequired
};

/**
 * Extracts the contactId in the pathname assuming it's a tasks sub page.
 * pathname example: tasks/c331af7c-d093-4712-8a65-6f18d755e4bb/contact-79b96504-bbe5-4a39-a50b-9caefc17997a/texts
 * @param {String} pathname pathname in the location object
 */
function extractContactIdFromTaskSubpagePathname(pathname) {
  return pathname?.split('contact-')[1]?.split('/')[0];
}
