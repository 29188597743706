import { Editor, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import {
  convertAndDeserializeTextMessageForSlate,
  DEFAULT_EDITOR_NODE,
  DEFAULT_TEMPLATE_TEXT,
  ELEMENT_TYPES,
  LIST_TYPES,
  TEMPLATE_HOTKEY
} from './editor-utils';
import { UNICODE } from '../../constants';

const { ZERO_WIDTH_SPACE } = UNICODE;

export const EditorCommands = {
  deselectAndPlaceCursorAfter(editor) {
    Transforms.collapse(editor, { edge: 'end' });
  },

  getBlockNodeToSet(isActive, isList, command, value, matchingNode) {
    const children = [];
    const DEFAULT_VALUES = ['left']; // Some commands have default values that don't need application. i.e. Aligning left.
    const { [command]: matchingNodeValue } = matchingNode || {};
    const valuesMatch = value === matchingNodeValue;

    // We should remove the command from the block if the command isActive and its value matches.
    // Values can be undefined and match, as well as string matches ('left' === 'left').
    // We also remove the command if value matches an editor default such as left alignment.
    const commandShouldBeRemoved = (isActive && valuesMatch) || DEFAULT_VALUES.includes(value?.toLowerCase());

    // If the commandShouldBeRemoved, we reset the block to the editor default.
    if (commandShouldBeRemoved) {
      return DEFAULT_EDITOR_NODE;
    }

    // If the command is for list type, we set the block to a list-item.
    if (isList) {
      return {
        type: 'list-item',
        children
      };
    }

    // Otherwise, we set block to the command.
    return {
      type: command,
      [command]: value,
      children
    };
  },

  getMatchingNode(editor, command) {
    const [match] = Editor.nodes(editor, {
      match: node => node.type === command
    });

    return match || []; // [node, path]
  },

  getPrevChar(editor) {
    const { selection } = editor;
    const { anchor, focus } = selection;
    const { offset: anchorOffset, path: anchorPath } = anchor;
    // Using the anchor from the selection, we figure out the range and text of the previous character.
    const prevCharOffset = anchorOffset > 0 ? anchorOffset - 1 : 0;
    const rangeOfPrevChar = { anchor: { offset: prevCharOffset, path: anchorPath }, focus };
    const textOfPrevChar = Editor.string(editor, rangeOfPrevChar);

    return { text: textOfPrevChar, range: rangeOfPrevChar };
  },

  getSelectionMarkValue(editor, command) {
    const marks = Editor.marks(editor) || {};

    return marks[command];
  },

  getSelectionNodeValue(editor, command) {
    const [node] = EditorCommands.getMatchingNode(editor, command);
    return node?.[command];
  },

  getSelectionText(editor) {
    const { selection } = editor;
    return selection && Editor.string(editor, selection);
  },

  giveFocusAndMoveCursorForward(editor) {
    // Give focus back to the editor,
    ReactEditor.focus(editor);
    // Move the cursor forward (can be after a void element like an image).
    Transforms.move(editor, { distance: 1 });
  },

  handleMarkStyleChange(editor, command, value) {
    ReactEditor.focus(editor);
    Editor.addMark(editor, command, value);
  },

  history(editor, command) {
    if (command === 'undo') {
      editor.undo();
    } else {
      editor.redo();
    }
  },

  insertImage(editor, url) {
    const text = { text: '' };
    const image = { type: 'image', url, children: [text] };

    Transforms.insertNodes(editor, image, { select: true });
  },

  insertLink(editor, url, textToLink) {
    // We need to refocus the editor and reselect the text after the dialog closes.
    ReactEditor.focus(editor);
    Transforms.select(editor, editor.savedSelection);

    EditorCommands.wrapLink(editor, url, textToLink);
  },

  insertRawTemplate(editor, template) {
    const slateFragment = convertAndDeserializeTextMessageForSlate(template);

    // 1. We first make sure to insert a space.  This will allow us to place the cursor properly after template insertion.
    Transforms.insertText(editor, ' ');
    // 2. Once the space is inserted, we move the cursor back to the previous location.
    Transforms.move(editor, { distance: 1, reverse: true, edge: 'start' });
    Transforms.collapse(editor, { edge: 'start' });
    // 3. We insert the template.
    Transforms.insertFragment(editor, slateFragment);
    // 4. We move forward one spot after insertion in case we are left on a selected inline node like a template, image, or link.
    Transforms.move(editor, { distance: 1 });
  },

  insertTemplate(editor) {
    const isFocused = ReactEditor.isFocused(editor);

    if (!isFocused) {
      // If the editor isn't in focus, we need to set focus, so we can insert the template node.
      // This case occurs most often when the template toolbar button is clicked.
      ReactEditor.focus(editor);

      const newSelection = editor.savedSelection || Editor.start(editor, [0, 0]);
      Transforms.select(editor, newSelection);
    }

    const isTemplate = EditorCommands.isBlockActive(editor, 'template');

    if (isTemplate) {
      // We don't want to add a template within a template.
      return;
    }

    // 1. We insert the DEFAULT_TEMPLATE_TEXT (2 ZERO_WIDTH_SPACEs).
    editor.insertText(DEFAULT_TEMPLATE_TEXT);
    // 2. We walk back and place the start of the selection before the DEFAULT_TEMPLATE_TEXT, resulting
    // in the DEFAULT_TEMPLATE_TEXT being selected.
    Transforms.move(editor, { distance: 2, reverse: true, edge: 'start' });
    // 3. We create a template node, and wrap it around the selected DEFAULT_TEMPLATE_TEXT.
    const templateNode = { type: 'template', children: [] };
    Transforms.wrapNodes(editor, templateNode, { split: true });
    // 4. We move the start of the selection forward 1 spot, and then we collapse the selection on the new start position,
    // leaving the cursor sitting between the two ZERO_WIDTH_SPACEs of the DEFAULT_TEMPLATE_TEXT.
    Transforms.move(editor, { distance: 1, edge: 'start' });
    Transforms.collapse(editor, { edge: 'start' });
    // FINALLY: The cursor remains in the middle of the new template node between the two ZERO_WIDTH_SPACEs. We have these
    // two ZERO_WIDTH_SPACEs so that the user can type inside the visible template node, and do so when this new inline node is empty.
    // If the cursor sat at the end of the template node - without that trailing ZERO_WIDTH_SPACE, typing would move the cursor
    // outside of the node, and the user wouldn't be able to use auto-suggest for templates.
  },

  insertTemplateCheck(editor) {
    // Get the previous character { text, range }
    const prevChar = EditorCommands.getPrevChar(editor);

    if (prevChar.text !== TEMPLATE_HOTKEY) {
      // If the prevChar is NOT the template char, we simply insert this instance of the char into the editor text
      // and return out of the insertTemplate command.
      editor.insertText(TEMPLATE_HOTKEY);
      return;
    }

    // If the prevChar is the template char, the user has entered the double {{ as the insertTemplate command.
    // We selected the prevChar ({), toggle the template mark, and then insert a space so we can see the template mark in the editor body.
    Transforms.select(editor, prevChar.range);
    EditorCommands.insertTemplate(editor);
  },

  isBlockActive(editor, command) {
    const [node] = EditorCommands.getMatchingNode(editor, command);

    return !!node;
  },

  isCollapsed(editor) {
    const { selection } = editor;

    return !selection || Range.isCollapsed(selection);
  },

  isMarkActive(editor, command) {
    const marks = Editor.marks(editor);
    return marks ? marks[command] === true : false;
  },

  keyBackspace(editor) {
    const { selection } = editor;
    const { anchor } = selection;
    const { offset, path } = anchor;

    const isCollapsed = EditorCommands.isCollapsed(editor);
    const isStartOfDocument = isCollapsed && offset === 0 && path[0] === 0;
    const isTemplate = EditorCommands.isBlockActive(editor, 'template');

    if (isStartOfDocument && !isTemplate) {
      // We want to ensure that all block elements at the start of the document can be removed.
      ELEMENT_TYPES.forEach(type => {
        if (EditorCommands.isBlockActive(editor, type)) {
          EditorCommands.toggleBlock(editor, type);
        }
      });
    }

    // If a template node is active, we check to see that it is empty (has only a ZERO_WIDTH_SPACE)
    // removeTemplateNode has the check to see if this is true.
    if (isTemplate) {
      EditorCommands.removeTemplateNode(editor);
    }
  },

  keyEnter(editor) {
    const [node] = EditorCommands.getMatchingNode(editor, 'list-item');
    const isEmptyListItem = node?.children.length === 1 && node?.children[0].text.trim() === '';

    // If the current block is an empty list item, we close the list, and start a new default block (in unwrapList).
    if (isEmptyListItem) {
      EditorCommands.unwrapList(editor);
    }
  },

  removeTemplateNode(editor) {
    // Check to see if the text is equivalent to a ZERO_WIDTH_SPACE.
    const prevChar = EditorCommands.getPrevChar(editor);
    const shouldRemoveMark = [ZERO_WIDTH_SPACE].includes(prevChar.text);

    if (shouldRemoveMark) {
      // We get the path of the current template node, and we remove it.
      const [, path] = EditorCommands.getMatchingNode(editor, 'template');
      Transforms.removeNodes(editor, { at: path });
    }
  },

  toggleBlock(editor, command, value) {
    // Check to see if the command matches the current block.
    const [node] = EditorCommands.getMatchingNode(editor, command);
    // Set a boolean if the command is active in the current block.
    const isActive = !!node;
    // Check to see if the current block is already a list.  We need to deal with lists differently do to the html nested structure of ul>li, and ol>li.
    const isList = LIST_TYPES.includes(command);

    // First we remove any wrapping list root nodes - ul/ol elements.
    EditorCommands.unwrapList(editor);

    // If the command isActive, we remove the command by setting the block's node to the default black type (div).
    // Essentially we are toggle off the command node.
    // If it is not active and the command needs to be added, we set a list item if command isList or simply set the command.
    // We make sure to set the command's value for cases where it is necessary - such as the alignment left|center|right.
    if (command === 'image') {
      Transforms.removeNodes(editor, { match: node => ['image'].includes(node.type) });
    } else {
      const nodeToSet = EditorCommands.getBlockNodeToSet(isActive, isList, command, value, node);
      Transforms.setNodes(editor, nodeToSet);
    }

    // If the command is to ADD a list, we need to make sure to wrap the new list-item in the root list type ul|ol
    // HTML output will become ul>li or ol>li
    if (!isActive && isList) {
      const block = { type: command, [command]: value, children: [] };
      Transforms.wrapNodes(editor, block);
    }

    // If the command's value is added via a menu, we need to trigger focus and cursor position.
    // Currently, only the alignment command is the only block element set via a menu item in the toolbar.
    if (['alignment'].includes(command)) {
      ReactEditor.focus(editor);
      Transforms.move(editor, { distance: 0 });
    }
  },

  toggleMark(editor, command, value = true) {
    const isActive = EditorCommands.isMarkActive(editor, command);

    if (isActive) {
      Editor.removeMark(editor, command);
    } else {
      Editor.addMark(editor, command, value);
    }
  },

  unwrapList(editor) {
    // We unwrap the parent list ol/ul.
    Transforms.unwrapNodes(editor, {
      match: node => LIST_TYPES.includes(node.type),
      split: true
    });

    // Then we set the current node to the default email node.
    Transforms.setNodes(editor, DEFAULT_EDITOR_NODE);
  },

  unwrapNode(editor, command) {
    Transforms.unwrapNodes(editor, { match: node => node.type === command });
  },

  wrapLink(editor, url, textToLink) {
    const command = 'link';
    // If textToLink has a value, we use it to link, otherwise we use the url as the link text.
    const text = textToLink || url;

    const isCollapsed = EditorCommands.isCollapsed(editor);
    const isActive = EditorCommands.isBlockActive(editor, command);
    const [node] = EditorCommands.getMatchingNode(editor, 'image');
    const isImage = !!node;

    if (isActive) {
      // if already linked, unwrap it before applying the new link.
      EditorCommands.unwrapNode(editor, command);
    }

    const link = {
      type: command,
      url,
      children: isCollapsed ? [{ text }] : []
    };

    if (isCollapsed && !isImage) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
    }
    EditorCommands.deselectAndPlaceCursorAfter(editor);
    editor.insertText(''); // Hack: This line toggles the link command off after being active.  Without it, a duplicate link element can occur.
  }
};
