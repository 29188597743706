import React, { useState } from 'react';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import { Button } from '../Button';
import { Dialog, DialogHeader } from '../Dialog';
import { FormFooter } from '../FormFooter';
import { useMediaQueryContext } from '../MediaQueryProvider/MediaQueryProvider';

import styles from './Filters.css';

const FiltersBody = props => {
  const { children, classes } = props;
  return <div className={classes}>{children}</div>;
};

export const Filters = props => {
  const { children, className, isLoading = false, isVisible = true, title = 'Filter results' } = props;
  const { isTabletLandscapeAndUp } = useMediaQueryContext() || {};
  const [isFiltersVisible, setFiltersVisible] = useState(false);

  if (!children || !isVisible) {
    return null;
  }

  const handleFilterClick = () => {
    setFiltersVisible(!isFiltersVisible);
  };

  const classes = classnames({
    [styles.filters]: !className,
    [className]: !!className
  });

  const bodyClasses = classnames({
    [styles.body]: true,
    [styles.isVisible]: isFiltersVisible
  });

  return (
    <div className={classes}>
      <Button
        icon="filter"
        ariaLabel={title}
        label="Filter"
        onClick={handleFilterClick}
        className={styles.button}
        styleType="transparent"
      />
      {isTabletLandscapeAndUp ? (
        <FiltersBody classes={bodyClasses}>{children}</FiltersBody>
      ) : (
        <Dialog isOpen={isFiltersVisible}>
          <DialogHeader icon="filter" clearHandler={handleFilterClick} title={title} />
          <div className={styles.wrap}>
            <FiltersBody classes={bodyClasses}>{children}</FiltersBody>
          </div>
          <FormFooter loading={isLoading}>
            <Button
              disabled={false}
              label="Done"
              ariaLabel="Done"
              type="submit"
              styleType="primary"
              onClick={handleFilterClick}
            />
          </FormFooter>
        </Dialog>
      )}
    </div>
  );
};

Filters.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
  isLoading: bool,
  isVisible: bool,
  title: string
};
