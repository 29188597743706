/** @module */

export const ACTIVATE_ADVANCED_SEARCH = 'ACTIVATE_ADVANCED_SEARCH';
export const SET_ADVANCED_SEARCH = 'SET_ADVANCED_SEARCH';
export const SHOW_ADVANCED_SEARCH = 'SHOW_ADVANCED_SEARCH';

export const initialState = {
  search: {
    ...{ firstName: '', lastName: '', phone: '', email: '', contactTypes: [], sources: [], assignedTo: null },
    address: { streetNumber: '', streetName: '', city: '', state: '', zip: '' }
  },
  ...{ isOpen: false, time: Date.now(), isActive: false }
};

export const advancedSearchReducer = (state = initialState, action = {}) => {
  const { data, isActive, isOpen, type } = action;

  switch (type) {
    case ACTIVATE_ADVANCED_SEARCH:
      return { ...state, isActive };
    case SET_ADVANCED_SEARCH:
      return { ...state, search: data, time: Date.now() };
    case SHOW_ADVANCED_SEARCH:
      return { ...state, isOpen };
    default:
      return state;
  }
};
