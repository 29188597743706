export const DEFAULT_STREET_TYPES = [
  { title: 'Alley', value: 'ALY' },
  { title: 'Annex', value: 'ANX' },
  { title: 'Avenue', value: 'AVE' },
  { title: 'Arcade', value: 'ARC' },
  { title: 'Beach', value: 'BCH' },
  { title: 'Boulevard', value: 'BLVD' },
  { title: 'Bypass', value: 'BYP' },
  { title: 'Cape', value: 'CPE' },
  { title: 'Cay', value: 'CYN' },
  { title: 'Circle', value: 'CIR' },
  { title: 'Close', value: 'CLOSE' },
  { title: 'Court', value: 'CT' },
  { title: 'Cove', value: 'CV' },
  { title: 'Creek', value: 'CRK' },
  { title: 'Crescent', value: 'CRES' },
  { title: 'Crest', value: 'CRST' },
  { title: 'Crossing', value: 'XING' },
  { title: 'Crossroad', value: 'XRD' },
  { title: 'Drive', value: 'DR' },
  { title: 'Estate', value: 'ESTS' },
  { title: 'Expressway', value: 'EXPY' },
  { title: 'Extension', value: 'EXT' },
  { title: 'Freeway', value: 'FWY' },
  { title: 'Heights', value: 'HTS' },
  { title: 'Highway', value: 'HWY' },
  { title: 'Junction', value: 'JCT' },
  { title: 'Lane', value: 'LN' },
  { title: 'Loop', value: 'LOOP' },
  { title: 'Manor', value: 'MNR' },
  { title: 'Park', value: 'PARK' },
  { title: 'Parkway', value: 'PKWY' },
  { title: 'Passage', value: 'PASS' },
  { title: 'Place', value: 'PL' },
  { title: 'Plaza', value: 'PLZ' },
  { title: 'Point', value: 'PT' },
  { title: 'Private', value: 'PVT' },
  { title: 'Road', value: 'RD' },
  { title: 'Square', value: 'SQ' },
  { title: 'Station', value: 'STA' },
  { title: 'Street', value: 'ST' },
  { title: 'Terrace', value: 'TER' },
  { title: 'Trace', value: 'TRCE' },
  { title: 'Trail', value: 'TRL' },
  { title: 'Turnpike', value: 'TPKE' },
  { title: 'View', value: 'VW' },
  { title: 'Way', value: 'WAY' }
];
