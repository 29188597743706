import React from 'react';
import { bool, func, number, object, oneOf, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { Button, ButtonGroup } from '../Button';
import { Card } from '.';
import { PropertyCardImage } from './PropertyCardImage';
import { Dropdown } from '../Dropdown';
import { clearAlert, showAlert } from '../../actions';
import {
  deletePropertyInsight,
  updatePropertyInsight,
  togglePropertyInsightsTransactionForm
} from '../../actions/propertyInsights';
import { showDrawer } from '../../actions/drawer';
import { DATE_FORMATS } from '../../constants';
import { getCityStateStr } from '../../utils/data';
import { getDisplayImage } from '../../utils/image';
import { INSIGHT_STATUS_LABELS } from '../../utils/insights';
import { formatLongDate, formatPriceWithCommas } from '../../utils/strings';
import { checkIsSold, getPropertyStatuses, getTransactionPrice } from '../../utils/transactions';
import styles from './PropertyInsightCard.css';

const CONFIRM_DELETE_MSG = 'Are you sure you want to delete this property insight?';

const PropertyInsightCard = props => {
  const dispatch = useDispatch();
  const {
    data,
    contactId,
    handleClick,
    isEditable = false,
    isSelected,
    orientation = 'horizontal',
    tpxTransactionClosingDate,
    tpxTransactionContractAgreementDate,
    tpxTransactionListPrice,
    tpxTransactionSalePrice,
    tpxTransactionStatus,
    address
  } = props || {};

  const {
    days_on_market,
    description,
    href,
    insight_id,
    insight_status_id,
    last_sold_date,
    last_sold_price,
    list_price,
    listingDate,
    location,
    mls_status,
    mlsNumber, // manually entered, only used with manually entered transactions
    primary_photo,
    source
  } = data || {};

  const { line: manualLine, city: manualCity, state: manualState, zip: manualZip } = address || {};
  const { line, city, state_code: state, postal_code: zip } = location?.address || {};

  const cityStateStr = getCityStateStr({
    city: manualCity || city,
    state: manualState || state,
    zip: manualZip || zip
  });
  const displayLine = manualLine || line;
  const addressLine = `${displayLine == null || displayLine === '' ? '' : `${displayLine}, `} ${cityStateStr}`.trim();

  const { contract_date, listing_id: mls_number, raw, id: mls_source_id } = source || {};

  const { baths, beds, sqft, sold_date } = description || {};

  const displayImage = primary_photo ? getDisplayImage(primary_photo.href) : null;
  const displayStatus = mls_status === 'Expired' ? mls_status : mls_status || raw?.status;
  const statuses = getPropertyStatuses(tpxTransactionStatus, displayStatus);
  const isSoldStatus = checkIsSold(mls_status) || tpxTransactionStatus === 'Closed';
  const displayLastSoldPrice = formatPriceWithCommas(last_sold_price || description?.sold_price);
  const displayListPrice = formatPriceWithCommas(list_price || tpxTransactionListPrice);
  const displaySalePrice = formatPriceWithCommas(tpxTransactionSalePrice);
  const displayCirrusSoldPrice = formatPriceWithCommas(description?.sold_price);
  const displaySoldPrice = displaySalePrice || displayLastSoldPrice || displayCirrusSoldPrice;

  const displayPrice = formatPriceWithCommas(
    getTransactionPrice(data, tpxTransactionSalePrice, tpxTransactionListPrice)
  );
  const listedPriceTitle = `Listed at $${displayListPrice}.`;
  const priceTitle =
    isSoldStatus && displaySoldPrice ? `Sold for $${displaySoldPrice}. ${listedPriceTitle}` : listedPriceTitle;
  const displayListingDate =
    listingDate || contract_date ? formatLongDate(listingDate || contract_date, DATE_FORMATS.SHORT_DATE_WITH_YEAR) : '';
  const closingDate = tpxTransactionClosingDate || tpxTransactionContractAgreementDate || last_sold_date || sold_date;
  const displayClosingDate = closingDate ? formatLongDate(closingDate, DATE_FORMATS.SHORT_DATE_WITH_YEAR) : '';
  const displayMlsNumber = mlsNumber || mls_number;
  const displayDOM = days_on_market ? `(${days_on_market} DOM)` : '';
  const displayUrl = href ? `${href}?utm_source=tpx&utm_medium=propertyInsights` : null; // ToDo: We might want to remove this in future. We should replace with landing pages.

  const handleAddNote = e => {
    e.stopPropagation();
    dispatch(showDrawer({ drawerType: 'notesForm', contactId, insightId: insight_id }));
  };

  const handleDeleteInsight = () => {
    dispatch(deletePropertyInsight(contactId, insight_id)).then(success => {
      if (success) {
        dispatch(clearAlert());
      }
    });
  };

  const confirmDeleteHandler = e => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(
      showAlert({
        message: CONFIRM_DELETE_MSG,
        primaryButtonHandler: handleDeleteInsight
      })
    );
  };

  const handleClickInsightStatus = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleChangeInsightStatus = e => {
    const { target } = e;
    const { value } = target;

    dispatch(
      updatePropertyInsight(contactId, {
        insight_id,
        insight_status_id: parseInt(value),
        mls_number,
        address_line: line,
        mls_source_id
      })
    );
  };

  const confirmAddTransactionHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(togglePropertyInsightsTransactionForm(insight_id, contactId));
  };

  const handleRealtorLink = e => {
    e.stopPropagation();
  };

  const insightStatus = insight_status_id && insight_status_id.toString();

  const isMadeOfferStatus = insightStatus === '5';

  const classes = classnames({
    [styles.propertyInsightCard]: true,
    [styles.isEditable]: isEditable,
    [styles.isClickable]: !!handleClick,
    [styles.isSelected]: isSelected,
    [styles.isVertical]: orientation === 'vertical'
  });

  return (
    <Card id={insight_id} isNested className={classes} onClick={handleClick}>
      <div className={styles.grid}>
        <PropertyCardImage address={line} hasPropertyData={!!contract_date} tags={statuses} url={displayImage} />
        <div className={styles.content}>
          <div className={styles.contentWrap}>
            <div className={styles.title}>
              <div className={styles.titleWrap}>
                <span className={styles.address}>{addressLine}</span>
              </div>
              {displayPrice && (
                <div className={styles.priceWrap}>
                  <div className={styles.price} title={priceTitle}>
                    <div>${displayPrice}</div>
                    {isSoldStatus && <div className={styles.isSold}>${displayListPrice}</div>}
                  </div>
                </div>
              )}
            </div>
            {(beds != null || baths != null || sqft != null) && (
              <div className={styles.features}>
                {beds != null && <div className={styles.detail}>{beds} bed</div>}
                {baths != null && <div className={styles.detail}>{baths} bath</div>}
                {sqft != null && <div className={styles.detail}>{sqft} sqft</div>}
              </div>
            )}
            <div className={styles.details}>
              {(displayListingDate || displayClosingDate) && (
                <div className={styles.dates}>
                  {displayListingDate && (
                    <div className={styles.detail}>
                      <span className={styles.label}>Listed</span> {displayListingDate} {displayDOM}
                    </div>
                  )}
                  {displayClosingDate && isSoldStatus && (
                    <div className={styles.detail}>
                      <span className={styles.label}>Closed</span> {displayClosingDate}
                    </div>
                  )}
                </div>
              )}
            </div>
            {displayMlsNumber && (
              <div className={styles.detail}>
                <span className={styles.label}>MLS ID</span> {displayMlsNumber}
              </div>
            )}
          </div>
          {isEditable && (
            <div className={styles.actions}>
              <ButtonGroup variant="icon">
                <Button
                  icon="noteAdd"
                  ariaLabel="Add note"
                  data-cy="addNoteFromInsight"
                  tooltipPos="left"
                  className={styles.button}
                  onClick={handleAddNote}
                />
                {displayUrl && (
                  <object className="nestedLinkWrapper">
                    <Button
                      url={displayUrl}
                      icon="link"
                      ariaLabel="Open realtor.com"
                      externalLink={true}
                      onClick={handleRealtorLink}
                      tooltipPos="left"
                      className={styles.button}
                    />
                  </object>
                )}
                <Button
                  icon="delete"
                  ariaLabel="Delete insight"
                  data-cy="deleteInsight"
                  tooltipPos="left"
                  className={styles.button}
                  onClick={confirmDeleteHandler}
                />
              </ButtonGroup>
              <ButtonGroup>
                <Dropdown
                  id="insightStatus"
                  items={INSIGHT_STATUS_LABELS}
                  onChange={handleChangeInsightStatus}
                  onClick={handleClickInsightStatus}
                  size="s"
                  value={insightStatus}
                />
                {isMadeOfferStatus ? (
                  <Button
                    ariaLabel="Add transaction"
                    icon="transaction"
                    label="Add transaction"
                    labelShort="Add trans."
                    onClick={confirmAddTransactionHandler}
                    styleType="primary"
                    tooltipPos="left"
                    data-cy="addTransactionFromInsight"
                  />
                ) : null}
              </ButtonGroup>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default PropertyInsightCard;

PropertyInsightCard.propTypes = {
  contactId: string,
  data: object,
  handleClick: func,
  isEditable: bool,
  isSelected: bool,
  orientation: oneOf(['horizontal', 'vertical']),
  tpxTransactionListPrice: number,
  tpxTransactionStatus: string
};
