import classnames from 'classnames';
import format from 'date-fns/format';
import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import React, { Component } from 'react';
import { Calendar } from 'react-calendar';
import { FormTextInput, Icon, Popover } from '../';
import { DATE_FORMATS, KEYCODE_MAP } from '../../constants';
import { getElementOffset } from '../../utils';
import { parseISODate, validateDate } from '../../utils/dates';
import { MediaQueryContext } from '../../components/MediaQueryProvider/MediaQueryProvider';
import styles from './FormDateInput.css';
import '!style-loader!css-loader!./ReactCalendar-override.css';

export class FormDateInput extends Component {
  static contextType = MediaQueryContext;

  constructor(props) {
    super(props);
    this.calRef = React.createRef();
    const { timestamp, showInvalid = false } = this.props || {};
    this.state = {
      pickerOpen: false,
      openerOffset: null,
      selectedDate: timestamp ? format(parseISODate(timestamp), DATE_FORMATS.ISO_DATE) : null,
      // Temporary manual input, will sync with selectedDate when validated
      manualInput: timestamp ? format(parseISODate(timestamp), DATE_FORMATS.ISO_DATE) : '',
      isValid: !showInvalid
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { timestamp, showInvalid } = this.props;
    const { pickerOpen } = this.state;

    // We need to update the selectedDate when the date prop changes.
    // Test case, change the add note date on a contact, then switch contacts.
    // The field should show the current date after the switch.

    if (timestamp && prevProps.timestamp !== timestamp) {
      this.setState({
        selectedDate: format(parseISODate(timestamp), DATE_FORMATS.ISO_DATE),
        manualInput: format(parseISODate(timestamp), DATE_FORMATS.ISO_DATE),
        pickerOpen: false
      });
    }

    // Need to keep isValid is state in sync to show invalid
    if (prevProps.showInvalid !== showInvalid) {
      this.setState({ isValid: !showInvalid });
    }

    if (prevState.pickerOpen !== pickerOpen) {
      // we want to handle keypress and click to close the calendar popover when it should no longer be open.
      if (pickerOpen) {
        window.addEventListener('keydown', this.handleKeypress, true);
        window.addEventListener('click', this.handleCloseAction, true);
        window.addEventListener('resize', this.handleCloseAction, true);
        window.addEventListener('scroll', this.handleCloseAction, true);
        return;
      }
      window.removeEventListener('keydown', this.handleKeypress, true);
      window.removeEventListener('click', this.handleCloseAction, true);
      window.removeEventListener('resize', this.handleCloseAction, true);
      window.removeEventListener('scroll', this.handleCloseAction, true);
    }
  }

  handleKeypress = e => {
    const keycode = e.which;
    const { TAB } = KEYCODE_MAP;
    const isObservedKey = keycode === TAB;

    if (!isObservedKey) {
      return;
    }

    this.handleCloseAction();
  };

  handleOnFocus = e => {
    this.setState({ pickerOpen: true, openerOffset: getElementOffset(e.target) });
  };

  handleCloseAction = e => {
    if (
      e &&
      (e.target.id === this.props.id ||
        (this.calRef.current && this.calRef.current.contains(e.target)) ||
        (e.target.parentElement &&
          e.target.parentElement.className &&
          e.target.parentElement.className.includes('react-calendar')) ||
        e.type === 'scroll')
    ) {
      return;
    }

    this.setState({ pickerOpen: false });
  };

  handleDateChange = timestamp => {
    const { onDateChange } = this.props;

    const selectedDate = format(parseISODate(timestamp), DATE_FORMATS.ISO_DATE);

    this.setState({ isValid: true });
    this.setState({ selectedDate });
    this.setState({ manualInput: selectedDate });

    if (onDateChange) {
      onDateChange(selectedDate);
    }
  };

  handleDateInput = e => {
    const { onDateChange, allowEmpty = true } = this.props || {};
    const { value } = e.target;
    const isEmpty = value === '';
    const input = isEmpty ? null : value;
    // Validate and accept the following formats, but transform valid date into ISO format
    const validInputWithSlash = validateDate(input, DATE_FORMATS.DATE_WITH_SLASH)
      ? parseISODate(input, DATE_FORMATS.DATE_WITH_SLASH)
      : null;
    const validInputWithoutDivider = validateDate(input, DATE_FORMATS.DATE_WITH_NO_DIVIDER)
      ? parseISODate(input, DATE_FORMATS.DATE_WITH_NO_DIVIDER)
      : null;
    const validInputISO = validateDate(input, DATE_FORMATS.ISO_DATE)
      ? parseISODate(input, DATE_FORMATS.ISO_DATE)
      : null;
    const parsedInput = validInputWithSlash || validInputWithoutDivider || validInputISO;

    const isValid = !isNaN(parsedInput) && parsedInput != null;

    // Keep selectedDate in string for displaying, only format unempty valid input
    const formattedInput = isValid && (!isEmpty || allowEmpty) ? format(parsedInput, DATE_FORMATS.ISO_DATE) : input;

    // Allow empty input
    this.setState({ isValid: isValid || (isEmpty && allowEmpty) });
    this.setState({
      manualInput: formattedInput
    });

    if (isValid || (isEmpty && allowEmpty)) {
      if (onDateChange) {
        onDateChange(formattedInput);
      }
      this.setState({
        selectedDate: formattedInput
      });
    }
  };

  render() {
    const { disabled, id, inputRef, placeholder, readOnly = false, required } = this.props;
    const { pickerOpen, openerOffset, selectedDate, manualInput, isValid } = this.state;
    const parsedSelectedDate = selectedDate ? parseISODate(selectedDate) : null;
    const calendarDate = selectedDate ? parsedSelectedDate : null;

    const fieldClasses = classnames({
      [styles.dateField]: true,
      [styles.invalid]: !isValid
    });

    const { isTabletLandscapeAndUp } = this.context || {};
    return (
      <div className={fieldClasses}>
        <FormTextInput
          id={id}
          placeholder={placeholder}
          onFocus={this.handleOnFocus}
          size="s"
          value={manualInput}
          readOnly={readOnly}
          ref={inputRef}
          className={styles.dateInput}
          showInvalid={!isValid}
          aria-invalid={isValid === false}
          disabled={disabled}
          required={required}
          onChange={this.handleDateInput}
        />
        <Icon name="calendar" className={styles.icon} size="s" />
        <Popover
          ref={this.calRef}
          isOpen={pickerOpen}
          position={'bottomLeft'}
          openerOffset={openerOffset}
          className={styles.popover}
        >
          <Calendar
            className={styles.calendar}
            onChange={this.handleDateChange}
            maxDetail="month"
            nextLabel={<Icon name="arrowright" size="s" className={styles.arrows} />}
            prevLabel={<Icon name="arrowleft" size="s" className={styles.arrows} />}
            showDoubleView={isTabletLandscapeAndUp}
            tileClassName={styles.calendarDay}
            calendarType="US"
            value={calendarDate}
          />
        </Popover>
      </div>
    );
  }
}

FormDateInput.propTypes = {
  id: string.isRequired,
  placeholder: string,
  timestamp: oneOfType([string, instanceOf(Date)]),
  onDateChange: func,
  showInvalid: bool,
  required: bool,
  disabled: bool
};

export default FormDateInput;
