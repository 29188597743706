export const MARKETING_CAROUSEL_DATA = [
  {
    description:
      "Social Connect not only delivers a steady stream of exclusive leads but automatically warms them up until they're ready to talk with you about buying or selling. And with 30 lead/mo for just $300, it's actually affordable. Lead inventory is limited—Get started today!",
    imgSize: {
      width: 680,
      height: 470
    },
    imgUrl: 'https://www.topproducer.com/hubfs/sc/Social-Media-Leads-TP-Social-Connect.png',
    isNew: false,
    title: 'Social Connect: Leads + Automated Nurturing',
    btnLabel: 'Check Lead Availability',
    url: 'https://learnmore.topproducer.com/social-connect'
  },
  {
    description:
      'With HomeIntelle, you can find potential sellers "hiding" in your database. It automatically sends personalized, interactive home valuation and property reports to your contacts, helping you become their go-to source for real estate information (instead of Google or Zillow)!',
    imgSize: {
      width: 680,
      height: 470
    },
    imgUrl: 'https://www.topproducer.com/hubfs/tpx-login-home-intel.png',
    isNew: false,
    title: 'Your Top Producer System Is Ready for an Upgrade',
    btnLabel: 'Learn More',
    url: 'https://www.topproducer.com/homeintel'
  },
  {
    description:
      "What if you could speak with potential sellers before your competitors even know they're ready to list? Smart Targeting uses AI-powered predictive analytics and automated marketing to put you in front of the top 20% of homeowners that are most likely to sell in the next 12 months!",
    imgSize: {
      width: 680,
      height: 470
    },
    imgUrl: 'https://www.topproducer.com/hubfs/TopProducerSoftware_July2022/images/smart-targeting-TPXlogin.jpg',
    isNew: false,
    title: 'Smart Targeting: Real Estate Farming With AI',
    btnLabel: 'Learn More',
    url: 'https://www.topproducer.com/smart-targeting/?utm_source=TPX&utm_medium=product&utm_campaign=smart-targeting'
  },
  {
    description:
      'Are you using AI Author to write emails and text messages? To save time and effort on quick follow ups or long-term nurturing, simply click a pre-made prompt like "market update"—or write your own prompt—and it\'ll craft the message for you. You can then edit it yourself, or with AI Author\'s help.',
    imgSize: {
      width: 680,
      height: 470
    },
    imgUrl: 'https://www.topproducer.com/hubfs/AI-Author-blog-image-v2.webp',
    isNew: false,
    title: 'AI-Powered Engagement Assistant'
  },
  {
    description:
      '"Speed to lead" the easy way! By automatically applying a Lead Response plan to your incoming leads, you can sit back while prospects are nurtured on your behalf with carefully crafted messages sent at just the right times.',
    imgSize: {
      width: 680,
      height: 470
    },
    imgUrl: 'https://www.topproducer.com/hubfs/TopProducerSoftware_July2022/images/lead-response.jpg',
    isNew: false,
    title: 'Automated Lead Response',
    btnLabel: 'Learn More',
    url: 'https://www.topproducer.com/lead-response'
  },
  {
    description:
      "Did you know you can build workflows that update as you complete tasks, shift priorities, and handle missed deadlines? That's right—no more letting things slip! Dynamic workflows and tasks ensure you stay on track, keep clients happy with timely follow-ups, and make every transaction stress-free.",
    imgSize: {
      width: 680,
      height: 473
    },
    imgUrl:
      'https://www.topproducer.com/hubfs/TopProducerSoftware_July2022/images/2021-TP-redesign-HOME_desktop_25.jpg',
    isNew: false,
    title: 'Dynamic Workflows + Tasks',
    btnLabel: 'Learn More',
    url: 'https://www.topproducer.com/dynamic-workflows'
  },
  {
    description:
      "Take the guesswork out of day-to-day prioritization. With a personal Follow Up Coach, you'll never have to wonder who to contact next. Simply follow the system-suggested touch points to build rapport and turn prospects into commissions paid!",
    imgSize: {
      width: 680,
      height: 470
    },
    imgUrl: 'https://www.topproducer.com/hubfs/Smart-Lead-Follow-up-System.webp',
    isNew: false,
    title: 'Your Smart Follow-Up System',
    btnLabel: 'Learn More',
    url: 'https://support.topproducer.com/x/tpx-follow-up-coach'
  }
];
