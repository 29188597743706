import React, { Fragment, useEffect, useRef } from 'react';
import { bool, func, object, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { navigate, useLocation } from '@reach/router';
import classnames from 'classnames';

import { showAlert, setAlertLoading, deletePlanItem, clearAlert } from '../../actions';
import { PLANS_URI } from '../../constants';
import { buildTestId, getTaskDescription, getTaskDueLabel, getTaskPriorityLabel } from '../../utils';
import { Button, Card, Icon, PriorityIndicator } from '..';

import cardStyles from './Card.css';
import styles from './TaskPlanItemCard.css';
import taskPlanStyles from './TaskPlanCard.css';
import documentStyles from '../Document/Document.css';
import { getDisplayTaskIcon, TIME_RANGES } from '../../utils/tasks';
import { pluralize } from '../../utils/strings';
import { ASSIGNED_TO_DEFAULT } from '../../pages/Settings/TaskPlans/ItemForm/constants';

const HEADER_OFFSET = -60;

export const TaskPlanItemCard = props => {
  const { className, data, handleClick, isNested = false, readOnly = false, testId = 'TaskPlanItemCard' } = props;

  const dispatch = useDispatch();

  const location = useLocation();
  const ref = useRef();
  const { state: locationState } = location;

  // Scroll to card when it's selected
  useEffect(() => {
    const editId = locationState?.currentId;
    if (editId && editId === data?.id) {
      ref.current.scrollIntoView(true);
      window.scrollBy(0, HEADER_OFFSET);
    }
  }, [data, locationState]);

  if (!data) {
    return null;
  }

  const {
    activityTypeId,
    dateType,
    description,
    id,
    planId,
    priorityEnum,
    assignedToValue,
    timeRangeEnum,
    TaskReminders
  } = data || {};

  const isTransactionType = dateType != null;
  const isTimedTask = timeRangeEnum === TIME_RANGES.timed;

  const handleEdit = e => {
    e.preventDefault();
    navigate(`${PLANS_URI}/${planId}/edit/${id}`, { state: { currentId: id } });
  };

  const handleDelete = e => {
    e.preventDefault();
    dispatch(
      showAlert({
        message: 'Are you sure you want to delete this task?',
        primaryButtonHandler: () => {
          dispatch(setAlertLoading());
          dispatch(deletePlanItem(data)).then(() => {
            dispatch(clearAlert());
          });
        }
      })
    );
  };
  const shouldShowAdjustment = isTransactionType || isTimedTask;
  const displayDescription = getTaskDescription(description);
  const displayAdjustment = getTaskDueLabel(data);
  const displayAssignedTo = assignedToValue || ASSIGNED_TO_DEFAULT;

  const priorityLabel = getTaskPriorityLabel(priorityEnum);

  const classes = classnames({
    [styles.card]: !className,
    [styles.isReadyOnly]: readOnly,
    [className]: !!className
  });

  return (
    <Card className={classes} isNested={isNested} onClick={handleClick} id={`TaskPlanItemCard-${id}`}>
      <div className={styles.grid} title={displayAdjustment} ref={ref}>
        <div className={taskPlanStyles.columnMain}>
          <div className={cardStyles.titleContainer}>
            <div className={taskPlanStyles.cardTitle} title={description}>
              <span className={taskPlanStyles.iconSpan}>
                <Icon name={getDisplayTaskIcon(activityTypeId)} size="s" className={taskPlanStyles.icon} />
                {TaskReminders?.length > 0 && (
                  <Fragment>
                    {' '}
                    <Icon name="reminder" title={`${pluralize(TaskReminders.length, 'Reminder')}`} size="s" />
                  </Fragment>
                )}
              </span>
              <span className={taskPlanStyles.title}>{displayDescription}</span>
            </div>
            {shouldShowAdjustment && (
              <div className={cardStyles.footer}>
                <span className={documentStyles.micro}>{displayAdjustment}</span>
              </div>
            )}
          </div>
        </div>
        <div className={taskPlanStyles.column} title={`${priorityLabel} Priority`}>
          <PriorityIndicator priority={priorityEnum} />
        </div>
        <div className={taskPlanStyles.column} title="Assigned To">
          <span>{displayAssignedTo}</span>
        </div>
        {!readOnly && (
          <div className={taskPlanStyles.columnActions}>
            <Button
              data-testid={buildTestId(testId, 'Button-edit')}
              ariaLabel="Edit task"
              icon="edit"
              onClick={handleEdit}
            />
            <Button
              data-testid={buildTestId(testId, `item-${id}-Button-delete`)}
              ariaLabel="Delete from plan"
              icon="delete"
              onClick={handleDelete}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

TaskPlanItemCard.propTypes = {
  className: string,
  data: object,
  handleClick: func,
  isNested: bool,
  readOnly: bool,
  testId: string
};
