import React, { lazy, Suspense } from 'react';
import { arrayOf, bool, func, node, number, object, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import { Loading } from '../';
const ListNoResults = lazy(() => import('./ListNoResults'));

import styles from './List.css';
import { Virtuoso } from 'react-virtuoso';
import { buildTestId } from '../../utils';

// in pixels
const VIEW_OVERSCAN = 500;
const VIEW_OVERSCAN_REVERSE = 2000;

export const List = props => {
  const {
    children,
    className,
    listClassName,
    isNested = false,
    noResultsIcon,
    noResultsMessage,
    noResultsComponent,
    pageHandler,
    donePaging,
    headerHeightOffset,
    testId = 'List',
    isVirtuoso = true,
    ...attrs
  } = props;

  //Checking children key for handling null values returned for follow up API (contact id is zero's for invalid record)
  if (
    !children ||
    children.length === 0 ||
    (children[0] && children[0].key === '00000000-0000-0000-0000-000000000000')
  ) {
    return (
      <Suspense fallback={<Loading loading={true} />}>
        {noResultsComponent || (
          <ListNoResults isNested={isNested} noResultsIcon={noResultsIcon} noResultsMessage={noResultsMessage} />
        )}
      </Suspense>
    );
  }

  if (!Array.isArray(children)) {
    return children;
  }

  const classes = classnames({
    [styles.list]: !className,
    [styles.isNested]: isNested,
    [className]: !!className,
    [listClassName]: !!listClassName
  });

  const totalCount = children?.length || 0;

  const onBottomThresholdReached = atBottom => {
    if (atBottom && !Boolean(donePaging) && pageHandler) {
      pageHandler();
    }
  };

  return isVirtuoso ? (
    <Virtuoso
      style={{
        height: headerHeightOffset ? `calc(100vh - ${headerHeightOffset}px)` : 'auto',
        willChange: 'transform'
      }}
      className={classes}
      totalCount={totalCount}
      overscan={{ main: VIEW_OVERSCAN, reverse: VIEW_OVERSCAN_REVERSE }}
      atBottomStateChange={onBottomThresholdReached}
      increaseViewportBy={{ top: 2000 }}
      itemContent={index => {
        return (
          <div className={index % 2 ? styles.tableRowEven : null} data-testid={buildTestId(testId, `tr-${index}`)}>
            {children?.[index]}
          </div>
        );
      }}
      useWindowScroll={headerHeightOffset ? false : true}
      {...(process.env.NODE_ENV === 'test'
        ? {
            initialItemCount: totalCount,
            key: totalCount
          }
        : {})}
    />
  ) : (
    <ul className={classes} {...attrs}>
      {children}
    </ul>
  );
};

List.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
  isNested: bool,
  isVirtuoso: bool,
  noResultsIcon: string,
  noResultsMessage: string,
  noResultsComponent: object,
  pageHandler: func,
  headerHeightOffset: number,
  donePaging: bool
};
