/** @module */

import { DEFAULT_PAGING_SIZE } from '../constants';
import { getMergedEntities, getMergedSetOfIds } from '../utils';

export const SNAPSHOT_ACCOUNT_DETAILS = 'SNAPSHOT_ACCOUNT_DETAILS';
export const SNAPSHOT_SETTINGS = 'SNAPSHOT_SETTINGS';
export const SNAPSHOT_PROP_TYPES = 'SNAPSHOT_PROP_TYPES';
export const SNAPSHOT_FIELD_LIST = 'SNAPSHOT_FIELD_LIST';
export const UPDATE_AGENT_SETTING_REQUEST = 'UPDATE_AGENT_SETTING_REQUEST';
export const UPDATE_AGENT_SETTING_SUCCESS = 'UPDATE_AGENT_SETTING_SUCCESS';
export const UPDATE_AGENT_SETTING_FAIL = 'UPDATE_AGENT_SETTING_FAIL';
export const GET_MARKET_SNAPSHOT_DETAILS_REQUEST = 'GET_MARKET_SNAPSHOT_DETAILS_REQUEST';
export const GET_MARKET_SNAPSHOT_DETAILS_SUCCESS = 'GET_MARKET_SNAPSHOT_DETAILS_SUCCESS';
export const GET_MARKET_SNAPSHOT_DETAILS_FAIL = 'GET_MARKET_SNAPSHOT_DETAILS_FAIL';
export const GET_MARKET_SNAPSHOT_DETAILS_PAGING = 'GET_MARKET_SNAPSHOT_DETAILS_PAGING';
export const UPDATE_LISTING_ALERT = 'UPDATE_LISTING_ALERT';
export const SAVE_PREVIEW_LISTINGS = 'SAVE_PREVIEW_LISTINGS';
export const CLEAR_PREVIEW_LISTINGS = 'CLEAR_PREVIEW_LISTINGS';
export const SET_MARKET_SNAPSHOT_STATUS = 'SET_MARKET_SNAPSHOT_STATUS';
export const SET_PAGE_INDEX = 'SET_PAGE_INDEX';

export const initialState = {
  isSnapshotOnline: true,
  isLoading: false,
  msAccountDetails: null,
  msAgentSettings: null,
  msPropertyTypes: null,
  msFieldList: null,
  reportsAreLoading: false,
  reports: [],
  donePaging: {},
  listingEntities: {},
  listingGroup: {},
  currentListingGroup: null,
  currentListingCount: null,
  currentSnapshotGroup: null
};

/**
 * The user/auth redux reducer.
 * @param {Object} state - the current state of the user store.
 * @param {Object} action - the action to take on the user store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const snapshotReducer = (state = initialState, action = {}) => {
  const {
    msAccountDetails,
    msAgentSettings,
    msPropertyTypes,
    msFieldList,
    data,
    contactId,
    reportId,
    group,
    pageIndex,
    previewListingData,
    id,
    isSnapshotOnline
  } = action;

  switch (action.type) {
    case SNAPSHOT_ACCOUNT_DETAILS:
      return { ...state, msAccountDetails: msAccountDetails };
    case SNAPSHOT_SETTINGS:
      return { ...state, msAgentSettings: msAgentSettings };
    case SNAPSHOT_PROP_TYPES:
      return { ...state, msPropertyTypes: msPropertyTypes };
    case SNAPSHOT_FIELD_LIST:
      return { ...state, msFieldList: msFieldList };
    case UPDATE_AGENT_SETTING_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case UPDATE_AGENT_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        msAgentSettings: {
          ...state.msAgentSettings,
          listing_alert_setting: action.listAlertSettings,
          market_snapshot_setting: action.msSettings
        }
      };
    case UPDATE_AGENT_SETTING_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case GET_MARKET_SNAPSHOT_DETAILS_REQUEST:
      return {
        ...state,
        reportsAreLoading: true
      };
    case GET_MARKET_SNAPSHOT_DETAILS_SUCCESS:
      const entities = getMergedEntities([], data, { idKey: 'report_id' });

      // Initialize pageIndex
      const reportData = { id, entities, pageIndex: 1 };

      const newReport = getMergedEntities(state.reports, [reportData], { idKey: contactId ? 'contactId' : 'id' });

      return {
        ...state,
        currentSnapshotGroup: id,
        reports: newReport,
        reportsAreLoading: false,
        donePaging: {
          ...state.donePaging,
          [id]: data.length < DEFAULT_PAGING_SIZE
        }
      };
    case GET_MARKET_SNAPSHOT_DETAILS_PAGING:
      const previousSize = Object.keys(state.reports[id]?.entities)?.length;

      const newReports = getMergedEntities(state.reports[id].entities, data, { idKey: 'report_id' });

      const updatedSize = Object.keys(newReports).length;

      return {
        ...state,
        reports: {
          ...state.reports,
          [id]: {
            ...state.reports[id],
            entities: newReports
          }
        },
        currentSnapshotGroup: id,
        donePaging: {
          ...state.donePaging,
          [id]: id === 'myReports' ? data.length < DEFAULT_PAGING_SIZE : previousSize === updatedSize
        },
        reportsAreLoading: false
      };
    case GET_MARKET_SNAPSHOT_DETAILS_FAIL:
      return {
        ...state,
        reportsAreLoading: false
      };
    case UPDATE_LISTING_ALERT:
      return {
        ...state,
        reports: {
          ...state.reports,
          [contactId]: {
            ...state.reports[contactId],
            entities: {
              ...state.reports[contactId].entities,
              [reportId]: {
                ...state.reports[contactId].entities[reportId],
                listing_alert_detail: {
                  ...state.reports[contactId].entities[reportId].listing_alert_detail,
                  new_listing: data.new,
                  sold: data.sold,
                  price_change: data.price_change
                }
              }
            }
          }
        }
      };
    case CLEAR_PREVIEW_LISTINGS:
      return {
        ...state,
        currentListingGroup: null,
        currentListingCount: null
      };
    case SAVE_PREVIEW_LISTINGS:
      const { requestId, allPreviews } = previewListingData;

      return {
        ...state,
        currentListingGroup: requestId,
        currentListingCount: allPreviews.length,
        listingEntities: getMergedEntities(state.listingEntities, allPreviews, { idKey: 'listing_id' }),
        listingGroup: {
          ...state.listingGroup,
          [requestId]: getMergedSetOfIds(state.listingGroup[group], allPreviews, { idKey: 'listing_id' })
        }
      };
    case SET_MARKET_SNAPSHOT_STATUS:
      return {
        ...state,
        isSnapshotOnline
      };
    case SET_PAGE_INDEX:
      return {
        ...state,
        reports: {
          ...state.reports,
          [id]: {
            ...state.reports[id],
            pageIndex
          }
        }
      };
    default:
      return state;
  }
};
