import { navigate } from '@reach/router';
import { string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Icon } from '../../components';
import { BAR_CHART_COLOR_LIST } from '../../constants/preferences';
import { capitalize, formatCount } from '../../utils/strings';
import styles from './SalesPipelineStats.css';

const colors = BAR_CHART_COLOR_LIST;

const HorizontalBarChart = ({ data, onClick }) => {
  const maxValue = Math.max(...data.map(item => item.value));

  const getLabel = item => {
    if (item.name.toLowerCase() === 'social connect') {
      return (
        <div className={styles.chartLabel} onClick={onClick}>
          <Icon name={'facebook'} /> <span className={styles.labelText}>{item.name}</span>
        </div>
      );
    }
    return (
      <div className={styles.chartLabel} onClick={onClick}>
        <Icon name={item.name.toLowerCase()} size="s" isColored={true} />
        <span className={styles.labelText}> {item.name}</span>
      </div>
    );
  };
  return (
    <div className={styles.chartContainer}>
      {data.map((item, index) => (
        <div key={index} className={styles.chartRow}>
          {getLabel(item)}
          <div className={styles.chartBarBackground}>
            <div
              className={styles.chartBar}
              style={{ '--background-color': item.fill, '--target-width': `${(item.value / maxValue) * 100}%` }}
              onClick={onClick}
            ></div>
          </div>
          <span className={styles.count}>{formatCount(item.value)}</span>
        </div>
      ))}
    </div>
  );
};

export const SalesPipelineStats = ({ userId }) => {
  const { statusCounts } = useSelector(store => store.contacts);

  const convertObjectToArray = obj => Object.keys(obj).map(key => ({ name: key, value: obj[key] }));

  const defaultBars = ['Social Connect', 'new', 'engage', 'future', 'active'];

  const handleLabelClick = e => {
    const label = e.target.innerText.toLowerCase().trim();

    const assignedToStr = `assignedTo=${userId}`;
    const navigationMap = {
      all: '/contacts?q=all',
      new: `/contacts?${assignedToStr}`,
      engage: `/contacts?status=20&${assignedToStr}`,
      future: `/contacts?status=40&${assignedToStr}`,
      active: `/contacts?status=60&${assignedToStr}`,
      'social connect': `/contacts?q=social connect&sortBy=touch&${assignedToStr}`
    };

    const path = navigationMap?.[label];

    if (path) {
      navigate(path);
    }
  };

  const data = (() => {
    const arr = defaultBars
      .map(bar => {
        const item = convertObjectToArray(statusCounts).find(item => item.name === bar.toLowerCase());

        return item ? { ...item, name: capitalize(bar) } : { name: capitalize(bar), value: 0 };
      })
      .map((item, index) => ({
        ...item,
        fill: colors[index % colors.length]
      }));

    return arr;
  })();

  return <HorizontalBarChart data={data} onClick={handleLabelClick} />;
};

SalesPipelineStats.propTypes = {
  className: string,
  userId: string
};
