/** @module */

import {
  CALENDAR_SYNC_ACCOUNTS,
  CALENDAR_SYNC_ACCOUNTS_DELETE,
  CALENDAR_SYNC_CALENDARS
} from '../reducers/calendarSync';
import { getMergedEntities, request, requestError } from '../utils';
import { SYNC_STATUSES } from '../utils/sync';
import { showMessage } from './message';

const errorMsg = 'Issue authenticating with email service.';
const showReauthorizeMessage = () => {
  return showMessage({
    message: `Please reauthorize your calendar account in settings.`,
    actionLabel: 'Settings',
    actionUrl: '/settings/calendar-sync'
  });
};

/**
 * Request for getting calendar sync account
 */
export const getCalendarSyncAccounts = options => {
  const { forceRefresh = false, shouldBeCached } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      forceRefresh,
      path: 'getAccountBySyncTypeAsync',
      params: {
        nylasSyncType: 'calendar'
      },
      shouldBeCached
    };

    try {
      const response = await request(requestOptions);
      const { exception, status } = response;
      if (exception) {
        throw exception;
      }
      const data = getMergedEntities([], response.data, { idKey: 'NylasAccountId' });
      if (status === 200) {
        dispatch({
          type: CALENDAR_SYNC_ACCOUNTS,
          data: data
        });

        return true;
      }

      return false;
    } catch (error) {
      // Sync not set up
      if (error?.response?.status === 404) {
        return false;
      }

      requestError(error, dispatch);
    }
  };
};

export const getNylasAuthUrl = options => {
  const callbackUrl = '/settings/calendar-sync';

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      method: 'POST',
      path: 'getAuthorizeUrl',
      payload: {
        ...options,
        callbackUrl: `${window.location.origin}${callbackUrl}`
      },
      shouldBeCached: false
    };

    try {
      const { data, status } = await request(requestOptions);
      const { exception } = data;

      if (exception || status !== 200) {
        const errorToThrow = exception ? exception : { message: errorMsg };
        throw errorToThrow;
      }

      const url = new URL(data.url).toString();
      return url;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Authenticate with Nylas.
 * @param {String} code - the url parameter returned by the email provider must be sent to Nylas.
 */
export const authenticateUsingNylas = (code, email) => {
  const callbackUrl = '/settings/calendar-sync';

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      method: 'POST',
      path: 'authorizeAccount',
      params: {
        code,
        emailAddress: email,
        nylas3SyncType: 'calendar',
        callbackUrl: `${window.location.origin}${callbackUrl}`
      },
      shouldBeCached: false
    };

    try {
      const { data, status } = await request(requestOptions);
      const { exception } = data;

      if (exception || status !== 200) {
        const errorToThrow = exception ? exception : { message: errorMsg };
        throw errorToThrow;
      }

      dispatch(getCalendarSyncAccounts({ forceRefresh: true }));
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Deletes an email accounts from TP DB & Nylas.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 */
export const deleteEmailAccount = (accountId, version) => {
  return async dispatch => {
    const requestOptions =
      version === 3
        ? {
            apiServiceType: 'nylasV3',
            baseUrlKey: 'api',
            method: 'DELETE',
            path: 'deleteAccount',
            params: {
              grantId: accountId,
              nylas3SyncType: 'calendar'
            },
            shouldBeCached: false
          }
        : {
            apiServiceType: 'calendarSync',
            baseUrlKey: 'api',
            method: 'DELETE',
            path: `accounts/${accountId}`,
            shouldBeCached: false
          };

    try {
      const response = await request(requestOptions);
      const { data } = response;
      const { exception } = data;

      const status = data?.status || data?.Status;
      if (exception || status !== 'success') {
        const errorToThrow = exception ? exception : { message: errorMsg };
        throw errorToThrow;
      }

      dispatch({ type: CALENDAR_SYNC_ACCOUNTS_DELETE });

      return data;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Request for getting calendar sync status
 */
export const getCalendarSyncStatus = options => {
  const { forceRefresh = false, shouldBeCached } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'calendarSync',
      baseUrlKey: 'api',
      forceRefresh,
      path: 'autosyncstatus', // Previous api path of `status` still exists, `autosyncstatus` uses Nylas web hooks.
      shouldBeCached
    };

    try {
      const response = await request(requestOptions);
      const { exception, status } = response;
      if (exception) {
        throw exception;
      }

      if (status === 200) {
        const data = response.data;
        if (data?.Status && SYNC_STATUSES[data.Status.toLowerCase()]?.statusIsPositive === false) {
          dispatch(showReauthorizeMessage());
        }
        return Promise.resolve(data?.Status);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      requestError(error, dispatch);
      return Promise.reject(error);
    }
  };
};

export const getCalendarSyncCalendars = options => {
  const { forceRefresh = false, shouldBeCached } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'calendarSync',
      baseUrlKey: 'api',
      forceRefresh,
      path: 'calendars',
      shouldBeCached
    };

    try {
      const response = await request(requestOptions);
      const { data, exception, status } = response;
      if (exception) {
        throw exception;
      }

      if (status === 200) {
        dispatch({ type: CALENDAR_SYNC_CALENDARS, data });
        return Promise.resolve(data);
      } else {
        return Promise.reject();
      }
    } catch (error) {
      requestError(error, dispatch);
      return Promise.reject(error);
    }
  };
};

export const linkCalendarToAccount = (calendarId, emailId, options) => {
  const { forceRefresh = true, shouldBeCached = false } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'calendarSync',
      baseUrlKey: 'api',
      forceRefresh,
      method: 'POST',
      path: 'selectedCalendar',
      payload: { id: calendarId, email: emailId },
      shouldBeCached
    };

    try {
      const response = await request(requestOptions);
      const { exception, status } = response;
      if (exception) {
        throw exception;
      }

      if (status === 200) {
        await dispatch(getCalendarSyncAccounts({ forceRefresh: true }));
      } else {
        return Promise.reject(response);
      }
    } catch (error) {
      requestError(error, dispatch);
      return Promise.reject(error);
    }
  };
};
