const TP_ROOT_URL = 'https://www.topproducer.com';
const CAMPUS_PATH = '/campus';

const HELP_URLS = {
  billingInfo: '/billing/how-to-download-invoices-or-update-your-payment-information/',
  contactDetails: '/x/tpx-viewing-contact-history-tasks/',
  contactOrganization: '/tpx-quick-start/organize-your-contacts/',
  contactTypesStatuses: '/x/tpx-contact-types-statuses/#statuses',
  contactSync: '/x/tpx-contact-sync/',
  demo: '/get-demo/',
  emailIntegration: '/tpx-quick-start/integrate-your-email/',
  emailSending: '/x/tpx-sending-an-email/',
  emailSignatures: '/tpx-quick-start/integrate-your-email/',
  home: '',
  homeIntelReports: '',
  importContacts: '/tpx-quick-start/add-import-or-sync-your-contacts/',
  importantDates: '/x/tpx-adding-important-dates/',
  leadIntegration: '/x/tpx-lead-integration/',
  leadProviders: '/top-producer/setup/lead-providers/lead-providers-sources/',
  notes: '/x/tpx-adding-a-note/',
  propertyInsights: '/x/tpx-tracking-interested-properties/',
  properties: '/x/tpx-adding-a-property/',
  settings: '/x/tpx-settings/',
  snapshotProduct: '/market-snapshot/',
  snapshotReports: '/x/tpx-viewing-market-snapshot-reports/',
  snapshotSetup: '/tpx-quick-start/set-up-market-snapshot/',
  status: '/status/',
  tasks: '/x/tpx-adding-a-task/',
  taskPlans: '/x/tpx-creating-a-task-plan/',
  textingSend: '/x/tpx-sending-a-text/',
  textingSetup: '/tpx-quick-start/set-up-texting/',
  transaction: '/x/tpx-adding-a-transaction/',
  transactionDetails: '/x/tpx-transactions/'
};

const HELP_UTM_DEFAULTS = {
  source: 'TPX',
  medium: 'product',
  campaign: 'tp-help'
};

/**
 * Generates a help link to be used in the app.
 * @param {string} key A key to be used to fetch a campus or tp.com path to a document.
 * @param {Object} options - The options used in generating a help link.
 * @param {boolean} options.[isCampusLink=false] - Whether to use the campus path in the help link url.
 * @param {string} options.[utmCampaign=HELP_UTM_DEFAULTS.campaign] - the password value
 * @param {string} options.[utmMedium=HELP_UTM_DEFAULTS.medium] - the password value
 * @param {string} options.[utmSource=HELP_UTM_DEFAULTS.source] - the password value
 * @returns string
 */
export const getHelpLink = (key, options) => {
  if (!key) {
    return null;
  }

  const {
    isCampusLink = true, // most links are from campus
    utmSource = HELP_UTM_DEFAULTS.source,
    utmMedium = HELP_UTM_DEFAULTS.medium,
    utmCampaign = HELP_UTM_DEFAULTS.campaign
  } = options || {};

  const pathArray = HELP_URLS[key].split('#');
  const path = pathArray[0];
  const hashStr = pathArray[1] ? `#${pathArray[1]}` : '';
  const campusPath = isCampusLink ? CAMPUS_PATH : '';

  return `${TP_ROOT_URL}${campusPath}${path}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}${hashStr}`;
};
