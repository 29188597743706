import React, { useEffect, useRef, useState } from 'react';
import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { saveTemplate } from '../../actions/templates';
import { getParsedTemplateBody } from '../../utils/templates';
import { TemplateLibrary } from '../TemplateLibrary/TemplateLibrary';
import { Fragment } from 'react';
import { navigate, useLocation } from '@reach/router';
import classnames from 'classnames';

import { handleImageUpload } from '../../actions/images';
import { clearMessage, showMessage } from '../../actions/message';
import { ERROR_CODES } from '../../constants/errorCodes';
import { EMAIL_LIMIT_NOTIFIER, MESSAGE_SIGNATURE_AUTOMATIC } from '../../constants/templates';
import { Button, ButtonGroup } from '../Button';
import { Editor } from '../Editor/Editor';
import { Form } from '../Form';
import { FormFieldReadOnly } from '../FormFieldReadOnly';
import { FormLabel } from '../FormLabel';
import { FormRow } from '../FormRow';
import { FormTextInput } from '../FormTextInput';
import { ImageUploading } from '../ImageUploading';
import { MessageMicro } from '../MessageMicro';

import { getEmailsFromContacts } from '../../utils/contacts';
import { pluralize } from '../../utils/strings';
import { cleanAndDeserializeForSlate, serialize } from '../Editor/editor-utils';
import { sendMassEmail } from '../../actions/tasks';
import { UNICODE } from '../../constants';
import { emailSanitizer } from '../../utils/email';
import { getRandomStateId } from '../../utils/nylas';
import { isFieldFilled } from '../../utils/validation';

import styles from './MassEmailForm.css';
import formFieldStyles from '../FormField/FormField.css';

export const MassEmailForm = props => {
  const { listOfSelected, id, templateStore, setSelectedTemplate, selectedTemplate, defaultBody, clearHandler } =
    props || {};
  const { templates, templateCategories } = templateStore || {};

  const subjectRef = useRef(null);

  const dispatch = useDispatch();
  const location = useLocation();

  const { entities } = useSelector(store => store.templates);
  const { entities: contactsEntities } = useSelector(store => store.contacts);
  const { userId, awsToken } = useSelector(store => store.user);

  const { validList: emailList, noEmailList } = getEmailsFromContacts(listOfSelected, contactsEntities);

  const [subject, setSubject] = useState(null);
  const [subjectIsValid, setSubjectIsValid] = useState(true);
  const [body, setBody] = useState(null);
  const [editor, setEditor] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [mode, setMode] = useState('view');

  const isPreviewMode = mode === 'preview';
  const isCustomizeMode = mode === 'customize';
  const isViewMode = mode === 'view';
  const isNewEmailMode = mode === 'new';

  useEffect(() => {
    if (isNewEmailMode) {
      setSubject('');
      return;
    }
    const templateBodyObj = getParsedTemplateBody(entities[selectedTemplate]?.body);
    const { subject } = templateBodyObj;
    setSubject(subject);
  }, [selectedTemplate, entities, isNewEmailMode]);

  // Update body for Editor on mode change
  useEffect(() => {
    subjectRef?.current?.focus();

    if (isPreviewMode || isViewMode) {
      setBody(defaultBody);
      setEditor(defaultBody);
      return;
    }
    if (isCustomizeMode) {
      const { body: parsedBody } = getParsedTemplateBody(entities[selectedTemplate]?.body);
      setBody(cleanAndDeserializeForSlate(parsedBody));
      setEditor(parsedBody);
      return;
    }
    if (isNewEmailMode && !isFormSubmitting) {
      setBody(null);
      setEditor(null);
      return;
    }
  }, [
    defaultBody,
    isCustomizeMode,
    selectedTemplate,
    entities,
    isPreviewMode,
    isViewMode,
    isNewEmailMode,
    isFormSubmitting
  ]);

  useEffect(() => {
    if (id) {
      setSelectedTemplate(id);
    } else if (templateCategories !== null) {
      // Set the default template
      const url = `contacts/templates/view/${
        templateCategories?.[Object.keys(templateCategories)?.[0]]?.templates[0]?.fileId
      }${location.search}`;
      navigate(url);
    }
  }, [id, templateCategories, setSelectedTemplate, location]);

  const handleChangeSubject = e => {
    const { target } = e;
    const { value } = target;

    const isValid = target.checkValidity() && isFieldFilled(value);
    setSubject(value);
    setSubjectIsValid(isValid);
  };

  const handleChangeMode = newMode => {
    setMode(newMode);
  };

  const validRecipientsStr = `${emailList?.length} ${pluralize(emailList?.length, 'email')} to be sent`;
  const noEmailRecipientsStr =
    noEmailList.length > 0 ? `; ${noEmailList?.length} excluded due to no email address/subscription` : '';

  const recipients = validRecipientsStr + noEmailRecipientsStr;

  const handleSubmit = () => {
    // clear any existing app errors
    dispatch(clearMessage());

    if (isImageUploading) {
      dispatch(showMessage({ message: ERROR_CODES.get('fe-image-wait'), type: 'error' }, true));
      return;
    }

    const instances = listOfSelected?.reduce((acc, key) => {
      const { emails, is_unsubscribed } = contactsEntities[key] || {};
      if (emails?.length > 0 && !Boolean(is_unsubscribed)) {
        return emails?.reduce((acc, i) => {
          return [...acc, { commId: i.id, contactId: key }];
        }, acc);
      }
      return acc;
    }, []);

    setIsFormSubmitting(true);

    if (!subjectIsValid) {
      setIsFormSubmitting(false);
      return;
    }

    if (!isCustomizeMode && !isNewEmailMode) {
      dispatch(
        sendMassEmail({
          description: subject,
          taskTemplate: { instances, templateFileId: entities[selectedTemplate].fileId }
        })
      ).then(() => {
        setIsFormSubmitting(false);
        clearHandler();
      });
    } else {
      const templateBody = emailSanitizer(serialize(editor));

      const options = {
        body: templateBody,
        category: null,
        description: subject,
        fileId: null,
        objectType: '3',
        subject: subject
      };

      dispatch(saveTemplate(options, { shouldShowMessage: false })).then(data => {
        const { fileId } = data;

        dispatch(
          sendMassEmail({
            description: subject,
            taskTemplate: { instances, templateFileId: fileId }
          })
        ).then(() => {
          setIsFormSubmitting(false);
          clearHandler();
        });
      });
    }
  };

  const uploadImage = async e => {
    const randomEntityId = getRandomStateId(10);
    setIsImageUploading(true);

    const fileData = await dispatch(handleImageUpload(e, userId, randomEntityId, awsToken));

    setIsImageUploading(false);

    return fileData;
  };

  const handleEditorChange = value => {
    setEditor(value);
  };
  const footerClasses = classnames({
    [styles.footer]: true,
    [styles.backButtonFooter]: !isViewMode
  });

  return (
    <Fragment>
      {isViewMode ? (
        templates && (
          <TemplateLibrary
            selectedTemplate={selectedTemplate}
            templates={templates}
            templateCategories={templateCategories}
            showCustomizeButton={false}
          />
        )
      ) : (
        <Form id="massEmailForm" className={styles.massEmailForm}>
          <div className={styles.subjectContainer}>
            <FormRow>
              <div className={formFieldStyles.field}>
                <FormLabel htmlFor="recipients">Recipients</FormLabel>
                <FormFieldReadOnly id="recipients" value={recipients} />
              </div>
            </FormRow>
            {emailList && (
              <ul className={styles.emailList}>
                {emailList.map((email, i) => {
                  const isLast = i === emailList.length - 1;
                  const separator = !isLast ? `;${UNICODE.NBSP}` : '';
                  const emailLabel = `${email}${separator}`;
                  const numberOfEmailsToShow = 15;

                  if (i === numberOfEmailsToShow) {
                    const plusLabel = `+${emailList.length - numberOfEmailsToShow} more`;
                    return (
                      <li key={`${i}::${email}`} className={styles.emailListItem}>
                        {plusLabel}
                      </li>
                    );
                  }

                  if (i > numberOfEmailsToShow) {
                    return null;
                  }

                  return (
                    <li key={`${email}-${i}'`} className={styles.emailListItem}>
                      {emailLabel}
                    </li>
                  );
                })}
              </ul>
            )}
            <FormRow>
              <div className={formFieldStyles.field}>
                <FormLabel htmlFor="subject" required={isCustomizeMode}>
                  Email Subject
                </FormLabel>
                {isCustomizeMode || isNewEmailMode ? (
                  <FormTextInput
                    id="subject"
                    onChange={handleChangeSubject}
                    size="l"
                    type="text"
                    value={subject}
                    disabled={isFormSubmitting}
                    showInvalid={!subjectIsValid}
                    required={true}
                    ref={subjectRef}
                  />
                ) : (
                  <FormFieldReadOnly id="subject" value={subject} />
                )}
              </div>
            </FormRow>
          </div>
          {!isCustomizeMode && (
            <h3 className={styles.h3}>
              <span>Email Preview</span>
            </h3>
          )}
          <FormRow>
            <div className={styles.editorContainer}>
              <Editor
                defaultBody={body}
                editorPortal="dialog"
                handleFileUpload={uploadImage}
                isDisabled={isPreviewMode}
                mode={isPreviewMode ? 'preview' : 'template'}
                getEditorValue={isCustomizeMode || isNewEmailMode ? handleEditorChange : null}
              />
              <MessageMicro icon="check" message={MESSAGE_SIGNATURE_AUTOMATIC} />
              <MessageMicro message={EMAIL_LIMIT_NOTIFIER} />
            </div>
          </FormRow>
          <ImageUploading isImageUploading={isImageUploading} />
        </Form>
      )}
      <footer className={footerClasses}>
        <ButtonGroup>
          {!isViewMode && (
            <Button
              label="Go Back"
              ariaLabel="Go Back"
              icon="arrowleft"
              type="submit"
              styleType="white"
              onClick={() => handleChangeMode('view')}
            />
          )}
        </ButtonGroup>
        <ButtonGroup>
          {isViewMode && (
            <Fragment>
              <Button
                label="Create from Scratch"
                ariaLabel="Create from Scratch."
                type="submit"
                styleType="white"
                onClick={() => handleChangeMode('new')}
              />
              <Button
                label="Select Template"
                ariaLabel="Select Template."
                type="submit"
                styleType="primary"
                onClick={() => handleChangeMode('preview')}
              />
            </Fragment>
          )}
          {isPreviewMode && (
            <Button
              label="Customize Email"
              ariaLabel="Customize emails."
              type="submit"
              styleType="white"
              onClick={() => handleChangeMode('customize')}
            />
          )}
          {!isViewMode && (
            <Button
              label="Send Group Email"
              ariaLabel="Send group emails."
              type="submit"
              styleType="primary"
              onClick={handleSubmit}
              disabled={isFormSubmitting}
            />
          )}
        </ButtonGroup>
      </footer>
    </Fragment>
  );
};

MassEmailForm.propTypes = {
  className: string
};
