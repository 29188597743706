/** @module */
import { getTime } from 'date-fns';
import format from 'date-fns/format';
import { changeContactStatus, getImportantDatesForContact } from '../actions';
import { getContacts } from '../actions/contacts';
import { showMessage } from '../actions/message';
import { getNotes } from '../actions/notes';
import { DATE_FORMATS, DEFAULT_PAGING_SIZE } from '../constants';
import { DATE_RANGES_FROM_NOW } from '../constants/preferences';
import { CONTACTS_SET, CONTACTS_UPDATE_CONTACT } from '../reducers/contacts';
import { IMPORTANT_DATES_MARKDONE } from '../reducers/importantDates';
import {
  TASKS_CURRENT_GROUP,
  TASKS_DASHBOARD_GROUP,
  TASKS_DELETE,
  TASKS_REMOVE_BY_GROUP,
  TASKS_DETAILS,
  TASKS_DONE_PAGING,
  TASKS_LOADING,
  TASKS_MARK_INCOMPLETE,
  TASKS_MOVE,
  TASKS_SET,
  TASKS_MARK_COMPLETE,
  TASKS_REMOVE_BY_PLAN_ID,
  TASKS_RESET_PAGING,
  TASKS_GET_REPEATING_PATTERN,
  TASKS_DELETE_ALL_OCCURRENCES,
  TASKS_EMAIL_STATUS,
  TASKS_SET_DETAIL_LOCATION,
  TASKS_CURRENT_ENTITY,
  TASKS_SET_LAST_SEARCHED_DATE,
  TASKS_IGNORE_TEMPORARILY,
  TASK_RESET_IGNORE_DATA,
  TASKS_UNIGNORE
} from '../reducers/tasks';
import { cleanStrOfSymbols, request, requestError, searchAndRemoveFromStorage } from '../utils';
import { getLastTouchFromAction, getContactStatus } from '../utils/contacts';
import { parseISODate } from '../utils/dates';
import { ENTITY_TYPES } from '../utils/notes';
import { pluralize } from '../utils/strings';
import { TASK_TYPES, getDateFromToday } from '../utils/tasks';
import {
  DEFAULT_DUE_PERIOD,
  TASK_CATEGORIES,
  getTasksByPlanIdGroupName,
  buildTasksGroupName,
  getDateRangeFromGroupName,
  DELETE_RECURRING_MESSAGE,
  CONFIRM_DELETE_MSG,
  EDIT_RECURRING_SYNC_MESSAGE
} from '../utils/tasks';
import { clearAlert, setAlertLoading, showAlert } from './alert';
import { navigate } from '@reach/router';

/**
 * Fetches a list of tasks.
 * If success, dispatches the setting of the tasks in the store, and optionally whether the list has reached the last page.
 * If fails, shows error message via <Toast />.
 * @param {Object} actionOptions - options to fetch a list of tasks
 * @param {String} [actionOptions.activityId] - the taskId/activityID of the last task used to fetch the next tasks while paging.
 * @param {String} [actionOptions.keyRecId] - keyRecId of the last task used to fetch the next tasks while paging.
 * @param {0} [actionOptions.direction] - the sort direction of the list of results. If 0 is passed, it will be sent to the request. Otherwise, it will compute 1 or 2 based on the due parameter.
 * @param {Number} [actionOptions.pageSize=25] - the number of tasks to fetch with the request.
 * @param {Number} [actionOptions.activityType=0] - the activity type is used as filter when fetching tasks. 0 is for today business, 1 is contact, 2 is listing, 3 is closing
 * @param {'current'|'overdue'|'today'|'tomorrow'|'future'|'currentYear'|'custom'|'pastMonth'} [actionOptions.due=today] - due period for which we are fetching the tasks
 * @param {Number} [actionOptions.category] - the category is used as filter when fetching tasks.
 * @param {Number} [actionOptions.category=0] - 0 is used for all task categories.
 * @param {Number} [actionOptions.category=1] - 1 for contact.
 * @param {Number} [actionOptions.category=2] - 2 for listing (deprecated).
 * @param {Number} [actionOptions.category=3] - 3 for closing (deprecated).
 * @param {Number} [actionOptions.category=9] - 9 for transaction.
 * @param {Number} [actionOptions.isDone=0] - 0 is for incomplete tasks
 * @param {Number} [actionOptions.isDone=1] - 1 is for complete tasks
 * @param {Number} [actionOptions.allcounts=1] -
 * @param {Boolean} [actionOptions.shouldBeCached=true] - pass false if request should not be cached
 * @param {Boolean} [actionOptions.forceRefresh=false] - pass true to forceRefresh the request
 * @param {Boolean} [actionOptions.setCurrentGroup=true] - pass false to set currentGroup as current active group
 * @param {Boolean} [actionOptions.setDashboardGroup=false] - pass true to to set dashboardGroup as current active group
 * @param {String} assignedToId user id to which the task is assigned
 */
export const getTasks = (actionOptions, assignedToId) => {
  const {
    due = 'today',
    activityId,
    keyRecId,
    pageSize = DEFAULT_PAGING_SIZE,
    category = TASK_CATEGORIES.all,
    isDone = 0,
    assignedTo = assignedToId === 'all' ? { all: 1, assigned: [] } : { all: 0, assigned: [assignedToId] },
    allCounts = 1,
    activityType = 0,
    objectId,
    forceRefresh = false,
    shouldBeCached = false,
    setCurrentGroup = true,
    setDashboardGroup = false
  } = actionOptions || {};

  // When not set directly in options of getTasks (or is from Dashboard), set default values based on due period
  const dateFrom =
    actionOptions.dateFrom && !setDashboardGroup
      ? actionOptions.dateFrom
      : format(parseISODate(getDateFromToday(DATE_RANGES_FROM_NOW[due].dateFrom)), DATE_FORMATS.ISO_DATE);
  const dateTo =
    actionOptions.dateTo && !setDashboardGroup
      ? actionOptions.dateTo
      : format(parseISODate(getDateFromToday(DATE_RANGES_FROM_NOW[due].dateTo)), DATE_FORMATS.ISO_DATE);

  let direction;

  if (actionOptions.direction === 0) {
    direction = actionOptions.direction;
  } else {
    direction = actionOptions.due === 'overdue' ? 1 : 2;
  }

  return async dispatch => {
    const queryKey = buildTasksGroupName(actionOptions, assignedToId);

    // In case of forceRefresh, clear the redux group to make sure deleted tasks are removed
    if (forceRefresh) {
      dispatch(removeTasksFromStoreByGroup(queryKey));
    }
    const requestOptions = {
      apiServiceType: 'task',
      forceRefresh,
      method: 'POST',
      path: 'list',
      payload: {
        direction: parseInt(direction),
        activityId,
        keyRecId,
        pageSize: parseInt(pageSize),
        assignedTo,
        type: activityType,
        category: parseInt(category),
        isDone: parseInt(isDone),
        allCounts: parseInt(allCounts),
        dateFrom: format(parseISODate(dateFrom), DATE_FORMATS.ISO_DATETIME_START),
        dateTo: format(parseISODate(dateTo), DATE_FORMATS.ISO_DATETIME_END),
        objectId
      },
      shouldBeCached
    };

    try {
      if (setCurrentGroup) {
        dispatch({
          type: TASKS_LOADING,
          isLoading: true
        });
      }

      if (setCurrentGroup) {
        dispatch({
          type: TASKS_CURRENT_GROUP,
          query: queryKey,
          due: due,
          isLoading: true
        });
      }

      if (setDashboardGroup) {
        dispatch(setDashboardState(queryKey, due));
      }

      const taskRequest = await request(requestOptions).catch(() => {
        // catch when we abort the request
        if (setCurrentGroup) {
          dispatch({
            type: TASKS_LOADING,
            isLoading: false
          });
        }
      });

      if (!taskRequest) {
        return;
      }

      const { data } = taskRequest;

      const { exception, records, counts } = data;

      if (exception) {
        throw exception;
      }

      if (records.length < pageSize) {
        // if the results set returned has less than the page size, we have reached the end of the list
        dispatch({
          type: TASKS_DONE_PAGING,
          data: {
            group: queryKey
          }
        });
      }

      dispatch({
        type: TASKS_SET,
        data: {
          counts,
          records
        },
        query: queryKey,
        due,
        category,
        isPaging: !!keyRecId, // we set isPaging to true if there is an keyRecId set in options
        isLoading: false,
        setCurrentGroup,
        setDashboardGroup
      });
    } catch (error) {
      dispatch({
        type: TASKS_LOADING,
        isLoading: false
      });
      requestError(error, dispatch);
    }
  };
};

/**
 * Fetches a list of tasks by plan id.
 * @param {Object} options - options to fetch a list of tasks by plan id
 * @param {String} [options.isDone] - 0, 1 or 2 TODO: find out what each of these means
 * @param {String} [options.ownerId] - Owner Id (e.g., contact id, if ownerType=contact)
 * @param {String} [options.ownerType] - Owner Type (e.g: 8: contact; 9: transaction) @see OWNER_TYPE (utils/taskPlans.js)
 * @param {String} [options.planId] - Plan Id to fetch tasks by
 * @param {boolean} [options.forceRefresh] - true if should force refresh
 */
export const getTasksByPlanId = options => {
  const { isDone, ownerId, ownerType, planId, forceRefresh = false, setCurrentGroup = true } = options || {};
  return async dispatch => {
    if (!planId || !ownerId || !ownerType) {
      return;
    }

    try {
      if (setCurrentGroup) {
        dispatch({
          type: TASKS_LOADING,
          isLoading: true
        });
      }
      const queryKey = getTasksByPlanIdGroupName(options);
      if (setCurrentGroup) {
        dispatch({
          type: TASKS_CURRENT_GROUP,
          query: queryKey,
          isLoading: true
        });
      }

      const taskRequest = await request({
        apiServiceType: 'task',
        forceRefresh,
        path: `listByPlan/${planId}`,
        params: { isDone, ownerId, ownerType }
      }).catch(() => {
        // catch when we abort the request
        dispatch({
          type: TASKS_LOADING,
          isLoading: false
        });
      });

      if (!taskRequest) {
        return;
      }

      const { data } = taskRequest;

      const { exception, records } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: TASKS_SET,
        data: {
          records
        },
        query: queryKey,
        isLoading: false
      });
    } catch (error) {
      dispatch({
        type: TASKS_LOADING,
        isLoading: false
      });
      requestError(error, dispatch);
    }
  };
};

/**
 * Get the details of a task.
 * @param {Object} options - options to request the task details
 * @param {String} options.id - the id of the task to fetch.
 */
export const getRepeatingPattern = options => {
  const { id } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'taskLegacy',
      path: `repeatingPattern/${id}`,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: TASKS_GET_REPEATING_PATTERN,
        data,
        taskId: id
      });
      return Promise.resolve(data);
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const saveTaskDetails = data => {
  return async dispatch => {
    dispatch({
      type: TASKS_DETAILS,
      data
    });
    return Promise.resolve();
  };
};

/**
 * Get the details of a task.
 * @param {Object} options - options to request the task details
 * @param {String} options.id - the id of the task to fetch.
 */
export const getEmailTaskStatus = options => {
  const { id } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'taskLegacy',
      path: `emailTaskStatus/${id}`,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data || {};

      if (data == null || data === '') {
        return Promise.reject();
      }

      if (exception) {
        throw exception;
      }
      dispatch({
        type: TASKS_EMAIL_STATUS,
        taskId: id,
        data
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Get the details of a task.
 * @param {Object} options - options to request the task details
 * @param {String} options.id - the id of the task to fetch.
 */
export const getTaskDetail = options => {
  const { id, contactCurrentGroup, shouldBeCached = false, forceRefresh = false } = options || {};
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'taskLegacy',
      path: `details/${id}`,
      shouldBeCached,
      forceRefresh
    };

    try {
      dispatch({
        type: TASKS_LOADING,
        isLoading: true
      });
      const { data } = await request(requestOptions);

      const { exception, pattern } = data || {};
      // When the tasks has already been deleted elsewhere but still remains in cache
      if (data == null || data === '') {
        return Promise.reject();
      }

      if (exception) {
        throw exception;
      }

      dispatch(saveTaskDetails(data)).then(() => {
        if (pattern) {
          dispatch(getRepeatingPattern(options));
        }
      });

      if (data.contacts && data.contacts.length > 0) {
        // In case the contact isn't in state, we need to put it here for the Lookup component

        data.contacts.map(contact => {
          const { contactStatus } = contact || {};
          // Since each contact may have different status, we need to save them one by one
          if (contactStatus) {
            const group = getContactStatus(contactStatus).toLowerCase().replace(' ', '');

            dispatch({
              type: CONTACTS_SET,
              contactRecords: [contact],
              group
            });

            // Update the current contact group to remove the contacts above
            if (contactCurrentGroup) {
              dispatch(
                getContacts({
                  q: contactCurrentGroup,
                  setCurrentGroup: false,
                  forceRefresh: true,
                  replaceGroup: true
                })
              );
            }
          }
        });

        // Remove cached requests for contact list so the next call will give up-to-date data
        searchAndRemoveFromStorage('leadlist', 'POST');
      }

      if (data.type === TASK_TYPES.tpxEmail.value) {
        dispatch(getEmailTaskStatus({ id }));
      }
      dispatch({
        type: TASKS_LOADING,
        isLoading: false
      });

      return data;
    } catch (error) {
      requestError(error, dispatch);
      dispatch({
        type: TASKS_LOADING,
        isLoading: false
      });
    }
  };
};

// Todo: Could we have a move entity action?
/**
 * Move task action.
 * @param {String} id - the ID of a task to move groups.
 * @param {String} newGroup - the name of the group that the task is moving to.
 */
export const moveTask = (id, newGroup) => {
  return dispatch => {
    dispatch({
      type: TASKS_MOVE,
      data: { id, group: newGroup }
    });
  };
};

export const saveTask = (options, dispatchers) => {
  const { transactionId, ...payload } = options;
  const { id, contacts, assignedToId, pattern } = payload;
  const { shouldDeleteAllOccurrences, currentTasksGroup, isFromDashboard = false } = dispatchers || {};

  // if transactionId is passed, put it in the
  // right place of the payload so we link the
  // task to the corresponding transaction.
  if (transactionId) {
    payload.linkTo = {
      id: transactionId,
      type: TASK_CATEGORIES.transaction
    };
  }

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'taskLegacy',
      method: 'POST',
      path: 'save',
      payload,
      shouldBeCached: false,
      ...(!!pattern ? { timeout: 60000 } : {}) // Longer timeout for repeating tasks.
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (shouldDeleteAllOccurrences) {
        dispatch({
          type: TASKS_DELETE_ALL_OCCURRENCES,
          taskId: id
        });
      }

      dispatch({
        type: TASKS_SET,
        data: {
          records: [data]
        },
        setCurrentGroup: false
      });

      if (currentTasksGroup) {
        // See buildTasksGroupName for group name format
        const currentTaskOptions = currentTasksGroup.split('::'); // the current task group name is made up of the due key and activityType id

        // we remove any task list requests from the cache
        searchAndRemoveFromStorage(`assigned${cleanStrOfSymbols(assignedToId)}`, 'tasklist');

        // We need diff params for whether the current group is for a contact or not.
        // ToDo: We need a better way of triggering a fetch of the current group.
        const isAllCategoryType = Object.keys(DATE_RANGES_FROM_NOW).includes(currentTaskOptions[1]);

        const isPlan = currentTaskOptions[1] === 'plan';

        const getTaskParams =
          isAllCategoryType || isFromDashboard
            ? // From Tasks tab
              {
                due: currentTaskOptions[1],
                activityType: currentTaskOptions[2],
                isDone: currentTaskOptions[3]
              }
            : transactionId
            ? // From transaction, different activityType/isDone if a plan.
              // If not on a plan tasks list, currentTaskOptions 2, 3 are activityType, isDone.
              // If on a plan tasks list, currentTaskOptions 3, 5 are activityType, isDone.
              {
                objectId: transactionId,
                category: TASK_CATEGORIES.transaction,
                due: DEFAULT_DUE_PERIOD['transaction'],
                activityType: isPlan ? currentTaskOptions[3] : currentTaskOptions[2],
                isDone: isPlan ? currentTaskOptions[5] : currentTaskOptions[3]
              }
            : {
                // From contact
                // If not on a plan tasks list, currentTaskOptions 1, 2, 3 are id, activityType, isDone.
                // If on a plan tasks list, currentTaskOptions 4, 3, 5 are id, activityType, isDone.
                due: DEFAULT_DUE_PERIOD['contact'], // acts as fallback for dateRange
                category: TASK_CATEGORIES.contact,
                objectId: isPlan ? currentTaskOptions[4] : currentTaskOptions[1],
                activityType: isPlan ? currentTaskOptions[3] : currentTaskOptions[2],
                isDone: isPlan ? currentTaskOptions[5] : currentTaskOptions[3]
              };

        const dispatchGetTasks = taskParams => {
          const dateRange = getDateRangeFromGroupName(currentTasksGroup);

          const actionOptions = {
            ...taskParams,
            ...dateRange,
            setCurrentGroup: false,
            forceRefresh: true
          };

          // fetches current task group tasks to make sure it is up to date (we don't worry about all lists)
          dispatch(getTasks(actionOptions, currentTaskOptions[5]));
        };

        // Get the tasks for the most likely current group.
        dispatchGetTasks(getTaskParams);

        // For each contact on the task, refetch their likely tasks. Different activityType/isDone if a plan.
        // If not on a plan tasks list, currentTaskOptions 2, 3 are activityType, isDone.
        // If on a plan tasks list, currentTaskOptions 3, 5 are activityType, isDone.

        contacts.forEach(contact => {
          const contactTaskParams = {
            objectId: contact.id,
            category: TASK_CATEGORIES.contact,
            due: DEFAULT_DUE_PERIOD['contact'],
            activityType: isPlan ? currentTaskOptions[3] : currentTaskOptions[2],
            isDone: isPlan ? currentTaskOptions[5] : currentTaskOptions[3]
          };

          dispatchGetTasks(contactTaskParams);
        });
      }
      dispatch({ type: TASKS_RESET_PAGING });
      dispatch(showMessage({ message: 'Task saved successfully.', type: 'success' }, true)); // useTimer set to true

      return data;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

const toggleTaskDoneInOptions = (fetchOptions, isDone) => {
  const newOptions = [...fetchOptions];
  // set the isDone value
  newOptions[3] = isDone;

  return newOptions;
};

export const wrapUpTask = (options, dispatchOptions) => {
  const { activityId, contactId, contactStatus, reminderId } = options;
  const {
    shouldGetFollowUp,
    shouldUpdateContactStatus,
    shouldUpdateNotes,
    shouldUpdateTasks,
    shouldUpdateImportantDates,
    currentTasksGroup,
    taskData,
    contactCurrentGroup,
    location
  } = dispatchOptions;

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'taskLegacy',
      method: 'POST',
      path: activityId ? 'wrapupActivity' : 'wrapup',
      payload: options,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (shouldUpdateContactStatus) {
        dispatch(changeContactStatus(contactId, parseInt(contactStatus)));
      }

      if (contactId) {
        dispatch({
          type: CONTACTS_UPDATE_CONTACT,
          contactId: contactId,
          updatedKeys: {
            lastTouchPoint: getLastTouchFromAction('task')
          }
        });
      }

      if (shouldUpdateNotes) {
        // We remove the myNotes request form the cache to ensure we get latest next time we view it.
        searchAndRemoveFromStorage('notelist', 'searchRequestallfalse');

        // when wrapping up within a contact details
        if (contactId) {
          dispatch(
            getNotes({
              group: `contact::${contactId}`,
              entity: { id: contactId, type: ENTITY_TYPES.contact },
              setCurrentGroup: false,
              forceRefresh: true
            })
          );
        }
        // when wrapping up a task when marking it done - you have to dispatch for task notes, and each of the attached contacts
        if (taskData) {
          dispatch(
            getNotes({
              group: `task::${activityId}`,
              entity: { id: activityId, type: ENTITY_TYPES.task },
              setCurrentGroup: false,
              forceRefresh: true
            })
          );

          if (taskData.contacts) {
            taskData.contacts.forEach(contact => {
              dispatch(
                getNotes({
                  entity: { id: contact.id, type: ENTITY_TYPES.contact },
                  group: `contact::${contact.id}`,
                  setCurrentGroup: false,
                  forceRefresh: true
                })
              );
            });
          }
        }
      }

      if (shouldUpdateTasks) {
        // get the current tasks list

        // TASK OPTION STRUCTURE
        // task::${listId}::${activityType}::${isDone}::${timeframeStr}::${assignedToId}`
        const currentTaskOptions = currentTasksGroup.split('::'); // the current task group name is made up of the due key and activityType id

        // TODO: investigate why this works: toggleTaskDoneInOptions expects isDone as 2nd parameter.
        const newTaskGroup = toggleTaskDoneInOptions(currentTaskOptions).join('::'); // We take all options, but set isDone to 1;
        const currentTaskOptionDateRangeArr = currentTaskOptions[4]?.split('to');
        const assignedToId = currentTaskOptions[5];

        if (activityId) {
          dispatch(moveTask(activityId, newTaskGroup));
        } // removes the task being marked done from the list and moves it to the new list if in state

        // Only fetch tasks if no location (indicating dialog) AND dialog isn't popped from a "plan" group,
        // or if location (indicating drawer) is a tasks page but not a transaction tasks or plan tasks page.
        const fetchTasks =
          (!location && currentTaskOptions[1] !== 'plan') ||
          (location?.pathname.includes('/tasks') &&
            !location.pathname.includes('/transactions') &&
            !location.pathname.includes('/plans'));

        if (fetchTasks) {
          const getTaskParams = (() => {
            // If on contact tasks page, do a contacts fetch.
            if (!!contactId && currentTaskOptions[1] === contactId) {
              return {
                objectId: contactId,
                category: TASK_CATEGORIES.contact,
                due: DEFAULT_DUE_PERIOD.contact,
                activityType: currentTaskOptions[2],
                isDone: currentTaskOptions[3]
              };
            } // Or else when dialog is triggered from the transaction task tab
            else if (taskData?.linkTo?.type === TASK_CATEGORIES.transaction) {
              return {
                objectId: taskData?.linkTo?.id,
                category: TASK_CATEGORIES.transaction,
                due: DEFAULT_DUE_PERIOD.transaction,
                activityType: currentTaskOptions[2],
                isDone: currentTaskOptions[3]
              };
            }
            return {
              due: currentTaskOptions[1],
              activityType: currentTaskOptions[2],
              isDone: currentTaskOptions[3],
              dateFrom: currentTaskOptionDateRangeArr?.[0],
              dateTo: currentTaskOptionDateRangeArr?.[1]
            };
          })();

          dispatch(getTasks({ ...getTaskParams, forceRefresh: true }, assignedToId));
        } // fetches current task group tasks to make sure it is up to date (we don't worry about all lists)
        if (activityId) {
          dispatch(getTaskDetail({ id: activityId, contactCurrentGroup }));
        } // make sure the tasks details are the latest
      }

      if (shouldGetFollowUp) {
        dispatch(getContacts({ q: 'followup' }));
        dispatch({
          type: CONTACTS_UPDATE_CONTACT,
          contactId: contactId,
          updatedKeys: {
            is_valid: false
          },
          forceRefresh: true
        });
      }

      if (shouldUpdateImportantDates) {
        dispatch({
          type: IMPORTANT_DATES_MARKDONE,
          data: {
            id: reminderId
          }
        });

        dispatch(
          getImportantDatesForContact({
            entityId: contactId,
            forceRefresh: true,
            setCurrentGroup: false
          })
        );
      }

      dispatch(showMessage({ message: 'Wrap up completed successfully.', type: 'success' }, true)); // useTimer set to true

      return true;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

export const markTaskIncomplete = (options, dispatchOptions) => {
  const { id } = options;
  const { currentTasksGroup } = dispatchOptions || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'taskLegacy',
      method: 'POST',
      path: 'undomarkdone',
      payload: {
        id
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      // get the current tasks list
      const currentTaskOptions = currentTasksGroup.split('::'); // the current task group name is made up of the due key and activityType id

      // Flip isDone if it's 1, otherwise it stays as 0 or 2
      const newIsDone = currentTaskOptions[3] === '1' ? '0' : currentTaskOptions[3];

      const newTaskGroup = toggleTaskDoneInOptions(currentTaskOptions, newIsDone).join('::'); // We take due, activityType, and set isDone to false 0;
      const assignedToId = currentTaskOptions[5];

      dispatch({
        type: TASKS_MARK_INCOMPLETE,
        data: { id }
      });

      dispatch({
        type: TASKS_UNIGNORE,
        data: {
          idToUnignore: id,
          timestamp: getTime(new Date())
        }
      });

      dispatch(moveTask(id, newTaskGroup)); // removes the task being marked done from the list and moves it to the new list if in state

      dispatch(getTasks({ due: 'today', setCurrentGroup: false, forceRefresh: true }, assignedToId)); // fetches today's tasks to make sure it is up to date (we don't worry about all lists)

      dispatch(showMessage({ message: 'Task marked incomplete.', type: 'success' }, true)); // useTimer set to true

      return true;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * @param {Object} options
 * @param {String} options.id Task Id
 * @param {String} options.completedDate Date of completion in the format DATE_FORMATS.ISO_DATETIME_Z
 * @param {Object} dispatchOptions
 * @param {String} dispatchOptions.currentTasksGroup the group to which the task currently belongs
 */
export const markTaskComplete = (options, dispatchOptions) => {
  const { id, completedDate } = options;
  const { currentTasksGroup } = dispatchOptions || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'taskLegacy',
      method: 'POST',
      path: 'markdone',
      payload: {
        id,
        completedDate
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      // get the current tasks list
      const currentTaskOptions = currentTasksGroup.split('::'); // the current task group name is made up of the due key and activityType id
      const newTaskGroup = toggleTaskDoneInOptions(currentTaskOptions, 1).join('::'); // We take due, activityType, and set isDone to true 1;
      const assignedToId = currentTaskOptions[5];

      dispatch({
        type: TASKS_MARK_COMPLETE,
        data: { id }
      });
      dispatch(moveTask(id, newTaskGroup)); // removes the task being marked done from the list and moves it to the new list if in state

      dispatch(getTasks({ due: 'today', setCurrentGroup: false, forceRefresh: true }, assignedToId)); // fetches today's tasks to make sure it is up to date (we don't worry about all lists)

      dispatch(showMessage({ message: 'Task marked complete.', type: 'success' }, true)); // useTimer set to true

      return true;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * Delete task action.
 * @param {String} id - the ID of a task to delete.
 * @param {String} group - the name of a group of entities
 * @param {Number} [deleteOption] - option to delete just the instance or all instances of the pattern
 * @param {Number} [deleteOption=0] - to delete one recurring instance
 * @param {Number} [deleteOption=1] - to delete all recurring instances
 */
export const deleteTask = (id, group, deleteOption = null) => {
  const options = deleteOption == null ? undefined : { delRep: deleteOption };

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'taskLegacy',
      method: 'POST',
      path: `delete/${id}`,
      params: options,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }
      // Since we have no references to all other occurences, if others are deleted,
      // We need to reset and fetch fresh again
      if (deleteOption === 1) {
        dispatch({
          type: TASKS_DELETE_ALL_OCCURRENCES,
          taskId: id
        });
      }
      dispatch({
        type: TASKS_DELETE,
        data: {
          id,
          group
        }
      });

      dispatch(showMessage({ message: 'Task deleted successfully.', type: 'success' }, true)); // useTimer set to true
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * @param {String} groupName
 */
export function removeTasksFromStoreByGroup(groupName) {
  return async dispatch => {
    dispatch({
      type: TASKS_REMOVE_BY_GROUP,
      payload: { groupName }
    });
  };
}
/**
 * @param {String} id
 * @param {String} group
 */
export function removeTasksFromStore(id, group) {
  return async dispatch => {
    dispatch({
      type: TASKS_DELETE,
      data: {
        id,
        group
      }
    });
  };
}
/**
 * @param {String} planId
 */
export const removeTasksFromStoreByPlanId = planId => {
  return async dispatch => {
    dispatch({
      type: TASKS_REMOVE_BY_PLAN_ID,
      payload: { planId }
    });
  };
};

export const resetTasksPaging = () => {
  return async dispatch => {
    dispatch({ type: TASKS_RESET_PAGING });
  };
};

/**
 * Delete a repeating task based on options
 * @param {String} id taskId
 * @param {String} group redux group name
 * @param {1|0|null} [deleteOption=0] delete only this task
 * @param {1|0|null} [deleteOption=1] delete all occurrences
 * @returns
 */
const deleteHandler = (id, group, deleteOption) => {
  return async dispatch => {
    dispatch(setAlertLoading());
    dispatch(deleteTask(id, group, deleteOption)).then(() => {
      dispatch(clearAlert());
    });
  };
};

export const openCalendarSyncWarning = (id, group, deleteOption) => {
  return async dispatch => {
    await dispatch(clearAlert());
    dispatch(
      showAlert({
        icon: 'error',
        message: EDIT_RECURRING_SYNC_MESSAGE,
        primaryButtonHandler: () => dispatch(deleteHandler(id, group, deleteOption)),
        primaryButtonLabel: 'Confirm',
        secondaryButtonHandler: () => dispatch(confirmDeleteHandler(id, group, { showCalendarSyncWarning: false })),
        secondaryButtonLabel: 'Cancel'
      })
    );
  };
};

export function confirmDeleteHandler(id, group, options) {
  const { showCalendarSyncWarning = false, isFromDashboard = false } = options || {};
  return async dispatch => {
    await dispatch(clearAlert());
    dispatch(getTaskDetail({ id }))
      .then(data => {
        const { pattern } = data || {};
        const isRepeatingTask = pattern != null && pattern !== '';
        const deleteOption = isRepeatingTask ? 0 : null;
        dispatch(
          showAlert({
            message: isRepeatingTask ? DELETE_RECURRING_MESSAGE : CONFIRM_DELETE_MSG,
            primaryButtonHandler: showCalendarSyncWarning
              ? () => dispatch(openCalendarSyncWarning(id, group, deleteOption))
              : () =>
                  dispatch(deleteHandler(id, group, deleteOption)).then(() => {
                    if (isFromDashboard) {
                      navigate('/');
                    }
                  }),
            primaryButtonLabel: isRepeatingTask ? 'Only this occurrence' : 'Delete',
            secondaryButtonLabel: isRepeatingTask ? 'All occurrences' : 'Cancel',
            secondaryButtonHandler: isRepeatingTask ? () => dispatch(deleteHandler(id, group, 1)) : null,
            tertiaryButtonLabel: isRepeatingTask ? 'Cancel' : null,
            tertiaryButtonHandler: isRepeatingTask ? () => dispatch(clearAlert()) : null
          })
        );
      })
      .catch(() => {
        dispatch(clearAlert());

        dispatch(removeTasksFromStore(id, group));
        dispatch(showMessage({ message: 'The task has been deleted.', type: 'success' }, true)); // useTimer set to true
      });
  };
}

/**
 * Send the email now
 * @param {String} id task id
 * @param {String} group the group so we can move the task later
 */
export const sendNow = (id, group, isEmail) => {
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'POST',
      path: `tasks/${isEmail ? 'sendTemplateEmail' : 'sendTemplateTexting'}`,
      payload: {
        sendStatus: 3,
        taskId: id
      },
      shouldBeCached: false
    };

    try {
      const response = await request(requestOptions);
      const { status } = response || {};

      if (status === 200) {
        dispatch({
          type: TASKS_IGNORE_TEMPORARILY,
          key: 'massMarkdone',
          data: {
            list: [id],
            timestamp: getTime(new Date())
          }
        });
        dispatch(showMessage({ message: `${isEmail ? 'Email' : 'Text'} sent successfully.`, type: 'success' }, true));
        let currentTaskOptions = group?.split('::');
        const newTaskGroup = toggleTaskDoneInOptions(currentTaskOptions).join('::');
        dispatch(moveTask(id, newTaskGroup));
      } // useTimer set to true
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const saveTaskDetailGroup = location => {
  return async dispatch => {
    dispatch({ type: TASKS_SET_DETAIL_LOCATION, location });
  };
};

export const sendMassEmail = options => {
  const { description, taskTemplate } = options;

  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'POST',
      path: 'tasks/sendMassEmail',
      payload: {
        description,
        taskTemplate
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch(showMessage({ message: 'Group email sent successfully.', type: 'success' }, true)); // useTimer set to true
      return true;
    } catch (error) {
      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Sets the current selected task in state.
 * @param {Object} options - a simple data object with the task id.
 * @param {String} options[id] - the task id.
 */
export const setCurrentTask = options => {
  const { id } = options || {};

  return dispatch => {
    dispatch({
      type: TASKS_CURRENT_ENTITY,
      data: {
        id
      }
    });
  };
};

export const setTaskLastSearchedDate = data => {
  return dispatch => {
    dispatch({
      type: TASKS_SET_LAST_SEARCHED_DATE,
      data
    });
  };
};

export const getTaskWrapupInfo = options => {
  const { activityId, category, needStopPlanFlag = false, contactId } = options;
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'task',
      method: 'POST',
      path: 'retrieveWrapupInfobyTask',
      payload: {
        activityId,
        category,
        needStopPlanFlag,
        contactId
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const getTimelineEventsByContact = options => {
  // Default to get most recent plan event
  const { contactId, eventType = 8, actionType = 2, top = 1 } = options;
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'GET',
      path: 'tasks/getTimelineEventsByContact',
      params: { contactId, eventType, actionType, top },
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Resets deletion cache
 */
export const resetIgnoreData = key => {
  return dispatch => {
    dispatch({
      type: TASK_RESET_IGNORE_DATA,
      key
    });
  };
};

/**
 * Mass delete tasks action.
 * @param {String[]} ids -  list of ids to delete
 * @param {String} group
 */
export const massDeleteTasks = (ids, group) => {
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'DELETE',
      path: `tasks`,
      payload: ids,
      shouldBeCached: false
    };

    try {
      const { data, status } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (status === 200) {
        ids.map(id =>
          dispatch({
            type: TASKS_DELETE,
            data: {
              id,
              group
            }
          })
        );

        dispatch({
          type: TASKS_IGNORE_TEMPORARILY,
          key: 'massDelete',
          data: {
            list: ids,
            timestamp: getTime(new Date())
          }
        });

        dispatch(
          showMessage(
            { message: `${ids.length} ${pluralize(ids.length, 'task')} deleted successfully.`, type: 'success' },
            true
          )
        );
      }
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * Mass mark done tasks
 * @param {String[]} ids -  list of ids to mark done
 */
export const massMarkDone = (ids, currentGroup) => {
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'POST',
      path: `tasks/markDone`,
      payload: {
        objectIds: ids,
        actionDate: format(new Date(), DATE_FORMATS.ISO_DATETIME_CALENDAR)
      },
      shouldBeCached: false
    };

    try {
      const { data, status } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (status === 200) {
        // get the current tasks list
        const currentTaskOptions = currentGroup.split('::'); // the current task group name is made up of the due key and activityType id

        // Flip isDone if it's 0, otherwise it stays as 1 or 2
        const newIsDone = currentTaskOptions[3] === '0' ? '1' : currentTaskOptions[3];
        const newTaskGroup = toggleTaskDoneInOptions(currentTaskOptions, newIsDone).join('::'); // We take due, activityType

        dispatch({
          type: TASKS_IGNORE_TEMPORARILY,
          key: 'massMarkdone',
          data: {
            list: ids,
            timestamp: getTime(new Date())
          }
        });

        ids.map(id => {
          dispatch(moveTask(id, newTaskGroup)); // removes the task being marked done from the list and moves it to the new list if in state
          dispatch({
            type: TASKS_MARK_COMPLETE,
            data: { id }
          });
        });
        dispatch(
          showMessage(
            { message: `Marked ${ids.length} ${pluralize(ids.length, 'task')} done successfully.`, type: 'success' },
            true
          )
        );
      }
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

export function setDashboardState(group, due) {
  return async dispatch => {
    dispatch({
      type: TASKS_DASHBOARD_GROUP,
      query: group,
      due
    });
  };
}
