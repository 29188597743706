import React from 'react';
import { oneOf, number, string } from 'prop-types';
import classnames from 'classnames';

import styles from './List.css';

export const ListPlaceholder = props => {
  const { className, isNested = false, listItemSize = 'm', rows = 10 } = props;

  const items = new Array(rows).fill('');

  const classes = classnames({
    [styles.placeholderList]: !className,
    [styles.isNested]: isNested,
    [className]: !!className
  });

  const listItemClasses = classnames({
    [styles.placeholderListItem]: true,
    [styles[`${listItemSize}SizeListItem`]]: true
  });

  return (
    <ul className={classes}>
      {items.map((row, i) => (
        <li key={i} className={listItemClasses}>
          {row}
        </li>
      ))}
    </ul>
  );
};

ListPlaceholder.propTypes = {
  className: string,
  listItemSize: oneOf(['s', 'm']),
  rows: number
};
