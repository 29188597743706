import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button, Loading } from '../';
import { formatBytes } from '../../utils';

import styles from './Attachments.css';

export const Attachments = props => {
  const { attachments, disabled, handleAttachmentRemoval } = props;

  const hasAttachments = attachments && Object.keys(attachments).length > 0;

  if (!hasAttachments) {
    return null;
  }

  const attachmentClasses = classnames({
    [styles.attachments]: true,
    [styles.attachmentsDisabled]: disabled
  });

  return (
    <ul className={attachmentClasses}>
      {Object.keys(attachments).map(key => {
        const attachment = attachments[key];
        const { attachmentMethod, id, name, size } = attachment;
        const fileSize = size && formatBytes(size, 2);

        // we don't show inline images in the attachments display list.
        if (attachmentMethod === 'inline') {
          return null;
        }

        return (
          <li key={name} className={styles.attachment}>
            {name}{' '}
            <em className={styles.fileSize}>
              {fileSize ? `(${fileSize})` : <Loading loading={true} size="m" className={styles.fileLoading} />}
            </em>
            <Button
              ariaLabel="Remove attachment"
              title="Remove attachment"
              icon="close"
              size="xs"
              data-fileid={id}
              onClick={handleAttachmentRemoval}
              disabled={disabled}
            />
          </li>
        );
      })}
    </ul>
  );
};

Attachments.propTypes = {
  disabled: PropTypes.bool,
  attachments: PropTypes.object,
  handleAttachmentRemoval: PropTypes.func.isRequired
};
