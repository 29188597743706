/** @module */

import { API_HOST, LOCAL_STORAGE_VERSION, LOCAL_STORAGE_ALLOW_LIST } from '../constants';

/**
 * Gets an item from localStorage
 * @param {String} key - the localStorage key to get.
 * @returns {any} value stored indexed by key in localStorage.
 *                It returns undefined if:
 *                a) key is not in localStorage
 *                b) an error occurred
 */
export const getLocalStorageItem = key => {
  try {
    const serializedItem = localStorage.getItem(key);

    if (serializedItem === null) {
      return undefined;
    }

    return JSON.parse(serializedItem);
  } catch (err) {
    return undefined;
  }
};

/**
 * Gets items from localStorage ignoring items with undefined as value.
 * @param {String[]} keys - the set of localStorage keys to get.
 * @returns {Map} a Map with the found keys mapped with their corresponding values.
 *                It returns an empty Map object if no item is found in the localStorage
 */
export const getAllLocalStorageItemsMap = keys => {
  const items = new Map();

  if (Array.isArray(keys)) {
    for (const key of keys) {
      const value = getLocalStorageItem(key);

      // we don't want to push `undefined` values (null, false, 0 are recognized)
      if (value !== undefined) {
        items.set(key, value);
      }
    }
  }

  return items;
};

/**
 * Sets an item in localStorage
 * @param {String} key - the key to use when setting the item in localStorage.
 * @param {Any} value - the value of the localStorage item.
 */
export const setLocalStorageItem = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value);

    localStorage.setItem(key, serializedValue);
  } catch (err) {
    console.warn(err);
  }
};

/**
 * Sets items in localStorage
 * @param {Map} map - a Map containing the items to set as key/value pairs.
 */
export const setAllLocalStorageItems = map => {
  if (map instanceof Map) {
    map.forEach((value, key) => setLocalStorageItem(key, value));
  }
};

/**
 * Clears localStorage resulting in the user token being deleted, and thus forcing a sign out.
 */
export const clearLocalStorage = () => {
  try {
    const allowList = getAllLocalStorageItemsMap(LOCAL_STORAGE_ALLOW_LIST);
    localStorage.clear();
    setAllLocalStorageItems(allowList);
  } catch (err) {
    console.warn(err);
  }
};

/**
 * Checks the localStorage value against an app constant to see if localStorage should be cleared.
 * It also checks to see if localStorage should be cleared due to API_HOST change.
 */
export const checkLocalStorageVersion = () => {
  const browserStorageVersion = getLocalStorageItem('version');
  const appStorageVersion = LOCAL_STORAGE_VERSION;
  const browserApiHost = getLocalStorageItem('api');
  const appApiHost = API_HOST;

  // we only want to clear localStorage during the check if the storage version number OR the api host has changed
  if (browserStorageVersion === appStorageVersion && browserApiHost === appApiHost) {
    return;
  }

  // clear local storage if there is an old version
  if (browserStorageVersion) {
    clearLocalStorage();
  }

  // set new local storage version
  setLocalStorageItem('api', API_HOST);
  setLocalStorageItem('version', appStorageVersion);
};

/**
 * Loop through localStorage and delete possible matches
 * @param {String} str - A string to match localStorage keys against - usually an id of some kind
 * @param {String} type - A string to identify the type of localStorage key to match against (essentially check that it is also included)
 */
export const searchAndRemoveFromStorage = (str, type) => {
  if (!str || !type) {
    return;
  }

  Object.keys(localStorage).forEach(key => {
    if (key.includes(str) && key.includes(type)) {
      localStorage.removeItem(key);
    }
  });
};

/**
 * Loop through customTabs and clear localStorage of relevant contactSearch queries.
 * @param {Object} customTabs - The custom tabs object with the search URLs.
 */
export const clearCustomTabsFromStorage = customTabs => {
  const tabsWithQueries = Object.keys(customTabs).filter(tab => customTabs[tab].url.startsWith('?q'));
  const searchesToClear = tabsWithQueries.map(tab => {
    const separator = customTabs[tab].url.startsWith('?q=is:') ? 'is:' : '=';
    return customTabs[tab].url.split(separator)[1];
  });
  searchesToClear.forEach(search => searchAndRemoveFromStorage('contactSearch', search));
};
