/** @module */

import {
  CREATE_NEW_CONTACT_PROPERTY,
  GET_CONTACT_PROPERTIES,
  GET_CONTACT_PROPERTIES_SUCCESS,
  CONTACT_PROPERTY_FORM_TOGGLE,
  SET_CONTACT_PROPERTY_FORM_EDIT_MODE,
  SET_PREFILLED_CONTACT_PROPERTY_FORM_DATA,
  SET_CONTACT_PROPERTY_DETAILS_FOR_FORM,
  DELETE_CONTACT_PROPERTY,
  TOGGLE_TRANSACTION_FORM
} from '../reducers/properties';
import { request, requestError } from '../utils';
import { clearAlert } from './alert';

/**
 * Toggles property form modal display
 */
export const togglePropertyFormDialog = togglePropertyFormModal => {
  return dispatch => {
    dispatch({
      type: CONTACT_PROPERTY_FORM_TOGGLE,
      togglePropertyFormModal
    });
  };
};

/**
 * Fetches property details without the need for MLS source
 * @param {string} mpr_id - property id
 */
export const getPropertyDetails = mpr_id => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'GET',
      path: `mls/properties/${mpr_id}`,
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);

      const result = data.results[0];

      dispatch({
        type: SET_CONTACT_PROPERTY_DETAILS_FOR_FORM,
        selectedPropertyDetails: result
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const setPropertyFormEditMode = editMode => {
  return dispatch => {
    dispatch({
      type: SET_CONTACT_PROPERTY_FORM_EDIT_MODE,
      propertyFormIsEditMode: editMode
    });
  };
};

/**
 * Prefill the Edit form
 */
export const prefillEditForm = propertyDetailsData => {
  return dispatch => {
    dispatch({
      type: SET_PREFILLED_CONTACT_PROPERTY_FORM_DATA,
      prefillFormData: propertyDetailsData
    });
  };
};

/**
 * clear property details object in reducer
 */
export const clearPropertyDetails = () => {
  return dispatch => {
    dispatch({
      type: SET_CONTACT_PROPERTY_DETAILS_FOR_FORM,
      selectedPropertyDetails: {}
    });
  };
};

/**
 * Fetch a contact's properties
 * @param {string} contactId - The id of the contact
 */
export const fetchProperties = contactId => {
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'GET',
      path: `contacts/${contactId}/properties`,
      shouldBeCached: false
    };

    dispatch({
      type: GET_CONTACT_PROPERTIES
    });

    try {
      const response = await request(requestOptions);
      const { data } = response;

      dispatch({
        type: GET_CONTACT_PROPERTIES_SUCCESS,
        group: contactId,
        propertyRecords: data
      });

      dispatch(togglePropertyFormDialog(false));
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Save a new contact property
 */
export const saveContactProperty = (contactId, payload, options) => {
  const { mode } = options || {};

  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: mode === 'update' ? 'PUT' : 'POST',
      path: `contacts/${contactId}/properties`,
      shouldBeCached: false,
      payload
    };

    try {
      const response = await request(requestOptions);
      const { data } = response;

      dispatch({
        type: CREATE_NEW_CONTACT_PROPERTY,
        contactProperties: data
      });

      dispatch(togglePropertyFormDialog(false));
    } catch (error) {
      requestError(error, dispatch);
    }

    dispatch(fetchProperties(contactId));

    dispatch(prefillEditForm({}));
    dispatch(clearPropertyDetails());
  };
};

/**
 * Delete a property
 */
export const deleteProperty = (contactId, propertyId) => {
  return async dispatch => {
    const requestOptions = {
      method: 'DELETE',
      baseUrlKey: 'api',
      path: `contacts/${contactId}/properties/${propertyId}`,
      shouldBeCached: false,
      payload: {}
    };
    try {
      await request(requestOptions);
      dispatch({
        type: DELETE_CONTACT_PROPERTY,
        propertyIdToDelete: propertyId
      });
    } catch (error) {
      requestError(error, dispatch);
    }

    dispatch(clearAlert());
    dispatch(fetchProperties(contactId));
  };
};

/**
 * Sets property as primary property
 */
export const setPrimaryProperty = (contactId, propertyData) => {
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'PUT',
      path: `contacts/${contactId}/properties`,
      shouldBeCached: false,
      payload: {
        ...propertyData,
        propertyOwnership: 0
      }
    };

    try {
      const response = await request(requestOptions);
      const { data } = response;

      dispatch({
        type: CREATE_NEW_CONTACT_PROPERTY,
        contactProperties: data
      });
    } catch (error) {
      requestError(error, dispatch);
    }

    dispatch(fetchProperties(contactId));
    dispatch(prefillEditForm({}));
    dispatch(clearPropertyDetails());
  };
};

/**
 * Toggles Add Transaction Form
 */
export const toggleTransactionForm = (propertyId, contactId) => {
  return async dispatch => {
    dispatch({
      type: TOGGLE_TRANSACTION_FORM,
      propertyId,
      contactId
    });
  };
};
