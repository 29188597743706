import { string } from 'prop-types';
import React from 'react';
import { COUNTRIES_LIST } from '../../data/countries';
import { DEFAULT_STATES_LIST } from '../../data/states';
import { DEFAULT_STREET_TYPES } from '../../data/street-types';
import { isValidZip } from '../../utils';
import { Dropdown } from '../Dropdown';
import { FormField } from '../FormField';
import formFieldStyles from '../FormField/FormField.css';
import { FormFieldset } from '../FormFieldset';
import { FormLabel } from '../FormLabel';
import { FormRow } from '../FormRow';
import { SET_ADDRESS_FIELDS } from './manualAddTransactionFormReducer';
const DEFAULT_COUNTRY = 'USA';

export const ManualAddAddressForm = props => {
  const { address, isFormSubmitting, isFormValid, dispatchFormState } = props;
  const { unit, streetNumber, streetName, streetSuffix, country, state, city, postalCode, county } = address;

  const handleChange = e => {
    const { target } = e;

    const { id, value } = target;

    const stateKey = id;

    dispatchFormState({
      type: SET_ADDRESS_FIELDS,
      id: stateKey,
      value: {
        [stateKey]: {
          value,
          isValid: target.checkValidity()
        }
      }
    });
  };

  const handleZipChange = e => {
    const { target } = e;

    const { id, value } = target;

    const stateKey = id;
    dispatchFormState({
      type: SET_ADDRESS_FIELDS,
      id: stateKey,

      value: {
        [stateKey]: {
          value,
          isValid: target.checkValidity() && isValidZip(value)
        }
      }
    });
  };

  return (
    <FormFieldset label="Address">
      <FormRow>
        <FormField
          id="streetNumber"
          label="Street #"
          value={streetNumber.value || ''}
          onChange={handleChange}
          inputType="text"
          maxLength="32"
          size="xs"
          disabled={isFormSubmitting}
          formIsValid={isFormValid}
          fieldIsValid={streetNumber.isValid}
          required={streetNumber.isRequired}
          validationMessage=""
        />

        <FormField
          id="streetName"
          label="Street Name"
          value={streetName.value || ''}
          inputType="text"
          maxLength="256"
          size="m"
          onChange={handleChange}
          disabled={isFormSubmitting}
          formIsValid={isFormValid}
          fieldIsValid={streetName.isValid}
          required={streetName.isRequired}
        />
        <div className={formFieldStyles.field}>
          <FormLabel htmlFor="streetSuffix" required={streetSuffix.isRequired}>
            Street Type
          </FormLabel>
          <Dropdown
            id="streetSuffix"
            items={DEFAULT_STREET_TYPES}
            onChange={handleChange}
            defaultValue=" "
            size="s"
            value={streetSuffix?.value?.toUpperCase() || ''}
            fieldIsValid={streetSuffix.isValid}
            formIsValid={isFormValid}
            disabled={isFormSubmitting}
          />
        </div>
      </FormRow>
      <FormRow>
        <FormField
          id="unit"
          label="Unit"
          value={unit.value || ''}
          inputType="text"
          maxLength="32"
          size="xs"
          onChange={handleChange}
          disabled={isFormSubmitting}
          formIsValid={isFormValid}
          fieldIsValid={unit.isValid}
          required={unit.isRequired}
        />
      </FormRow>
      <FormRow>
        <FormField
          id="city"
          label="City"
          value={city.value || ''}
          onChange={handleChange}
          disabled={isFormSubmitting}
          formIsValid={isFormValid}
          fieldIsValid={city.isValid}
          required={city.isRequired}
        />
        <div className={formFieldStyles.field}>
          <FormLabel htmlFor="state" required={state.isRequired}>
            State/Province
          </FormLabel>
          <Dropdown
            id="state"
            items={DEFAULT_STATES_LIST}
            onChange={handleChange}
            defaultValue="Select a state..."
            value={state.value || ''}
            fieldIsValid={state.isValid}
            formIsValid={isFormValid}
            disabled={isFormSubmitting}
          />
        </div>
      </FormRow>
      <FormRow>
        <FormField
          id="postalCode"
          label="Zip/Postal Code"
          value={postalCode.value || ''}
          onChange={handleZipChange}
          disabled={isFormSubmitting}
          formIsValid={isFormValid}
          fieldIsValid={postalCode.isValid}
          required={postalCode.isRequired}
        />
        <FormField
          id="county"
          label="County"
          value={county.value || ''}
          onChange={handleChange}
          disabled={isFormSubmitting}
          formIsValid={isFormValid}
          fieldIsValid={county.isValid}
          required={county.isRequired}
        />
      </FormRow>
      <FormRow>
        <div className={formFieldStyles.field}>
          <FormLabel htmlFor="country" required={country.isRequired}>
            Country
          </FormLabel>
          <Dropdown
            id="country"
            label="Country"
            items={COUNTRIES_LIST}
            defaultValue={DEFAULT_COUNTRY}
            onChange={handleChange}
            value={country.value || ''}
            disabled={isFormSubmitting}
            fieldIsValid={country.isValid}
          />
        </div>
      </FormRow>
    </FormFieldset>
  );
};

ManualAddAddressForm.propTypes = {
  className: string
};
