/** @module */
import { showMessage } from '../actions/message';
import { DEFAULT_PAGING_SIZE } from '../constants';
import { CONTACTS_UPDATE_CONTACT } from '../reducers/contacts';
import { SETTINGS_RA_TEXTING_DATA, SETTINGS_TEXTING_DATA } from '../reducers/settings';
import { TEXTS_DONE_PAGING, TEXTS_SET, TEXTS_LOADING } from '../reducers/texts';
import { request, requestError, formatPhoneNumber } from '../utils';
import { getLastTouchFromAction } from '../utils/contacts';

/**
 * Check whether user registered for texting.
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to check texting registration
 * @param {String} [options.id] - the user id to check texting registration.
 * @param {String} [options.isResponsibleAgent] - check this account's responsible agent's data
 */
export const checkTextingRegistration = options => {
  const { id, forceRefresh, isResponsibleAgent = false } = options || {};

  return async dispatch => {
    try {
      const requestOptions = {
        baseUrlKey: 'api',
        method: 'GET',
        path: `tasks/registration/${id}`,
        shouldBeCached: false,
        forceRefresh
      };

      if (!id) {
        return;
      }
      const textingRequest = await request(requestOptions);

      if (!textingRequest) {
        return;
      }

      const { data } = textingRequest;
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: !isResponsibleAgent ? SETTINGS_TEXTING_DATA : SETTINGS_RA_TEXTING_DATA,
        texting: data
      });

      return data;
    } catch (error) {
      requestError(error, dispatch);

      return null;
    }
  };
};

/**
 * Saves texting registration details.
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to save texting details
 * @param {String} [options.userId] - the user id to check texting registration.
 * @param {String} [options.agentPhone] - the phone number of the agent.
 * @param {String} [options.timeZone] - the timezone of the agents location.
 */
export const saveTextingRegistration = (options, isEdit) => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'sms',
      method: 'POST',
      path: `Registration${isEdit ? '/Update' : ''}`,
      payload: options,
      shouldBeCached: false,
      timeout: 60000
    };

    try {
      const textingRequest = await request(requestOptions);
      if (!textingRequest) {
        return;
      }
      const { data } = textingRequest;
      const { error } = data;

      const parsedError = error && JSON.parse(error);

      if (parsedError) {
        return Promise.reject(parsedError);
      }
      dispatch({
        type: SETTINGS_TEXTING_DATA,
        texting: data
      });
      if (data.insider) {
        dispatch(
          showMessage(
            {
              message: 'Texting setup registered successfully.',
              type: 'success'
            },
            true
          )
        ); // useTimer set to true
      } else if (data?.registerred === false) {
        dispatch(
          showMessage({ message: 'Failed setup Texting registeration. Please try again later', type: 'error' }, true)
        ); // useTimer set to true
      }
      return data;
    } catch (error) {
      requestError(error, dispatch);

      return null;
    }
  };
};

/**
 * Resend the texting setup confirmation text.
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to resend the texting confirmation.
 * @param {String} [options.userId] - the user id.
 */
export const resendTextingConfirmation = options => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'sms',
      method: 'POST',
      path: 'Registration/Resend',
      payload: options,
      shouldBeCached: false
    };

    try {
      const textingRequest = await request(requestOptions);

      if (!textingRequest) {
        return;
      }

      const { data } = textingRequest;
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (data.status === 'OK') {
        dispatch(
          showMessage({ message: 'Please check your text messages for a new confirmation.', type: 'success' }, true)
        ); // useTimer set to true
      }

      return data;
    } catch (error) {
      requestError(error, dispatch);

      return null;
    }
  };
};

/**
 * Deletes texting registration.
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to deletes texting
 * @param {String} [options.userId] - the user id to check texting registration.
 */
export const deleteTextingRegistration = options => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'sms',
      method: 'POST',
      path: 'Registration/Delete',
      payload: options,
      shouldBeCached: false
    };

    try {
      const textingRequest = await request(requestOptions);

      if (!textingRequest) {
        return;
      }

      const { data } = textingRequest;
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: SETTINGS_TEXTING_DATA,
        texting: null
      });

      if (data.status === 'OK') {
        dispatch(showMessage({ message: 'Texting setup removed successfully.', type: 'success' }, true)); // useTimer set to true
      } else {
        dispatch(showMessage({ message: 'Failed removing Texting registeration.', type: 'error' }, true)); // useTimer set to true
      }
      return data;
    } catch (error) {
      requestError(error, dispatch);

      return null;
    }
  };
};

/**
 * Get Texts
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to get texts
 * @param {String} [options.contact] - the contact Id to get the texts.
 * @param {String} [options.type] - the type id to get the texts.
 */
export const getTexts = options => {
  const { contact, index = 1, type, forceRefresh } = options;
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'sms',
      forceRefresh,
      params: { contact, index, type, pagesize: DEFAULT_PAGING_SIZE },
      path: 'TimelineSms'
    };

    try {
      dispatch({
        type: TEXTS_LOADING,
        isLoading: true
      });

      const textingRequest = await request(requestOptions);

      if (!textingRequest) {
        return;
      }

      const { data } = textingRequest;
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (data.pageIndex === data.totalPages) {
        // if the results set returned has less than the page size, we have reached the end of the list
        dispatch({
          type: TEXTS_DONE_PAGING,
          id: options.contact
        });
      }

      dispatch({
        type: TEXTS_SET,
        id: options.contact,
        data,
        isLoading: false
      });
    } catch (error) {
      dispatch({
        type: TEXTS_LOADING,
        isLoading: false
      });
      requestError(error, dispatch);

      return null;
    }
  };
};

/**
 * Send Text
 * @param {String} contactId - contact Id to send contacts
 * @param {String} userId - User Id through which text will be sent
 * @param {phone} - Phone number of the reciever
 * @param {message} - Message to be sent
 */
export const sendText = options => {
  return async dispatch => {
    const { contactId, userId, phone, message } = options;
    const receiverPhone = formatPhoneNumber(phone);

    if (!receiverPhone) {
      dispatch(showMessage({ message: 'Invalid phone number', type: 'error' }, true));
      return false;
    }

    const payload = {
      contactId,
      userId,
      receiverPhone,
      message
    };

    const requestOptions = {
      apiServiceType: 'sms',
      method: 'POST',
      path: `OutboundSms`,
      payload,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch(showMessage({ message: 'Text message sent.', type: 'success' }, true));

      dispatch(getTexts({ contact: contactId, type: 'all', forceRefresh: true }));

      if (contactId) {
        dispatch({
          type: CONTACTS_UPDATE_CONTACT,
          contactId: contactId,
          updatedKeys: {
            lastTouchPoint: getLastTouchFromAction('text')
          }
        });
      }

      return true;
    } catch (error) {
      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Check for SMS Opt In
 * @param {String} contactId - contact Id to send contacts
 */
export const checkSMSOptIn = contactId => {
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'GET',
      path: `tasks/initiateSmsOptIn?contactId=${contactId}`,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      return data;
    } catch (error) {
      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Send SMS Opt In Email
 */
export const sendSMSInvitationEmail = options => {
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'POST',
      path: `tasks/sendSMSInvitationEmail`,
      payload: options,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch(showMessage({ message: 'Opt In Email Sent.', type: 'success' }, true));

      return data;
    } catch (error) {
      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Check for SMS texting history
 * @param {String} contactId - contact Id to send contacts
 */
export const checkSMSHistory = contactId => {
  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'GET',
      path: `tasks/hasTextHistory?contactId=${contactId}`,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      return data;
    } catch (error) {
      requestError(error, dispatch);
      return false;
    }
  };
};
