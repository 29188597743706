import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { clearAlert, showAlert } from '../../../actions';
import { clearDrawer, showDrawer } from '../../../actions/drawer';
import { Icon } from '../../../components';
import { cleanPhoneNumber, URI_SCHEMES_BY_COMM_TYPE, getCommunicationLabel, checkUnsubscribe } from '../../../utils';
import { trackEvent } from '../../../utils/analytics';

import styles from './CommList.css';
import { formatUrl } from '../../../utils/strings';

const CommListItem = props => {
  const {
    commMethod,
    commMethodType,
    defaultCommId,
    hasEmailIntegrationSetup,
    isUnsubscribed,
    showAlert,
    clearAlert,
    showDrawer,
    clearDrawer
  } = props;
  const { id, value } = commMethod;
  const uriScheme = URI_SCHEMES_BY_COMM_TYPE[commMethodType] ? `${URI_SCHEMES_BY_COMM_TYPE[commMethodType]}:` : '';
  const isDefaultCommMethod = defaultCommId === id;
  const label = getCommunicationLabel(commMethod, commMethodType);
  const cleanValue = commMethodType === 'phone' ? cleanPhoneNumber(value) : value;
  const tooltip = isDefaultCommMethod ? `Primary contact method: ${cleanValue}` : `${label}: ${cleanValue}`;
  const href = commMethodType !== 'website' ? `${uriScheme}${cleanValue}` : formatUrl(cleanValue);
  const isSmsOptIn = commMethod.smsOptInStatus === 1;
  const isSmsOptOut = commMethod.smsOptInStatus === 2;
  const isSmsOptInOrOut = commMethod.tag === 'Mobile' && (isSmsOptIn || isSmsOptOut);

  const openerProps =
    commMethodType !== 'phone' // email or website
      ? {
          target: '_blank',
          rel: 'noopener'
        }
      : null;

  const trackComm = useCallback(() => {
    trackEvent('contactDetails', commMethodType);
  }, [commMethodType]);

  const handleCommCallback = useCallback(
    e => {
      if (commMethodType === 'email') {
        if (hasEmailIntegrationSetup) {
          e.preventDefault();
          showDrawer({ drawerType: 'emailForm', email: cleanValue });
          //navigate(location.pathname, { state: { email: cleanValue } });
        } else {
          checkUnsubscribe(
            {
              href,
              isUnsubscribed,
              commMethodType,
              showAlert,
              clearAlert,
              clearDrawer,
              callBack: null
            },
            e
          );
        }
      }
      trackComm();
    },
    [
      cleanValue,
      clearAlert,
      clearDrawer,
      commMethodType,
      hasEmailIntegrationSetup,
      href,
      isUnsubscribed,
      showAlert,
      showDrawer,
      trackComm
    ]
  );

  return (
    <li className={isSmsOptInOrOut || isDefaultCommMethod ? styles.listItem : styles.listItemWithoutIcons}>
      <a
        href={href}
        className={styles.link}
        title={tooltip}
        onClick={handleCommCallback}
        {...openerProps}
        data-cy={`commIdItem${commMethodType}`}
      >
        {cleanValue}
      </a>
      <span>
        {isSmsOptInOrOut && (
          <Icon
            name={isSmsOptIn ? 'smsOptIn' : 'smsOptOut'}
            size="xs"
            isColored={true}
            className={styles.smsOptInIcon}
            title={isSmsOptIn ? 'SMS Opted In' : 'SMS Opted Out'}
          />
        )}
        {isDefaultCommMethod && (
          <Icon
            name="flag"
            size="xxs"
            isColored={true}
            className={styles.defaultCommIcon}
            title="Primary contact method"
          />
        )}
      </span>
      <span className={styles.label} title={label}>
        {label}
      </span>
    </li>
  );
};

export const CommList = props => {
  const {
    className,
    commMethodType,
    data,
    defaultCommId,
    hasEmailIntegrationSetup,
    isUnsubscribed,
    showAlert,
    clearAlert,
    showDrawer,
    clearDrawer
  } = props;

  if (!data) {
    return null;
  }

  const classes = classnames({
    [styles.list]: !className,
    [className]: !!className
  });

  return (
    <ul className={classes}>
      {data.map((commMethod, i) => {
        return (
          <CommListItem
            key={`${commMethodType}${i}`}
            commMethod={commMethod}
            commMethodType={commMethodType}
            defaultCommId={defaultCommId}
            hasEmailIntegrationSetup={hasEmailIntegrationSetup}
            isUnsubscribed={isUnsubscribed}
            showAlert={showAlert}
            clearAlert={clearAlert}
            showDrawer={showDrawer}
            clearDrawer={clearDrawer}
          />
        );
      })}
    </ul>
  );
};

const mapDispatchToProps = {
  clearAlert,
  clearDrawer,
  showAlert,
  showDrawer
};

export default connect(null, mapDispatchToProps)(CommList);

CommList.propTypes = {
  className: PropTypes.string,
  commMethodType: PropTypes.oneOf(['phone', 'email', 'website']).isRequired,
  data: PropTypes.array.isRequired,
  isUnsubscribed: PropTypes.bool
};
