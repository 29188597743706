export const DEFAULT_STATES_LIST = [
  { value: 'AL', title: 'Alabama' },
  { value: 'AK', title: 'Alaska' },
  { value: 'AB', title: 'Alberta' },
  { value: 'AS', title: 'American Samoa' },
  { value: 'AZ', title: 'Arizona' },
  { value: 'AR', title: 'Arkansas' },
  { value: 'BC', title: 'British Columbia' },
  { value: 'CA', title: 'California' },
  { value: 'CO', title: 'Colorado' },
  { value: 'CT', title: 'Connecticut' },
  { value: 'DE', title: 'Delaware' },
  { value: 'DC', title: 'District of Columbia' },
  { value: 'FL', title: 'Florida' },
  { value: 'GA', title: 'Georgia' },
  { value: 'GU', title: 'Guam' },
  { value: 'HI', title: 'Hawaii' },
  { value: 'ID', title: 'Idaho' },
  { value: 'IL', title: 'Illinois' },
  { value: 'IN', title: 'Indiana' },
  { value: 'IA', title: 'Iowa' },
  { value: 'KS', title: 'Kansas' },
  { value: 'KY', title: 'Kentucky' },
  { value: 'LA', title: 'Louisiana' },
  { value: 'ME', title: 'Maine' },
  { value: 'MB', title: 'Manitoba' },
  { value: 'MH', title: 'Marshall Islands' },
  { value: 'MD', title: 'Maryland' },
  { value: 'MA', title: 'Massachusetts' },
  { value: 'MI', title: 'Michigan' },
  { value: 'FM', title: 'Micronesia' },
  { value: 'MN', title: 'Minnesota' },
  { value: 'MS', title: 'Mississippi' },
  { value: 'MO', title: 'Missouri' },
  { value: 'MT', title: 'Montana' },
  { value: 'NE', title: 'Nebraska' },
  { value: 'NV', title: 'Nevada' },
  { value: 'NB', title: 'New Brunswick' },
  { value: 'NH', title: 'New Hampshire' },
  { value: 'NJ', title: 'New Jersey' },
  { value: 'NM', title: 'New Mexico' },
  { value: 'NY', title: 'New York' },
  { value: 'NL', title: 'Newfoundland' },
  { value: 'NC', title: 'North Carolina' },
  { value: 'ND', title: 'North Dakota' },
  { value: 'MP', title: 'Northern Marianas' },
  { value: 'NT', title: 'Northwest Territories' },
  { value: 'NS', title: 'Nova Scotia' },
  { value: 'NU', title: 'Nunavut' },
  { value: 'OH', title: 'Ohio' },
  { value: 'OK', title: 'Oklahoma' },
  { value: 'ON', title: 'Ontario' },
  { value: 'OR', title: 'Oregon' },
  { value: 'PW', title: 'Palau' },
  { value: 'PA', title: 'Pennsylvania' },
  { value: 'PE', title: 'Prince Edward Island' },
  { value: 'PR', title: 'Puerto Rico' },
  { value: 'QC', title: 'Quebec' },
  { value: 'RI', title: 'Rhode Island' },
  { value: 'SA', title: 'Saipan' },
  { value: 'SK', title: 'Saskatchewan' },
  { value: 'SC', title: 'South Carolina' },
  { value: 'SD', title: 'South Dakota' },
  { value: 'TN', title: 'Tennessee' },
  { value: 'TX', title: 'Texas' },
  { value: 'UT', title: 'Utah' },
  { value: 'VT', title: 'Vermont' },
  { value: 'VI', title: 'Virgin Islands' },
  { value: 'VA', title: 'Virginia' },
  { value: 'WA', title: 'Washington' },
  { value: 'WV', title: 'West Virginia' },
  { value: 'WI', title: 'Wisconsin' },
  { value: 'WY', title: 'Wyoming' },
  { value: 'YT', title: 'Yukon' }
];

export const STATES_LIST = [
  ...DEFAULT_STATES_LIST,
  { value: 'ACT', title: 'ACT' },
  { value: 'NSW', title: 'NSW' },
  { value: 'QLD', title: 'QLD' },
  { value: 'TAS', title: 'TAS' },
  { value: 'VIC', title: 'VIC' }
].sort((a, b) => a.title.localeCompare(b.title));

const CANADA_PROVINCE_LIST = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];

export const US_STATE_ONLY = STATES_LIST.filter(state => !CANADA_PROVINCE_LIST.includes(state.value));

export const CANADA_PROVINCE_ONLY = STATES_LIST.filter(state => CANADA_PROVINCE_LIST.includes(state.value));
