/** @module */

export const PHOTOVIEWER_OPEN = 'PHOTOVIEWER_OPEN';
export const PHOTOVIEWER_CLOSE = 'PHOTOVIEWER_CLOSE';
export const PHOTOVIEWER_CLEAR = 'PHOTOVIEWER_CLEAR';
export const PHOTOVIEWER_SET_INDEX = 'PHOTOVIEWER_SET_INDEX';
export const PHOTOVIEWER_SET_URLS = 'PHOTOVIEWER_SET_URLS';
export const PHOTOVIEWER_SET_URL = 'PHOTOVIEWER_SET_URL';
export const PHOTOVIEWER_SET_NEXT_INDEX = 'PHOTOVIEWER_SET_NEXT_INDEX';
export const PHOTOVIEWER_SET_PREV_INDEX = 'PHOTOVIEWER_SET_PREV_INDEX';

export const initialState = {
  isOpen: false,
  index: null,
  urls: null
};

/**
 * The photo viewer redux reducer.
 * @param {Object} state - the current state of the photo viewer store.
 * @param {Object} [action.type=default] - the action to take on the photo viewer store
 */
export const photoViewerReducer = (state = initialState, action = {}) => {
  const { type = 'default', index, urls, url } = action;
  const { index: currentIndex, urls: stateUrls } = state;
  switch (type) {
    case PHOTOVIEWER_OPEN:
      return {
        ...state,
        isOpen: true
      };
    case PHOTOVIEWER_CLOSE:
      return {
        ...state,
        isOpen: false
      };
    case PHOTOVIEWER_CLEAR:
      return initialState;
    case PHOTOVIEWER_SET_NEXT_INDEX:
      const nextIndex = currentIndex + 1 < stateUrls?.length ? currentIndex + 1 : 0;
      return {
        ...state,
        index: nextIndex
      };
    case PHOTOVIEWER_SET_PREV_INDEX:
      const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : stateUrls?.length - 1;
      return {
        ...state,
        index: prevIndex
      };
    case PHOTOVIEWER_SET_INDEX:
      return {
        ...state,
        index
      };
    case PHOTOVIEWER_SET_URLS:
      return {
        ...state,
        urls
      };
    case PHOTOVIEWER_SET_URL:
      if (state.urls) {
        const foundIndex = state.urls.findIndex(data => data?.url === url);
        if (foundIndex != null) {
          return {
            ...state,
            index: foundIndex
          };
        }
      }
    default:
      return state;
  }
};
