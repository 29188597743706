import React, { Fragment, lazy, Suspense, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string, bool, element, func, oneOfType, arrayOf, shape, array } from 'prop-types';
import { navigate } from '@reach/router';
import classnames from 'classnames';

import { getTaskDetail, markTaskIncomplete, saveTask, sendNow, setCurrentTask } from '../../actions/tasks';
import { showWrapup } from '../../actions/wrapup';
import { trackEvent } from '../../utils';
import { buildTestId } from '../../utils/tests';
import { Button } from '../Button';
import { Container } from '../Container';
import { TaskPlanItemCard } from '../Card/TaskPlanItemCard';
import { Dialog, DialogHeader } from '../Dialog';
import { List } from './List';
import ListNoResults from './ListNoResults';
import { Loading } from '../Loading';
import { PreviewTaskDialog } from '../PreviewEmailTaskDialog/PreviewEmailTaskDialog';
import { PLANS_URI } from '../../constants/taskPlans';

import styles from './TaskPlanItemsList.css';
import listStyles from './List.css';

const TaskForm = lazy(() => import('../TaskForm/TaskForm'));

export const TaskPlanItemsList = props => {
  const {
    Card = TaskPlanItemCard,
    className,
    groupedList,
    isLoading = false,
    isNested = false,
    planId,
    readOnly = false,
    testId = 'TaskPlanItemsList',
    isVirtuoso = true
  } = props;

  const classes = classnames({
    [listStyles.listContainer]: true,
    [listStyles.listContainerNested]: isNested,
    [className]: true
  });

  return (
    <Fragment>
      <Container className={classes}>
        {isLoading ? (
          <Loading />
        ) : (
          <GroupList
            Card={Card}
            groups={groupedList}
            isNested={isNested}
            planId={planId}
            readOnly={readOnly}
            testId={testId}
            isVirtuoso={isVirtuoso}
          />
        )}
      </Container>
    </Fragment>
  );
};

TaskPlanItemsList.propTypes = {
  Card: oneOfType([element, func]),
  className: string,
  isLoading: bool,
  isNested: bool,
  groupedList: arrayOf(shape({ name: string, items: array })).isRequired,
  planId: string,
  readOnly: bool,
  testId: string
};

function GroupList(props) {
  const { Card, isNested, planId, readOnly, testId, groups, isVirtuoso } = props;

  if (groups.length === 0) {
    return <ListNoResults isNested={isNested} noResultsMessage="No matching tasks." />;
  }

  return groups.map((group, i) => {
    const showAddDayButton = !readOnly && (!groups[i + 1] || groups[i + 1]?.daysFromStart - group.daysFromStart > 1);

    return (
      <ItemsGroup
        key={group.name}
        Card={Card}
        groupNumber={i}
        isNested={isNested}
        planId={planId}
        readOnly={readOnly}
        testId={testId}
        isVirtuoso={isVirtuoso}
        {...group}
        showAddDayButton={showAddDayButton}
      />
    );
  });
}

function ItemsGroup({
  Card,
  dateType,
  daysFromStart,
  groupNumber,
  showAddDayButton,
  name,
  items,
  isNested,
  planId,
  readOnly,
  testId,
  isVirtuoso
}) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const {
    currentEntity: currentTask,
    currentGroup: taskCurrentGroup,
    entities: tasks,
    isLoading
  } = useSelector(store => store.tasks);

  const groupClasses = classnames({
    [styles.groupHeader]: true,
    [styles[`group${groupNumber}`]]: groupNumber === 0
  });
  const listClasses = classnames({
    [styles.dayList]: true,
    [styles.noHeader]: !(name && name.length > 0)
  });

  const navigateAdd = useCallback(
    state => {
      navigate(`${PLANS_URI}/${planId}/add`, { state });
    },
    [planId]
  );

  const handleAddTask = useCallback(() => {
    const days = daysFromStart != null ? daysFromStart : 1;
    navigateAdd({ dateType, daysFromStart: days });
  }, [dateType, daysFromStart, navigateAdd]);

  const handleAddDay = useCallback(() => {
    const days = daysFromStart ? daysFromStart + 1 : 1;
    navigateAdd({ daysFromStart: days });
  }, [daysFromStart, navigateAdd]);

  const handleCompleteTask = task => {
    dispatch(showWrapup({ task }));
  };

  const handleWrapup = (e, task) => {
    e.preventDefault();

    const { id, completed, isDone } = task;

    if (isDone || completed) {
      dispatch(markTaskIncomplete({ id }, { currentTasksGroup: taskCurrentGroup }));
      return;
    }

    handleCompleteTask(task, taskCurrentGroup);
  };

  const toggleEditMode = (e, id) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!isOpen) {
      dispatch(setCurrentTask({ id }));
      trackEvent('taskLists', 'editOpen');
    }

    setIsOpen(!isOpen);
  };

  const togglePreviewMode = (e, id) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!isPreviewOpen) {
      dispatch(setCurrentTask({ id }));
      trackEvent('taskLists', 'previewOpen');
    }

    setIsPreviewOpen(!isPreviewOpen);
  };

  const handleSubmitFromPreview = (e, { isEmail }) => {
    dispatch(sendNow(currentTask, taskCurrentGroup, isEmail)).then(() => {
      dispatch(getTaskDetail({ id: currentTask, forceRefresh: true }));
    });
  };

  // Update the task with the new fileId from the one-off template
  const handleTemplateChange = ({ id: templateFileId }) => {
    const {
      taskTemplate,
      contacts,
      assignedTo,
      assignedToId,
      description,
      descriptionNote,
      endOfEvent,
      id,
      isTemplate,
      startOfEvent,
      activityType
    } = tasks?.[currentTask] || {};

    const payload = {
      assignedTo,
      assignedToId,
      description,
      descriptionNote,
      contacts,
      endOfEvent,
      id,
      isTemplate: isTemplate ? '1' : '0',
      startOfEvent,
      taskTemplate: { ...taskTemplate, templateFileId },
      type: activityType
    };
    return dispatch(saveTask(payload));
  };

  const { taskTemplate, contacts } = tasks?.[currentTask] || {};

  const { templateFileId: fileId } = taskTemplate || {};

  if (items.length === 0) {
    return null;
  }

  return (
    <Fragment>
      {name && (
        <div className={groupClasses}>
          <h2 className={styles.h2}>{name}</h2>
        </div>
      )}
      <div className={listClasses}>
        <List
          data-testid={buildTestId(testId, 'List')}
          noResultsMessage="No matching tasks."
          headerHeightOffset={230}
          isVirtuoso={isVirtuoso}
        >
          {items.map(item => {
            const { id, itemCode } = item;

            return (
              <li
                data-testid={buildTestId(testId, `listItem-${id}`)}
                key={`${id}-${itemCode}`}
                className={styles.listItem}
              >
                <Card
                  data={item}
                  isNested={isNested}
                  location={location}
                  readOnly={readOnly}
                  testId={buildTestId(testId, `Card`)}
                  group={taskCurrentGroup}
                  handleEditMode={e => toggleEditMode(e, id)}
                  handlePreviewMode={e => togglePreviewMode(e, id)}
                  wrapupHandler={handleWrapup}
                />
              </li>
            );
          })}
        </List>
        {!readOnly && (
          <div className={styles.dayListActions}>
            <Button
              ariaLabel={`Add task to ${name}`}
              icon="addtask"
              label="Add Task"
              onClick={handleAddTask}
              styleType="white"
              data-cy="addTaskFromPlanItemList"
            />
          </div>
        )}
      </div>
      {showAddDayButton && (
        <div className={styles.afterDayActions}>
          <Button
            ariaLabel="Add task to new day"
            icon="addtask"
            label="Add Day"
            onClick={handleAddDay}
            data-cy="addTaskToNewDay"
          />
        </div>
      )}
      <Dialog isOpen={isOpen}>
        <DialogHeader title="Edit" icon="edittask" clearHandler={toggleEditMode} />
        <Suspense fallback={<Loading />}>
          <TaskForm task={tasks[currentTask]} closeCallback={() => setIsOpen(false)} location={location} />
        </Suspense>
      </Dialog>
      <PreviewTaskDialog
        isOpen={isPreviewOpen}
        taskId={isPreviewOpen ? currentTask : null}
        fileId={fileId}
        contactId={contacts?.[0]?.id || ''}
        handlePreview={togglePreviewMode}
        handleSubmit={handleSubmitFromPreview}
        handleTemplateChange={handleTemplateChange}
        showSaveTemplate={false}
        disabled={isLoading}
      />
    </Fragment>
  );
}
