import React from 'react';
import { useSelector } from 'react-redux';

import { ButtonGroup } from '../Button';
import { ContactMethodButton } from '../Button/ContactMethodButton';

import { checkEmailIntegrationSetup } from '../../utils';

import styles from './PrimaryContactMethod.css';

export const PrimaryContactMethods = props => {
  const agentEmailAccounts = useSelector(state => state.settings.email) || {};
  const hasEmailIntegrationSetup = checkEmailIntegrationSetup(agentEmailAccounts);
  const { contact } = props;
  const { emails, phones } = contact;
  if (!phones || !emails) {
    return null;
  }

  const mainPhones = phones.filter(phone => phone.enum === 0);
  const mobilePhone = mainPhones.filter(phone => phone.type === 3);
  const phoneForList = mobilePhone[0] || mainPhones[0];
  const cleanPhone = phoneForList ? { ...phoneForList, type: 'phone' } : null;

  const primaryEmail = emails.filter(email => email.enum === 1 && email.type === 0);
  const emailForList = primaryEmail[0] || emails[0];
  const cleanEmail = emailForList ? { ...emailForList, type: 'email' } : null;

  return (
    <ButtonGroup className={styles.buttonGroup}>
      {[cleanPhone, cleanEmail].map((contactMethod, i) => {
        if (!contactMethod) {
          return null;
        }

        return (
          <ContactMethodButton
            key={`primaryButton-${i}`}
            contact={contact}
            data={contactMethod}
            hasEmailIntegrationSetup={hasEmailIntegrationSetup}
          />
        );
      })}
    </ButtonGroup>
  );
};
