import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { refreshFollowup, skipFollowup } from '../../actions/contacts';
import { Button, ContactCard, Container, Icon, List, ListFooter, ListPlaceholder } from '../../components';
import { trackEvent } from '../../utils/analytics';
import classnames from 'classnames';

import styles from './FollowUpList.css';
import { checkIsFromDashboard } from '../../utils/urls';

export class FollowUpList extends Component {
  handleRefresh = e => {
    e.preventDefault();
    e.stopPropagation();

    const { refreshFollowup } = this.props;

    refreshFollowup();
    trackEvent('followup', 'refresh');
  };

  handleSkipFollowup = e => {
    e.preventDefault();
    e.stopPropagation();

    const { skipFollowup } = this.props;
    const { contactid } = e.target.dataset;

    skipFollowup(contactid);
    trackEvent('followup', 'skipFollowup');
  };

  handleFollowup = () => {
    trackEvent('followup', 'followup');
  };

  render() {
    const {
      contacts,
      isLoading = false,
      list,
      location,
      pageHandler,
      donePaging,
      variant = 'default',
      className
    } = this.props;

    const isCompact = variant !== 'default';

    const introClasses = classnames({
      [styles.intro]: !isCompact,
      [styles.introCompact]: isCompact,
      [className]: !!className
    });

    const listClasses = classnames({
      [styles.list]: !isCompact,
      [styles.listCompact]: isCompact
    });

    const listContainerClasses = classnames({
      [styles.listContainer]: !isCompact,
      [styles.compactListContainer]: isCompact
    });
    const isFromDashboard = checkIsFromDashboard(location.pathname);
    return (
      <Fragment>
        <Container className={listContainerClasses}>
          <div className={introClasses}>
            {!isCompact && (
              <div className={styles.iconWrap}>
                <Icon name="followup" size="l" />
              </div>
            )}
            {!isFromDashboard && (
              <>
                <p className={styles.p}>
                  Following up is a simple way to grow your business. We suggest you get in touch with these 5 contacts
                  today.
                </p>

                <Button
                  label="Refresh List"
                  ariaLabel="Refresh your follow-up list."
                  icon="refresh"
                  onClick={this.handleRefresh}
                  data-cy="refreshFollowUpButton"
                  size={isFromDashboard ? 'xxs' : 's'}
                />
              </>
            )}
          </div>

          {isLoading ? (
            <div className={styles.placeholderContainer}>
              <div className={styles.placeholder}>
                <ListPlaceholder rows={3} />
              </div>
            </div>
          ) : (
            <List noResultsIcon="contact" className={listClasses} isVirtuoso={false}>
              {list.map((id, i) => {
                if (i > 4) {
                  return null;
                }

                return (
                  <li key={id} className={styles.listItem} data-cy={`followUpCard-${i}`}>
                    <ContactCard
                      data={contacts[id]}
                      location={location}
                      className={styles.card}
                      handleFollowup={this.handleFollowup}
                      handleSkipFollowup={this.handleSkipFollowup}
                      variant="followup"
                    />
                  </li>
                );
              })}
            </List>
          )}
        </Container>
        {pageHandler && (
          <ListFooter
            isLoading={isLoading}
            donePaging={donePaging}
            buttonAriaLabel="Load more contacts."
            pagingHandler={pageHandler}
          />
        )}
        {isFromDashboard && (
          <div className={styles.dahsboardFooter}>
            <Button
              // styleType="inline"
              label="Refresh List"
              ariaLabel="Refresh your follow-up list."
              icon="refresh"
              onClick={this.handleRefresh}
              data-cy="refreshFollowUpButton"
              size={'s'}
              className={styles.inlineButton}
            />
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  isLoading: state.contacts.isLoading
});

FollowUpList.propTypes = {
  entity: PropTypes.object
};

const mapDispatchToProps = {
  refreshFollowup,
  skipFollowup
};

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpList);
