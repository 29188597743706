import React, { useState, useCallback, useEffect } from 'react';
import { bool, arrayOf, shape, string, number, func } from 'prop-types';
import classnames from 'classnames';
import Downshift from 'downshift';
import { FormTextInput, Icon, NoResultsItem } from '..';
import { CATEGORY_ITEMS, buildTestId, sortArrayOfObjects } from '../../utils';
import { isNewlyAdded } from '../../utils/dates';

import styles from './Lookup.css';
import dropdownStyles from '../Dropdown/Dropdown.css';
import searchStyles from '../Search/Search.css';
import templateStyles from '../Editor/Nodes/Template.css';
import { TagGroup, Tag } from '../Tag';
import { TASK_PLAN_CATEGORIES } from '../../utils/taskPlans';

const getLowerCaseValue = value => (value || '').toLowerCase();
const getItemLowerCaseValue = (item, key) => getLowerCaseValue(item?.[key]);
const getCategoryValue = item => {
  const isLeadAutoResponse = item?.autoPlanType != null && item?.categoryId === TASK_PLAN_CATEGORIES.contacts;
  return !isLeadAutoResponse ? CATEGORY_ITEMS[item?.categoryId]?.toLocaleLowerCase() || '' : 'Lead Response';
};

const getCleanList = (list, searchTerm) => {
  const listValue = Array.isArray(list) ? list : [];
  const searchTermValue = getLowerCaseValue(searchTerm);

  const filteredList = listValue.filter(item => {
    const nameValue = getItemLowerCaseValue(item, 'name');
    const categoryValue = getCategoryValue(item);

    return nameValue.includes(searchTermValue) || categoryValue.includes(searchTermValue);
  });

  return sortArrayOfObjects(filteredList, { sortKey: 'name', sortType: 'alpha' });
};

const itemToString = item => item?.name || '';

export const LookupPlan = props => {
  const {
    fieldIsValid = true,
    initialInputValue = '',
    list,
    onChange = null,
    onSelect,
    onRemove,
    textInputId = 'lookupTextField',
    testId = 'LookupPlan',
    required = false
  } = props;
  const [inputValue, setInputValue] = useState(initialInputValue || '');

  useEffect(() => {
    setInputValue(initialInputValue);
  }, [initialInputValue]);

  const handleSelect = useCallback(
    item => {
      // update state with the selected item
      setInputValue(itemToString(item));

      // call onSelect prop
      onSelect(item);
    },
    [onSelect]
  );

  const handleChange = e => {
    const { value: searchTerm } = e.target;

    if (searchTerm.length === 0 && onRemove) {
      onRemove();
    }

    if (onChange) {
      onChange(searchTerm);
    }

    // change the input value
    setInputValue(searchTerm);
  };

  const cleanList = getCleanList(list, inputValue);

  const noResultsFound = list.length === 0;
  const noCleanResultsFound = cleanList.length === 0;

  const menuClasses = classnames({
    [dropdownStyles.menu]: true,
    [dropdownStyles.lSize]: true,
    [dropdownStyles.menuNoResults]: noResultsFound
  });

  const classes = classnames({
    [styles.searchInput]: true,
    [dropdownStyles.invalid]: !fieldIsValid
  });

  return (
    <Downshift inputValue={inputValue} onSelect={handleSelect} itemToString={itemToString}>
      {({ getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex, openMenu }) => (
        <div className={searchStyles.field}>
          <FormTextInput
            data-testid={buildTestId(testId, 'searchInput')}
            id={textInputId}
            type="search"
            placeholder="Search plans by name or type..."
            size="l"
            className={classes}
            showInvalid={!fieldIsValid}
            required={required}
            {...getInputProps({ onChange: handleChange, onClick: openMenu })}
          />
          <Icon name="search" size="s" className={searchStyles.icon} />
          {isOpen ? (
            <ul {...getMenuProps()} className={menuClasses}>
              {noCleanResultsFound ? (
                <NoResultsItem />
              ) : (
                cleanList.map((item, index) => {
                  const classes = classnames({
                    [dropdownStyles.item]: true,
                    [dropdownStyles.hovered]: highlightedIndex === index,
                    [templateStyles.item]: true
                  });
                  const isNew = isNewlyAdded(item.createDate);

                  return (
                    <li
                      className={classes}
                      {...getItemProps({
                        key: itemToString(item),
                        index,
                        item
                      })}
                    >
                      {itemToString(item)}
                      <TagGroup>
                        {isNew && <Tag label="new" />}
                        <Tag label={getCategoryValue(item)} />
                      </TagGroup>
                    </li>
                  );
                })
              )}
            </ul>
          ) : null}
        </div>
      )}
    </Downshift>
  );
};

LookupPlan.propTypes = {
  fieldIsValid: bool,
  list: arrayOf(shape({ name: string.isRequired, categoryId: number.isRequired })).isRequired,
  initialInputValue: string,
  onChange: func,
  onRemove: func,
  onSelect: func.isRequired,
  textInputId: string,
  testId: string
};
