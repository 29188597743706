import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../';

const dataValidationCheck = data => {
  if (data.areaOfInt) {
    // Buyer prefs
    if (
      data.areaOfInt.length === 0 &&
      (data.maxPrice === '0.0000' || data.maxPrice === '') &&
      (data.minPrice === '0.0000' || data.minPrice === '') &&
      data.noBaths === '' &&
      data.noBeds === '' &&
      data.sqrFt === ''
    ) {
      return true;
    }
  }

  return false;
};

export const DataCell = props => {
  const { children, className, dataKey, handleNoDataClick, noDataPlaceholder, focusId } = props;

  if (!dataKey || dataKey.length === 0 || dataValidationCheck(dataKey)) {
    const label = `Add ${noDataPlaceholder}`;

    return (
      <Button
        ariaLabel={label}
        label={label}
        styleType="inline"
        onClick={handleNoDataClick}
        data-focusid={focusId}
        className={className}
      />
    );
  }

  return children;
};

DataCell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  dateKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])
};
