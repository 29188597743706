import classnames from 'classnames';
import React, { Fragment, useState } from 'react';

import { MlsDropdown } from '../Dropdown';
import { LookupByMLSNumberForm } from '../LookupTypeForm/LookupByMLSNumberForm';
import LookupTypeForm from '../LookupTypeForm/LookupTypeForm';
import PropertyTypeForm from '../PropertyTypeForm/PropertyTypeForm';
import { Form, FormFieldset, FormFooter, FormMessage } from '../../components';
import ErrorMLSCredentials from '../../pages/Settings/ErrorMlsCredentials';
import { getErrorMessage, hasMlsCredentials, hasOneMlsCredential } from '../../utils/mlsBoards';

import styles from './TransactionForm.css';

export const ImportForm = props => {
  const {
    formIsSubmitting,
    formRef,
    handleChangeMlsBoard,
    handleLookupSelect,
    hasListings,
    isMlsDataAccessApproved,
    isMlsValid,
    lookupIsValid,
    setLookupIsValid,
    mlsBoard,
    mlsCredentials,
    mlsSearchValue,
    setMlsSearchValue,
    mlsStates,
    setListingPreview,
    showNoTempListingsError
  } = props;

  const [inputType, setInputType] = useState('address');
  const hasMLSError = !isMlsValid || !isMlsDataAccessApproved;
  const hasCredentials = hasMlsCredentials(mlsCredentials);
  const hasOneCredential = hasOneMlsCredential(mlsCredentials);

  // If only one mls and is incorrect or no mls at all, CTA
  if ((hasOneCredential && hasMLSError) || !hasCredentials) {
    return (
      <Form id="propertyInsightsForm">
        <ErrorMLSCredentials
          hasCredentials={hasCredentials}
          hasOneCredential={hasOneCredential}
          isMlsDataAccessApproved={isMlsDataAccessApproved}
          isMlsValid={isMlsValid}
        />
      </Form>
    );
  }

  const lookupErrorMessage = getErrorMessage(showNoTempListingsError, mlsSearchValue, lookupIsValid, inputType);

  const formMessageClasses = classnames({
    [styles.formMessage]: true,
    [styles.formMessageMls]: inputType !== 'address'
  });

  return (
    <Fragment>
      <Form id="TransactionForm" ref={formRef}>
        <FormFieldset label="Import a listing from your MLS">
          <MlsDropdown handleChangeMlsBoard={handleChangeMlsBoard} mlsBoard={mlsBoard} />
          {hasMLSError ? (
            <ErrorMLSCredentials
              hasCredentials={hasCredentials}
              hasOneCredential={hasOneCredential}
              isMlsDataAccessApproved={isMlsDataAccessApproved}
              isMlsValid={isMlsValid}
            />
          ) : (
            <Fragment>
              <PropertyTypeForm
                formIsSubmitting={formIsSubmitting}
                inputType={inputType}
                setInputType={setInputType}
                showNoTempListingsError={showNoTempListingsError}
                lookupIsValid={lookupIsValid}
                setLookupIsValid={setLookupIsValid}
              />
              <FormMessage message={lookupErrorMessage} className={formMessageClasses} />
              <LookupTypeForm
                hasListings={hasListings}
                handleLookupSelect={handleLookupSelect}
                inputType={inputType}
                lookupIsValid={lookupIsValid}
                mlsBoard={mlsBoard}
                mlsSearchValue={mlsSearchValue}
                mlsStates={mlsStates}
                setListingPreview={setListingPreview}
                setLookupIsValid={setLookupIsValid}
                setMlsSearchValue={setMlsSearchValue}
              />
            </Fragment>
          )}
        </FormFieldset>
      </Form>
      {!hasListings && inputType !== 'address' && (
        // This needs to be a separate, non-nested form element so we can use Enter to submit.
        <LookupByMLSNumberForm
          lookupIsValid={lookupIsValid}
          mlsBoard={mlsBoard}
          mlsSearchValue={mlsSearchValue}
          setListingPreview={setListingPreview}
          setLookupIsValid={setLookupIsValid}
          setMlsSearchValue={setMlsSearchValue}
        />
      )}
      <FormFooter loading={formIsSubmitting}>&nbsp;</FormFooter>
    </Fragment>
  );
};
