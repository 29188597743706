/** @module */

import { MESSAGE_SET, MESSAGE_CLEAR } from '../reducers/message';

/**
 * Shows a message via <Toast />.
 * @param {Object} data - a data that for errors is a standard error object or a server generated exception object.
 */
export const showMessage = (data, useTimer = false) => {
  return dispatch => {
    dispatch({
      type: MESSAGE_SET,
      data,
      useTimer
    });
  };
};

/**
 * Closes the message <Toast />
 */
export const clearMessage = () => {
  return dispatch => {
    dispatch({
      type: MESSAGE_CLEAR
    });
  };
};
