import { array, bool, object, string } from 'prop-types';
import React, { Fragment } from 'react';
import { Container, List, ListPlaceholder } from '../';
import styles from '../../components/List/List.css';
import TransactionCard from '../Card/TransactionCard';
import { Loading } from '../Loading';

const TransactionsList = props => {
  const { isLoading, entities, currentList, noResultsMessage, isNested = false } = props;

  return (
    <Fragment>
      <Container className={styles.listContainer}>
        {isLoading ? (
          <Loading />
        ) : (
          <List isNested={isNested} noResultsMessage={noResultsMessage}>
            {currentList ? (
              currentList.map(id => {
                return (
                  <li key={id} className={styles.listItem}>
                    <TransactionCard data={entities[id]} location={location} isNested={isNested} />
                  </li>
                );
              })
            ) : (
              <ListPlaceholder rows={1} />
            )}
          </List>
        )}
      </Container>
    </Fragment>
  );
};

export default TransactionsList;

TransactionsList.propTypes = {
  isLoading: bool,
  entities: object,
  currentList: array,
  noResultsMessage: string,
  isNested: bool
};
