/** @module */

import { showMessage } from '../actions/message';
import { DEFAULT_PAGING_SIZE } from '../constants';
import { CONTACTS_UPDATE_CONTACT } from '../reducers/contacts';
import {
  NOTES_CURRENT_ENTITY,
  NOTES_CURRENT_GROUP,
  NOTES_DELETE,
  NOTES_DETAILS,
  NOTES_DONE_PAGING,
  NOTES_SET,
  NOTES_LOADING,
  NOTES_UPDATE
} from '../reducers/notes';
import { cleanStrOfSymbols, request, requestError, searchAndRemoveFromStorage } from '../utils';
import { ENTITY_TYPES } from '../utils/notes';
import { getLastTouchFromAction } from '../utils/contacts';

/**
 * Fetches a list of notes.
 * If success, dispatches the setting of the notes in the store, and optionally whether the list has reached the last page.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to fetch a list of notes
 * @param {Number} [options.dir=0] - the sort direction of the list of results 0 is forward, 1 is reverse
 * @param {String} [options.id] - the noteId of the last note used to fetch the next notes while paging.
 * @param {String} [options.key] - the key of the last note used to fetch the next notes while paging.
 * @param {Number} [options.page_size=25] - the number of notes to fetch with the request.
 * @param {Object} [options.searchRequest] - the query string to use when fetching notes. all is true for all notes. all is false for my notes.
 */
export const getNotes = options => {
  const {
    group,
    dir = 0,
    entity,
    forceRefresh = false,
    id,
    key,
    page_size = DEFAULT_PAGING_SIZE,
    setCurrentGroup = true,
    isSync = false
  } = options || {};
  const searchRequest = entity ? { searchTags: [entity] } : { all: group !== 'myNotes' };

  return async dispatch => {
    const options = {
      apiServiceType: 'note',
      forceRefresh,
      method: 'POST',
      path: 'list',
      payload: { dir, id, key, page_size, searchRequest }
    };

    try {
      dispatch({
        type: NOTES_LOADING,
        isLoading: true
      });

      const notesRequest = await request(options);

      if (!notesRequest) {
        return;
      }

      const { data } = notesRequest;

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (data.records && data.records.length < page_size) {
        // if the results set returned has less than the page size, we have reached the end of the list
        dispatch({
          type: NOTES_DONE_PAGING,
          group
        });
      }

      dispatch({
        type: NOTES_SET,
        data,
        group,
        isPaging: !!id, // we set isPaging to true if there is an id set in options
        isLoading: false,
        isSync,
        setCurrentGroup
      });
    } catch (error) {
      dispatch({
        type: NOTES_LOADING,
        isLoading: false
      });
      requestError(error, dispatch);
    }
  };
};

/**
 * Clean note action.
 * @param {String} id - the ID of a note.
 * @param {String} group - the name of a group of entities
 */
export const cleanNote = (id, group) => {
  return dispatch => {
    dispatch({
      type: NOTES_UPDATE,
      data: { id },
      updatedKeys: {
        is_removed: true
      }
    });
    dispatch({
      type: NOTES_DELETE,
      data: {
        id
      },
      group
    });
    dispatch(showMessage({ message: 'Note has been deleted.', type: 'error' }, true)); // useTimer set to true
  };
};

/**
 * Get the details of a note.
 * @param {Object} options - options to request the note details
 * @param {String} options.id - the id of the note to fetch.
 */
export const getNoteDetail = options => {
  const { id, group, forceRefresh = false } = options || {};

  return async dispatch => {
    const options = {
      apiServiceType: 'note',
      path: `details/${id}`,
      shouldBeCached: false,
      forceRefresh
    };

    try {
      const { data } = await request(options);

      const { exception } = data;

      if (exception) {
        if (exception.exceptionType === 'RecordNotFoundException') {
          dispatch(cleanNote(id, group));
          return;
        } else {
          throw exception;
        }
      }

      dispatch({
        type: NOTES_DETAILS,
        data
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Sets the current notes group in state.
 * @param {Object} options - a simple data object with the note id.
 * @param {String} options[id] - the note id.
 */
export const setNotesCurrentGroup = options => {
  const { group } = options || {};

  return dispatch => {
    dispatch({
      type: NOTES_CURRENT_GROUP,
      group
    });
  };
};

/**
 * Sets the current selected note in state.
 * @param {Object} options - a simple data object with the note id.
 * @param {String} options[id] - the note id.
 */
export const setCurrentNote = options => {
  const { id } = options || {};

  return dispatch => {
    dispatch({
      type: NOTES_CURRENT_ENTITY,
      data: {
        id
      }
    });
  };
};

/**
 * Save the note (add or edit).
 * If success, dispatches the saved notes to the notes records in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} data - data to add or edit note
 * @param {String} [data.date] - the date when the notes get created.
 * @param {String} [data.noteText] - the text of the notes which has max limit of 2800.
 * @param {Object} [data.tags] - the tags for the notes.
 * @param {Object} [data.untags] - the tags of the notes which has to be untagged.
 * @param {String} group - the group in state that the note should be added to.
 * @param {String} currentGroup - the current group of notes for main view the app is showing (this can be different than group).  Tasks have task notes and contact notes.
 */
export const saveNote = (data, group, currentGroup = 'myNotes') => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'note',
      method: 'POST',
      path: 'save',
      payload: data,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception, tags } = data;

      if (exception) {
        throw exception;
      }

      const setNoteDataInGroup = (data, group) => {
        dispatch({
          type: NOTES_SET,
          data: {
            /* When adding and editing a note, we manually assign the data returned to the records key to keep
              it consistent with the NOTES_SET reducer's expectations */
            records: [data]
          },
          group
        });
      };

      const isMyNotes = currentGroup === 'myNotes';

      if (isMyNotes) {
        // if the currentGroup isMyNotes, we push the note into that group in the reducer.
        setNoteDataInGroup(data, group);
      } else {
        // We remove the myNotes request from the cache to ensure we get latest next time we view it.
        searchAndRemoveFromStorage('notelist', 'searchRequestallfalse');
      }

      // if contacts or other entities are linked to the note, we make sure to purge localStorage for cached queries that include the id
      if (tags) {
        tags.forEach(key => {
          const { id, type } = key;

          if (!id) {
            return;
          }

          // if the currentGroup includes the id of a tag, we push the note into that group in the reducer.
          if (currentGroup?.includes(id)) {
            setNoteDataInGroup(data, group);
          }

          // If the tag is of type contact, we need to update the last touch in redux state.
          // This will eventually happen on the BE, but we need to be optimistic.
          if (type === ENTITY_TYPES['contact']) {
            dispatch({
              type: CONTACTS_UPDATE_CONTACT,
              contactId: id,
              updatedKeys: {
                lastTouchPoint: getLastTouchFromAction('note')
              }
            });
          }

          if (
            // If the tag ID doesn't match the current group we need to purge from local storage.
            currentGroup &&
            !currentGroup.includes(id)
          ) {
            const cleanKey = cleanStrOfSymbols(id); // we remove dashed since the cached request identifier set using getRequestIdentifier has no symbols
            searchAndRemoveFromStorage('notelist', cleanKey);
          }

          if (
            // If the current group IS a contact group and the tag is an insight tag, we need to purge from local storage.
            // This scenario happens when you have navigated to property insights inside a contact, but have not opened
            // the insight notes list, but you add a note to the an insight. (The contact notes are still the currentGroup in this case.)
            currentGroup &&
            currentGroup.startsWith('contact') &&
            type === ENTITY_TYPES['insight']
          ) {
            const contactId = currentGroup.split('::')[1];
            const cleanKey = cleanStrOfSymbols(contactId); // we remove dashed since the cached request identifier set using getRequestIdentifier has no symbols

            searchAndRemoveFromStorage('notelist', cleanKey);
          }

          if (currentGroup && currentGroup.startsWith('contact') && type === ENTITY_TYPES['task']) {
            // When adding notes in the nested contact tab in task, we have updated the note to reducer for the contact group but the note group for tasks is yet to be updated
            // We need to purge from local stroage so that the next fetch for task note list will go through
            searchAndRemoveFromStorage('notelist', id);
          }
        });
      }

      dispatch(showMessage({ message: 'Note saved successfully.', type: 'success' }, true)); // useTimer set to true

      return true;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * Delete note action.
 * @param {String} id - the ID of a note to delete.
 * @param {String} group - the name of a group of entities
 */
export const deleteNote = (id, group) => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'note',
      method: 'POST',
      path: `delete/${id}`,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: NOTES_DELETE,
        data: {
          id
        },
        group
      });
      dispatch(showMessage({ message: 'Note deleted successfully.', type: 'success' }, true)); // useTimer set to true
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const fetchNotes = (props, options) => {
  const { group, entity, shouldSetCurrentGroup } = props;

  return async dispatch => {
    // when fetching notes, an entity passed in will override the currentGroup
    return dispatch(getNotes({ group, entity, setCurrentGroup: shouldSetCurrentGroup, ...options })).then(() => {
      // make sure the currentGroup is up-to-date even when the request above is cached
      if (shouldSetCurrentGroup) {
        dispatch(setNotesCurrentGroup({ group }));
      }
    });
  };
};
