/** @module */

export const USER_PROFILE_SET_INFO = 'USER_PROFILE_SET_INFO';

export const initialState = {
  userInfo: null
};

/**
 * The user profile redux reducer.
 * @param {Object} state - the current state of the user profile store.
 * @param {Object} action - the action to take on the user profile store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.userInfo] - user profile info data to save to the store.
 * @param {Object} [action.isUpdated] - True if user info updated successfully else otherwise.
 */
export const userProfileReducer = (state = initialState, action = {}) => {
  const { type = 'default', userInfo } = action;

  switch (type) {
    case USER_PROFILE_SET_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...userInfo
        }
      };
    default:
      return state;
  }
};
