import React, { lazy, Suspense } from 'react';
import { Dialog } from '../Dialog';
import { DialogHeader } from '../DialogHeader';
const TaskForm = lazy(() => import('../../TaskForm/TaskForm'));
import { useLocation } from '@reach/router';
import { parseParamsStr, serializeToParamsStr } from '../../../utils';
import { Loading } from '../../Loading';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';

export const EditTaskDialog = () => {
  const location = useLocation();
  const { search, pathname } = location || {};
  const { mode, taskId } = parseParamsStr(search) || {};

  const { entities } = useSelector(store => store.tasks);

  const isOpen = mode === 'edit';

  const clearHandler = () => {
    const currentParams = parseParamsStr(search) || {};
    const { mode, taskId, ...newParams } = currentParams;

    const newParamsStr = serializeToParamsStr(newParams);
    const newPath = newParamsStr.length > 0 ? `${pathname}?${newParamsStr}` : pathname;
    navigate(newPath);
  };
  return (
    <Dialog isOpen={isOpen}>
      <DialogHeader title="Edit" icon="edittask" clearHandler={clearHandler} />
      <Suspense fallback={<Loading />}>
        <TaskForm task={entities[taskId]} closeCallback={clearHandler} location={location} />
      </Suspense>
    </Dialog>
  );
};
