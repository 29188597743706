import { string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../components';
import { RecentActivityCard } from '../../../components/Card/RecentActivityCard';
import styles from './RecentActivity.css';
import ListNoResults from '../../../components/List/ListNoResults';

export const RecentActivity = ({ userId }) => {
  const { groups, entities } = useSelector(store => store.contacts);

  const group = 'all::consumer_touch::1';

  const recentList = groups?.[group] || [];

  // Function to perform the scrolling
  const performScroll = direction => {
    const scrollable = document.querySelector(`.${styles.wrapper}`);
    const containerWidth = scrollable.offsetWidth;
    const cardWidth = document.querySelector(`.${styles.itemCard}`).offsetWidth;
    const offsetWidth = Math.floor(containerWidth / cardWidth) * cardWidth;
    const scrollAmount = offsetWidth * (direction === 'left' ? -1 : 1);
    const duration = 500;

    scrollable.scroll({
      left: scrollable.scrollLeft + scrollAmount,
      behavior: 'smooth',
      duration: duration
    });
  };

  const scrollDiv = direction => {
    // Check if the DOM is already loaded
    if (document.readyState === 'loading') {
      // If DOM is still loading, add an event listener
      document.addEventListener('DOMContentLoaded', performScroll);
    } else {
      // If the DOM is already loaded, execute the scrolling immediately
      performScroll(direction);
    }
  };

  const list = recentList?.slice(0, 5) || [];

  const validEntities = list.reduce((acc, key) => {
    if (entities[key]?.consumerLastTouchPoint) {
      return [...acc, entities[key]];
    }
    return acc;
  }, []);

  const hasResult = validEntities.length > 0;

  return (
    <>
      <div className={styles.wrapper}>
        {hasResult ? (
          <div className={styles.recentActivities}>
            {validEntities.map(entity => {
              return (
                <div className={styles.itemCard} key={entity.id}>
                  <RecentActivityCard key={entity.id} data={entity} />
                </div>
              );
            })}
          </div>
        ) : (
          <ListNoResults isNested={false} noResultsIcon={'contact'} noResultsMessage={'No Recent Activities.'} />
        )}
      </div>
      <div className={styles.footer}>
        <a href={`/contacts?q=all&dir=1&sortBy=consumer_touch&assignedTo=${userId}`}>See All Recent Activities</a>

        {hasResult && (
          <div>
            <Button ariaLabel="left" icon="arrowleft" styleType="transparent" onClick={() => scrollDiv('left')} />
            <Button ariaLabel="right" icon="arrowright" styleType="transparent" onClick={() => scrollDiv('right')} />
          </div>
        )}
      </div>
    </>
  );
};

RecentActivity.propTypes = {
  className: string,
  userId: string
};
