import React from 'react';
import { shape, func, string, number, bool } from 'prop-types';

import { TASK_PLANS_CATEGORY_ICONS, TASK_PLANS_CATEGORY_LABELS } from '../../constants/taskPlans';
import { TASK_PLAN_CATEGORIES } from '../../utils/taskPlans';
import { Button, Icon } from '../';
import { Card } from './Card';

import cardStyles from './Card.css';
import styles from './TaskPlanCard.css';
import { buildTestId } from '../../utils';
import { PLANS_URI } from '../../constants';

export const TaskPlanCard = props => {
  const { data, deleteLabelPrefix = 'Delete plan', deleteIcon = 'delete', onDelete, testId = 'TaskPlanCard' } = props;

  if (!data?.name) {
    return null;
  }

  const { id, name, description, categoryId, autoPlanType } = data || {};

  const url = `${PLANS_URI}/${id}`;
  const isLeadResponse = TASK_PLAN_CATEGORIES.contacts && autoPlanType != null;

  const categoryLabel = isLeadResponse
    ? TASK_PLANS_CATEGORY_LABELS.leadResponse
    : TASK_PLANS_CATEGORY_LABELS[categoryId] || TASK_PLANS_CATEGORY_LABELS[TASK_PLAN_CATEGORIES.all];

  const iconName = isLeadResponse ? TASK_PLANS_CATEGORY_ICONS.leadResponse : TASK_PLANS_CATEGORY_ICONS[categoryId];

  const handleDelete = e => {
    e.preventDefault();
    onDelete(id);
  };

  return (
    <Card url={url} className={styles.card}>
      <div className={styles.grid}>
        <div className={styles.gridAreaMain}>
          <Icon name={iconName} size="s" className={styles.icon} title={categoryLabel} />
          <div className={cardStyles.titleContainer}>
            <div className={styles.cardTitle} title={name}>
              <span className={cardStyles.title}>{name}</span>
            </div>
          </div>
        </div>
        <div className={styles.gridAreaDesc}>{description}</div>
        <div className={styles.gridAreaActions}>
          <Button
            data-testid={buildTestId(testId, 'Button-delete')}
            ariaLabel={`${deleteLabelPrefix}.`}
            tooltipPos="left"
            icon={deleteIcon}
            onClick={handleDelete}
          />
        </div>
      </div>
    </Card>
  );
};

TaskPlanCard.propTypes = {
  data: shape({
    id: string,
    name: string,
    description: string,
    categoryId: number
  }),
  deleteIcon: string,
  deleteLabelPrefix: string,
  onDelete: func.isRequired,
  testId: string,
  showLeadAutoResponse: bool
};
