import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './FormTextInput.css';

export const FormTextInput = React.forwardRef((props, ref) => {
  const { className, disabled, id, inputMode, size = 'm', showInvalid, type = 'text', ...attrs } = props;

  const classes = classnames({
    [styles.input]: !className,
    [styles[`${size}Size`]]: true,
    [styles.invalid]: !!showInvalid,
    [className]: !!className
  });

  return (
    <input ref={ref} id={id} type={type} inputMode={inputMode} className={classes} disabled={disabled} {...attrs} />
  );
});

FormTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  inputMode: PropTypes.oneOf(['text', 'tel', 'url', 'email', 'numeric', 'decimal', 'search']),
  showInvalid: PropTypes.bool,
  size: PropTypes.oneOf(['xxxs', 'xxs', 'xs', 's', 'm', 'l', 'xl']),
  type: PropTypes.oneOf(['text', 'password', 'search', 'email', 'tel', 'number', 'url'])
};
