import React from 'react';
import { array, bool, string } from 'prop-types';

import { Icon } from '../Icon';
import { Loading } from '../Loading';
import { Tag, TagGroup } from '../Tag';

import insightCardStyles from './PropertyInsightCard.css';

export const PropertyCardImage = props => {
  const { address, hasPropertyData, tags, url } = props;

  const isLoading = !url && !hasPropertyData;

  return (
    <div className={insightCardStyles.imageWrapper}>
      {url ? (
        <img src={url} className={insightCardStyles.image} alt={`property image for ${address}`} loading="lazy" />
      ) : (
        <div className={insightCardStyles.placeholderIconWrap}>
          <Icon name="house" size="l" />
        </div>
      )}
      <TagGroup className={insightCardStyles.tagGroup}>
        {tags.map((tag, i) => {
          return <Tag key={`${tag}-${i}`} label={tag} className={insightCardStyles.photoTag} />;
        })}
      </TagGroup>
      <Loading loading={isLoading} />
    </div>
  );
};

PropertyCardImage.propTypes = {
  address: string,
  hasPropertyData: bool,
  tags: array,
  url: string
};
