import { TASK_PLAN_CATEGORIES } from '../utils/taskPlans';

export const PLANS_URI = '/marketing/task-plan-manager';
export const PLAN_TEMPLATES_URI = 'task-plan-templates';

export const TASK_PLANS_LIST_COLUMN_DATA = [
  { id: 'name', label: 'Plan' },
  { id: 'description', label: 'Description' },
  { id: 'actions', label: '' }
];

export const TASK_PLANS_CATEGORY_LABELS = {
  [TASK_PLAN_CATEGORIES.all]: 'All',
  [TASK_PLAN_CATEGORIES.contacts]: 'Contact',
  [TASK_PLAN_CATEGORIES.transactions]: 'Transaction',
  leadResponse: 'Lead Response'
};

export const TASK_PLANS_CATEGORY_ICONS = {
  [TASK_PLAN_CATEGORIES.contacts]: 'contact',
  [TASK_PLAN_CATEGORIES.transactions]: 'transaction',
  leadResponse: 'lightning'
};

export const STOP_PLAN_OPTIONS = {
  NOT_IN_PLAN: 0,
  AUTO_STOP: 1,
  MANUAL_STOP: 2
};
