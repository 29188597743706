import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DialogHeader } from '../../components';

import { MassEmailForm } from '../MassEmailForm/MassEmailForm';

import styles from './MassEmailModal.css';
import { Dialog } from '../Dialog';
import { useLocation } from '@reach/router';
import { EDITOR_TEMPLATE_CATEGORIES } from '../Editor/editor-templates';
import { checkEmailIntegrationSetup, interpolate, sortCollection } from '../../utils';
import { getParsedTemplateBody, isSupportedTemplateObjectType } from '../../utils/templates';
import { getEmailTemplates, getTemplateById } from '../../actions/templates';
import { cleanAndDeserializeForSlate, DEFAULT_EDITOR_CONTENT } from '../Editor/editor-utils';
import { MessageBanner } from '../MessageBanner';
import { getRelationships } from '../../actions/relationships';
import { getContactDetail } from '../../actions';
import { interpolateAddressData } from '../../utils/data';

const PATH_ACTION_FRAGMENTS = ['add', 'edit', 'view'];
const MassEmailModal = props => {
  const { listOfSelected, isOpen, clearHandler } = props;
  const location = useLocation();
  const dispatch = useDispatch();

  const { entities } = useSelector(store => store.templates);
  const { entities: contactsEntities } = useSelector(store => store.contacts);
  const agentEmailAccounts = useSelector(store => store.settings.email);
  const user = useSelector(state => state.user);
  const userInfo = useSelector(state => state.userProfile.userInfo);
  const { groups: relationshipsGroups, entities: relationshipsEntities } = useSelector(store => store.relationships);

  const hasEmailIntegrationSetup = checkEmailIntegrationSetup(agentEmailAccounts);

  const [templateStore, setTemplateStore] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [defaultBody, setDefaultBody] = useState(null);

  const paths = location.pathname.split('/');
  const pathActionFragment = PATH_ACTION_FRAGMENTS.includes(paths[3]) ? paths[3] : null;
  const lastPathFragment = paths[paths.length - 1];

  const id = !!pathActionFragment && !PATH_ACTION_FRAGMENTS.includes(lastPathFragment) ? lastPathFragment : null;

  const { templateCategories } = templateStore;

  const currentTemplateEntity = entities[selectedTemplate];
  const { body: entityBody } = currentTemplateEntity || {};
  const contactData = contactsEntities[listOfSelected[0]];
  // We use data from the first selected id for preview
  const contactId = contactData?.id;

  const updateDefaultBody = htmlStr => {
    const editorContactRelationship = relationshipsGroups?.[contactData?.id]?.map(id => relationshipsEntities?.[id]);
    const { contactFormatted, agentFormatted, relationshipFormatted } = interpolateAddressData(
      contactData,
      userInfo,
      editorContactRelationship
    );
    const content = interpolate(
      htmlStr,
      { ...contactData },
      { ...user, ...userInfo },
      editorContactRelationship,
      contactFormatted,
      agentFormatted,
      relationshipFormatted
    );

    setDefaultBody(cleanAndDeserializeForSlate(content));
  };

  useEffect(() => {
    const templateCategories = EDITOR_TEMPLATE_CATEGORIES.reduce((acc, category) => {
      const { id, value } = category;
      acc[id] = { label: value, templates: [] };
      return acc;
    }, {});

    const templatesAndMergeCodes = { ...entities };
    const sortedTemplateIds = sortCollection(Object.keys(templatesAndMergeCodes), templatesAndMergeCodes, {
      sortKey: 'description'
    });

    const filteredTemplates = sortedTemplateIds.reduce((acc, id) => {
      const template = templatesAndMergeCodes[id];
      const { objectType } = template;
      if (isSupportedTemplateObjectType(objectType)) {
        acc[id] = template;
      }

      return acc;
    }, {});

    for (const id of Object.keys(filteredTemplates)) {
      const template = filteredTemplates[id];
      const { file, category } = template || {};
      const { category: fileCategory } = file || {};
      const categoryForSort = category || fileCategory;
      const sortCategory = categoryForSort && categoryForSort !== '' ? categoryForSort : 'other'; // We fallback to the Other category in case a category isn't set.

      if (templateCategories[sortCategory]) {
        templateCategories[sortCategory].templates.push({ ...template });
      }
    }

    setTemplateStore({ templates: filteredTemplates, templateCategories });
  }, [entities]);

  useEffect(() => {
    dispatch(getEmailTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (contactId) {
      dispatch(getContactDetail({ id: contactId }));
      dispatch(getRelationships({ contactId }));
    }
  }, [dispatch, contactId]);
  useEffect(() => {
    if (id) {
      if (!entityBody) {
        dispatch(getTemplateById(id, { objectType: 1 })).then(data => {
          const { body: entityBody } = data || {};
          const { body } = getParsedTemplateBody(entityBody);
          updateDefaultBody(body);
        });
      } else {
        const { body } = getParsedTemplateBody(entityBody);
        updateDefaultBody(body);
      }
      return;
    }
    updateDefaultBody(DEFAULT_EDITOR_CONTENT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, entityBody]);

  const showMassEmailForm = templateCategories?.[Object.keys(templateCategories)?.[0]]?.templates[0]?.fileId;

  return (
    <Dialog isOpen={isOpen} className={styles.dialog}>
      <DialogHeader title="Group email " icon="email" iconSize="m" clearHandler={clearHandler} />

      {!hasEmailIntegrationSetup ? (
        <MessageBanner
          icon="error"
          isVisible={!hasEmailIntegrationSetup}
          message="Setup email integration to start using email templates in your communication."
        >
          <Button
            ariaLabel="Setup Email Integration"
            icon="emailsetup"
            label="Email Integration"
            styleType="transparent"
            url="/settings/email-integration"
          />
        </MessageBanner>
      ) : (
        showMassEmailForm && (
          <MassEmailForm
            listOfSelected={listOfSelected}
            id={id}
            templateStore={templateStore}
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            defaultBody={defaultBody}
            clearHandler={clearHandler}
          />
        )
      )}
    </Dialog>
  );
};

MassEmailModal.propTypes = {
  className: string
};

export default MassEmailModal;
