import { nanoid } from 'nanoid';

import { sanitizer } from './dom';
import { generateCleanParamsStr, parseParamsStr, validateUrl } from './strings';

export const NYLAS_AUTH_KEY = 'stateId';

/**
 * Gets a random ID to use as a state param to protect against a CSRF attack.
 * @param {Number} lgth=30 The length of the rqndom ID.
 * @returns {String} A rqndom string to be used as an ID.
 */
export const getRandomStateId = (lgth = 30) => {
  return nanoid(lgth);
};

/**
 * Clean the Nylas auth url and replace the state param with a version created for local state comparison.
 * @param {String} url Nylas auth url from backend service.
 * @param {String} stateId The stateId just set in local storage.
 */
export const cleanAndSetAuthUrl = (url, stateId) => {
  if (!url || !validateUrl(url) || !stateId) {
    return null;
  }

  // We sanitize and create a URL object from the passed in url.
  const urlObj = new URL(sanitizer(url));
  // We get the params and set a new state param to the generated stateId.
  const params = { ...parseParamsStr(urlObj.search), state: stateId };
  // We get a new search string from the params.
  const searchStr = generateCleanParamsStr(params);
  urlObj.search = searchStr;

  // We convert the URL to a string and return for use.
  return urlObj.toString();
};

/**
 * Compares a URL's state param value to the local storage stateId.
 * @param {String} stateParam The URL's state param value.
 * @param {String} stateId The stateId value from local storage.
 * @returns {Boolean} Whether it is a match.
 */
export const isStateIdSetAndMatchesParam = (stateParam, stateId) => {
  // We need to explicitly check for null and undefined to be safe and obvious that we are checking these as values.
  return stateId !== undefined && stateId !== null && stateId === stateParam;
};

/**
 * Takes either v3 or v2 account and return accountId and email formated.
 * @param {*} account
 * @returns
 */
export const getNylasIdAndEmail = account => {
  const { email, Email, accountId, NylasAccountId } = account || {};
  if (!account) {
    return null;
  }
  return {
    email: email || Email,
    accountId: accountId || NylasAccountId
  };
};
