import React from 'react';
import { arrayOf, node, oneOf, oneOfType } from 'prop-types';
import classnames from 'classnames';

import { Container } from '../Container';
import { List } from './List';
import { ListPlaceholder } from './ListPlaceholder';

import styles from '../../components/List/List.css';

export const TemplateList = props => {
  const { children, variant = 'default' } = props;

  const classes = classnames({
    [styles.listContainer]: variant === 'simple',
    [styles.borderedList]: variant === 'default'
  });

  return (
    <Container className={classes}>
      <List noResultsMessage="No templates found.">{children ? children : <ListPlaceholder rows={4} />}</List>
    </Container>
  );
};

TemplateList.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  variant: oneOf(['default', 'simple'])
};
