import { string } from 'prop-types';
import React, { Suspense, useEffect, useState } from 'react';
import { Loading, Tab, TabContainer, TabGroup } from '../../components';
import { View } from '../../components/View';
import { ViewBody } from '../../components/ViewBody';
import { ViewHeader } from '../../components/ViewHeader';
import { useLocation } from '@reach/router';
import { PLAN_TEMPLATES_URI } from '../../constants/taskPlans';
import { buildTestId } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getSmartzipSsoUrl, getSmartzipStatus } from '../../actions/smartzip';

const DEFAULT_TAB = 'reports';

const TAB = {
  SOCIAL_CONNECT: {
    id: 'social-connect',
    label: 'Social Connect',
    icon: 'facebook'
  },
  MARKET_SNAPSHOT: { id: 'market-snapshots', label: 'Market Snapshots', icon: 'snapshot' },
  LISTING_ALERT: {
    id: 'listing-alerts',
    label: 'Listing Alerts',
    icon: 'housegeo'
  },
  HOME_INTEL: {
    id: 'home-intelle',
    label: 'HomeIntelle',
    icon: 'homeintel'
  },
  TEMPLATE: {
    id: 'template-library',
    label: 'Template Library',
    icon: 'template'
  },
  TASK_PLAN_MANAGER: {
    id: 'task-plan-manager',
    label: 'Task Plan Manager',
    icon: 'plan'
  },
  LEAD_RESPONSE_RULE: {
    id: 'lead-response-rules',
    label: 'Lead Response Rules',
    icon: 'lightning'
  },
  SMART_TARGETING: {
    id: 'smart-targeting',
    label: 'Smart Targeting',
    icon: 'smartzip'
  }
};

/**
 * Snapshots for Market Snapshot reports
 * @param {Object} props
 */
const Marketing = ({ children }) => {
  const dispatch = useDispatch();

  const { userId } = useSelector(store => store.user);
  const { isLoading: isLoadingSmartzip, smartzipStatus, smartzipSsoUrl } = useSelector(store => store.smartzip);
  const { userInfo } = useSelector(store => store.userProfile);
  const { isUSAgent } = userInfo || {};
  const { smart_targeting_customer } = smartzipStatus || {};

  const [selectedTab, setSelectedTab] = useState(DEFAULT_TAB);
  const location = useLocation();

  const mountedTab = location.pathname.split('/')[2];

  useEffect(() => {
    // We check, just so we have some of the basics in place.
    dispatch(getSmartzipStatus()).then(response => {
      if (response?.smart_targeting_customer) {
        dispatch(getSmartzipSsoUrl());
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userId]);

  useEffect(() => {
    if (
      [
        TAB.LISTING_ALERT.id,
        TAB.TEMPLATE.id,
        TAB.HOME_INTEL.id,
        TAB.TASK_PLAN_MANAGER.id,
        TAB.LEAD_RESPONSE_RULE.id,
        PLAN_TEMPLATES_URI,
        TAB.SMART_TARGETING.id,
        TAB.SOCIAL_CONNECT.id
      ].includes(mountedTab)
    ) {
      setSelectedTab(mountedTab);
      return;
    }

    // Default
    setSelectedTab(TAB.MARKET_SNAPSHOT.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, mountedTab]);

  const handleTabClick = (e, tabId) => {
    if (tabId === TAB.SMART_TARGETING.id && smart_targeting_customer) {
      e.preventDefault();

      const ssoWindow = window.open();
      dispatch(getSmartzipSsoUrl()).then(response => {
        ssoWindow.location = response.SmartzipSsoUrl;
      });
    }
  };

  const checkSelected = tabId => {
    if (mountedTab === PLAN_TEMPLATES_URI && tabId === 'task-plan-manager') {
      return true;
    }
    return selectedTab === tabId;
  };

  const getTabUrlOverride = shouldOpenSmartTargeting => {
    if (shouldOpenSmartTargeting) {
      return smartzipSsoUrl || null;
    }
    return null;
  };

  return (
    <Suspense fallback={<Loading />}>
      <View>
        <ViewHeader title="Marketing" titleAs="h1" titleIcon="marketing" />
        <TabContainer>
          <TabGroup>
            {Object.keys(TAB).map(key => {
              if (key === TAB.SMART_TARGETING.id && isLoadingSmartzip) {
                return <Loading key="loading" />;
              }

              if (TAB[key].id === TAB.HOME_INTEL.id && !isUSAgent) {
                return <Loading key={key} />;
              }

              const { smart_targeting_customer } = smartzipStatus || {};
              const shouldOpenSmartTargeting = TAB[key].id === TAB.SMART_TARGETING.id && smart_targeting_customer;

              const tabUrlOverride = getTabUrlOverride(shouldOpenSmartTargeting);

              return (
                <Tab
                  id={TAB[key].id}
                  isSelected={checkSelected(TAB[key].id)}
                  key={key}
                  url={tabUrlOverride || TAB[key].url || TAB[key].id}
                  label={TAB[key].label}
                  icon={TAB[key].icon}
                  onClick={e => handleTabClick(e, TAB[key].id)}
                  data-testid={buildTestId(TAB[key].id, 'Marketing-Tab')}
                  data-cy={`marketingTab${TAB[key].icon}`}
                />
              );
            })}
          </TabGroup>
        </TabContainer>
        <ViewBody>{children}</ViewBody>
      </View>
    </Suspense>
  );
};

/**
 * Snapshots for Market Snapshot reports
 * @param {Object} props
 */
Marketing.propTypes = {
  className: string
};

export default Marketing;
