/** @module */
import { getEmailAccountDetails } from '../actions/email';
import {
  SETTINGS_EMAIL_ACCOUNTS,
  SETTINGS_EMAIL_ACCOUNT_DELETE,
  SETTINGS_EMAIL_SIGNATURE_SAVE
} from '../reducers/settings';
import { request, requestError } from '../utils';
import { sanitizer } from '../utils/dom';
const EMAIL_INTEGRATION_URL = '/settings/email-integration';
/**
 * Get email accounts from Nylas.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 */
export const getEmailAccounts = options => {
  const { forceRefresh = true, nylasSyncType = 'email' } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      forceRefresh,
      shouldBeCached: false,
      method: 'GET',
      path: 'getAccountBySyncTypeAsync',
      params: {
        nylasSyncType
      },
      cacheThreshold: 60000
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({ type: SETTINGS_EMAIL_ACCOUNTS, email: data });

      if (data?.length > 0) {
        data.forEach(account => {
          const { NylasAccountId: accountId } = account || {};
          if (accountId) {
            dispatch(
              getEmailAccountDetails({
                accountId,
                forceRefresh: true,
                shouldBeCached: false
              })
            );
          }
        });
      }
    } catch (error) {
      // Sync not set up
      if (error?.response?.status === 404) {
        return;
      }
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Deletes an email accounts from TP DB & Nylas.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 */
export const deleteEmailAccount = options => {
  const { Version, accountId, nylas3SyncType = 'email' } = options || {};

  if (!accountId) {
    return;
  }

  return async dispatch => {
    const requestOptions =
      Version === 3
        ? {
            apiServiceType: 'nylasV3',
            baseUrlKey: 'api',
            method: 'DELETE',
            path: `deleteAccount`,
            params: {
              grantId: accountId,
              nylas3SyncType
            },
            shouldBeCached: false
          }
        : {
            apiServiceType: 'nylas',
            method: 'POST',
            path: `accounts/${accountId}/delete`,
            shouldBeCached: false
          };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      const status = data?.status || data?.Status;
      if (exception || status !== 'success') {
        throw exception;
      }

      dispatch({ type: SETTINGS_EMAIL_ACCOUNT_DELETE, emailAccountId: accountId });

      return data;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Get Nylas Auth URL.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 * @param {Object} host - Host of the application for call back.
 */
export const getNylasAuthUrl = options => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      method: 'POST',
      path: 'getAuthorizeUrl',
      payload: {
        ...options,
        callbackUrl: `${window.location.origin}${EMAIL_INTEGRATION_URL}`
      },
      shouldBeCached: false,
      timeout: 50000
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      const sanitizedUrl = sanitizer(data.url);
      const url = new URL(sanitizedUrl).toString();

      return url;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Authenticate with Nylas.
 * @param {String} code - the url parameter returned by the email provider must be sent to Nylas.
 */
export const authenticateUsingNylas = (code, email) => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      method: 'POST',
      path: 'authorizeAccount',
      params: {
        code,
        emailAddress: email,
        nylas3SyncType: 'email',
        callbackUrl: `${window.location.origin}${EMAIL_INTEGRATION_URL}`
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception, status } = data;

      if (exception || status === 'error') {
        const errorToThrow = exception ? exception : { message: 'Issue authenticating with Nylas email service.' };
        throw errorToThrow;
      }

      dispatch(getEmailAccounts({ forceRefresh: true }));
      return data;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

export const saveV2Signature = preferences => {
  return async dispatch => {
    const options = {
      apiServiceType: 'userProfile',
      method: 'POST',
      path: 'users/current/settings',
      payload: { feature: 'v2_emailsignature', value: JSON.stringify(preferences) },
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: SETTINGS_EMAIL_SIGNATURE_SAVE,
        data: preferences
      });

      return true;
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

export const getV2Signature = () => {
  return async dispatch => {
    const options = {
      apiServiceType: 'userProfile',
      method: 'GET',
      params: { feature: 'v2_emailsignature' },
      path: 'users/current/settings',
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;
      if (exception) {
        throw exception;
      }

      if (!data.value) {
        return;
      }

      const parsedData = JSON.parse(data.value);

      dispatch({
        type: SETTINGS_EMAIL_SIGNATURE_SAVE,
        data: parsedData
      });

      return parsedData;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

export const checkIfAccountExistInNylasVersion2 = email => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      forceRefresh: true,
      shouldBeCached: false,
      method: 'GET',
      path: 'checkIfAccountExistInNylasVersion2',
      params: {
        email
      }
    };

    try {
      const { data } = await request(requestOptions);
      return data;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

export const deleteAccountForEmail = email => {
  if (!email) {
    return;
  }

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      method: 'DELETE',
      path: `deleteAccountForEmail`,
      params: {
        email
      },
      shouldBeCached: false
    };

    try {
      const { status } = await request(requestOptions);
      if (status === 200) {
        return Promise.resolve;
      }
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};
