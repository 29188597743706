/** @module */

import { getMergedEntities, getMergedSetOfIds } from '../utils';

export const CIRRUS_PROPERTIES_GET_START = 'CIRRUS_PROPERTIES_GET_START';
export const CIRRUS_PROPERTIES_GET_SUCCCESS = 'CIRRUS_PROPERTIES_GET_SUCCCESS';
export const CIRRUS_PROPERTIES_GET_ERROR = 'CIRRUS_PROPERTIES_GET_ERROR';

export const initialState = {
  currentGroup: '',
  entities: {},
  groups: {},
  doneLoading: {},
  isLoading: false
};

export const cirrusPropertiesReducer = (state = initialState, action) => {
  const { type = 'default', payload = {} } = action || {};
  switch (type) {
    case CIRRUS_PROPERTIES_GET_START: {
      const { groupName } = payload;
      return {
        ...state,
        currentGroup: groupName,
        doneLoading: {
          ...state.doneLoading,
          [groupName]: false
        },
        isLoading: true
      };
    }
    case CIRRUS_PROPERTIES_GET_SUCCCESS: {
      const { data, groupName } = payload;
      const { autocomplete } = data;

      return {
        ...state,
        entities: getMergedEntities(state.entities, autocomplete, { ignorePreexisting: true, idKey: 'mls_number' }),
        groups: {
          ...state.groups,
          [groupName]: getMergedSetOfIds(state.groups[groupName], autocomplete, { idKey: 'mls_number' })
        },
        doneLoading: {
          ...state.doneLoading,
          [groupName]: true
        },
        isLoading: false
      };
    }
    case CIRRUS_PROPERTIES_GET_ERROR: {
      return { ...state, isLoading: false };
    }
    default:
      return initialState;
  }
};
