import classnames from 'classnames';
import { format } from 'date-fns';
import addMinutes from 'date-fns/addMinutes';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import parseISO from 'date-fns/parseISO';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormLabel, FormRow } from '../';
import { clearMessage, showMessage } from '../../actions/message';
import { DATE_FORMATS } from '../../constants';
import { getTimeFromDate, parseISODate } from '../../utils/dates';
import formFieldStyles from '../FormField/FormField.css';
import { FormTimeInput } from '../FormTimeInput';

const INTERVALS = [
  { value: '15', label: '15 mins', minutes: 15 },
  { value: '30', label: '30 mins', minutes: 30 },
  { value: '45', label: '45 mins', minutes: 45 },
  { value: '60', label: '1 hr', minutes: 60 },
  { value: '90', label: '1.5 hrs', minutes: 90 },
  { value: '120', label: '2 hrs', minutes: 120 }
  //{ value: 'allDay', label: 'All Day', minutes: 1440 }
];

const SEC_TO_MINUTE = 60000;
const DEFAULT_DURATION = 30;

class FormTimeRangeInput extends Component {
  constructor(props) {
    super(props);

    const dateStart = this.getDateFromTime('10:00', parseISODate(props.date, DATE_FORMATS.ISO_DATETIME_CALENDAR));

    const {
      startTime = dateStart,
      endTime,
      duration = differenceInMinutes(parseISODate(endTime), parseISODate(startTime)) || DEFAULT_DURATION
    } = this.props;
    const parsedStartTime = parseISODate(startTime);
    const parsedEndTime = parseISODate(endTime);

    this.state = {
      startTime: parsedStartTime,
      endTime: parsedEndTime ? parsedEndTime : this.getAddedTime(duration, parsedStartTime),
      duration: duration
    };
  }

  getAvailableDuration = minutes => {
    const duration = INTERVALS.find(item => {
      return item.minutes === minutes || (item.minutes + 7 > minutes && item.minutes - 7 < minutes);
    });

    return duration || INTERVALS[0];
  };

  getAvailableMinutes = (startTime, endTime) => {
    const minutes = differenceInMinutes(endTime, startTime);
    return this.getAvailableDuration(minutes);
  };

  handleOnChange = (state, options) => {
    const { setFlag = true } = options || {};
    const { onChange } = this.props;
    const { startTime, endTime } = state;

    if (onChange) {
      onChange(startTime, endTime, { setFlag });
    }
  };

  getDateFromTime = (time, dateTime) => {
    const date = new Date(parseISODate(dateTime, DATE_FORMATS.ISO_DATETIME));

    if (time) {
      const splitTime = time.split(':');
      date.setHours(splitTime[0]);
      date.setMinutes(splitTime[1]);
      date.setSeconds(0);
    } else {
      date.setHours(0, 0, 0, 0);
    }

    return date;
  };

  getAddedTime = (duration, time) => {
    return parseISODate(new Date(time).getTime() + duration * SEC_TO_MINUTE, DATE_FORMATS.ISO_DATETIME);
  };

  handleOnStartTimeChange = time => {
    const formattedTime = this.getDateFromTime(time, this.state.startTime);
    const stateSnapshot = { ...this.state, startTime: formattedTime };

    this.setState({ startTime: formattedTime });
    this.handleOnChange(stateSnapshot);
  };

  handleOnEndTimeChange = time => {
    const formattedTime = this.getDateFromTime(time, this.state.endTime);
    const stateSnapshot = { ...this.state, endTime: formattedTime };

    this.setState({ endTime: formattedTime });
    this.handleOnChange(stateSnapshot);
  };

  validateEndTime = () => {
    const { showMessage } = this.props;
    const { startTime, endTime } = this.state;

    if (differenceInMinutes(parseISODate(endTime), parseISODate(startTime)) < 1) {
      const newEndTime = format(addMinutes(new Date(startTime), 1), DATE_FORMATS.ISO_TIME);

      this.handleOnEndTimeChange(newEndTime);

      showMessage({ message: 'The end time was before the start time, so we adjusted it.', type: 'error' }, true);
    }
  };

  componentDidMount() {
    this.handleOnChange(this.state, { setFlag: false });
  }

  componentDidUpdate(prevProps) {
    const { startTime: stateStartTime, endTime: stateEndTime } = this.state;
    const { date } = this.props;
    const { date: prevDate } = prevProps;

    if (prevDate !== date) {
      const startClockTime = getTimeFromDate(stateStartTime);
      const endClockTime = getTimeFromDate(stateEndTime);

      const newStartTime = this.getDateFromTime(startClockTime, parseISO(date, DATE_FORMATS.ISO_DATETIME));
      const newEndTime = this.getDateFromTime(endClockTime, parseISO(date, DATE_FORMATS.ISO_DATETIME));

      this.setState({ ...this.state, startTime: newStartTime, endTime: newEndTime });
    }
  }

  render() {
    const { className } = this.props;
    const { startTime, endTime } = this.state;

    const classes = classnames({
      [className]: !!className
    });

    return (
      <Fragment>
        <FormRow>
          <div className={formFieldStyles.field}>
            <FormLabel htmlFor="wrapupDate">Start Time</FormLabel>
            <FormTimeInput
              handleChange={this.handleOnStartTimeChange}
              handleValidation={this.validateEndTime}
              time={startTime}
              className={classes}
              id="startTime"
            />
          </div>
          <div
            className={classnames({
              [formFieldStyles.field]: true,
              [formFieldStyles.timeRangeSecondField]: true
            })}
          >
            <FormLabel htmlFor="wrapupDate">End Time</FormLabel>
            <FormTimeInput
              handleChange={this.handleOnEndTimeChange}
              handleValidation={this.validateEndTime}
              time={endTime}
              localegi="en-US"
              id="endTime"
            />
          </div>
        </FormRow>
      </Fragment>
    );
  }
}

FormTimeRangeInput.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  duration: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  clearMessage,
  showMessage
};

export default connect(null, mapDispatchToProps)(FormTimeRangeInput);
