import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';

import { getUserInfo } from '../../actions/userProfile';
import { getSalesforceStatus } from '../../actions/salesforce';
import { Avatar, Button, ButtonGroup, Container, DataCell, Document, Icon, LinkList } from '../../components';
import { MediaQueryContext } from '../../components/MediaQueryProvider/MediaQueryProvider';
import { View } from '../../components/View';
import { ViewHeader } from '../../components/ViewHeader';
import { CommList } from '../Contacts/ContactDetail/CommList';
import { SETTINGS_NAV } from '../../data/nav-links';
import { TIMEZONE_LIST } from '../../data/timezones';
import { getCityStateStr } from '../../utils/data';

import styles from './Settings.css';
import documentStyles from '../../components/Document/Document.css';
import contactDetailStyles from '../Contacts/ContactDetail/ContactDetail.css';
import socialBioStyles from '../Contacts/ContactDetail/SocialBio.css';

export class Settings extends Component {
  static contextType = MediaQueryContext;

  componentDidMount() {
    const { getUserInfo } = this.props;

    getUserInfo();
  }

  handleNoDataClick = () => {
    navigate('/settings/my-information');
  };

  checkSettingsNav = () => {
    // We want to check to ensure all settings nav items should be shown for the user.
    const { user, userInfo } = this.props;

    return SETTINGS_NAV.reduce((acc, link) => {
      const { url } = link;

      if (
        !userInfo ||
        !user ||
        (!userInfo.isTextingAllowed && url.includes('texting')) || // hide texting based on criteria set by BE
        (userInfo.allowNylasCalendarSync && url.includes('google-sync')) || // if the user has CALENDAR sync enabled, hide Google calendar sync.
        (!userInfo.allowNylasCalendarSync && url.includes('calendar-sync')) || // if the user does not have CALENDAR sync enabled, hide it.
        (!userInfo.allowNylasContactSync && url.includes('contact-sync')) || // if the user does not have CONTACT sync enabled, hide it.
        (user.isAssistant && url.includes('lead-providers')) || // if the user is an assistant, hide lead providers
        (!userInfo.hasHomeIntelLicense && url.includes('homeintelle-settings')) // if the user does not have a HomeIntelle license, hide HomeIntelle settings
      ) {
        // Do not return the texting link for non-USA agents
        return acc;
      }

      acc.push(link);

      return acc;
    }, []);
  };

  getPhoneCommsList = comms => {
    const { businessPhone, homePhone, mobilePhone, otherPhone } = comms;
    const commsList = [];

    if (homePhone) {
      commsList.push({
        tag: 'Home',
        value: homePhone
      });
    }

    if (mobilePhone) {
      commsList.push({
        tag: 'Mobile',
        value: mobilePhone
      });
    }

    if (businessPhone) {
      commsList.push({
        tag: 'Business',
        value: businessPhone
      });
    }

    if (otherPhone) {
      commsList.push({
        tag: 'Other',
        value: otherPhone
      });
    }

    return commsList;
  };

  getEmailCommsList = comms => {
    const { agentEmail, companyEmail } = comms;
    const commsList = [];

    if (agentEmail) {
      commsList.push({
        description: 'Agent',
        value: agentEmail
      });
    }

    if (companyEmail) {
      commsList.push({
        description: 'Company',
        value: companyEmail
      });
    }

    return commsList;
  };

  getWebsiteCommsList = comms => {
    const { agentWebSite, companyWebsite } = comms;
    const commsList = [];

    if (agentWebSite) {
      commsList.push({
        tag: 'Agent',
        value: agentWebSite
      });
    }

    if (companyWebsite) {
      commsList.push({
        tag: 'Company',
        value: companyWebsite
      });
    }

    return commsList;
  };

  handleGetSalesforceStatus = () => {
    const { getSalesforceStatus } = this.props;

    getSalesforceStatus(window.location);
  };

  render() {
    const { isTabletLandscapeAndUp } = this.context;
    const { children, location, userInfo } = this.props;
    const isSubpage = location.pathname.startsWith('/settings/');

    const {
      agentAddress,
      agentEmail,
      agentName,
      agentPhotoUrl,
      agentWebSite, // ToDo:  get this uppercase S changed to lower.
      companyEmail,
      companyLogoUrl,
      companyName,
      companyWebsite,
      disclaimer,
      license,
      businessPhone,
      homePhone,
      mobilePhone,
      otherPhone,
      socialMedia,
      teamMotto,
      timeZoneId
    } = userInfo || {};

    const { addressLine } = agentAddress || {};
    const cityStateStr = getCityStateStr(agentAddress);

    const phonesCommsList = this.getPhoneCommsList({ homePhone, mobilePhone, businessPhone, otherPhone });
    const hasPhone = businessPhone || homePhone || mobilePhone;

    const emailCommsList = this.getEmailCommsList({ agentEmail, companyEmail });
    const hasEmail = agentEmail || companyEmail;

    const websiteCommsList = this.getWebsiteCommsList({ agentWebSite, companyWebsite });
    const hasWebsite = agentWebSite || companyWebsite;

    const socialMediaList = socialMedia || {};

    const hasSocial = Object.keys(socialMediaList).reduce((acc, key) => {
      if (socialMediaList[key] !== '') {
        acc = 'true';
      }

      return acc;
    }, null);

    const hasAgentPhoto = agentPhotoUrl !== '';
    const hasCompanyLogo = companyLogoUrl !== '';
    const showImageWrap = hasAgentPhoto || hasCompanyLogo;

    // ToDo: Make this method reusable
    const selectedTimeZoneItem = TIMEZONE_LIST.find(item => {
      return timeZoneId === item.id;
    });
    const selectedTimeZone = selectedTimeZoneItem ? selectedTimeZoneItem.value.replace('&amp;', '&') : null;

    const settingsNav = this.checkSettingsNav();

    const { hasSalesforceSSO } = userInfo || {};
    const { isLoading } = this.props.salesforce || {};

    return (
      <View>
        <ViewHeader title="Settings" titleAs="h1" titleIcon="settings" helpKey="settings" />
        <Container className={documentStyles.container}>
          <div className={documentStyles.sidebar}>
            <div className={documentStyles.navContainer}>
              <LinkList
                data={settingsNav}
                orientation={isTabletLandscapeAndUp ? 'vertical' : 'horizontal'}
                type="subNav"
                className={styles.subNav}
              />
            </div>
          </div>
          {isSubpage ? (
            children
          ) : (
            <Document>
              <section className={styles.settingsCard}>
                <div className={styles.sectionWrap}>
                  {showImageWrap && (
                    <div className={styles.imageWrap}>
                      {hasAgentPhoto && <Avatar pictureURL={agentPhotoUrl} size="xl" />}
                      {hasCompanyLogo && <img src={companyLogoUrl} className={styles.logo} />}
                    </div>
                  )}
                  <h3 className={styles.cardH3}>My Information</h3>
                  <div className={styles.cardSummary}>
                    <label className={contactDetailStyles.summaryGridLabel}>
                      <Icon name="myinfo" size="s" /> Name
                    </label>
                    <div className={contactDetailStyles.summaryGridValue}>{agentName}</div>

                    <label className={contactDetailStyles.summaryGridLabel}>
                      <Icon name="house" size="s" /> Address
                    </label>
                    <div className={contactDetailStyles.summaryGridValue}>
                      <DataCell
                        dataKey={addressLine}
                        noDataPlaceholder="address"
                        handleNoDataClick={this.handleNoDataClick}
                        focusId="addressLine"
                      >
                        <Fragment>
                          {addressLine}
                          {cityStateStr.length > 0 && <br />}
                          {cityStateStr}
                        </Fragment>
                      </DataCell>
                    </div>

                    <label className={contactDetailStyles.summaryGridLabel}>
                      <Icon name="call" size="s" /> Phone
                    </label>
                    <div className={contactDetailStyles.summaryCommValue}>
                      <DataCell dataKey={hasPhone} noDataPlaceholder="phone" handleNoDataClick={this.handleNoDataClick}>
                        {hasPhone && <CommList data={phonesCommsList} commMethodType="phone" defaultCommId={false} />}
                      </DataCell>
                    </div>

                    <label className={contactDetailStyles.summaryGridLabel}>
                      <Icon name="email" size="s" /> Email
                    </label>
                    <div className={contactDetailStyles.summaryCommValue}>
                      <DataCell dataKey={hasEmail} noDataPlaceholder="email" handleNoDataClick={this.handleNoDataClick}>
                        {hasEmail && <CommList data={emailCommsList} commMethodType="email" defaultCommId={false} />}
                      </DataCell>
                    </div>

                    <label className={contactDetailStyles.summaryGridLabel}>
                      <Icon name="website" size="s" /> Website
                    </label>
                    <div className={contactDetailStyles.summaryCommValue}>
                      <DataCell
                        dataKey={hasWebsite}
                        noDataPlaceholder="website"
                        handleNoDataClick={this.handleNoDataClick}
                      >
                        {hasWebsite && (
                          <CommList data={websiteCommsList} commMethodType="website" defaultCommId={false} />
                        )}
                      </DataCell>
                    </div>

                    <label className={contactDetailStyles.summaryGridLabel}>
                      <Icon name="link" size="s" /> Social links
                    </label>
                    <div className={contactDetailStyles.summaryCommValue}>
                      <DataCell
                        dataKey={hasSocial}
                        noDataPlaceholder="social media links"
                        handleNoDataClick={this.handleNoDataClick}
                      >
                        <ButtonGroup className={socialBioStyles.buttonGroup}>
                          {socialMedia &&
                            Object.keys(socialMedia).map(key => {
                              const url = socialMedia[key];

                              if (!url || url === '') {
                                return null;
                              }

                              return (
                                <Button
                                  key={key}
                                  icon={key}
                                  url={url}
                                  ariaLabel={`Visit ${key}.`}
                                  size="l"
                                  externalLink={true}
                                  className={socialBioStyles.button}
                                />
                              );
                            })}
                        </ButtonGroup>
                      </DataCell>
                    </div>
                  </div>

                  <h3 className={styles.cardH3}>Professional Info</h3>
                  <div className={styles.cardSummary}>
                    <label className={contactDetailStyles.summaryGridLabel}>
                      <Icon name="office" size="s" /> Company
                    </label>
                    <div className={contactDetailStyles.summaryGridValue}>
                      <DataCell
                        dataKey={companyName}
                        noDataPlaceholder="company"
                        handleNoDataClick={this.handleNoDataClick}
                      >
                        {companyName}
                      </DataCell>
                    </div>

                    <label className={contactDetailStyles.summaryGridLabel}>
                      <Icon name="announcement" size="s" /> Team/motto
                    </label>
                    <div className={contactDetailStyles.summaryGridValue}>
                      <DataCell
                        dataKey={teamMotto}
                        noDataPlaceholder="team name/motto"
                        handleNoDataClick={this.handleNoDataClick}
                      >
                        {teamMotto}
                      </DataCell>
                    </div>

                    <label className={contactDetailStyles.summaryGridLabel}>
                      <Icon name="license" size="s" /> License
                    </label>
                    <div className={contactDetailStyles.summaryGridValue}>
                      <DataCell
                        dataKey={license}
                        noDataPlaceholder="license"
                        handleNoDataClick={this.handleNoDataClick}
                      >
                        {license}
                      </DataCell>
                    </div>

                    <label className={contactDetailStyles.summaryGridLabel}>
                      <Icon name="notes" size="xs" /> Disclaimer
                    </label>
                    <div className={contactDetailStyles.summaryGridValue}>
                      <DataCell
                        dataKey={disclaimer}
                        noDataPlaceholder="disclaimer"
                        handleNoDataClick={this.handleNoDataClick}
                      >
                        <p className={styles.p}>{disclaimer}</p>
                      </DataCell>
                    </div>
                  </div>

                  <h3 className={styles.cardH3}>Location</h3>
                  <div className={styles.cardSummary}>
                    <label className={contactDetailStyles.summaryGridLabel}>
                      <Icon name="timezone" size="s" /> Time zone
                    </label>
                    <div className={contactDetailStyles.summaryGridValue}>
                      <DataCell
                        dataKey={timeZoneId}
                        noDataPlaceholder="time zone"
                        handleNoDataClick={this.handleNoDataClick}
                      >
                        {selectedTimeZone}
                      </DataCell>
                    </div>
                  </div>

                  {hasSalesforceSSO && (
                    <>
                      <h3 className={styles.cardH3}>Others</h3>
                      <div className={styles.cardSummary}>
                        <label className={contactDetailStyles.summaryGridLabel}>
                          <Icon name="settings" size="s" /> Account
                        </label>
                        <div className={contactDetailStyles.summaryGridValue}>
                          <DataCell
                            dataKey={timeZoneId}
                            noDataPlaceholder="time zone"
                            handleNoDataClick={this.handleNoDataClick}
                          >
                            <ButtonGroup className={`${styles.accountManagement} ${socialBioStyles.buttonGroup}`}>
                              <Button
                                label="Billing & Account Info"
                                icon="billing"
                                ariaLabel="Billing"
                                size="xxs"
                                styleType="white"
                                isLoading={isLoading}
                                onClick={this.handleGetSalesforceStatus}
                              />
                              {/* This is a future feature
                            <Button
                              label="Knowledge Base"
                              icon="help"
                              ariaLabel="Knowledge Base"
                              size="xxs"
                              styleType="white"
                            /> */}
                            </ButtonGroup>
                          </DataCell>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <footer className={styles.footer}>
                  <ButtonGroup className={styles.buttonGroup}>
                    <Button
                      url="my-information"
                      label="Edit My Info"
                      ariaLabel="Edit my information."
                      icon="edit"
                      size="xs"
                      styleType="white"
                    />
                  </ButtonGroup>
                </footer>
              </section>
            </Document>
          )}
        </Container>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  salesforce: state.salesforce,
  user: state.user,
  userInfo: state.userProfile.userInfo
});

const mapDispatchToProps = {
  getUserInfo,
  getSalesforceStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
