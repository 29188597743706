/** @module */

import {
  DELETE_AGENT,
  TEAM_SET,
  LICENSE_SET,
  SET_DISPLAY_SAVE_ACCOUNT_MODAL,
  SET_DISPLAY_DELETE_ACCOUNT_MODAL,
  SET_AGENT_TO_DELETE,
  SET_AGENT_TO_UPDATE
} from '../reducers/team';
import { request, requestError } from '../utils';

/**
 * Toggles the display state of the TeamManagement Create/Edit Agent  Modal
 * @param {bool} isShowing - sets the display state of the modal
 */

export const toggleDisplaySaveAccountModal = isShowing => {
  return dispatch => {
    dispatch({
      type: SET_DISPLAY_SAVE_ACCOUNT_MODAL,
      displaySaveAccountModal: isShowing
    });
  };
};

/**
 * Toggles the display state of the TeamManagement Delete Agent  Modal
 * @param {bool} isShowing - sets the display state of the modal
 */

export const toggleDisplayDeleteAgentModal = isShowing => {
  return dispatch => {
    dispatch({
      type: SET_DISPLAY_DELETE_ACCOUNT_MODAL,
      displayDeleteAccountModal: isShowing
    });
  };
};

/**
 * Fetches a list of Team members in a partnership account
 * If success, dispatches the members.
 * If fails, shows error message via <Toast />.
 */
export const getTeamAgents = () => {
  return async dispatch => {
    const options = {
      apiServiceType: 'contactOld',
      path: 'picklist/assignedTo'
    };

    try {
      const { data } = await request(options);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: TEAM_SET,
        group: 'agents', // Includes ONLY agents
        data: data.assignedTo || []
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Fetches a list of Team members with associated agents in Team Management page.
 * If success, dispatches the setting of the team in the store.
 * If fails, shows error message via <Toast />.
 */
export const getAgentAccounts = (displayError = false) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      forceRefresh: true,
      path: 'accounts/agents'
    };

    try {
      const { data } = await request(options);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: TEAM_SET,
        group: 'agents', // Includes ONLY agents
        data
      });
    } catch (error) {
      if (displayError) {
        requestError(error, dispatch);
      }
    }
  };
};

/**
 * Fetches a list of Team members to use in the assigned to list.
 * If success, dispatches the setting of the team in the store.
 * If fails, shows error message via <Toast />.
 */

export const getTeam = () => {
  return async dispatch => {
    const options = {
      apiServiceType: 'taskLegacy',
      path: 'get_assigned_to_list'
    };

    try {
      const { data } = await request(options);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: TEAM_SET,
        group: 'all', // Includes assistants and agents
        data
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Adds agent to agents list in Team  Management page.
 * If success, dispatches the getAgentAccounts call and closes the modal to update the team members list.
 * If fails, shows error message via <Toast />.
 */
export const addAgent = ({
  loginName,
  password,
  firstName,
  lastName,
  emailAddress,
  workPhone,
  homePhone,
  accessRights
}) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'POST',
      path: 'accounts/agents',
      payload: {
        loginName,
        password,
        firstName,
        lastName,
        emailAddress,
        workPhone,
        homePhone,
        accessRights
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch(toggleDisplaySaveAccountModal(false));
      dispatch({
        type: TEAM_SET,
        group: 'agents', // Includes ONLY agents
        data: [data]
      });
    } catch (error) {
      const { response } = error;
      const { data } = response || {};
      requestError(error, dispatch);
      return { errorMessage: data?.Message };
    }
  };
};

/**
 * Edits information of a specific agent in the team member list.
 * If success, dispatches the getAgentAccounts call and closes the modal to update the team members list.
 * If fails, shows error message via <Toast />.
 */
export const updateAgent = (
  id,
  { loginName, firstName, lastName, emailAddress, workPhone, homePhone, userStatus, accessRights }
) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'PUT',
      path: `accounts/agents/${id}`,
      payload: {
        id,
        loginName,
        firstName,
        lastName,
        emailAddress,
        workPhone,
        homePhone,
        userStatus,
        accessRights
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);

      const { exception } = data;

      if (exception) {
        throw exception;
      }
      dispatch(toggleDisplaySaveAccountModal(false));
      dispatch({
        type: TEAM_SET,
        group: 'agents', // Includes ONLY agents
        data: [data]
      });
    } catch (error) {
      const { response } = error;
      const { data } = response || {};
      requestError(error, dispatch);
      return { errorMessage: data?.Message };
    }
  };
};

/**
 * Fetches license data for use in the Team Management page .
 * If success, dispatches the license  data in the store.
 * If fails, shows error message via <Toast />.
 */
export const setSelectedAgentToUpdate = selectedAgentToUpdate => {
  return dispatch => {
    dispatch({
      type: SET_AGENT_TO_UPDATE,
      selectedAgentToUpdate
    });
  };
};

/**
 * Fetches license data for use in the Team Management page .
 * If success, dispatches the license  data in the store.
 * If fails, shows error message via <Toast />.
 */
export const setSelectedAgentToDelete = selectedAgentToDelete => {
  return dispatch => {
    dispatch({
      type: SET_AGENT_TO_DELETE,
      selectedAgentToDelete
    });
  };
};

/**
 * Fetches license data for use in the Team Management page .
 * If success, dispatches the license  data in the store.
 * If fails, shows error message via <Toast />.
 */
export const getAgentLicenseCount = () => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      forceRefresh: true,
      path: 'accounts/agents/licensecount'
    };

    try {
      const { data } = await request(options);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: LICENSE_SET,
        licenses: { agent: data }
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Deletes agent from team member list.
 * If success, dispatches the getAgentAccounts call and closes the modal to update the team members list.
 * If fails, shows error message via <Toast />.
 */
export const deleteAgent = (idToDelete, reassignToUserId) => {
  return async dispatch => {
    const options = {
      baseUrlKey: 'api',
      method: 'DELETE',
      path: `accounts/agents/${idToDelete}`,
      payload: {
        reassignToUserId
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);

      const { exception } = data;

      if (exception) {
        throw exception;
      }
      dispatch(toggleDisplayDeleteAgentModal(false));
      dispatch({
        type: DELETE_AGENT,
        agentId: idToDelete
      });
      const displayAgentAccountErrorMessage = true;
      dispatch(getAgentAccounts(displayAgentAccountErrorMessage));
      dispatch(getAgentLicenseCount());
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};
