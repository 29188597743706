import React, { useState } from 'react';
import { bool, object, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';

import { FormDateInput } from '../../components';
import { parseParamsStr, serializeToParamsStr } from '../../utils';
import { validateDateRange } from '../../utils/transactions';
import { setTransactionLastSearchedDate } from '../../actions/transactions';

import formFieldStyles from '../../components/FormField/FormField.css';

export const CustomDateRangeForm = props => {
  const { startDateInputRef, startDate, endDate, location, isClosings = false } = props;

  const { pathname, search } = location;

  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(true);

  const lastSearchedDates = useSelector(store => store.transactions.lastSearchedDates);

  const handleDateChange = ({ date, type }) => {
    // Validation on date range and redirect when valid
    const currentParams = parseParamsStr(search) || {};
    const newParams = {
      ...currentParams,
      startDate,
      endDate,
      [type]: date
    };

    const rangeIsValid = validateDateRange(startDateInputRef, newParams.startDate, newParams.endDate, dispatch);
    setIsValid(rangeIsValid);

    if (rangeIsValid) {
      const newParamsStr = serializeToParamsStr(newParams);
      const paramsStr = newParamsStr === '' ? newParamsStr : `?${newParamsStr}`;
      const newUrl = `${pathname}${paramsStr}`;

      dispatch(
        setTransactionLastSearchedDate({
          ...lastSearchedDates,
          [isClosings ? 'closings' : 'listings']: { dateFrom: newParams.startDate, dateTo: newParams.endDate }
        })
      );

      navigate(newUrl);
    }
  };

  return (
    <div className={formFieldStyles.dateRange}>
      <FormDateInput
        id="startDate"
        timestamp={startDate}
        placeholder="Start Date"
        inputRef={startDateInputRef}
        showInvalid={!isValid}
        onDateChange={date => handleDateChange({ date, type: 'startDate' })}
      />
      <span>to</span>
      <FormDateInput
        id="endDate"
        timestamp={endDate}
        placeholder="End Date"
        onDateChange={date => handleDateChange({ date, type: 'endDate' })}
      />
    </div>
  );
};

CustomDateRangeForm.propTypes = {
  endDate: string,
  location: object.isRequired,
  startDate: string,
  startDateInputRef: object.isRequired,
  isClosings: bool
};
