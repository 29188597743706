import {
  AI_CLOSE_DIALOG,
  AI_OPEN_DIALOG,
  AI_PREFERENCES_SET,
  AI_SET_CONTENT,
  AI_UPDATE_MESSAGE
} from '../reducers/aiAuthor';
import { request, requestError } from '../utils';
import { v4 as uuidv4 } from 'uuid';

export const getResponse = ({ messages, user, conversationId = uuidv4(), type }, setIsLoading) => {
  if (setIsLoading) {
    setIsLoading(true);
  }

  return async dispatch => {
    const requestOptions = {
      baseUrlKey: 'api',
      method: 'POST',
      path: 'aiauthor/getResponse',
      shouldBeCached: false,
      payload: {
        messages,
        user,
        conversationId,
        type: type === 'texting' ? 'text' : type
      }
    };

    try {
      const response = await request(requestOptions);
      const { data } = response;

      if (setIsLoading) {
        setIsLoading(false);
      }

      return { response: data, conversationId };
    } catch (error) {
      const { response } = error;
      const { status } = response || {};

      if (status !== 500) {
        requestError(error, dispatch);
      }
      return Promise.reject();
    }
  };
};

export const updateMessages = (messages, type, conversationId) => {
  return async dispatch => {
    dispatch({
      type: AI_UPDATE_MESSAGE,
      messages,
      chatType: type,
      conversationId
    });
  };
};

export const openAIDialog = () => {
  return dispatch => {
    dispatch({
      type: AI_OPEN_DIALOG
    });
  };
};

export const closeAIDialog = () => {
  return dispatch => {
    dispatch({
      type: AI_CLOSE_DIALOG
    });
  };
};

/**
 * Set the "currentContent" in AI Author's reducer
 * @param {string} data
 */
export const setAIContent = data => {
  return dispatch => {
    dispatch({
      type: AI_SET_CONTENT,
      data
    });
  };
};

/**
 * Gets the user's preferences from the preference API.
 * If success, the preferences are stored in Redux.
 * If fails, shows error message via <Toast />.
 */
export const getAIAuthorPreferences = () => {
  return async dispatch => {
    const options = {
      apiServiceType: 'userProfile',
      method: 'GET',
      params: { feature: 'aiAuthor' },
      path: 'users/current/settings',
      shouldBeCached: false
    };

    try {
      const { data } = await request(options);
      const { exception } = data;
      if (exception) {
        throw exception;
      }

      if (!data.value) {
        return;
      }

      const { IsBetaUser } = JSON.parse(data.value) || {};
      dispatch({
        type: AI_PREFERENCES_SET,
        data: IsBetaUser
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};
