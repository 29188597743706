import isAfter from 'date-fns/isAfter';

import { openPopup } from './';
import { EMAIL_SPACER_STR } from '../components/Editor/editor-utils';
import { UNICODE } from '../constants';
import { getEmailDateForAttribution } from './dates';

const { ZERO_WIDTH_SPACE } = UNICODE;
const PARTICIPANTS_MAX_LENGTH = 20;

export const UNSUBSCRIBE_ALERT_DEFAULTS = {
  icon: 'unsubscribe',
  iconIsColored: true,
  iconSize: 'm',
  message: 'Attention: This contact has unsubscribed from all communication. Are you sure you want to proceed?',
  primaryButtonLabel: 'Proceed'
};

/**
 * URI schemes object keyed by communication type.
 */
export const URI_SCHEMES_BY_COMM_TYPE = {
  phone: 'tel',
  email: 'mailto'
};

/**
 * Gets the display label of a communication object based on the communication type.
 * @param {Object} commMethod - the communication method object.
 * @param {String} commMethodType - the communication type to match against.
 */
export const getCommunicationLabel = (commMethod, commMethodType) => {
  const { tag, description } = commMethod;
  const defaultLabel = 'Other';
  const specialCommMethodType = ['email', 'website'];
  // Descriptions or tags that need to use `Primary` as label
  const usePrimaryDescription = ['Email', 'Website'];

  if (specialCommMethodType.includes(commMethodType)) {
    // Primary website in 8i doesn't have description. It has tag filled instead
    if (usePrimaryDescription.includes(description) || usePrimaryDescription.includes(tag)) {
      return 'Primary';
    }

    return description || defaultLabel;
  }

  return tag || defaultLabel;
};

/**
 * Triggers the communication action in the browser based on the communication method type.
 * @param {String} href - the link to trigger.
 * @param {String} commMethodType - the communication type to match against.
 */
export const triggerCommunication = (href, commMethodType) => {
  if (commMethodType === 'email') {
    openPopup(href, { windowWidth: 750, windowHeight: 500, position: 'center' });
  } else {
    window.location = href;
  }
};

/**
 * Triggers a workflow based on the communication method type and the contact's unsubscribe status.
 * @param {Object} options - options to perform the unsubscribe check.
 * @param {Boolean} [options.isUnsubscribed] - The contact's unsubscribe status.
 * @param {String} [options.commMethodType] - the communication type to match against.
 * @param {Function} [options.showAlert] - the redux action that shows the AlertDialog.
 * @param {Function} [options.clearAlert] - the redux action that clears the AlertDialog.
 * @param {Function} [options.callBack] - a callback function to call on triggering the the comm method.
 * @param {Object} e - the event object that triggers checkUnsubscribe.
 */
export const checkUnsubscribe = (options, e) => {
  const { href, isUnsubscribed, commMethodType, showAlert, clearAlert, callBack, dispatch, isUnsubscribedCallback } =
    options || {};
  const { dataset } = (e && e.currentTarget) || {};
  const communicationCallback = () => {
    if (e) {
      // sometimes checkUnsubscribe doesn't come via an event but rather another function.
      e.preventDefault();
      e.stopPropagation();
    }

    if (href) {
      triggerCommunication(href, commMethodType);
    }

    if (callBack) {
      callBack({ href, commMethodType, dataset });
    }
  };

  // we don't check unsubscribe for phones.
  // therefore we just let the event default happen.
  if (commMethodType === 'phone' || !isUnsubscribed) {
    communicationCallback();
    return;
  }

  if (e) {
    e.preventDefault();
  }

  if (dispatch) {
    dispatch(
      showAlert({
        ...UNSUBSCRIBE_ALERT_DEFAULTS,
        primaryButtonHandler: () => {
          dispatch(clearAlert());
          communicationCallback();
        },
        secondaryButtonHandler: () => {
          dispatch(clearAlert());
          isUnsubscribedCallback();
        }
      })
    );
    return;
  }
  showAlert({
    ...UNSUBSCRIBE_ALERT_DEFAULTS,
    primaryButtonHandler: () => {
      clearAlert();
      communicationCallback();
    },
    secondaryButtonHandler: clearAlert
  });
};

/**
 * Triggers a workflow based on the communication method type and the contact's unsubscribe status.
 * @param {Object} options - options to perform the unsubscribe check.
 * @param {Boolean} [options.isUnsubscribed] - The contact's unsubscribe status.
 * @param {Function} [options.showAlert] - the redux action that shows the AlertDialog.
 * @param {Function} [options.clearAlert] - the redux action that clears the AlertDialog.
 * @param {Function} [options.primaryCallBack] - a callback function to call when clicking the primary button.
 * @param {Function} [options.secondaryCallBack] - a callback function to call when clicking the secondary button.
 */
export const checkUnsubscribeEmail = options => {
  const {
    isUnsubscribed,
    showAlert,
    clearAlert,
    clearDrawer,
    primaryCallBack = () => {},
    secondaryCallBack = () => {}
  } = options;

  if (!isUnsubscribed) {
    return;
  }

  showAlert({
    ...UNSUBSCRIBE_ALERT_DEFAULTS,
    primaryButtonHandler: () => {
      clearAlert();
      primaryCallBack();
    },
    secondaryButtonHandler: () => {
      clearAlert();
      clearDrawer();
      secondaryCallBack();
    }
  });
};

/**
 * Check to see if the newly selected recipient is in the recipients list already.
 * @param {Array} recipients - the current recipients list.
 * @param {Object} newNewRecipient - The new recipient selected.
 */
export const checkAlreadyRecipient = (recipients, newNewRecipient) => {
  return recipients.find(entity => {
    return newNewRecipient.email === entity.email;
  });
};

/**
 * Check to see if the user has any email accounts set up with Nylas.
 * @param {Object} agentEmailAccounts - the redux store key from settings.
 */
export const checkEmailIntegrationSetup = agentEmailAccounts => {
  const { group } = agentEmailAccounts;
  const hasAccounts = group.length > 0;

  if (!hasAccounts) {
    return false;
  }

  return true;
};

/**
 * Check to see if the an agent email account is valid with Nylas and has the correct scopes.
 * @param {Object} account - An agent email account object.
 */
export const checkEmailAccountValid = account => {
  const { state, sync_state } = account || {};

  const isAccountRunning = ['initializing', 'downloading', 'running', 'valid'].includes(sync_state); // https://docs.nylas.com/reference#account-sync-status
  const isAccountActive = state === 'valid'; // https://docs.nylas.com/reference#token-info

  return isAccountRunning && isAccountActive;
};

export const getEmailAccountsForDropdown = agentEmailAccounts => {
  const { entities, group } = agentEmailAccounts;

  if (group.length < 1) {
    return null;
  }

  return group.reduce((acc, accountId) => {
    const account = { ...entities[accountId] };
    const isValid = checkEmailAccountValid(account);

    // We only want to make available the email accounts that are currently valid. Accounts that are invalid or need reauth will be filtered out.
    if (isValid) {
      const { email, name, NylasAccountId, Email } = account || {};

      const accountEmail = email || Email;
      const id = accountId || NylasAccountId;
      acc.push({
        id,
        email: accountEmail,
        title: `${name} (${accountEmail})`,
        value: id
      });
    }

    return acc;
  }, []);
};

const participantHasValidName = participant => {
  return participant.name && participant.name !== '';
};

/**
 * Get all email participants passed in
 * @param {Array} participants - the list of current email thread participants.
 */
export const getParticipantsArr = participants => {
  return participants.reduce((acc, participant) => {
    const recipientDisplayName = participantHasValidName(participant) ? participant.name : participant.email;

    acc.push(recipientDisplayName);

    return acc;
  }, []);
};

/**
 * Get all email thread participants minus the any set of accounts (usually current agent/TPX user).
 * @param {Array} participants - the list of current email thread participants.
 * @param {Object} agentEmailAccounts - the redux store key from settings.
 */
export const getEmailParticipantsMinusAccounts = (participants, agentEmailAccounts) => {
  const agentEmailArr = Object.keys(agentEmailAccounts.entities).map(id => {
    return agentEmailAccounts.entities[id].email;
  });

  return participants.reduce((acc, participant) => {
    if (!agentEmailArr.includes(participant.email)) {
      const recipientDisplayName = participant.name && participant.name !== '' ? participant.name : participant.email;

      acc.push(recipientDisplayName);
    }

    return acc;
  }, []);
};

/**
 * Get a display specific string for showing email thread participants in the email list.
 * @param {Array} participants - the list of current email thread participants.
 */
export const getParticipantsStr = participants => {
  // If one recipient besides the agent, show the whole name, otherwise show the first word of each name.
  const participantsStr =
    participants.length === 1
      ? participants.join(', ')
      : participants
          .reduce((acc, participant) => {
            acc.push(participant.split(' ')[0].replace(/(,|'|")/g, ''));

            return acc;
          }, [])
          .join(', ');

  const str =
    participantsStr.length > PARTICIPANTS_MAX_LENGTH
      ? `${participantsStr.slice(0, PARTICIPANTS_MAX_LENGTH).trim()}...`
      : participantsStr;

  return str;
};

/**
 * Get a display specific string for showing email recipient names and emails.
 * @param {Array} participants - the list of email participants to format.
 */
export const getParticipantsWithEmailsStr = participants => {
  const str = participants
    .reduce((acc, participant) => {
      const str = participantHasValidName(participant)
        ? `${participant.name} <${participant.email}>`
        : participant.email;
      acc.push(str);

      return acc;
    }, [])
    .join(', ');

  return str;
};

/**
 * Get an appropriate email thread tracking or inbox icon for showing in the email list.
 * @param {Boolean} unread - The thread's unread status.
 * @param {Boolean} isOpened - The thread's tracking status - based on the last message in the thread.
 * @param {Boolean} lastMessageWasSentFromUser - A boolean to indicate that the last message in the thread was sent from the user.
 * @param {date} date - A JavaScript date to check against the email tracking feature release date.
 */
export const getEmailIcon = (unread, isOpened, date) => {
  const DEFAULT_ICON = 'inbox';

  if (unread) {
    return DEFAULT_ICON;
  }

  const isAfterLaunchOfTracking = isAfter(date, new Date(2019, 9, 10)); // Oct 10, 2019 (months are zero-based)

  if (!isAfterLaunchOfTracking) {
    return null;
  }

  return isOpened ? 'opened' : 'unopened';
};

/**
 * Gets a displayable email subject.
 * @param {String} subject - An email or thread's subject.
 */
export const getEmailSubject = subject => {
  return !subject || subject === '' ? '(no subject)' : subject;
};

/**
 * Gets the reply message intro string.
 * @param {Object} message - An email message object.
 */
export const getReplyEmailIntro = message => {
  if (!message) {
    return EMAIL_SPACER_STR;
  }

  const { date, from } = message;
  const sender = from[0];
  const messageDateAttribution = getEmailDateForAttribution(new Date(date * 1000));

  return `${EMAIL_SPACER_STR}${EMAIL_SPACER_STR}On ${messageDateAttribution} ${sender.name} &lt;${ZERO_WIDTH_SPACE}<a href="mailto:${sender.email}">${sender.email}</a>${ZERO_WIDTH_SPACE}}&gt; wrote:`;
};

/**
 * Gets the forward message intro string.
 * @param {Object} message - An email message object.
 */
export const getForwardEmailIntro = message => {
  if (!message) {
    return EMAIL_SPACER_STR;
  }

  const { cc, date, from, subject, to } = message;
  const sender = from[0];
  const messageDateAttribution = getEmailDateForAttribution(new Date(date * 1000));
  const toRecipients = getParticipantsWithEmailsStr(to);
  const toRecipientStr = toRecipients ? `\nTo: ${toRecipients}` : '';
  const ccRecipients = getParticipantsWithEmailsStr(cc);
  const ccRecipientStr = ccRecipients ? `\nCc: ${ccRecipients}` : '';

  return `${EMAIL_SPACER_STR}---------- Forwarded message ---------\nFrom: <strong>${sender.name}</strong> &lt;${ZERO_WIDTH_SPACE}${sender.email}${ZERO_WIDTH_SPACE}&gt;\nDate: ${messageDateAttribution}\nSubject: ${subject}${toRecipientStr}${ccRecipientStr}${EMAIL_SPACER_STR}`;
};

export const getCommaDelimitedEmails = emails => {
  if (!emails) {
    return null;
  }

  return emails
    .reduce((acc, email) => {
      if (!acc.includes(email.value)) {
        acc.push(email.value);
      }

      return acc;
    }, [])
    .join(',');
};
