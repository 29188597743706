import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import '../../assets/logos.svg';
import styles from './Logo.css';

export const Logo = props => {
  const { className, name, size = 'm' } = props;

  if (!name) {
    return null;
  }

  const classes = classnames({
    [styles.logo]: true,
    [styles[`${name}Logo`]]: !!name,
    [styles[`${size}Size`]]: !!size,
    [className]: !!className
  });

  return (
    <div className={classes}>
      <svg role="presentation" focusable="false" className={styles.svg}>
        <use xlinkHref={`#logos_${name}`} />
      </svg>
    </div>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.oneOf(['full']),
  size: PropTypes.oneOf(['s', 'm'])
};
