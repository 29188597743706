import React from 'react';
import { func, string } from 'prop-types';
import { Button } from '../../components/Button/Button';

const MLSCredentialsButton = props => {
  const { buttonClick, buttonLabel = 'Add MLS Credentials', styleType, url = '/settings/mls-credentials/add' } = props;

  return (
    <Button
      url={url}
      ariaLabel={buttonLabel}
      icon="housekey"
      label={buttonLabel}
      onClick={buttonClick}
      styleType={styleType}
      data-cy="addMLSCredentialButton"
    />
  );
};

export default MLSCredentialsButton;

MLSCredentialsButton.propTypes = {
  buttonClick: func,
  buttonLabel: string,
  styleType: string,
  url: string
};
