import React, { Fragment } from 'react';
import { array, bool, func, number, oneOf, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import styles from './Dropdown.css';
import { Container } from '../';

export const Dropdown = React.forwardRef((props, ref) => {
  const {
    className,
    id,
    items,
    defaultValue,
    disabled,
    onChange,
    size = 'm',
    value = '',
    fieldIsValid,
    formIsValid,
    required,
    ...attrs
  } = props;
  const defaultOption = defaultValue ? <option value="">{defaultValue}</option> : null;
  const showInvalid = formIsValid === false && fieldIsValid === false;

  const classes = classnames({
    [styles.container]: !className,
    [styles[`${size}Size`]]: true,
    [styles.invalid]: !!showInvalid,
    [styles.disabled]: disabled,
    [className]: !!className
  });

  return (
    <Container className={classes}>
      <select
        aria-required={required}
        aria-invalid={fieldIsValid === false}
        className={styles.select}
        disabled={disabled}
        id={id}
        onChange={onChange}
        ref={ref}
        required={required}
        value={value}
        {...attrs}
      >
        <Fragment>
          {defaultOption}
          {items.map((item, i) => {
            const optionId = item.id || item.value;
            const optionValue = item.code || item.value; // code is for support of countries from ONYX
            const displayValue = item.title || item.value; // this accommodates a third dimension in data objects, such as with Google calendar lists.

            return (
              <option key={`${optionId}-${i}`} id={optionId} value={optionValue}>
                {displayValue}
              </option>
            );
          })}
        </Fragment>
      </select>
    </Container>
  );
});

Dropdown.propTypes = {
  className: string,
  defaultValue: string,
  disabled: bool,
  id: string.isRequired,
  items: array.isRequired,
  onChange: func.isRequired,
  size: oneOf(['xxs', 'xs', 's', 'm', 'l', 'auto']),
  value: oneOfType([string, number])
};
