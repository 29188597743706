import { string } from 'prop-types';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabContainer, TabGroup, TaskList } from '../../components';
import styles from './Dashboard.css';
import { showWrapup } from '../../actions/wrapup';
import { setDashboardState } from '../../actions/tasks';

const base = '/dashboard';
export const TaskTile = () => {
  const { counts, isLoading, dashboardGroup, dashboardDue } = useSelector(state => state.tasks);
  const dispatch = useDispatch();

  const COLUMN_DATA = [
    { id: 'task', label: 'Task' },
    { id: 'priority', label: 'Priority' },
    { id: 'due', label: 'Due' },
    { id: 'contact', label: 'Contact' },
    { id: 'assignedTo', label: 'Assigned to' },
    { id: 'actions', label: '' }
  ];
  const maxTaskCount = 5;

  const checkSelected = tabKey => {
    return tabKey === dashboardDue || (!dashboardDue && tabKey === 'today');
  };

  const handleClick = tabKey => {
    dispatch(setDashboardState(dashboardGroup, tabKey));
  };

  const handleCompleteTask = task => {
    dispatch(showWrapup({ task }));
  };

  const getTaskCount = type => counts?.[type]?.['all'];

  const todayIconLabel = new Date().getDate().toString();

  return (
    <Fragment>
      <TabContainer>
        <TabGroup>
          <Tab
            id="overdue"
            url="?due=overdue"
            label="Overdue"
            matchParams={{ due: 'overdue' }}
            count={getTaskCount('overdue')}
            isSelected={checkSelected('overdue')}
            onClick={() => {
              handleClick('overdue');
            }}
          />
          <Tab
            id="today"
            url={base}
            label="Today"
            matchParams={null}
            icon="calendar"
            iconLabel={todayIconLabel}
            count={getTaskCount('today')}
            isSelected={checkSelected('today')}
            onClick={() => {
              handleClick('today');
            }}
          />
          <Tab
            id="tomorrow"
            url="?due=tomorrow"
            label="Tomorrow"
            matchParams={{ due: 'tomorrow' }}
            isLoading={isLoading}
            count={getTaskCount('tomorrow')}
            isSelected={checkSelected('tomorrow')}
            onClick={() => {
              handleClick('tomorrow');
            }}
          />
          <Tab
            id="future"
            url="?due=future"
            label="Next 7 Days"
            matchParams={{ due: 'future' }}
            isLoading={isLoading}
            count={getTaskCount('future')}
            isSelected={checkSelected('future')}
            onClick={() => {
              handleClick('future');
            }}
          />
        </TabGroup>
      </TabContainer>
      <TaskList
        location={location}
        isMinimalView={true}
        headerClassName={styles.headerGrid}
        listClassName={styles.taskList}
        headerColumns={COLUMN_DATA}
        handleCompleteTask={handleCompleteTask}
        isVirtuoso={false}
        limitDisplayCount={maxTaskCount}
        isOnDashboard={true}
      />
    </Fragment>
  );
};

TaskTile.propTypes = {
  className: string
};
