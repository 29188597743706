import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';

import { activateAdvancedSearch, showAdvancedSearch, setAdvancedSearch } from '../../actions/advancedSearch';
import { addLeadSourcePickList, saveContactLeadSource, getContactLeadSourcePicklist } from '../../actions/contacts';
import { initialState } from '../../reducers/advancedSearch';
import { getAssignedToOptions } from '../../utils/team';

import { Button } from '../Button';
import { Dropdown } from '../Dropdown';
import { Form } from '../Form';
import { FormField } from '../FormField';
import { FormFieldset } from '../FormFieldset';
import { FormFooter } from '../FormFooter';
import { FormRow } from '../FormRow';
import { LookupBasic } from '../Lookup';

import { STATES_LIST } from '../../data/states';
import { KEYCODE_MAP } from '../../constants';
import styles from './AdvancedSearchForm.css';

export const AdvancedSearchForm = () => {
  const { leadSourcePicklist, types } = useSelector(state => state.contacts);
  const { isRestrictedAgent, userId } = useSelector(state => state.user);
  const search = useSelector(state => state.advancedSearch.search);
  const team = useSelector(state => state.team);

  const dispatch = useDispatch();

  const [formState, updateFormState] = useState(search);
  const [isDisabled, setIsDisabled] = useState(true);

  const { firstName, lastName, phone, email, address, contactTypes, sources, assignedTo } = formState;
  const { entities, groups, currentGroup } = types;
  const parsedLeadSourcePicklist = leadSourcePicklist.filter(item => item.id !== 'Other');
  const teamList = getAssignedToOptions(team, 'agents');
  const emptySearch = initialState.search;
  const addressFields = Object.keys(emptySearch.address);
  const searchGroup = groups[currentGroup];
  const contactTypeList = searchGroup.reduce((acc, id) => [...acc, entities[id]], []);

  const handleChange = e => {
    const { id, value } = e?.target;
    const isAddress = addressFields.includes(id);
    const newValue = { [id]: value };
    updateFormState({ ...formState, ...(isAddress ? { address: { ...address, ...newValue } } : { ...newValue }) });
  };

  const handleContactTypeChange = selected => {
    const contactTypes = Object.keys(selected).reduce((acc, id) => [...acc, selected[id].contactType], []);
    updateFormState({ ...formState, contactTypes });
  };

  const handleSourceChange = selected => {
    const selectedSourceList = Object.keys(selected);
    updateFormState({ ...formState, sources: selectedSourceList });
    const newSources = selectedSourceList.filter(e => !parsedLeadSourcePicklist.map(e => e.id).includes(e));
    newSources.forEach(itemValue => dispatch(saveContactLeadSource({ itemValue })));
  };

  const handleAssignToChange = e => {
    const { id } = e?.target?.selectedOptions[0];
    updateFormState({ ...formState, assignedTo: id !== 'All' ? id : null });
  };

  const handleCancel = () => dispatch(showAdvancedSearch(false));
  const handleClearSearch = () => {
    updateFormState(emptySearch);
    dispatch(setAdvancedSearch({ ...emptySearch }));
    dispatch(activateAdvancedSearch(false));
  };

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(setAdvancedSearch({ ...formState, assignedTo: !isRestrictedAgent ? assignedTo : userId }));
    dispatch(activateAdvancedSearch(true));
    dispatch(showAdvancedSearch(false));
    navigate(`/contacts?searchType=advanced`);
  };

  const handleKeyDown = e => {
    e.stopPropagation();
    if (e.which === KEYCODE_MAP.ENTER && !isDisabled) {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    dispatch(getContactLeadSourcePicklist());
  }, [dispatch]);

  useEffect(() => {
    const { address, contactTypes, sources, ...rest } = formState;
    const values = [
      ...[...contactTypes, ...sources, ...Object.values(address)],
      ...Object.values({ ...rest, ...(isRestrictedAgent ? { assignedTo: null } : {}) })
    ].filter(e => e);

    setIsDisabled(values.length === 0 ? true : false);
  }, [formState, isRestrictedAgent, setIsDisabled]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <Fragment>
      <Form id="advancedSearchForm" autoComplete="off" className={styles.form}>
        <FormRow>
          <FormField id="firstName" placeholder="First Name" type="input" value={firstName} onChange={handleChange} />
          <FormField id="lastName" placeholder="Last Name" type="input" value={lastName} onChange={handleChange} />
        </FormRow>
        <FormRow>
          <FormField {...{ id: 'phone', placeholder: 'Phone', type: 'input', value: phone, onChange: handleChange }} />
        </FormRow>
        <FormRow>
          <FormField {...{ id: 'email', placeholder: 'Email', type: 'input', value: email, onChange: handleChange }} />
        </FormRow>
        <FormRow>
          <FormField
            {...{ id: 'streetNumber', placeholder: 'Street Number', type: 'input', value: address.streetNumber }}
            onChange={handleChange}
          />
          <FormField
            {...{ id: 'streetName', placeholder: 'Street Name', type: 'input', value: address.streetName }}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <FormField id="city" placeholder="City" onChange={handleChange} type="input" value={address.city} />
          <Dropdown
            {...{ id: 'state', size: 's', defaultValue: 'State/Province', items: STATES_LIST, value: address.state }}
            onChange={handleChange}
          />
          <FormField id="zip" placeholder="Zip" onChange={handleChange} type="input" value={address.zip} size="xs" />
        </FormRow>
        <FormFieldset label="Contact types" className={styles.fieldset}>
          <LookupBasic
            {...{ searchKey: 'contactType', searchList: contactTypeList, searchGroup, entities: contactTypes }}
            changeHandler={handleContactTypeChange}
          />
        </FormFieldset>
        <FormFieldset label="Sources" className={styles.fieldset}>
          <LookupBasic
            {...{ id: 'sourceLookUp', searchKey: 'id', label: 'Source', searchList: parsedLeadSourcePicklist }}
            {...{ entities: sources || [], isEntityCreationEnabled: true }}
            addTagType={tag => dispatch(addLeadSourcePickList(tag))}
            changeHandler={handleSourceChange}
          />
        </FormFieldset>
        {teamList.length > 0 && !isRestrictedAgent && (
          <FormFieldset label="Assigned to" className={styles.fieldset}>
            <Dropdown
              {...{ id: 'assignedTo', items: [{ id: null, value: 'All' }, ...teamList] }}
              value={teamList.filter(e => e.id === assignedTo)[0]?.value || ''}
              onChange={handleAssignToChange}
            />
          </FormFieldset>
        )}
      </Form>
      <FormFooter className={styles.footer} advancedSearch={true}>
        {!isDisabled && (
          <span className={styles.clear}>
            <Button ariaLabel="Clear search" label="Clear search" styleType="inline" onClick={handleClearSearch} />
          </span>
        )}
        <Button ariaLabel="Cancel" label="Cancel" onClick={handleCancel} styleType="white" type="submit" />
        <Button
          {...{ ariaLabel: 'Apply', label: 'Apply', styleType: 'primary', type: 'submit', disabled: isDisabled }}
          onClick={handleSubmit}
        />
      </FormFooter>
    </Fragment>
  );
};
