import { deleteFromAllGroups, deleteFromCollection, getMergedEntities, getMergedSetOfIds } from '../utils/collections';
import { deletePartyFromState, preserveTransactionParties } from '../utils/transactions';

export const TRANSACTIONS_ADD_PARTY = 'TRANSACTIONS_ADD_PARTY';
export const TRANSACTIONS_ADD_PARTY_FORM_TOGGLE = 'TRANSACTIONS_ADD_PARTY_FORM_TOGGLE';
export const TRANSACTIONS_DELETE = 'TRANSACTIONS_DELETE';
export const TRANSACTION_DETAILS = 'TRANSACTION_DETAILS';
export const TRANSACTIONS_FETCH_FAIL = 'TRANSACTIONS_FETCH_FAIL';
export const TRANSACTIONS_FETCH_SUCCESS = 'TRANSACTIONS_FETCH_SUCCESS';
export const TRANSACTIONS_FORM_TOGGLE = 'TRANSACTIONS_FORM_TOGGLE';
export const TRANSACTIONS_LOADING = 'TRANSACTIONS_LOADING';
export const TRANSACTIONS_LOADING_BY_CONTACT = 'TRANSACTIONS_LOADING_BY_CONTACT';
export const TRANSACTIONS_LOADING_FINISHED = 'TRANSACTIONS_LOADING_FINISHED';
export const TRANSACTIONS_PARTY_DELETE = 'TRANSACTIONS_PARTY_DELETE';
export const TRANSACTIONS_PARTY_SAVE = 'TRANSACTIONS_PARTY_SAVE';
export const TRANSACTIONS_SAVE = 'TRANSACTIONS_SAVE';
export const TRANSACTIONS_SET = 'TRANSACTIONS_SET';
export const TRANSACTIONS_UPDATE_FORM_TOGGLE = 'TRANSACTIONS_UPDATE_FORM_TOGGLE';
export const TRANSACTIONS_SET_AGENTS = 'TRANSACTIONS_SET_AGENTS';
export const TRANSACTIONS_8I_IMPORT = 'TRANSACTIONS_8I_IMPORT';
export const TRANSACTIONS_SET_LAST_SEARCHED_DATE = 'TRANSACTIONS_SET_LAST_SEARCHED_DATE';

export const initialState = {
  currentGroup: '0', // group name format: ${status}::${representationType}::${startDate}::${endDate}, otherwise contactId
  entities: {},
  focusId: null,
  groups: {},
  isLoading: false,
  toggleForm: false,
  toggleAddPartyForm: false,
  updateTransactionId: null,
  partyTransactionId: null,
  has8iImportBeenTriggered: false,
  lastSearchedDates: null
};

/**
 * The alert redux reducer.
 * @param {Object} state - the current state of the alert store.
 * @param {Object} action - the action to take on the alert store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - A data object that represents the alert's details.
 */
export const transactionsReducer = (state = initialState, action = {}) => {
  const {
    contactId,
    data,
    group,
    focusId,
    listing,
    message,
    setStatus,
    toggleValue,
    transactionId,
    transactionRecords,
    type = 'default'
  } = action;

  const idKey = 'transactionId';
  switch (type) {
    case TRANSACTIONS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case TRANSACTIONS_FETCH_SUCCESS:
      // Data is sorted on closingDate from BE
      const mergedEntities = getMergedEntities(state.entities, transactionRecords, { idKey });
      return {
        ...state,
        entities: mergedEntities,
        groups: {
          ...state.groups,
          [group]: getMergedSetOfIds(state.groups[group], transactionRecords, {
            idKey,
            entities: mergedEntities
          })
        },
        currentGroup: setStatus ? group : state.currentGroup,
        isLoading: false
      };
    case TRANSACTIONS_LOADING_FINISHED:
    case TRANSACTIONS_FETCH_FAIL:
      return {
        ...state,
        isLoading: false
      };
    case TRANSACTIONS_SET:
      return {
        ...state,
        currentGroup: group,
        isLoading: false
      };
    case TRANSACTIONS_FORM_TOGGLE:
      return {
        ...state,
        toggleForm: toggleValue != null ? toggleValue : !state.toggleForm,
        updateTransactionId: null
      };
    case TRANSACTIONS_UPDATE_FORM_TOGGLE:
      return {
        ...state,
        updateTransactionId: transactionId,
        toggleForm: toggleValue != null ? toggleValue : !state.toggleForm,
        focusId
      };
    case TRANSACTIONS_SAVE:
      const payload = { ...data, listing };

      const tempEntities = getMergedEntities(state.entities, [payload], { idKey });
      // transaction parties are served in a separate endpoint, preserve existing ones before updating.
      const entities = preserveTransactionParties(state.entities, tempEntities);

      const newGroups = payload?.transactionId
        ? deleteFromAllGroups(state.groups, payload.transactionId)
        : { ...state.groups };

      return {
        ...state,
        entities: entities,
        groups: newGroups
      };
    case TRANSACTION_DETAILS:
      if (data === null) {
        return state;
      }

      return {
        ...state,
        entities: {
          ...state.entities,
          [transactionId]: {
            ...state.entities[transactionId],
            ...data
          }
        }
      };
    case TRANSACTIONS_DELETE:
      return {
        ...state,
        entities: {
          ...deleteFromCollection(state.entities, transactionId)
        },
        groups: deleteFromAllGroups(state.groups, transactionId)
      };
    case TRANSACTIONS_ADD_PARTY_FORM_TOGGLE:
      return {
        ...state,
        partyTransactionId: transactionId,
        toggleAddPartyForm: toggleValue != null ? toggleValue : !state.toggleAddPartyForm,
        message
      };
    case TRANSACTIONS_ADD_PARTY:
      const oldParties = state.entities[transactionId]?.transactionParties || [];
      const newTransactionParties = [...oldParties, ...[data]];
      return {
        ...state,
        entities: {
          ...state.entities,
          [transactionId]: {
            ...state.entities[transactionId],
            transactionParties: newTransactionParties
          }
        }
      };
    case TRANSACTIONS_PARTY_SAVE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [transactionId]: {
            ...state.entities[transactionId],
            transactionParties: data
          }
        }
      };
    case TRANSACTIONS_PARTY_DELETE:
      return {
        ...state,
        entities: {
          ...state.entities,
          [transactionId]: {
            ...state.entities[transactionId],
            transactionParties: deletePartyFromState(
              state.entities[transactionId].transactionParties,
              transactionId,
              contactId
            )
          }
        }
      };
    case TRANSACTIONS_SET_AGENTS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [transactionId]: {
            ...state.entities[transactionId],
            transactionAgents: data
          }
        }
      };
    case TRANSACTIONS_8I_IMPORT:
      return { ...state, has8iImportBeenTriggered: true };
    case TRANSACTIONS_SET_LAST_SEARCHED_DATE:
      return {
        ...state,
        lastSearchedDates: data
      };
    default:
      return state;
  }
};
