export const ENV = process.env.ENV;
export const HOST = process.env.HOST;
export const PUBLIC_HOST = process.env.PUBLIC_HOST;
export const SVC_HOST = process.env.SVC_HOST;
export const API_HOST = process.env.API_HOST;
export const EDGE_HOST = process.env.EDGE_HOST;
export const API_TMK_HOST = process.env.TMK_HOST;
export const API_GEO_HOST = process.env.GEO_HOST;
export const BROKER_HOST = 'https://broker.topproducer8i.com/'; /* Only the one environment exists */

// All URLs in this set SHOULD NOT end in slash '/'
export const API_URL = `${API_HOST}`;
export const API_URL_SYNC = `${HOST}/SyncService`;
export const API_URL_MARKET_SNAPSHOT = `${API_TMK_HOST}/api/MSService`;
export const SVC_URL = `${SVC_HOST}`;
export const EDGE_URL = `${EDGE_HOST}`;
export const API_URL_GEO = `${API_GEO_HOST}`;
export const API_URL_MARKET_SNAPSHOT_HESTIA = `${API_TMK_HOST}/ms`;

export const API_SERVICE_PATHS = {
  contact: 'tpx/contactservice.svc',
  contactOld: 'contact.svc',
  contactSearch: 'tpx/contactsearchservice.svc',
  filesLegacy: 'fileuploader.svc',
  importantDates: 'tpx/importantdateservice.svc',
  importantDatesLegacy: 'importantdatesreminder.svc',
  lead: 'tpx/leadservice.svc',
  note: 'note.svc',
  nylas: 'tpx/nylasservice.svc',
  task: 'tpx/activity.svc',
  taskLegacy: 'activity.svc',
  user: 'tpx/userservice.svc',
  userProfile: 'tpx/userprofileservice.svc',
  sms: 'tpx/CommunicationService.svc',
  subscriptions: 'tpx/unsubscribeService.svc',
  syncService: 'tpx/syncservice.svc',
  sync: '/sync.svc',
  null: ''
};

export const API_PATHS = {
  accounts: 'accounts',
  calendarSync: 'nylas/calendarsync',
  contacts: 'contacts',
  contactSync: 'nylas/contactsync',
  plans: 'plans',
  sso: 'sso',
  users: 'users',
  transactions: 'transactions',
  nylasV3: 'Nylas3Wrapper',
  admin: 'admin',
  null: ''
};

export const API_BASE_URL = {
  api: API_URL,
  svc: SVC_URL,
  edge: EDGE_URL,
  geo: API_URL_GEO,
  sync: API_URL_SYNC,
  snapshot: API_URL_MARKET_SNAPSHOT,
  snapshotHestia: API_URL_MARKET_SNAPSHOT_HESTIA
};

export const API_PAGING_KEYS = {
  contact: 'name',
  lead: 'dateTime'
};

export const GIT_SHA = process.env.GIT_COMMIT_SHA;
export const BUILD_VERSION = __VERSION__; // version comes from Webpack and is read from the package.json version

export const LIVE_CHAT_ID = parseInt(process.env.LC_ID);

export const REFER_A_FRIEND_LINK = 'https://hubs.li/H0MfpG10';
export const SMART_TARGETING_LINK =
  'https://learnmore.topproducer.com/smart-targeting?utm_source=TPX&utm_medium=product&utm_campaign=smart-targeting';
export const SOCIAL_CONNECT_LINK = 'https://learnmore.topproducer.com/social-connect';

export const ANNOUNCEMENT_LIVE = false;
export const ANNOUNCEMENT_LINK = SMART_TARGETING_LINK;
export const ANNOUNCEMENT_LOCAL_STORAGE_KEY = 'sz20220721'; // Format name like 'csat20200406'
export const ADMIN_LOCAL_STORAGE_KEY = 'admin20250219';

export const LOCAL_STORAGE_VERSION = '0.2.36';

// The keys in this array should be preserved when `clearLocalStorage` is called
export const LOCAL_STORAGE_ALLOW_LIST = [ANNOUNCEMENT_LOCAL_STORAGE_KEY, ADMIN_LOCAL_STORAGE_KEY];

export const LIST_QUERIES = ['all', 'fav', 'recent', 'selected'];
export const ALLOWED_SEARCH_QUERIES = ['followup', ...LIST_QUERIES];

export const DEFAULT_PAGING_SIZE = 25;
export const EMAIL_PAGING_SIZE = 50;
export const EWS_EMAIL_PAGING_SIZE = 20;
export const FOLLOWUP_PAGING_SIZE = 5;

export const KEYCODE_MAP = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  PREV: 37, // arrow left
  UP: 38, // arrow up
  NEXT: 39, // arrow right
  DOWN: 40, // arrow down
  SEMICOLON: 186,
  COMMA: 188
};

// https://github.com/date-fns/date-fns/blob/master/CHANGELOG.md#200---2019-08-20
export const DATE_FORMATS = {
  INPUT_PLACEHOLDER: 'YYYY-MM-DD',
  DATE_WITH_SLASH: 'yyyy/MM/dd',
  ISO_DATE: 'yyyy-MM-dd',
  ISO_DATETIME: "yyyy-MM-dd'T'HH:mm:00", // T is for milliseconds timestamp in date-fns v2, be sure to explicitly escape
  ISO_DATETIME_CALENDAR: 'yyyy-MM-dd HH:mm:ss',
  ISO_DATETIME_Z: "yyyy-MM-dd'T'HH:mm:ssxxx", // xxx is for timezone instead of Z
  ISO_DATETIME_START: "yyyy-MM-dd'T'00:00:00",
  ISO_DATETIME_END: "yyyy-MM-dd'T'23:59:59",
  ISO_TIME: 'HH:mm:ss',
  LONG_DATE: 'LLLL d, yyyy',
  LONG_DATE_TIME: 'MMM dd, yyyy, h:mma',
  LONG_DATE_FORMAL: 'MMMM do, yyyy',
  TIMELINE_DATE: 'eee, MMM d, yyyy',
  TIMELINE_DATE_FORMAL: 'eeee, MMMM do, yyyy',
  SHORT_DATE: 'MMM d',
  SHORT_DATE_WITH_YEAR: 'MMM d, yyyy',
  SHORT_DATE_FORMAL: 'MMM do, yyyy',
  SHORT_TIME: 'h:mma',
  UTC_WITH_SLASH: 'M/dd/yyyy hh:mm:ss aX',
  DATE_WITH_NO_DIVIDER: 'yyyyMMdd'
};

export const REGEX = {
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  URL_SIMPLE:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, // assumes valid urls and boo.com will pass, but boo will not
  US_ZIP: /(^\d{5}$)/,
  CANADA_ZIP: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
  IS_NUMERIC: /^\d+$/,
  URL_SIMPLE_CASE_INSENSITIVE:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, // case insensitive version of URL_SIMPLE,
  URL_WITH_PROTOCOL:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)+[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/, // case insensitive version of URL_SIMPLE,
  HESTIA_CANADA_PARTIAL_ZIP: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]$/i, // 3 Chars
  HESTIA_CANADA_FULL_ZIP: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] \d[ABCEGHJKLMNPRSTVWXYZ]\d$/i, // 6 Chars with a space in between,
  IS_RGB_STRING: /^rgb\(\d+\,\d+\,\d+\)/,
  IS_HEX_STRING: /^#?(([a-f\d])([a-f\d])([a-f\d])){1,2}$/i, // Accepts both 3 and 6 chars with or without # in front,
  TIME_PICKER_FORMAT: /^[0-9]{2}:[0-9]{2}/,
  PHONE: /^(\+?\d{1,5}\s?)?[-.\s]?(\(\d{3}\)|\d{3})[-.\s]?(\d{3})[-.\s]?(\d{4})$/ // Optional country code, area code, exchange code, line number, optional -, ., or space
};

/**
 * Used with native HTML5 form validation in the 'pattern' property
 */
export const VALIDATION_PATTERN = {
  US_ZIP: '\\d{5}',
  CANADA_ZIP: '[A-Za-z]\\d[A-Za-z][ \\-]?\\d[A-Za-z]\\d' // simplified regexp, as the flag 'i' is not supported
};

export const UNICODE = {
  EN_DASH: '\u2013',
  NBSP: '\u00A0',
  ZERO_WIDTH_SPACE: '\u200B',
  NON_BREAKING_HYPHEN: '\u2011',
  CHECK: '\u2713'
};

export const SUPPORT = {
  PHONE: `1${UNICODE.NON_BREAKING_HYPHEN}800${UNICODE.NON_BREAKING_HYPHEN}830${UNICODE.NON_BREAKING_HYPHEN}8300`,
  EMAIL: 'support@topproducer.com',
  MLS_SUPPORT_EMAIL: 'mlssupport@topproducer.com'
};

export const SALES = {
  PHONE: `1${UNICODE.NON_BREAKING_HYPHEN}833${UNICODE.NON_BREAKING_HYPHEN}387${UNICODE.NON_BREAKING_HYPHEN}1911`,
  EMAIL: 'learnmore@topproducer.com'
};

export const BILLING = {
  PHONE: `1${UNICODE.NON_BREAKING_HYPHEN}800${UNICODE.NON_BREAKING_HYPHEN}241${UNICODE.NON_BREAKING_HYPHEN}5721`
};

export const GOOGLE_SIGN_IN_ACCOUNTS = ['fmtestnan', 'georgesync', 'kellyagent3'];

export { PLANS_URI } from './taskPlans';

export const DEFAULT_TITLE_TAG = 'Top Producer X';

export const CAPTCHA_ERROR_MESSAGE = `We're having a hard time verifying that you aren't a robot. Please contact customer care at ${SUPPORT.EMAIL}.`;
export const CAPTCHA_THRESHOLD = 0.3;

export const DEFAULT_NOTE_MAX_LENGTH = '2800';

export const INFINITE_SCROLL_ROOT_MARGIN = '0px 0px 400px 0px';

export const TRANSACTION_FUTURE_DATE_LABEL = 'Future';

export const TASK_PENDING_DATE_LABEL = 'Pending';

export const textingWhiteList = ['94ab7721-edb6-418a-97a5-da88eb649692'];

// Special timestamp far in the future to ensure email stays on top of the queue
export const EMAIL_IN_QUEUE_TIMESTAMP = Math.floor(new Date('2999-12-31').getTime() / 1000).toString();
