import { differenceInMonths } from 'date-fns';
import { REGEX } from '../constants';
import { REPORT_FREQUENCY } from '../data/snapshot';
import { cleanStrOfSymbols, getArrayFromString, getValueAsWholeNumber, parsePrice, toSnakeCase } from './strings';
import { isFieldFilled } from './validation';
import { getCleanMatches, sortArrayOfObjects } from './collections';

export const PROPERTY_UNKNOWN_MESSAGE = 'Unknown - View summary or landing page for details';

/**
 * Get a state value from the entity object using its ID.
 * @param {*} DATALIST - the dropdown's data
 * @param {*} id - the id of the selected dropdown item.
 */
export const getDataValueById = (DATALIST, id) => {
  if (!id) {
    return '';
  }
  const data = typeof id === 'string' ? id : id.toString();
  const found = DATALIST.find(item => item.id === data);
  if (found) {
    return found.value;
  }
  return data;
};

/**
 * Checks if zip is valid
 * @param {String} code - the zip code
 */
export const isValidZip = code => {
  return REGEX.US_ZIP.test(code) || REGEX.CANADA_ZIP.test(code);
};

/**
 * Checks if the zip is a valid Canadian address, accepting 6 chars zips with spacing in the middle
 * @param {String} code
 */
export const isValidCanadianFullZip = code => {
  return REGEX.HESTIA_CANADA_FULL_ZIP.test(code);
};

/**
 * Checks if the zip is a valid Canadian address, accepting both formats
 * @param {String} code
 */
export const isValidCanadianZip = code => {
  return isValidCanadianFullZip(code) || REGEX.HESTIA_CANADA_PARTIAL_ZIP.test(code);
};
/**
 * Checks if zip is valid, enforcing different regex rules for Canadian zip with required spacing
 * @param {String} code - the zip code
 */
export const isValidZipForHestia = code => {
  return REGEX.US_ZIP.test(code) || isValidCanadianFullZip(code);
};

/**
 * Get a list of cleaned zipcode from a comma separated string
 * @param {String} zips comma separated string of validated zip code
 * @returns {String[]} list of zip code
 */
export const getCleanListOfZips = zips => {
  if (zips == null) {
    return [];
  }
  if (Array.isArray(zips)) {
    return zips;
  }

  return zips
    ?.split(',')
    .filter(zip => zip.trim() !== '')
    .map(zip => zip.trim());
};

/**
 * Checks if zips list are valid from string separated by comma
 * @param {String} multiZip - the multiple zips string
 */
export const isValidZipList = (multiZip, validationFunc = isValidZip) => {
  if (multiZip == null) {
    return true;
  }

  const zips = getCleanListOfZips(multiZip);
  return zips.length <= 10 && zips.every(zip => validationFunc(zip));
};

export const getMinValueRange = (list, value, index) => {
  const data = !value
    ? null
    : [...list].reverse().find(item => {
        return parseInt(item.id) <= parseInt(value.replace(',', ''));
      });

  return data || list[index || 0];
};

export const getMaxValueRange = (list, value, index) => {
  const data = !value
    ? null
    : list.find(item => {
        return parseInt(item.id) === parseInt(value.replace(',', ''));
      });

  return data || list[index || 0];
};

export const getValueRange = (list, value, index) => {
  const data = !value
    ? null
    : list.find(item => {
        return item.id === value || item.id === value.replace(',', '');
      });

  return data || list[index || 0];
};

/**
 * Cleans a price of symbols and checks if filled.
 * @param {String} price
 */
export const getCleanPrice = price => {
  if (!isFieldFilled(price)) {
    return null;
  }

  return getValueAsWholeNumber(price);
};

/**
 * Check if the user can access market snapshot features based on the redux store
 * @param {Object} options snapshot and MLS credentials state from redux store
 * @returns {Boolean} true if the user can access the snapshot or false otherwise
 */
export const userCanAccessSnapshot = options => {
  const { msAgentSettings, msAccountDetails } = options || {};
  return Boolean(
    msAgentSettings && msAccountDetails && msAccountDetails.isAgentActive && msAgentSettings?.boards?.length > 0
  );
};

export const SNAPSHOT_PROPERTY_TYPE_MAPPINGS = {
  commercial: 'commercial',
  condo: 'condos, apartment',
  condotownhome: 'condo_townhome_rowhouse_coop',
  farmorranch: 'farm',
  investment: 'investment',
  land: 'land',
  manufacturedmobile: 'mobile',
  multifamily: 'multi_family',
  singlefamily: 'single_family',
  residential: 'single_family',
  townhome: 'townhomes, duplex_triplex'
};

/**
 * Get the mapped property types for modification of a basic snapshot that has moved to Hestia.
 * @param {String} propType the basic Snapshot property type.
 * @param {Array} picklist the Hestia board picklist values to validate against.
 * @returns {Array} An array of Hestia compatible property types to modify.
 */
export const getPropertyTypeMapping = (propType, picklist) => {
  if (!propType) {
    return null;
  }

  const cleanPropertyType = cleanStrOfSymbols(propType.toLowerCase());

  const mapping = SNAPSHOT_PROPERTY_TYPE_MAPPINGS[cleanPropertyType].replace(/\s/g, '').split(',');

  const validatedMapping = mapping.reduce((acc, item) => {
    // We ensure the mapped items are options in the current picklist.
    if (picklist && picklist.includes(item)) {
      acc.push(item);
    }

    return acc;
  }, []);

  return validatedMapping;
};

export const getCreateMSPayload = props => {
  const {
    board,
    contactId,
    tmkAgentId,
    firstName,
    lastName,
    streetName,
    city,
    state,
    zip,
    email,
    minPrice,
    maxPrice,
    extAreaField,
    extCountyField,
    extZipField,
    extCityField,
    extMinGarage,
    extMaxGarage,
    extMinBed,
    extMaxBed,
    extMinBath,
    extMaxBath,
    extPool,
    extPropTypeField,
    extPropSubTypesField,
    extPropTypesSetField,
    extBasicPropTypeField,
    extSchoolField,
    extStyles,
    extFirePlace,
    reportFrequency,
    multiZips,
    extMinSqft,
    extMaxSqft,
    extMinYearBuilt,
    extMaxYearBuilt,
    extSubdivision,
    role
  } = props;
  const { listing_alert_setting, mls_code, board_id } = board;
  const { new_listing, sold, price_change } = listing_alert_setting;

  const propTypeFieldValue = extPropTypeField.value || extBasicPropTypeField.value;
  const prop_types = propTypeFieldValue || null;

  const styles = extStyles.value || null;
  const subdivisions = getArrayFromString(extSubdivision.value);

  const cleanMinPrice = getCleanPrice(minPrice.value);
  const cleanMaxPrice = getCleanPrice(maxPrice.value);

  const payload = {
    tmk_agent_id: tmkAgentId.value,
    source: 'TPX',
    lead_id: contactId.value,
    board_id,
    rdc_code: mls_code,
    role: role.value,
    consumer: {
      first_name: firstName.value,
      last_name: lastName.value,
      street: streetName.value,
      city: city.value,
      state: state.value,
      zip: zip.value,
      email: email.value
    },
    search_criteria: extBasicPropTypeField.value
      ? {
          min_price: cleanMinPrice != null ? cleanMinPrice.toString() : '',
          max_price: cleanMaxPrice != null ? cleanMaxPrice.toString() : '',
          prop_type: Object.values(extBasicPropTypeField.value)[0].id
        }
      : {
          min_price: cleanMinPrice != null ? cleanMinPrice.toString() : '',
          max_price: cleanMaxPrice != null ? cleanMaxPrice.toString() : ''
        },
    extended_search_criteria: {
      zips: getCleanListOfZips(extZipField.value), // Uses all zipfield values.
      cities: extCityField.value,
      areas: extAreaField.value,
      counties: extCountyField.value,
      prop_types: propTypeFieldValue ? prop_types : null,
      raw_mls_prop_sub_types: extPropSubTypesField.value ? extPropSubTypesField.value : null,
      prop_types_set: extPropTypesSetField.value,
      school_districts: extSchoolField.value,
      styles,
      garages: {
        min: parsePrice(extMinGarage.value),
        max: parsePrice(extMaxGarage.value)
      },
      beds: {
        min: parsePrice(extMinBed.value),
        max: parsePrice(extMaxBed.value)
      },
      baths: {
        min: parsePrice(extMinBath.value),
        max: parsePrice(extMaxBath.value)
      },
      sqft: {
        min: parsePrice(extMinSqft.value),
        max: parsePrice(extMaxSqft.value)
      },
      year_built: {
        min: getCleanPrice(extMinYearBuilt.value),
        max: getCleanPrice(extMaxYearBuilt.value)
      },
      subdivisions,
      has_pool: extPool.value,
      has_fireplace: extFirePlace.value
    },
    report_frequency: REPORT_FREQUENCY.find(item => item.value === reportFrequency.value).id,
    listing_alert_detail: {
      new: new_listing,
      price_change,
      sold,
      additional_zips: multiZips.value
    },
    requested_by: 1 // means it's from agent
  };
  return payload;
};

/**
 * Return customized error message for Market Snapshot based on response from the backend
 * @param {String} errorCode one word error code, used to identify the failure
 * @param {String} message message that could provide more insight about why it failed
 * @returns {String} error message
 */
export const getErrorMessage = (errorCode, message) => {
  if (!errorCode) {
    return null;
  }

  const generalErrorMsg = 'Market Snapshot failed.';

  switch (errorCode) {
    case 'mls_invalid_credential':
    case 'invalid_property_address':
    case 'invalid_email_address':
    case 'account_inactive':
    case 'no_active_mls_board':
    case 'auto_ms_off':
    case 'invalid_property_type':
    case 'mls_many_results':
    case 'missing_area_mgmt':
    case 'mls_no_few_results':
    case 'ms_unavailable':
    case 'mls_unavailable':
      return `${message}.`;
    case 'mls_not_support_ms':
      return 'MLS no longer supports Market Snapshot.';
    default:
      // 'general_error', 'mls_error', 'network_error' or unhandled error from BE
      if (message) {
        return `${message}.`;
      }
      return generalErrorMsg;
  }
};
/**
 * Return customized stop reason based on response from the backend
 * @param {String} stopReason one word reason code
 * @returns {String} stop reason
 */
export const getStopReason = stopReason => {
  if (!stopReason) {
    return null;
  }

  const stopMessages = {
    account_closed: 'the agent account was closed',
    cold_prospect: 'the prospect went cold',
    email_bounce: 'the email bounced',
    email_spam: 'the email was marked as spam',
    email_invalid: 'the email was invalid',
    email_dropped: 'the email was dropped',
    inactive_agent: 'of agent inactivity',
    ms_over_quota: 'your Market Snapshot quota was exceeded',
    one_time_report: 'it was marked as a "one time" report'
  };

  return stopMessages[stopReason] || null;
};

export const REPORT_STATUSES = [
  { title: 'All', value: 'any' },
  { title: 'Viewed', value: 'viewed' },
  { title: 'Failed', value: 'failed' }
];

/**
 * Get clean set of zips from the Hestia Snapshot form's consumer zip and extended zips
 * @param {String} zipStr - a single consumer zip
 * @param {Array} zipArr - an array of extended zips
 */
export const getCleanZips = (zipStr, zipArr) => {
  const hasZipStr = zipStr && zipStr !== '';

  if (!hasZipStr && !zipArr) {
    return zipStr; //undefined
  }

  if (zipArr) {
    if (!hasZipStr || zipArr.includes(zipStr)) {
      return zipArr;
    }

    return [zipStr, ...zipArr];
  }

  return zipStr ? [zipStr] : zipStr;
};

/**
 * Get clean set of cities from the Hestia Snapshot form's consumer city and extended cities
 * @param {String} city - consumer city
 * @param {String} state_code - consumer state_code
 * @param {Array} citiesArr - an array of extended cities
 */
export const getCleanCities = (city, state_code, citiesArr) => {
  const newItem = city && state_code ? { city, state_code } : undefined;

  if (citiesArr) {
    if (newItem) {
      const hasCityAlready = citiesArr.some(item => {
        return newItem.city === item.city && newItem.state_code === item.state_code;
      });

      if (!hasCityAlready) {
        return [newItem, ...citiesArr];
      }
    }

    return citiesArr;
  }

  return newItem ? [newItem] : newItem;
};

/**
 * Create payload for preview listings, aggregating basic and extended fields.
 * @param {Object} options options for the payload
 */
export const getPreviewListingPayload = options => {
  const { tmkAgentId } = options;
  const { board_id, consumer, extended_search_criteria, rdc_code, role, search_criteria } = getCreateMSPayload(options);
  const { zip: consumerZip, city: consumerCity, state: consumerState } = consumer;
  const { cities, zips } = extended_search_criteria;

  return {
    board_id,
    ...search_criteria,
    ...extended_search_criteria,
    consumer: { zip: consumerZip, city: consumerCity, state: consumerState },
    cities: cities,
    rdc_code,
    role,
    tmk_agent_id: tmkAgentId,
    zips: getCleanListOfZips(zips) // extended search zips
  };
};

/**
 * BE wants FE to match BE filtering which is seemingly applied exclusively to MS generation.
 *  ¯\_(ツ)_/¯
 * @param {Object} listings results returned from endpoint
 */
export const filterListings = listings => {
  return listings.filter(listing => {
    const { location, description, mls_status, source, list_price, status, last_status_change_date } = listing || {};
    const { contract_date } = source || {};
    const { sold_date, sold_price } = description || {};
    // Reject when required fields are missing
    if (
      description == null ||
      mls_status == null ||
      mls_status === '' ||
      source == null ||
      list_price == null ||
      contract_date == null
    ) {
      return false;
    }

    const cleanStatus = status.toLowerCase();
    if (cleanStatus === 'sold') {
      if (sold_date == null || sold_price == null) {
        return false;
      }
      // Invalid price if the diff between sold and list is over 80% change
      if (
        Math.abs(sold_price - list_price) > 0.8 * list_price ||
        Math.abs(sold_price - list_price) > 0.8 * sold_price
      ) {
        return false;
      }
    }

    // The last status change of off market listing need to be within 3 months
    if (cleanStatus === 'off_market' && differenceInMonths(new Date(), new Date(last_status_change_date)) >= 3) {
      return false;
    }
    const { address } = location || {};

    // Canadian listings don't return lon lat
    if (isValidCanadianZip(address?.postal_code)) {
      return true;
    }

    // Coordinates
    return (
      address.coordinate?.lat != null &&
      address.coordinate?.lon != null &&
      address.coordinate?.lat !== 0 &&
      address.coordinate?.lon !== 0
    );
  });
};

/**
 * Sort preview listings by source.contract_date
 * @param {Object[]} listings
 * @param {String} direction
 */
export const sortPreview = (listings, direction = 'asc') => {
  return [...listings].sort((a, b) => {
    const dateA = a?.mls_status.toLowerCase() === 'sold' ? a?.description?.sold_date : a?.source?.contract_date;
    const dateB = b?.mls_status.toLowerCase() === 'sold' ? b?.description?.sold_date : b?.source?.contract_date;
    const sortA = direction === 'dsc' ? dateB : dateA;
    const sortB = direction === 'dsc' ? dateA : dateB;

    return new Date(sortA).getTime() - new Date(sortB).getTime();
  });
};

export const buildSnapshotGroupName = ({ id, startDate, endDate, status }) => {
  // Contact snapshot doens't have filters, simply keep the group name as the contact id
  if (id !== 'myReports') {
    return id;
  }
  return `${id}::${status || ''}::${startDate || ''}::${endDate || ''}`;
};

/**
 * Returns adjusted sqft list with custom sqft inserted if not already in list.
 * @param {Array} list The sqft list.
 * @param {Number} value The sqft value.
 * @returns {Array} The list of sqft.
 */
export const adjustSqft = (list, value) => {
  if (!value) {
    return list;
  }
  const valueInList = list.some(e => e.value === value);
  if (valueInList) {
    return list;
  }
  const newList = [...list, { id: value.toString(), value: value.toString() }];
  const sortedList = sortArrayOfObjects(newList, { sortKey: 'value', sortType: 'numeric' });

  return sortedList;
};

/**
 * Unescapes Unicode escape sequences in a given input string.
 *
 * @param {string} input - The input string possibly containing Unicode escape sequences.
 * @returns {string} - The input string with Unicode escape sequences replaced by their corresponding characters.
 */
export const unescapeUnicode = input => {
  if (!input) {
    return input;
  }

  // Replace Unicode escape sequences with their corresponding characters
  return input.replace(/\\[uU][\dA-Fa-f]{4}/g, match => {
    // Extract the hexadecimal value from the matched escape sequence
    // and convert it to a Unicode character
    return String.fromCharCode(parseInt(match.substr(2), 16));
  });
};

/**
 *
 * @param {Object[]} data
 * @param {Boolean} isHestia
 * @param {Object[]} selectedParents
 * @param {Object[]} selectedChildren
 * @param {String} field
 * @returns {Object}
 */
export const getSearchListData = (data, isHestia, selectedParents, selectedChildren, field) => {
  if (data == null) {
    return [];
  }

  const hasParents = selectedParents && selectedParents.length > 0;
  const hasChildren = selectedChildren && selectedChildren.length > 0;

  const parentsToMatch = hasParents ? selectedParents : Object.keys(data);

  const matches = parentsToMatch.reduce((acc, item) => {
    const itemKey = field === 'styles' && isHestia ? toSnakeCase(item.toLowerCase()) : item;
    const parentItemData = data[itemKey] || [];

    if (Array.isArray(parentItemData)) {
      const augmentedItemData =
        // In the case of cities, we need to save an object that includes the state_code, otherwise it is a string.
        field === 'cities'
          ? parentItemData.map(city => {
              return { id: city, name: city, state_code: itemKey };
            })
          : field === 'areas' // In the case of areas, decode Unicode from the area name
          ? parentItemData.map(area => unescapeUnicode(area))
          : parentItemData;

      return [...acc, ...augmentedItemData];
    }

    const childrenToMatch = hasChildren ? selectedChildren : parentItemData ? Object.keys(parentItemData) : [];
    const childMatches = childrenToMatch.reduce((acc, child) => {
      // Cities have an object structure, do we check for the existence of a city key. All other are strings.
      const childKey = child.city || child;
      const matchingItems = parentItemData[childKey];

      if (Array.isArray(matchingItems)) {
        return [...acc, ...matchingItems];
      }

      return acc;
    }, []);

    if (childMatches) {
      return [...acc, ...childMatches];
    }

    return acc;
  }, []);

  // Note: Cities are clean and don't contain dupes, etc. If you see a dupe, it is likely a similar city name but from different states.
  // Bright MLS has multiple cities named Washington, but all are from different states.
  const sortKey = field === 'school_districts' ? 'name' : 'id';
  const entities = ['cities', 'school_districts'].includes(field)
    ? sortArrayOfObjects(matches, { sortKey, sortType: 'alpha' })
    : getCleanMatches(matches);

  return entities;
};
