import React from 'react';
import { navigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { bool, object } from 'prop-types';
import classnames from 'classnames';

import { signOutUser } from '../../actions/authentication';
import { ssoToTp8i } from '../../actions/sso';

import { Header } from './';
import { Avatar } from '../Avatar';
import { Logo } from '../Logo';
import { useMediaQueryContext } from '../MediaQueryProvider/MediaQueryProvider';
import { MenuButton } from '../MenuButton';
import { Search } from '../Search';
import { USER_MENU_SUPPORT_LINKS } from '../../data/user-menu-links';
import { trackEvent } from '../../utils/analytics';
import mobileLogo from '../../assets/apple-touch-icon.png';

import styles from './HeaderAuthenticated.css';
import { getSalesforceStatus } from '../../actions/salesforce';

export const HeaderAuthenticated = props => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const userInfo = useSelector(state => state.userProfile.userInfo);
  const { isAdmin } = useSelector(state => state.admin);
  const { isTabletLandscapeAndUp } = useMediaQueryContext();
  const { agentPhotoUrl } = userInfo || {};
  const { isDetailsPage, location } = props;

  const handleLogout = () => {
    dispatch(signOutUser(user));
  };

  const handleTp8iRedirect = () => {
    trackEvent('authentication', 'SSO to 8i');
    dispatch(ssoToTp8i());
  };

  const handleSalesForceRedirect = () => {
    trackEvent('authentication', 'SSO to Salesforce');
    dispatch(getSalesforceStatus(window.location));
  };

  const userMenuItemsWithSalesforce = [
    {
      icon: 'settings',
      id: 'settings',
      label: 'Settings',
      onSelect: () => {
        navigate('/settings');
      }
    },
    {
      icon: 'tp',
      id: 'signIn8i',
      label: 'Go to TP8i',
      onSelect: handleTp8iRedirect
    },
    ...USER_MENU_SUPPORT_LINKS,
    {
      icon: 'billing',
      id: 'signInSalesForce',
      label: 'Billing & Account Info',
      onSelect: handleSalesForceRedirect
    },
    {
      icon: 'signout',
      id: 'signOut',
      label: 'Sign out',
      onSelect: handleLogout
    }
  ];

  const userMenuItems = [
    {
      icon: 'settings',
      id: 'settings',
      label: 'Settings',
      onSelect: () => {
        navigate('/settings');
      }
    },
    {
      icon: 'tp',
      id: 'signIn8i',
      label: 'Go to TP8i',
      onSelect: handleTp8iRedirect
    },
    ...USER_MENU_SUPPORT_LINKS,
    {
      icon: 'signout',
      id: 'signOut',
      label: 'Sign out',
      onSelect: handleLogout
    }
  ];

  const classes = classnames({
    [styles.header]: true,
    [styles.isDetailsPage]: isDetailsPage // used when responsive size is smartphone.
  });

  const { hasSalesforceSSO } = userInfo || {};

  return (
    <Header className={classes}>
      <div className={styles.logoContainer}>
        {isTabletLandscapeAndUp ? (
          <Logo name="full" size="s" />
        ) : (
          <img className={styles.mobileLogo} src={mobileLogo} />
        )}
      </div>
      {isAdmin && <label className={styles.impersonateLabel}>**IMPERSONATION MODE**</label>}
      <div className={styles.searchContainer}>
        <Search location={location} />
      </div>
      <div className={styles.userContainer}>
        <MenuButton
          alignment="right"
          className={styles.avatarMenuButton}
          dataCy="avatar"
          menuItems={hasSalesforceSSO ? userMenuItemsWithSalesforce : userMenuItems}
          menuListClassName={styles.avatarMenuList}
          shouldUsePortal={true}
          showArrow={isTabletLandscapeAndUp}
        >
          <Avatar pictureURL={agentPhotoUrl} size="s" hideImage={false} />
        </MenuButton>
      </div>
    </Header>
  );
};

HeaderAuthenticated.propTypes = {
  isDetailsPage: bool,
  isAdmin: bool,
  location: object.isRequired
};
