export const INSIGHT_STATUS_LABELS = [
  {
    id: '1',
    code: '1',
    title: 'Inquired on'
  },
  {
    id: '2',
    code: '2',
    title: 'Interested in'
  },
  {
    id: '3',
    code: '3',
    title: 'Not interested'
  },
  {
    id: '4',
    code: '4',
    title: 'Shown'
  },
  {
    id: '5',
    code: '5',
    title: 'Made offer'
  },
  {
    id: '6',
    code: '6',
    title: 'Offer rejected'
  },
  {
    id: '7',
    code: '7',
    title: 'Sent to client'
  }
];
