/** @module */
import { showMessage } from '../actions/message';
import {
  SETTINGS_UPLOAD_CONTACTS_FILE,
  SETTINGS_UPLOAD_CONTACTS_PROGRESS,
  SETTINGS_CONTACTS_GET_INTERNAL_FIELDS,
  SETTINGS_IMPORT_HISTORY,
  SETTINGS_IMPORT_MAPPING_CONTACTS,
  SETTINGS_GOOGLE_CALENDAR_LIST
} from '../reducers/settings';
import {
  contactStatuses,
  request,
  requestError,
  getProgressCount,
  getContactStatusId,
  searchAndRemoveFromStorage
} from '../utils';

import { sanitizer } from '../utils/dom';

export const getImportHistory = options => {
  const { forceRefresh } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'sync',
      baseUrlKey: 'sync',
      forceRefresh,
      path: 'u/gethistory'
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: SETTINGS_IMPORT_HISTORY,
        importHistory: data
      });

      return data;
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * Gets internal fields for contacts
 * If success, dispatches the data in the store.
 * If fails, shows error message via <Toast />.
 */
export const getInternalFields = () => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'sync',
      baseUrlKey: 'sync',
      path: 'u/getinternalfieldsjson'
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: SETTINGS_CONTACTS_GET_INTERNAL_FIELDS,
        internalFields: data
      });
    } catch (error) {
      requestError(error, dispatch);
    }
  };
};

/**
 * uploads contacts file
 * If success, dispatches the file data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to upload contact file
 * @param {String} [options.formData] - the form data which contains file details.
 * @param {String} [options.fileName] - the name of the file to be uploaded.
 */
export const uploadContactsFile = options => {
  const { formData } = options || {};

  return async dispatch => {
    dispatch({
      type: SETTINGS_UPLOAD_CONTACTS_PROGRESS,
      progressStatus: 0
    });

    const requestOptions = {
      apiServiceType: 'sync',
      baseUrlKey: 'sync',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      method: 'POST',
      onUploadProgress: progressEvent => {
        const progressStatus = getProgressCount(progressEvent);

        dispatch({
          type: SETTINGS_UPLOAD_CONTACTS_PROGRESS,
          progressStatus: progressStatus
        });
      },
      path: 'u/upload',
      payload: formData,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: SETTINGS_UPLOAD_CONTACTS_FILE,
        mappingData: data
      });

      return data;
    } catch (error) {
      requestError(error, dispatch);

      return null;
    }
  };
};

/**
 * Importing the mapped contact fields
 * If success, dispatches the mapping data in the store.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to send mapping data
 * @param {String} [options.location_path] - the location path for the file stored in server.
 * @param {String} [options.original_name] - the original name of the file.
 * @param {String} [options.user_mapping] - the user field mapping data.
 * @param {String} [options.contact_type] - the type of the contact.
 * @param {String} [options.sales_pipeline_status] - the status of the contacts sales pipeline.
 * @param {String} [options.login_name] - the login name of the user assigned to.
 * @param {String} [options.has_headers] - if contact has headers to be passed.
 * @param {String} [options.responsibleuser_id] - the user id responsible for importing the contacts.
 * @param {String} [options.responsibleuser_display_name] - the user display name responsible for importing the contacts.
 */
export const importContactsMapping = options => {
  const {
    fileName: location_path,
    originalFileName: original_name,
    userMapping: user_mapping,
    contactTypes: contact_types,
    contactStatus: sales_pipeline_status,
    loginName: login_name,
    has_headers = true,
    userId: responsibleuser_id,
    userName: responsibleuser_display_name
  } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'sync',
      baseUrlKey: 'sync',
      method: 'POST',
      path: 'v2/startimport',
      payload: {
        location_path,
        original_name,
        user_mapping,
        contact_type: contact_types.value.join(', '),
        sales_pipeline_status: contactStatuses.get(sales_pipeline_status).toLowerCase(),
        login_name,
        has_headers,
        responsibleuser_id,
        responsibleuser_display_name
      },
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: SETTINGS_IMPORT_MAPPING_CONTACTS,
        importStatus: data
      });

      if (data.status) {
        dispatch(showMessage({ message: 'Contacts imported successfully.', type: 'success' }, true)); // useTimer set to true
        //Remove cached contacts from storage
        const contactStatus = getContactStatusId(requestOptions.payload.sales_pipeline_status);
        searchAndRemoveFromStorage(`status${contactStatus}`, 'leadlist');
        searchAndRemoveFromStorage('contact', 'search');
      } else {
        dispatch(showMessage({ message: 'Failed importing contacts.', type: 'error' }, true)); // useTimer set to true
        return false;
      }
    } catch (error) {
      requestError(error, dispatch);

      return false;
    }
  };
};

/**
 * Request for google sync settings data.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 */
export const getGoogleSyncSettings = () => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'syncService',
      path: 'google/syncsetting',
      shouldBeCached: false
    };
    try {
      const res = await request(requestOptions);
      const { data } = res;
      const { exception } = data;
      if (exception) {
        throw exception;
      }
      return data;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Update google sync settings.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to update sync settings
 */
export const updateGoogleSyncSettings = options => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'syncService',
      method: 'POST',
      path: 'google/updatesyncsetting',
      payload: options,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch(showMessage({ message: 'Sync Preferences updated successfully.', type: 'success' }, true)); // useTimer set to true
      return true;
    } catch (error) {
      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Request for google calendar list.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 */
export const getGoogleCalendarList = () => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'syncService',
      path: 'google/calendarlist',
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      const formatList = data.map(item => {
        return { ...item, value: item.id };
      });

      dispatch({
        type: SETTINGS_GOOGLE_CALENDAR_LIST,
        calendarList: formatList
      });
      return formatList;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Get google Auth URL.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 * @param {Object} host - Host of the application for call back.
 */
export const getGoogleAuthUrl = () => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'syncService',
      method: 'GET',
      path: 'Google/OAuthURL',
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      const sanitizedUrl = sanitizer(data.url);
      const url = new URL(sanitizedUrl).toString();

      return url;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Handle google Auth callback call.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 * @param {Object} code - Code from google callback.
 * @param {Object} scope - Scope from google callback.
 */
export const handleGoogleAuthCallback = (code, scope) => {
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'syncService',
      method: 'POST',
      params: { code, scope },
      path: 'Google/OAuthCallBack',
      shouldBeCached: false
    };

    try {
      const res = await request(requestOptions);
      const { data } = res;
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      return data;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Save and sync google settings.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 * @param {Object} options - options to update sync settings.
 */
export const saveAndSyncGoogle = options => {
  const SUCCESS_MESSAGE = 'Google sync enabled successfully.';
  const ERROR_MESSAGE = 'Google sync setup not complete';

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'syncService',
      method: 'POST',
      path: 'Google/SelectedSyncCalendar',
      payload: options,
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }
      //API returns Empty response with status code 200 ,on success
      const resp = data === '';

      dispatch(
        showMessage({ message: resp ? SUCCESS_MESSAGE : ERROR_MESSAGE, type: resp ? 'success' : 'error' }, true)
      );

      return resp;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

export const deleteGoogleSyncSettings = () => {
  const SUCCESS_DEL_MESSAGE = 'Google Sync removed successfully.';
  const ALREADY_DEL_MESSAGE = 'Google Sync settings for this account are already beeen removed.';
  const ERROR_DEL_MESSAGE = 'Error removing Google Sync settings';

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'syncService',
      method: 'GET',
      path: 'Google/DeleteSyncSetting',
      shouldBeCached: false
    };

    try {
      const { data } = await request(requestOptions);
      const { exception } = data;

      if (exception) {
        throw exception;
      }

      if (!data) {
        dispatch(showMessage({ message: ERROR_DEL_MESSAGE, type: 'error' }, true));
      } else {
        dispatch(
          showMessage(
            { message: data.targeti_d === '' ? ALREADY_DEL_MESSAGE : SUCCESS_DEL_MESSAGE, type: 'success' },
            true
          )
        );
      }

      return data;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};
