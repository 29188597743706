import React from 'react';
import { arrayOf, node, oneOf, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import styles from './ButtonGroup.css';

export const ButtonGroup = props => {
  const { children, className, variant = 'default', ...attrs } = props;

  if (!children) {
    return null;
  }

  const classes = classnames({
    [styles.buttonGroup]: !className,
    [styles[`${variant}Group`]]: true,
    [className]: !!className
  });

  return (
    <div className={classes} {...attrs}>
      {children}
    </div>
  );
};

ButtonGroup.propTypes = {
  className: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
  variant: oneOf(['default', 'icon'])
};
