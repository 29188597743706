import React from 'react';
import { arrayOf, node, object, oneOfType } from 'prop-types';

import { cleanStrOfSymbols } from '../../../utils/strings';

export const fontSizes = [
  { id: 'small', label: 'Small', size: 1 },
  { id: 'regular', label: 'Regular', size: 2 },
  { id: 'large', label: 'Large', size: 4 },
  { id: 'huge', label: 'Huge', size: 6 }
];

export const fontSizeKeywordMap = new Map([
  [1, 'x-small'],
  [2, 'small'],
  [3, 'medium'],
  [4, 'large'],
  [5, 'x-large'],
  [6, 'xx-large']
]);

export const fontFamilies = {
  sansserif: 'Sans Serif',
  serif: 'Serif',
  arial: 'Arial',
  verdana: 'Verdana',
  georgia: 'Georgia',
  timesnewroman: 'Times New Roman',
  courier: 'Courier'
};

/**
 * Gets a CSS compatible font family string from a slate leaf fontFamily prop.
 * @param {String} family A font family identifier string.
 */
export const getFontFamilyStr = (family = 'sansserif') => {
  if (!family) {
    return null; // this can happen on paste of signature from Gmail
  }

  // Needs to support ids like sansserif.
  // Needs to support font family strings like 'Arial, Helvetica, sans-serif'
  const isFamilyStr = family.includes(',');

  // if it looks to be a family str versus an ID, we use it by itself.
  if (isFamilyStr) {
    return family;
  }

  const id = cleanStrOfSymbols(family.toLowerCase());

  const isSerif = ['georgia', 'timesnewroman'].includes(id);
  const isCode = ['courier'].includes(id);

  if (isSerif) {
    return id === 'serif' ? id : `${fontFamilies[id]}, serif`;
  }

  if (isCode) {
    return `${fontFamilies[id]}, monospace`;
  }

  // else default to sans-serif
  return id === 'sansserif' ? 'Arial, Helvetica, sans-serif' : `${fontFamilies[id]}, sans-serif`;
};

/**
 * React component for rendering Slate Leaf nodes.
 * @param {Object} props Leaf component props.
 * @param {Object} [props.attributes] The node's Slate attributes.
 * @param {Array} [props.children] The child nodes of a node.
 * @param {Object} [props.leaf] Editor marks (bold, italic, etc.), and HTML element related attribute and style values.
 */
export const Leaf = ({ attributes, children, leaf }) => {
  const { bold, fontBackground, fontColor, fontFamily, fontSize, italic, strikethrough, underline } = leaf;

  const style =
    fontBackground || fontColor
      ? {
          backgroundColor: fontBackground,
          color: fontColor
        }
      : null;

  if (bold) {
    children = <strong>{children}</strong>;
  }

  if (fontFamily || fontSize) {
    const familyStr = getFontFamilyStr(fontFamily);

    if ((fontFamily && fontFamily !== 'sansserif') || fontSize !== 2) {
      // We only want to wrap the font tag when NOT the default size and font family, because we want it to use the default text size of the email application.
      children = (
        <font face={familyStr} size={fontSize} {...attributes}>
          {children}
        </font>
      );
    }
  }

  if (italic) {
    children = <em>{children}</em>;
  }

  if (strikethrough) {
    children = <s>{children}</s>;
  }

  if (underline) {
    children = <u>{children}</u>;
  }

  return (
    <span {...attributes} style={style}>
      {children}
    </span>
  );
};

Leaf.propTypes = {
  attributes: object,
  children: oneOfType([arrayOf(node), node]).isRequired,
  leaf: object.isRequired
};
