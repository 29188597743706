import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { array, func, string } from 'prop-types';
import Downshift from 'downshift';
import classnames from 'classnames';

import { FormTextInput, Icon } from '..';
import { sortArrayOfObjects } from '../../utils';
import { decodeURIComponentSafe } from '../../utils/strings';
import { MAX_ITEMS, SEARCH_ICONS } from '../../constants/search';
import styles from '../Search/Search.css';
import dropdownStyles from '../Dropdown/Dropdown.css';

const menuClasses = classnames({ [styles.menu]: true });

const itemToString = item => (item?.searchTerm ? decodeURIComponentSafe(item?.searchTerm) : '');

const markSearch = (search, value, reverse) => {
  const valueSpace = value[3] === ' ';
  const searchSpace = search[3] === ' ';
  const modRequired = value.length > 3;
  // Small modifier for the edge case of i.e. "is:b" matching "is: b" and vice versa.
  const lengthModifier =
    valueSpace && !searchSpace && modRequired ? -1 : !valueSpace && searchSpace && modRequired ? 1 : 0;

  const markLength = value.length + lengthModifier;

  const item = reverse ? (
    <Fragment>
      {search.substring(0, search.length - markLength)}
      <mark className={styles.mark}>{search.substring(search.length - markLength)}</mark>
    </Fragment>
  ) : (
    <Fragment>
      <mark className={styles.mark}>{search.substring(0, markLength)}</mark>
      {search.substring(markLength)}
    </Fragment>
  );

  return <div>{item}</div>;
};

const getCleanList = (list, contactTypes, searchTerm) => {
  const listValue = Array.isArray(list) ? list : [];
  const isSearch = searchTerm.startsWith('is:');
  const searchTermValue = encodeURIComponent(searchTerm).toLowerCase();
  const letterSearch = searchTermValue.length === 1 &&
    /[a-zA-Z]/.test(searchTermValue[0]) && {
      searchTerm: `Last names starting with ${searchTerm.toUpperCase()}`,
      type: 'letterSearch'
    };
  const searchSuggestion = { searchTerm: `${searchTerm} - Search Top Producer`, type: 'searchSuggestion' };

  const trackedTypes = contactTypes.reduce(
    (types, type) => [...types, { searchTerm: encodeURIComponent(`is: ${type}`), type: 'contactType' }],
    []
  );

  const listToFilter = isSearch ? [...listValue, ...trackedTypes] : listValue;

  const filteredList = listToFilter.filter(item => {
    const nameValue = item?.searchTerm?.toLowerCase().replace('is%3a%20', 'is%3a');
    return nameValue?.startsWith(searchTermValue.replace('is%3a%20', 'is%3a'));
  });

  const sortedArray = sortArrayOfObjects(filteredList, {
    sortKey: 'whenCreated',
    sortType: 'date',
    direction: 'dsc'
  });

  return letterSearch
    ? [letterSearch, ...sortedArray].splice(0, MAX_ITEMS)
    : searchTerm.length > 0 && searchTerm.trim() !== 'is:'
    ? [searchSuggestion, ...sortedArray].splice(0, MAX_ITEMS)
    : sortedArray.splice(0, MAX_ITEMS);
};

export const LookupSearch = props => {
  const { list, onChange, onSelect, value } = props;
  const trackedList = list.map(item => ({ ...item, type: 'recentSearch' }));
  const contactTypes = useSelector(state => state.contacts.types.groups?.['contactType::all']) || [];

  const cleanList = getCleanList(trackedList, contactTypes, value);

  return (
    <Downshift id="searchLookup" defaultHighlightedIndex={0} onSelect={onSelect} itemToString={itemToString}>
      {({ getInputProps, getItemProps, getMenuProps, isOpen, highlightedIndex, openMenu }) => (
        <div className={styles.field}>
          <FormTextInput
            data-cy="searchContacts"
            id="contactSearchInput"
            type="search"
            placeholder="Search contacts..."
            className={styles.input}
            autoCapitalize="off"
            {...getInputProps({
              onClick: openMenu,
              value,
              onChange,
              onKeyDown: event => {
                if (['Home', 'End'].includes(event.key)) {
                  event.nativeEvent.preventDownshiftDefault = true;
                }
              }
            })}
          />
          <Icon name="search" size="s" className={styles.icon} />
          {isOpen && cleanList.length > 0 && (
            <ul {...getMenuProps()} className={menuClasses}>
              {cleanList.map((item, index) => {
                const classes = classnames({
                  [dropdownStyles.item]: true,
                  [dropdownStyles.hovered]: highlightedIndex === index
                });

                return (
                  <li
                    className={classes}
                    {...getItemProps({ key: itemToString(item) + item.type + (item?.whenCreated || ''), index, item })}
                  >
                    <Icon name={SEARCH_ICONS[item.type]} size="s" />
                    {item.type === 'searchSuggestion'
                      ? itemToString(item)
                      : markSearch(itemToString(item), value, item.type === 'letterSearch')}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </Downshift>
  );
};

LookupSearch.propTypes = {
  list: array.isRequired,
  onChange: func.isRequired,
  onSelect: func.isRequired,
  value: string.isRequired
};
