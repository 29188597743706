import React from 'react';
import { arrayOf, node, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import styles from './TabContainer.css';

export const TabContainer = props => {
  const { children, className } = props;

  if (!children) {
    return null;
  }

  const classes = classnames({
    [styles.tabContainer]: !className,
    [className]: !!className
  });

  return <div className={classes}>{children}</div>;
};

TabContainer.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string
};
