/** @module */
import dompurify from 'dompurify';

// See how to use Dompurify here: https://github.com/cure53/DOMPurify
const DEFAULT_DOMPURIFY_OPTIONS = { FORBID_TAGS: ['meta', 'style', 'title'] };

const removeNode = node => {
  if (!node.parentNode) {
    return;
  }

  return node.parentNode.removeChild(node);
};

const isTrackingPixel = node => {
  const width = node.getAttribute('width') || node.style.width || '';
  const height = node.getAttribute('height') || node.style.height || '';

  return width.replace('px', '') === '1' && height.replace('px', '') === '1';
};

/**
 * Cleans the email body of unwanted HTML.
 * @param {Node} node - a DOM node.
 */
const cleanEmailNode = node => {
  const tagName = node.nodeName;

  // We want to remove elements under a number of situations.
  if (
    // is a tracking pixel
    (tagName === 'IMG' && isTrackingPixel(node)) ||
    // has style.display set to none
    node.style.display === 'none'
  ) {
    removeNode(node);
  }
};

dompurify.addHook('afterSanitizeAttributes', node => {
  cleanEmailNode(node);
});

export const emailSanitizer = (dirtyHTML, options) =>
  dompurify.sanitize(dirtyHTML, { ...DEFAULT_DOMPURIFY_OPTIONS, ...options });
