import { getGroupNameFromInput, request, requestError } from '../utils';
import { getAppliedPlansGroupName, getAppliedPlansParams, getPlansGroupName, OWNER_TYPE } from '../utils/taskPlans';
import {
  TASK_PLANS_GET_START,
  TASK_PLANS_GET_SUCCESS,
  TASK_PLANS_ADD_START,
  TASK_PLANS_ADD_SUCCESS,
  TASK_PLANS_ADD_ERROR,
  TASK_PLANS_UPDATE_START,
  TASK_PLANS_UPDATE_SUCCESS,
  TASK_PLANS_UPDATE_ERROR,
  TASK_PLANS_DELETE_START,
  TASK_PLANS_DELETE_SUCCESS,
  TASK_PLANS_DELETE_ERROR,
  TASK_PLANS_GET_ERROR,
  TASK_PLANS_GET_BY_ID_START,
  TASK_PLANS_GET_BY_ID_SUCCESS,
  TASK_PLANS_GET_BY_ID_ERROR,
  TASK_PLANS_GET_ITEMS_START,
  TASK_PLANS_GET_ITEMS_SUCCESS,
  TASK_PLANS_GET_ITEMS_ERROR,
  TASK_PLANS_ADD_ITEM_START,
  TASK_PLANS_ADD_ITEM_SUCCESS,
  TASK_PLANS_ADD_ITEM_ERROR,
  TASK_PLANS_UPDATE_ITEM_START,
  TASK_PLANS_UPDATE_ITEM_SUCCESS,
  TASK_PLANS_UPDATE_ITEM_ERROR,
  TASK_PLANS_DELETE_ITEM_START,
  TASK_PLANS_DELETE_ITEM_SUCCESS,
  TASK_PLANS_DELETE_ITEM_ERROR,
  TASK_PLANS_APPLY_START,
  TASK_PLANS_APPLY_SUCCESS,
  TASK_PLANS_APPLY_ERROR,
  TASK_PLANS_UNAPPLY_START,
  TASK_PLANS_UNAPPLY_SUCCESS,
  TASK_PLANS_UNAPPLY_ERROR,
  TASK_PLANS_GET_ALL_RULES,
  TASK_PLANS_DELETE_RULE,
  TASK_PLANS_MASS_APPLY_START,
  TASK_PLANS_MASS_APPLY_SUCCESS,
  TASK_PLANS_MASS_APPLY_ERROR,
  TASK_PLANS_RESET_RULES
} from '../reducers/taskPlans';
import { showMessage } from './message';
import { clearAlert } from '.';
import { removeTasksFromStoreByPlanId, resetTasksPaging } from './tasks';

const requestPlans = options =>
  request({
    baseUrlKey: 'api',
    path: 'plans',
    shouldBeCached: false,
    ...options
  });

/**
 * Generic action to be used by different actions for getting plans.
 * (This action is not intended to be used directly/publicly)
 *
 * @see getPlans
 * @see getAppliedPlansByContactId
 *
 * @param {String} groupName Name of the group to be used in the reducer
 * @param {Object} params Params to be passed to be used in the request
 */
const fetchPlans = (groupName, params) => {
  return async dispatch => {
    dispatch({
      type: TASK_PLANS_GET_START,
      payload: { groupName }
    });

    try {
      const { data } = await requestPlans({ params });

      dispatch({
        type: TASK_PLANS_GET_SUCCESS,
        payload: { data, groupName }
      });
    } catch (error) {
      dispatch({ type: TASK_PLANS_GET_ERROR });
      requestError(error, dispatch);
    }
  };
};

/**
 * Fetch plans by category
 * @param {String|Number} categoryId Category id to fetch by
 */
export const getPlans = (categoryId, autoPlanType) => {
  return async dispatch => {
    if (categoryId == null) {
      return;
    }

    const groupName = getPlansGroupName(categoryId.toString(), autoPlanType);
    dispatch(fetchPlans(groupName, { categoryId, autoPlanFilter: autoPlanType }));
  };
};

/**
 * Fetch plans by Contact/Transaction Id
 * @param {String} ownerId id to fetch by
 * @param {number} ownerType owner type @see OWNER_TYPE (utils/taskPlans.js)
 */
export const getAppliedPlans = (ownerId, ownerType) => {
  return async dispatch => {
    if (!ownerId || !ownerType) {
      return;
    }

    const groupName = getAppliedPlansGroupName(ownerId, ownerType);
    const params = getAppliedPlansParams(ownerId, ownerType);

    dispatch(fetchPlans(groupName, params));
  };
};

export const getPlanById = id => {
  return async dispatch => {
    if (!id) {
      return;
    }

    dispatch({
      type: TASK_PLANS_GET_BY_ID_START,
      payload: { id }
    });

    try {
      const { data } = await requestPlans({
        path: `plans/${id}`
      });

      dispatch({
        type: TASK_PLANS_GET_BY_ID_SUCCESS,
        payload: { data, id }
      });
      return Promise.resolve(data);
    } catch (error) {
      dispatch({ type: TASK_PLANS_GET_BY_ID_ERROR, payload: { id } });
      requestError(error, dispatch);
    }
  };
};

const PlanItemsSort = {
  DEFAULT: '0',
  TPO_CHRONOLOGICALLY: '1',
  CHRONOLOGICALLY: '2'
};

/**
 * Get plan items by the plan id
 * @param {String} id Plan Id
 * @param {Number} sort Sort type 0: Default; 1: Chronologically
 */
export const getPlanItems = (id, sort = PlanItemsSort.DEFAULT) => {
  return async dispatch => {
    if (!id) {
      return;
    }

    dispatch({
      type: TASK_PLANS_GET_ITEMS_START,
      payload: { id }
    });

    try {
      const { data } = await requestPlans({
        path: `plans/${id}/items`,
        params: { sort }
      });

      dispatch({
        type: TASK_PLANS_GET_ITEMS_SUCCESS,
        payload: { data, id }
      });
    } catch (error) {
      dispatch({ type: TASK_PLANS_GET_ITEMS_ERROR, payload: { id } });
      requestError(error, dispatch);
    }
  };
};

/**
 * Get plan items by the plan id in chronological order
 *
 * Testing Tip:
 * - It's also possible to use PlanItemsSort.TPO_CHRONOLOGICALLY. This change can help finding bugs in the back-end.
 *   Some times, when the BE is updated with some feature changes, TPO_CHRONOLOGICALLY works and CHRONOLOGICALLY don't.
 *
 * @see PlanItemsSort
 * @param {String} id Plan Id
 */
export const getPlanItemsChronologically = id => getPlanItems(id, PlanItemsSort.CHRONOLOGICALLY); // can use PlanItemsSort.TPO_CHRONOLOGICALLY for testing purposes.

/**
 * Get the options that can be used when creating or editing a Plan's item
 * @param {String} planId Plan Id
 * @param {String|null} itemId Id of the item being updated or null if it's creating.
 */
export const getPlanItemOptions = (planId, itemId) => {
  return async dispatch => {
    if (!planId) {
      return null;
    }

    try {
      const { data } = await requestPlans({
        path: `plans/${planId}/options`,
        // itemId must be present in the request even if it is null
        params: { itemId: itemId || '' }
      });

      return data;
    } catch (error) {
      requestError(error, dispatch);
      return null;
    }
  };
};

const validateNewPlan = (planEntities, newPlan) => {
  const found = Object.values(planEntities).find(plan => plan.name === newPlan.name);
  if (found) {
    throw new Error('A plan with this name already exists');
  }
};

const createPlan = (newPlan, items, shouldShowMessage) => {
  return async (dispatch, getState) => {
    try {
      // inform the store that an operation is in progress
      dispatch({ type: TASK_PLANS_ADD_START });

      const existingPlans = getState().taskPlans.entities;
      validateNewPlan(existingPlans, newPlan);

      const isDefault = Boolean(items?.length);

      const result = await requestPlans({
        method: 'POST',
        path: isDefault ? 'plans/default' : 'plans',
        payload: isDefault ? { ...newPlan, items } : newPlan
      });

      const entity = result?.data;

      dispatch({
        type: TASK_PLANS_ADD_SUCCESS,
        payload: {
          entity,
          groupName: getGroupNameFromInput(`${newPlan.categoryId}`)
        }
      });
      if (shouldShowMessage) {
        dispatch(showMessage({ message: 'Plan created successfully.', type: 'success' }, true));
      }

      return entity;
    } catch (error) {
      // success or error, isLoading is not true anymore.
      dispatch({ type: TASK_PLANS_ADD_ERROR });
      requestError(error, dispatch);
      return null;
    }
  };
};

const updatePlan = (existingPlan, shouldShowMessage) => {
  return async dispatch => {
    try {
      dispatch({ type: TASK_PLANS_UPDATE_START });

      const { data: entity } = await requestPlans({ method: 'PUT', payload: existingPlan });

      dispatch({
        type: TASK_PLANS_UPDATE_SUCCESS,
        payload: { entity }
      });
      if (shouldShowMessage) {
        dispatch(showMessage({ message: 'Plan updated successfully.', type: 'success' }, true));
      }
      return entity;
    } catch (error) {
      dispatch({ type: TASK_PLANS_UPDATE_ERROR });
      requestError(error, dispatch);
      return null;
    }
  };
};

/**
 * Create or update a plan
 * @param {Object} plan Plan to be saved (created or updated)
 * @param {String?} [plan.id] Plan id
 * @param {String} [plan.name] Plan name
 * @param {String} [plan.description] Plan description
 * @param {Object[]} items array of items in the plan template
 */
export const savePlan = (plan, items, shouldShowMessage = true) =>
  plan?.id ? updatePlan(plan, shouldShowMessage) : createPlan(plan, items, shouldShowMessage);

const createPlanItem = (planId, newItem) => {
  return async dispatch => {
    if (!planId) {
      return;
    }

    try {
      // inform the store that an operation is in progress
      dispatch({ type: TASK_PLANS_ADD_ITEM_START });

      await requestPlans({
        method: 'POST',
        path: `plans/${planId}/items`,
        payload: newItem
      });

      dispatch({
        type: TASK_PLANS_ADD_ITEM_SUCCESS,
        payload: { planId, newItem }
      });

      dispatch(getPlanItemsChronologically(planId));
      dispatch(showMessage({ message: 'Task created successfully.', type: 'success' }, true));
    } catch (error) {
      // success or error, isLoading is not true anymore.
      dispatch({ type: TASK_PLANS_ADD_ITEM_ERROR });
      requestError(error, dispatch);
    }
  };
};

const updatePlanItem = (planId, existingItem) => {
  return async dispatch => {
    if (!planId) {
      return;
    }

    try {
      // inform the store that an operation is in progress
      dispatch({ type: TASK_PLANS_UPDATE_ITEM_START });

      await requestPlans({
        method: 'PUT',
        path: `plans/${planId}/items`,
        payload: existingItem
      });

      dispatch({
        type: TASK_PLANS_UPDATE_ITEM_SUCCESS,
        payload: { planId, existingItem }
      });

      dispatch(getPlanItemsChronologically(planId));
      dispatch(showMessage({ message: 'Task updated successfully.', type: 'success' }, true));
    } catch (error) {
      // success or error, isLoading is not true anymore.
      dispatch({ type: TASK_PLANS_UPDATE_ITEM_ERROR });
      requestError(error, dispatch);
    }
  };
};

/**
 * Create or update a plan item
 * @param {Object} item Item to be saved (created or updated)
 * @param {String?} [item.planId] Plan id
 * @param {String?} [item.id] item id
 */
export const savePlanItem = item => (item.id ? updatePlanItem(item.planId, item) : createPlanItem(item.planId, item));

/**
 * Delete a plan by id
 * @param {String} entityId Plan id
 */
export const deletePlan = entityId => {
  return async dispatch => {
    if (!entityId) {
      return;
    }
    try {
      dispatch({ type: TASK_PLANS_DELETE_START });

      await requestPlans({ method: 'DELETE', path: `plans/${entityId}` });

      dispatch({
        type: TASK_PLANS_DELETE_SUCCESS,
        payload: { entityId }
      });
      dispatch(showMessage({ message: 'Plan deleted successfully.', type: 'success' }, true));
    } catch (error) {
      dispatch({ type: TASK_PLANS_DELETE_ERROR });
      requestError(error, dispatch);
    }
  };
};

/**
 * Delete a plan item
 * @param {Object} item Item to be deleted
 * @param {String} [item.planId] Plan id to which the item belongs
 * @param {String} [item.id] Item id
 * @param {Number} [item.categoryId] Category Id
 */
export const deletePlanItem = item => {
  return async dispatch => {
    const { planId, id, categoryId } = item || {};
    if (!planId || !id || !categoryId) {
      return;
    }
    try {
      dispatch({ type: TASK_PLANS_DELETE_ITEM_START, payload: { planId, id } });

      await requestPlans({ method: 'DELETE', path: `plans/${planId}/items/${id}/${categoryId}` });

      dispatch({ type: TASK_PLANS_DELETE_ITEM_SUCCESS });
      dispatch(getPlanItemsChronologically(planId));
      dispatch(showMessage({ message: 'Task removed successfully.', type: 'success' }, true));
    } catch (error) {
      dispatch({ type: TASK_PLANS_DELETE_ITEM_ERROR, payload: { planId, item } });
      requestError(error, dispatch);
    }
  };
};

/**
 * @param {String} id Plan id
 * @param {String} startOfPlan Application's start date in the following format: YYYY-MM-DD
 * @param {8|9} ownerType 8 means contact, 9 means transaction
 * @param {String} ownerId contact id (if ownerType = 8) or transaction id (if ownerType = 9)
 */
export const applyPlan = (id, startOfPlan, ownerType, ownerId) => {
  return async dispatch => {
    if (!id || !ownerId || !ownerType) {
      return;
    }
    try {
      dispatch({ type: TASK_PLANS_APPLY_START });

      // this request returns the plan information as well as the application data:
      // (categoryId, id, ownerId, ownerLevel, ownerType, planName, and startOfPlan)
      // but we don't need any of these here.
      await requestPlans({
        method: 'POST',
        path: `plans/apply`,
        payload: {
          id,
          startOfPlan,
          ownerId,
          ownerType
        }
      });

      dispatch({ type: TASK_PLANS_APPLY_SUCCESS });
      dispatch(showMessage({ message: 'Plan applied successfully.', type: 'success' }, true));
    } catch (error) {
      dispatch({ type: TASK_PLANS_APPLY_ERROR });
      requestError(error, dispatch);
    }
  };
};

/**
 *
 * @param {String} id Plan id
 * @param {Array} contactIds Array of selected contactIds
 * @param {String} startOfPlan Optional. Format (YYYY-MM-DD)
 */
export const massApplyPlan = (id, contactIds, startOfPlan) => {
  return async dispatch => {
    try {
      dispatch({ type: TASK_PLANS_MASS_APPLY_START });

      // this request returns the plan information as well as the application data:
      // (categoryId, id, ownerId, ownerLevel, ownerType, planName, and startOfPlan)
      // but we don't need any of these here.
      await requestPlans({
        method: 'POST',
        path: `plans/massApply`,
        payload: {
          id,
          contactIds,
          startOfPlan
        }
      });

      dispatch({ type: TASK_PLANS_MASS_APPLY_SUCCESS });
      dispatch(
        showMessage(
          {
            message:
              'Success. The selected plan is being applied to each contact. This process might take a few minutes..',
            type: 'success'
          },
          true
        )
      );
    } catch (error) {
      dispatch({ type: TASK_PLANS_MASS_APPLY_ERROR });
      requestError(error, dispatch);
    }
  };
};

/**
 * @param {String} id Plan id
 * @param {8|9} ownerType 8 means contact, 9 means transaction
 * @param {String} ownerId contact id (if ownerType = 8) or transaction id (if ownerType = 9)
 * @param {Array} taskList list of currently applied plan tasks, so we don't POST if no tasks (gives error).
 */
export const unapplyPlan = (id, ownerType, ownerId, taskList) => {
  return async dispatch => {
    if (!id || !ownerId || !ownerType || !taskList) {
      return;
    }
    try {
      dispatch({ type: TASK_PLANS_UNAPPLY_START });
      if (taskList.length > 0) {
        await requestPlans({
          method: 'POST',
          path: `plans/unapply`,
          payload: {
            id,
            ownerId,
            ownerType
          }
        });
      }
      dispatch({ type: TASK_PLANS_UNAPPLY_SUCCESS, payload: { entityId: id } });
      dispatch(showMessage({ message: 'Plan unapplied successfully.', type: 'success' }, true));
    } catch (error) {
      dispatch({ type: TASK_PLANS_UNAPPLY_ERROR });
      requestError(error, dispatch);
    }
  };
};

/**
 * Fetch all rules
 */
export const getAllRules = () => {
  return async dispatch => {
    dispatch({ type: TASK_PLANS_RESET_RULES });
    const { data } = await requestPlans({ path: 'plans/rules/all' });
    dispatch({ type: TASK_PLANS_GET_ALL_RULES, payload: { data } });
  };
};

/**
 * Fetch all rule options
 */
// export const getRuleOptions = () => {
//   return async dispatch => {
//     const { data } = await requestPlans({ path: 'plans/rules/options' });
//   };
// };

export const addPlanRule = options => {
  const {
    name,
    source,
    subSource = ['No Sub Source'],
    contactType,
    planId,
    planName,
    otherSource = [],
    id,
    lastModified,
    shouldShowMessage = true
  } = options || {};

  return async dispatch => {
    try {
      const response = await requestPlans({
        method: id ? 'PUT' : 'POST',
        path: 'plans/rules',
        payload: {
          id,
          name,
          source,
          subSource,
          otherSource,
          contactType, // Buyer: 1, Seller: 2
          planId,
          planName,
          categoryId: 8, // Must be 8 as it only works for TPX aciton plan,
          ownerLevel: 40, // 40 means plan level,
          lastModified
        }
      });
      const { data } = response;

      const payload = [data];
      dispatch({ type: TASK_PLANS_GET_ALL_RULES, payload: { data: payload } });
      if (shouldShowMessage) {
        dispatch(showMessage({ message: `Rule ${id ? 'updated' : 'added'} successfully.`, type: 'success' }, true));
      }
    } catch (error) {
      const { status, data } = error.response || {};
      const { Message } = data || {};
      if (status === 400) {
        return Promise.reject(Message);
      }
    }
  };
};

export const deletePlanRule = options => {
  const { id } = options;
  return async dispatch => {
    dispatch(clearAlert());
    const { status } = await requestPlans({
      method: 'DELETE',
      path: `plans/rules/${id}`
    });

    if (status === 204) {
      dispatch({ type: TASK_PLANS_DELETE_RULE, payload: { id } });
      dispatch(showMessage({ message: 'Rule delete successfully.', type: 'success' }, true));
    }
  };
};

export const unapplyPlanAndWrapUp = (task, currentList) => {
  const { planId, contactId, linkTo, contacts } = task || {};

  const { id: linkToId } = linkTo || {};

  const ownerType = linkToId ? OWNER_TYPE.transactions : OWNER_TYPE.contacts;
  const ownerId = linkToId || contactId || contacts?.[0]?.id;

  return async dispatch => {
    dispatch(unapplyPlan(planId, ownerType, ownerId, currentList)).then(() => {
      dispatch(removeTasksFromStoreByPlanId(planId));
      dispatch(resetTasksPaging());
      dispatch(clearAlert());
    });
  };
};
