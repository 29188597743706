export const TEMPLATES_URL = '/marketing/template-library';
export const EMAIL_TEMPLATE_FORMAT_VERSION = '0'; // This should be a string.

export const TEMPLATES_SUBTYPE_MAP = new Map([
  [1, 'Email'],
  [2, 'Text']
]);

export const TEMPLATES_SUBTYPE = {
  email: { value: 1, label: 'Email' },
  texting: { value: 2, label: 'Text' }
};

export const SYSTEM_EMAIL_TEMPLATE_GROUP_NAME = '99::1::0';
export const USER_EMAIL_TEMPLATE_GROUP_NAME = '1::1::0';

export const SYSTEM_TEXT_TEMPLATE_GROUP_NAME = '99::2::0';
export const USER_TEXT_TEMPLATE_GROUP_NAME = '1::2::0';

export const TEMPLATE_OBJECT_TYPE_MAP = new Map([
  [0, 'team'],
  [1, 'personal'],
  [99, 'system'],
  ['agent', 'agent'],
  ['contact', 'contact'],
  ['relationship', 'relationship']
]);

export const SUPPORTED_TEMPLATE_OBJECT_TYPES = [1, 99, 'template'];

export const MESSAGE_SIGNATURE_AUTOMATIC = 'Your signature will be appended automatically to your email.';
export const EMAIL_LIMIT_NOTIFIER =
  '*Daily email limits are defined by your email provider. If you go beyond the daily limit your provider sets, your messages, including scheduled emails and task emails, may be delayed until the following day.';
