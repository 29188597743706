import React from 'react';
import { useSelector } from 'react-redux';
import { ApplyPlanForm } from '../ApplyPlanForm';
import { Dialog, DialogHeader } from '../Dialog';

const TaskPlanModal = props => {
  const {
    isOpen,
    ownerType,
    testId,
    entityId,
    selectedContacts,
    handleApplyPlanSubmitCallback,
    handleRemoveContact,
    closeHandler
  } = props;

  const plansAreLoading = Boolean(useSelector(state => state.taskPlans.isLoading));

  const handleApplyPlanFormClose = () => {
    closeHandler();
  };

  return (
    <Dialog isOpen={isOpen}>
      <DialogHeader
        testId={`${testId}-DialogHeader`}
        title="Apply Plan"
        icon="tasks"
        clearHandler={handleApplyPlanFormClose}
      />
      <ApplyPlanForm
        categoryId={ownerType}
        isLoading={plansAreLoading}
        ownerId={entityId}
        selectedContacts={selectedContacts}
        handleRemoveContact={handleRemoveContact}
        submitCallback={handleApplyPlanSubmitCallback}
      />
    </Dialog>
  );
};

export default TaskPlanModal;
