import { object, oneOf, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate, useLocation } from '@reach/router';

import { getTemplateById } from '../../actions/templates';
import { KEYCODE_MAP, UNICODE } from '../../constants';
import { TEMPLATE_OBJECT_TYPE_MAP, TEMPLATES_URL } from '../../constants/templates';
import { getNextPrevEntityFromCollection } from '../../utils/collections';
import { isElementForDataEntry } from '../../utils/dom';
import { getParsedTemplateBody } from '../../utils/templates';

import { Button, ButtonGroup } from '../Button';
import { Editor } from '../Editor/Editor';
import { EDITOR_TEMPLATE_CATEGORIES } from '../Editor/editor-templates';
import { cleanAndDeserializeForSlate, convertAndDeserializeTextMessageForSlate } from '../Editor/editor-utils';
import { FormFieldReadOnly } from '../FormFieldReadOnly';
import { FormFooter } from '../FormFooter';
import { FormRow } from '../FormRow';
import { Loading } from '../Loading';
import { Tag, TagGroup } from '../Tag';
import { TemplateCategory } from '../TemplateCategory/TemplateCategory';

import styles from './TemplateLibrary.css';
import documentStyles from '../Document/Document.css';
import { getResponse, updateMessages } from '../../actions/openai';
import { COLOR_GREEN_LIGHT } from '../../constants/preferences';
import { getInitialMessages } from '../../utils/aiAuthor';
import { buildTestId } from '../../utils';
import { AI_TEST_ID } from '../Editor/Toolbar/AIDialog';

export const TemplateLibrary = props => {
  const {
    selectedTemplate: selectedTemplateProp,
    templateCategories,
    templates,
    showCustomizeButton = true,
    variant = 'email'
  } = props;

  const location = useLocation();
  const dispatch = useDispatch();

  const { pathname } = location || {};
  const url = !pathname.includes('/contacts') ? TEMPLATES_URL : '/contacts/templates';

  const { userId } = useSelector(state => state.user);

  const { AI } = useSelector(store => store.settings.eula) || {};
  const isAIEulaValid = AI?.accepted === true;

  const [isLoading, setIsLoading] = useState(false);
  const [defaultBody, setDefaultBody] = useState(null);
  const [orderedTemplates, setOrderedTemplates] = useState(null);
  const [selectedTemplate, _setSelectedTemplate] = useState(selectedTemplateProp);
  const selectedTemplateRef = useRef(selectedTemplate);
  const setSelectedTemplate = template => {
    selectedTemplateRef.current = template;
    _setSelectedTemplate(template);
  };

  const [AIContent, setAIContent] = useState(null);

  const isEmail = variant === 'email';
  const currentEntity = templates[selectedTemplate];
  const { body: entityBody, category, description, objectType } = currentEntity || {};
  const bodyObj = getParsedTemplateBody(entityBody);
  const { body, category: fileCategory, subject = UNICODE.NBSP } = bodyObj;

  const categoryForDisplay = category || fileCategory;
  const displayTemplateCategory = EDITOR_TEMPLATE_CATEGORIES.find(item => item.id === categoryForDisplay)?.value;
  const displayObjectType = TEMPLATE_OBJECT_TYPE_MAP.get(objectType);
  const displayTemplateVariant = isEmail ? 'Email' : 'Texting';

  const updateDefaultBody = str => {
    const cleanBody = isEmail ? cleanAndDeserializeForSlate(str) : convertAndDeserializeTextMessageForSlate(str);
    setDefaultBody(cleanBody);
  };

  const switchTemplate = (dir = 'next') => {
    const newTemplate = getNextPrevEntityFromCollection(
      orderedTemplates,
      selectedTemplateRef.current,
      orderedTemplates.indexOf(selectedTemplateRef.current),
      dir
    );

    navigate(`${url}/view/${newTemplate}${location.search}`);
  };

  const startEndCheck = dir => {
    return orderedTemplates?.[dir === 'prev' ? 0 : orderedTemplates.length - 1] === selectedTemplateRef.current;
  };

  const handleKeypress = e => {
    const { target, which: keycode } = e;
    const isElementForEntry = isElementForDataEntry(target);

    if (isElementForEntry) {
      // we don't want to enable keypress behaviours for form elements.
      return;
    }

    const { NEXT, PREV } = KEYCODE_MAP;
    const isObservedKey = keycode === NEXT || keycode === PREV;

    if (!isObservedKey) {
      return;
    }

    if (keycode === NEXT) {
      switchTemplate('next');
    } else if (keycode === PREV) {
      switchTemplate('prev');
    }
  };

  useEffect(() => {
    const orderedCategory = Object.keys(templateCategories).reduce((acc, key) => {
      templateCategories[key].templates.forEach(template => {
        acc.push(template.fileId);
      });

      return acc;
    }, []);

    setOrderedTemplates(orderedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeypress, false);

    return () => {
      window.removeEventListener('keydown', handleKeypress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderedTemplates]);

  useEffect(() => {
    // When the url changes.
    setSelectedTemplate(selectedTemplateProp);
    updateDefaultBody(null);
    setAIContent(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateProp]);

  useEffect(() => {
    // When the selectedTemplate changes.
    if (body) {
      updateDefaultBody(body);
      return;
    }

    if (!selectedTemplate) {
      return;
    }

    setIsLoading(true);

    dispatch(getTemplateById(selectedTemplate, { objectType, subType: isEmail ? 1 : 2 }))
      .then(data => {
        const { body: entityBody } = data || {};
        const { body } = getParsedTemplateBody(entityBody);
        updateDefaultBody(body);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body, dispatch, objectType, selectedTemplate]);

  const paraphraseTemplate = () => {
    const newMessages = [...getInitialMessages(), { role: 'user', content: body }];

    try {
      dispatch(getResponse({ messages: newMessages, user: userId, type: variant }, setIsLoading)).then(
        ({ response, conversationId }) => {
          const newContent = response.content;
          setAIContent(cleanAndDeserializeForSlate(newContent));

          dispatch(updateMessages(newContent, 'template', conversationId));
        }
      );
    } catch (error) {
      console.error('Error getting response from AI:', error);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        {templateCategories &&
          Object.keys(templateCategories).map(key => {
            const category = templateCategories[key];
            return (
              <TemplateCategory key={key} category={category} selectedTemplate={selectedTemplate} variant="simple" />
            );
          })}
      </div>
      <div id="preview" className={styles.previewContainer}>
        <div className={styles.preview}>
          <div className={styles.header}>
            <h2 className={documentStyles.h2}>{description}</h2>
            <ButtonGroup variant="icon">
              <Button
                ariaLabel="Previous"
                icon="arrowleft"
                data-direction="prev"
                disabled={startEndCheck('prev')}
                onClick={() => switchTemplate('prev')}
                size="m"
              />
              <Button
                ariaLabel="Next contact"
                icon="arrowright"
                data-direction="next"
                disabled={startEndCheck('next')}
                onClick={() => switchTemplate('next')}
                size="m"
              />
            </ButtonGroup>
          </div>
          {isEmail && (
            <FormRow>
              <FormFieldReadOnly id={subject} label="Email subject" value={subject} />
            </FormRow>
          )}
          <FormRow>
            <TagGroup>
              <Tag label={displayTemplateCategory} />
              <Tag label={displayObjectType} />
              <Tag label={displayTemplateVariant} />
            </TagGroup>{' '}
          </FormRow>
          <hr className={documentStyles.hr} />
          {AIContent && (
            <div className={styles.aiActionBar}>
              <Tag label={'Original'} className={styles.aiTag} />
              <Button
                ariaLabel="Customize Template"
                disabled={isLoading}
                icon="fileCreate"
                label="Customize Template"
                labelShort="Customize"
                styleType="primary"
                url={`${url}/add/${selectedTemplate}${location.search}`}
              />
            </div>
          )}
          <Editor
            className={styles.editor}
            defaultBody={defaultBody}
            editorPortal="dialog"
            isDisabled={true}
            mode="preview"
          />
          {AIContent && (
            <>
              <hr className={documentStyles.hr} />
              <div className={styles.aiActionBar}>
                <Tag label={'AI Rewrite'} color={COLOR_GREEN_LIGHT} className={styles.aiTag} />
                <Button
                  ariaLabel="Use AI Rewrite"
                  label="Use AI Rewrite"
                  icon="aiAuthor"
                  styleType="white"
                  url={`${url}/ai/${location.search}`}
                  data-cy={buildTestId(AI_TEST_ID, 'AIUseRewrite')}
                />
              </div>
              <Editor defaultBody={AIContent} editorPortal="dialog" isDisabled={true} mode="preview" />
            </>
          )}

          <Loading loading={isLoading} className={styles.loading} />
        </div>
        {showCustomizeButton && (
          <FormFooter loading={isLoading} className={styles.footer}>
            <Button ariaLabel="Close preview" label="Cancel" styleType="white" url={`${url}${location.search}`} />
            {isAIEulaValid && (
              <Button
                ariaLabel="AI Rephrase"
                label="AI Rephrase"
                icon="aiAuthor"
                styleType="secondary"
                onClick={paraphraseTemplate}
                data-cy={buildTestId(AI_TEST_ID, 'AIRephraseButton')}
              />
            )}
            {!AIContent && (
              <Button
                ariaLabel="Customize Template"
                disabled={isLoading}
                icon="fileCreate"
                label="Customize Template"
                labelShort="Customize"
                styleType="primary"
                url={`${url}/add/${selectedTemplate}${location.search}`}
                data-cy={buildTestId('templateLibrary', 'customizeTempplate')}
              />
            )}
          </FormFooter>
        )}
      </div>
    </div>
  );
};

TemplateLibrary.propTypes = {
  selectedTemplate: string,
  templates: object,
  templateCategories: object,
  variant: oneOf(['email', 'texting'])
};
