import React from 'react';

import { BILLING } from './';

export const PROBATION_TITLE = 'Your account is in probation';

export const PROBATION_MESSAGE = (
  <>
    <strong>{PROBATION_TITLE}</strong>
    <div>
      For some reason, we were unable to process the most recent payment for your Top Producer solutions. <br />
      To resolve this matter, please click on the Update Payment button below to make a payment. If you need assistance,
      please contact our billing team at {BILLING.PHONE} during the following times: Monday to Friday, 7:30 AM to 4:00
      PM PST.
    </div>
  </>
);
export const PROBATION_MESSAGE_TEAM = (
  <>
    <strong>{PROBATION_TITLE}</strong>
    <div>
      For some reason, we were unable to process the most recent payment for your Top Producer solutions. To resolve
      this matter, please contact our billing team at {BILLING.PHONE} during the following times: Monday to Friday, 7:30
      AM to 4:00 PM PST.
    </div>
  </>
);

export const SUSPENSION_MESSAGE = (
  <>
    <div>
      Your account is now greater than 15 days past due and has been suspended. To resolve this matter, please click on
      the Update Payment button below to make a payment. If you need assistance, please contact our billing team
      at&nbsp;{BILLING.PHONE} during the following times: Monday to Friday, 7:30 AM to 4:00 PM PST.
    </div>
    <div>
      You will not be able to use any Top Producer solutions until full payment is received.
      <br />
      <strong>Your account will also continue to bill until we hear from you.</strong>
    </div>
  </>
);
export const SUSPENSION_MESSAGE_RA = (
  <>
    <div>
      Your access to Top Producer has been suspended by the account owner. Please contact the account owner in your Top
      Producer team or partnership to re-activate your account.
    </div>
  </>
);

export const SUSPENSION_MESSAGE_TEAM = (
  <>
    <div>
      Your account is now greater than 30 days past due and is no longer accessible. To resolve this matter, please
      contact our billing team at&nbsp;{BILLING.PHONE} during the following times: Monday to Friday, 7:30 AM to 4:00 PM
      PST.
    </div>
    <div>
      You will not be able to use any Top Producer solutions until full payment is received.
      <br />
      <strong>Your account will also continue to bill until we hear from you.</strong>
    </div>
  </>
);

export const SUSPENSION_TITLE = 'Your account is suspended';

export const CLOSED_MESSAGE = (
  <>
    <div>
      Your account is now greater than 30 days past due and is no longer accessible. To resolve this matter, please
      click on the Update Payment button below to make a payment. If you need assistance, please contact our billing
      team at&nbsp;{BILLING.PHONE} during the following times: Monday to Friday, 7:30 AM to 4:00 PM PST.
    </div>
    <div>
      You will not be able to use any Top Producer solutions until full payment is received.
      <br />
      <strong>Your account will also continue to bill until we hear from you.</strong>
    </div>
  </>
);
export const CLOSED_MESSAGE_TEAM = (
  <>
    <div>
      Your account is now greater than 30 days past due and is no longer accessible. To resolve this matter, please
      contact our billing team at&nbsp;{BILLING.PHONE} during the following times: Monday to Friday, 7:30 AM to 4:00 PM
      PST.
    </div>
    <div>
      You will not be able to use any Top Producer solutions until full payment is received.
      <br />
      <strong>Your account will also continue to bill until we hear from you.</strong>
    </div>
  </>
);

export const CLOSED_TITLE = 'Your account is inaccessible';
