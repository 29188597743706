/** @module */

export const LEAD_ALERT_SAVE = 'LEAD_ALERT_SAVE';

export const initialState = {
  receiveLeadAlertEmail: false,
  recipientEmail: '',
  receiveLeadAlertText: false,
  recipientPhone: ''
};

/**
 * The lead alert preferences reducer redux reducer.
 * @param {Object} state - the current state of the user store.
 * @param {Object} action - the action to take on the user store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - Request data to save to the store.
 */
export const leadAlertReducer = (state = initialState, action = {}) => {
  const { data } = action;
  switch (action.type) {
    case LEAD_ALERT_SAVE:
      return {
        ...state,
        ...data
      };
    default:
      return state;
  }
};
