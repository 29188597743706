import React from 'react';
import { bool, oneOf, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import camelCase from 'camelcase';
import classnames from 'classnames';

import { showDrawer } from '../../actions/drawer';
import { Icon } from '../';

import '@reach/menu-button/styles.css';
import styles from './ComposeButton.css';
import buttonStyles from './Button.css';

export const ComposeButton = props => {
  const { isTextingAllowed, toolTip = 'Compose', tooltipPos = 'below' } = props;
  const dispatch = useDispatch();

  const openCompose = drawerType => {
    dispatch(showDrawer({ drawerType }));
  };

  const menuItems = [
    {
      ariaLabel: 'Add Note',
      id: 'notesForm',
      icon: 'noteAdd',
      label: 'Note',
      onSelect: () => openCompose('notesForm')
    },
    {
      ariaLabel: 'Compose Email',
      id: 'emailForm',
      icon: 'email',
      label: 'Email',
      onSelect: () => openCompose('emailForm')
    },
    { ariaLabel: 'Send Text', id: 'textForm', icon: 'texting', label: 'Text', onSelect: () => openCompose('textForm') },
    { ariaLabel: 'Add Task', id: 'taskForm', icon: 'addtask', label: 'Task', onSelect: () => openCompose('taskForm') },
    {
      ariaLabel: 'Send Snapshot',
      id: 'snapshotForm',
      icon: 'snapshot',
      label: 'Snapshot',
      onSelect: () => openCompose('snapshotForm')
    },
    {
      ariaLabel: 'Create Wrap-up',
      id: 'wrapupForm',
      icon: 'wrapup',
      label: 'Wrap-up',
      onSelect: () => openCompose('wrapupForm')
    }
  ];

  const buttonClasses = classnames({
    [styles.button]: true,
    [buttonStyles[`${tooltipPos}TooltipPos`]]: !!tooltipPos
  });

  return (
    <Menu>
      <MenuButton className={buttonClasses} aria-label={toolTip} data-cy="composeButton">
        <Icon name="edit" size="m" />
      </MenuButton>
      <MenuList className={styles.menuList} portal={false}>
        {menuItems.map(item => {
          const { ariaLabel, icon, id, onSelect } = item;

          if (!isTextingAllowed && id === 'textForm') {
            // We filter out the texting button for non-USA agents.
            return null;
          }

          return (
            <MenuItem
              data-cy={camelCase(ariaLabel)}
              key={id}
              onSelect={onSelect}
              className={styles.menuItem}
              aria-label={ariaLabel}
            >
              <Icon name={icon} size="m" />
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

ComposeButton.propTypes = {
  isTextingAllowed: bool,
  toolTip: string,
  tooltipPos: oneOf(['below', 'above', 'left', 'right'])
};
