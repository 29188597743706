import React from 'react';
import { bool, func, object, oneOf, string } from 'prop-types';
import classnames from 'classnames';

import { Card, ContactCardBasic, ContactCardFollowUp } from './';

import { getParamFromSearch } from '../../utils';

import styles from './ContactCard.css';
import { checkIsFromDashboard } from '../../utils/urls';

export const ContactCard = props => {
  const {
    className,
    data,
    location,
    handleEntitySelect,
    handleFollowup,
    handleSkipFollowup,
    handleClick,
    isChecked,
    variant = 'default'
  } = props;

  if (!data) {
    return null;
  }

  const { id } = data;

  const classes = classnames({
    [styles.contactCard]: !className,
    [className]: !!className
  });

  const searchParam = getParamFromSearch(location.search, 'q');
  const isFollowup = searchParam === 'followup' || variant === 'followup';

  const isFromDashboard = checkIsFromDashboard(location.pathname);

  const url = !isFromDashboard ? `/contacts/${id}${location.search}` : `view/contacts/${id}${location.search}`;

  return (
    <Card url={url} className={classes} isFollowup={isFollowup} onClick={handleClick}>
      {isFollowup ? (
        <ContactCardFollowUp
          data={data}
          location={location}
          handleFollowup={handleFollowup}
          handleSkipFollowup={handleSkipFollowup}
        />
      ) : (
        <ContactCardBasic
          data={data}
          handleEntitySelect={handleEntitySelect}
          isChecked={isChecked}
          location={location}
          variant={variant}
        />
      )}
    </Card>
  );
};

ContactCard.propTypes = {
  className: string,
  data: object.isRequired,
  handleEntitySelect: func,
  handleFollowup: func,
  handleSkipFollowup: func,
  isChecked: bool,
  location: object.isRequired,
  variant: oneOf(['default', 'followup', 'dashboard'])
};
