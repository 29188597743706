export const SEND_STATUS_MAP = new Map([
  [0, 'Undefined'],
  [1, 'Not yet scheduled'],
  [2, 'Sending'],
  [3, 'Sending'],
  [301, 'Sending'],
  [302, 'Sending'],
  [303, 'Sending'],
  [4, 'Sending'],
  [5, 'Done'],
  [6, 'Failed'],
  [10, 'Template issue'],
  [10001, 'Missing template'],
  [10002, 'Template has been deleted'],
  [10003, 'Incorrect template type'],
  [10004, 'Cannot read template file'],
  [10005, 'Cannot parse the template'],
  [11, 'Unable to retrieve contact information'],
  [11001, 'Task is not assigned to a user'],
  [11006, 'The contact is unsubscribed'],
  [11102, 'No email integration setup'],
  [11105, 'Contact does not have an email address'],
  [11107, "The contact's email is blocked by TPX"],
  [11108, "The contact's email is blocked by the vendor"],
  [11202, 'No texting setup'],
  [11203, 'Texting unavailable for non-US agents'],
  [11205, 'Contact does not have a mobile phone number'],
  [11206, 'Texting unavailable for non-US contacts'],
  [11207, "The text message won't be sent as this phone number has not opted in to receive text messages."],
  [11208, "The contact's phone is blocked by the vendor"],
  [12, 'Sending'],
  [13, 'Sending'],
  [14, 'Sending'],
  [15, 'Sent successfully'],
  [16, 'Failed'],
  [17, 'Failed'],
  [171401, 'Could not verify access credentials. Reauthorize your email account in settings.'],
  [18, 'Failed']
]);

// We only 1 contact for initial release
export const TEXT_TASK_CONTACT_LIMIT = 1;

export const MASS_ACTION_LIMIT = 1000;
