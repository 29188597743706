export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_TEMPLATE_MENU_INDEX = 'SET_TEMPLATE_MENU_INDEX';
export const SET_CONTACT = 'SET_CONTACT';
export const SET_RELATIONSHIP = 'SET_RELATIONSHIP';
export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE';
export const SET_EDITOR_METHODS = 'SET_EDITOR_METHODS';
export const SET_CURRENT_MODE = 'SET_CURRENT_MODE';

export const reducer = (state, action) => {
  const { type, value } = action;
  switch (type) {
    case SET_TEMPLATES:
      return {
        ...state,
        templates: value,
        templateCount: Object.keys(value).length
      };
    case SET_TEMPLATE_MENU_INDEX:
      return {
        ...state,
        templateMenuIndex: value.templateMenuIndex,
        templateCount: value.templateCount != null ? value.templateCount : state.templateCount
      };
    case SET_CONTACT:
      return {
        ...state,
        contact: value
      };
    case SET_RELATIONSHIP:
      return {
        ...state,
        relationship: value
      };
    case SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: value
      };
    case SET_EDITOR_METHODS:
      return {
        ...state,
        ...value
      };
    case SET_CURRENT_MODE:
      return {
        ...state,
        mode: value
      };
    default:
      return state;
  }
};
