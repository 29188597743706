/** @module */

import { SERVER_STATUS_LOADING, SERVER_STATUS_SUCCESS, SERVER_STATUS_FAIL } from '../reducers/serverStatus';
import { request, requestError } from '../utils';

/**
 * Checks and sets the server status
 */
export const checkIfServerIsOnline = () => {
  const checkOnlineRequestOptions = {
    baseUrlKey: 'api',
    forceRefresh: true,
    path: 'tp8istatus' // Note: Change path to tp8istatus/false to force into maintenace for testing.
  };

  return async dispatch => {
    dispatch({
      type: SERVER_STATUS_LOADING
    });

    try {
      const data = await request(checkOnlineRequestOptions);
      if (data.status === 200) {
        dispatch({
          type: SERVER_STATUS_SUCCESS
        });
      }
    } catch (error) {
      // When we do get a response back but it's a 404 or 503
      // This is tricky since this endpoint runs on the same machine as the actual servers do.
      // We won't get a response back if the server is actually down.
      if (!error.response || error.response.status === 503 || error.response.status === 404) {
        dispatch({
          type: SERVER_STATUS_FAIL
        });
      } else if (error.request) {
        // The request was made but there's no response.
        requestError({ ...error, message: 'Server Status Endpoint Error' }, dispatch);
      }
    }
  };
};
