import React from 'react';
import formToggleStyles from '../../components/FormToggle/FormToggle.css';
import tabContainerStyles from '../../components/Tab/TabContainer.css';
import { string } from 'prop-types';
import { Fragment } from 'react';
import { Dropdown, FormLabel, FormRadio, FormRadioGroup, FormToggle, Icon } from '../../components';
import {
  parseParamsStr,
  serializeToParamsStr,
  TASK_TABS_WITH_DATE_RANGE,
  TASK_TYPES,
  TASK_TYPES_MAP
} from '../../utils';
import { navigate } from '@reach/router';
import { checkTextingEnabled } from '../../utils/texting';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import styles from './Tasks.css';
import { CustomDateRangeForm } from './CustomDateRangeForm';
import { setTaskLastSearchedDate } from '../../actions/tasks';
import { getAssignedToOptions, getAssignedToValue } from '../../utils/team';
import { useEffect } from 'react';

export const TaskFilter = props => {
  const { location } = props;
  const { search, pathname } = location;
  const isTasksImportantDates = pathname === '/tasks/important-dates';

  const mountedParams = parseParamsStr(search) || {};
  const {
    due: paramDue = 'today',
    complete,
    incomplete,
    assignedTo: mountedAssignTo,
    activityType: mountedActivityType
  } = mountedParams;
  const due = isTasksImportantDates ? null : paramDue;
  //   const selectedTaskTypeId = getParamFromSearch(search, 'activityType');
  const mountedComplete = complete ? true : false;
  const mountedInComplete = incomplete ? true : false;

  const selectedTaskType = TASK_TYPES_MAP.get(parseInt(mountedActivityType));

  const userProfile = useSelector(state => state.userProfile);
  const { userId } = useSelector(state => state.user);
  const settings = useSelector(state => state.settings);
  const team = useSelector(state => state.team);

  const showText = checkTextingEnabled(settings, userProfile);

  const [completeIsActive, setCompleteIsActive] = useState(mountedComplete);
  const [incompleteIsActive, setIncompleteIsActive] = useState(mountedInComplete);
  const [currentTab, setCurrentTab] = useState(due);
  //   const [currentAssignedToUserId, setCurrentAssignedToUserId] = useState(mountedAssignTo);

  const teamList = getAssignedToOptions(team, 'all');
  teamList.unshift({ id: 'all', value: 'All' });

  const dispatch = useDispatch();

  const showDateRangeSection = TASK_TABS_WITH_DATE_RANGE.includes(currentTab);

  useEffect(() => {
    setCurrentTab(due);

    if (due === 'overdue') {
      setCompleteIsActive(false);
    }
  }, [due]);

  // For assigning calendar path if there is none
  useEffect(() => {
    const currentParams = parseParamsStr(search);
    const { complete, incomplete } = currentParams || {};

    const isComplete = complete === 'true';
    // Set incomplete to true on default
    const isIncomplete = incomplete == null ? true : incomplete === 'true';

    setCompleteIsActive(isComplete);
    setIncompleteIsActive(isIncomplete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  if (isTasksImportantDates) {
    return null;
  }
  const handleActivityTypeChange = e => {
    const { location } = props;

    const activityType = TASK_TYPES[e.target.value].value;
    const currentParams = parseParamsStr(location.search) || {};
    const newParams = { ...currentParams, activityType };
    // we need to clean away a null activityType
    if (activityType === 0) {
      delete newParams.activityType;
    }
    const newParamsStr = serializeToParamsStr(newParams);
    navigate(`/tasks?${newParamsStr}`);
  };

  const getOppositeLabel = label => {
    const newLabel = label === 'complete' ? 'incomplete' : 'complete';

    return newLabel;
  };

  const handleTaskStatusChange = (value, label) => {
    // label: `complete` or `incomplete`
    const currentParams = parseParamsStr(location.search) || {};
    const { [label]: oldParams, ...updatedParams } = currentParams;

    updatedParams[label] = value.toString();

    const { complete: updatedComplete = 'false', incomplete: updatedIncomplete = 'true', ...newParams } = updatedParams;

    // If both the updated values of complete and incomplete are unchecked, we check the previously unchecked,
    // leaving the newly unchecked as unchecked. Doing so keeps at least one toggle checked.
    if (updatedComplete === 'false' && updatedIncomplete === 'false') {
      const oppositeLabel = getOppositeLabel(label);
      newParams[oppositeLabel] = 'true';

      const swappedLabel = getOppositeLabel(oppositeLabel);
      newParams[swappedLabel] = 'false';
    } else {
      newParams.complete = updatedComplete;
      newParams.incomplete = updatedIncomplete;
    }

    const hasParams = Object.keys(newParams).length > 0;
    const newParamsStr = hasParams ? `?${serializeToParamsStr(newParams)}` : '';

    navigate(`${location.pathname}${newParamsStr}`);
  };

  const handleDateChange = (dateFrom, dateTo) => {
    dispatch(
      setTaskLastSearchedDate({
        dateFrom,
        dateTo
      })
    );
  };

  const handleFilterByAssignedTo = e => {
    const { value } = e.target;
    const filterId = teamList.filter(item => item.value === value)[0].id;
    const currentParams = parseParamsStr(location.search) || {};
    const newParams = { ...currentParams, assignedTo: filterId };
    const newParamsStr = serializeToParamsStr(newParams);
    navigate(`/tasks?${newParamsStr}`);
  };

  return (
    <Fragment>
      <div className={tabContainerStyles.filterContainer}>
        <section className={tabContainerStyles.filterSection}>
          <FormLabel htmlFor="activityTypeAll" className={tabContainerStyles.filterLabel}>
            Task type
          </FormLabel>
          <FormRadioGroup name="activityType">
            <FormRadio
              id="activityTypeAll"
              label="All"
              value="all"
              title="All tasks"
              onChange={handleActivityTypeChange}
              checked={!selectedTaskType}
            />
            <FormRadio
              id="activityTypeAppointment"
              icon="appointment"
              value="appointment"
              title="Appointments"
              onChange={handleActivityTypeChange}
              checked={selectedTaskType === 'appointment'}
              size="s"
            />
            <FormRadio
              id="activityTypeCall"
              icon="call"
              value="call"
              title="Calls"
              onChange={handleActivityTypeChange}
              checked={selectedTaskType === 'call'}
              size="s"
            />
            <FormRadio
              id="activityTypeTodo"
              icon="todo"
              value="todo"
              title="To Dos"
              onChange={handleActivityTypeChange}
              checked={selectedTaskType === 'todo'}
              size="s"
            />
            <FormRadio
              id="activityTypeEmail"
              icon="email"
              value="tpxEmail"
              title="Emails"
              onChange={handleActivityTypeChange}
              checked={selectedTaskType === 'tpxEmail'}
              size="s"
            />
            {showText ? (
              <FormRadio
                id="activityTypeText"
                icon="texting"
                value="texting"
                title="Texts"
                onChange={handleActivityTypeChange}
                checked={selectedTaskType === 'texting'}
                size="s"
              />
            ) : (
              <></>
            )}
            <FormRadio
              id="activityTypeTransactionTask"
              icon="transaction"
              value="listingTodo"
              title="Transaction To Dos"
              onChange={handleActivityTypeChange}
              checked={selectedTaskType === 'listingTodo'}
              size="s"
            />
          </FormRadioGroup>
        </section>
        <section className={tabContainerStyles.filterSection}>
          <FormLabel htmlFor="toggleIsCompleted" className={tabContainerStyles.filterLabel}>
            Task status
          </FormLabel>
          {due !== 'overdue' ? (
            <div className={formToggleStyles.toggleGroup}>
              <FormToggle
                id="toggleComplete"
                ariaLabel="Complete"
                label="Complete"
                isChecked={completeIsActive}
                changeHandler={value => handleTaskStatusChange(value, 'complete')}
                className={styles.formToggle}
              />
              <FormToggle
                id="toggleIncomplete"
                ariaLabel="Incomplete"
                label="Incomplete"
                isChecked={incompleteIsActive}
                changeHandler={value => handleTaskStatusChange(value, 'incomplete')}
                className={styles.formToggle}
              />
            </div>
          ) : (
            <div className={styles.readonlyLabel}>
              <Icon name="check" isColored={true} size="s" />
              Incomplete
            </div>
          )}
        </section>
        {showDateRangeSection && (
          <section className={tabContainerStyles.filterSection}>
            <FormLabel htmlFor="startDate" className={tabContainerStyles.filterLabel}>
              Date range
            </FormLabel>
            <CustomDateRangeForm location={location} setLastSearchedDate={handleDateChange} />
          </section>
        )}
        <section className={tabContainerStyles.filterSection}>
          <FormLabel htmlFor="assignedTo" className={tabContainerStyles.filterLabel}>
            Assigned to
          </FormLabel>
          <Dropdown
            value={getAssignedToValue(teamList, mountedAssignTo, userId)}
            id="assignedTo"
            items={teamList}
            onChange={handleFilterByAssignedTo}
            size="auto"
          />
        </section>
      </div>
    </Fragment>
  );
};

TaskFilter.propTypes = {
  className: string
};
