/** @module */

export const WRAPUP_SET = 'WRAPUP_SET';
export const WRAPUP_CLEAR = 'WRAPUP_CLEAR';

export const initialState = {
  task: null,
  contactId: null,
  contactStatus: null,
  reminderId: null,
  isOpen: false,
  shouldUpdateTasksForCalendar: false,
  wrapupActivity: null
};

/**
 * The message redux reducer.
 * @param {Object} state - the current state of the wrapup store.
 * @param {Object} action - the action to take on the wrapup store
 * @param {String} [action.type=default] - the action to take.
 * @param {Boolean} [action.isOpen=false] - A boolean to show the wrapup.
 */
export const wrapupReducer = (state = initialState, action = {}) => {
  const { data, type = 'default' } = action;
  const {
    task = null,
    contactId = null,
    contactStatus = null,
    isOpen = true,
    importantDate = null,
    shouldUpdateTasksForCalendar = false,
    wrapupActivity = null
  } = data || {};

  switch (type) {
    case WRAPUP_SET:
      return {
        ...state,
        task,
        contactId,
        contactStatus,
        isOpen,
        importantDate,
        shouldUpdateTasksForCalendar,
        wrapupActivity
      };
    case WRAPUP_CLEAR:
      return initialState;
    default:
      return state;
  }
};
