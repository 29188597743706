/** @module */

export const DIALOG_OPEN = 'DIALOG_OPEN';
export const DIALOG_CLEAR = 'DIALOG_CLEAR';

export const initialState = {
  isOpen: false
};

/**
 * The dialog redux reducer.
 * @param {Object} state - the current state of the dialog store.
 * @param {Object} [action.type=default] - the action to take on the dialog store
 */
export const dialogReducer = (state = initialState, action = {}) => {
  const { type = 'default' } = action;

  switch (type) {
    case DIALOG_OPEN:
      return {
        ...state,
        isOpen: true
      };
    case DIALOG_CLEAR:
      return initialState;
    default:
      return state;
  }
};
