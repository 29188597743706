import { getArrayOfEntities, sortArrayOfObjects } from './';
import { clearTempListings, getPropertyById } from '../actions/propertyInsights';

export const MLS_CREDS_ERROR_MESSAGE = 'Failed to load MLS credentials, please try again later.';
export const MLS_SOURCES_ERROR_MESSAGE = 'Failed to load MLS sources, please try again later.';

/**
 * Gets the proper display name for an MLS board.
 * @param {Object} source - An MLS board object.
 */
export const getMLSBoardDisplayName = source => {
  const { provider, mlsName } = source || {};
  const cleanProvider = !provider ? null : provider.trim();
  const cleanMlsName = !mlsName ? null : mlsName.trim();

  return cleanProvider || cleanMlsName;
};

/**
 * Gets a sorted list of MLS boards.
 * @param {Array} sources - An array of source objects.
 */
export const getSortedMLSBoards = sources => {
  if (!sources) {
    return [];
  }

  const sortedBoardsList = sortArrayOfObjects(
    getArrayOfEntities(sources).map(source => {
      const cleanMlsName = getMLSBoardDisplayName(source);
      return {
        ...source,
        title: cleanMlsName
      };
    }),
    {
      sortKey: 'title', // Typically used as the display value in the Dropdown component.
      sortType: 'alpha'
    }
  );

  return sortedBoardsList;
};

/**
 * Check if the user has MLS board credentials set up.
 * @param {Object} credentials MLS credentials state from redux store.
 */
export const hasMlsCredentials = credentials => {
  return Boolean(credentials && Object.keys(credentials).length > 0);
};

/**
 * Check if the user has only ONE MLS board credential set up.
 * @param {Object} credentials MLS credentials state from redux store.
 */
export const hasOneMlsCredential = credentials => {
  return Boolean(credentials && Object.keys(credentials).length === 1);
};

/**
 * Check if the user has MLS board credentials set up.
 * @param {Object} sources MLS sources state from redux store.
 */
export const hasMlsSources = sources => {
  return Boolean(sources && Object.keys(sources).length > 0);
};

/**
 * Get MLS board labels for display when given credential and source
 * @param {Object} sources MLS source from redux.
 * @param {Object} mlsCredentials MLS credentials state from redux store.
 */
export const getMLSBoardLabels = (sources, credentials) => {
  // Filter out mismatch source id from sources and credentials in case of data error
  const arrayOfCredentials = Object.values(credentials)
    .filter(i => Object.keys(sources).includes(i.source))
    .map(item => {
      const { source } = item;
      const sourceDetails = sources[source];
      const { provider, mlsName } = sourceDetails;

      return {
        id: source,
        value: source,
        mlsName,
        provider
      };
    });

  const mlsBoards = getSortedMLSBoards(arrayOfCredentials);

  return mlsBoards;
};

/**
 * Get Error Message from lookup
 * @param {boolean} showNoTempListingsError from redux store
 * @param {boolean} mlsSearchValue MLS board info used for searching
 */
export const getErrorMessage = (showNoTempListingsError, mlsSearchValue, lookupIsValid, inputType) => {
  if (!showNoTempListingsError && lookupIsValid) {
    return null;
  }

  if (!lookupIsValid) {
    if (inputType !== 'address') {
      return null;
    }

    return 'Address is required.';
  }
  if (mlsSearchValue) {
    return 'The MLS ID entered cannot be found in your MLS.';
  }

  return 'Address chosen is not in your MLS. Try again.';
};

export const handleAddressOrMLSChange = (e, dispatch, showNoTempListingsError, lookupIsValid) => {
  // e.preventDefault();
  if (showNoTempListingsError || !lookupIsValid) {
    dispatch(clearTempListings());
  }
};

export const handleTempInsightRemove = (e, dispatch, setLookupIsValid, setMlsSearchValue) => {
  e.preventDefault();
  dispatch(clearTempListings());
  setMlsSearchValue('');
  setLookupIsValid(true);
};

export const handleUpdateMLS = (e, dispatch, setMlsSearchValue, showNoTempListingsError, lookupIsValid) => {
  setMlsSearchValue(e.target.value);
  handleAddressOrMLSChange(e, dispatch, showNoTempListingsError, lookupIsValid);
};

export const handleSearchMLS = (e, dispatch, mlsSearchValue, mlsBoard, setListingPreview, setLookupIsValid) => {
  e.preventDefault();
  if (mlsSearchValue.trim().length === 0) {
    setLookupIsValid(false);
    return;
  }
  setListingPreview({ location: { address: { line: '' } } });
  dispatch(getPropertyById({ source: mlsBoard, mlsNumber: mlsSearchValue })).then(success => {
    setListingPreview(null);

    if (success) {
      setLookupIsValid(true);
    } else {
      setLookupIsValid(false);
    }
  });
};

/**
 * For mls board set up in TPX, check isValid. Checks if password works.
 * @param {Object} credential from redux
 */
export const checkMlsValidity = credential => {
  return Boolean(credential?.isValid);
};

/**
 * For mls board set up in TPX, check canAccessMlsData. Checks if MLS approves data usage.
 * @param {Object} credential from redux
 */
export const checkCanAccessMLSData = credential => {
  return Boolean(credential?.canAccessMlsData);
};

/**
 * Takes user's credentials, reduces out discontinued credentials no longer in sources list.
 * @param {Object} credentials user's MLS credentials.
 * @param {Object} sources the MLS sources list.
 * @returns {Object} the credentials with discontinued boards removed.
 */
export const reduceMlsCredentials = (credentials, sources) => {
  if (credentials === null) {
    return null; // Keep credentials null if null, for a mlsCredError check in TransactionForm.
  }
  const sourceKeys = sources ? Object.keys(sources) : [];
  const reducedCredentials = Object.keys(credentials).reduce(
    (acc, mls) => (sourceKeys.includes(mls) ? { ...acc, [mls]: credentials[mls] } : acc),
    {}
  );
  return reducedCredentials;
};
