/** @module */

/**
 * Utilities common to all reducers
 */

/**
 * Get the name of the group to be created corresponding to the input
 *
 * This function is used to organize the entities in a reducer store
 * when we want to ids are grouped by input
 *
 * @param {String?} input The input text
 * @returns {String|undefined} input in lowercase with no spaces, or undefined if input is not a string
 */
export const getGroupNameFromInput = input => input?.toLowerCase?.().replace(/\s/g, '');
