import React from 'react';
import { string } from 'prop-types';

export const Box = ({ as = 'div', children, ...props }) => {
  if (typeof children === 'function') {
    return children(props);
  }

  return React.createElement(as, props, children);
};

Box.propTypes = {
  as: string
};
