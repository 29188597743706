import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormLabel } from '../';

import styles from './FormFieldReadOnly.css';
import formFieldStyles from '../FormField/FormField.css';

export const FormFieldReadOnly = props => {
  const { className, disabled, id, label, value, ...attrs } = props;

  if (!value) {
    return null;
  }

  const classes = classnames({
    [formFieldStyles.field]: !className,
    [formFieldStyles.fullWidth]: !className,
    [className]: !!className
  });

  const valueClassName = classnames({
    [styles.readOnlyValue]: true,
    [styles.disabled]: disabled
  });

  return (
    <div className={classes}>
      <div className={formFieldStyles.labelWrap}>
        <FormLabel htmlFor={id}>{label}</FormLabel>
      </div>
      <div id={id} className={valueClassName} {...attrs}>
        {value}
      </div>
    </div>
  );
};

FormFieldReadOnly.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired
};
