import React, { useEffect } from 'react';
import { bool, func, number, string } from 'prop-types';
import classnames from 'classnames';
import styles from './FieldCounter.css';

/**
 * Counter the character given a max character limit, can be used as a standalone or within components like <FormField/>
 * @param {Boolean} props.isVisible Flag to determine whether this component is visible
 * @param {Number} props.maxLength The max character limit
 * @param {Number} props.currentLength Current character limit
 * @param {Function} props.onValidate Optional validation function that validate the corresponding field
 * @param {Function} props.fieldIsValid Optional isValid boolean used to compare before update
 */
export const FieldCounter = props => {
  const { isVisible, maxLength, currentLength = 0, className, onValidate, fieldIsValid } = props || {};

  const isValid = currentLength <= maxLength;
  useEffect(() => {
    if (fieldIsValid != null && isValid !== fieldIsValid && onValidate != null) {
      onValidate(isValid);
    }
  }, [isValid, onValidate, fieldIsValid]);

  if (!isVisible) {
    return null;
  }

  const classes = classnames({
    [styles.counter]: !className,
    [styles.warning]: currentLength >= maxLength * 0.8 && isValid,
    [styles.error]: !isValid,
    [className]: !!className
  });

  return <div className={classes}>{`${currentLength}/${maxLength}`}</div>;
};

FieldCounter.propTypes = {
  className: string,
  isVisible: bool.isRequired,
  maxLength: number.isRequired,
  currentLength: number,
  fieldIsValid: bool,
  onValidate: func
};
