import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormMessage, FormRow } from '../../components';
import { getErrorMessage, handleAddressOrMLSChange, handleTempInsightRemove } from '../../utils/mlsBoards';
import { LookupProperty } from '../Lookup';
import styles from '../PropertyInsightsForm/PropertyInsightsForm.css';

const LookupTypeForm = props => {
  const {
    handleLookupSelect,
    inputType,
    lookupIsValid,
    mlsBoard,
    mlsSearchValue,
    mlsStates,
    setLookupIsValid,
    setMlsSearchValue
  } = props;
  const { showNoTempListingsError } = useSelector(state => state.propertyInsights);
  const dispatch = useDispatch();
  const lookupErrorMessage = getErrorMessage(showNoTempListingsError, mlsSearchValue);

  return (
    <Fragment>
      <FormMessage message={lookupErrorMessage} className={styles.formMessage} />
      {inputType === 'address' && (
        <FormRow>
          <LookupProperty
            mlsBoard={mlsBoard}
            fieldIsValid={!showNoTempListingsError && lookupIsValid}
            filterBy={{
              key: 'state_code',
              matches: mlsStates
            }}
            inputThreshold={5}
            onChange={e => handleAddressOrMLSChange(e, dispatch, showNoTempListingsError, lookupIsValid)}
            onRemove={e => handleTempInsightRemove(e, dispatch, setLookupIsValid, setMlsSearchValue)}
            onSelect={handleLookupSelect}
          />
        </FormRow>
      )}
    </Fragment>
  );
};

export default LookupTypeForm;
