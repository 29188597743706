import React, { Fragment } from 'react';
import { number } from 'prop-types';

import { Icon } from '../../components/Icon';
import { capitalize, formatPriceWithCommas } from '../../utils/strings';
import { TRANSACTION_ALL_STATUSES } from '../../utils/transactions';

import styles from './TransactionStats.css';
import contactDetailStyles from '../Contacts/ContactDetail/ContactDetail.css';

export const TransactionStats = props => {
  const { projected, realized, status } = props;

  const showExpected = status < 4;
  const displayStatus = TRANSACTION_ALL_STATUSES.find(item => item.id === status)?.value.toLowerCase();

  return (
    <section className={styles.container}>
      <div className={contactDetailStyles.singleColumnSummaryGrid}>
        <label className={contactDetailStyles.summaryGridLabel}>
          <Icon name="closed" size="s" /> Actual commissions
        </label>
        <div className={contactDetailStyles.summaryGridValue}>
          <div className={styles.amount}>${formatPriceWithCommas(realized)}</div>
          <span className={styles.desc}>
            Earnings from <em className={styles.em}>closed</em> transactions in the current date range.
          </span>
        </div>
        {showExpected && (
          <Fragment>
            <label className={contactDetailStyles.summaryGridLabel}>
              <Icon name="billing" size="s" />
              {capitalize(displayStatus)} commissions
            </label>
            <div className={contactDetailStyles.summaryGridValue}>
              <div className={styles.amount}>${formatPriceWithCommas(projected - realized)}</div>
              <span className={styles.desc}>
                Expected earnings from <em className={styles.em}>{displayStatus}</em> transactions in the current date
                range.
              </span>
            </div>
            <label className={contactDetailStyles.summaryGridLabel}>
              <Icon name="moneytrend" size="s" /> Expected commissions
            </label>
            <div className={contactDetailStyles.summaryGridValue}>
              <div className={styles.amount}>${formatPriceWithCommas(projected)}</div>
              <span className={styles.desc}>
                Expected earnings from both <em className={styles.em}>closed</em> and{' '}
                <em className={styles.em}>{displayStatus}</em> transactions in the current date range.
              </span>
            </div>
          </Fragment>
        )}
      </div>
    </section>
  );
};

TransactionStats.propTypes = {
  projected: number.isRequired,
  realized: number.isRequired,
  status: number.isRequired
};
