import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './Form.css';

export const Form = React.forwardRef((props, ref) => {
  const { children, className, id, isNested = false, submitHandler, ...attrs } = props;

  if (!children) {
    return null;
  }

  const classes = classnames({
    [styles.form]: !className,
    [styles.isNested]: isNested,
    [className]: !!className
  });

  return (
    <form id={id} ref={ref} onSubmit={submitHandler} className={classes} {...attrs}>
      {children}
    </form>
  );
});

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  submitHandler: PropTypes.func
};
