import React from 'react';
import { bool, func, oneOf, string } from 'prop-types';
import classnames from 'classnames';

import styles from './FormCheckBox.css';

export const FormCheckBox = props => {
  const { className, disabled, id, isChecked, changeHandler, clickHandler, size = 'm', label, ...attrs } = props;

  const labelClasses = classnames({
    [styles.label]: !className,
    [styles.noLabel]: !label
  });

  const checkClasses = classnames({
    [styles.checkbox]: !className,
    [styles[`${size}Size`]]: true,
    [className]: !!className
  });

  const handleClick = e => {
    // We need to prevent the click from propagating.
    e.stopPropagation();

    if (clickHandler) {
      clickHandler();
    }
  };

  return (
    <label className={labelClasses}>
      <input
        id={id}
        type="checkbox"
        checked={isChecked}
        disabled={disabled}
        className={checkClasses}
        onChange={changeHandler}
        onClick={handleClick}
        {...attrs}
      />
      {label && <span className={styles.labelText}>{label}</span>}
    </label>
  );
};

FormCheckBox.propTypes = {
  changeHandler: func,
  className: string,
  clickHandler: func,
  disabled: bool,
  id: string.isRequired,
  isChecked: bool,
  label: string,
  size: oneOf(['s', 'm', 'l', 'em'])
};
