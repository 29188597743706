import React from 'react';
import { array, oneOf, oneOfType, string } from 'prop-types';
import classnames from 'classnames';

import styles from './FormLabel.css';

export const FormLabel = props => {
  const { children, className, htmlFor, required = false, size = 's', ...attrs } = props;

  if (!children) {
    return null;
  }

  const classes = classnames({
    [styles.label]: !className,
    [styles[`${size}Size`]]: true,
    [styles.isRequired]: required,
    [className]: !!className
  });

  return (
    <label htmlFor={htmlFor} className={classes} {...attrs}>
      {children}
    </label>
  );
};

FormLabel.propTypes = {
  children: oneOfType([array, string]),
  htmlFor: string.isRequired,
  size: oneOf(['xxs', 'xs', 's', 'm', 'l', 'xl'])
};
