/**
 * A Map of entity types.
 */
export const ENTITY_TYPES = {
  task: 1,
  closing: 2,
  listing: 3,
  contact: 4,
  insight: 5,
  transaction: 6
};

/**
 * A function that gets the entity type label from a given entity type ID.
 * @param {String} id - an entity id.
 */
export const getEntityTypeFromId = id => {
  const typedId = parseInt(id);

  return Object.keys(ENTITY_TYPES).find(key => ENTITY_TYPES[key] === typedId);
};
