import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, FormField, FormLabel, FormRow } from '../';
import { DEFAULT_STATES_LIST } from '../../data/states';

import styles from './SnapshotForm.css';
import formFieldStyles from '../FormField/FormField.css';

export const AddressForm = props => {
  const { city, disabled, formIsValid, isSeller, onChange, onSelectChange, state, streetName, zip } = props;

  return (
    <Fragment>
      <FormRow>
        <FormField
          id="streetName"
          label="Street Address"
          value={streetName.value || ''}
          maxLength="256"
          onChange={onChange}
          fieldIsValid={streetName.isValid}
          formIsValid={formIsValid}
          disabled={disabled}
          required={isSeller}
          size="l"
        />
      </FormRow>
      <FormRow>
        <FormField
          id="city"
          type="input"
          inputType="text"
          label="City"
          value={city.value || ''}
          maxLength="64"
          onChange={onChange}
          fieldIsValid={city.isValid}
          formIsValid={formIsValid}
          disabled={disabled}
          required={isSeller}
        />
        <div className={formFieldStyles.field}>
          <FormLabel htmlFor="state" required={isSeller}>
            State/Province
          </FormLabel>
          <Dropdown
            id="state"
            items={DEFAULT_STATES_LIST}
            className={styles.select}
            onChange={onSelectChange}
            defaultValue="Select a state..."
            value={state.value || ''}
            fieldIsValid={state.isValid}
            formIsValid={formIsValid}
            disabled={disabled}
            required={isSeller}
          />
        </div>
      </FormRow>
      {zip ? (
        <FormRow>
          <FormField
            aria-invalid={zip.isValid === false}
            id="zip"
            type="input"
            inputType="text"
            label="Zip/Postal Code"
            value={zip.value || ''}
            maxLength="32"
            fieldIsValid={zip.isValid}
            formIsValid={formIsValid}
            onChange={onChange}
            validationMessage="Invalid zip"
            disabled={disabled}
            required
          />
        </FormRow>
      ) : null}
    </Fragment>
  );
};

AddressForm.propTypes = {
  city: PropTypes.object,
  isSeller: PropTypes.bool,
  onChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  state: PropTypes.object,
  streetName: PropTypes.object,
  zip: PropTypes.object
};
