import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

export const LiveAgentHelmet = () => {
  const { profileId, userId } = useSelector(state => state.user);
  const userInfo = useSelector(state => state.userProfile.userInfo);
  const { isOpen: drawerIsOpen } = useSelector(state => state.drawer);
  const { isOpen: dialogIsOpen } = useSelector(state => state.dialog);
  // TODO: Replace below matchMedia with mobile hook once available.
  const isTabletAndUp = window.matchMedia('(min-width: 737px)').matches;

  if (!isTabletAndUp) {
    // We don't want to initialize LiveAgent if it is mobile.
    return null;
  }

  const prepopulatedPrechatFields =
    userInfo &&
    `{
    FirstName: "${userInfo.firstName}",
    LastName: "${userInfo.lastName}",
    Email: "${userInfo.agentEmail}"
  }`;

  const sessionVariables =
    userInfo &&
    `[{
    "label": "Agent Name",
    "value": "${userInfo.agentName}",
    "displayToAgent": true
    },
    {
    "label": "Email",
    "value": "${userInfo.agentEmail}",
    "displayToAgent": true
    },{
    "label": "Phone - Mobile",
    "value": "${userInfo.mobilePhone}",
    "displayToAgent": true
    },{
    "label": "Phone - Business",
    "value": "${userInfo.businessPhone}",
    "displayToAgent": true
    },{
    "label": "Profile ID",
    "value": "${profileId}",
    "displayToAgent": true
    },{
    "label": "User ID",
    "value": "${userId}",
    "displayToAgent": true
    },
    ]`;

  const displayChat = profileId && !dialogIsOpen && !drawerIsOpen;

  return (
    <Helmet>
      <style type="text/css">
        {`
        .embeddedServiceSidebarMinimizedDefaultUI.helpButton {
          background-color: #00396b !important;
          border-color: #00396b !important;
          border: #00396b !important;
        }
        .embeddedServiceSidebarMinimizedDefaultUI.helpButton:hover::before, .embeddedServiceSidebarMinimizedDefaultUI.helpButton:focus::before {
          content: " " !important;
          position: absolute !important;
          top: 0 !important;
          left: 0 !important;
          width: 100% !important;
          height: 100% !important;
          background-color: #000 !important;
          opacity: 0.2 !important;
          pointer-events: none !important;
        }
        .embeddedServiceSidebarMinimizedDefaultUI.helpButton:hover {
          background-color: #00396b !important;
          border-color: #00396b !important;
        }
        .embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:focus {
          background-color: #00396b !important;
        }
        [embeddedservice-chatheader_chatheader-host] {
          background-color: #00396b !important;
        }
        .embeddedServiceHelpButton .helpButton {
          height: 60px !important;
          bottom: 16px !important;
          right: 8px !important;
        }
        .embeddedServiceHelpButton .uiButton {
          min-width: 60px !important;
          max-width: 60px !important;
          min-height:60px !important;
          max-height:60px !important;
        }
        .embeddedServiceHelpButton .helpButton .uiButton {
          border-radius: 50% !important;
          padding-left:0px !important;
          margin-right:0px !important;
        }
        .embeddedServiceHelpButton .helpButton .helpButtonLabel {
          display:none !important;
        }
        .embeddedServiceHelpButton .embeddedServiceIcon::before {
          font-size:40px !important;
        }
        .embeddedServiceHelpButton .helpButton .uiButton:focus {
          outline: none !important;
        }
        .embeddedServiceHelpButton .embeddedServiceIcon {
          margin-right: 0px !important;
        }
        .embeddedServiceHelpButton .helpButtonEnabled .uiButton {
          display:none !important;
        }
        .embeddedServiceHelpButton .helpButton .helpButtonEnabled .uiButton {
          display:none !important;
        }
        .embeddedServiceIcon {
          padding-left:11px !important;
        }
        .embeddedServiceHelpButton .helpButtonLabel {
          display:none !important;
        }
        .embeddedServiceHelpButton .assistiveText {
          display:none !important;
        }
        .embeddedServiceHelpButton .message {
          display:none !important;
        }
        .embeddedServiceSidebarMinimizedDefaultUI {
          min-width: 60px !important;
          max-width: 60px !important;
          width: 60px !important;
          height: 60px !important;
          border-radius: 50% !important;
          bottom: 16px !important;
        }
        .embeddedServiceSidebarMinimizedDefaultUI .embeddedServiceIcon {
          margin-right: 9px !important;
        }
        .embeddedServiceIcon.x-small svg {
          width: 40px !important;
          height: 40px !important;
        }
        .embeddedServiceHelpButton .helpButton .helpButtonEnabled:hover::before, .embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus::before {
          opacity: 0.2 !important;
          border-radius: 50% !important;
        }
        .helpButton {
          display: ${displayChat ? 'initial' : 'none'};
        }
        ${
          !displayChat &&
          `.dockableContainer.showDockableContainer {
          display: none !important;
        }`
        };
        `}
      </style>

      <script type="text/javascript">
        {`
        const OFFLINE_MESSAGE = "<div style='padding:5px;text-align: left;'><h3>Sorry we missed you!</h3><p>Customer Care is available Monday to Friday, 7:30AM to 4PM (PST).</p><p>Please contact us again, or email us at: <a href='mailto:support@topproducer.com' target='_blank'>support@topproducer.com</a></p></div>";

        function waitForElm(selector) {
            return new Promise(resolve => {
                if (document.querySelector(selector)) {
                    return resolve(document.querySelector(selector));
                }

                const observer = new MutationObserver(mutations => {
                    if (document.querySelector(selector)) {
                        resolve(document.querySelector(selector));
                        observer.disconnect();
                    }
                });

                observer.observe(document.body, {
                    childList: true,
                    subtree: true
                });
            });
        }

        function detectIconChanges() {
            const icon = document.querySelector('.embeddedServiceIcon');
            const config = {
                attributes: true,
                childList: true,
                subtree: true
            };
            const iconObserver = new MutationObserver((mutation) => {
                if (icon && icon.style !== "display:inline-block;") {
                    icon.style = "display:inline-block;";
                }

                iconObserver.disconnect();
                iconObserver.observe(icon, config);
            });

            iconObserver.observe(icon, config);
        }

        function detectHelpButtonChanges() {
            const helpButton = document.querySelector('.embeddedServiceHelpButton');
            const config = {
                attributes: true,
                childList: true,
                subtree: true
            };
            const helpButtonObserver = new MutationObserver((mutation) => {
                const offlineSupportUI = document.querySelector('.offlineSupportUI');

                if (offlineSupportUI && offlineSupportUI.innerHTML !== OFFLINE_MESSAGE) {
                    offlineSupportUI.innerHTML = OFFLINE_MESSAGE;
                }
                helpButtonObserver.observe(helpButton, config);
            });
            helpButtonObserver.observe(helpButton, config);
        }

        waitForElm('.embeddedServiceHelpButton').then((elm) => {
            detectHelpButtonChanges();
            detectIconChanges();
        });
      `}
      </script>

      <style type="text/css">
        {`
        .embeddedServiceHelpButton .helpButton .uiButton {
          background-color: #00396b !important;
          font-family: "Arial", sans-serif !important;
        }
        .embeddedServiceHelpButton .helpButton .uiButton:focus {
          outline: 1px solid #00396b !important;
        }
        `}
      </style>

      <script type="text/javascript" src="https://service.force.com/embeddedservice/5.0/esw.min.js"></script>

      <script type="text/javascript">
        {`
        var initESW = function(gslbBaseURL) {
          embedded_svc.settings.displayHelpButton = true; //Or false
          embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'
          //embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
          //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)
          //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
          //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
          // Settings for Chat
          //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
          // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
          // Returns a valid button ID.
          //};
          //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
          //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
          //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

          embedded_svc.settings.enabledFeatures = ['LiveAgent'];
          embedded_svc.settings.entryFeature = 'LiveAgent';

          embedded_svc.settings.prepopulatedPrechatFields = ${prepopulatedPrechatFields};
          embedded_svc.settings.extraPrechatFormDetails = ${sessionVariables};

          embedded_svc.init(
              'https://topproducersoftwarecorp.my.salesforce.com',
              'https://topproducerservice.secure.force.com/liveAgentSetupFlow',
              gslbBaseURL,
              '00D5Y000002SyZd',
              'Top_Producer_X',
              {
                  baseLiveAgentContentURL: 'https://c.la3-c2-ia4.salesforceliveagent.com/content',
                  deploymentId: '5725Y000000UNub',
                  buttonId: '5735Y000000UOe7',
                  baseLiveAgentURL: 'https://d.la3-c2-ia4.salesforceliveagent.com/chat',
                  eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I5Y000000cZ0IUAU_182c62d5101',
                  isOfflineSupportEnabled: true
              }
          );
        };

        var HELP_BUTTON_CLASS = "embeddedServiceHelpButton";

        if (!window.embedded_svc) {
          var s = document.createElement('script');
          s.setAttribute('src', 'https://topproducersoftwarecorp.my.salesforce.com/embeddedservice/5.0/esw.min.js');
          s.setAttribute("defer", "");

          s.onload = function() {
            window.embedded_svc.handleMessage = function handleMessage(payload) {
              if (typeof this.messageHandlers[payload.method] !== 'function') {
                return null;
              }
              if(this.messageHandlers[payload.method]) {
                this.messageHandlers[payload.method](payload.domain, payload.data);
              } else {
                console.log("Unregistered method " + payload.method + " received in frame.");
              }
            };

            window.embedded_svc.loadScriptFromDirectory = function loadScriptFromDirectory(directory, name, scriptOnLoadFunction, isReleaseAgnostic) {
              if (!this.settings.loadedScripts) {
                this.settings.loadedScripts = [];
              }
              if (this.settings.loadedScripts.includes(name)) {
                return null;
              }

              this.settings.loadedScripts.push(name);

              var lowerCaseName = name.toLowerCase();
              var script = document.createElement("script");
              var baseURL = this.settings.gslbBaseURL;
              var scriptId = lowerCaseName.replace(".", "-") + "-script";

              script.id = scriptId;
              script.type = "text/javascript";
              script.src = [
                baseURL,
                "embeddedservice",
                isReleaseAgnostic ? undefined : this.settings.releaseVersion,
                directory,
                lowerCaseName + (this.settings.devMode ? "" : ".min") + ".js"
              ].filter(function(item) {
                // Filter out undefined items.
                return Boolean(item);
              }).join("/");

              if(scriptOnLoadFunction) script.onload = scriptOnLoadFunction;

              if (name == 'common') {
                  script.onload = function() {
                    scriptOnLoadFunction();
                      window.embedded_svc.utils.loadScriptFromUrl = function(url, onScriptLoad, onScriptError, elementId) {
                        var scriptElem = document.createElement("script");
                        if (!this.loadedScripts) {
                          this.loadedScripts = [];
                        }
                        if (this.loadedScripts.includes(url)) {
                          return null;
                        }
                        this.loadedScripts.push(url);

                        if(elementId) scriptElem.id = elementId;
                        scriptElem.type = "text/javascript";
                        if(onScriptLoad) scriptElem.onload = onScriptLoad;
                        if(onScriptError) scriptElem.onerror = onScriptError;
                        scriptElem.src = url;

                        document.body.appendChild(scriptElem);
                      };

                  }
              }

              document.body.appendChild(script);
            };
            window.embedded_svc.appendHelpButton = function appendHelpButton(isVisible) {
              var helpButton = document.createElement("div");
              var directionAttribute = "";

              helpButton.className = HELP_BUTTON_CLASS;
              if(this.isLanguageRtl(this.settings.language) && this.isDesktop()) {
                directionAttribute = RTL_DIRECTION_ATTRIBUTE;
              }

              helpButton.innerHTML =
                '<div class="helpButton"' + directionAttribute + '>' +
                '<button class="helpButtonEnabled uiButton" href="javascript:void(0)">' +
                '<span class="embeddedServiceIcon" aria-hidden="true" data-icon="&#59648;"></span>' +
                '<span class="helpButtonLabel" id="helpButtonSpan" aria-live="polite" aria-atomic="true">' +
                '<span class="assistiveText">' + (this.settings.defaultAssistiveText || "") + '</span>' +
                '<span class="message"></span>' +
                '</span>' +
                '</button>' +
                '</div>';

              if(!isVisible) {
                helpButton.style.display = "none";
              }

              if(this.settings.hasBottomTabBar) {
                helpButton.classList.add("embeddedServiceBottomTabBar");
              }

              this.settings.targetElement.appendChild(helpButton);

              this.setHelpButtonText(this.settings.defaultMinimizedText);

              if("ontouchstart" in document.documentElement) {
                [].slice.apply(document.querySelectorAll(".embeddedServiceHelpButton .uiButton")).forEach(function(element) {
                  element.classList.add("no-hover");
                });
              }

              this.onButtonStatusChange();
              this.settings.appendHelpButton = false;
            };
            ${userInfo && 'initESW(null);'}
          };

          document.body.appendChild(s);

        } else {
          initESW('https://service.force.com');
        }
      `}
      </script>
    </Helmet>
  );
};
