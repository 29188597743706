import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import '../../assets/icons.svg';
import styles from './Icon.css';

const IconText = props => {
  const { label } = props;

  if (!label) {
    return null;
  }

  return <span className={styles.label}>{label}</span>;
};

export const Icon = props => {
  const { className, isColored = false, label, name, size = 'm', isFilled = false, ...attrs } = props;

  if (!name) {
    return null;
  }

  const classes = classnames({
    [styles.icon]: true,
    [styles[`${name}Icon`]]: !!name && !!styles[`${name}Icon`], // This class isn't defined for every icon, so we don't want to add the class undefined.
    [styles[`${size}Size`]]: !!size,
    [styles.withLabel]: !!label,
    [styles[`${name}--clr`]]: !!isColored,
    [styles[`${name}--filled`]]: !!isFilled,
    [className]: !!className
  });

  return (
    <div className={classes} {...attrs}>
      <svg role="presentation" focusable="false" className={styles.svg}>
        <use xlinkHref={`#icons_${name}`} />
      </svg>
      {label && <IconText label={label} />}
    </div>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.oneOf([
    'close',
    'contact',
    'email',
    'emailsetup',
    'homeintel',
    'homeintelsettings',
    'loading',
    'call',
    'new',
    'engage',
    'future',
    'active',
    'closed',
    'inactive',
    'nonclient',
    'favorite',
    'followup',
    'search',
    'notes',
    'noteAdd',
    'error',
    'success',
    'calendar',
    'delete',
    'arrowright',
    'arrowleft',
    'arrowdown',
    'arrowup',
    'wrapup',
    'snapshot',
    'appointment',
    'todo',
    'tasks',
    'addtask',
    'house',
    'addressbook',
    'check',
    'edit',
    'addcontact',
    'edittask',
    'editcontact',
    'office',
    'refresh',
    'feedback',
    'importantdates',
    'wedding',
    'birthday',
    'houseanniversary',
    'death',
    'announcement',
    'flag',
    'myinfo',
    'settings',
    'website',
    'timezone',
    'google',
    'upload',
    'arrowThick',
    'arrowThickLeft',
    'unsubscribe',
    'texting',
    'textinglist',
    'textingsetup',
    'undo',
    'redo',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'bulletedlist',
    'orderedlist',
    'blockquote',
    'fontfamily',
    'fontsize',
    'colors',
    'alignleft',
    'aligncenter',
    'alignright',
    'attach',
    'inbox',
    'outbox',
    'opened',
    'unopened',
    'imagefile',
    'datafile',
    'pdf',
    'zip',
    'videofile',
    'reply',
    'replyall',
    'lock',
    'profile',
    'facebook',
    'instagram',
    'linkedin',
    'medium',
    'pinterest',
    'twitter',
    'youtube',
    'coffee',
    'signature',
    'link',
    'image',
    'leadsetup',
    'tag',
    'reminder',
    'housekey',
    'license',
    'housestar',
    'heart',
    'contactsync',
    'billing',
    'calendardate',
    'dashboard',
    'househeart',
    'source',
    'signout',
    'support',
    'tp',
    'calendarsync',
    'transaction',
    'plan',
    'planbrowse',
    'transactionparty',
    'lockbox',
    'file',
    'houseinbubble',
    'houseshake',
    'commission',
    'moneytrend',
    'referfriend',
    'copy',
    'help',
    'team',
    'mailbox',
    'template',
    'fileAdd',
    'fileCreate',
    'recent',
    'mlsSync',
    'housedownarrow',
    'emailpreview',
    'alphabetize',
    'menu',
    'print',
    'maintenance',
    'contactcheck',
    'minimize',
    'maximize',
    'refreshtask',
    'housecheck',
    'filter',
    'lightning',
    'textpreview',
    'relationships',
    'marketing',
    'housegeo',
    'smartzip',
    'salesforce',
    'zillow',
    'clicks',
    'impression',
    'totalLeads',
    'recentLeads',
    'preview',
    'performance',
    'pie',
    'pin',
    'dashboardChart',
    'advancedSearch',
    'editLayout',
    'notifications',
    'smsOptIn',
    'smsOptOut',
    'info',
    'aiAuthor',
    'handshake',
    'emoji',
    'briefcase',
    'cool',
    'compress',
    'enhance',
    'reset',
    'reload',
    'yearsOwned',
    'equity',
    'interestRate',
    'homeValue'
  ]),
  size: PropTypes.oneOf(['xxxs', 'xxs', 'xs', 's', 'm', 'l', 'xl']),
  tooltipPos: PropTypes.oneOf(['below', 'above', 'left', 'right'])
};
