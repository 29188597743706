/** @module */
import { getMergedEntities, getMergedSetOfIds, sortCollectionByDate } from '../utils/collections';

export const TEXTS_SET = 'TEXTS_SET';
export const TEXTS_DONE_PAGING = 'TEXTS_DONE_PAGING';
export const TEXTS_LOADING = 'TEXTS_LOADING';

export const initialState = {
  entities: {},
  groups: {},
  pageIndex: {},
  donePaging: {},
  totalPages: {},
  isLoading: false
};

/**
 * The texts redux reducer.
 * @param {Object} state - the current state of the texts store.
 * @param {Object} action - the action to take on the texts store
 * @param {String} [action.type=default] - the action to take.
 * @param {String} [action.id] - contact id.
 * @param {String} [action.data] - text data for a contact.
 */

export const textsReducer = (state = initialState, action = {}) => {
  const { type = 'default', id, data, isLoading, isPaging } = action;
  const { messages = [], pageIndex, totalPages } = data || {};
  let mergedEntities;

  if (type === 'TEXTS_SET' || type === 'TEXTS_SET') {
    mergedEntities = getMergedEntities(state.entities, messages);
  }

  switch (type) {
    case TEXTS_SET:
      return {
        ...state,
        entities: mergedEntities,
        groups: {
          ...state.groups,
          [id]: getMergedSetOfIds(state.groups[id], messages, {
            isPaging,
            sortFunction: sortCollectionByDate,
            sortKey: 'timeStamp',
            entities: mergedEntities
          })
        },
        pageIndex: {
          ...state.pageIndex,
          [id]: pageIndex
        },
        totalPages: {
          ...state.totalPages,
          [id]: totalPages
        },
        isLoading: isLoading
      };
    case TEXTS_DONE_PAGING:
      return {
        ...state,
        donePaging: {
          ...state.donePaging,
          [id]: true
        }
      };
    case TEXTS_LOADING:
      return {
        ...state,
        isLoading: isLoading
      };
    default:
      return state;
  }
};
