/** @module */
import { v4 as uuidv4 } from 'uuid';
import { showMessage } from '../actions/message';
import { EMAIL_PAGING_SIZE, EWS_EMAIL_PAGING_SIZE } from '../constants';
import { CONTACTS_UPDATE_CONTACT } from '../reducers/contacts';
import {
  EMAILS_CURRENT_GROUP,
  EMAILS_DONE_PAGING,
  EMAILS_LOADING,
  EMAILS_MESSAGE_SET,
  EMAILS_MESSAGE_UPDATE,
  EMAILS_ATTACHMENT_SET,
  EMAILS_OPENED,
  EMAILS_SET,
  EMAILS_ATTACHMENTS_OBJECT_ID,
  EMAILS_DELETE
} from '../reducers/email';
import { SETTINGS_EMAIL_DETAILS, SETTINGS_EMAIL_SET_UP } from '../reducers/settings';
import { nylasRequest, request, requestError } from '../utils';
import { getLastTouchFromAction } from '../utils/contacts';
import { downloadBlob } from '../utils/dom';

const EMAIL_TIMEOUT = 120000;
const EMAIL_INTEGRATION_URL = '/settings/email-integration';

/**
 * Dispatches an update to settings when the details or scope can't be retrieved.
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 */
const detailsOrScopeRequestFailed = accountId => {
  return dispatch => {
    dispatch({ type: SETTINGS_EMAIL_DETAILS, emailAccountId: accountId, emailDetails: { scopes: 'unknown' } });
  };
};

export const updateAttachmentObjectId = objectId => {
  return dispatch => {
    dispatch({ type: EMAILS_ATTACHMENTS_OBJECT_ID, attachmentObjectId: objectId });
  };
};

const showCustomNylasReAuthError = (error, dispatch) => {
  const customMessage = 'Please reauthorize your email account in settings.';
  const shouldUseTimer = false;
  const action = {
    label: 'Settings',
    url: EMAIL_INTEGRATION_URL
  };

  requestError(error, dispatch, shouldUseTimer, customMessage, action);
};

/**
 * Gets the email account's scope/rights details from Nylas.
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 * @param {Boolean} forceRefresh - Whether to force the request through.
 * @param {Boolean} shouldBeCached - Whether the request should be cached.
 */
export const getEmailAccountScope = options => {
  const { accountId, forceRefresh = true, shouldBeCached = false } = options || {};

  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      forceRefresh,
      method: 'GET',
      path: `getEmailNylasAccountStatusByGrantId?grantId=${accountId}`,
      shouldBeCached
    };

    try {
      const response = await nylasRequest(requestOptions);

      dispatch({ type: SETTINGS_EMAIL_DETAILS, emailAccountId: accountId, emailDetails: response });

      return;
    } catch (error) {
      const { response } = error;
      const { data, status } = response || {};
      const { rawResponse } = data || {};
      const nylasResponse = rawResponse && JSON.parse(rawResponse);
      const { type } = nylasResponse || {};

      dispatch(detailsOrScopeRequestFailed(accountId));

      if (window.location.pathname === EMAIL_INTEGRATION_URL) {
        // We want to suppress the error message when already on the EMAIL_INTEGRATION_URL.
        return;
      }

      if ([404].includes(status) && type === 'api_error') {
        showCustomNylasReAuthError(error, dispatch);
        return;
      }

      requestError(error, dispatch, true);

      return null;
    }
  };
};

/**
 * Gets an email account's details from Nylas.
 * If success, returns data to the caller.
 * If fails, shows error message via <Toast />.
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 * @param {Boolean} forceRefresh - Whether to force the request through.
 * @param {Boolean} shouldBeCached - Whether the request should be cached.
 */
export const getEmailAccountDetails = options => {
  const { accountId, forceRefresh = false, shouldBeCached = true } = options || {};

  if (!accountId) {
    return;
  }
  return async dispatch => {
    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      forceRefresh,
      method: 'GET',
      path: `getEmailNylasAccountInformation?grantId=${accountId}`,
      shouldBeCached
    };

    try {
      const response = await nylasRequest(requestOptions);

      // Some of the data from the Nylas response is a duplication of data we already have, so we throw it away.
      const { account_id, email_address, id, ...emailDetails } = response;

      dispatch({ type: SETTINGS_EMAIL_DETAILS, emailAccountId: accountId, emailDetails });
      dispatch(getEmailAccountScope({ accountId }));

      return;
    } catch (error) {
      const { response } = error;
      const { data, status } = response || {};
      const { rawResponse } = data || {};
      const nylasResponse = rawResponse && JSON.parse(rawResponse);
      const { type } = nylasResponse || {};

      dispatch(detailsOrScopeRequestFailed(accountId));

      if (window.location.pathname === EMAIL_INTEGRATION_URL) {
        // We want to suppress the error message when already on the EMAIL_INTEGRATION_URL.
        return;
      }

      if ([401, 403].includes(status) && type === 'invalid_request_error') {
        showCustomNylasReAuthError(error, dispatch);
        return;
      }

      requestError(error, dispatch, true);

      return null;
    }
  };
};

/**
 * Send Email
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 * @param {String} contactId - contact Id of each contact on the email.
 * @param {Object} emailPayload - The email form data.
 */
export const sendEmail = options => {
  return async dispatch => {
    const { accountId, contactIds, group, emailAction, attachmentObjectId, ...emailPayload } = options;

    const payload = {
      grantId: accountId,
      payload: JSON.stringify(emailPayload),
      contactIds,
      objectId: attachmentObjectId
    };

    const requestOptions = {
      apiServiceType: 'nylasV3',
      method: 'POST',
      path: 'sendEmailMessage',
      baseUrlKey: 'api',
      payload,
      timeout: EMAIL_TIMEOUT, // Note: Recommended length from Nylas https://docs.nylas.com/reference#sending
      shouldBeCached: false
    };

    try {
      const response = await nylasRequest(requestOptions);
      // When sending an email, we get back most of what we need to mimic a thread, so we prep it for adding with EMAILS_SET.

      const {
        account_id,
        date: last_message_timestamp,
        files,
        id: message_id,
        subject,
        thread_id: id,
        to,
        from
      } = response;

      dispatch(showMessage({ message: 'Email message sent.', type: 'success' }, true));
      // If there is an emailAction like a reply or forward, we don't eagerly set the message as a new thread.
      if (emailAction) {
        dispatch({
          type: EMAILS_LOADING,
          isLoading: false
        });

        return true;
      }

      dispatch({
        type: EMAILS_SET,
        emailThreads: [
          {
            account_id,
            has_attachments: Boolean(files && files.length > 0),
            id,
            last_message_timestamp,
            last_message_sent_timestamp: last_message_timestamp,
            participants: [...from, ...to],
            subject,
            message_ids: [message_id]
          }
        ],
        group,
        setCurrentGroup: false,
        isPaging: false, // We set isPaging to true if there is an offset greater than zero.
        isLoading: false
      });

      if (contactIds) {
        contactIds.forEach(id => {
          dispatch({
            type: CONTACTS_UPDATE_CONTACT,
            contactId: id,
            updatedKeys: {
              lastTouchPoint: getLastTouchFromAction('email')
            }
          });
        });
      }

      return true;
    } catch (error) {
      requestError(error, dispatch);
      return false;
    } finally {
      dispatch(updateAttachmentObjectId(null));
    }
  };
};

/**
 * Upload email attachment
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 * @param {String} contactId - contact Id of each contact on the email.
 * @param {String} file - the file path of the attachment.
 */
export const uploadEmailAttachment = (options, attachmentObjectId) => {
  return async dispatch => {
    const { accountId, file } = options;
    const newAttachmentObjectId = uuidv4();

    if (attachmentObjectId == null) {
      dispatch(updateAttachmentObjectId(newAttachmentObjectId));
    }
    var formData = new FormData();
    formData.append('grantId', accountId);
    formData.append('objectId', attachmentObjectId == null ? newAttachmentObjectId : attachmentObjectId);
    formData.append('objectType', '4');
    formData.append('uploadedFile', file);

    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      method: 'POST',
      path: `uploadAttachment`,
      payload: formData,
      timeout: EMAIL_TIMEOUT,
      shouldBeCached: false
    };

    try {
      const response = await nylasRequest(requestOptions);

      return response;
    } catch (error) {
      requestError(error, dispatch);
      return Promise.reject(error);
    }
  };
};

/**
 * Delete email attachment
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 * @param {String} contactId - contact Id of each contact on the email.
 * @param {String} fileId - the file id of the attachment to delete.
 */
export const deleteEmailAttachment = options => {
  return async dispatch => {
    const { accountId, contactId, fileId } = options;

    const payload = {
      grantId: accountId,
      fileId: fileId,
      contactIds: [contactId]
    };

    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      method: 'DELETE',
      path: 'deleteAttachment',
      payload,
      shouldBeCached: false
    };

    try {
      await nylasRequest(requestOptions);

      return true;
    } catch (error) {
      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Check whether a list of email messages have been opened.
 * @param {Array} options.messages - A list of message IDs to check.
 * @param {Boolean} options.forceRefresh - Whether to force the request through caching.
 * @param {Boolean} options.shouldBeCached - Whether the request should be cached.
 */
export const checkEmailsOpened = options => {
  return async dispatch => {
    const { messages, forceRefresh = false, shouldBeCached = false } = options || {};

    if (!messages || messages.length === 0) {
      return false;
    }

    const requestOptions = {
      apiServiceType: 'nylas',
      forceRefresh,
      method: 'POST',
      path: 'messages/checkopened',
      payload: messages,
      shouldBeCached
    };

    try {
      const { data } = await request(requestOptions);

      const { exception } = data;

      if (exception) {
        throw exception;
      }

      dispatch({
        type: EMAILS_OPENED,
        openedEmails: data
      });

      return true;
    } catch (error) {
      dispatch({
        type: EMAILS_LOADING,
        isLoading: false
      });

      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Get a list of threads.
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 * @param {String} emails - a comma delimited list of emails to query against.
 * @param {Number} offset - the paging offset.
 * @param {Number} pageSize - the paging size.
 * @param {Boolean} forceRefresh - Whether to force the request through.
 * @param {Boolean} shouldBeCached - Whether the request should be cached.
 * @param {Boolean} setCurrentGroup - Whether the current group should be set based on this request.
 */
export const getThreads = options => {
  return async dispatch => {
    const {
      accountId,
      contactId,
      emails,
      offset = 0,
      provider,
      pageSize = provider === 'ews' ? EWS_EMAIL_PAGING_SIZE : EMAIL_PAGING_SIZE,
      forceRefresh = false,
      shouldBeCached = false,
      setCurrentGroup = true
    } = options || {};

    if (!emails || emails.length === 0) {
      return false;
    }

    const requestOptions = {
      apiServiceType: 'nylasV3',
      forceRefresh,
      method: 'POST',
      path: 'getEmailThreads',
      baseUrlKey: 'api',
      payload: {
        ContactEmails: emails,
        grantId: accountId,
        page_token: offset,
        limit: pageSize
      },
      shouldBeCached
    };

    try {
      const group = `${accountId}::${contactId}`;

      if (setCurrentGroup) {
        dispatch({
          type: EMAILS_CURRENT_GROUP,
          group,
          isLoading: true
        });
      }

      const response = await nylasRequest(requestOptions);

      if (response.length < pageSize) {
        // if the results set returned has less than the page size, we have reached the end of the list
        dispatch({
          type: EMAILS_DONE_PAGING,
          group
        });
      }

      dispatch({
        type: EMAILS_SET,
        emailThreads: response,
        group,
        isPaging: offset > 0, // We set isPaging to true if there is an offset greater than zero.
        isLoading: false,
        setCurrentGroup
      });
      const messages = response.reduce((acc, thread) => {
        const { message_ids } = thread;

        return [...acc, ...message_ids];
      }, []);

      dispatch(checkEmailsOpened({ messages }));

      return { messages, response };
    } catch (error) {
      dispatch({
        type: EMAILS_LOADING,
        isLoading: false
      });

      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Gets the email messages for a given thread.
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 * @param {String} threadId - the id of the email thread.
 * @param {Boolean} forceRefresh - Whether to force the request through.
 * @param {Boolean} shouldBeCached - Whether the request should be cached.
 * @param {Boolean} setCurrentGroup - Whether the current group should be set based on this request.
 */
export const getThreadEmails = options => {
  return async dispatch => {
    const { accountId, threadId, forceRefresh, shouldBeCached = true } = options || {};

    if (!threadId) {
      return false;
    }
    const payload = {
      grantId: accountId,
      messageIds: [threadId]
    };

    const requestOptions = {
      apiServiceType: 'nylasV3',
      forceRefresh,
      method: 'POST',
      path: 'getEmailThreadsByThreadId',
      baseUrlKey: 'api',
      payload,
      shouldBeCached
    };

    try {
      const response = await nylasRequest(requestOptions);

      dispatch({
        type: EMAILS_MESSAGE_SET,
        messages: response
      });

      const messages = response.reduce((acc, message) => {
        const { id } = message;

        return [...acc, ...id];
      }, []);

      dispatch(checkEmailsOpened({ messages }));

      return response;
    } catch (error) {
      dispatch({
        type: EMAILS_LOADING,
        isLoading: false
      });

      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Updates an email message
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 * @param {String} messageId - the id of the email message.
 * @param {Boolean} unread - the unread status to set.
 * @param {Boolean} forceRefresh - Whether to force the request through.
 * @param {Boolean} shouldBeCached - Whether the request should be cached.
 * @param {Boolean} setCurrentGroup - Whether the current group should be set based on this request.
 */
export const updateEmailMessage = options => {
  return async dispatch => {
    const { accountId, messageId, unread, forceRefresh, shouldBeCached = false } = options || {};

    if (!messageId) {
      return false;
    }

    const requestOptions = {
      apiServiceType: 'nylasV3',
      forceRefresh,
      method: 'PUT',
      baseUrlKey: 'api',
      path: `updateEmaiReadStatus?grantId=${accountId}&messageId=${messageId}&unread=${unread}`,
      shouldBeCached
    };

    try {
      const response = await nylasRequest(requestOptions);

      dispatch({
        type: EMAILS_MESSAGE_UPDATE,
        messageId,
        messageDetails: response
      });

      return true;
    } catch (error) {
      dispatch({
        type: EMAILS_LOADING,
        isLoading: false
      });

      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Updates an email message
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 * @param {String} messageId - the id of the email message.
 * @param {Boolean} unread - the unread status to set.
 * @param {Boolean} forceRefresh - Whether to force the request through.
 * @param {Boolean} shouldBeCached - Whether the request should be cached.
 * @param {Boolean} setCurrentGroup - Whether the current group should be set based on this request.
 */
export const getEmailMessageAttachments = options => {
  return async dispatch => {
    const { accountId, messageId, forceRefresh, shouldBeCached = true } = options || {};

    if (!messageId) {
      return false;
    }

    const payload = {
      grantId: accountId,
      messageId: messageId
    };

    const requestOptions = {
      apiServiceType: 'nylasV3',
      baseUrlKey: 'api',
      forceRefresh,
      method: 'POST',
      path: 'getAttachmentMetadata',
      payload,
      shouldBeCached
    };

    try {
      const response = await nylasRequest(requestOptions);

      dispatch({
        type: EMAILS_ATTACHMENT_SET,
        attachments: response,
        attachmentGroup: messageId
      });

      return true;
    } catch (error) {
      dispatch({
        type: EMAILS_LOADING,
        isLoading: false
      });

      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Updates an email message
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 * @param {Object} data - the attachment data object.
 * @param {Function} handleDownloadProgress - a callback to call with the download progress;
 * @param {Boolean} forceRefresh - Whether to force the request through.
 * @param {Boolean} shouldBeCached - Whether the request should be cached.
 */
export const downloadAttachment = options => {
  return async dispatch => {
    const { messageId, accountId, data, progressCallback, forceRefresh, shouldBeCached = false } = options || {};
    const { id: fileId, filename } = data;

    if (!fileId) {
      return false;
    }
    const requestOptions = {
      apiServiceType: 'nylasV3',
      forceRefresh,
      method: 'GET',
      baseUrlKey: 'api',
      onDownloadProgress: e => {
        const { loaded } = e;
        progressCallback(loaded);
      },
      path: `downloadAttachment?grantId=${accountId}&messageId=${messageId}&attachmentId=${fileId}`,
      responseType: 'blob',
      shouldBeCached
    };

    try {
      const response = await request(requestOptions);
      const { data } = response;

      downloadBlob(data, filename);
      progressCallback(null);

      return true;
    } catch (error) {
      dispatch({
        type: EMAILS_LOADING,
        isLoading: false
      });

      requestError(error, dispatch);
      return false;
    }
  };
};

/**
 * Updates an email message
 * @param {String} accountId - the nylas account Id associated to the agent's From email address.
 * @param {Object} data - the attachment data object.
 * @param {Function} handleDownloadProgress - a callback to call with the download progress;
 * @param {Boolean} forceRefresh - Whether to force the request through.
 * @param {Boolean} shouldBeCached - Whether the request should be cached.
 */
export const downloadInlineAttachment = options => {
  return async dispatch => {
    const { accountId, messageId, data, forceRefresh, shouldBeCached = false } = options || {};
    const { id: fileId } = data;

    if (!fileId) {
      return false;
    }
    const payload = {
      accountId,
      fileId
    };

    const requestOptions = {
      apiServiceType: 'nylasV3',
      forceRefresh,
      method: 'GET',
      baseUrlKey: 'api',
      path: `downloadAttachment?grantId=${accountId}&messageId=${messageId}&attachmentId=${fileId}`,
      responseType: 'blob',
      payload,
      shouldBeCached
    };

    try {
      const response = await request(requestOptions);
      const { data } = response;

      return data;
    } catch (error) {
      dispatch({
        type: EMAILS_LOADING,
        isLoading: false
      });

      requestError(error, dispatch);
      return false;
    }
  };
};

export const updateNylasEmail = email => {
  return dispatch => {
    dispatch({
      type: SETTINGS_EMAIL_SET_UP,
      email
    });
  };
};

export const deleteEmail = (messageId, group) => {
  return dispatch => {
    dispatch({
      type: EMAILS_DELETE,
      messageId,
      group
    });
  };
};
