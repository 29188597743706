/** @module */

/**
 * Opens a centered popup window of a specified widht and height.
 * @param {String} url - the popup window's url.
 * @param {Object} options - the popup window's options.
 * @param {String} [options.windowWidth=550] - width of the popup window.
 * @param {String} [options.windowHeight=550] - height of the popup window.
 * @param {String} [options.windowTop] - window top position.
 * @param {String} [options.windowLeft] - window left position.
 */
export const openPopup = (url, options) => {
  const { position = 'topLeft', windowWidth = 550, windowHeight = 550 } = options || {};

  const WINDOW_POSITIONS = {
    center: {
      top: (window.outerHeight - windowHeight) / 2 + window.screenY,
      left: (window.outerWidth - windowWidth) / 2 + window.screenX
    },
    topLeft: {
      top: 0,
      left: 0
    }
  };

  window.open(
    url,
    'tpxPopup',
    `width=${windowWidth}, height=${windowHeight}, top=${WINDOW_POSITIONS[position].top}, left=${WINDOW_POSITIONS[position].left}, resizable=yes, scrollbars=yes, status=yes`
  );
};

export const openLiveChat = () => {
  window.LiveChatWidget.call('maximize');
};
