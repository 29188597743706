import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Panel } from '../';

import styles from './Document.css';

export const Document = props => {
  const { children, className, isNested = false, isTab = false, title, ...attrs } = props;

  if (!children && !title) {
    return null;
  }

  const classes = classnames({
    [styles.document]: !className,
    [styles.isNested]: isNested,
    [styles.isTab]: isTab,
    [className]: !!className
  });

  return (
    <Panel className={classes} {...attrs}>
      {title && <h1 className={styles.h1}>{title}</h1>}
      {children}
    </Panel>
  );
};

Document.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  isNested: PropTypes.bool,
  title: PropTypes.string
};
