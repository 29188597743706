import { format } from 'date-fns';
import { string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { DATE_FORMATS } from '../../constants';
import { DEFAULT_TRANSACTION_SETTINGS } from '../../constants/preferences';
import { getDateFromToday } from '../../utils';
import { NON_ACTIVE_FLAG, buildTransactionsGroupName, getTotalCommission } from '../../utils/transactions';
import { TransactionStats } from '../Transactions/TransactionStats';

export const Commissions = () => {
  const { groups, entities, lastSearchedDates } = useSelector(store => store.transactions);
  const transactionsPref = useSelector(state => state.preferences?.transactions);
  const dateTo = transactionsPref?.transactionRange?.dateTo;
  const dateFrom = transactionsPref?.transactionRange?.dateFrom;
  const lastSearchDates = lastSearchedDates?.listings;

  const startDate =
    lastSearchDates?.dateFrom ||
    format(
      getDateFromToday(parseInt(dateFrom != null ? dateFrom : DEFAULT_TRANSACTION_SETTINGS.transactionRange.dateFrom)),
      DATE_FORMATS.ISO_DATE
    );

  const endDate =
    lastSearchDates?.dateTo ||
    format(
      getDateFromToday(parseInt(dateTo != null ? dateTo : DEFAULT_TRANSACTION_SETTINGS.transactionRange.dateTo)),
      DATE_FORMATS.ISO_DATE
    );

  const commissionGroupName = {
    startDate,
    endDate,
    source: '',
    buyer: true,
    seller: true
  };
  const status = 0;
  // ALL_ACTIVE_STATUS includes `active`, `active under contact` and `pending`.
  // There is no unrealized commission when the status is closed.
  const unrealizedCommission = getTotalCommission(
    groups[
      buildTransactionsGroupName({
        status,
        ...commissionGroupName
      })
    ],
    entities
  );

  const realizedCommission = getTotalCommission(
    groups[
      buildTransactionsGroupName({
        status: NON_ACTIVE_FLAG,
        ...commissionGroupName
      })
    ],
    entities
  );

  const projectedCommission = realizedCommission + unrealizedCommission;

  return <TransactionStats projected={projectedCommission} realized={realizedCommission} status={status} />;
};

Commissions.propTypes = {
  className: string
};
