import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import isThisYear from 'date-fns/isThisYear';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isYesterday from 'date-fns/isYesterday';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import toDate from 'date-fns/toDate';
import { DATE_FORMATS } from '../constants';
import { convertToDate } from '../utils';
import isSameSecond from 'date-fns/isSameSecond';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import isMatch from 'date-fns/isMatch';
import isEqual from 'date-fns/isEqual';
import { zonedTimeToUtc } from 'date-fns-tz';
import isDate from 'lodash/isDate';
import { pluralize } from './strings';

const SERVER_TIMEZONE = 'America/Phoenix';

/**
 * Takes string or number and parse to Date.
 * You can pass date in when you are not sure of the type but want a date object.
 * @param {string|number|Date} date - input date
 * @param {string} [date] - parse format
 * @returns {Date|NaN} returns NaN when the input is invalid against the format
 */
export const parseISODate = (date, parseFormat = DATE_FORMATS.ISO_DATE) => {
  if (typeof date === 'string') {
    if (parseFormat !== DATE_FORMATS.ISO_DATE) {
      return parse(date, parseFormat, new Date());
    }
    return parseISO(date, parseFormat);
  } else if (typeof date === 'number') {
    return toDate(date, parseFormat);
  }
  return date;
};

/**
 * Converts a server timestamp, in its timezone, and converts it to a local ISO date.
 * @param {string} timestamp - timestamp date.
 * @param {string} [timezone=SERVER_TIMEZONE] - timezone string like America/New_York (needs underscore).
 * @returns {Date} returns an ISO date in user's local time.
 */
export const getParsedLocalDateFromServerDate = (timestamp, timezone = SERVER_TIMEZONE) => {
  const utcDate = zonedTimeToUtc(timestamp, timezone);

  return parseISODate(utcDate);
};

/**
 * Gets a simple calendar date from the formats 2010-01-30T00:00:00Z or 2010-01-30 00:00:00Z. Important dates has both formats.
 * @param {String} timestamp - a date like timestamp.
 */
export const getCalendarDate = timestamp => {
  return timestamp.includes('T') ? timestamp.split('T')[0] : timestamp.split(' ')[0];
};

export const getStartOfMonth = date => {
  const datified = new Date(date);
  const parsed = new Date(datified.getFullYear(), datified.getMonth() - 1, 15);
  return format(parseISODate(parsed), DATE_FORMATS.ISO_DATE);
};

export const getEndOfMonth = date => {
  const datified = new Date(date);
  const parsed = new Date(datified.getFullYear(), datified.getMonth() + 1, 7);
  return format(parseISODate(parsed), DATE_FORMATS.ISO_DATE);
};

export const getNumberOfDaysFromToday = date => {
  return Math.abs(differenceInCalendarDays(date, new Date()));
};

export const getImportDatesIcon = description => {
  const DEFAULT_ICON = 'importantdates';

  if (!description) {
    return DEFAULT_ICON;
  }

  const str = description.toLowerCase().replace(/[^ \w]+/g, '');

  if (
    str.includes('house anniversary') ||
    str.includes('home anniversary') ||
    str.includes('closing anniversary') ||
    str.includes('move-in') ||
    str.includes('purchase')
  ) {
    return 'houseanniversary';
  }

  if (str.includes('mortgage')) {
    return 'transaction';
  }

  if (str.includes('wedding') || str.includes('anniversary') || str.includes('engagement')) {
    return 'wedding';
  }

  if (str.includes('birthday')) {
    return 'birthday';
  }

  if (str.includes('death') || str.includes('died') || str.includes('passed away')) {
    return 'death';
  }

  return DEFAULT_ICON;
};

/**
 * Returns a timeline date.
 * @param {string} timestamp - timestamp returned from the API
 */
export const formatTimelineDate = timestamp => {
  if (!timestamp) {
    return null;
  }

  const parsedTimestamp = parseISODate(new Date(timestamp));

  if (isTomorrow(parsedTimestamp)) {
    return 'Tomorrow';
  }

  if (isToday(parsedTimestamp)) {
    return 'Today';
  }

  if (isYesterday(parsedTimestamp)) {
    return 'Yesterday';
  }

  return format(parsedTimestamp, DATE_FORMATS.TIMELINE_DATE);
};

/**
 * Returns a the date visible on an email thread or inbox list.
 * @param {string} timestamp - timestamp returned from Nylas.
 */
export const formatEmailThreadDate = timestamp => {
  if (!timestamp) {
    return null;
  }

  // If today, show time.
  if (isToday(timestamp)) {
    return format(timestamp, DATE_FORMATS.SHORT_TIME);
  }

  // If this year OR within the last 62 days, show the short date.
  if (isThisYear(timestamp) || differenceInCalendarDays(new Date(), timestamp) < 63) {
    // 63 is number of days in Jan and Dec plus 1.
    return format(timestamp, DATE_FORMATS.SHORT_DATE);
  }

  // Otherwise show the long date
  return format(timestamp, DATE_FORMATS.SHORT_DATE_WITH_YEAR);
};

/**
 * Returns a the date visible on an email thread or inbox list.
 * @param {string} timestamp - timestamp returned from Nylas.
 */
export const formatEmailMessageDate = timestamp => {
  if (!timestamp) {
    return null;
  }

  const DAYS_TO_SHOW_DISTANCE_IN_WORDS = 15; // days
  const distanceInWords = `(${formatDistanceToNow(timestamp)} ago)`;

  // If today, show time.
  if (isToday(timestamp)) {
    return `${format(timestamp, DATE_FORMATS.SHORT_TIME)} ${distanceInWords}`;
  }

  const diffInDays = differenceInCalendarDays(Date.now(), timestamp);

  // Just show the long date time if the date is more than DAYS_TO_SHOW_DISTANCE_IN_WORDS days old.
  if (diffInDays > DAYS_TO_SHOW_DISTANCE_IN_WORDS) {
    return format(timestamp, DATE_FORMATS.LONG_DATE_TIME);
  }

  // Otherwise show the long date time with relative distance in words.
  return `${format(timestamp, DATE_FORMATS.LONG_DATE_TIME)} ${distanceInWords}`;
};

/**
 * Returns a the date visible on an email thread or inbox list.
 * @param {string} timestamp - timestamp returned from Nylas.
 */
export const getEmailDateForAttribution = timestamp => {
  if (!timestamp) {
    return null;
  }

  // Otherwise show the long date time with relative distance in words.
  return `${format(timestamp, DATE_FORMATS.TIMELINE_DATE)} at ${format(timestamp, DATE_FORMATS.SHORT_TIME)}`;
};

export const getCrossBrowserDateFromTimestamp = timestamp => {
  if (!timestamp) {
    return null;
  }

  // Note: This is a tricky coversion to deal with a Safari bug treating 2019-12-15T03:00:00 incorrectly when using new Date().
  return new Date(convertToDate(timestamp).replace('T', ' '));
};

export const getISODateFromNow = () => {
  return new Date().toISOString();
};
/**
 * Preserve year, month and date from date, and hour, min, sec from timestamp
 * @param {object} date - date
 * @param {object} timestamp - timestamp returned from Nylas.
 * @returns {object} new date
 */
export const setNewTime = (date, timestamp) => {
  if (!timestamp) {
    return date;
  }
  const newDate = new Date(date);

  newDate.setHours(timestamp.getHours());
  newDate.setMinutes(timestamp.getMinutes());
  newDate.setSeconds(timestamp.getSeconds());

  return newDate;
};

/**
 * Parse non-standard UTC time into Date object
 * @param {string} time returned from TMK backend
 * @returns {Object} Formatted date object in local time
 */
export const uTCToLocalTime = time => {
  if (!time) {
    return null;
  }
  // time is in UTC but not in the standard UTC format
  const formattedTime = `${time}Z`;
  return parse(formattedTime, DATE_FORMATS.UTC_WITH_SLASH, new Date());
};

/**
 * Inclusive version of isBefore
 * @param {Object} d1 start date
 * @param {Object} d2 end date
 */
export const sameOrBefore = (d1 = new Date(), d2 = new Date()) => {
  return isSameSecond(d1, d2) ? true : isBefore(d1, d2) ? true : false;
};

/**
 * Inclusive version of isAfter
 * @param {Object} d1 start date
 * @param {Object} d2 end date
 */
export const sameOrAfter = (d1 = new Date(), d2 = new Date()) => {
  return isSameSecond(d1, d2) ? true : isAfter(d1, d2) ? true : false;
};

/**
 * Get the name of the a given day
 */
export const getDayName = date => {
  return date.toLocaleString('en-us', { weekday: 'long' });
};

/**
 * Check is a date string is valid against a given regex
 * @param {String} date
 * @param {String} format
 * @returns {Boolean}
 */
export const validateDate = (date, format = DATE_FORMATS.ISO_DATE) => {
  if (date == null || typeof date !== 'string') {
    return false;
  }
  return isMatch(date, format);
};

/**
 * Checks whether is imported all day event
 * (BE seems to return these in format of 00:00:00 -> 23:59:59 on the same day)
 * @param {string} startOfEvent
 * @param {String} endOfEvent
 * @returns {Boolean}
 */
export const isImportedAllDayEvent = (startOfEvent, endOfEvent) => {
  const startDate = startOfEvent.substring(0, 9);
  const endDate = endOfEvent.substring(0, 9);

  const startTime = startOfEvent.substring(11, 19);
  const endTime = endOfEvent.substring(11, 19);

  return startDate === endDate && startTime === '00:00:00' && endTime === '23:59:59';
};

/**
 * Checks whether start and end times are 00:00:00
 * If so, then it is an untimed event
 * @param {string} startOfEvent
 * @param {string} endOfEvent
 * @returns {Boolean}
 */
export const isUntimedEvent = (startOfEvent, endOfEvent) => {
  const startTime = startOfEvent.substring(11, 19);
  const endTime = endOfEvent.substring(11, 19);

  return startTime === '00:00:00' && endTime === '00:00:00';
};

/**
 * Checks whether a timestamp/date is new (from the last 30 days)
 * @param {string} timestamp
 * @returns {Boolean}
 */
export const isNewlyAdded = timestamp => {
  const parsedDate = parseISODate(timestamp);
  const numberOfDaysFromToday = getNumberOfDaysFromToday(parsedDate);

  return numberOfDaysFromToday != null && numberOfDaysFromToday < 30;
};

const getDateRangeLabel = value => {
  const absValue = Math.abs(value);
  if (value > 0) {
    return `${absValue} ${pluralize(absValue, 'day')} after today`;
  }
  if (value < 0) {
    return `${absValue} ${pluralize(absValue, 'day')} before today`;
  }
  return 'Today';
};

export const getDisplayDateRange = range => {
  const { dateTo, dateFrom } = range;

  return {
    dateFrom: getDateRangeLabel(parseInt(dateFrom)),
    dateTo: getDateRangeLabel(parseInt(dateTo))
  };
};

/**
 * Get time in string from Date object
 * @param {object} time
 * @returns {string} Time like "11:30"
 */
export const getTimeFromDate = time => {
  const date = isDate(time) ? time : new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

/**
 * Return if transaction date is absent by checking against the dummy value
 * @param {object} date
 * @returns {Boolean}
 */
export const isFutureTransactionDate = date => {
  return isEqual(new Date('2550/01/01'), date);
};

export const getSpecifiedTimeLabel = (specifiedHour, specifiedMinute) => {
  if (specifiedHour == null || specifiedMinute == null) {
    return '';
  }

  // in 24 hour format string
  const timestamp24hr = `${specifiedHour?.toString()?.padStart(2, '0')}:${specifiedMinute
    ?.toString()
    ?.padStart(2, '0')}:00`;

  const timestamp12hr = new Date(`1970-01-01T${timestamp24hr}Z`).toLocaleTimeString('en-US', {
    timeZone: 'UTC',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric'
  });
  return timestamp12hr;
};
