import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AlertDialog } from '../';
import { BUILD_VERSION } from '../../constants';
import { requestError, requestIndexHtml } from '../../utils';

export class CheckVersion extends Component {
  constructor(props) {
    super(props);
    this.state = { newVersion: null };
  }

  cancelUpdate = () => {
    this.setState({ newVersion: null });
  };

  checkVersion = async () => {
    try {
      const { data } = await requestIndexHtml();

      const parser = new DOMParser();
      const parsedDoc = parser.parseFromString(data, 'text/html');
      const el = parsedDoc.getElementById('tpxVersion');

      const version = el ? el.dataset.version : BUILD_VERSION;

      if (version !== BUILD_VERSION) {
        // a new version is live
        this.setState({ newVersion: version });
      }
    } catch (error) {
      requestError(error);

      return false;
    }
  };

  reloadApp = e => {
    e.preventDefault();
    window.location.reload(true);
  };

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location !== location) {
      this.checkVersion();
    }
  }

  render() {
    const alertProps = {
      isOpen: !!this.state.newVersion,
      icon: 'refresh',
      iconSize: 'm',
      loading: false,
      hint: `You are using version ${BUILD_VERSION}, and version ${this.state.newVersion} is now available.`,
      message: 'A new version of Top Producer X is now available. Would you like to update?',
      primaryButtonLabel: 'Update',
      primaryButtonHandler: this.reloadApp,
      secondaryButtonHandler: this.cancelUpdate
    };

    return <AlertDialog data={alertProps} />;
  }
}

CheckVersion.propTypes = {
  location: PropTypes.object.isRequired
};
