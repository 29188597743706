import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { formatPriceRange } from '../../utils';

import styles from './BuyerPrefs.css';

const PriceRange = props => {
  const { minPrice, maxPrice } = props;

  if (!minPrice && !maxPrice) {
    return null;
  }

  const priceRange = formatPriceRange(minPrice, maxPrice);

  return <div className={styles.pref}>{priceRange}</div>;
};

const AreasOfInterest = props => {
  const { areaOfInt } = props;

  if (!areaOfInt) {
    return null;
  }

  const areas = areaOfInt.join(', ');

  return (
    <div className={styles.pref} title={areas}>
      {areas}
    </div>
  );
};

const Feature = props => {
  const { label = '', value } = props;

  if (!value) {
    return null;
  }

  const str = `${value} ${label}`;

  return (
    <div className={styles.feature} title={str}>
      {str}
    </div>
  );
};

const Features = props => {
  const { beds, baths, propType, sqft } = props;

  if (!beds && !baths && !sqft) {
    return null;
  }

  return (
    <>
      <div className={styles.features}>
        <Feature label="bd" value={beds} />
        <Feature label="ba" value={baths} />
        <Feature label="sqft" value={sqft} />
      </div>
      {propType && (
        <div className={styles.propTypes} title={propType}>
          {propType}
        </div>
      )}
    </>
  );
};

export const BuyerPrefs = props => {
  const { buyerPrefs, className } = props;

  if (!buyerPrefs) {
    return null;
  }

  const { areaOfInt, minPrice, maxPrice, noBaths, noBeds, propType, sqrFt } = buyerPrefs;

  const classes = classnames({
    [styles.buyerPrefs]: !className,
    [className]: !!className
  });

  return (
    <div className={classes}>
      <PriceRange minPrice={minPrice} maxPrice={maxPrice} />
      <Features beds={noBeds} baths={noBaths} sqft={sqrFt} propType={propType} />
      <AreasOfInterest areaOfInt={areaOfInt} />
    </div>
  );
};

BuyerPrefs.propTypes = {
  buyerPrefs: PropTypes.object.isRequired
};
