export const AI_UPDATE_MESSAGE = 'AI_UPDATE_MESSAGE';
export const AI_RESET = 'AI_RESET';
export const AI_OPEN_DIALOG = 'AI_OPEN_DIALOG';
export const AI_CLOSE_DIALOG = 'AI_CLOSE_DIALOG';
export const AI_SET_CONTENT = 'AI_SET_CONTENT';
export const AI_PREFERENCES_SET = 'AI_PREFERENCES_SET';

export const initialState = {
  isLoading: true,
  messages: {},
  isOpen: false,
  currentContent: null, // Use to retrieve last message and check if chat is active. Reset upon closing of email and texting form.
  isBetaUser: false,
  conversationIds: null
};

/**
 * The AI redux reducer.
 * @param {Object} state - the current state of the AI store.
 * @param {Object} action - the action to take on the AI store
 * @param {String} [action.type=default] - the action to take.
 * @param {Object} [action.data] - A data object that represents the alert's details.
 */
export const aiAuthorReducer = (state = initialState, action = {}) => {
  const { type = 'default', messages, data, chatType, conversationId } = action || {};

  switch (type) {
    case AI_UPDATE_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          [chatType]: messages
        },
        conversationIds: {
          ...state.conversationIds,
          [chatType]: conversationId
        }
      };
    case AI_RESET:
      return {
        ...initialState
      };
    case AI_OPEN_DIALOG:
      return {
        ...state,
        isOpen: true
      };
    case AI_CLOSE_DIALOG:
      return {
        ...state,
        isOpen: false
      };
    case AI_SET_CONTENT:
      return {
        ...state,
        currentContent: data
      };
    case AI_PREFERENCES_SET:
      return {
        ...state,
        isBetaUser: data
      };
    default:
      return state;
  }
};
